import { useStore } from "@/store";

const TABLE_NAME_BLE_BEACONS = "ble_beacons";
const COLUMN_BLE_COMPANY_ID = "company_id";
const COLUMN_BLE_BEACON_ID = "beacon_id";
const COLUMN_BLE_CODE_ZONE = "code_zone";
const COLUMN_BLE_DESCRIPTION = "description";
const COLUMN_BLE_LEVEL = "level";
const COLUMN_BLE_TYPE = "beacon_type";
const COLUMN_BLE_BATTERY = "battery";

const COMPANY_ID = 0;
const BEACON_ID = 1;
const CODE_ZONE = 2;
const DESC = 3;
const LEVEL = 4;
const TYPE = 5;

export const addBleBeacon = beacon => {
  const dbFile = useStore().state.emeritValues.dbFile;

  let sqlScript =
    "INSERT INTO " +
    TABLE_NAME_BLE_BEACONS +
    ' ("' +
    COLUMN_BLE_COMPANY_ID +
    '","' +
    COLUMN_BLE_BEACON_ID +
    '","' +
    COLUMN_BLE_CODE_ZONE +
    '","' +
    COLUMN_BLE_DESCRIPTION +
    '","' +
    COLUMN_BLE_LEVEL +
    '","' +
    COLUMN_BLE_TYPE +
    '","' +
    COLUMN_BLE_BATTERY +
    '") VALUES("' +
    beacon[COMPANY_ID] +
    '","' +
    beacon[BEACON_ID] +
    '","' +
    beacon[CODE_ZONE] +
    '","' +
    beacon[DESC] +
    '","' +
    beacon[LEVEL] +
    '","' +
    beacon[TYPE] +
    '",-1)';

  try {
    return dbFile.exec(sqlScript);
  } catch (error) {
    console.log('addBleBeacon sql script failed: ' + error);
  }
};

export const deleteAllBleBeacons = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript = "DELETE FROM ble_beacons";
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('deleteAllBleBeacons sql script failed: ' + error);
  }
};

export const deleteBleBeacon = (companyId, beaconId) => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    "DELETE FROM " +
    TABLE_NAME_BLE_BEACONS +
    " WHERE " +
    COLUMN_BLE_COMPANY_ID +
    '="' +
    companyId +
    '" AND ' +
    COLUMN_BLE_BEACON_ID +
    '="' +
    beaconId +
    '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("deleteBleBeacon failed: " + error);
  }
};

export const getBleBeacons = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * FROM ble_beacons";
  try {
    return dbFile.exec(sqlScript)[0];
  } catch (error) {
    console.log('getAllBeacons sql script failed: ' + error);
  }
};

export const getBleBeaconsCount = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT COUNT(*) FROM ble_beacons";
  try {
    return dbFile.exec(sqlScript)[0].values[0];
  } catch (error) {
    console.log('getBleBeaconsCount failed: ' + error);
  }
};

export const getBleBeaconId = (companyId, beaconId) => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    "SELECT _id FROM " +
    TABLE_NAME_BLE_BEACONS +
    " WHERE " +
    COLUMN_BLE_COMPANY_ID +
    '="' +
    companyId +
    '" INTERSECT SELECT _id FROM ' +
    TABLE_NAME_BLE_BEACONS +
    " WHERE " +
    COLUMN_BLE_BEACON_ID +
    '="' +
    beaconId +
    '" COLLATE NOCASE';
  try {
    return dbFile.exec(sqlScript)[0].values;
  } catch (error) {
    console.log("getBleBeaconId failed: " + error);
  }
};

export const updateBleBeacon = beaconModified => {
  const dbFile = useStore().state.emeritValues.dbFile;

  let sqlScript =
    "UPDATE " +
    TABLE_NAME_BLE_BEACONS +
    " SET " +
    COLUMN_BLE_COMPANY_ID +
    '="' +
    beaconModified.newBeacon[COMPANY_ID] +
    '", ' +
    COLUMN_BLE_BEACON_ID +
    '="' +
    beaconModified.newBeacon[BEACON_ID] +
    '", ' +
    COLUMN_BLE_CODE_ZONE +
    '="' +
    beaconModified.newBeacon[CODE_ZONE] +
    '", ' +
    COLUMN_BLE_DESCRIPTION +
    '="' +
    beaconModified.newBeacon[DESC] +
    '", ' +
    COLUMN_BLE_LEVEL +
    '="' +
    beaconModified.newBeacon[LEVEL] +
    '", ' +
    COLUMN_BLE_TYPE +
    '="' +
    beaconModified.newBeacon[TYPE] +
    '", ' +
    COLUMN_BLE_BATTERY +
    '=-1 WHERE _id=' +
    beaconModified.id;
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("updateBleBeacon failed: " + error);
  }
};
