<template>
  <!-- BEGIN: UserDeviceId local component -->
  <BoxTitle
    :title="componentTitle"
    color="bg-green-200"
    help-locale="menu_device_user_name"
  />
  <div class="clearfix box ">
    <div class="p-3 input-group">
      <input
        v-model="deviceUserId"
        type="text"
        maxlength="30"
        style="width: 100%"
        class="form-control w-full text-left"
        @input="$emit('resetValidationButton')"
      >
      <Tippy 
        :class="getTippyClass"
        :content="$t(getTippyContent)"
        :options="{
          theme: 'light',
          onShow: initTippyInstance,
        }"
        @click="switchTippy"
      >
        <component
          :is="getIcon"
        />
      </Tippy>
    </div>
    <ErrorMsg
      class="pl-3 pb-2"
      :error-msg="errorMsg"
    />
  </div>
  <!-- END: UserDeviceId local component -->
</template>

<script>
import { defineComponent } from 'vue'
import { 
  getBoolPtiValue,
  getParameterPTIValue,
  setNumberPtiValue,
  setParameterPTIValue
} from '../../../dao/parametre-pti'
import { getMessagingWatchState } from '../../../dao/recipient-dao';


const PARAM_PTI_USER_ID = "Identifiant";
const PARAM_PTI_IS_PEC = "TraitementSmsPriseEnCompteAppel";
const PARAM_PTI_USERNAME_DISPLAY_ENABLED = "isUserNameDisplayEnabled";

const MODEL_NAME = "configuration.menu_configuration_model_name";
const DEVICE_USER = "internalrecipientssettings.menu_device_user";

const ICON_NAME_IS_DISPLAYED = "EyeIcon";
const ICON_NAME_IS_NOT_DISPLAYED = "EyeOffIcon";

const TIPPY_GREEN_CLASS = "btn  ml-3 w-auto tooltip"
const TIPPY_RED_CLASS ="btn  ml-3 w-auto tooltip";

const NAME_IS_DISPLAYED = "configuration.menu_name_is_displayed";
const NAME_IS_NOT_DISPLAYED = "configuration.menu_name_is_not_displayed";

const ERR_FIELD_IS_EMPTY = "common.menu_field_cannot_be_empty";
const ERR_BAD_CHARACTER = "configuration.menu_wrong_character_in_user_id";

const EV_RESET_VALIDATION_BUTTON = "resetValidationButton";


export default defineComponent({

  props: {
    isDeployment: {
      type: Boolean,
      required: true,
    },
  },

  emits: [EV_RESET_VALIDATION_BUTTON],

  data() {
    return {
      componentTitle: undefined,
      deviceUserId: '',
      errorMsg: '',
      isUserNameDisplayed: undefined,
      tippyInstance: undefined,
      isSendingSmsToWatch: undefined,
      isPec: undefined,
    }
  },

  computed: {
    getTippyClass() {
      if (this.isUserNameDisplayed)
        return TIPPY_GREEN_CLASS;
      return TIPPY_RED_CLASS;
    },

    getTippyContent() {
      if (this.isUserNameDisplayed)
        return NAME_IS_DISPLAYED;
      return NAME_IS_NOT_DISPLAYED;
    },

    getIcon() {
      if (this.isUserNameDisplayed)
        return ICON_NAME_IS_DISPLAYED;
      return ICON_NAME_IS_NOT_DISPLAYED;
    },
  },

  watch: {
    deviceUserId() {
      if (!this.isPec && this.isSendingSmsToWatch && this.deviceUserId.match('_'))
        this.errorMsg = ERR_BAD_CHARACTER;
      else if (this.errorMsg.length)
        this.errorMsg = '';
    }
  },

  created() {
    this.componentTitle = this.isDeployment ? MODEL_NAME : DEVICE_USER;
    this.isSendingSmsToWatch  = getMessagingWatchState();
    this.isPec = getBoolPtiValue(PARAM_PTI_IS_PEC);
    this.deviceUserId = getParameterPTIValue(PARAM_PTI_USER_ID);
    this.isUserNameDisplayed = getBoolPtiValue(PARAM_PTI_USERNAME_DISPLAY_ENABLED);
  },

  methods: {

    switchTippy() {
      this.isUserNameDisplayed = !this.isUserNameDisplayed;
      setNumberPtiValue(PARAM_PTI_USERNAME_DISPLAY_ENABLED, this.isUserNameDisplayed);
      this.tippyInstance.setContent(this.$t(this.getTippyContent));
      this.tippyInstance.show();
      this.$emit(EV_RESET_VALIDATION_BUTTON);
    },

    initTippyInstance(instance) {
      this.tippyInstance = instance;
    },

    /**
     * @brief Check if the username is valid. If emerit messaging between watches
     *        is enabled, username can't contain '_' character. A check of this component
     *        is done each time user leaves main page (in beforeRouteLeave in 
     *        emerit-main-config.vue), in this case errorMsg printing is deactivated
     *        to avoid an error being printed on screen for less than 1 sec before leaving to
     *        another view.
     * 
     * @param {Boolean} printErrorMsg
     *    If true, errors will be rendered if check is false. If false, nothing appears.
     */
    check(printErrorMsg) {
      let tmpErrorMsg = '';
      if (!this.deviceUserId.length)
        tmpErrorMsg = ERR_FIELD_IS_EMPTY;
      else if (!getBoolPtiValue(PARAM_PTI_IS_PEC) 
          && this.isSendingSmsToWatch
          && this.deviceUserId.match('_'))
        tmpErrorMsg = ERR_BAD_CHARACTER;
      if (printErrorMsg)
        this.errorMsg = tmpErrorMsg;
      return !tmpErrorMsg.length;
    },

    save() {
      setParameterPTIValue(PARAM_PTI_USER_ID, this.deviceUserId);
    },
  }
})
</script>
