<template>
  <div class="clearfix box mt-6">
    <BoxTitle
      title="configuration.menu_device_stop"
      color="bg-gray-400"
    />
    <CheckBoxFieldAndXIcons
      v-for="(fields, index) in systemOption"
      :key="index"
      v-model="fields.value"
      :custom-id="`checkbox-system-option-${index}`"
      :check-box-text="fields.title"
      :error-msg="fields.errorMsg"
      :icon-params="fields.iconParams"
    />
  </div>
</template>
  
<script>
  import { defineComponent } from 'vue'
  import { useStore } from '@/store';
  import {
    getBoolPtiValue,
    setNumberPtiValue,
  } from "../../../dao/parametre-pti";
  import { STORE_SET_IS_CUSTOM_SCREENS } from '../../../store/const_store';
  import { 
    resetAllError, 
  } from '../../../utils/fieldManagement';
  
  
  const PTI_PARAM_IS_BUTTON_ON_OFF_DISABLED = "isOnOffButtonDisabled";
  
  const I18_TITLE_DISABLE_ON_OFF_BTN = "configuration.menu_disable_on_off_btn";
  
  
  export default defineComponent({
  
    data() {
      return {
  
        systemOption: [
            { 
            title: I18_TITLE_DISABLE_ON_OFF_BTN, 
            ptiParam: PTI_PARAM_IS_BUTTON_ON_OFF_DISABLED,
            value: false, 
            errorMsg: '',
            iconParams: undefined,
            },
        ],
      }
    },
  
    created() {
      for (let i = 0; i < this.systemOption.length; ++i) {
        const field = this.systemOption[i];
        if (field.ptiParam)
          field.value = getBoolPtiValue(field.ptiParam)
        else
          field.value = useStore().state.emeritValues[field.storeParamName];
      }
    },
  
    methods: {
      onCheckBoxChange() {
        const field = this.systemOption[0];
        if (field.ptiParam){
          setNumberPtiValue(field.ptiParam, field.value);
        }
        resetAllError(this.systemOption);
      },
    }
  })
</script>  