<template>
  <!-- BEGIN: ButtonUploadFile -->
  <button 
    :class="customClass"
    type="button"
    @click="click()"
  >
    {{ $t(text) }}
    <input
      ref="uploadFile"
      type="file"
      :accept="accept"
      style="display:none;"
      @click="$event.target.value = ''"
      @change="$emit('file-uploaded', $event.target.files)"
    >
  </button>
  <!-- END: ButtonUploadFile -->
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A button with a text, that allows to upload a file which will
 * trigger an event with x files stored in $event[x].
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} text
 *    The text of the button. Should be i18 key for the locale 
 *    (ex: 'common.menu_save').
 * 
 * @param {String} accept
 *    The types of file accepted. Can be one or several files types.
 *    It's just an indication for the user, a file extenstion check 
 *    should still be performed on the imported file. Csv file by default.
 *    (ex: '.csv'. For more info looks to 'accept' HTML attribute).
 * 
 * @param {Boolean} disabled
 *    Optional props. If true, the button will be disabled. False by
 *    default.
 * 
 * @param {String} customClass
 *    A custom class that can override the default class 
 *    (by default a blue button). Some examples:
 *      - yellow: 'btn btn-warning'
 *      - black: 'btn btn-dark'
 *      - blue: 'btn btn-primary'
 *      - white: 'btn btn-outline-secondary'
 *      - red: 'btn btn-danger' 
 * 
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits fileUploaded
 * @return {Array[x Objects]}
 *    Emits x files uploaded by the user. You can access them
 *    with $event[x] in parent component (ex: $event[0] if user uploaded
 *    only one file).
 * 
 * ---------------- METHODS ACCESSIBLE USING REFS -------------------
 * @method click
 * @param void
 *    Allows to trigger a click on the button from the outside.
 *    (ex: this.$refs.xxx.click()).
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({

  props: {
    text: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      default: '.csv',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: 'btn btn-primary mt-6',
    }
  },

  emits: ['file-uploaded'],

  methods: {
    click() {
      if (!this.disabled)
        this.$refs.uploadFile.click();
    }
  }
})
</script>
