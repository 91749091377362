<template>
  <!-- BEGIN: SystemOption local component -->
  <div class="clearfix box mt-6">
    <BoxTitle
      title="configuration.menu_system_option"
      color="bg-gray-400"
      help-locale="menu_system_option"
    />
    <div class="pl-3 pt-3 pb-3 border-b border-gray-200">  
      {{ $t(text) }}
      <router-link 
        v-for="(icon, index) in getIconWithClasses"
        :key="index"
        :to="{ name: icon.routeName, params: { type: icon.paramsType } }"
      >
        <component 
          :is="icon.componentName"
          :class="icon.class"
        /> 
    <div 
      v-if="showerreur === true"
      class="pb-2 "
    >
     <p>
       {{ $t(erreur) }}
     </p>
    
      </div>
      </router-link>
    </div>
   
    <CheckBoxFieldAndXIcons
      v-for="(fields, index) in systemOption"
      :key="index"
      v-model="fields.value"
      :custom-id="`checkbox-system-option-${index}`"
      :check-box-text="fields.title"
      :error-msg="fields.errorMsg"
      :icon-params="fields.iconParams"
      @change="onCheckBoxChange(index)"
    />
  </div>
  <!-- END: SystemOption local component -->
</template>

<script>
import { defineComponent } from 'vue'
import { useStore } from '@/store';
import {
  getBoolPtiValue,
  setNumberPtiValue,
  getParameterPTIValue, 

} from "../../../dao/parametre-pti";
import { STORE_SET_IS_CUSTOM_SCREENS } from '../../../store/const_store';
import { 
  resetAllError, 
  resetError, 
  setError 
} from '../../../utils/fieldManagement';

const PTI_PARAM_IS_BUTTON_ON_OFF_DISABLED = "isOnOffButtonDisabled";
const PARAM_PTI_IS_CUSTOM_LAUNCH_SCREEN_ENABLED =  "isCustomLaunchScreenEnabled";
const PARAM_PTI_IS_CUSTOM_WIDGET_SCREEN_ENABLED =  "isCustomWidgetScreenEnabled";

const I18_TITLE_DISABLE_ON_OFF_BTN = "configuration.menu_disable_on_off_btn";
const I18_TITLE_CUSTOM_SCREENS = "configuration.menu_custom_screens"
const I18_TITLE_CUSTOM_ON_OFF = "configuration.menu_custom_on_off"

const I18_ERR_CHOOSE_AT_LEAST_ONE_CUSTOM_SCREEN = "configuration.menu_choose_at_least_one_custom_screen"

const STORE_IS_CUSTOM_SCREENS = "isCustomScreens";

const INDEX_CUSTOM_SCREEN = 0;
const I18_ERR_WRONG_NUMBER_FORMAT = "common.menu_phone_number_must_start_with_+";



export default defineComponent({
  props: {
    isSipActivated: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      erreur: I18_ERR_WRONG_NUMBER_FORMAT,
      text: I18_TITLE_CUSTOM_ON_OFF,
      iconParams: [ { componentName: 'SlidersIcon', routeName: 'emerit-custom-button-on-off', paramsType: '' } ],
      showerreur: false,
      systemOption: [
        // { 
        //   title: I18_TITLE_CUSTOM_ON_OFF, 
        //   ptiParam: undefined,
        //   value: false, 
        //   errorMsg: '',
        //   iconParams: [ { componentName: 'SlidersIcon', routeName: 'emerit-custom-screens', paramsType: '' } ],
        //   storeParamName: STORE_IS_CUSTOM_SCREENS,
        //   storeSetParam: STORE_SET_IS_CUSTOM_SCREENS,
        // },
        { 
          title: I18_TITLE_CUSTOM_SCREENS, 
          ptiParam: undefined,
          value: false, 
          errorMsg: '',
          iconParams: [ { componentName: 'SlidersIcon', routeName: 'emerit-custom-screens', paramsType: '' } ],
          storeParamName: STORE_IS_CUSTOM_SCREENS,
          storeSetParam: STORE_SET_IS_CUSTOM_SCREENS,
        },
      ],
    }
  },

  computed: {
    getIconWithClasses() {
      if (!this.iconParams)
        return undefined;
      let icon = new Array();
      for (let i = 0; i < this.iconParams.length; ++i) {
        let iconClass = (i === 0) ? "float-right mr-3" : "float-right mr-3";
        icon.push({
          componentName: this.iconParams[i].componentName,
          routeName: this.iconParams[i].routeName,
          paramsType: this.iconParams[i].paramsType,
          class: iconClass,
        })
      }
      return icon;
    },
  },

  created() {
    this.initIsCustomScreens();
    for (let i = 0; i < this.systemOption.length; ++i) {
      const field = this.systemOption[i];
      if (field.ptiParam)
        field.value = getBoolPtiValue(field.ptiParam)
      else
        field.value = useStore().state.emeritValues[field.storeParamName];
    }
  },

  methods: {

    initIsCustomScreens() {
      if (useStore().state.emeritValues.isCustomScreens != undefined)
        return;
      const initValue = getBoolPtiValue(PARAM_PTI_IS_CUSTOM_LAUNCH_SCREEN_ENABLED)
          || getBoolPtiValue(PARAM_PTI_IS_CUSTOM_WIDGET_SCREEN_ENABLED);
      this.$store.commit(STORE_SET_IS_CUSTOM_SCREENS, initValue);
    },
    
    onCheckBoxChange(i) {
      const field = this.systemOption[i];
      if (field.ptiParam)
        setNumberPtiValue(field.ptiParam, field.value)
      else
        this.$store.commit(STORE_SET_IS_CUSTOM_SCREENS, field.value);
      resetAllError(this.systemOption);
    },

    check() {
      if (!this.isSipActivated && getParameterPTIValue('onOffButtonTriggerType')== 2){
        let phone = getParameterPTIValue('automaticCallPhoneRecipient')
        if (phone[0] != '+' || phone.length === 1){
          this.showerreur = true
          return false;
        }
      }     
      if (this.systemOption[INDEX_CUSTOM_SCREEN].value
          && !getBoolPtiValue(PARAM_PTI_IS_CUSTOM_LAUNCH_SCREEN_ENABLED)
          && !getBoolPtiValue(PARAM_PTI_IS_CUSTOM_WIDGET_SCREEN_ENABLED))
        return setError(this.systemOption, INDEX_CUSTOM_SCREEN, I18_ERR_CHOOSE_AT_LEAST_ONE_CUSTOM_SCREEN);
      return resetError(this.systemOption, INDEX_CUSTOM_SCREEN);
    },
  }
})

</script>
