<template>
  <!-- BEGIN: AddLocExInputFields local component-->
  <div class="grid grid-cols-10 gap-3 mt-3">
    <Tippy
      v-for="(field, index) in getLocExFields"
      :key="index"
      :ref="field.title"
      :content="$t(field.tippy)"
      :options="{
        theme: 'light',
        delay: index,
        onShow: updateTippyContent,
      }"
      :class="field.class"
    >
      <CustomTextInput
        v-model="field.value"
        :title="field.title"
        :max-length="field.len"
      />
    </Tippy>
    <CustomButton
      custom-class="lg:mt-6 col-start-9 col-span-2 btn btn-primary mb-2 mt-4"
      text="common.menu_add"
      @click="check()"
    />
    <ErrorMsg
      v-for="(field, index) in getLocExFields"
      :key="index"
      :error-msg="field.errorMsg"
      class="col-start-1 col-span-10"
    />
  </div>
  <!-- END: AddLocExInputFields local component-->
</template>

<script>
import { defineComponent } from 'vue'
import {
  checkLevel,
  checkCodeZone,
  beaconAlreadyExisting,
  checkDescription,
  LOC_EX_UUID,
  checkMacAddress,
} from './utils'
import {
  setError,
  resetError,
  resetAllFields,
} from '../../../utils/fieldManagement'


const BEACON_ID = 0;
const CODE_ZONE = 1;
const LEVEL = 2;
const DESC = 3;

const MAC_ADDRESS_LEN = 17;

const ERR_LOC_EX_ALREADY_EXIST = "locationindoorble.menu_err_loc_ex_already_exist";
const ERR_LOC_EX_BEAC_ID = "locationindoorble.menu_err_loc_ex_beacon_id";
const ERR_CODE_ZONE = "locationindoorble.menu_err_wrong_code_zone";
const ERR_LEVEL = "locationindoorble.menu_err_wrong_level";
const ERR_DESC = "locationindoorble.menu_err_empty_desc";

const BEAC_ID_TITLE = "locationindoorble.menu_beacon_id";
const CODE_ZONE_TITLE = "locationindoorble.menu_beacon_code_zone";
const LEVEL_TITLE = "locationindoorble.menu_beacon_level";
const DESC_TITLE = "locationindoorble.menu_beacon_description";

const BEAC_ID_CLASS = "col-span-10 lg:col-span-6 lg:col-start-1";
const CODE_ZONE_CLASS = "col-span-10 lg:col-span-2 lg:col-start-7";
const LEVEL_CLASS = "col-span-10 lg:col-span-2 lg:col-start-9";
const DESC_CLASS = "col-span-10 lg:col-span-8 lg:col-start-1";

const LOC_EX_MAC_ADDRESS_TIPPY = "locationindoorble.menu_tippy_loc_ex_mac_address";
const CODE_ZONE_TIPPY = "locationindoorble.menu_tippy_code_zone";
const LEVEL_TIPPY = "locationindoorble.menu_tippy_level";
const DESC_TIPPY = "locationindoorble.menu_tippy_description";

const EV_ADD_LOC_EX_FAILED = 'addLocExFailed';
const EV_ADD_LOC_EX_SUCCESS = 'addLocExSuccess';


export default defineComponent({

  props: {
    hideLevelField: {
      type: Boolean,
      required: true,
    },
    beaconList: {
      type: Array,
      required: true,
    }
  },

  emits: [
    EV_ADD_LOC_EX_FAILED, 
    EV_ADD_LOC_EX_SUCCESS
  ],

  data() {
    return {
      LocExFields: [
        { title: BEAC_ID_TITLE, class: BEAC_ID_CLASS, tippy: LOC_EX_MAC_ADDRESS_TIPPY, value: '', len: MAC_ADDRESS_LEN, errorMsg: ''},
        { title: CODE_ZONE_TITLE, class: CODE_ZONE_CLASS, tippy: CODE_ZONE_TIPPY, value: '', len: 3, errorMsg: ''},
        { title: LEVEL_TITLE, class: LEVEL_CLASS, tippy: LEVEL_TIPPY, value: '', len: 3, errorMsg: ''},
        { title: DESC_TITLE, class: DESC_CLASS, tippy: DESC_TIPPY, value: '', len: 50, errorMsg: ''},
      ],
    }
  },

  computed: {
    getLocExFields() {
      const len = this.hideLevelField ? this.LocExFields.length - 1 : this.LocExFields.length;
      let newLocExFields = new Array(len);
      for (let i = 0, j = 0; i < this.LocExFields.length; ++i, ++j) {
        if (this.hideLevelField && i === LEVEL)
          --j
        else 
          newLocExFields[j] = this.LocExFields[i];
      }
      return newLocExFields;
    },
  },

  watch: {
    hideLevelField() {
      this.LocExFields[LEVEL].value = '';
      resetError(this.LocExFields, LEVEL);
    }
  },

  methods: {
    
    updateTippyContent(instance) {
      const index = instance.props.delay;
      instance.setContent(this.$t(this.getLocExFields[index].tippy));
    },

    check() {
      let error = this.LocExFields.length;
      error -= checkMacAddress(this.LocExFields, BEACON_ID, ERR_LOC_EX_BEAC_ID);
      error -= checkLevel(this.hideLevelField, this.LocExFields, LEVEL, ERR_LEVEL);
      error -= checkCodeZone(this.LocExFields, CODE_ZONE, ERR_CODE_ZONE);
      error -= checkDescription(this.LocExFields, DESC, ERR_DESC);
      if (!error && !beaconAlreadyExisting(this.beaconList, LOC_EX_UUID, this.LocExFields[BEACON_ID].value.valueOf(), this.LocExFields, ERR_LOC_EX_ALREADY_EXIST))
        this.emitAddLocExSuccess();
      else
        this.$emit(EV_ADD_LOC_EX_FAILED);
    },

    emitAddLocExSuccess() {
      this.$emit(EV_ADD_LOC_EX_SUCCESS, this.createNewBeacon());
      resetAllFields(this.LocExFields);
    },

    createNewBeacon() {
      return [
        LOC_EX_UUID,
        this.LocExFields[BEACON_ID].value.toUpperCase(),
        this.LocExFields[CODE_ZONE].value,
        this.LocExFields[DESC].value,
        this.LocExFields[LEVEL].value,
      ]
    },

  }
})
</script>
