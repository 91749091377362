<template>
  <!-- BEGIN: BoxOfXTextInputs component-->
  <div class="grid grid-cols-12 gap-6 mt-6">
    <div class="intro-y col-span-12 lg:col-span-6">
      <div class="clearfix box">
        <BoxTitle
          :color="boxTitleColor"
          :title="title"
        />
        <div class="clearfix flex-row p-3 border-b border-gray-200">
          <CustomTextInput
            v-for="(field, index) in inputFieldsParams"
            :key="index"
            v-model="inputFieldsValue[index]"
            class="mb-3"
            :title="field.title"
            :is-password-field="field.isPasswordField"
            :error-msg="field.errorMsg"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- END: BoxOfXTextInputs component-->
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A box with a title and x text input fields, each one with their
 * own title and owm error message in case the input is wrong.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} boxTitleColor 
 *    The class representing the background color of the title part for 
 *    this component.
 * 
 * @param {String} title
 *    The title of the box. Should be i18 key for the locale 
 *    (ex: 'common.menu_save').
 * 
 * @param {Array[x Object]} inputFieldsParams
 *    An array of x objects. Object should be the following: 
 *    objectExample: {
 *      title: 'sip.accountName',  // Key for i18 locale.
 *      value: 'Lucas',            // Text show to the user before he modifies it.
 *      errorMsg: '',              // If empty, nothing appears. Otherwise, this string 
 *    }                               will appear as an error in red to the user.
 *                                    Should be key for i18 locale.
 * 
 * ---------------- METHODS ACCESSIBLE USING $REFS ------------------
 * @method getData
 * @param void
 * @return {Array[x String]}
 *    Returns an array containing the data from the x text input 
 *    fields. The fields can be filled or empty. A data check 
 *    should be performed on this array, and if one of the inputs
 *    is wrong, the props inputFieldsParams[i].errorMsg should be set 
 *    with the appropriate error message to inform the user.
 *                    
 * ------------------------------------------------------------------
 */
export default defineComponent({

  props: {
    boxTitleColor: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    inputFieldsParams: {
      type: Array,
      required: true,
    },
  },
  
  data() {
    return {
      inputFieldsValue: [],
    }
  },
  
  created() {
    for(let i = 0; i < this.inputFieldsParams.length; ++i)
      this.inputFieldsValue.push(this.inputFieldsParams[i].value); 
  },

  methods: {
    getData() {
      return this.inputFieldsValue;
    }
  }
})
</script>
