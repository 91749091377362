<template>
  <div>
    <Pagination
      v-if="beaconFilteredList.length"
      :current-page="page"
      :total-pages="getTotalPages"
      @begin="page = 1"
      @end="page = getTotalPages"
      @decrement="page = page === 1 ? 1 : --page"
      @increment="page = page === getTotalPages ? page : ++page"
    />
    <CustomTableGreenRedOptional
      v-if="beaconFilteredList.length"
      :headers="getHeaders"
      :elems-list="getBleBeaconPage"
      :screen-percent-width="10"
      :showing-errors="true"
      list-element-component-name="ListElementNotGreen"
      red-button-text="common.menu_remove"
      red-button-bootstrap-target="#modal-alert-confirm"
      @element-red-button-clicked="$emit('remove-beacon-filtered', $event + (page - 1) * 10)"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue"

import { 
  BBE9_UUID,
  LOC_EX_UUID,
  MODEL_BBE9,
  MODEL_LOC_EX,
} from './utils'

const COMPANY_ID = 0;

const NB_ITEMS = 10;

const MENU_LIST_UUID_FILTERED = "locationindoorble.menu_list_uuid_filtered";

const EV_REMOVE_BEACON = 'remove-beacon-filtered';

export default defineComponent({
    props: {
        beaconFilteredList: {
            type: Array,
            required: true
        },
    },
    emits: [
        EV_REMOVE_BEACON,
    ],
    data() {
        return {
            page: 1,
        };
    },
    computed: {
        getBleBeaconPage() {
            const startIndex = (this.page - 1) * NB_ITEMS;
            const length = startIndex + NB_ITEMS < this.beaconFilteredList.length ?
                NB_ITEMS : this.beaconFilteredList.length - startIndex;
            let beaconPage = new Array(length);
            for (let i = 0; i < length; ++i)
                beaconPage[i] = this.formatBeaconInfo(this.beaconFilteredList[i + startIndex]);
            return beaconPage;
        },
        getHeaders() {
            return [MENU_LIST_UUID_FILTERED];
        },
        getTotalPages() {
            return Math.ceil(this.beaconFilteredList.length / NB_ITEMS);
        }
    },
    watch: {
        /**
         * Update the page number dynamically when ble beacons are added / removed.
         */
        "beaconFilteredList.length": {
            handler: function updatePageNumber(newVal, oldVal) {
                if (this.page > this.getTotalPages)
                    --this.page;
                else if (newVal > oldVal)
                    this.page = this.getTotalPages;
            }
        },
    },
    methods: {
        formatBeaconInfo(beacon) {
            const len = beacon.length;
            let newBeaconFields = new Array(len);
            for (let i = 0; i < len; ++i) {
                if (i === COMPANY_ID && beacon[i].valueOf() === BBE9_UUID) {
                    newBeaconFields[i] = MODEL_BBE9;
                }
                else if (i === COMPANY_ID && beacon[i].valueOf() === LOC_EX_UUID)
                    newBeaconFields[i] = MODEL_LOC_EX;
                else
                    newBeaconFields[i] = beacon[i];
            }
            return newBeaconFields;
        },
    },
})

</script>