<template>
  <!-- BEGIN: BoxTitle component-->
  <div 
    :class="color" 
    class="flex flex-row relative pt-3 p-3 bg-color-200 border-gray-200 dark:border-dark-5 rounded-t"
  >
    <div>
      <h2 
        class="font-medium text-base"
      >
        {{ $t(title) }}
      </h2>
    </div>
    <CustomButton
      v-if="helpLocale"
      icon-component-name="HelpCircleIcon"
      :custom-class="color"
      class="absolute right-0 mr-4 transform hover:-translate-y-1"
      @click.stop="openLink($t(deviceStr + 'help.' + helpLocale))"
    />
  </div>
  <!-- END: BoxTitle component-->
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "@/store"


/**
 * -------------------------- DESCRIPTION ---------------------------
 * The title part for the different box components used in Emerit console.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} color   
 *    The class representing the background color of this component.
 * 
 * @param {String} title   
 *    The title of the box. Should be i18 key for the locale 
 *    (ex: 'common.menu_save').
 * 
 * @param {String} helpLocale
 *    Second part of the i18 locale (ex: 'menu_loc_outdoor') in order to make 
 *    appear a help button at the right of the title which redirects to online 
 *    help. First part of the locale ('watchhelp' or 'phonehelp') is 
 *    automatically managed. If undefined, the button does not appear. 
 *    Undefined by default. 
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  name: 'BoxTitle',
  
  props: {
    color: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    helpLocale: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      deviceStr: useStore().state.emeritValues.deviceStr,
    }
  },

  methods: {
    openLink(link) {
      window.open(link);
    },
  }
})
</script>
