import Tippy from "./from-template-bought/tippy/Main.vue";
import LoadingIcon from "./from-template-bought/loading-icon/Main.vue";
import * as featherIcons from "@zhuowenli/vue-feather-icons";

// Emerit
import ListElementNotGreen from "./emerit/Tables/ListElementNotGreen";
import CustomTableGreenRedOptional from "./emerit/Tables/CustomTableGreenRedOptional";
import CustomButton from "./emerit/Buttons/CustomButton";
import ButtonDismissModal from "./emerit/Modals_and_Toasts/ButtonDismissModal";
import Title from "./emerit/Texts/Title";
import ButtonTargetModal from "./emerit/Modals_and_Toasts/ButtonTargetModal";
import ModalAlertConfirmChoice from "./emerit/Modals_and_Toasts/ModalAlertConfirmChoice";
import BoxOfXTextInputs from "./emerit/Boxes/BoxOfXTextInputs";
import BoxTitle from "./emerit/Boxes/BoxTitle";
import CheckBoxAndXTextInputs from "./emerit/Boxes/CheckBoxAndXTextInputs";
import CheckBoxFieldAndXIcons from "./emerit/Inputs/CheckBoxFieldAndXIcons";
import CheckBoxTitle from "./emerit/Boxes/CheckBoxTitle";
import CustomTextInput from "./emerit/Inputs/CustomTextInput";
import CustomTextInputWithUnitDescription from "./emerit/Inputs/CustomTextInputWithUnitDescription";
import ListElement from "./emerit/Tables/ListElement";
import CustomTable from "./emerit/Tables/CustomTable";
import ListElementPreviewOnly from "./emerit/Tables/ListElementPreviewOnly";
import CustomTablePreviewOnly from "./emerit/Tables/CustomTablePreviewOnly";
import CustomSelect from "./emerit/Inputs/CustomSelect";
import CustomRadioInput from "./emerit/Inputs/CustomRadioInput";
import CustomRangeInput from "./emerit/Inputs/CustomRangeInput";
import CustomRangeInputNotBoldTitle from "./emerit/Inputs/CustomRangeInputNotBoldTitle";
import ButtonUploadFile from "./emerit/Buttons/ButtonUploadFile";
import CustomToast from "./emerit/Modals_and_Toasts/CustomToast";
import ErrorMsg from "./emerit/Texts/ErrorMsg";
import BoldText from "./emerit/Texts/BoldText";
import CustomSwitch from "./emerit/Inputs/CustomSwitch";
import Pagination from "./emerit/Tables/Pagination";
import FieldXHorizontalCheckBoxes from "./emerit/Inputs/FieldXHorizontalCheckBoxes";
import FieldXVerticalCheckBoxes from "./emerit/Inputs/FieldXVerticalCheckBoxes";
import Ewg100WatchIcon from "./emerit/Icons/Ewg100WatchIcon";
import SipIcon from "./emerit/Icons/SipIcon";
import LoginInfo from "./emerit/Login_Screen/LoginInfo";
import SelectLanguageAndDevice from "./emerit/Login_Screen/SelectLanguageAndDevice";
import DeviceInfo from "./emerit/Login_Screen/DeviceInfo";
import NotifDeviceUpdated from "./emerit/Login_Screen/NotifDeviceUpdated";

export default app => {
  app.component("Tippy", Tippy);
  app.component("LoadingIcon", LoadingIcon);

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = "24";
    app.component(key, icon);
  }

  // Emerit
  app.component("ListElementNotGreen", ListElementNotGreen);
  app.component("CustomTableGreenRedOptional", CustomTableGreenRedOptional);
  app.component("CustomButton", CustomButton);
  app.component("ButtonDismissModal", ButtonDismissModal);
  app.component("Title", Title);
  app.component("ButtonTargetModal", ButtonTargetModal);
  app.component("ModalAlertConfirmChoice", ModalAlertConfirmChoice);
  app.component("BoxOfXTextInputs", BoxOfXTextInputs);
  app.component("BoxTitle", BoxTitle);
  app.component("CheckBoxAndXTextInputs", CheckBoxAndXTextInputs);
  app.component("CheckBoxFieldAndXIcons", CheckBoxFieldAndXIcons);
  app.component("CheckBoxTitle", CheckBoxTitle);
  app.component("CustomTextInput", CustomTextInput);
  app.component("CustomTextInputWithUnitDescription", CustomTextInputWithUnitDescription);
  app.component("ListElement", ListElement);
  app.component("CustomTable", CustomTable);
  app.component("ListElementPreviewOnly", ListElementPreviewOnly);
  app.component("CustomTablePreviewOnly", CustomTablePreviewOnly);
  app.component("CustomSelect", CustomSelect);
  app.component("CustomRadioInput", CustomRadioInput);
  app.component("CustomRangeInput", CustomRangeInput);
  app.component("CustomRangeInputNotBoldTitle", CustomRangeInputNotBoldTitle);
  app.component("ButtonUploadFile", ButtonUploadFile);
  app.component("CustomToast", CustomToast);
  app.component("ErrorMsg", ErrorMsg);
  app.component("BoldText", BoldText);
  app.component("CustomSwitch", CustomSwitch);
  app.component("Pagination", Pagination);
  app.component("FieldXHorizontalCheckBoxes", FieldXHorizontalCheckBoxes);
  app.component("FieldXVerticalCheckBoxes", FieldXVerticalCheckBoxes);
  app.component("Ewg100WatchIcon", Ewg100WatchIcon);
  app.component("SipIcon", SipIcon);
  app.component("LoginInfo", LoginInfo);
  app.component("SelectLanguageAndDevice", SelectLanguageAndDevice);
  app.component("DeviceInfo", DeviceInfo);
  app.component("NotifDeviceUpdated", NotifDeviceUpdated);
};
