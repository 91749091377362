<template>
  <!-- BEGIN: Top Bar -->
  <div class="h-28 top-bar">
    <DeviceInfo />
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({})
</script>
