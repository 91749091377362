import { useStore } from "@/store";
import { getDbVersion } from "./pragma-dao";
import { getParameterPTIValue } from "./parametre-pti";
import { getRecipienSupIp } from "./sup-ip-dao";

export const setDbVersion = pragmaVersion => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "PRAGMA user_version = " + pragmaVersion;
  execSqlRequest(dbFile, sqlScript);
};

export const migrate = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const DB_VERSION = getDbVersion();

  if (process.env.VUE_APP_DB_VERSION > DB_VERSION) {
    if (DB_VERSION < 7) migrateTo_1_3_6(dbFile);
    if (DB_VERSION < 8) migrateTo_1_3_8(dbFile);
    if (DB_VERSION < 9) migrateTo_2_0_3(dbFile);
    if (DB_VERSION < 10) migrateTo_2_1_1(dbFile);
    if (DB_VERSION < 11) migrateTo_2_2_0(dbFile);
    if (DB_VERSION < 12) migrateTo_2_3_0(dbFile);
    if (DB_VERSION < 13) migrateTo_2_4_0(dbFile);
    if (DB_VERSION < 14) migrateTo_2_5_0(dbFile);
    if (DB_VERSION < 15) migrateTo_2_6_0(dbFile);
    if (DB_VERSION < 16) migrateTo_2_7_0(dbFile);
    if (DB_VERSION < 17) migrateTo_2_8_0(dbFile);
    if (DB_VERSION < 19) migrateTo_2_9_0(dbFile);
    if (DB_VERSION < 20) migrateTo_2_10_0(dbFile);
    if (DB_VERSION < 21) migrateTo_2_11_0(dbFile);
    if (DB_VERSION < 22) migrateTo_2_12_0(dbFile);
    setDbVersion(process.env.VUE_APP_DB_VERSION);
    console.log("DB version : " + process.env.VUE_APP_DB_VERSION);
  }
};

const TABLE_NAME_WIFI = "wifi";
const COLUMN_ID = "_id";
const COLUMN_SSID = "ssid";
const COLUMN_PASSWORD = "password";
const COLUMN_NETWORK_TYPE = "network_type";

const TABLE_NAME_PARAMETRE_PTI = "parametre_pti";
const COLUMN_TYPE = "type";
const COLUMN_VAL_MAX = "val_max";
const COLUMN_VAL_MIN = "val_min";
const COLUMN_PARAM = "param";
const COLUMN_VALEUR = "valeur";
const COLUMN_COMMENTAIRE = "commentaires";

const TABLE_NAME_PASSWORD = "password";
const COLUMN_DEFAULT_PWD = "default_pwd";
const COLUMN_PWD = "pwd";

const TABLE_NAME_WIFI_BEACONS = "wifiBeacons";
const COLUMN_BSSID = "bssid";
const COLUMN_CODE_ZONE = "code_zone";
const COLUMN_DESCRIPTION = "description";
const COLUMN_TIMESTAMP = "last_known_timestamp";

const TABLE_NAME_SIP = "sip_account";
const COLUMN_ACCOUNT_NAME = "account_name";
const COLUMN_USER_NAME = "user_name";
const COLUMN_USER_PASSWORD = "user_password";
const COLUMN_SERVER_URL = "server_url";

const TABLE_NAME_BLE_BEACONS = "ble_beacons";
const COLUMN_BLE_BEACON_TYPE = "beacon_type";
const COLUMN_BLE_COMPANY_ID = "company_id";
const COLUMN_BLE_BEACON_ID = "beacon_id";
const COLUMN_BLE_CODE_ZONE = "code_zone";
const COLUMN_BLE_DESCRIPTION = "description";
const COLUMN_BLE_LEVEL = "level";

const COLUMN_TYPE_ALERTE = "type_alerte";
const COLUMN_PRIORITE = "priorite";
const COLUMN_TIME_SYSTEM = "time_system";
const COLUMN_BATTERY_LEVEL = "battery_level";
const COLUMN_NFC_TAG_ID = "nfc_tag_id";
const COLUMN_GPS_LATITUDE = "gps_latitude";
const COLUMN_GPS_LONGITUDE = "gps_longitude";
const COLUMN_GPS_PRECISION = "gps_precision";
const COLUMN_GPS_ALTITUDE = "gps_altitude";
const COLUMN_GPS_SPEED = "gps_speed";
const COLUMN_GPS_TIME_MS = "gps_time_ms";
const COLUMN_BACKUP_TRANSMISSION_GSM = "transmission_gsm";
const COLUMN_BACKUP_TRANSMISSION_DATAS = "transmission_datas";

const TABLE_NAME_BACKUP_ALARME_TEMP = "backup_alarm_temp";
const TABLE_NAME_BLE_BEACONS_TEMP = "ble_beacons_temp";
const TABLE_NAME_BACKUP_ALARME = "backup_alarme";

const TABLE_NAME_SUP_IP = "serveur_supervision_gprs";
const TABLE_NAME_TLS_IP = "tele_surveillance_gprs";

const PARAM_PTI_TYPE_BOOL = "BOOLEAN";
const PARAM_PTI_TYPE_INT = "INTEGER";
const PARAM_PTI_TYPE_STRING = "STRING";

const SQL_TYPE_INT = "integer";
const SQL_TYPE_TEXT = "text";

const COLUMN_BATTERY_BEACON_BUG_INTEGER = "batteryinteger";

/* *************************************************************** */
/* **************************** 1.3.6 **************************** */
/* *************************************************************** */

const SQL_CREATE_WIFI =
  "create table " +
  TABLE_NAME_WIFI +
  " (" +
  COLUMN_ID +
  " integer primary key autoincrement, " +
  COLUMN_SSID +
  " text," +
  COLUMN_PASSWORD +
  " text," +
  COLUMN_NETWORK_TYPE +
  " text" +
  ");";

const PARAM_PTI_IS_WIFI_WANTED = "isWifiWanted";

const SQL_CREATE_DEPLOY_PASSWORD = "Insert into " + TABLE_NAME_PASSWORD + " (" + COLUMN_DEFAULT_PWD + "," + COLUMN_PWD + ") values('deploy','')";

export const migrateTo_1_3_6 = dbFile => {
  console.log("Executing migration 1.3.6");

  execSqlRequest(dbFile, SQL_CREATE_WIFI);
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WIFI_WANTED, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, SQL_CREATE_DEPLOY_PASSWORD);
  return true;
};

/* *************************************************************** */
/* **************************** 1.3.8 **************************** */
/* *************************************************************** */

const SQL_MODIFY_WIFI_LOC_PARAM = "Update parametre_pti set valeur = 0 where param='isWifiLocParamEnabled'";

const SQL_CREATE_INDOOR_WIFI =
  "create table " +
  TABLE_NAME_WIFI_BEACONS +
  " (" +
  COLUMN_ID +
  " integer primary key autoincrement, " +
  COLUMN_SSID +
  " text," +
  COLUMN_BSSID +
  " text," +
  COLUMN_CODE_ZONE +
  " text," +
  COLUMN_DESCRIPTION +
  " text," +
  COLUMN_TIMESTAMP +
  " text);";

const PARAM_PTI_IS_WIFI_LOC_REFRESH_UPDATE_ENABLED = "isWifiLocRefreshUpdateEnabled";
const PARAM_PTI_WIFI_LOC_REFRESH_DELAY_IN_SECONDS = "WifiLocRefreshDelayInSeconds";
const PARAM_PTI_IS_AUTOMATIC_CALL_PICKUP = "isAutomaticCallPickup";

export const migrateTo_1_3_8 = dbFile => {
  console.log("Executing migration 1.3.8");

  execSqlRequest(dbFile, SQL_CREATE_INDOOR_WIFI);
  execSqlRequest(dbFile, SQL_MODIFY_WIFI_LOC_PARAM);
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WIFI_LOC_REFRESH_UPDATE_ENABLED, 1, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_WIFI_LOC_REFRESH_DELAY_IN_SECONDS, 60, PARAM_PTI_TYPE_INT));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_AUTOMATIC_CALL_PICKUP, 0, PARAM_PTI_TYPE_BOOL));

  return true;
};

/* *************************************************************** */
/* **************************** 2.0.3 **************************** */
/* *************************************************************** */

const SQL_MODIFY_GPS_LOC_REFRESH_DELAY_VALEUR = "Update parametre_pti set valeur = 10 where param='isGpsLocRefreshDelayInSeconds'";
const SQL_MODIFY_GPS_LOC_REFRESH_DELAY_PARAM =
  "Update parametre_pti set param ='GpsLocRefreshDelayInSeconds' where param='isGpsLocRefreshDelayInSeconds'";
const SQL_MODIFY_GPS_LOC_REFRESH_DISTANCE_PARAM =
  "Update parametre_pti set param ='GpsLocRefreshDistanceInMeters' where param='isGpsLocRefreshDistanceInMeters'";
const SQL_MODIFY_MOUVEMENT_SENSI_SEUIL_VALEUR = "Update parametre_pti set valeur = 1030 where param='AbsenceMouvementSensibilite'";

const PARAM_PTI_IS_REMOTE_CONFIG_ENABLED = "isRemoteConfigEnabled";
const PARAM_PTI_IS_USER_NAME_DISPLAY_ENABLED = "isUserNameDisplayEnabled";

export const migrateTo_2_0_3 = dbFile => {
  console.log("Executing migration 2.0.3");

  var deviceModel = useStore().state.emeritValues.deviceInfo.deviceModelName;
  let remoteConfigParam = "0";
  let userNameDisplayedParam = "0";
  if (deviceModel === "E-WG100") {
    remoteConfigParam = "1";
    userNameDisplayedParam = "1";
  }

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_REMOTE_CONFIG_ENABLED, remoteConfigParam, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_USER_NAME_DISPLAY_ENABLED, userNameDisplayedParam, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, SQL_MODIFY_MOUVEMENT_SENSI_SEUIL_VALEUR);

  if (deviceModel !== "E-WG100") {
    execSqlRequest(dbFile, SQL_MODIFY_GPS_LOC_REFRESH_DELAY_VALEUR);
    execSqlRequest(dbFile, SQL_MODIFY_GPS_LOC_REFRESH_DELAY_PARAM);
    execSqlRequest(dbFile, SQL_MODIFY_GPS_LOC_REFRESH_DISTANCE_PARAM);
  }
  return true;
};

/* *************************************************************** */
/* **************************** 2.1.1 **************************** */
/* *************************************************************** */

const PARAM_PTI_SIP_MANAGER_AVAILABLE = "SipManagerAvailable";
const PARAM_PTI_SIP_ACTIVATION_STATE = "SipActivationState";

const SQL_UPDATE_WIFI_REFRESH = "Update parametre_pti set valeur = 1 where param='isWifiLocRefreshUpdateEnabled'";
const SQL_UPDATE_WIFI_REFRESH_DELAY = "Update parametre_pti set valeur = 60 where param='WifiLocRefreshDelayInSeconds'";

const SQL_WIFI_TABLE_ADD_HIDDEN_COLUMN = "Alter table wifi ADD Column hidden_ssid integer default 0";
const SQL_CREATE_SIP_TABLE =
  "create table " +
  TABLE_NAME_SIP +
  " (" +
  COLUMN_ID +
  " integer primary key autoincrement, " +
  COLUMN_ACCOUNT_NAME +
  " text, " +
  COLUMN_USER_NAME +
  " text, " +
  COLUMN_USER_PASSWORD +
  " text, " +
  COLUMN_SERVER_URL +
  " text" +
  ");";

export const migrateTo_2_1_1 = dbFile => {
  console.log("Executing migration 2.1.1");

  execSqlRequest(dbFile, SQL_CREATE_SIP_TABLE);
  execSqlRequest(dbFile, SQL_WIFI_TABLE_ADD_HIDDEN_COLUMN);
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_SIP_MANAGER_AVAILABLE, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_SIP_ACTIVATION_STATE, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, SQL_UPDATE_WIFI_REFRESH);
  execSqlRequest(dbFile, SQL_UPDATE_WIFI_REFRESH_DELAY);
};

/* *************************************************************** */
/* **************************** 2.2.1 **************************** */
/* *************************************************************** */

const PARAM_PTI_BLE_INDOOR_LEVEL_PRECISION = "IndoorBleLevelPrecisionActivated";
const PARAM_PTI_IS_FRAGMENT_LOC_WANTED = "isFragmentLocWanted";

const EXTENDED_DATA_IMEI_COLUMN_NAME = "sia_ex_data_device_imei";
const EXTENDED_DATA_BEACON_ID_COLUMN_NAME = "sia_ex_data_beacon_id";

const PARAM_PTI_IS_WRIST_DETEC_ENABLED = "isWristDetectionEnabled";
const PARAM_PTI_IS_WRIST_NOTIF_VIB = "isNotifWristMouvementVibrationEnabled";
const PARAM_PTI_IS_WRIST_NOTIF_SOUND = "isNotifWristMouvementSoundEnabled";
const PARAM_PTI_IS_WRIST_DETEC_DISCRET_MODE = "isWristMouvementDiscretModeEnabled";
const PARAM_PTI_WRIST_DETEC_NOTIF_TIME = "WristMouvementNotifTime";

const PARAM_PTI_IS_MSG_NOTIF_VIB_ENABLED = "isEmeritMessageNotificationVibrationEnabled";
const PARAM_PTI_IS_MSG_NOTIF_SOUND_ENABLED = "isEmeritMessageNotificationSoundEnabled";

const INTERNAL_RECI_ADD_P_EMERIT_WATCH_COLUMN = "Alter table destinataire_internalise ADD Column p_emerit_watch integer default 0";

const SQL_WIFIBEACONS_TABLE_ADD_LEVEL_COLUMN = "Alter table wifiBeacons ADD Column level text default ''";
const SQL_CREATE_BLE_BEACONS_TABLE =
  "create table " +
  TABLE_NAME_BLE_BEACONS +
  " (" +
  COLUMN_ID +
  " integer primary key autoincrement, " +
  COLUMN_BLE_COMPANY_ID +
  " text, " +
  COLUMN_BLE_BEACON_ID +
  " text, " +
  COLUMN_BLE_CODE_ZONE +
  " text, " +
  COLUMN_BLE_DESCRIPTION +
  " text, " +
  COLUMN_BLE_LEVEL +
  " text, " +
  COLUMN_BLE_BEACON_TYPE +
  " text" +
  ");";

export const migrateTo_2_2_0 = dbFile => {
  console.log("Executing migration 2.2.0");

  execSqlRequest(dbFile, SQL_CREATE_BLE_BEACONS_TABLE);
  execSqlRequest(dbFile, SQL_WIFIBEACONS_TABLE_ADD_LEVEL_COLUMN);

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_BLE_INDOOR_LEVEL_PRECISION, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_FRAGMENT_LOC_WANTED, 0, PARAM_PTI_TYPE_BOOL));

  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_SUP_IP, EXTENDED_DATA_IMEI_COLUMN_NAME, SQL_TYPE_INT, 0));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_SUP_IP, EXTENDED_DATA_BEACON_ID_COLUMN_NAME, SQL_TYPE_INT, 0));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_TLS_IP, EXTENDED_DATA_IMEI_COLUMN_NAME, SQL_TYPE_INT, 0));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_TLS_IP, EXTENDED_DATA_BEACON_ID_COLUMN_NAME, SQL_TYPE_INT, 0));

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WRIST_DETEC_ENABLED, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WRIST_NOTIF_VIB, 1, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WRIST_NOTIF_SOUND, 1, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WRIST_DETEC_DISCRET_MODE, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_WRIST_DETEC_NOTIF_TIME, 5, PARAM_PTI_TYPE_INT));

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_MSG_NOTIF_SOUND_ENABLED, 1, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_MSG_NOTIF_VIB_ENABLED, 1, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, INTERNAL_RECI_ADD_P_EMERIT_WATCH_COLUMN);

  return true;
};

/* *************************************************************** */
/* **************************** 2.3.0 **************************** */
/* *************************************************************** */

const SQL_ADD_COLUMN_SIP_ACCOUNT_ID = "Alter table destinataire_internalise ADD Column sip_account_id text default ''";
const SQL_ADD_COLUMN_SIP_SERVER_URL = "Alter table destinataire_internalise ADD Column sip_server_url text default ''";
const SQL_ADD_COLUMN_IS_SIP_RECIPIENT = "Alter table destinataire_internalise ADD Column is_sip_recipient integer default 0";

const PARAM_PTI_SHOW_BATTERY_PERCENTAGE = "isShowBatteryLevelEnabled";
const PARAM_PTI_IS_UPDATE_APP_BY_WIFI_ENABLED = "isUpdateAppByWifiEnabled";
const PARAM_PTI_WRIST_THRESHOLD = "WristMouvementThreshold";
const PARAM_PTI_IS_WAKE_SCREEN_ENABLED = "isWakeUpScreenWhenChargingEnabled";
const PARAM_PTI_IS_SOUND_NOTIF_SECURITE_POSITIVE_ENABLED = "isSoundNotificationSecuritePositiveEnabled";

export const migrateTo_2_3_0 = dbFile => {
  console.log("Executing migration 2.3.0");

  //Specials request for correct some missing column or param

  //1.3.8 stuff
  execSqlRequest(dbFile, SQL_CREATE_INDOOR_WIFI); // Create wifi beacon because sometimes , it is not created at all
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WIFI_LOC_REFRESH_UPDATE_ENABLED, 1, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_WIFI_LOC_REFRESH_DELAY_IN_SECONDS, 60, PARAM_PTI_TYPE_INT));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_AUTOMATIC_CALL_PICKUP, 0, PARAM_PTI_TYPE_BOOL));

  //2.1.1 stuff
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_SIP_MANAGER_AVAILABLE, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_SIP_ACTIVATION_STATE, 0, PARAM_PTI_TYPE_BOOL));

  //2.2.0 stuff
  execSqlRequest(dbFile, SQL_WIFIBEACONS_TABLE_ADD_LEVEL_COLUMN); // Added level here and not in table process creation because we don't know if the table already exist
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_BLE_INDOOR_LEVEL_PRECISION, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_FRAGMENT_LOC_WANTED, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_SUP_IP, EXTENDED_DATA_IMEI_COLUMN_NAME, SQL_TYPE_INT, 0));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_SUP_IP, EXTENDED_DATA_BEACON_ID_COLUMN_NAME, SQL_TYPE_INT, 0));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_TLS_IP, EXTENDED_DATA_IMEI_COLUMN_NAME, SQL_TYPE_INT, 0));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_TLS_IP, EXTENDED_DATA_BEACON_ID_COLUMN_NAME, SQL_TYPE_INT, 0));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WRIST_DETEC_ENABLED, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WRIST_NOTIF_VIB, 1, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WRIST_NOTIF_SOUND, 1, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WRIST_DETEC_DISCRET_MODE, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_WRIST_DETEC_NOTIF_TIME, 5, PARAM_PTI_TYPE_INT));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_MSG_NOTIF_SOUND_ENABLED, 1, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_MSG_NOTIF_VIB_ENABLED, 1, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, INTERNAL_RECI_ADD_P_EMERIT_WATCH_COLUMN);

  //End specials sql requests

  execSqlRequest(dbFile, SQL_ADD_COLUMN_SIP_ACCOUNT_ID);
  execSqlRequest(dbFile, SQL_ADD_COLUMN_SIP_SERVER_URL);
  execSqlRequest(dbFile, SQL_ADD_COLUMN_IS_SIP_RECIPIENT);

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_UPDATE_APP_BY_WIFI_ENABLED, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_SHOW_BATTERY_PERCENTAGE, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WAKE_SCREEN_ENABLED, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_WRIST_THRESHOLD, 1, PARAM_PTI_TYPE_INT));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_SOUND_NOTIF_SECURITE_POSITIVE_ENABLED, 0, PARAM_PTI_TYPE_INT));

  return true;
};

/* *************************************************************** */
/* **************************** 2.4.0 **************************** */
/* *************************************************************** */

const PTI_PARAM_UNSTOPABLE_CALL_IS_AVAILABLE = "isUnstopableCallAvailable";
const PTI_PARAM_UNSTOPABLE_CALL_IS_ACTIVATED = "isUnstopableCallActivated";
const PARAM_PTI_IS_ON_OFF_BUTTON_DISABLED = "isOnOffButtonDisabled";

const PARAM_PTI_IS_WHITELIST_ENABLED = "isWhitelistEnabled";

const TABLE_NAME_WHITELIST = "whitelist_recipient";
const COLUMN_NAME = "name";
const COLUMN_PHONE_NUMBER = "phone_number";

const SQL_CREATE_WITELIST_TABLE =
  "create table " +
  TABLE_NAME_WHITELIST +
  " (" +
  COLUMN_ID +
  " integer primary key autoincrement, " +
  COLUMN_NAME +
  " text," +
  COLUMN_PHONE_NUMBER +
  " text);";

export const migrateTo_2_4_0 = dbFile => {
  console.log("Executing migration 2.4.0");

  execSqlRequest(dbFile, addNewPtiParam(PTI_PARAM_UNSTOPABLE_CALL_IS_AVAILABLE, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PTI_PARAM_UNSTOPABLE_CALL_IS_ACTIVATED, 0, PARAM_PTI_TYPE_BOOL));

  execSqlRequest(dbFile, SQL_CREATE_WITELIST_TABLE);
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WHITELIST_ENABLED, 0, PARAM_PTI_TYPE_BOOL));

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_ON_OFF_BUTTON_DISABLED, 0, PARAM_PTI_TYPE_BOOL));

  return true;
};

/* *************************************************************** */
/* **************************** 2.5.0 **************************** */
/* *************************************************************** */

const PARAM_PTI_IS_CUSTOM_LAUNCH_SCREEN_ENABLED = "isCustomLaunchScreenEnabled";
const PARAM_PTI_IS_CUSTOM_WIDGET_SCREEN_ENABLED = "isCustomWidgetScreenEnabled";

const PARAM_PTI_IS_LAUNCH_SCREEN_IMG_UPLOADED = "isLaunchScreenImgUploaded";
const PARAM_PTI_IS_WIDGET_SCREEN_IMG_UPLOADED = "isWidgetScreenImgUploaded";

const PARAM_PTI_CUSTOM_WIDGET_TOP_TEXT = "customWidgetTopText";
const PARAM_PTI_CUSTOM_WIDGET_BOTTOM_TEXT = "customWidgetBottomText";
const PARAM_PTI_IS_CUSTOM_WIDGET_TEXT_IN_BLACK = "isCustomWidgetTextInBlack";

export const migrateTo_2_5_0 = dbFile => {
  console.log("Executing migration 2.5.0");

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_CUSTOM_LAUNCH_SCREEN_ENABLED, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_CUSTOM_WIDGET_SCREEN_ENABLED, 0, PARAM_PTI_TYPE_BOOL));

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_LAUNCH_SCREEN_IMG_UPLOADED, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_WIDGET_SCREEN_IMG_UPLOADED, 0, PARAM_PTI_TYPE_BOOL));

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_CUSTOM_WIDGET_TOP_TEXT, "''", PARAM_PTI_TYPE_STRING));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_CUSTOM_WIDGET_BOTTOM_TEXT, "''", PARAM_PTI_TYPE_STRING));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_IS_CUSTOM_WIDGET_TEXT_IN_BLACK, 0, PARAM_PTI_TYPE_BOOL));

  return true;
};

/* *************************************************************** */
/* **************************** 2.6.0 **************************** */
/* *************************************************************** */

const EV_BEACON_LOW_BATTERY_COL_NAME = "p_beacon_batterie_basse";
const TABLE_NAME_INTERNAL_RECIPIENT = "destinataire_internalise";
const TABLE_NAME_RECIPIENT_PEC = "destinataire_sms_prise_en_compte";

const PARAM_PTI_SEUIL_BEACON_LOW_BATTERY_LEVEL = "beaconLowBatteryLevel";

const TABLE_NAME_BACKUP_ALARM = "backup_alarme";
const BATTERY_COLUMN_NAME = "battery";

export const migrateTo_2_6_0 = dbFile => {
  console.log("Executing migration 2.6.0");

  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_INTERNAL_RECIPIENT, EV_BEACON_LOW_BATTERY_COL_NAME, SQL_TYPE_INT, 0));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_RECIPIENT_PEC, EV_BEACON_LOW_BATTERY_COL_NAME, SQL_TYPE_INT, 0));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_TLS_IP, EV_BEACON_LOW_BATTERY_COL_NAME, SQL_TYPE_INT, 0));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_SUP_IP, EV_BEACON_LOW_BATTERY_COL_NAME, SQL_TYPE_INT, 0));

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_SEUIL_BEACON_LOW_BATTERY_LEVEL, 30, PARAM_PTI_TYPE_INT));

  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_BLE_BEACONS, BATTERY_COLUMN_NAME, SQL_TYPE_INT, -1));

  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_BACKUP_ALARM, COLUMN_BLE_COMPANY_ID, SQL_TYPE_TEXT, "''"));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_BACKUP_ALARM, COLUMN_BLE_BEACON_ID, SQL_TYPE_TEXT, "''"));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_BACKUP_ALARM, COLUMN_CODE_ZONE, SQL_TYPE_TEXT, "''"));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_BACKUP_ALARM, COLUMN_DESCRIPTION, SQL_TYPE_TEXT, "''"));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_BACKUP_ALARM, COLUMN_TIMESTAMP, SQL_TYPE_TEXT, "''"));

  return true;
};

/* *************************************************************** */
/* **************************** 2.7.0 **************************** */
/* *************************************************************** */
/* *************************************************************** */
/* **************************** 2.7.0 **************************** */
/* *************************************************************** */

const CREATE_TABLE_BACKUP_ALARM_TEMP =
  "CREATE TABLE  " +
  TABLE_NAME_BACKUP_ALARME_TEMP +
  "(" +
  COLUMN_ID +
  " integer primary key autoincrement, " +
  COLUMN_TYPE_ALERTE +
  " text, " +
  COLUMN_PRIORITE +
  " integer, " +
  COLUMN_TIME_SYSTEM +
  " text, " +
  COLUMN_BATTERY_LEVEL +
  " text, " +
  COLUMN_NFC_TAG_ID +
  " text, " +
  COLUMN_GPS_LATITUDE +
  " text, " +
  COLUMN_GPS_LONGITUDE +
  " text, " +
  COLUMN_GPS_PRECISION +
  " text, " +
  COLUMN_GPS_ALTITUDE +
  " text, " +
  COLUMN_GPS_SPEED +
  " text, " +
  COLUMN_GPS_TIME_MS +
  " text," +
  COLUMN_BACKUP_TRANSMISSION_GSM +
  " integer," +
  COLUMN_BACKUP_TRANSMISSION_DATAS +
  " integer," +
  COLUMN_BLE_COMPANY_ID +
  " text default '', " +
  COLUMN_BLE_BEACON_ID +
  " text default '', " +
  COLUMN_CODE_ZONE +
  " text default ''," +
  COLUMN_DESCRIPTION +
  " text default '', " +
  COLUMN_TIMESTAMP +
  " text default '0'" +
  ");";

const COPY_TABLE_BACKUP_ALARM_DATA_INTO_BACKUP_ALARM_TEMP =
  "INSERT INTO  " +
  TABLE_NAME_BACKUP_ALARME_TEMP +
  " (" +
  COLUMN_ID +
  "," +
  COLUMN_TYPE_ALERTE +
  "," +
  COLUMN_PRIORITE +
  "," +
  COLUMN_TIME_SYSTEM +
  "," +
  COLUMN_BATTERY_LEVEL +
  "," +
  COLUMN_NFC_TAG_ID +
  "," +
  COLUMN_GPS_LATITUDE +
  "," +
  COLUMN_GPS_LONGITUDE +
  "," +
  COLUMN_GPS_PRECISION +
  "," +
  COLUMN_GPS_ALTITUDE +
  "," +
  COLUMN_GPS_SPEED +
  "," +
  COLUMN_GPS_TIME_MS +
  "," +
  COLUMN_BACKUP_TRANSMISSION_GSM +
  "," +
  COLUMN_BACKUP_TRANSMISSION_DATAS +
  "," +
  COLUMN_CODE_ZONE +
  "," +
  COLUMN_DESCRIPTION +
  "," +
  COLUMN_TIMESTAMP +
  "," +
  COLUMN_BLE_COMPANY_ID +
  "," +
  COLUMN_BLE_BEACON_ID +
  ") " +
  "SELECT " +
  COLUMN_ID +
  "," +
  COLUMN_TYPE_ALERTE +
  "," +
  COLUMN_PRIORITE +
  "," +
  COLUMN_TIME_SYSTEM +
  "," +
  COLUMN_BATTERY_LEVEL +
  "," +
  COLUMN_NFC_TAG_ID +
  "," +
  COLUMN_GPS_LATITUDE +
  "," +
  COLUMN_GPS_LONGITUDE +
  "," +
  COLUMN_GPS_PRECISION +
  "," +
  COLUMN_GPS_ALTITUDE +
  "," +
  COLUMN_GPS_SPEED +
  "," +
  COLUMN_GPS_TIME_MS +
  "," +
  COLUMN_BACKUP_TRANSMISSION_GSM +
  "," +
  COLUMN_BACKUP_TRANSMISSION_DATAS +
  "," +
  COLUMN_CODE_ZONE +
  "," +
  COLUMN_DESCRIPTION +
  "," +
  COLUMN_TIMESTAMP +
  "," +
  COLUMN_BLE_COMPANY_ID +
  "," +
  COLUMN_BLE_BEACON_ID +
  " FROM " +
  TABLE_NAME_BACKUP_ALARME;

const CREATE_TABLE_BLE_BEACON_2_6_1_TEMP =
  "CREATE TABLE  " +
  TABLE_NAME_BLE_BEACONS_TEMP +
  "(" +
  COLUMN_ID +
  " integer primary key autoincrement, " +
  COLUMN_BLE_COMPANY_ID +
  " text, " +
  COLUMN_BLE_BEACON_ID +
  " text, " +
  COLUMN_CODE_ZONE +
  " text, " +
  COLUMN_DESCRIPTION +
  " text, " +
  COLUMN_BLE_LEVEL +
  " text, " +
  COLUMN_BLE_BEACON_TYPE +
  " text, " +
  BATTERY_COLUMN_NAME +
  " integer default -1" +
  ");";

const COPY_TABLE_BLE_BEACON_DATA_INTO_BLE_BEACON_TEMP =
  "INSERT INTO  " +
  TABLE_NAME_BLE_BEACONS_TEMP +
  " (" +
  COLUMN_ID +
  "," +
  COLUMN_BLE_COMPANY_ID +
  "," +
  COLUMN_BLE_BEACON_ID +
  "," +
  COLUMN_CODE_ZONE +
  "," +
  COLUMN_DESCRIPTION +
  "," +
  COLUMN_BLE_LEVEL +
  "," +
  COLUMN_BLE_BEACON_TYPE +
  "," +
  BATTERY_COLUMN_NAME +
  ") " +
  "SELECT " +
  COLUMN_ID +
  "," +
  COLUMN_BLE_COMPANY_ID +
  "," +
  COLUMN_BLE_BEACON_ID +
  "," +
  COLUMN_CODE_ZONE +
  "," +
  COLUMN_DESCRIPTION +
  "," +
  COLUMN_BLE_LEVEL +
  "," +
  COLUMN_BLE_BEACON_TYPE +
  "," +
  COLUMN_BATTERY_BEACON_BUG_INTEGER +
  " FROM " +
  TABLE_NAME_BLE_BEACONS;

export const migrateTo_2_7_0 = dbFile => {
  console.log("Executing migration 2.7.0");
  execSqlRequest(dbFile, CREATE_TABLE_BACKUP_ALARM_TEMP);
  execSqlRequest(dbFile, COPY_TABLE_BACKUP_ALARM_DATA_INTO_BACKUP_ALARM_TEMP);
  execSqlRequest(dbFile, dropTable(TABLE_NAME_BACKUP_ALARME));
  execSqlRequest(dbFile, renameTable(TABLE_NAME_BACKUP_ALARME_TEMP, TABLE_NAME_BACKUP_ALARME));

  if (isColumnExist(dbFile, TABLE_NAME_BLE_BEACONS, COLUMN_BATTERY_BEACON_BUG_INTEGER)) {
    console.log("Correct battery problem column");
    execSqlRequest(dbFile, CREATE_TABLE_BLE_BEACON_2_6_1_TEMP);
    execSqlRequest(dbFile, COPY_TABLE_BLE_BEACON_DATA_INTO_BLE_BEACON_TEMP);
    execSqlRequest(dbFile, dropTable(TABLE_NAME_BLE_BEACONS));
    execSqlRequest(dbFile, renameTable(TABLE_NAME_BLE_BEACONS_TEMP, TABLE_NAME_BLE_BEACONS));
  }
};

function dropTable(tableName) {
  return "DROP TABLE " + tableName;
}

function renameTable(oldName, newName) {
  return "ALTER TABLE " + oldName + " RENAME TO " + newName;
}

/* *************************************************************** */
/* **************************** 2.8.0 **************************** */
/* *************************************************************** */

const COLUMN_PASSWORD_CERT = "password_cert";
const COLUMN_IDENTIFIANT_CERT = "identifiant_cert";
const COLUMN_CERTIFICATE = "certificate";

const PARAM_PTI_POST_SWITCH_SOS = "isPostSwitchSos";
const PARAM_PTI_PRE_SWITCH_SOS = "isPreSwitchSos";
const PARAM_PTI_PRE_LOUD_SPEAKER_POST_ALERT_SOS = "isLoudSpeakerPreAlertSos";
const PARAM_PTI_DISPLAY_POST_ALERT_SOS = "isDisplayPostAlertSos";
const PARAM_PTI_SOUND_POST_ALERT_SOS = "isSoundPostAlertSos";
const PARAM_PTI_POST_FLASH_POST_ALERT_SOS = "isFlashPostAlertSos";
const PARAM_PTI_POST_LOUD_SPEAKER_POST_ALERT_SOS = "isLoudSpeakerPostAlertSos";
const PARAM_PTI_AGRESSION_SOS = "isAgressionSos";

const PARAM_PTI_POST_SWITCH_ON_OFF = "isPostSwitchOnOff";
const PARAM_PTI_PRE_SWITCH_ON_OFF = "isPreSwitchOnOff";
const PARAM_PTI_PRE_LOUD_SPEAKER_POST_ALERT_ON_OFF = "isLoudSpeakerPreAlertOnOff";
const PARAM_PTI_DISPLAY_POST_ALERT_ON_OFF = "isDisplayPostAlertOnOff";
const PARAM_PTI_SOUND_POST_ALERT_ON_OFF = "isSoundPostAlertOnOff";

const PARAM_PTI_POST_LOUD_SPEAKER_POST_ALERT_ON_OFF = "isLoudSpeakerPostAlertOnOff";
const PARAM_PTI_POST_FLASH_POST_ALERT_ON_OFF = "isFlashPostAlertOnOff";

const PARAM_PTI_AGRESSION_ON_OFF = "isAgressionOnOff";

const PARAM_PTI_POST_SWITCH_POIGNET = "isPostSwitchWrist";
const PARAM_PTI_PRE_SWITCH_POIGNET = "isPreSwitchWrist";
const PARAM_PTI_PRE_LOUD_SPEAKER_POST_ALERT_POIGNET = "isLoudSpeakerPreAlertWrist";
const PARAM_PTI_DISPLAY_POST_ALERT_POIGNET = "isDisplayPostAlertWrist";
const PARAM_PTI_SOUND_POST_ALERT_POIGNET = "isSoundPostAlertWrist";
const PARAM_PTI_POST_LOUD_SPEAKER_POST_ALERT_POIGNET = "isLoudSpeakerPostAlertWrist";
const PARAM_PTI_AGRESSION_POIGNET = "isAgressionWrist";

const COLUMN_SOS_TYPE_BACKUP_ALARM = "sos_type";

const PARAM_PTI_UPDATE_BUTTON1_TEMPS_NOTIFICATION = "SOSButton1TempsNotification";
const PARAM_PTI_UPDATE_BUTTON2_TEMPS_NOTIFICATION = "SOSButton2TempsNotification";
const PARAM_PTI_UPDATE_WRIST_TEMPS_NOTIFICATION = "WristMouvementNotifTime";

const isPostSwitchSos = 1;
const isPostSwitchOnOff = 1;
const isPostSwitchWrist = 1;

let alarmSosLoud = undefined;
let alarmSosLoud2 = undefined;
let alarmSosLoud3 = undefined;

let alarmSoundLoud = undefined;
let alarmSoundLoud2 = undefined;
let alarmSoundLoud3 = undefined;

export const migrateTo_2_8_0 = dbFile => {
  console.log("Executing migration 2.8.0");

  if (getParameterPTIValue("SOSButton2DiscreetAlert") == 1) {
    alarmSosLoud = 0;
  } else {
    alarmSosLoud = 1;
  }
  if (getParameterPTIValue("SOSButton1DiscreetAlert") == 1) {
    alarmSosLoud2 = 0;
  } else {
    alarmSosLoud2 = 1;
  }
  if (getParameterPTIValue("isWristMouvementDiscretModeEnabled") == 1) {
    alarmSosLoud3 = 0;
  } else {
    alarmSosLoud3 = 1;
  }

  if (getParameterPTIValue("SOSButton2DiscreetAlert") == 1) {
    alarmSoundLoud = 1;
  } else {
    alarmSoundLoud = 0;
  }
  if (getParameterPTIValue("SOSButton1DiscreetAlert") == 1) {
    alarmSoundLoud2 = 1;
  } else {
    alarmSoundLoud2 = 0;
  }
  if (getParameterPTIValue("isWristMouvementDiscretModeEnabled") == 1) {
    alarmSoundLoud3 = 1;
  } else {
    alarmSoundLoud3 = 0;
  }

  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_WIFI, COLUMN_IDENTIFIANT_CERT, SQL_TYPE_TEXT, "''"));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_WIFI, COLUMN_PASSWORD_CERT, SQL_TYPE_TEXT, "''"));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_WIFI, COLUMN_CERTIFICATE, SQL_TYPE_INT, 0));

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_POST_SWITCH_SOS, isPostSwitchSos, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_PRE_SWITCH_SOS, alarmSosLoud, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_PRE_LOUD_SPEAKER_POST_ALERT_SOS, alarmSosLoud, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_DISPLAY_POST_ALERT_SOS, alarmSosLoud, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_SOUND_POST_ALERT_SOS, alarmSosLoud, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_POST_FLASH_POST_ALERT_SOS, alarmSosLoud, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_POST_LOUD_SPEAKER_POST_ALERT_SOS, alarmSosLoud, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_AGRESSION_SOS, alarmSoundLoud, PARAM_PTI_TYPE_BOOL));

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_POST_SWITCH_ON_OFF, isPostSwitchOnOff, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_PRE_SWITCH_ON_OFF, alarmSosLoud2, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_PRE_LOUD_SPEAKER_POST_ALERT_ON_OFF, alarmSosLoud2, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_DISPLAY_POST_ALERT_ON_OFF, alarmSosLoud2, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_SOUND_POST_ALERT_ON_OFF, alarmSosLoud2, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_POST_FLASH_POST_ALERT_ON_OFF, alarmSosLoud2, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_POST_LOUD_SPEAKER_POST_ALERT_ON_OFF, alarmSosLoud2, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_AGRESSION_ON_OFF, alarmSoundLoud2, PARAM_PTI_TYPE_BOOL));

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_POST_SWITCH_POIGNET, isPostSwitchWrist, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_PRE_SWITCH_POIGNET, alarmSosLoud3, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_PRE_LOUD_SPEAKER_POST_ALERT_POIGNET, alarmSosLoud3, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_DISPLAY_POST_ALERT_POIGNET, alarmSosLoud3, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_SOUND_POST_ALERT_POIGNET, alarmSosLoud3, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_POST_LOUD_SPEAKER_POST_ALERT_POIGNET, alarmSosLoud3, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_AGRESSION_POIGNET, alarmSoundLoud3, PARAM_PTI_TYPE_BOOL));

  //add backup column
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_BACKUP_ALARME, COLUMN_SOS_TYPE_BACKUP_ALARM, SQL_TYPE_INT, -1));

  //update
  execSqlRequest(dbFile, editNewPtiParamWithMaxMin(PARAM_PTI_UPDATE_BUTTON1_TEMPS_NOTIFICATION, PARAM_PTI_TYPE_INT, 3, 30));
  execSqlRequest(dbFile, editNewPtiParamWithMaxMin(PARAM_PTI_UPDATE_BUTTON2_TEMPS_NOTIFICATION, PARAM_PTI_TYPE_INT, 3, 30));
  execSqlRequest(dbFile, editNewPtiParamWithMaxMin(PARAM_PTI_UPDATE_WRIST_TEMPS_NOTIFICATION, PARAM_PTI_TYPE_INT, 3, 30));

  return true;
};
/* *************************************************************** */
/* **************************** 2.9.0 **************************** */
/* *************************************************************** */

const PARAM_PTI_LAST_LOC = "isLastLocEnabled";
const PARAM_PTI_BUTTON_WIFI_ALONE = "isUseWifiAlone";
const PARAM_PTI_UTC_TIME = "UtcTime";
const PARAM_PTI_UTC_TIME_ALL_CHARACTER = "UtcTimeAllCharacter";
const PARAM_PTI_UPDATE_TIME_OUT_CALL = "TimeOutCall";
const PARAM_PTI_SYSTEM_REACTIVED_ON_BOOT = "isSystemReactivatedOnBoot";

const EXTENDED_P_LINK_TEST_COLUMN_NAME = "p_securite_positive_link_test";
const PARAM_PTI_LINK_TEST = "isLinkTestEnabled";
const PARAM_PTI_LINK_TEST_RANGE = "linkTestRange";

const PARAM_PTI_PERMANENT_TRACKING = "isPermanentTracking";
const PARAM_PTI_ALERT_TRACKING = "isAlertTracking";
const PARAM_PTI_ALERT_TRACKING_RANGE = "alertTrackingRange";
const PARAM_PTI_ALERT_TRACKING_END_POST_ALERT = "isAlertTrackingEndPostAlert";
const PARAM_PTI_NUMBER_MESSAGE_TO_FINISH_ALERT_TRACKING = "numberOfMessagesToFinishAlertTracking";

const PARAM_PTI_SECURITE_POSITIVE_TEMPS_MINUTE = "SecuritePositiveTempsMinute";

export const migrateTo_2_9_0 = dbFile => {
  console.log("Executing migration 2.9.0");
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_BUTTON_WIFI_ALONE, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_UTC_TIME, "''", SQL_TYPE_TEXT));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_UTC_TIME_ALL_CHARACTER, "''", SQL_TYPE_TEXT));
  execSqlRequest(dbFile, editNewPtiParamWithMaxMin(PARAM_PTI_UPDATE_TIME_OUT_CALL, PARAM_PTI_TYPE_INT, 20, 60));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_SYSTEM_REACTIVED_ON_BOOT, 0, PARAM_PTI_TYPE_BOOL));

  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_TLS_IP, EXTENDED_P_LINK_TEST_COLUMN_NAME, SQL_TYPE_INT, 0));
  execSqlRequest(dbFile, sqlAddColumnToTable(TABLE_NAME_SUP_IP, EXTENDED_P_LINK_TEST_COLUMN_NAME, SQL_TYPE_INT, 0));

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_LAST_LOC, 0, PARAM_PTI_TYPE_BOOL));

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_LINK_TEST, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_PERMANENT_TRACKING, 1, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_ALERT_TRACKING, 0, PARAM_PTI_TYPE_BOOL));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_ALERT_TRACKING_END_POST_ALERT, 0, PARAM_PTI_TYPE_BOOL));

  execSqlRequest(dbFile, addNewPtiParamWithMaxMin(PARAM_PTI_NUMBER_MESSAGE_TO_FINISH_ALERT_TRACKING, 0, PARAM_PTI_TYPE_INT, 1, 999));

  execSqlRequest(dbFile, addNewPtiParamWithMaxMin(PARAM_PTI_LINK_TEST_RANGE, 60, PARAM_PTI_TYPE_INT, 10, 1440));
  execSqlRequest(dbFile, addNewPtiParamWithMaxMin(PARAM_PTI_ALERT_TRACKING_RANGE, 5, PARAM_PTI_TYPE_INT, 1, 15));

  execSqlRequest(dbFile, editNewPtiParamWithMaxMin(PARAM_PTI_SECURITE_POSITIVE_TEMPS_MINUTE, PARAM_PTI_TYPE_INT, 1, 1440));

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_CUSTOM_WIDGET_TOP_TEXT, "''", PARAM_PTI_TYPE_STRING));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_CUSTOM_WIDGET_BOTTOM_TEXT, "''", PARAM_PTI_TYPE_STRING));

  return true;
};

/* *************************************************************** */
/* **************************** 2.10.0 *************************** */
/* *************************************************************** */
const PARAM_PTI_NAME_RECIPIENT = "automaticCallNameRecipient";
const PARAM_PTI_PHONE_RECIPIENT = "automaticCallPhoneRecipient";
const PARAM_PTI_GET_TRIGGER = "onOffButtonTriggerType";
let index = undefined;

export const migrateTo_2_10_0 = dbFile => {
  console.log("Executing migration 2.10.0");
  if (getParameterPTIValue("SOSButton1") == 1) {
    index = 1;
  } else {
    index = 0;
  }
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_GET_TRIGGER, index, SQL_TYPE_INT));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_PHONE_RECIPIENT, "''", PARAM_PTI_TYPE_STRING));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_NAME_RECIPIENT, "''", PARAM_PTI_TYPE_STRING));

  return true;
};

/* *************************************************************** */
/* **************************** 2.11.0 *************************** */
/* *************************************************************** */

// const PARAM_PTI_NAME_RECIPIENT = "automaticCallNameRecipient";
// const PARAM_PTI_PHONE_RECIPIENT = "automaticCallPhoneRecipient";

const PARAM_PTI_TRANSPARENT = "isTransparentMode";
const PARAM_PTI_INDOOR_ENABLED = "isIndoorEnabled";
const TABLE_WIFI_TRANSPARENT = "wifi_transparent";
const TABLE_BLE_TRANSPARENT = "ble_transparent";

const COLUMN_UUID = "uuid_filter";
const COLUMN_SSID_FILTER = "ssid_filter";
const BLE_BEACON_PRORITY_RSSI = "isBleBeaconPriorityOverWifiBeacon";

const TABLE_NAME_RSSI_STRENGHT_BLE = "rssi_strenght_ble";
const TABLE_NAME_RSSI_STRENGHT_WIFI = "rssi_strenght_wifi";

const COLUMN_STRENGHT_POWER = "strenght_power";
const COLUMN_VALUE = "value";

const STRENGHT_LOW = "low";
const STRENGHT_MEDIUM = "medium";
const STRENGHT_GOOD = "good";

const VALUE_BLE_STRENGHT_LOW = -95;
const VALUE_BLE_STRENGHT_MEDIUM = -84;
const VALUE_BLE_STRENGHT_GOOD = -70;
const VALUE_WIFI_STRENGHT_LOW = -76;
const VALUE_WIFI_STRENGHT_MEDIUM = -66;
const VALUE_WIFI_STRENGHT_GOOD = -50;

const SQL_CREATE_WIFI_TRANSPARENT =
  "create table " + TABLE_WIFI_TRANSPARENT + " (" + COLUMN_ID + " integer primary key autoincrement, " + COLUMN_SSID_FILTER + " text" + ")";

const SQL_CREATE_BLE_TRANSPARENT =
  "CREATE TABLE " +
  TABLE_BLE_TRANSPARENT +
  " (" +
  COLUMN_ID +
  " INTEGER PRIMARY KEY AUTOINCREMENT, " +
  COLUMN_UUID +
  " TEXT CHECK(length(" +
  COLUMN_UUID +
  ") <= 34))";

const SQL_CREATE_RSSI_STRENGHT_BLE =
  "create table " +
  TABLE_NAME_RSSI_STRENGHT_BLE +
  " (" +
  COLUMN_ID +
  " integer primary key autoincrement, " +
  COLUMN_STRENGHT_POWER +
  " text, " +
  COLUMN_VALUE +
  "  integer default 0" +
  ")";

const SQL_CREATE_RSSI_STRENGHT_WIFI =
  "create table " +
  TABLE_NAME_RSSI_STRENGHT_WIFI +
  " (" +
  COLUMN_ID +
  " integer primary key autoincrement, " +
  COLUMN_STRENGHT_POWER +
  " text," +
  COLUMN_VALUE +
  "  integer default 0" +
  ")";

const INSERT_STRENGHT_VALUES_BLE =
  "insert into " +
  TABLE_NAME_RSSI_STRENGHT_BLE +
  " ( " +
  COLUMN_ID +
  "," +
  COLUMN_STRENGHT_POWER +
  "," +
  COLUMN_VALUE +
  ") values" +
  "(1,'" +
  STRENGHT_GOOD +
  "','" +
  VALUE_BLE_STRENGHT_GOOD +
  "')," +
  "(2,'" +
  STRENGHT_MEDIUM +
  "','" +
  VALUE_BLE_STRENGHT_MEDIUM +
  "')," +
  "(3,'" +
  STRENGHT_LOW +
  "','" +
  VALUE_BLE_STRENGHT_LOW +
  "');";

const INSERT_STRENGHT_VALUES_WIFI =
  "insert into " +
  TABLE_NAME_RSSI_STRENGHT_WIFI +
  " ( " +
  COLUMN_ID +
  "," +
  COLUMN_STRENGHT_POWER +
  "," +
  COLUMN_VALUE +
  ") values" +
  "(1,'" +
  STRENGHT_GOOD +
  "','" +
  VALUE_WIFI_STRENGHT_GOOD +
  "')," +
  "(2,'" +
  STRENGHT_MEDIUM +
  "','" +
  VALUE_WIFI_STRENGHT_MEDIUM +
  "')," +
  "(3,'" +
  STRENGHT_LOW +
  "','" +
  VALUE_WIFI_STRENGHT_LOW +
  "');";
let i = undefined;

export const migrateTo_2_11_0 = dbFile => {
  console.log("Executing migration 2.11.0");

  if (getParameterPTIValue("isWifiLocParamEnabled") == 1 || getParameterPTIValue("isBluetoothLocParamEnabled") == 1) {
    i = 1;
  } else {
    i = 0;
  }

  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_TRANSPARENT, 0, SQL_TYPE_INT));
  execSqlRequest(dbFile, addNewPtiParam(PARAM_PTI_INDOOR_ENABLED, i, SQL_TYPE_INT));
  execSqlRequest(dbFile, SQL_CREATE_WIFI_TRANSPARENT);
  execSqlRequest(dbFile, SQL_CREATE_BLE_TRANSPARENT);
  execSqlRequest(dbFile, addNewPtiParam(BLE_BEACON_PRORITY_RSSI, 1, SQL_TYPE_INT));
  execSqlRequest(dbFile, SQL_CREATE_RSSI_STRENGHT_BLE);
  execSqlRequest(dbFile, SQL_CREATE_RSSI_STRENGHT_WIFI);
  execSqlRequest(dbFile, INSERT_STRENGHT_VALUES_BLE);
  execSqlRequest(dbFile, INSERT_STRENGHT_VALUES_WIFI);

  return true;
};

const BLE_SCAN_MODE = "bleScanMode";
const WAIT_TRAITEMENT_FOR_BLE_SCAN= "isWaitTraitementForBleScan";


export const migrateTo_2_12_0 = dbFile => {
  console.log("Executing migration 2.12.0");

  execSqlRequest(dbFile, addNewPtiParam(BLE_SCAN_MODE, 0, SQL_TYPE_INT));
  execSqlRequest(dbFile, addNewPtiParam(WAIT_TRAITEMENT_FOR_BLE_SCAN, 1, SQL_TYPE_INT));
  

  return true;
};

/* *************************************************************** */
/* **************************** utils **************************** */
/* *************************************************************** */

export const execSqlRequest = (dbFile, sqlRequest) => {
  if (sqlRequest === undefined) {
    console.log("SQL request not executed !  ");
    return false;
  }
  try {
    dbFile.exec(sqlRequest);
  } catch (error) {
    console.log("SQL request failed: " + error + "\nRequest >>> " + sqlRequest);
    return false;
  }
  return true;
};

function sqlAddColumnToTable(tableName, columnName, valueType, defaultValue) {
  const addDefaultValue = defaultValue != undefined ? " default " + defaultValue : "";
  return "Alter table " + tableName + " ADD Column " + columnName + " " + valueType + addDefaultValue;
}
function addNewPtiParamWithMaxMin(name, value, type, val_min, val_max) {
  if (getParameterPTIValue(name) === undefined) {
    return (
      "Insert into " +
      TABLE_NAME_PARAMETRE_PTI +
      " (" +
      COLUMN_PARAM +
      "," +
      COLUMN_VALEUR +
      "," +
      COLUMN_TYPE +
      "," +
      COLUMN_VAL_MIN +
      "," +
      COLUMN_VAL_MAX +
      "," +
      COLUMN_COMMENTAIRE +
      ') values("' +
      name +
      '",' +
      value +
      ',"' +
      type +
      '",' +
      val_min +
      "," +
      val_max +
      ",NULL)"
    );
  } else {
    console.log("param already present script not executed for paramName = " + name);
    return undefined;
  }
}
function editNewPtiParamWithMaxMin(name, type, val_min, val_max) {
  if (getParameterPTIValue(name) !== undefined) {
    return (
      "UPDATE " +
      TABLE_NAME_PARAMETRE_PTI +
      " SET " +
      COLUMN_TYPE +
      ' = "' +
      type +
      '", ' +
      COLUMN_VAL_MIN +
      " = " +
      val_min +
      ", " +
      COLUMN_VAL_MAX +
      " = " +
      val_max +
      " WHERE " +
      COLUMN_PARAM +
      ' = "' +
      name +
      '"'
    );
  } else {
    console.log("param not present script not executed for paramName = " + name);
    return undefined;
  }
}

function addNewPtiParam(name, value, type) {
  if (getParameterPTIValue(name) === undefined) {
    return (
      "Insert into " +
      TABLE_NAME_PARAMETRE_PTI +
      " (" +
      COLUMN_PARAM +
      "," +
      COLUMN_VALEUR +
      "," +
      COLUMN_TYPE +
      "," +
      COLUMN_VAL_MIN +
      "," +
      COLUMN_VAL_MAX +
      "," +
      COLUMN_COMMENTAIRE +
      ') values("' +
      name +
      '",' +
      value +
      ',"' +
      type +
      '",NULL,NULL,NULL)'
    );
  } else {
    console.log("param already present script not executed for paramName = " + name);
    return undefined;
  }
}

function isColumnExist(dbFile, tableName, coloumnName) {
  const result = dbFile.exec("PRAGMA table_info(" + tableName + ")");
  const rows = result[0].values;

  let isColumnExist = false;
  for (let row of rows) {
    if (row[1] === coloumnName) {
      console.log("param exist !!");
      isColumnExist = true;
      break;
    }
  }

  return isColumnExist;
}
