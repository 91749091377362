<template>
  <!-- BEGIN: ModalAddModifyRecipientWhiteList local component-->
  <div>
    <div
      :id="modalId"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ $t(getModalTitle) }}
            </h2>
          </div>
          <div class="modal-body">
            <CustomTextInput
              v-for="(field, index) in inputFields"
              :key="index"
              :ref="'customTextInput' + index"
              v-model="field.value"
              :title="field.title"
              :error-msg="field.errorMsg"
              class="mb-3"
            />
          </div>
          <div class="mt-5 modal-footer text-right">
            <ButtonDismissModal
              ref="leftButton"
              custom-class="btn btn-outline-secondary mr-1"
              text="common.menu_cancel"
            />
            <CustomButton
              text="common.menu_confirm"
              @click="saveForm"
            />
          </div>
        </div>
      </div>
    </div>
    <CustomToast
      ref="toastAddRecipientWhiteListSuccess"
      toast-id="toast-add-recipient-white-list-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="whitelist.menu_recipient_whitelist_added_with_success"
    />
    <CustomToast
      ref="toastModifyRecipientWhiteListSuccess"
      toast-id="toast-modify-recipient-white-list-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="whitelist.menu_recipient_whitelist_modified_with_success"
    />
  </div>
  <!-- END: ModalAddModifyRecipientWhiteList local component-->
</template>

<script>
import { defineComponent } from 'vue'
import { checkFieldIsNotEmpty, resetAllError, resetAllFields, resetError, setError } from '../../../utils/fieldManagement';
import { getWhitelistRecipientId } from '../../../dao/whitelist-dao'


const EV_ADD_RECIPIENT_WHITE_LIST = "add-recipient-white-list";
const EV_MODIFY_RECIPIENT_WHITE_LIST = "modify-recipient-white-list";

const I18_MODAL_TITLE_ADD_RECIPIENT_WHITELIST = "whitelist.menu_add_a_recipient_whitelist";
const I18_MODAL_TITLE_MODIFY_RECIPIENT_WHITELIST = "whitelist.menu_modify_a_recipient_whitelist";

const I18_TITLE_NAME = 'internalrecipients.menu_name';
const I18_TITLE_PHONE_NUMBER = 'internalrecipients.menu_call_number';
const I18_ERR_EMPTY_FIELD = "common.menu_field_cannot_be_empty";
const I18_ERR_PHONE_NB_ALREADY_EXIST = "common.menu_phone_number_already_used";
const I18_ERR_PHONE_NB_MUST_START_WITH_PLUS = "common.menu_phone_number_must_start_with_+";

const INDEX_NAME = 0;
const INDEX_PHONE_NB = 1;


export default defineComponent({

  props: {
    modalId: {
      type: String,
      required: true,
    },
    recipientWhiteList: {
      type: Array,
      required: true,
    }
  },

  emits: [
    EV_ADD_RECIPIENT_WHITE_LIST,
    EV_MODIFY_RECIPIENT_WHITE_LIST,
  ],

  data() {
    return {
      inputFields: [
        { 
          title: I18_TITLE_NAME, 
          value: '', 
          errorMsg: '' 
        },
        { 
          title: I18_TITLE_PHONE_NUMBER, 
          value: '', 
          errorMsg: '' 
        },
      ],
      edit: false,
      currentIndex: undefined,
      dbId: undefined,
    }
  },
  
  computed: {
    getModalTitle() {
    if (this.edit)
      return I18_MODAL_TITLE_MODIFY_RECIPIENT_WHITELIST;
    return I18_MODAL_TITLE_ADD_RECIPIENT_WHITELIST;
    },
  },

  methods: {
    saveForm() {
      if (!this.checkForm())
        return ;      
      let tmpRecipient = this.createRecipient();
      let eventName = this.edit ? EV_MODIFY_RECIPIENT_WHITE_LIST : EV_ADD_RECIPIENT_WHITE_LIST;
      this.$emit(eventName, tmpRecipient);
      this.triggerToast();
      this.$refs.leftButton.click();
    },

    checkForm() {
      const error = checkFieldIsNotEmpty(this.inputFields, INDEX_NAME, I18_ERR_EMPTY_FIELD);
      const phoneNb = this.inputFields[INDEX_PHONE_NB].value; 
      if (phoneNb.length < 2 || phoneNb[0] != '+')
        return setError(this.inputFields, INDEX_PHONE_NB, I18_ERR_PHONE_NB_MUST_START_WITH_PLUS);
      else {
        for (let i = 1; i < phoneNb.length; ++i)
          if (phoneNb[i] < '0' || phoneNb[i] > '9')
            return setError(this.inputFields, INDEX_PHONE_NB, I18_ERR_PHONE_NB_MUST_START_WITH_PLUS);
        for (let i = 0; i < this.recipientWhiteList.length; ++i) {
          if (i != this.currentIndex &&
              this.recipientWhiteList[i][INDEX_PHONE_NB].valueOf()
              === phoneNb.valueOf())
          return setError(this.inputFields, INDEX_PHONE_NB, I18_ERR_PHONE_NB_ALREADY_EXIST)
        }
        resetError(this.inputFields, INDEX_PHONE_NB);
      }
      return error;
    },

    createRecipient() {
      return {
        id: this.dbId,
        recipient: [
          this.inputFields[INDEX_NAME].value,
          this.inputFields[INDEX_PHONE_NB].value,
        ]
      };
    },

    triggerToast() {
      if (this.edit)
        this.$refs.toastModifyRecipientWhiteListSuccess.trigger();
      else
        this.$refs.toastAddRecipientWhiteListSuccess.trigger();
    },

    initAdd() {
      this.edit = false;
      resetAllFields(this.inputFields);
      this.dbId = -1;
    },

    initEdit(index) {
      this.currentIndex = index;
      this.edit = true;
      this.dbId = getWhitelistRecipientId(this.recipientWhiteList[index][INDEX_PHONE_NB]);
      for (let i = 0; i < this.inputFields.length; ++i)
        this.inputFields[i].value = this.recipientWhiteList[index][i];
      resetAllError(this.inputFields);
    },

    dismiss() {
      this.$refs.leftButton.click();
    }
  }

})
</script>
