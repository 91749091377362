<template>
  <!-- BEGIN: CustomTextInput component-->
  <div>
    <label 
      v-if="title.length"
      :class="getTitleClass"
    >
      {{ $t(title) }}
    </label>
    <div class="input-group mt-1">
      <input
        :value="modelValue"
        :type="getInputType"
        :maxlength="maxLength"
        :class="inputFieldClass"
        @input="$emit('update:modelValue', $event.target.value)"
      >
      <component
        :is="getIcon" 
        v-if="isPasswordField"
        class="btn bg-gray-100 ml-3 h-auto w-auto tooltip"
        @click="hidePassword = !hidePassword"
      />
    </div>
    <p 
      v-if="errorMsg.length != 0"
      class="mt-1"
    >
      {{ $t(errorMsg) }}
    </p>
  </div>
  <!-- END: CustomTextInput component-->
</template>

<script>
import { defineComponent } from 'vue'


const ICON_DISPLAY_PASSWORD = "EyeOffIcon";
const ICON_HIDE_PASSWORD = "EyeIcon";

const INPUT_TYPE_PASSWORD = "password";
const INPUT_TYPE_TEXT = "text";

const TITLE_CLASS_BOLD = "font-bold";


/**
 * -------------------------- DESCRIPTION ---------------------------
 * A text input field with a title and an error message in case the 
 * input is wrong. v-model MUST be used on this component, the value of
 * the input field will be automatically bind to the parent variable.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} modelValue
 *    Value of the input field. Automatically sets when using v-model on this
 *    component. For example, v-model="parentVariable" will bind this 
 *    modelValue to parentVariable. Will be initialized with parent variable
 *    value.
 * 
 * @param {String} title
 *    The title above the input field. Should be i18 key for the locale 
 *    (ex: 'common.menu_save'). Will not appear if empty.
 * 
 * @param {Boolean} boldTitle
 *    If true, title will be displayed in bold. Classic text otherwise.
 *    True by default, fill this props only if you want title displayed
 *    as classic text.
 * 
 * @param {String} inputFieldClass
 *    A custom class that can override default input field class. Do not 
 *    fill this props if you're ok with default style.  
 * 
 * @param {Boolean} isPasswordField
 *    When true, the input field will be treated as a password field and
 *    the information will be hided. An eye icon will appear allowing
 *    to display the password typed if needed. When false, the field
 *    will act as a normal text input field. False by default, do not fill
 *    this props if password feature is not needed.
 * 
 * @param {Number} maxLength
 *    The maximum number of carac authorized for the input field.
 *    50 by default.
 * 
 * @param {String} errorMsg
 *    If empty, nothing appears. Otherwise, this string will appear 
 *    as an error in red to the user. Should be key for i18 locale.
 *    Empty by default, if no error management is needed do not fill it.
 * 
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits update:modelValue
 * @return {String}
 *    Emits the value of the text input field. When v-model is
 *    used on this component, v-model automatically update with this
 *    value. A data check should be performed on this string, and if 
 *    this one isn't corret errorMsg should be set with the appropriate 
 *    error message to inform the user.
 *              
 * ---------------- METHODS ACCESSIBLE USING REFS -------------------
 * @method resetHidePassword
 * @param void
 *    When isPasswordField props is set to true, calling this method will
 *    hide again the password if this one was displayed.
 *    (ex: this.$refs.xxx.resetHidePassword()).
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    boldTitle: {
      type: Boolean,
      default: true,
    },
    inputFieldClass: {
      type: String,
      default: "form-control w-full text-left",
    },
    isPasswordField: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 50,
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      hidePassword: true,
    }
  },

  computed: {
    getInputType() {
      if (this.isPasswordField && this.hidePassword)
        return INPUT_TYPE_PASSWORD;
      return INPUT_TYPE_TEXT;
    },

    getIcon() {
      if (this.hidePassword)
        return ICON_DISPLAY_PASSWORD;
      return ICON_HIDE_PASSWORD;
    },

    getTitleClass() {
      if (this.boldTitle)
        return TITLE_CLASS_BOLD;
      return "";
    }
  },

  methods: {
    resetHidePassword() {
      this.hidePassword = true;
    }
  }
})
</script>
