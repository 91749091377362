<template>
  <!-- BEGIN: ImportExportWifiHotspot local component -->
  <div class="intro-y lg:col-span-5 lg:col-start-6 2xl:col-span-4 2xl:col-start-5 col-span-12">
    <BoxTitle
      color="bg-red-300"
      title="locationindoorwifi.menu_enable_import_export_csv"
    />
    <div class="clearfix box mb-6">
      <div class="clearfix p-3 border-b border-gray-200">
        <CustomButton
          text="locationindoorwifi.menu_download_excel_file_template_for_importation"
          custom-class="btn btn-warning"
          @click="downloadTemplate()"
        />
        <div>
          <ButtonTargetModal
            v-if="hotspotList.length"
            target-id="#modal-alert-confirm-csv-upload"
            text="locationindoorwifi.menu_import_a_new_wifi_hotspot_file"
            custom-class="btn btn-warning mt-4"
          />
          <ButtonUploadFile
            v-else
            text="locationindoorwifi.menu_import_a_new_wifi_hotspot_file"
            accept=".csv"
            custom-class="btn btn-warning mt-4"
            @file-uploaded="checkCsvFile($event[0])"
          />
        </div>
        <CustomButton
          v-if="hotspotList.length"
          text="locationindoorwifi.menu_export_to_csv_wifi_configuration"
          custom-class="btn btn-dark mt-4"
          @click="exportBeaconsList()"
        />
        <p 
          v-if="errorMsg.length"
          class="mt-2"
        >
          {{ errorMsg }}
        </p>
      </div>
    </div>
    <CustomToast
      ref="toastSuccess"
      toast-id="toast-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="locationindoorwifi.menu_import_succeeded"
    />
    <CustomToast
      ref="toastFailed"
      toast-id="toast-failed"
      icon-component-name="XCircleIcon"
      icon-component-class="text-theme-6"
      text="locationindoorwifi.menu_error_while_importing"
    />
    <ModalAlertConfirmChoice
      ref="modalAlertConfirmCsvUpload"
      modal-id="modal-alert-confirm-csv-upload"
      alert-title="common.menu_are_you_sure"
      alert-text="locationindoorwifi.menu_import_will_replace_existing_list"
      icon-component-name="AlertTriangleIcon"
      left-button-component-name="ButtonDismissModal"
      left-button-text="common.menu_cancel"
      left-button-custom-class="btn btn-outline-secondary mr-1"
      right-button-component-name="ButtonUploadFile"
      right-button-text="locationindoorwifi.menu_confirm_import"
      right-button-custom-class="btn btn-danger"
      @change="checkCsvFile($event.target.files[0])"
      @click="$refs.modalAlertConfirmCsvUpload.dismiss()"
    />
  </div>
  <!-- END: ImportExportWifiHotspot local component -->
</template>

<script>
import { defineComponent } from 'vue'
import { formatLevel } from './utils'
import papa from "papaparse";
import { regexCheck } from '../../../utils/fieldManagement'


const TEMPLATE_URL = 'https://www.dropbox.com/scl/fi/22ih0oxifxd4i7i66pv6t/wifi_beacon_list_template.xlsx?dl=1&rlkey=6eeh01w3c6p9rj1u8n7gtkssn';

const SSID = 0;
const BSSID = 1;
const CODE_ZONE = 2;
const DESC = 3;
const LEVEL = 4;

const WRONG_CODE_ZONE = 0;

const HEADERS = ["SSID","BSSID","Code_zone","Description","Level"];

const WRONG_FILE_FORMAT = "common.menu_wrong_flie_format";
const ERROR_DETECTED_LINE = "locationindoorwifi.menu_an_error_was_detected_line";
const CHECK_EXCEL_FILE = "locationindoorwifi.menu_check_your_excel_file";
const EXCEL_FILE_IS_EMPTY = "locationindoorwifi.menu_excel_file_is_empty";
const INCORRECT_HEADERS = "locationindoorwifi.menu_incorrect_column_headers";

const FILENAME = "exportedWifiBeacons.csv";

const EV_IMPORT_SUCCESS = 'import-success';

const VALID_HEADER_FIELDS = [
  "SSID",
  "BSSID",
  "Code_zone",
  "Description",
  "Level",
];


export default defineComponent({

  props: {  
    hotspotList: {
      type: Array,
      required: true,
    }
  }, 

  emits : [EV_IMPORT_SUCCESS],

  data(){
    return {
      errorMsg : "",
      wifiBeaconsList: undefined,
    }
  },

  methods: {

    dismissModal() {
      this.$refs.modalAlertConfirmCsvUpload.dismiss();
    },

    downloadTemplate() {
      if (this.errorMsg.length)
        this.errorMsg = '';
      window.open(TEMPLATE_URL);
    },

    setError(str, parser) {
      this.errorMsg = str;
      this.$refs.toastFailed.trigger();
      if (parser)
        parser.abort();
      return false;
    },

    checkCsvFile(csvFile) {
      if (this.errorMsg.length)
        this.errorMsg = '';
      if (!csvFile.name.endsWith(".csv"))
        this.setError(this.$t(WRONG_FILE_FORMAT))
      else
        this.parseCsvFile(csvFile);
    }, 

    parseCsvFile(csvFile) {
      let isHeaderFieldsChecked = false;
      let line = 1;
      this.wifiBeaconsList = new Array();

      papa.parse(csvFile, {
        worker: true,
        header: true,
        
        step: (row, parser) => {
          if (!isHeaderFieldsChecked) {
            if (!this.checkHeaderFields(parser, row.meta.fields))
              return ;
            isHeaderFieldsChecked = true;
          }
          if(this.emptyLine(row.data))
            parser.abort();
          else {
            ++line;
            this.checkRow(parser, row.data, line);
          }
        },
        complete: () => {
          if (!this.errorMsg.length)
            this.onCsvParseSuccess();
        },
      });
    },

    emptyLine(data) {
      if (data.SSID == undefined)
        return true;
      return (!data.SSID.length &&
          (!data.BSSID ||
          !data.Code_zone ||
          !data.Description ||
          !data.Level));
    },

    checkRow(parser, data, line) {
      if (this.checkBeacon(data) && !this.beaconAlreadyExisting(data))
          this.wifiBeaconsList.push(this.createWifiBeacon(data)); 
      else
        this.setError(this.$t(ERROR_DETECTED_LINE) 
            + line 
            + this.$t(CHECK_EXCEL_FILE),
            parser);
    },
    
   checkBeacon(data){
     return(
      (data.SSID.length >= 1 && data.SSID.length <= 50) &&
      (regexCheck(data.BSSID, /^([a-z A-Z0-9]{2}:){5}[a-z A-Z0-9]{2}$/)) &&
      (regexCheck(data.Code_zone, /^[0-9]{1,3}$/) && (Number(data.Code_zone) != WRONG_CODE_ZONE)) &&
      (data.Description.length >= 1 && data.Description.length <= 50) &&
      (regexCheck(data.Level, /^-?[0-9]{1,2}$/) || regexCheck(data.Level, /^$/))
     )
    },

    beaconAlreadyExisting(data) {
      for (let i = 0; i < this.wifiBeaconsList.length; ++i)
        if (this.wifiBeaconsList[i][BSSID] === data.BSSID)
          return true;
      return false;
    },

    createWifiBeacon(data){
      let currentBeacon = new Array(5);
      currentBeacon[SSID] = data.SSID;
      currentBeacon[BSSID] = data.BSSID.toUpperCase();
      currentBeacon[CODE_ZONE] = this.addPaddingZeros(data.Code_zone);
      currentBeacon[DESC] = data.Description;
      currentBeacon[LEVEL] = formatLevel(data.Level);
      return currentBeacon;
    },

    addPaddingZeros(strCodeZone) {
      while (strCodeZone.length < 3)
        strCodeZone = '0' + strCodeZone;
      return strCodeZone;
    },

    onCsvParseSuccess() {
      if (this.wifiBeaconsList.length) {
        this.$refs.toastSuccess.trigger();
        this.$emit(EV_IMPORT_SUCCESS,this.wifiBeaconsList);
      }
      else
        this.setError(this.$t(EXCEL_FILE_IS_EMPTY) 
            + this.$t(CHECK_EXCEL_FILE));
    },

    checkHeaderFields(parser, headerFields) {
      if (!this.isValidCsvFileHeader(headerFields))
        return this.setError(this.$t(INCORRECT_HEADERS), parser);
      return true;
    },
    
    isValidCsvFileHeader(headerFields) {
      return (
        headerFields[0] === VALID_HEADER_FIELDS[0] &&
        headerFields[1] === VALID_HEADER_FIELDS[1] &&
        headerFields[2] === VALID_HEADER_FIELDS[2] &&
        headerFields[3] === VALID_HEADER_FIELDS[3] &&
        headerFields[4] === VALID_HEADER_FIELDS[4]
      );
    },

    exportBeaconsList(){
      if (this.errorMsg.length)
        this.errorMsg = '';
      let tmpList = this.hotspotList.slice();
      tmpList.splice(0, 0, HEADERS);
      let csvContent = "data:text/csv;charset=utf-8," 
          + tmpList.map(e => e.join(";")).join("\n");
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", FILENAME);
      document.body.appendChild(link); 
      link.click();   
    }

  },
  
})
</script>
