/* eslint-disable */
<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-6">
      <div class="intro-y col-span-12 lg:col-span-6">
        <p 
          style="color:black"
          class="font-medium"
        >
          <a
            class="font-medium text-blue-600"
            :href="$t('contact.menu_contact_link')"
            target="_blank"
          >
            {{ $t("contact.menu_online_support_request_form") }}
          </a>
          <br>
          <br>
          {{ $t("contact.menu_technical_support_email") }}<br>
          <a
            class="font-medium text-blue-600"
            href="mailto:technique@magneta.fr?subject=Demande%20de%20support%20en%20provenance%20de%20Emerit-console"
          >
            technique@magneta.fr
          </a>
          <br>
          <br>
          MAGNETA S.A.S.<br>
          189 rue d'Aubervilliers<br>
          75018 Paris - France<br>
          www.magneta.fr<br>
          Tél. : +33 (0)1 40 36 02 65
          <br>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {};
</script>
