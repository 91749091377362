<template>
   <div class="intro-y col-span-12 lg:col-span-6 lg:col-start-7 2xl:col-span-5 2xl:col-start-6">
    <div class="clearfix box mb-6">
      <BoxTitle title="locationindoorwifi.menu_add_ssid_filter" color="bg-red-300" />
      <div class="p-3">
        <Tippy
          v-for="(field, index) in getHotspotFields"
          :key="index"
          :content="$t(field.tippy)"
          :options="{
            theme: 'light',
            delay: index,
            onShow: updateTippyContent
          }"
          :class="field.class"
        >
          <CustomTextInput v-model="field.value" :title="field.title" :max-length="field.len" @input="handleInput(field)" />
        </Tippy>
        <div class="text-right">
          <CustomButton
            custom-class="lg:mt-6 col-start-9 col-span-2 btn btn-primary mb-2 mt-4"
            text="common.menu_add"
            @click="check()"
          />
          <ErrorMsg
            v-for="(field, index) in getHotspotFields"
            :key="index"
            :error-msg="field.errorMsg"
            class="col-start-1 col-span-10"
          />
        </div>
      </div>
    </div>
    <CustomToast
      ref="toastAddSSIDFilteredSuccess"
      toast-id="toast-add-ssid-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="locationindoorwifi.menu_add_hotspot_success"
    />

  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getTransparentWifiBeacons } from "../../../dao/transparent-indoor-wifi-dao";

const ERR_SSID = "locationindoorwifi.menu_err_empty_ssid";
const I18_SSID = "locationindoorwifi.menu_hotspot_ssid";
const EV_ADD_BEACON_FILTERED_SUCCESS = "addNewHotspot";
const SSID = 0;
const SSID_TIPPY = "locationindoorwifi.menu_tippy_ssid";

const SSID_TABLE_COMPONENT_INDEX = 0;

// let id = 0;
const ERR_SSID_ALREADY_EXIST = "locationindoorwifi.menu_err_ssid_already_exist";


export default defineComponent({
  props: {
    ssidFilteredList: {
      type: Array,
      required: true
    },
    isBleLevelActivated: {
      type: Boolean,
      required: true
    }
  },

  emits: [EV_ADD_BEACON_FILTERED_SUCCESS],

  data() {
    return {
      ssidField: [{ title: I18_SSID, value: "", tippy: SSID_TIPPY, maxLen: 50, errorMsg: "" }]
    };
  },
  computed: {
    getHotspotFields() {
      const len = this.isBleLevelActivated ? this.ssidField.length : this.ssidField.length - 1;
      let newSsidFileterdFields = new Array(len);
      for (let i = 0, j = 0; i < this.ssidField.length; ++i, ++j) {
        newSsidFileterdFields[j] = this.ssidField[i];
      }
      return newSsidFileterdFields;
    }
  },

  methods: {
    updateTippyContent(instance) {
      const index = instance.props.delay;
      instance.setContent(this.$t(this.getHotspotFields[index].tippy));
    },
    handleInput(field) {
      //field.value = field.value.toUpperCase();
    },

    check() {
      let error = this.ssidField.length;
      error -= this.checkSsid();
      if (!error) this.emitAddSSIDFilteredSuccess();
    },
    emitAddSSIDFilteredSuccess() {
      this.$emit("add-beacon-filtered-success", this.createNewHotspot());
      this.$refs.toastAddSSIDFilteredSuccess.trigger();
      this.resetAllFields();
    },

    createNewHotspot() {
      let newHotspot = new Array(this.ssidField.length);
      newHotspot[SSID_TABLE_COMPONENT_INDEX] = this.ssidField[SSID].value;
      return newHotspot;
    },

    checkSsid() {
      const ssid = this.ssidField[SSID].value;
      if (ssid.length < 1 || ssid.length > 50) 
        return this.setError(SSID, ERR_SSID);
      for (let i = 0; i < this.ssidFilteredList.length; ++i) {
        const existingSSID = this.ssidFilteredList[i][SSID];
        if (existingSSID && typeof existingSSID === 'string' && existingSSID === ssid) {
          return this.setError(SSID, ERR_SSID_ALREADY_EXIST);
        }
      }

        return this.resetError(SSID);
    },

    setError(index, err) {
      this.ssidField[index].errorMsg = err;
      return false;
    },

    resetAllFields() {
      for (let i = 0; i < this.ssidField.length; ++i) this.ssidField[i].value = "";
    },

    resetAllError() {
      for (let i = 0; i < this.ssidField.length; ++i) this.resetError(i);
      return true;
    },

    resetError(index) {
      if (this.ssidField[index].errorMsg.length) this.ssidField[index].errorMsg = "";
      return true;
    }
  }
});
</script>
