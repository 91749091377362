import { useStore } from "@/store";

export const getParameterPTIValue = parameter => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'select * from parametre_pti where param = "' + parameter + '"';
  try {
    return dbFile.exec(sqlScript)[0].values[0][2];
  } catch (error) {
    console.log('getParameterPTIValue for ' + parameter + ' failed: ' + error);
  }
};

export const getDeviceTargetParameterPTIValue = parameter => {
  var dbFile = useStore().state.emeritValues.targetDbFile;
  let sqlScript =
    'select * from parametre_pti where param = "' + parameter + '"';
  try {
    return dbFile.exec(sqlScript)[0].values[0][2];
  } catch (error) {
    console.log("getDeviceTargetParameterPTIValue failed: " + error);
  }
};

export const setParameterPTIValue = (parameter, value) => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'update parametre_pti set valeur = "' +
    value +
    '" where param="' +
    parameter +
    '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("setParameterPTIValue failed: " + error);
  }
};

export const setTargetDeviceParameterPTIValue = (parameter, value) => {
  var dbFile = useStore().state.emeritValues.targetDbFile;
  let sqlScript =
    'update parametre_pti set valeur = "' +
    value +
    '" where param="' +
    parameter +
    '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("setTargetDeviceParameterPTIValue failed: " + error);
  }
};

export const getParameterPTIMin = parameter => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'select * from parametre_pti where param = "' + parameter + '"';
  try {
    return dbFile.exec(sqlScript)[0].values[0][4];
  } catch (error) {
    console.log("getParameterPTIMin failed: " + error);
  }
};

export const getParameterPTIMax = parameter => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'select * from parametre_pti where param = "' + parameter + '"';
  try {
    return dbFile.exec(sqlScript)[0].values[0][5];
  } catch (error) {
    console.log("getParameterPTIMax failed: " + error);
  }
};

export const getNumberPtiValue = ptiParam => {
  return Number(getParameterPTIValue(ptiParam));
};

export const getBoolPtiValue = ptiParam => {
  return Boolean(getNumberPtiValue(ptiParam));
};

export const setNumberPtiValue = (ptiParam, value) => {
  setParameterPTIValue(ptiParam, Number(value));
};
