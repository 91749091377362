/* eslint-disable */

<template>
  <div>
    <div class="intro-y flex items-center mt-6">
      <h2 class="text-lg font-medium mr-auto">
        {{ $t("internalrecipientssettings.menu_internal_recipients_settings") }}
      </h2>
    </div>

    <!-- BEGIN: local-settings -->
    <div class="grid grid-cols-12 gap-6 mt-6">
      <!-- BEGIN: left column -->
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: choix de la langue SMS -->
        <div
          class="flex flex-col sm:flex-row p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
        >
          <h2 class="font-medium text-base mr-auto">
            {{ $t("internalrecipientssettings.menu_sms_language_selection") }}
          </h2>
        </div>
        <select v-model="androidAppLanguage" class="form-select">
          <option value="fr">FR - Français</option>
          <option value="de">DE - Deutsch</option>
          <option value="en">EN - English</option>
          <option value="es">ES - Español</option>
          <option value="nl">NL - Nederlands</option>
          <option value="it">IT - Italiano</option>
        </select>
        <!-- END: choix de la langue SMS -->
        <!-- BEGIN: choix du scenario -->
        <div class="clearfix box mt-6">
          <!-- entete de parametre -->
          <div
            class="flex flex-col sm:flex-row  p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <h2 class="font-medium text-base mr-auto">
              {{ $t("internalrecipientssettings.menu_alert_scenario_type") }}
            </h2>
          </div>
          <!-- BEGIN: bloc parametres -->
          <div>
            <!-- BEGIN: parametre avec radio button -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <div>
                <div class="form-check">
                  <input
                    id="sms-and-call"
                    v-model="radioButtonAlertScenarioType"
                    class="form-check-input"
                    type="radio"
                    value="1"
                  />
                  <label class="form-check-label" for="sms-and-call">
                    {{
                      $t("internalrecipientssettings.menu_sms_and_calls_cycle")
                    }}
                  </label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="sms-then-call"
                    v-model="radioButtonAlertScenarioType"
                    class="form-check-input"
                    type="radio"
                    value="2"
                  />
                  <label class="form-check-label" for="sms-then-call">
                    {{
                      $t("internalrecipientssettings.menu_sms_then_calls_cycle")
                    }}
                  </label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="sms-with-ack"
                    v-model="radioButtonAlertScenarioType"
                    class="form-check-input"
                    type="radio"
                    value="3"
                  />
                  <label class="form-check-label" for="sms-with-ack">
                    {{
                      $t(
                        "internalrecipientssettings.menu_sms_with_acknowledgment_then_call"
                      )
                    }}
                  </label>
                </div>
              </div>
            </div>
            <!-- END: parametre avec radio button -->
            <!-- BEGIN: parametre avec switch -->
            <div
              v-if="
                radioButtonAlertScenarioType == 1 ||
                  radioButtonAlertScenarioType == 2
              "
              class="clearfix flex-row p-3 border-t border-gray-200"
            >
              <div class="form-check mr-3 ">
                <input
                  id="checkbox-switch-1"
                  v-model="isAcquitCascadeSms"
                  class="form-check-input"
                  type="checkbox"
                  value="0"
                />
                <label class="form-check-label" for="checkbox-switch-1">
                  {{
                    $t(
                      "internalrecipientssettings.menu_call_cycle_stopped_by_sms"
                    )
                  }}
                </label>
              </div>
            </div>
            <!-- END: parametre avec switch -->
            <!-- BEGUIN: parametre range slider -->
            <div
              v-if="radioButtonAlertScenarioType == 3"
              class="clearfix flex-row p-3 border-b border-gray-200"
            >
              <label class="font-bold">
                {{
                  $t(
                    "internalrecipientssettings.menu_maximum_waiting_time_for_acknowledgment_reception"
                  )
                }}
              </label>
              <div class="input-group pb-1 mt-2">
                <input
                  v-model="traitementPecRange"
                  type="range"
                  :min="traitementPecMin"
                  :max="traitementPecMax"
                  step="10"
                />
                <div class="form-control w-20 text-center ml-5">
                  {{ traitementPecRange }}
                </div>
                <div id="input-group-3" class="input-group-text h-10 font-sm">
                  {{ $t("common.menu_seconds") }}
                </div>
              </div>
            </div>
            <!-- END: parametre range slider -->
          </div>
          <!-- END: bloc parametres -->
        </div>
        <!-- END: choix du scenario -->
        <!-- BEGIN: données a inserer dans sms -->
        <div class="clearfix box mt-6">
          <!-- entete de parametre avec switch box -->
          <div
            class="flex flex-col sm:flex-row p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div>
              <h2 class="font-medium text-base ">
                {{ $t("internalrecipientssettings.menu_sms_data_selection") }}
              </h2>
            </div>
          </div>
          <!-- BEGIN: bloc parametres -->
          <div>
            <!-- BEGIN: parametre avec multi check box -->
            <div
              class="clearfix flex-col pl-3 pb-3 border-b mt-3 border-gray-200"
            >
              <label class="font-bold">
                {{ $t("internalrecipientssettings.menu_basic_data") }}
              </label>
              <div class="flex flex-col sm:flex-col mt-3">
                <div class="form-check mr-3 sm:mt-0">
                  <input
                    id="checkbox-switch-2"
                    v-model="isDeviceUser"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-2">
                    {{ $t("internalrecipientssettings.menu_device_user") }}
                  </label>
                </div>
                <div class="form-check mt-2 mr-3 ">
                  <input
                    id="checkbox-switch-3"
                    v-model="isBatteryLevel"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-3">
                    {{ $t("internalrecipientssettings.menu_battery_level") }}
                  </label>
                </div>
                <div class="form-check mt-2 mr-3  ">
                  <input
                    id="checkbox-switch-4"
                    v-model="isEventTimeStamp"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-4">
                    {{ $t("internalrecipientssettings.menu_event_timestamp") }}
                  </label>
                </div>
              </div>
            </div>
            <!-- END: parametre avec multi check box -->
            <!-- BEGIN: parametre avec multi check box -->
            <div
              v-if="isGpsWanted == true"
              class="clearfix flex-col pl-3 pb-3 border-b mt-3 border-gray-200"
            >
              <label class="font-bold">
                {{ $t("internalrecipientssettings.menu_gps_data") }}
              </label>
              <div class="flex flex-col sm:flex-col mt-3">
                <div class="form-check mr-3">
                  <input
                    id="checkbox-switch-5"
                    v-model="isGpsGoogleMap"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-5">
                    {{ $t("internalrecipientssettings.menu_google_map_link") }}
                  </label>
                </div>
                <div class="form-check mt-2 mr-3 ">
                  <input
                    id="checkbox-switch-6"
                    v-model="isGpsTimeStamp"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-6">
                    {{ $t("internalrecipientssettings.menu_timestamp") }}
                  </label>
                </div>
                <div class="form-check mt-2 mr-3  ">
                  <input
                    id="checkbox-switch-7"
                    v-model="isGpsAddress"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-7">
                    {{
                      $t(
                        "internalrecipientssettings.menu_adress_google_reverse_geocoding"
                      )
                    }}
                  </label>
                </div>
                <div class="form-check mr-3 mt-2">
                  <input
                    id="checkbox-switch-8"
                    v-model="isGpsAccuracy"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-8">
                    {{ $t("internalrecipientssettings.menu_accuracy") }}
                  </label>
                </div>
                <div class="form-check mt-2 mr-3">
                  <input
                    id="checkbox-switch-9"
                    v-model="isGpsDms"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-9">
                    {{
                      $t(
                        "internalrecipientssettings.menu_coordinates_DMS_format"
                      )
                    }}
                  </label>
                </div>
                <div class="form-check mt-2 mr-3">
                  <input
                    id="checkbox-switch-10"
                    v-model="isGpsDd"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-10">
                    {{
                      $t(
                        "internalrecipientssettings.menu_coordinates_DD_format"
                      )
                    }}
                  </label>
                </div>
              </div>
            </div>
            <!-- END: parametre avec multi check box -->
          </div>
          <!-- END: bloc parametres -->
        </div>
        <!-- END: données a inserer dans sms -->
        <!-- BEGIN: personnalisation des libelles d'alerte -->
        <div class="clearfix box mt-6">
          <!-- entete de parametre avec switch box -->
          <div
            class="flex flex-col sm:flex-row p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div>
              <h2 class="font-medium text-base">
                {{
                  $t(
                    "internalrecipientssettings.menu_alert_labels_customization"
                  )
                }}
              </h2>
            </div>
          </div>
          <!-- BEGIN: bloc parametres -->
          <div>
            <!-- BEGIN: parametre texte -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("internalrecipientssettings.menu_request_for_help") }}
              </label>
              <div class="input-group pb-2">
                <input
                  v-model="smsTextRequestForHelp"
                  type="text"
                  maxlength="50"
                  class="form-control w-full text-left"
                  aria-describedby="input-group-1"
                />
              </div>
              <label class="font-bold">
                {{ $t("internalrecipientssettings.menu_assault") }}
              </label>
              <div class="input-group pb-2">
                <input
                  v-model="smsTextAssault"
                  type="text"
                  maxlength="50"
                  class="form-control w-full text-left"
                  aria-describedby="input-group-1"
                />
              </div>
              <label class="font-bold">
                {{ $t("internalrecipientssettings.menu_no_movement") }}
              </label>
              <div class="input-group pb-2">
                <input
                  v-model="smsTextImmobility"
                  type="text"
                  maxlength="50"
                  class="form-control w-full text-left"
                  aria-describedby="input-group-1"
                />
              </div>
              <label class="font-bold">
                {{ $t("internalrecipientssettings.menu_tilt") }}
              </label>
              <div class="input-group pb-2">
                <input
                  v-model="smsTextTilt"
                  type="text"
                  maxlength="50"
                  class="form-control w-full text-left"
                  aria-describedby="input-group-1"
                />
              </div>
            </div>
            <!-- END: parametre texte -->
          </div>
          <!-- END: bloc parametres -->
        </div>
        <!-- END: personnalisation des libelles d'alerte -->
      </div>
      <!-- END: left column -->
    </div>
    <!-- END: local-settings -->

    <button
      type="submit"
      class="btn btn-primary mt-6"
      @click="onSaveButtonClicked"
    >
      {{ $t("common.menu_save") }}
    </button>
  </div>
</template>

<script>
import { getOptionsValue, setOptionsValue } from "../../dao/options-dao";

import {
  getParameterPTIValue,
  getParameterPTIMin,
  getParameterPTIMax,
  setParameterPTIValue
} from "../../dao/parametre-pti";
import { useStore } from "@/store";

const MODEL_EWG100 = "E-WG100";

export default {
  data() {
    return {
      smseventschecked: 0,
      radiobuttonsmsackchecked: 0,
      smsackchecked: 0,
      smsdatachecked: 0,
      alertlabelchecked: 0,
      range: 60,
      dc09formatchecked: 3,
      radioButtonAlertScenarioType: null,
      isGpsWanted: false,
      isAcquitCascadeSms: false,
      traitementPecRange: 60,
      traitementPecMin: 60,
      traitementPecMax: 180,
      isSmsAppelCascade: null,
      isSmsGroupeAppelCascade: null,
      isSmsPecAppel: null,
      smsTextTilt: null,
      smsTextImmobility: null,
      smsTextAssault: null,
      smsTextRequestForHelp: null,
      isGpsDd: false,
      isGpsDms: false,
      isGpsAccuracy: false,
      isGpsAddress: false,
      isGpsTimeStamp: false,
      isGpsGoogleMap: false,
      isEventTimeStamp: false,
      isBatteryLevel: false,
      isDeviceUser: false,
      androidAppLanguage: "fr",

      isEwg100: undefined
    };
  },

  created() {
    this.isEwg100 =
      useStore().state.emeritValues.deviceInfo.deviceModelName === MODEL_EWG100;
  },

  mounted() {
    this.updateUI();
  },

  methods: {
    updateUI() {
      if (
        (this.isSmsAppelCascade = getParameterPTIValue(
          "TraitementSmsAppelsCascade"
        )) == 1
      ) {
        this.radioButtonAlertScenarioType = 1;
      } else if (
        (this.isSmsGroupeAppelCascade = getParameterPTIValue(
          "TraitementSmsGroupeAppelEnCascade"
        )) == 1
      ) {
        this.radioButtonAlertScenarioType = 2;
      } else if (
        (this.isSmsPecAppel = getParameterPTIValue(
          "TraitementSmsPriseEnCompteAppel"
        )) == 1
      ) {
        this.radioButtonAlertScenarioType = 3;
      }
      if (
        (this.isAcquitCascadeSms = getParameterPTIValue(
          "TraitementAquitSMS"
        )) == 1
      ) {
        this.isAcquitCascadeSms = true;
      } else {
        this.isAcquitCascadeSms = false;
      }
      this.traitementPecRange = getParameterPTIValue("TraitementTempsSMS");
      this.traitementPecMin = getParameterPTIMin("TraitementTempsSMS");
      this.traitementPecMax = getParameterPTIMax("TraitementTempsSMS");
      this.isGpsWanted = getParameterPTIValue("isGpsLocParamEnabled");
      this.isDeviceUser = this.intToBoolean(
        getParameterPTIValue("SmsIdentifiant")
      );
      this.isBatteryLevel = this.intToBoolean(
        getParameterPTIValue("SmsBatterie")
      );
      this.isEventTimeStamp = this.intToBoolean(
        getParameterPTIValue("SmsHorodatage")
      );
      this.isGpsGoogleMap = this.intToBoolean(
        getParameterPTIValue("SmsLienGMaps")
      );
      this.isGpsTimeStamp = this.intToBoolean(
        getParameterPTIValue("SmsHorodatageGps")
      );
      this.isGpsAddress = this.intToBoolean(
        getParameterPTIValue("SmsAdresseClaire")
      );
      this.isGpsAccuracy = this.intToBoolean(
        getParameterPTIValue("SmsPrecision")
      );
      this.isGpsDms = this.intToBoolean(getParameterPTIValue("SmsCoordGpsDms"));
      this.isGpsDd = this.intToBoolean(getParameterPTIValue("SmsCoordGpsDD"));
      this.smsTextTilt = getParameterPTIValue("PerteVerticaliteSMSTexte");
      this.smsTextImmobility = getParameterPTIValue("AbsenceMouvementSMSTexte");
      this.smsTextAssault = getParameterPTIValue("SOSAmberSMSTexte");
      this.smsTextRequestForHelp = getParameterPTIValue("SOSMaydaySMSTexte");
      this.androidAppLanguage = getOptionsValue("LanguageCode");
    },

    onSaveButtonClicked() {
      setOptionsValue("LanguageCode", this.androidAppLanguage);
      if (this.radioButtonAlertScenarioType == 1) {
        setParameterPTIValue("TraitementSmsAppelsCascade", 1);
        setParameterPTIValue("TraitementSmsGroupeAppelEnCascade", 0);
        setParameterPTIValue("TraitementSmsPriseEnCompteAppel", 0);
      } else if (this.radioButtonAlertScenarioType == 2) {
        setParameterPTIValue("TraitementSmsAppelsCascade", 0);
        setParameterPTIValue("TraitementSmsGroupeAppelEnCascade", 1);
        setParameterPTIValue("TraitementSmsPriseEnCompteAppel", 0);
      } else if (this.radioButtonAlertScenarioType == 3) {
        setParameterPTIValue("TraitementSmsAppelsCascade", 0);
        setParameterPTIValue("TraitementSmsGroupeAppelEnCascade", 0);
        setParameterPTIValue("TraitementSmsPriseEnCompteAppel", 1);
        setParameterPTIValue("TraitementTempsSMS", this.traitementPecRange);
      }
      if (this.isAcquitCascadeSms == true) {
        setParameterPTIValue("TraitementAquitSMS", 1);
      } else {
        setParameterPTIValue("TraitementAquitSMS", 0);
      }
      // save checkbox
      setParameterPTIValue(
        "SmsIdentifiant",
        this.booleanToInt(this.isDeviceUser)
      );
      setParameterPTIValue(
        "SmsBatterie",
        this.booleanToInt(this.isBatteryLevel)
      );
      setParameterPTIValue(
        "SmsHorodatage",
        this.booleanToInt(this.isEventTimeStamp)
      );
      setParameterPTIValue(
        "SmsLienGMaps",
        this.booleanToInt(this.isGpsGoogleMap)
      );
      setParameterPTIValue(
        "SmsHorodatageGps",
        this.booleanToInt(this.isGpsTimeStamp)
      );
      setParameterPTIValue(
        "SmsAdresseClaire",
        this.booleanToInt(this.isGpsAddress)
      );
      setParameterPTIValue(
        "SmsPrecision",
        this.booleanToInt(this.isGpsAccuracy)
      );
      setParameterPTIValue("SmsCoordGpsDms", this.booleanToInt(this.isGpsDms));
      setParameterPTIValue("SmsCoordGpsDD", this.booleanToInt(this.isGpsDd));

      // save texte
      if (this.smsTextTilt == null) {
        this.$router.push("/");
      } else {
        setParameterPTIValue("PerteVerticaliteSMSTexte", this.smsTextTilt);
      }
      if (this.smsTextImmobility == null) {
        this.$router.push("/");
      } else {
        setParameterPTIValue(
          "AbsenceMouvementSMSTexte",
          this.smsTextImmobility
        );
      }
      if (this.smsTextAssault == null) {
        this.$router.push("/");
      } else {
        setParameterPTIValue("SOSAmberSMSTexte", this.smsTextAssault);
      }
      if (this.smsTextRequestForHelp == null) {
        this.$router.push("/");
      } else {
        setParameterPTIValue("SOSMaydaySMSTexte", this.smsTextRequestForHelp);
      }
      this.$router.push("/");
    },

    intToBoolean(value) {
      if (value === "1") {
        return true;
      }
      return false;
    },
    booleanToInt(value) {
      if (value == true) {
        return "1";
      }
      return "0";
    }
  }
};
</script>
