<template>
  <!-- BEGIN: ButtonDismissModal component-->
  <button 
    ref="button"
    type="button"
    :class="customClass"
    data-dismiss="modal"
  >
    {{ $t(text) }}
  </button>
  <!-- END: ButtonDismissModal component-->
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A colored button with a text that will dismiss a modal when clicked on it.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} text
 *    The text of the button. Should be i18 key for the locale 
 *    (ex: 'common.menu_save').
 * 
 * @param {String} customClass
 *    A custom class that can override the default class (a blue button). 
 *    Some examples:
 *      - yellow: 'btn btn-warning'
 *      - black: 'btn btn-dark'
 *      - blue: 'btn btn-primary'
 *      - white: 'btn btn-outline-secondary'
 *      - red: 'btn btn-danger' 
 * 
 * ---------------- METHODS ACCESSIBLE USING REFS -------------------
 * @method click
 * @param void
 *    Allows to trigger a click on the button from the outside.
 *    (ex: this.$refs.xxx.click()).
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  props: {
    text: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: 'btn btn-primary mt-6'
    }
  },
  
  methods: {
    click() {
      this.$refs.button.click();
    }
  }
})
</script>
  