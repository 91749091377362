<template>
  <!-- BEGIN: WifiNetworkSettings local component-->
  <div class="intro-y grid grid-cols-12 gap-6 pt-6">
    <div class="lg:col-span-6 2xl:col-span-5 col-span-12">
      <BoxTitle
        title="wifi.menu_config_wifi_network"
        color="bg-indigo-300"
      />
      <div class="clearfix box p-3">
        <div class="font-bold text-theme-0 p-1">
          {{ $t("wifi.menu_nb_wifi_network_saved") + networkList.length }}
        </div>
        <div class="flex mt-2">
          <div class="pr-3">
            <ButtonTargetModal
              :target-id="'#' + modalIdAddModifyNetwork"
              text="wifi.menu_add_a_wifi_network"
              custom-class="btn btn-primary"
              @click="$emit('add-network')"
            />
          </div>
          <div>
            <ButtonTargetModal
              v-if="networkList.length"
              :target-id="'#' + modalIdAlertConfirm"
              custom-class="btn btn-danger"
              text="common.menu_remove_everything"
              @click="$emit('remove-all-networks')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="lg:col-span-6 2xl:col-span-5 col-span-12">
      <BoxTitle
        title="common.menu_settings"
        color="bg-indigo-300"
      />
      <div class="clearfix box border-b border -gray-200">
        <CustomSwitch
          :model-value="true"
          text="wifi.menu_use_wifi_connection"
          class="p-3"
          custom-text-class="text-theme-0 mt-1 pl-3"
          :is-disabled="true"
          tippy-text="wifi.menu_send_alert_with_wifi"
        />
        <CustomSwitch
          v-model="updateUsingWifi"
          text="wifi.menu_name_is_update_app_by_wifi_enabled"
          custom-text-class="text-theme-0 mt-1 pl-3"
          class=" p-3 border-b border-gray-200"
        />
        <div class="mt-3 ml-3 mr-3">
          <div class="font-bold text-theme-0 p-1">
            {{ $t("wifi.menu_mode_utilisation") }}
          </div>
          <div class="form-check pt-3">
            <input
              id="radiobutton1"
              v-model="isbuttonWifiAlone"
              class="form-check-input p-3"
              type="radio"
              value="1"
              name="radio-gsm-wifi"
            >
            <label
              class="form-check-label"
              for="radiobutton1"
            >
              {{ $t("wifi.menu_mode_gsm_plus_wifi") }}
            </label>
          </div>
          <div class="form-check mt-3 mb-3">
            <input
              id="radiobutton2"
              v-model="isbuttonWifiAlone"
              class="form-check-input"
              type="radio"
              value="2"
              name="radio-wifi-alone"
            >
            <label
              class="form-check-label"
              for="radiobutton2"
            >
              {{ $t("wifi.menu_mode_wifi_alone") }}
            </label>
          </div>
        </div>
        <div
          v-if="isbuttonWifiAlone == 2"
          class="mb-3 ml-3 mr-3"
        >
          <select
            v-model="selectedTimezone"
            class="hover:translate-y-1 form-select"
            @change="updateSelectedUTC"
          >
            <option
              v-if="UTC=='' || UTC=='(UTC )'"
              class="mr-1 mt-1"
              value=""
            >
              {{ $t("wifi.menu_select_utc") }}
            </option>
            <option
              v-if="UTC!='(UTC )' && UTC!=''"
              class="mr-2 mt-2"
              value=""
            >
              {{ UTC }}
            </option>
            <option
              v-for="zone in Zones"
              :key="zone.name"
              :value="zone.name"
            >
              {{ zone.name }} (UTC {{ zone.utc }})
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <!-- END: WifiNetworkSettings local component-->
</template>

<script>
import { defineComponent } from 'vue'
import { getBoolPtiValue, getParameterPTIValue, setNumberPtiValue, setParameterPTIValue } from '../../../dao/parametre-pti';
import moment from 'moment-timezone';


const PTI_PARAM_UPDATE_WIFI = "isUpdateAppByWifiEnabled";
const PTI_PARAM_WIFI_Alone = "isUseWifiAlone";
const PTI_PARAM_UTC_TIME = "UtcTime";
const PTI_PARAM_UTC_TIME_ALL_CHARACTER = "UtcTimeAllCharacter";

export default defineComponent({

  props: {
    networkList: {
      type: Array,
      required: true,
    },
    modalIdAddModifyNetwork: {
      type: String,
      required: true,
    },
    modalIdAlertConfirm: {
      type: String,
      required: true,
    },
  },

  emits: ['add-network', 'remove-all-networks'],
      data() {
    const zones = moment.tz.names().map(name => ({
      name,
      label: moment.tz(name).format('Z z'),
      utc: moment.tz(name).format('Z'),
    }));

    // Convert the time zone abbreviations to UTC
    zones.forEach(zone => {
      switch (zone.label) {
        case 'WAT WAT':
          zone.label = 'UTC+1 West Africa Time';
          break;
        case 'EAT EAT':
          zone.label = 'UTC+3 East Africa Time';
          break;
        case 'GMT GMT':
          zone.label = 'UTC±0 Greenwich Mean Time';
          break;
        case 'EET EET':
          zone.label = 'UTC+2 Eastern European Time';
          break;
        case 'CET CET':
          zone.label = 'UTC+1 Central European Time';
          break;
        default:
          break;
      }
    })
    return {
      isbuttonWifiAlone: undefined,
      updateUsingWifi: undefined,
      Zones: zones,
      selectedTimezone: '',
      selectedUTC: '',
      UtcFiltre:'',
      UTC: '',
    };
  },

  watch: {
    updateUsingWifi() {
      setNumberPtiValue(PTI_PARAM_UPDATE_WIFI, this.updateUsingWifi);
    },
    isbuttonWifiAlone() {
      if(this.isbuttonWifiAlone == 1){
        setNumberPtiValue(PTI_PARAM_WIFI_Alone, 0);
      }
      else{
        setNumberPtiValue(PTI_PARAM_WIFI_Alone, 1);       
      }
    },
    isChecked(val) {
      if (!val) {
        this.selectedTimezone = '';
        this.selectedUTC = '';
      }
    },
  },

  created() {
    this.updateUsingWifi = getBoolPtiValue(PTI_PARAM_UPDATE_WIFI);
    if(getBoolPtiValue(PTI_PARAM_WIFI_Alone) == 1){
      this.isbuttonWifiAlone = 2;
      this.UTC = getParameterPTIValue(PTI_PARAM_UTC_TIME);
    }
    else{
      this.isbuttonWifiAlone = 1;
    }
  },
  methods: {
    updateSelectedUTC() {
      const selectedZone = this.Zones.find(zone => zone.name === this.selectedTimezone);
      this.selectedUTC = selectedZone ? selectedZone.utc : '';
      this.UTC = this.selectedTimezone +("(UTC " + this.selectedUTC + ")");
      // this.UtcFiltre = this.selectedUTC.replace(/^\+0/, '');
      setParameterPTIValue(PTI_PARAM_UTC_TIME_ALL_CHARACTER, this.selectedTimezone);
      setParameterPTIValue(PTI_PARAM_UTC_TIME, this.UTC);
    },
  },

})
</script>
