<template>
  <div>
    <Title
      title="locationoutdoor.menu_gps_based"
      help-locale="menu_loc_outdoor"
    />
    <GpsSettings
      ref="gpsSettings"
      @input="blueButtonText = 'common.menu_save'"
    />
    <CustomButton
      :text="blueButtonText"
      @click="save"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import GpsSettings from './local-components/lc-gps-settings.vue'

export default defineComponent({

  name: 'EmeritLocationOutdoor',
  
  components: { GpsSettings },

  data() {
    return {
      blueButtonText: 'common.menu_back',
    }
  },

  methods: {
    
    save() {
      if (this.blueButtonText === 'common.menu_back')
        this.$router.push('/');  
      else if (this.$refs.gpsSettings.save());
        this.$router.push('/');  
    }
  }
})
</script>
