<template>
  <!-- BEGIN: ModifyIbeaconInputFields local component-->
  <div class="mt-3">
    <Tippy
      v-for="(field, index) in getIbeaconFields"
      :key="index"
      :ref="field.title"
      :content="$t(field.tippy)"
      :options="{
        theme: 'light',
        delay: index,
        onShow: updateTippyContent,
      }"
    >
      <CustomTextInput
        v-model="field.value"
        class="mb-3"
        :title="field.title"
        :max-length="field.len"
        :error-msg="field.errorMsg"
      />
    </Tippy>
  </div>
  <!-- END: ModifyIbeaconInputFields local component-->
</template>

<script>
import { defineComponent } from 'vue'
import {
  checkLevel,
  checkCodeZone,
  beaconModifiedAlreadyExisting,
  getModel,
} from './utils'
import {
  setError,
  resetError,
  resetAllFields,
  resetAllError,
  checkFieldIsNotEmpty,
  regexCheck,
} from '../../../utils/fieldManagement'

const COMPANY_ID = 0;
const BEACON_ID = 1;
const CODE_ZONE = 2;
const DESC = 3;
const LEVEL = 4;

const UUID_LEN = 32;
const MAJOR_MINOR_LEN = 8;
const MODEL_IBEACON = "iBeacon";

const ERR_IBEACON_ALREADY_EXIST = "locationindoorble.menu_err_ibeacon_already_exist";
const ERR_IBEACON_COMP_ID = "locationindoorble.menu_err_ibeacon_company_id";
const ERR_IBEACON_BEAC_ID = "locationindoorble.menu_err_ibeacon_beacon_id";
const ERR_CODE_ZONE = "locationindoorble.menu_err_wrong_code_zone";
const ERR_DESC = "locationindoorble.menu_err_empty_desc";
const ERR_LEVEL = "locationindoorble.menu_err_wrong_level";

const COMP_ID_TITLE = "locationindoorble.menu_beacon_company_id";
const BEAC_ID_TITLE = "locationindoorble.menu_beacon_id";
const CODE_ZONE_TITLE = "locationindoorble.menu_beacon_code_zone";
const DESC_TITLE = "locationindoorble.menu_beacon_description";
const LEVEL_TITLE = "locationindoorble.menu_beacon_level";

const UUID_TIPPY = "locationindoorble.menu_tippy_uuid";
const IBEACON_MAJOR_MINOR_TIPPY = "locationindoorble.menu_tippy_ibeacon_major_minor";
const CODE_ZONE_TIPPY = "locationindoorble.menu_tippy_code_zone";
const DESC_TIPPY = "locationindoorble.menu_tippy_description";
const LEVEL_TIPPY = "locationindoorble.menu_tippy_level";


export default defineComponent({

  props: {
    hideLevelField: {
      type: Boolean,
      required: true,
    },
    beaconList: {
      type: Array,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    nbTimesShow: {
      type: Number,
      required: true, 
    },
  },

  emits: ['modifyIbeaconSuccess'],

  data() {
    return {
      ibeaconFields: [
        { title: COMP_ID_TITLE, tippy: UUID_TIPPY, value: '', len: UUID_LEN, errorMsg: ''},
        { title: BEAC_ID_TITLE, tippy: IBEACON_MAJOR_MINOR_TIPPY, value: '', len: MAJOR_MINOR_LEN, errorMsg: ''},
        { title: CODE_ZONE_TITLE, tippy: CODE_ZONE_TIPPY, value: '', len: 3, errorMsg: ''},
        { title: DESC_TITLE, tippy: DESC_TIPPY, value: '', len: 50, errorMsg: ''},
        { title: LEVEL_TITLE, tippy: LEVEL_TIPPY, value: '', len: 3, errorMsg: ''},
      ],
    }
  },

  computed: {
    getIbeaconFields() {
      const len = this.hideLevelField ? this.ibeaconFields.length - 1 : this.ibeaconFields.length;
      let newIbeaconFields = new Array(len);
      for (let i = 0, j = 0; i < this.ibeaconFields.length; ++i, ++j) {
        if (this.hideLevelField && i === LEVEL)
          --j
        else 
          newIbeaconFields[j] = this.ibeaconFields[i];
      }
      return newIbeaconFields;
    },
  },

  watch: {
    hideLevelField() {
      this.ibeaconFields[LEVEL].value = '';
      resetError(this.ibeaconFields, LEVEL);
    },

    nbTimesShow: {
      immediate: true, 
      handler: function() {
        if (!this.beaconList.length)
          return ;
        const beaconToModify = this.beaconList[this.currentIndex];
        resetAllError(this.ibeaconFields);
        const model = getModel(beaconToModify[COMPANY_ID]);
        const isModelSelectedMatchingInputFieldsType = model.valueOf() === MODEL_IBEACON ? true : false;
        for (let i = 0; i < this.ibeaconFields.length; ++i) {
          if (!isModelSelectedMatchingInputFieldsType && (i === COMPANY_ID || i === BEACON_ID)) {
            this.ibeaconFields[i].value = '';
            continue ;  
          }
          this.ibeaconFields[i].value = beaconToModify[i];
        }
      }
    }
  },

  methods: {

    updateTippyContent(instance) {
      const index = instance.props.delay;
      instance.setContent(this.$t(this.getIbeaconFields[index].tippy));
    },

    check() {
      let error = this.ibeaconFields.length;
      error -= this.checkIbeaconCompanyId();
      error -= this.checkIbeaconBeaconId();
      error -= checkLevel(this.hideLevelField, this.ibeaconFields, LEVEL, ERR_LEVEL);
      error -= checkCodeZone(this.ibeaconFields, CODE_ZONE, ERR_CODE_ZONE);
      error -= checkFieldIsNotEmpty(this.ibeaconFields, DESC, ERR_DESC);
      if (!error && !beaconModifiedAlreadyExisting(this.currentIndex, this.beaconList, this.ibeaconFields[COMPANY_ID].value, this.ibeaconFields[BEACON_ID].value, this.ibeaconFields, ERR_IBEACON_ALREADY_EXIST))
        this.emitModifyIbeaconSuccess();
    },

    checkIbeaconCompanyId() {
      const companyId = this.ibeaconFields[COMPANY_ID].value.toUpperCase();
      if (!companyId.length || !regexCheck(companyId, /^[A-F0-9]{32}/))
        return setError(this.ibeaconFields, COMPANY_ID, ERR_IBEACON_COMP_ID);
      return resetError(this.ibeaconFields, COMPANY_ID);
    },

    checkIbeaconBeaconId() {
      const beaconId = this.ibeaconFields[BEACON_ID].value.toUpperCase();
      if (!beaconId.length || !regexCheck(beaconId, /^[A-F0-9]{8}/))
        return setError(this.ibeaconFields, BEACON_ID, ERR_IBEACON_BEAC_ID);
      return resetError(this.ibeaconFields, BEACON_ID);
    },

    emitModifyIbeaconSuccess() {
      this.$emit('modifyIbeaconSuccess', this.createNewBeacon());
      resetAllFields(this.ibeaconFields);
    },

    createNewBeacon() {
      return [
        this.ibeaconFields[COMPANY_ID].value.toUpperCase(),
        this.ibeaconFields[BEACON_ID].value.toUpperCase(),
        this.ibeaconFields[CODE_ZONE].value,
        this.ibeaconFields[DESC].value,
        this.ibeaconFields[LEVEL].value,
      ]
    },

  }
})
</script>
