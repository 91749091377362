<template>
  <p 
    v-if="errorMsg.length"
  >
    {{ $t(errorMsg) }}
  </p>
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * An error msg which will be printed in red if not empty. Does not
 * appears otherwise.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} errorMsg
 *    The error to print. Should be i18 key for the locale 
 *    (ex: 'common.menu_save').
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({

  props: {
    errorMsg: {
      type: String,
      required: true,
    }
  }
})
</script>
