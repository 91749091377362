<template>
  <!-- BEGIN: DeviceNotification local component -->
  <div class="clearfix box mt-6">
    <BoxTitle
      title="configuration.menu_device_notification"
      color="bg-gray-400"
      help-locale="menu_device_notif_management"
    />
    <FieldXHorizontalCheckBoxes
      v-if="isEwg100"
      v-model="notifs.values"
      title="configuration.menu_choose_notif_mode"
      :descriptions="notifs.descs"
      :ids="notifs.ids"
      :error-msg="notifs.errorMsg"
    />
    <CheckBoxFieldAndXIcons
      v-for="(fields, index) in getFields"
      :key="index"
      v-model="fields.value"
      :custom-id="`checkbox-device-notif-${index}`"
      :check-box-text="fields.title"
      :icon-params="fields.iconParams"
      @change="onCheckBoxChange(index)"
    />
  </div>
  <!-- END: DeviceNotification local component -->
</template>

<script>
import { defineComponent } from 'vue'
import {
  getBoolPtiValue,
  setNumberPtiValue,
} from "../../../dao/parametre-pti";

import { useStore } from "@/store";


const PARAM_PTI_NOTIF_MSG = [
  'isEmeritMessageNotificationVibrationEnabled',
  'isEmeritMessageNotificationSoundEnabled',
];

const VIB_INDEX = 0;
const SOUND_INDEX = 1;
const INDEX_CREATION_COMPONENT_FOR_SMARTPHONE = 1;

const VIB_ID = "vibration";
const SOUND_ID = "sound";

const VIB_DESC = "common.menu_notification_by_vibration";
const SOUND_DESC = "common.menu_notification_by_sound";

const ERR_SELECT_ONE_MSG_NOTIF = "configuration.menu_err_choose_at_least_one_notif_mode";

const I18_TITLE_NOTIF_NEWTORK_ISSUE = 'configuration.menu_GSM_data_wifi_issue_notification';
const I18_TITLE_SOUND_NOTIFICATION = 'configuration.menu_name_is_sound_notif_securite_positive_enabled';

const PTI_PARAM_NETWORK_ISSUE_NOTIF = "ReseauGSMControle";
const PTI_PARAM_SOUND_NOTIFICATION = "isSoundNotificationSecuritePositiveEnabled";

const MODEL_EWG100 = "E-WG100";


export default defineComponent({

   props: {
      isEwg100: {
        type: Boolean,
        required: true,
    }
  },

  data() {
    return {
      
      indexCountComponentCheckBoxFieldNotifs: 0,
      notifs: {
        values: [undefined, undefined],
        descs: [VIB_DESC, SOUND_DESC],
        ids: [VIB_ID, SOUND_ID],
        errorMsg: '',
      },
      notifsCheckBoxFields: [
        { 
          title: I18_TITLE_NOTIF_NEWTORK_ISSUE, 
          value: true, 
          ptiParam: PTI_PARAM_NETWORK_ISSUE_NOTIF,
          iconParams: undefined 
        },
         { 
          title: I18_TITLE_SOUND_NOTIFICATION, 
          value: false, 
          ptiParam: PTI_PARAM_SOUND_NOTIFICATION,
          iconParams: undefined 
        },
      ]
    }
  },

  computed: {
    getFields() {
      let renderFields = new Array();
      for (let i = 0; i < this.notifsCheckBoxFields.length; ++i){
        console.log("boucle i = "+i  + "  ,isEWG100 : "+this.isEwg100 + "index = "+INDEX_CREATION_COMPONENT_FOR_SMARTPHONE);
        if (i != INDEX_CREATION_COMPONENT_FOR_SMARTPHONE || (i == INDEX_CREATION_COMPONENT_FOR_SMARTPHONE && this.isEwg100)){
          renderFields.push(this.notifsCheckBoxFields[i]);
          console.log("passed");
        }
      }
      return renderFields;
    }
  },

  watch: {

    'notifs.values': {
      deep: true,
      handler: function updateNotifs() {
        for (let i = 0; i < this.notifs.values.length; ++i)
          if (this.notifs.values[i] != undefined)
            setNumberPtiValue(PARAM_PTI_NOTIF_MSG[i], this.notifs.values[i]);
        if (this.notifs.errorMsg.length)
          this.notifs.errorMsg = '';
      },
    },

  },

  created() {
   // this.isEwg100 = this.deviceModelName === MODEL_EWG100;
    if(this.isEwg100){
      this.indexCountComponentCheckBoxFieldNotifs = this.notifsCheckBoxFields.length;
    }else{
      this.indexCountComponentCheckBoxFieldNotifs = INDEX_CREATION_COMPONENT_FOR_SMARTPHONE;
    }
    for (let i = 0; i < this.notifs.values.length; ++i)
      this.notifs.values[i] = getBoolPtiValue(PARAM_PTI_NOTIF_MSG[i]);
    for (let i = 0; i < this.indexCountComponentCheckBoxFieldNotifs; ++i){
      this.notifsCheckBoxFields[i].value = getBoolPtiValue(this.notifsCheckBoxFields[i].ptiParam);
    }
  },

  methods: {
    
    onCheckBoxChange(i) {
      setNumberPtiValue(this.notifsCheckBoxFields[i].ptiParam, this.notifsCheckBoxFields[i].value);
    },

    check() {
      this.checkNotifs();
      return !this.notifs.errorMsg.length;
    },

    checkNotifs() {
      if (this.notifs.values[VIB_INDEX] || this.notifs.values[SOUND_INDEX]) {
        if (this.notifs.errorMsg.length)
          this.notifs.errorMsg = '';
        return true;
      }
      this.notifs.errorMsg = ERR_SELECT_ONE_MSG_NOTIF;
      return false;
    },

    initDeviceModelName() {
      this.deviceModelName = useStore().state.emeritValues.deviceInfo.deviceModelName;
     /* if (this.deviceModelName == RG170)
        this.deviceModelName = RG170_IS170;
      else if (this.deviceModelName == IS530)
        this.deviceModelName = RG530_IS530;*/
    },

  }
})
</script>
