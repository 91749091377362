<template>
  <!-- BEGIN: LoginInfo component-->
  <div class="hidden xl:flex flex-col min-h-screen">
    <a class="-intro-x flex items-center pt-5">
      <img
        tabindex="-1"
        class="w-30"
        :src="require(`@/assets/images/emerit_red_white_50px.png`)"
      >
      <small 
        style="color:white" 
        class="ml-5 mt-10"
      >
        V.{{ siteVersion }}
      </small>
    </a>
    <div class="my-auto">
      <img
        tabindex="-1"
        class="-intro-x w-3/4 -mt-24"
        :src="imageSource"
      >
    </div>
  </div>
  <!-- END: LoginInfo component-->
</template>

<script>
import { defineComponent } from 'vue'


/**
 * -------------------------- DESCRIPTION ---------------------------
 * Emerit-console version info and emerit logo. Rendered on the left
 * only for large screen.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} imageSource
 *    The big image rendered in the middle of the component 
 *    corresponding to the return value of require function. 
 *    Ex: require(`@/assets/images/logo_sygma_200px.png`).
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  props: {
    imageSource: {
      type: String,
      required: true,
    }
  },
  
  data() {
    return {
      siteVersion: process.env.VUE_APP_VERSION,
    }
  }
})
</script>
