import { useStore } from "@/store";

export const addRecipient = recipient => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'INSERT INTO  tele_surveillance_appel (nom,numero,id_tri)  VALUES( "' +
    recipient[1] +
    '","' +
    recipient[2] +
    '","' +
    recipient[3] +
    '")';
  try {
    return dbFile.exec(sqlScript);
  } catch (error) {
    console.log('addRecipient failed: ' + error);
  }
};

export const getAllTlsRecipient = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * from tele_surveillance_appel";
  try {
    var recipient = dbFile.exec(sqlScript)[0];
    return recipient;
  } catch (error) {
    console.log('getAllTlsRecipient failed: ' + error);
  }
};

export const getRecipient = id => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * from tele_surveillance_appel";
  try {
    var recipient = dbFile.exec(sqlScript)[0].values[id];
    return recipient;
  } catch (error) {
    console.log('getRecipient failed: ' + error);
  }
};

export const updateRecipient = recipient => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'UPDATE tele_surveillance_appel SET nom="' +
    recipient[1] +
    '", numero="' +
    recipient[2] +
    '",id_tri="' +
    recipient[3] +
    '" where _id="' +
    recipient[0] +
    '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('updateRecipient failed: ' + error);
  }
};

export const getCountRecipient = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT COUNT(*) FROM tele_surveillance_appel";
  try {
    return Number(dbFile.exec(sqlScript)[0].values[0]);
  } catch (error) {
    console.log('getCountRecipient failed: ' + error);
  }
};

export const getCountRecipientTlsCall = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT COUNT(*) FROM tele_surveillance_appel";
  try {
    return Number(dbFile.exec(sqlScript)[0].values[0]);
  } catch (error) {
    console.log('getCountRecipientTlsCall failed: ' + error);
  }
};

export const deleteRecipient = id => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = 'DELETE FROM tele_surveillance_appel where _id="' + id + '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('deleteRecipient failed: ' + error);
  }
};
