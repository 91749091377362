<template>
    <!-- BEGIN: CustomTable -->
    <div class="grid grid-cols-10">
      <div :class="`intro-y col-span-10 xl:col-span-${screenPercentWidth}`">
        <div :class="`intro-y col-span-10 xl:col-span-${screenPercentWidth} overflow-auto`">
          <table class="table table-report">
            <thead>
              <tr>
                <th
                  v-for="(header, index) in headers"
                  :key="index"
                >
                  {{ $t(header) }}
                </th>
                <th v-if="redButtonText"></th>
              </tr>
            </thead>
            <tbody>
              <component
                :is="listElementComponentName"
                v-for="(elem, index) in elemsList"
                :key="index"
                :elems-text="elem"
                :icons="getIcons(index)"
                :showing-errors="showingErrors"
                :red-button-text="redButtonText"
                :red-button-bootstrap-target="redButtonBootstrapTarget"
                @red-button-clicked="$emit('elementRedButtonClicked', index)"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- END: CustomTable component -->
  </template>
  
  <script>
  import { defineComponent } from "vue";
  
  export default defineComponent({
    props: {
      headers: {
        type: Array,
        default: undefined,
      },
      elemsList: {
        type: Array,
        required: true,
      },
      iconsList: {
        type: Array,
        default: undefined,
      },
      showingErrors: {
        type: Boolean,
        required: true,
      },
      screenPercentWidth: {
        type: Number,
        required: true,
      },
      listElementComponentName: {
        type: String,
        required: true,
      },
      redButtonText: {
        type: String,
        default: '',
      },
      redButtonBootstrapTarget: {
        type: String,
        required: true,
      }
    },
    methods: {
      getIcons(index) {
        if (!this.iconsList) {
          return undefined;
        }
        return this.iconsList[index];
      }
    }
  });
  </script>
  