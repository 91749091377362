/* eslint-disable */

<template>
  <div>
    <div class="intro-y flex items-center mt-6">
      <h2 class="text-lg font-medium mr-auto">
        {{ $t("internalrecipients.menu_monitoring_center_call_numbers") }}
      </h2>
    </div>

    <!-- BEGIN: tls-call -->
    <div class="grid grid-cols-12 gap-6 mt-6">
      <!-- BEGIN: left column -->
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: groupe parametres -->
        <div class="clearfix box">
          <!-- entete de parametre avec switch box -->
          <div
            class="flex flex-raw sm:flex-row pt-3 p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div class="form-check w-full">
              <input
                id="show-example-1"
                v-model="list_recipient_check[0]"
                name="show-immobility-settings"
                data-target="#input"
                class="show-code form-check-switch bg-white"
                type="checkbox"
              >
              <div>
                <h2
                  v-if="!list_recipient_check[0]"
                  class="font-medium text-base pl-3"
                >
                  {{ $t("internalrecipients.menu_enable_call_number") }} 1
                </h2>
              </div>
              <div>
                <h2
                  v-if="list_recipient_check[0]"
                  class="font-medium text-base pl-3"
                >
                  {{ $t("internalrecipients.menu_call_number") }} 1
                </h2>
              </div>
            </div>
          </div>
          <!-- BEGIN: bloc parametres masquable -->
          <div v-if="list_recipient_check[0]">
            <!-- BEGIN: parametre texte -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("internalrecipients.menu_name") }}
              </label>
              <div class="input-group mt-2 pb-1">
                <input
                  v-model="recipients_list[0][NAME_COLUMN]"
                  type="text"
                  maxlength="50"
                  class="form-control w-full text-left"
                  aria-describedby="input-group-1"
                >
              </div>
              <p v-if="!tab_error_name[0]">
                {{ $t("common.menu_field_cannot_be_empty") }}
              </p>
            </div>
            <!-- END: parametre texte -->
            <!-- BEGIN: parametre texte -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{
                  $t(
                    "internalrecipients.menu_phone_number_international_format"
                  )
                }}
              </label>
              <div class="input-group mt-2 pb-1">
                <input
                  v-model="recipients_list[0][NUMBER_COLUMN]"
                  type="text"
                  maxlength="50"
                  class="form-control w-full text-left"
                  aria-describedby="input-group-1"
                >
              </div>
              <p v-if="!tab_error_number_empty[0]">
                {{ $t("common.menu_field_cannot_be_empty") }}
              </p>
              <p v-if="!tab_error_number_international[0]">
                {{ $t("common.menu_phone_number_must_start_with_+") }}
              </p>
              <p v-if="!tab_error_number_already_used[0]">
                {{ $t("common.menu_phone_number_already_used") }}
              </p>
            </div>
            <!-- END: parametre texte -->
          </div>
          <!-- END: bloc parametres masquable -->
        </div>
        <!-- END: groupe parametres -->
        <!-- BEGIN: groupe parametres -->
        <div class="clearfix box mt-3">
          <!-- entete de parametre avec switch box -->
          <div
            class="flex flex-raw sm:flex-row p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div class="form-check w-full">
              <input
                id="show-example-1"
                v-model="list_recipient_check[1]"
                name="show-immobility-settings"
                data-target="#input"
                class="show-code form-check-switch bg-white"
                type="checkbox"
                value="1"
              >
              <div>
                <h2
                  v-if="!list_recipient_check[1]"
                  class="font-medium text-base pl-3"
                >
                  {{ $t("internalrecipients.menu_enable_call_number") }} 2
                </h2>
              </div>
              <div>
                <h2
                  v-if="list_recipient_check[1]"
                  class="font-medium text-base pl-3"
                >
                  {{ $t("internalrecipients.menu_call_number") }} 2
                </h2>
              </div>
            </div>
          </div>
          <!-- BEGIN: bloc parametres masquable -->
          <div v-if="list_recipient_check[1]">
            <!-- BEGIN: parametre texte -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("internalrecipients.menu_name") }}
              </label>
              <div class="input-group mt-2 pb-1">
                <input
                  v-model="recipients_list[1][NAME_COLUMN]"
                  type="text"
                  maxlength="50"
                  class="form-control w-full text-left"
                  aria-describedby="input-group-1"
                >
              </div>
              <p v-if="!tab_error_name[1]">
                {{ $t("common.menu_field_cannot_be_empty") }}
              </p>
            </div>
            <!-- END: parametre texte -->
            <!-- BEGIN: parametre texte -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{
                  $t(
                    "internalrecipients.menu_phone_number_international_format"
                  )
                }}
              </label>
              <div class="input-group mt-2 pb-1">
                <input
                  v-model="recipients_list[1][NUMBER_COLUMN]"
                  type="text"
                  maxlength="50"
                  class="form-control w-full text-left"
                  aria-describedby="input-group-1"
                >
              </div>
              <p v-if="!tab_error_number_empty[1]">
                {{ $t("common.menu_field_cannot_be_empty") }}
              </p>
              <p v-if="!tab_error_number_international[1]">
                {{ $t("common.menu_phone_number_must_start_with_+") }}
              </p>
              <p v-if="!tab_error_number_already_used[1]">
                {{ $t("common.menu_phone_number_already_used") }}
              </p>
            </div>
            <!-- END: parametre texte -->
          </div>
          <!-- END: bloc parametres masquable -->
        </div>
        <!-- END: groupe parametres -->
        <!-- BEGIN: groupe parametres -->
        <div class="clearfix box mt-3">
          <!-- entete de parametre avec switch box -->
          <div
            class="flex flex-raw sm:flex-row p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div class="form-check w-full">
              <input
                id="show-example-1"
                v-model="list_recipient_check[2]"
                name="show-immobility-settings"
                data-target="#input"
                class="show-code form-check-switch bg-white"
                type="checkbox"
                value="1"
              >
              <div>
                <h2
                  v-if="!list_recipient_check[2]"
                  class="font-medium text-base pl-3"
                >
                  {{ $t("internalrecipients.menu_enable_call_number") }} 3
                </h2>
              </div>
              <div>
                <h2
                  v-if="list_recipient_check[2]"
                  class="font-medium text-base pl-3"
                >
                  {{ $t("internalrecipients.menu_call_number") }} 3
                </h2>
              </div>
            </div>
          </div>
          <!-- BEGIN: bloc parametres masquable -->
          <div v-if="list_recipient_check[2]">
            <!-- BEGIN: parametre texte -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("internalrecipients.menu_name") }}
              </label>
              <div class="input-group mt-2 pb-1">
                <input
                  v-model="recipients_list[2][NAME_COLUMN]"
                  type="text"
                  maxlength="50"
                  class="form-control w-full text-left"
                  aria-describedby="input-group-1"
                >
              </div>
              <p v-if="!tab_error_name[2]">
                {{ $t("common.menu_field_cannot_be_empty") }}
              </p>
            </div>
            <!-- END: parametre texte -->
            <!-- BEGIN: parametre texte -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{
                  $t(
                    "internalrecipients.menu_phone_number_international_format"
                  )
                }}
              </label>
              <div class="input-group mt-2 pb-1">
                <input
                  v-model="recipients_list[2][NUMBER_COLUMN]"
                  type="text"
                  maxlength="50"
                  class="form-control w-full text-left"
                  aria-describedby="input-group-1"
                >
              </div>
              <p v-if="!tab_error_number_empty[2]">
                {{ $t("common.menu_field_cannot_be_empty") }}
              </p>
              <p v-if="!tab_error_number_international[2]">
                {{ $t("common.menu_phone_number_must_start_with_+") }}
              </p>
              <p v-if="!tab_error_number_already_used[2]">
                {{ $t("common.menu_phone_number_already_used") }}
              </p>
            </div>
            <!-- END: parametre texte -->
          </div>
          <!-- END: bloc parametres masquable -->
        </div>
        <!-- END: groupe parametres -->
      </div>
      <!-- END: left column -->
    </div>
    <!-- END: tls-call -->



    <button
      type="submit"
      class="btn btn-primary mt-6"
      @click="onSaveButtonClicked"
    >
      {{ $t("common.menu_save") }}
    </button>
  </div>
</template>

<script>

import {
  addRecipient,
  getRecipient,
  updateRecipient,
  deleteRecipient,
  getCountRecipient,
} from "../../dao/tls-call-dao";
import { useStore } from "@/store";

import {
  getBoolPtiValue,
} from '../../dao/parametre-pti'

const PARAM_PTI_IS_SIP_AVAILABLE = "SipManagerAvailable";
const PARAM_PTI_IS_SIP_ACTIVATED = "SipActivationState";
const MODEL_LOGIN_PATH = "model-login";

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["type"],
  data() {
    return {
      isSipActivated: false,
      tab_error_name: new Array(3).fill(true),
      tab_error_number_empty: new Array(3).fill(true),
      tab_error_number_international: new Array(3).fill(true),
      tab_error_number_already_used: new Array(3).fill(true),

      NAME_COLUMN: 1,
      NUMBER_COLUMN: 2,
      SORT_ID_COLUMN: 3,

      getCountRecipientTemp: [0, "", "", 0],

      tab_error_empty: new Array(6).fill(true),
      list_recipient_check: [
        this.recipient1checked,
        this.recipient2checked,
        this.recipient3checked
      ],
      recipients_list: new Array(3),

      pageTitle: "values",
      recipient1checked: 0,
      recipient2checked: 0,
      recipient3checked: 0,
      recipient4checked: 0,
      recipient5checked: 0,
      recipient6checked: 0,
      recipient7checked: 0,
      recipient8checked: 0,
      recipient9checked: 0,
      recipient10checked: 0,
      smseventschecked: 0,
      radiobuttonsmsandcallcyclechecked: 0,
      radiobuttonsmsthencallcyclechecked: 0,
      radiobuttonsmsackchecked: 0,
      smsackchecked: 0,
      smsdatachecked: 0,
      alertlabelchecked: 0,
      range: 60,
      dc09formatchecked: 3,
      radioButtonAlertScenarioType: -1,

      //showGpsPosition: false
      durationCallAttempt: 20,
      durationCallAttemptMin:20,
      durationCallAttemptMax:60,
    };
  },

  mounted() {
    //console.log("mounted");

    this.updateUI();
  },
  created() {
    this.initSipValue();
  },
  methods: {
    initSipValue() {
      let isDeployCred = false; 
      if (useStore().state.emeritValues.disconnectionPath === MODEL_LOGIN_PATH)
        isDeployCred = true;
      const isSipAvailable = isDeployCred ? true : getBoolPtiValue(PARAM_PTI_IS_SIP_AVAILABLE);
      if (isSipAvailable === undefined)
        this.isSipActivated = false;
      else if (isSipAvailable)
        this.isSipActivated = getBoolPtiValue(PARAM_PTI_IS_SIP_ACTIVATED)
    },
    updateUI() {
      if (getCountRecipient() > 0) {
        this.initFromDb();
      }
      if (getCountRecipient() < 3) {
        this.initFromVar();
      }
    },

    initFromDb() {
      for (let i = 0; i < getCountRecipient(); i++) {
        this.recipients_list[i] = getRecipient(i);
        this.list_recipient_check[i] = true;
      }
    },

    initFromVar() {
      for (let i = getCountRecipient(); i < 3; i++) {
        //console.log("passed initFromVar i = "+i);
        this.recipients_list[i] = [0, "", "", 0];
        this.list_recipient_check[i] = false;
      }
    },

    onSaveButtonClicked() {
      let getCountTlsCall = getCountRecipient();
      let counterDeletedRecipient = 0;
      for (let i = 0; i < 3; i++) {
        if (i < getCountTlsCall) {
          if (this.list_recipient_check[i]) {
            if (this.controlField(i)) {
              updateRecipient(this.recipients_list[i]);
            } else {
              return;
            }
          } else {
            //console.log("delete recipient id = "+getRecipient(i-counterDeletedRecipient)[0] + "  i = "+i);
            deleteRecipient(getRecipient(i - counterDeletedRecipient)[0]);
            counterDeletedRecipient++;
          }
        } else {
          if (this.list_recipient_check[i]) {
            if (this.controlField(i)) {
              //console.log("create recipient "+this.recipients_list[i]);
              addRecipient(this.recipients_list[i]);
            } else {
              return;
            }
          }
        }
      }
      this.$router.push("/");
    },
    controlField(index) {
      //console.log("control field initFromVar index = "+index);
      if (this.recipients_list[index][this.NAME_COLUMN] == "") {
        //console.log("control field false ");
        this.tab_error_name[index] = false;
        return false;
      } else {
        //console.log("control field true ");
        this.tab_error_name[index] = true;
      }

      if (this.recipients_list[index][this.NUMBER_COLUMN] == "") {
        this.tab_error_number_empty[index] = false;
        return false;
      } else {
        this.tab_error_number_empty[index] = true;
      }
      if (this.isSipActivated === true ){ 
      if (!this.recipients_list[index][this.NUMBER_COLUMN].startsWith("")) {
        this.tab_error_number_international[index] = false;
        //console.log("control field international false ");
        return false;
      } 
      }else {
          this.tab_error_number_international[index] = true;
          //console.log("control field international true ");
      }
      if (this.isSipActivated === false ){ 
      if (!this.recipients_list[index][this.NUMBER_COLUMN].startsWith("+")) {
        this.tab_error_number_international[index] = false;
        //console.log("control field international false ");
        return false;
      } 
      }else {
          this.tab_error_number_international[index] = true;
          //console.log("control field international true ");
      }

      for (let j = 0; j < 3 && j < index; ++j) {
        if (this.recipients_list[index][this.NUMBER_COLUMN] === this.recipients_list[j][this.NUMBER_COLUMN]) {
          this.tab_error_number_already_used[index] = false;
          return;
        }
        else
          this.tab_error_number_already_used[index] = true;
      }

      

      return true;
    }
  }
};
</script>

<style>
p {
  color: rgba(211, 41, 41, var(--tw-text-opacity));
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  margin-top: 30px;
  font-style: bold;
}
</style>
