export const setError = (fields, index, err) => {
  fields[index].errorMsg = err;
  return false;
}

export const resetAllFields = (fields) => {
  for (let i = 0; i < fields.length; ++i) {
    fields[i].value = '';
    fields[i].errorMsg = '';
  }
}

export const resetAllError = (fields) => {
  for (let i = 0; i < fields.length; ++i)
    resetError(fields, i);
  return true;
}

export const resetError = (fields, index) => {
  if (fields[index].errorMsg.length)
    fields[index].errorMsg = '';
  return true;
}

export const checkFieldIsNotEmpty = (fields, index, errorMsg) => {
  if (!fields[index].value.length)
    return setError(fields, index, errorMsg);
  return resetError(fields, index);
}

export const regexCheck = (str, regex) => {
  let regexResult = str.match(regex);
  if (!regexResult || regexResult[0].length != str.length)
    return false;
  return true;
}