<template>
  <!-- BEGIN: FieldXVerticalCheckBoxes component-->
  <div class="clearfix">
    <div class="flex flex-col sm:flex-column ml-3">
      <div 
        v-for="(elem, index) in vmodelValues"
        :key="index"
        :class="getCheckboxClass[index]"
      >
        <input
          :id="ids[index]"
          :checked="elem"
          type="checkbox"
          class="form-check-input float-left"
          :disabled="getDisabledArray[index]"
          @change="onCheckBoxChange(index, $event.target.checked)"
        >
        <label 
          class="form-check-label"
          :for="ids[index]"
        >
          {{ $t(descriptions[index] ) }}
        </label>
      </div>
      <ErrorMsg
        :error-msg="errorMsg"
        class="mt-2 mb"
      />
    </div>
  </div>
  <!-- END: FieldXVerticalCheckBoxes component-->
</template>

<script>
import { defineComponent } from 'vue'


const CHECKBOX_DISABLED_CLASS = "form-check mt-2 opacity-50";
const CHECKBOX_NOT_DISABLED_CLASS = "form-check mt-2";


/**
 * -------------------------- DESCRIPTION ---------------------------
 * Vertical checkboxes, from 1 checkbox to x checkboxes, each one being 
 * associated with a clickable description. v-model MUST be used on this 
 * component, it will be updated with checkboxes values each time the 
 * user clicks on one checkbox.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {Array[Boolean]} modelValue
 *    Values of the checkboxes. Automatically sets when using v-model on this
 *    component. For example, v-model="parentVariable" will bind this 
 *    modelValue to parentVariable. Checkboxes will be initialized with 
 *    parentVariable values.
 * 
 * @param {Array[String]} descriptions
 *    The descriptions associated with each checkbox. Should be keys 
 *    for i18 locale.
 * 
 * @param {Array[String]} ids
 *    HTML ids for each checkbox, in order to link the descriptions to
 *    them. When user clicks on a description, it will checked
 *    the associated checkbox. Each ID must be unique on the view.
 * 
 * @param {Array[Boolean] || undefined} disabledCheckBoxes
 *    An array to indicate which checkboxes will be disabled, which should
 *    be the same length than modelValue array. For example, [true, false, true] 
 *    will disable first and third checkboxes. Deactivated checkboxes will
 *    appear with their descriptions associated in grey. Optional props 
 *    (undefined by default), do not fill it if not needed. 
 * 
 * @param {String} errorMsg
 *    If empty, nothing appears. Otherwise, this string will appear 
 *    as an error in red to the user. Should be key for i18 locale.
 *    Empty by default, if no error management is needed do not fill it.
 * 
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits update:modelValue
 * @return {Array[Boolean]}
 *    Emits the values of the checkboxes (true or false). When v-model is
 *    used on this component, v-model automatically update with those
 *    values.
 *                    
 * ------------------------------------------------------------------
 */
export default defineComponent({

  props: {
    descriptions: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    ids: {
      type: Array,
      required: true,
    },
    disabledCheckBoxes: {
      type: Array || undefined,
      default: undefined,
    },
    errorMsg: {
      type: String,
      default: '',
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      vmodelValues: [],
    }
  },

  computed: {
    getDisabledArray() {
      if (this.disabledCheckBoxes === undefined) {
        let noneIsDisabled = new Array(this.modelValue.length).fill(false);
        return noneIsDisabled;
      }
      return this.disabledCheckBoxes;
    },

    getCheckboxClass() {
      if (this.disabledCheckBoxes === undefined) {
        let classNoneIsDisabled = new Array(this.modelValue.length).fill(CHECKBOX_NOT_DISABLED_CLASS);
        return classNoneIsDisabled;
      }
      let classArray = new Array(this.modelValue.length);
      for (let i = 0; i < this.modelValue.length; ++i)
        classArray[i] = this.disabledCheckBoxes[i] ? CHECKBOX_DISABLED_CLASS : CHECKBOX_NOT_DISABLED_CLASS;
      return classArray;
    },
  },

  watch: {
    modelValue: {
      deep: true,
      handler: function updateCheckBoxesValues() {
        for (let i = 0; i < this.modelValue.length; ++i)  
          this.vmodelValues[i] = this.modelValue[i];
      }
    }
  },

  created() {
    for (let i = 0; i < this.modelValue.length; ++i)
      this.vmodelValues[i] = this.modelValue[i];
  },

  methods: {
    onCheckBoxChange(index, newValue) {
      this.vmodelValues[index] = newValue;
      this.$emit('update:modelValue', this.vmodelValues);
    },
  },
  
})
</script>
