import { useStore } from "@/store";

/**
 * Returns the sip table in database, containing all the sip accounts.
 *
 * @returns {Object}
 *    The sip table. Object returned should be accessed like
 *    this : sipTables[row][column].
 */
export const getSipAccount = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript = "SELECT * FROM sip_account";
  try {
    return dbFile.exec(sqlScript)[0];
  } catch (error) {
    console.log('getSipAccount failed: ' + error);
  }
};

/**
 * Inserts a new sip account in the database. Account informations will be empty.
 *
 * @param {Array[4 Strings]} sipFields
 *    An array of 4 Strings representing the sip account info.
 *    he 4 fields are : account name, user name, user password and server url.
 */
export const createSipAccount = sipFields => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript =
    'INSERT INTO sip_account (' +
    'account_name,user_name,' +
    'user_password,server_url) VALUES("' +
    sipFields[0] +
    '", "' +
    sipFields[1] +
    '", "' +
    sipFields[2] +
    '", "' +
    sipFields[3] +
    '")';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('createNewSipAccount failed: ' + error);
  }
};

/**
 * Updates all rows in sip table with a new account informations. Usefull
 * if there is only one sip account in sip table.
 *
 * @param {Array[4 Strings]} sipFields
 *    An array of 4 Strings representing the sip account info.
 *    he 4 fields are : account name, user name, user password and server url.
 */
export const updateSipAccountInfo = sipFields => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript =
    'update sip_account set ' +
    'account_name = "' +
    sipFields[0] +
    '", ' +
    'user_name = "' +
    sipFields[1] +
    '", ' +
    'user_password = "' +
    sipFields[2] +
    '", ' +
    'server_url = "' +
    sipFields[3] +
    '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('setSipAccount failed: ' + error);
  }
};

/**
 * Updates all rows in target db sip table with new account information 
 * from deploy-login view.
 *
 * @param {Array[3 Strings]} sipFields
 *    An array of 3 Strings representing the sip account info.
 *    The 3 fields are : user name, user password and server url.
 */
 export const updateTargetDbSipAccountInfo = sipFields => {
  const dbFile = useStore().state.emeritValues.targetDbFile;
  const sqlScript =
    "update sip_account set " +
    "account_name = 'sip', " +
    'user_name = "' +
    sipFields[0] +
    '", ' +
    'user_password = "' +
    sipFields[1] +
    '", ' +
    'server_url = "' +
    sipFields[2] +
    '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('setTargetDbSipAccount failed: ' + error);
  }
};