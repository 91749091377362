<template>
  <!-- BEGIN: CustomButton component-->
  <button 
    ref="button"
    type="button"
    :class="customClass"
    :disabled="disabled"
    style="outline: 0 !important;"
  >
    <component 
      :is="iconComponentName"
      v-if="iconComponentName"
      :class="text.length ? 'mr-2' : ''" 
    />
    {{ text.length ? $t(text) : '' }}
  </button>
  <!-- END: CustomButton component-->
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A colored button with a text and an optionnal icon.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} text
 *    The text of the button. Should be i18 key for the locale 
 *    (ex: 'common.menu_save').
 * 
 * @param {String} customClass
 *    A custom class that can override the default class 
 *    (by default a blue button). Some examples:
 *      - yellow: 'btn btn-warning'
 *      - black: 'btn btn-dark'
 *      - blue: 'btn btn-primary'
 *      - white: 'btn btn-outline-secondary'
 *      - red: 'btn btn-danger' 
 * 
 * @param {String} iconComponentName
 *    Name of the dynamic icon component. The icon will be render 
 *    at the left of the text. Undefined by default, do not fill this
 *    props if icon is not needed.
 * 
 * @param {Boolean} disabled
 *    Optional props. If true, the button will be disabled. False by
 *    default.
 * 
 * ---------------- METHODS ACCESSIBLE USING REFS -------------------
 * @method click
 * @param void
 *    Allows to trigger a click on the button from the outside.
 *    (ex: this.$refs.xxx.click()).
 * 
 * @method triggerEvent
 * @param {String} eventName
 *    Trigger a js event represented by eventName.
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  props: {
    text: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: 'btn btn-primary mt-6'
    },
    iconComponentName: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  
  methods: {
    click() {
      this.$refs.button.click();
    },

    triggerEvent(eventName) {
      const mouseoverEvent = new Event(eventName);
      this.$refs.button.dispatchEvent(mouseoverEvent);
    }
  }
})
</script>
  