<template>
  <div>
    <Title
      title="locationindoorble.menu_ble_based_indoor_location"
      help-locale="menu_loc_indoor_ble"
    />
    <div class="grid grid-cols-12 gap-6 mt-6">
      <BleBeaconSettings 
         ref=bleBeaconSettings
        :nb-ble-beacons="beaconList.length"
        :level-error-msg="levelErrorMsg"
        @import-export-block-status-changed="showImportExportButtons = $event"
        @remove-all-beacons="initModalConfirm"
        @ble-state-changed="isLevelActivated = $event"
      />
      <ImportExportBleBeacon
        v-if="showImportExportButtons"
        ref="ImportExportBleBeacon"
        :beacon-list="beaconList"
        @import-success="addImportList($event)"
      />
      <AddBleBeacon
        v-if="!showImportExportButtons"
        :beacon-list="beaconList"
        :is-level-activated="isLevelActivated"
        @add-beacon-success="addNewBeacon"
      />
    </div>
    <BleBeaconList
      :beacon-list="beaconList"
      :is-level-activated="isLevelActivated"
      @modify-beacon="initModalModify"
      @remove-beacon="initModalConfirm"
    />
    <ModalModifyBleBeacon
      ref="modalModifyBleBeacon"
      modal-id="modal-ble-beacon-modify"
      :beacon-list="beaconList"
      :current-index="currentIndex"
      :is-level-activated="isLevelActivated"
      @modify-beacon-success="saveModifiedBeacon($event)"
    />
    <ModalAlertConfirmChoice
      ref="modalAlertConfirmChoice"
      modal-id="modal-alert-confirm"
      alert-title="common.menu_are_you_sure"
      :alert-text="modalAlertText"
      icon-component-name="AlertTriangleIcon"
      left-button-component-name="ButtonDismissModal"
      left-button-text="common.menu_cancel"
      left-button-custom-class="btn btn-outline-secondary mr-1"
      right-button-component-name="ButtonDismissModal"
      right-button-text="common.menu_remove"
      right-button-custom-class="btn btn-danger"
      @right-button="remove"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import BleBeaconSettings from './local-components/lc-ble-beacon-settings.vue'
import ImportExportBleBeacon from './local-components/lc-import-export-ble-beacon.vue'
import AddBleBeacon from './local-components/lc-add-ble-beacon.vue'
import BleBeaconList from './local-components/lc-ble-beacon-list.vue'
import ModalModifyBleBeacon from './local-components/lc-modal-modify-ble-beacon.vue'
import { 
  getBleBeacons,
  addBleBeacon,
  deleteBleBeacon,
  deleteAllBleBeacons,
  updateBleBeacon,
} from '../../dao/indoor-ble-dao'
import { createBeaconFromDbEntry } from './local-components/utils'


const ALERT_REMOVE_EVERYTHING = "locationindoorble.menu_remove_all_beacons"
const ALERT_REMOVE_ONE_BEACON = "locationindoorble.menu_remove_one_beacon"

const ERR_ONE_ELEM_IS_MISSING_FLOOR = "locationindoorble.menu_err_one_elem_missing_level";
const ERR_NO_LEVEL_BEACON = "locationindoorble.menu_err_no_level_beacon";

const COMPANY_ID = 0;
const BEACON_ID = 1;
const CODE_ZONE = 2;
const DESC = 3;
const LEVEL = 4;
const TYPE = 5;

const ZONE_TYPE = "0";

const REMOVE_ONE_ITEM = 0;
const REMOVE_EVERYTHING = 1;

const BODY = "BODY";

const MODAL_ID_ALERT_CONFIRM = "modal-alert-confirm";
const MODAL_ID_MODIFY_BEACON = "modal-ble-beacon-modify";
const MODAL_ID_ALERT_CONFIRM_UPLOAD = "modal-alert-confirm-csv-upload";


export default defineComponent({

  components: {
    ImportExportBleBeacon,
    AddBleBeacon,
    BleBeaconSettings,
    BleBeaconList,
    ModalModifyBleBeacon,
  },

  /**
   * Dismiss the modals if they're opened by clicking on their right buttons, 
   * and wait X milliseconds until the modals are correctly close by checking
   * if they're still present in the <body></body> HTML element, then route
   * to the new view.
   * 
   * Fix a bug when opening a modal and then using navigation arrows (next, prev).
   * The modal wasn't correctly closed and provoquing a crash with all the other modals
   * until next reload.
   */
  beforeRouteLeave (to, from, next) {
    this.$refs.bleBeaconSettings.save();
    this.$refs.modalAlertConfirmChoice.dismiss();
    this.$refs.modalModifyBleBeacon.dismiss();
    if (this.showImportExportButtons)
      this.$refs.ImportExportBleBeacon.dismissModal();
    let intervalId = setInterval(() => {  
      const modalAlertConfirm = document.getElementById(MODAL_ID_ALERT_CONFIRM);
      const modalModify = document.getElementById(MODAL_ID_MODIFY_BEACON);
      let modalAlertConfirmUpload = undefined;
      if (this.showImportExportButtons)
        modalAlertConfirmUpload = document.getElementById(MODAL_ID_ALERT_CONFIRM_UPLOAD);
      if (modalAlertConfirm.parentElement.tagName.valueOf() != BODY
          && modalModify.parentElement.tagName.valueOf() != BODY
          && ((this.showImportExportButtons 
              && modalAlertConfirmUpload.parentElement.tagName.valueOf() != BODY)
              || !this.showImportExportButtons)) {
        clearInterval(intervalId);
        next();
      }
    }, 100);
	},

  data() {
    return {
      isLevelActivated: false,
      showImportExportButtons: false,
      removeType: undefined,
      beaconList: new Array(),
      currentIndex: 0,
    }
  },

  computed: {
    modalAlertText() {
      return this.removeType == REMOVE_ONE_ITEM ? 
          ALERT_REMOVE_ONE_BEACON : ALERT_REMOVE_EVERYTHING;
    },

    levelErrorMsg() {
      if (!this.isLevelActivated || !this.beaconList.length)
        return '';
      let levelBeaconIsMissing = true;
      for (let i = 0; i < this.beaconList.length; ++i) {
        if (levelBeaconIsMissing && this.beaconList[i][TYPE].valueOf() != ZONE_TYPE)
          levelBeaconIsMissing = false;
        if (this.beaconList[i][TYPE].valueOf() === ZONE_TYPE && 
            !this.beaconList[i][LEVEL].length)
          return ERR_ONE_ELEM_IS_MISSING_FLOOR;
      }
      if (levelBeaconIsMissing)
        return ERR_NO_LEVEL_BEACON;
      return '';
    },
  },

  created() {
    const dbBeacons = getBleBeacons();
    if (dbBeacons != undefined)
      for (let i = 0; i < dbBeacons.values.length; ++i)
        this.beaconList.push(createBeaconFromDbEntry(dbBeacons.values[i]));
  },

  methods: {

    addImportList(bleImportList) {
      this.beaconList = bleImportList;
      deleteAllBleBeacons();
      for (let i = 0; i < bleImportList.length; ++i)
        addBleBeacon(bleImportList[i]);        
    },

    addNewBeacon(newBeacon) {
      this.beaconList.push(newBeacon);
      addBleBeacon(newBeacon);
    },

    saveModifiedBeacon(beaconModified) {
      updateBleBeacon(beaconModified);
      this.beaconList[this.currentIndex] = beaconModified.newBeacon;
    },

    remove() {
      if (this.removeType === REMOVE_ONE_ITEM) {
        deleteBleBeacon(this.beaconList[this.currentIndex][COMPANY_ID], this.beaconList[this.currentIndex][BEACON_ID]);
        this.beaconList.splice(this.currentIndex, 1)
      }
      else {
        deleteAllBleBeacons();
        this.beaconList = new Array();
      }
    },

    initModalConfirm(index) {
      this.currentIndex = index == undefined ? this.currentIndex : index;
      this.removeType = index == undefined ? REMOVE_EVERYTHING : REMOVE_ONE_ITEM;
    },

    initModalModify(index) {
      this.currentIndex = index;
      this.$refs.modalModifyBleBeacon.initValues(index)
    },

    
  },
  
})

</script>
