export const formatLevel = level => {
  let i = 0;
  if (level[i] === '-')
    ++i;
  while (i < level.length - 1 && level[i] === '0')
    ++i;
  if (i && level[0] != '-')
    level = level.substr(i);
  else if (i)
    level = '-' + level.substr(i);
  if (level === "-0")
    level = "0";
  return level;
}