{
  "common": {
    "menu_nothing":"Niente",
    "menu_min_message": "Il messaggio minimo è 1",
    "menu_field_cannot_be_empty_need_one": "È necessario inserire almeno un campo",
    "menu_affichage": "Visualizzazione",
    "menu_son": "Suo",
    "menu_post_alert": "Post-allerta",
    "menu_vibration_5_secondes": "Vibrazioni ogni 5 secondi",
    "menu_phone_number_must_start": "Il numero di telefono deve contenere solo numeri",
    "menu_wrong_file_certificate": "Formato di file non corretto. Il formato deve essere .pfx o .p12.",
    "menu_wrong_config": "La configurazione è errata",
    "menu_see_help_for_more_info": "Consultare l'assistenza online per maggiori informazioni.",
    "menu_back": "Indietro",
    "menu_save": "Salva",
    "menu_settings": "Impostazioni",
    "menu_notification_prealert": "Notifica di preavviso",
    "menu_notification_by_vibration": "Notifica mediante vibrazione",
    "menu_notification_by_sound": "Notifica mediante audio",
    "menu_notification_by_flash": "Notifica mediante flash",
    "menu_notification_duration": "Durata della notifica di preavviso",
    "menu_seconds": "secondi",
    "menu_minutes": "minuti",
    "menu_degrees": "gradi",
    "menu_meters": "metri",
    "menu_settings_for": "Impostazioni per ",
    "menu_confirm": "Conferma",
    "menu_field_cannot_be_empty": "Il campo non può essere vuoto",
    "menu_select_at_least_one_triggering_mode": "Selezionare almeno una modalità di attivazione",
    "menu_phone_number_must_start_with_+": "Il numero di telefono deve iniziare con + e contenere solo cifre",
    "menu_phone_number_is_too_short": "Numero di telefono troppo corto",
    "menu_phone_number_already_used": "Numero di telefono già utilizzato",
    "menu_select_at_least_one_event": "Selezionare almeno un evento",
    "menu_are_you_sure": "Confermare l'operazione?",
    "menu_add": "Aggiungi",
    "menu_modify": "Modifica",
    "menu_remove": "Elimina",
    "menu_remove_everything": "Elimina tutto",
    "menu_wrong_flie_format": "Formato file errato. Salvare il file Excel nel formato .csv con il punto e virgola come separatore.",
    "menu_cancel": "Annulla"
  },
  "configuration": {
    "menu_no_checked_standard": "Nessun parametro è stato selezionato per la modalità standard",
    "menu_no_checked_transparent": "Nessun parametro è stato selezionato per la modalità trasparente",
    "menu_location_standard": "Modalità standard",
    "menu_location_transparent": "Modalità trasparente",
    "menu_location_indoor": "Al chiuso",
    "menu_type_action": "Tipo di azione associata a tre tocchi rapidi",
    "menu_device_stop": "Arresto del dispositivo",
    "menu_custom_on_off": "Personalizzazione del pulsante On/Off",
    "menu_trigger_alert":"Attiva allarme",
    "menu_trigger_call": "Attiva una chiamata con un triplo tocco al numero seguente:",
    "menu_tippy_text_on_off":"Vai a [Personalizzazione del pulsante di accensione / spegnimento] per attivare la funzionalità",
    "menu_show_last_location": "Salva l'ultima posizione conosciuta ad ogni arresto del dispositivo",
    "menu_force_alert_reactivated_system": "Forzare la riattivazione del sistema ad ogni accensione del dispositivo",
    "menu_err_serv_with_no_events": "Nessun evento è stato configurato",
    "menu_err_one_or_parel_recipient_have_message_but_no_events": "Uno o più destinatari ricevono eventi via SMS ma nessun evento è stato configurato",
    "menu_img_upload_failure": "Trasferimento dell'immagine non riuscito, verificare la connessione a Internet",
    "menu_choose_at_least_one_custom_screen": "Nessuna configurazione effettuata",
    "menu_custom_screens": "Personalizza la visualizzazione",
    "menu_communication_option": "Opzioni di comunicazione",
    "menu_system_option": "Opzioni sistema",
    "menu_disable_on_off_btn": "Disattivare il pulsante di avvio/arresto per impedire lo spegnimento del dispositivo",
    "menu_model_check_emerit_version_unstopable_call": "Per questo parametro è necessario che il firmware del dispositivo sia compatibile. È possibile verificare la versione della ROM tramite il widget Info.",
    "menu_tippy_unstopable_call_not_available": "Questo parametro non è compatibile con il firmware del dispositivo in uso. Contattare l'assistenza tecnica per maggiori informazioni.",
    "menu_call_cant_be_stop": "Le chiamate degli avvisi non possono essere interrotte dall'utente",
    "menu_whitelist": "Whitelist (limite delle chiamate in arrivo)",
    "menu_wifi_configuration": "Connessione Wi-Fi",
    "menu_tippy_sip_not_available": "L'attivazione del VoIP non è disponibile sul dispositivo in uso",
    "menu_tippy_widget_loc": "Questo parametro richiede l'attivazione di almeno una delle modalità di localizzazione",
    "menu_model_check_emerit_version": "Il VoIP necessita dell'applicazione Emerit SIP su questo dispositivo. È possibile verificare se quest'ultima è presente tramite il widget Info.",
    "menu_enabling_sip_will_remove_recipients": "L'attivazione del VoIP comporterà l'eliminazione dei destinatari interni salvati. Sarà necessario inserire un nuovo elenco di destinatari.",
    "menu_disabling_sip_will_remove_recipients": "La disattivazione del VoIP comporterà l'eliminazione dei destinatari interni salvati. Sarà necessario inserire un nuovo elenco di destinatari.",
    "menu_wrong_character_in_user_id": "Il nome utente non può contenere il carattere '_' quando è presente almeno un orologio Emerit configurato come destinatario interno",
    "menu_choose_notif_mode": "Selezione della modalità di notifica per gli SMS in arrivo",
    "menu_err_choose_at_least_one_notif_mode": "Selezionare almeno una modalità di notifica",
    "menu_alerts_processing": "Gestione degli avvisi",
    "menu_internal_recipients": "Destinatari interni",
    "menu_alarm_monitoring_center": "Centro di monitoraggio remoto",
    "menu_alarm_monitoring_server": "Server di supervisione",
    "menu_alerts_localization": "Localizzazione degli avvisi",
    "menu_location_never": "Mai",
    "menu_location_outdoor": "All'esterno",
    "menu_alerts_triggering_modes": "Modalità di attivazione degli avvisi",
    "menu_alert_activation_sos_button": "Pulsante SOS",
    "menu_alert_activation_sos_button_and_programmable_button": "Pulsante SOS e pulsanti programmabili",
    "menu_alert_activation_side_black_button": "Pulsante laterale nero",
    "menu_alert_activation_side_yellow_button": "Pulsante laterale giallo",
    "menu_alert_activation_front_programmable_button": "Pulsante programmabile anteriore",
    "menu_alert_activation_side_programmable_button": "Pulsante programmabile laterale",
    "menu_alert_activation_automatic_detection": "Sensori automatici",
    "menu_wrist_alert_mode": "Movimenti rapidi del polso",
    "menu_wearing_style_wrist": "Al polso",
    "menu_wearing_style_waist": "In vita",
    "menu_wearing_style_arms": "Al braccio",
    "menu_wearing_style_neck": "Intorno al collo",
    "menu_alert_activation_power_button": "Pulsante di avvio/arresto",
    "menu_update_configuration": "Aggiorna la configurazione",
    "menu_configuration_update_has_succeeded": "L'aggiornamento della configurazione è riuscito",
    "menu_device_must_be_restarted": "Avviare il dispositivo o lanciare la procedura per caricare la nuova configurazione",
    "menu_no_alert_recipient_was_selected": "Non è stato selezionato alcun destinatario dell'avviso",
    "menu_no_alert_processing_was_selected": "Non è stata selezionata alcuna gestione dell'avviso",
    "menu_no_recipient_or_server_was_created": "Non è stato creato alcun destinatario o server",
    "menu_no_recipient_was_created": "Non è stato creato alcun destinatario",
    "menu_no_server_was_created": "Non è stato creato alcun server",
    "menu_validate_configuration": "Convalida la configurazione",
    "menu_wifi": "Wi-Fi",
    "menu_select_at_least_one_automatic_detection": "Selezionare almeno un sensore automatico",
    "menu_save_at_least_one_wifi_connection": "Salvare almeno una rete Wi-Fi",
    "menu_configuration_update_has_failed": "L'aggiornamento della configurazione non è riuscito",
    "menu_check_internet_connection_and_retry": "Verificare la connessione a Internet e riprovare",
    "menu_download_configuration_model_to_computer": "Scaricare il modello di configurazione sul computer",
    "menu_configuration_model_name": "Nome del modello di configurazione",
    "menu_device_notification": "Gestione delle notifiche del dispositivo",
    "menu_password_for_deployment": "Password per la configurazione mediante distribuzione: 6 caratteri che includono almeno 1 lettera minuscola, 1 lettera maiuscola e 1 cifra, nessun carattere speciale",
    "menu_enter_a_password": "Immetti una password",
    "menu_password_must_be_different_from_access_password": "La password deve essere diversa dalla password di accesso",
    "menu_location_indoor_wifi": "All'interno tramite Wi-Fi",
    "menu_location_indoor_ble": "All'interno tramite Bluetooth",
    "menu_location_automatic_incoming_call_pickup_after_three_rings": "Risposta automatica delle chiamate in arrivo dopo 3 squilli",
    "menu_err_ble_level_precision_not_activated": "La gestione del livello con beacon Bluetooth è stata attivata per la localizzazione Wi-Fi. Questo parametro è quindi obbligatorio.",
    "menu_deployment_password": "Password distribuzione",
    "menu_no_wifi_access_point_has_been_registered": "Non sono stati salvati hotspot Wi-Fi",
    "menu_no_ble_beacon_has_been_registered": "Non sono stati salvati beacon Bluetooth",
    "menu_deactivation_of_alert_system_by_the_user": "Disattivazione del sistema di avviso da parte dell'utente",
    "menu_freely_disable_enable_alert_system": "Autorizza la disattivazione/attivazione libera del sistema di avviso",
    "menu_temporarily_disable_enable_alert_system": "Autorizza la disattivazione/attivazione temporanea del sistema di avviso",
    "menu_duration_of_deactivation": "Durata della disattivazione",
    "menu_GSM_data_wifi_issue_notification": "Notifica in caso di problemi di rete GSM, dati e Wi-Fi",
    "menu_battery_monitoring": "Monitoraggio batteria",
    "menu_networks_monitoring": "Monitoraggio reti",
    "menu_low_battery_notification_triggering_threshold": "Soglia di attivazione di notifiche sulla batteria debole",
    "menu_name_is_not_displayed": "Il nome utente non viene visualizzato sul dispositivo",
    "menu_confirm_deactivation_of_localisation": "Conferma la disattivazione della localizzazione",
    "menu_ask_to_activate_sip": "Attiva la voce su IP (VoIP) mediante account SIP",
    "menu_wrong_sip_configuration": "Tutti i campi del VoIP devono essere compilati",
    "menu_show_fragment_location": "Attiva il widget di visualizzazione della localizzazione attuale",
    "menu_5_percent": "5%",
    "menu_10_percent": "10%",
    "menu_20_percent": "20%",
    "menu_name_is_displayed": "Il nome utente viene visualizzato sul dispositivo",
    "menu_name_is_battery_percentage_is_displayed": "Visualizza la percentuale della batteria",
    "menu_name_is_wake_up_screen_when_charging_enabled": "Riattivare lo schermo ogni 10 secondi durante la ricarica, quando l'orologio è in funzione",
    "menu_name_is_sound_notif_securite_positive_enabled": "Attiva il suono per le notifiche"
  },
  "alerttrigerring": {
    "menu_enable_selecte_switch":"Seleziona almeno un rilevamento",
    "menu_agression": "Assalto",
    "menu_demande_secours": "Chiedere aiuto",
    "menu_message_alert": "Messaggio di avviso da inviare",
    "menu_communication_unidirectionnelle": "Comunicazione unidirezionale (altoparlante disattivato)",
    "menu_communication_bidirectionnelle": "Comunicazione bidirezionale",
    "menu_cycle_destinataires_télésurveillance": "Ciclo automatico di chiamata (destinatari interni o chiamata di monitoraggio remoto)",
    "menu_sos_button_settings": "Impostazioni pulsante SOS",
    "menu_power_button_settings": "Impostazioni pulsante di avvio/arresto",
    "menu_automatic_detection_settings": "Impostazioni sensori automatici",
    "menu_trigger_mode": "Modalità di attivazione",
    "menu_one_long_press": "Una pressione prolungata",
    "menu_three_short_presses": "Tre pressioni rapide",
    "menu_alert_mode": "Modalità di avviso",
    "menu_speaker_on_request_for_help": "Altoparlante attivato > richiesta di soccorso",
    "menu_silent_assault": "Silenzioso > contesto di aggressione",
    "menu_select_at_least_one_notification_mode": "Selezionare almeno una modalità di notifica",
    "menu_front_programmable_button_settings": "Impostazioni del pulsante anteriore programmabile",
    "menu_side_black_button_settings": "Impostazioni del pulsante laterale nero",
    "menu_side_yellow_button_settings": "Impostazioni del pulsante laterale giallo",
    "menu_side_programmable_button_settings": "Impostazioni del pulsante laterale programmabile",
    "menu_sos_button_and_programmable_button_settings": "Impostazioni del pulsante SOS e dei pulsanti programmabili"
  },
  "locationoutdoor": {
    "menu_outdoor_location": "Impostazione localizzazione esterna",
    "menu_gps_based": "Localizzazione esterna basata sul GPS",
    "menu_refreseh_only_when_event_sending": "Aggiornamento esclusivamente alla trasmissione di un evento",
    "menu_update_at_regular_intervals": "Aggiornamento a intervalli regolari",
    "menu_automatic_refresh_interval": "Frequenza di aggiornamento automatica",
    "menu_location_update_method": "Metodo di aggiornamento della localizzazione"
  },
  "locationindoorwifi": {
    "menu_err_ssid_already_exist": "Il SSID esiste già nell'elenco",
    "menu_no_ble_transparent": "Nessun UUID Bluetooth è stato registrato",
    "menu_no_wifi_hotspot_transparent": "Nessun SSID Wifi è stato registrato",
    "menu_no_param_found_indoord": "Nessun parametro è stato selezionato",
    "menu_list_ssid_filter": "Elenco degli SSID da filtrare",
    "menu_add_ssid_filter": "Aggiungi SSID da filtrare",
    "menu_wifi_based_indoor_location_transparent": "Posizione interna basata su WiFi (Modalità Trasparente)",
    "menu_disable_ble_loc_to_handle_floors": "Questa impostazione deve essere disattivata dalla pagina di configurazione della localizzazione all'interno tramite Bluetooth",
    "menu_enable_ble_loc_to_handle_floors": "Questa impostazione deve essere attivata dalla pagina di configurazione della localizzazione all'interno tramite Bluetooth",
    "menu_wifi_based_indoor_location": "Localizzazione interna basata sul Wi-Fi",
    "menu_enable_wifi_based_indoor_location": "Attiva la localizzazione interna basata sul Wi-Fi",
    "menu_import_a_new_wifi_hotspot_file": "Importa un nuovo file degli hotspot Wi-Fi in formato .csv",
    "menu_import_succeeded": "Importazione riuscita",
    "menu_confirm_import": "Conferma l'importazione",
    "menu_import_will_replace_existing_list": "L'importazione da un file .csv sostituirà l'elenco degli hotspot Wi-Fi salvati.",
    "menu_error_while_importing": "Errore durante l'importazione",
    "menu_export_to_csv_wifi_configuration": "Esporta in formato .csv tutti gli hotspot Wi-Fi",
    "menu_excel_file_is_empty": "Il file Excel è vuoto",
    "menu_check_your_excel_file": ". Verifica il tuo file Excel.",
    "menu_download_excel_file_template_for_importation": "Scarica il modello Excel per l'importazione",
    "menu_number_of_imported_wifi_hotspots": "Numero di hotspot Wi-Fi importati: ",
    "menu_an_error_was_detected_line": "È stato rilevato un errore riga ",
    "menu_number_of_registered_wifi_hotspots": "Numero di hotspot Wi-Fi salvati: ",
    "menu_incorrect_column_headers": "Intestazioni di colonna errate. Utilizzare il modello Excel per inserire gli hotspot Wi-Fi, quindi salvare il file in formato .csv con il punto e virgola come separatore.",
    "menu_wifi_hotspot_for_indoor_location": "Hotspot Wi-Fi per la localizzazione interna",
    "menu_no_wifi_hotspot_recorded": "Nessun hotspot Wi-Fi salvato",
    "menu_no_wifi_hotspot_imported": "Nessun hotspot Wi-Fi importato",
    "menu_add_hotspot": "Aggiungi un hotspot Wi-Fi",
    "menu_modify_hotspot": "Modifica un hotspot Wi-Fi",
    "menu_hotspot_ssid": "SSID",
    "menu_hotspot_bssid": "BSSID",
    "menu_hotspot_code_zone": "Codice zona",
    "menu_hotspot_level": "Livello",
    "menu_hotspot_description": "Descrizione",
    "menu_tippy_ssid": "Nome della rete Wi-Fi",
    "menu_tippy_bssid": "Indirizzo MAC diffuso dall'hotspot Wi-Fi (6 coppie di caratteri esadecimali separati da ':')",
    "menu_tippy_code_zone": "Informazioni trasmesse tramite il protocollo SIA",
    "menu_tippy_level": "0 per rdc, numero negativo per il sottosuolo (ad es.: -3)",
    "menu_tippy_description": "Posizione associata all'hotspot. È vietato utilizzare il carattere '_' quando l'opzione invio di messaggi a orologi Emerit è attiva a causa di bug (tale bug sarà corretto durante l'aggiornamento successivo)",
    "menu_err_empty_ssid": "Il SSID non può essere vuoto.",
    "menu_err_wrong_bssid": "BSSID errato. Il BSSID deve essere una sequenza di 6 caratteri esadecimali separati dal carattere ':'. Esempio: 11:22:33:44:55:66.",
    "menu_err_bssid_already_exist": "Il BSSID inserito è già stato salvato.",
    "menu_err_wrong_level": "Errore durante l'inserimento del livello. Quest'ultimo deve essere un numero positivo o negativo.",
    "menu_err_empty_desc": "La descrizione non può essere vuota.",
    "menu_err_wrong_code_zone": "Il codice zona deve essere composto da 3 cifre e compreso tra 001 e 999. Esempio: 007.",
    "menu_add_hotspot_success": "Hotspot Wi-Fi aggiunto correttamente",
    "menu_err_one_elem_missing_level": "Il livello non è stato specificato per tutti gli hotspot Wi-Fi. Verificare l'elenco salvato.",
    "menu_add_hotspot_failed": "Errore durante l'aggiunta di un nuovo hotspot Wi-Fi",
    "menu_modify_hotspot_success": "Hotspot Wi-Fi modificato correttamente",
    "menu_level_precision_with_ble": "Gestisci la modifica di livello con beacon Bluetooth",
    "menu_enable_import_export_csv": "Importa/Esporta in formato .csv",
    "menu_add_manually_wifi_hotspots": "Aggiungi manualmente beacon Wi-Fi",
    "menu_remove_all_hotspots": "Eliminare tutti i beacon Wi-Fi salvati?",
    "menu_remove_one_hotspot": "Eliminare questo hotspot Wi-Fi?",
    "menu_precise_wifi_location_with_ble": "Posizione interna Wi-Fi ottimizzata tramite Bluetooth",
    "menu_title_refresh_choice":" Frequence d'actualisation"
  },
  "locationindoorble": {
    "menu_remove_one_beacon_transparent": "Sei sicuro di voler eliminare questo UUID?",
    "menu_add_beacon_transparent_success": "UUID aggiunto con successo",
    "menu_condition_uuid_transparent": "Il formato dell'UUID deve essere di 32 caratteri esadecimali",
    "menu_add_beacon_transparent_failed": "UUID già registrato",
    "menu_list_ssid_filtered": "Elenco degli SSID da filtrare",
    "menu_custom_model": "Altro UUID",
    "menu_ble_based_indoor_location_transparent": "Posizione interna basata su Bluetooth (Modalità Trasparente)",
    "menu_list_uuid_filtered": "Elenco degli UUID da filtrare",
    "menu_add_uuid_filtered": "Aggiungi Company ID (UUID) da filtrare",
    "menu_ble_based_indoor_location": "Posizione interna basata sul Bluetooth",
    "menu_enable_ble_based_indoor_location": "Attiva la posizione interna basata sul Bluetooth",
    "menu_download_excel_file_template_for_importation": "Scarica il modello Excel per l'importazione",
    "menu_import_a_new_ble_beacons_file": "Importa un nuovo file di beacon Bluetooth in formato .csv",
    "menu_import_succeeded": "Importazione riuscita",
    "menu_confirm_import": "Conferma l'importazione",
    "menu_import_will_replace_existing_list": "L'importazione da un file .csv sostituirà l'elenco dei beacon Bluetooth salvati.",
    "menu_error_while_importing": "Errore durante l'importazione",
    "menu_excel_file_is_empty": "Il file Excel è vuoto",
    "menu_check_your_excel_file": ". Verifica il tuo file Excel.",
    "menu_export_to_csv_ble_configuration": "Esporta in formato .csv tutti i beacon Bluetooth",
    "menu_no_ble_beacon_recorded": "Nessun beacon Bluetooth salvato",
    "menu_no_ble_beacon_imported": "Nessun beacon Bluetooth importato",
    "menu_number_of_registered_ble_beacons": "Numero di beacon Bluetooth salvati: ",
    "menu_incorrect_column_headers_ble": "Intestazioni di colonna errate. Utilizzare il modello Excel per inserire i beacon Bluetooth per salvare il file in formato .csv con il punto e virgola come separatore.",
    "menu_number_of_imported_ble_beacons": "Numero di beacon Bluetooth importati: ",
    "menu_an_error_was_detected_line": "È stato rilevato un errore riga ",
    "menu_add_beacon": "Aggiungi un beacon Bluetooth",
    "menu_modify_beacon": "Modifica un beacon Bluetooth",
    "menu_add_manually_ble_beacons": "Aggiungi manualmente beacon Bluetooth",
    "menu_remove_all_beacons": "Eliminare tutti i beacon Bluetooth salvati?",
    "menu_remove_one_beacon": "Eliminare questo beacon Bluetooth?",
    "menu_add_beacon_success": "Beacon Bluetooth aggiunto correttamente",
    "menu_add_beacon_failed": "Errore durante l'aggiunta di un nuovo beacon Bluetooth",
    "menu_modify_beacon_success": "Beacon Bluetooth modificato correttamente",
    "menu_beacon_model": "Modello di beacon Bluetooth",
    "menu_beacon_company_id": "Identificativo compagnia",
    "menu_beacon_id": "Identificativo beacon",
    "menu_beacon_type": "Tipo",
    "menu_beacon_level": "Livello",
    "menu_beacon_code_zone": "Codice zona",
    "menu_beacon_description": "Descrizione",
    "menu_tippy_uuid": "Corrisponde all'UUID del protocollo iBeacon, ossia una sequenza di 32 caratteri esadecimali",
    "menu_tippy_namespace_id": "Corrisponde al namespace ID del protocollo Eddystone, ossia una sequenza di 20 caratteri esadecimali",
    "menu_tippy_bbe9_major_minor": "Corrisponde agli 8 caratteri esadecimali stampati sul beacon Bluetooth e-BBE9",
    "menu_tippy_loc_ex_mac_address": "Corrisponde all'indirizzo MAC stampato sul beacon Bluetooth LOC-EX 01 (6 coppie di caratteri esadecimali separati da ':')",
    "menu_tippy_ibeacon_major_minor": "Corrisponde al Major + Minor del protocollo iBeacon, ossia una serie di 8 caratteri esadecimali",
    "menu_tippy_instance_id": "Corrisponde all'istanza ID del protocollo Eddystone, ossia una serie di 12 caratteri esadecimali",
    "menu_tippy_level": "0 per rdc, numero negativo per il sottosuolo (ad es.: -3)",
    "menu_tippy_code_zone": "Informazioni trasmesse tramite il protocollo SIA",
    "menu_tippy_description": "Localizzazione associata al beacon. È vietato utilizzare il carattere '_' quando l'opzione invio di messaggi a orologi Emerit è attiva a causa di bug (tale bug sarà corretto durante l'aggiornamento successivo)",
    "menu_err_ibeacon_company_id": "Identificativo della compagnia errato. Quest'ultimo corrisponde all'UUID. Deve essere costituito da 32 caratteri esadecimali.",
    "menu_err_ibeacon_beacon_id": "Identificativo del beacon errato. Quest'ultimo corrisponde al Major + Minor. Deve essere costituito da 8 caratteri esadecimali.",
    "menu_err_eddystone_company_id": "Identificativo della compagnia errato. Quest'ultimo corrisponde al namespace ID. Deve essere costituito da 20 caratteri esadecimali.",
    "menu_err_eddystone_beacon_id": "Identificativo del beacon errato. Quest'ultimo corrisponde all'istanza ID. Deve essere costituito da 12 caratteri esadecimali.",
    "menu_err_bbe9_beacon_id": "Identificativo del beacon errato. Quest’ultimo corrisponde agli 8 caratteri esadecimali stampati sul beacon Bluetooth e-BBE9.",
    "menu_err_bbe9_already_exist": "Un beacon Bluetooth e-BBE9 con questo identificativo esiste già.",
    "menu_err_loc_ex_beacon_id": "ID beacon errato. L'ID beacon corrisponde all'indirizzo MAC stampato sul beacon Bluetooth LOC-EX 01.",
    "menu_err_loc_ex_already_exist": "Esiste già un beacon Bluetooth LOC-EX 01 con questo identificatore.",
    "menu_err_ibeacon_already_exist": "Un beacon Bluetooth iBeacon con questo UUID e questo Major Minor esiste già.",
    "menu_err_eddy_already_exist": "Un beacon Bluetooth Eddystone con questo namespace ID e questo ID esiste già.",
    "menu_err_wrong_level": "Errore durante l'inserimento del livello. Quest'ultimo deve essere un numero positivo o negativo.",
    "menu_err_empty_desc": "La descrizione non può essere vuota.",
    "menu_err_wrong_code_zone": "Il codice zona deve essere composto da 3 cifre e compreso tra 001 e 999. Esempio: 007.",
    "menu_level_precision_with_ble": "Gestisci la modifica di livello",
    "menu_err_one_elem_missing_level": "Il livello non è stato specificato per tutti i beacon Bluetooth. Verificare l'elenco salvato.",
    "menu_err_wifi_level_precision_is_activated": "La gestione del livello con beacon Bluetooth è stata attivata per la localizzazione Wi-Fi. Questo parametro è quindi obbligatorio.",
    "menu_err_no_level_beacon": "La gestione della fase richiede l'aggiunta di almeno un beacon Bluetooth di tipo Level",
    "menu_enable_import_export_csv": "Importa/Esporta in formato .csv",
    "menu_title_refresh_ble_choice": "Frequenza di aggiornamento",
    "menu_scan_mode_fastest": "Aggiornamento a intervalli regolari (6 secondi, consumo elevato)",
    "menu_scan_mode_average": "Aggiornamento a intervalli regolari (10 secondi, consumo medio)",
    "menu_scan_mode_on_demand": "Aggiornamento solo alla trasmissione di un evento",
    "menu_ble_new_algo_activation_title":"Configurazione dell'algoritmo",
    "menu_ble_new_algo_activation":"Abilita il nuovo algoritmo di localizzazione dei beacon"
  },
  "internalrecipients": {
    "menu_name_recipient": "Nome del destinatario",
    "menu_duration_of_call_attempt_before_next_number": "Durata del tentativo di chiamata di avviso prima di passare al numero successivo",
    "menu_event_select_call_and_or_sip_messaging": "Seleziona chiamata e/o messaggio SIP",
    "menu_request_loc_with_sms_command": "Questo destinatario può richiedere la posizione mediante il comando SMS seguente: ?LOC",
    "menu_sip_scenario": "Trasmissione degli avvisi tramite account SIP",
    "menu_alert_configuration_not_available_with_pec": "Quando lo scenario di avviso 'SMS con presa in carico quindi chiamata' è attivato, la configurazione della ricezione degli avvisi viene eseguita automaticamente.",
    "menu_sens_sms_with_sip_not_possible": "L'invio di SMS verso un numero di telefono tramite un account SIP non è disponibile. È disponibile solo la chiamata.",
    "menu_err_sip_account_already_exist": "Un destinatario con questo identificativo SIP esiste già",
    "menu_receive_events_by_sip_messaging": "Questo destinatario riceve gli eventi mediante messaggio SIP",
    "menu_switch_watch_is_using_sip_account": "Questo destinatario utilizza un account SIP",
    "menu_recipient_added_with_success": "Destinatario aggiunto",
    "menu_recipient_modified_with_success": "Destinatario modificato",
    "menu_modify_recipient": "Modifica un destinatario",
    "menu_sip_account_id": "Identificativo SIP",
    "menU_sip_servel_url": "URL server SIP",
    "menu_icon_sms_desc": ": Questo destinatario riceve gli avvisi via SMS",
    "menu_icon_phone_desc": ": Questo destinatario riceve gli eventi mediante chiamata",
    "menu_icon_watch_desc": ": Questo destinatario è un orologio Emerit",
    "menu_icon_sip_desc": ": Questo destinatario utilizza un account SIP",
    "menu_number_or_sip_id": "Numero di chiamata/Identificativo SIP",
    "menu_remove_all_recipients": "Eliminare tutti i destinatari salvati?",
    "menu_remove_one_recipient": "Eliminare questo destinatario?",
    "menu_add_recipient": "Aggiungi un destinatario",
    "menu_sms_and_calls_cycle": "SMS e chiamate a cascata",
    "menu_sms_then_calls_cycle": "SMS quindi chiamate a cascata",
    "menu_sms_with_acknowledgment_then_call": "SMS con presa in carico quindi chiamata",
    "menu_scenario_is": "Scenario di avviso: ",
    "menu_settings": "Impostazioni",
    "menu_nb_recipients_saved": "Numero di destinatari interni salvati: ",
    "menu_is_emerit_watch": "Questo destinatario è un orologio Emerit",
    "menu_internal_recipients": "Destinatari interni",
    "menu_monitoring_center_call_numbers": "Numeri di chiamata del centro di monitoraggio remoto",
    "menu_recipient": "Destinatario",
    "menu_enable_recipient": "Attiva il destinatario",
    "menu_call_number": "Numero di chiamata",
    "menu_enable_call_number": "Attiva il numero di chiamata",
    "menu_name": "Nome",
    "menu_phone_number_international_format": "Numero di telefono (formato internazionale)",
    "menu_receive_alerts_by_call": "Questo destinatario riceve gli eventi mediante chiamata",
    "menu_receive_events_by_sms": "Questo destinatario riceve gli eventi via SMS",
    "menu_event_alerts": "Avvisi",
    "menu_event_system_status": "Stato del sistema",
    "menu_event_charge_status": "Stato della ricarica",
    "menu_event_low_battery": "Batteria debole",
    "menu_event_beacon_ble_low_battery": "Batteria Bluetooth Beacon scarica",
    "menu_event_select_call_and_or_sms": "Seleziona chiamata e/o SMS",
    "menu_nfc_reading": "Lettura del tag NFC"
  },
  "internalrecipientssettings": {
    "menu_internal_recipients_settings": "Impostazioni destinatari interni",
    "menu_sms_language_selection": "Selezione della lingua degli SMS",
    "menu_alert_scenario_type": "Selezione dello scenario di avviso",
    "menu_sms_and_calls_cycle": "SMS e chiamate a cascata",
    "menu_sms_then_calls_cycle": "SMS quindi chiamate a cascata",
    "menu_sms_with_acknowledgment_then_call": "SMS con presa in carico quindi chiamata",
    "menu_maximum_waiting_time_for_acknowledgment_reception": "Tempo di attesa massimo per la ricezione di SMS di presa in carico",
    "menu_call_cycle_stopped_by_sms": "Interruzione del ciclo di chiamate via SMS",
    "menu_show_sms_data_selection": "Visualizza la selezione dei dati SMS",
    "menu_sms_data_selection": "Selezione dei dati SMS",
    "menu_basic_data": "Dati di base",
    "menu_gps_data": "Dati GPS",
    "menu_device_user": "Utente del dispositivo",
    "menu_battery_level": "Livello di batteria",
    "menu_event_timestamp": "Marcatura temporale dell'evento",
    "menu_google_map_link": "Link Google Map",
    "menu_timestamp": "Marcatura temporale",
    "menu_adress_google_reverse_geocoding": "Indirizzo (Google reverse geocoding)",
    "menu_accuracy": "Precisione",
    "menu_coordinates_DMS_format": "Coordinate formato DMS",
    "menu_coordinates_DD_format": "Coordinate formato DD",
    "menu_alert_labels_customization": "Personalizzazione delle didascalie di avviso",
    "menu_show_alert_labels_customization": "Visualizza la personalizzazione delle didascalie di avviso",
    "menu_request_for_help": "richiesta di soccorso (predefinita)",
    "menu_assault": "aggressione (predefinita)",
    "menu_no_movement": "immobilità (predefinita)",
    "menu_tilt": "inclinazione (predefinita)"
  },
  "sipconfiguration": {
    "menu_title": "VOIP",
    "menu_sip_need_configuration": "Configurazione del VOIP",
    "menu_account_name": "Descrizione dell'account",
    "menu_user_name": "Nome utente",
    "menu_user_password": "Password",
    "menu_server_url": "URL del server"
  },
  "ipserversettings": {
    "menu_std_gps_timestamp(O)": "[H] Timestamp posizione GPS",
    "menu_extended_data_T2i_format_selection": "Selezione estesa dei dati in formato T2i©",
    "menu_T2i_format": "Formato T2i©",
    "menu_sia_protocol_settings": "Impostazioni protocollo SIA DC-09",
    "menu_encapsulated_alarm_protol": "Protocollo di avviso incapsulato",
    "menu_admeco_cid_contact_id": "Ademco Contact ID (CID)",
    "menu_sia_dcs": "SIA DCS",
    "menu_extended_data_format_and_selection": "Formato e selezione dei dati estesi",
    "menu_standard_format": "Formato standard",
    "menu_esi_format": "Formato ESI©",
    "menu_add_the_message_ending_timestamp": "Aggiungi la marcatura temporale della fine del messaggio",
    "menu_extended_data_standard_format_selection": "Selezione dati estesi in formato standard",
    "menu_extended_data_esi_format_selection": "Selezione dati estesi in formato ESI©",
    "menu_std_battery": "[B] Livello di batteria",
    "menu_std_event_timestamp": "[H] marcatura temporale evento",
    "menu_std_imei": "[D] IMEI del dispositivo",
    "menu_std_beacon_id": "[C] identificativo del beacon di localizzazione Wi-Fi/Bluetooth",
    "menu_std_gps_accuracy": "[M] precisione posizione GPS",
    "menu_std_gps_timestamp": "[O] marcatura temporale posizione GPS",
    "menu_std_gps_speed": "[S] velocità posizione GPS",
    "menu_std_gps_validity": "[V] validità posizione GPS",
    "menu_std_gps_altitude": "[Z] altitudine posizione GPS",
    "menu_esi_battery_level": "$B Livello di batteria",
    "menu_esi_gps_timestamp": "$T marcatura temporale posizione GPS",
    "menu_gps_coordinates_format": "Formato delle coordinate GPS",
    "menu_gps_DD": "DD Gradi decimali",
    "menu_gps_DDM": "DM Gradi minuti decimali",
    "menu_customed_field": "Campo personalizzato",
    "menu_lpref": "Lpref",
    "menu_monitoging_server_sia_protocol_settings": "Impostazioni protocollo SIA DC-09 per server di supervisione",
    "menu_monitoring_center_server_sia_protocol_settings": "Impostazioni protocollo SIA DC-09 per server monitoraggio remoto",
    "menu_location_maximum_age": "Periodo massimo della localizzazione",
    "menu_location_maximum_accuracy": "Precisione massima della localizzazione"
  },
  "ipserver": {
    "menu_unit_description_message":"Messaggi",
    "menu_event_end_tracking_stop_post_alert":"Fine del tracciamento alla fine dell'allarme di invio postale",
    "menu_event_end_tracking_after_x_message":"Fine del tracciamento dopo l'invio di ",
    "menu_event_permanents":"Permanenti",
    "menu_event_trigger_alert":"All'attivazione di un'allerta",
    "menu_enable_link_test":"Abilita il test di collegamento",
    "menu_link_test":"Test di collegamento",
    "menu_alarm_monitoring_center_server": "Server del centro di monitoraggio degli allarmi",
    "menu_enable_main_server": "Abilita il server principale",
    "menu_main_server": "Server principale",
    "menu_events_to_be_transmitted": "Eventi da trasmettere",
    "menu_client_id": "ID del cliente",
    "menu_ip_url": "Indirizzo IP o URL",
    "menu_port": "Numero di porta",
    "menu_enable_cyclic_test": "Abilita il tracciamento",
    "menu_cyclic_test": "Tracciamento",
    "menu_test_interval": "Intervallo di invio",
    "menu_backup_server": "Server di backup",
    "menu_backup_sms": "Backup SMS",
    "menu_enable_backup_server": "Abilita il server di backup",
    "menu_enable_backup_sms": "Abilita il backup SMS",
    "menu_port_must_be_between_0_and_65536": "Il numero di porta deve essere compreso tra 0 e 65536"
  },
  "wearingways": {
    "menu_waist_worn": "porta alla dimensione",
    "menu_enable_no_movement_detection": "Attiva il rilevamento di immobilità",
    "menu_enable_tilt_detection": "Attiva il rilevamento di inclinazione",
    "menu_no_movement_detection": "Rilevamento di immobilità",
    "menu_tilt_detection": "Rilevamento di inclinazione",
    "menu_detection_duration_before_prealert": "Durata di rilevamento prima della notifica di preavviso",
    "menu_detection_threshold": "Soglia di rilevamento",
    "menu_detection_mode": "Modalità di rilevamento",
    "menu_tilt_only": "Inclinazione sola",
    "menu_tilt_without_movement": "Inclinazione senza movimento",
    "menu_select_a_wearing_way_and_modify_settings_if_needed": "Seleziona una porta e modifica le impostazioni, se necessario"
  },
  "wristalert": {
    "menu_noise_mode": "Altoparlante attivato > richiesta di soccorso",
    "menu_discret_mode": "Silenzioso > contesto di aggressione",
    "menu_choose_alert_mode": "Modalità di avviso",
    "menu_title_wrist_alert": "Impostazione dell'avviso tramite movimento del polso",
    "menu_choose_threshold": "Intensità del movimento per attivare l'avviso",
    "menu_thresold_value_low": "Poco rapido",
    "menu_thresold_value_middle": "Mediamente rapido (raccomandato)",
    "menu_thresold_value_high": "Molto rapido"
  },
  "login": {
    "menu_first_device_boot_error": "Se si tratta della prima configurazione di un dispositivo, è necessario seguire una procedura di messa in servizio prima di effettuare la configurazione di quest'ultimo.",
    "menu_quick_start_guide": "Assistenza online",
    "menu_home_help": "https://magneta.document360.io/v1/en",
    "menu_connexion_error": "Si è verificato un problema di connessione. Verificare la connessione a Internet.",
    "menu_enter_five_last_digits_of_serial_number": "Inserire le ultime 5 cifre del numero di serie (SN)",
    "menu_enter_five_last_digits_of_imei_number": "Inserire le ultime 5 cifre del numero IMEI",
    "menu_login": "Connessione",
    "menu_select_device_model": "Seleziona il modello di dispositivo",
    "menu_please_select_the_device_model": "Seleziona innanzitutto il modello di dispositivo",
    "menu_entered_numbers_dont_match_with_available_device": "I numeri inseriti non corrispondono ad alcun dispositivo oppure si è verificato un problema di connessione.",
    "menu_please_read_quick_start_guide_first": "Leggere la guida di avvio rapido.",
    "menu_configure_this_device": "Configura questo dispositivo",
    "menu_configuration_models_management": "Gestione dei modelli di configurazione",
    "menu_deploy_a_configuration_model": "Distribuisci un modello di configurazione",
    "menu_invalid_file": "File non valido",
    "menu_only_file_with_emerit_extension_are_supported": "Sono presi in carico solo i file con estensione .emerit",
    "menu_create_configuration_model": "Crea un modello di configurazione",
    "menu_modify_a_configuration_model": "Modifica un modello di configurazione",
    "menu_drag_and_drop_file_or_click_to_select": "Seleziona/trascina o fai clic per selezionare il file modello di configurazione",
    "menu_configuration_model": "Modello di configurazione",
    "menu_configuration_model_creation": "Creazione di un modello di configurazione",
    "menu_connect_to_a_device": "Collegarsi a un dispositivo",
    "menu_models_dont_match": "Il file selezionato non è conforme o il modello di configurazione non è compatibile con il modello selezionato.",
    "menu_select_language": "Seleziona la lingua"
  },
  "wifi": { 
    "menu_mode_utilisation": "Aggiornamento fuso orario in base alla modalità di utilizzo",
    "menu_select_utc": "Seleziona un fuso orario",
    "menu_error_utc_none": "Seleziona un fuso orario, per favore",
    "menu_mode_wifi_alone": "Solo WiFi:",
    "menu_mode_gsm_plus_wifi": "WiFi + GSM (scheda SIM): aggiornamento automatico",
    "menu_certificate_upload_success": "Certificato caricato con successo",
    "menu_certificate_upload_fail": "Download del certificato non riuscito",
    "menu_err_pls_upload_certificate": "Scaricare un certificato",
    "menu_err_only_pfx_p12_accepted": "Si accettano solo certificati in formato .pfx/.p12.",
    "menu_err_certificate_is_too_heavy": "La dimensione del certificato è troppo grande, non può superare i 5Mb.",
    "menu_upload_certificat": "Importare il certificato PEAP (.pfx/.p12)",
    "menu_certificate_password": "Password",
    "menu_certificate_identifiant": "Identificativo",
    "menu_certificate_type": "CA Certificat",
    "menu_name_is_update_app_by_wifi_enabled": "Recuperare configurazioni e installare aggiornamenti solo se il Wi-Fi è attivo",
    "menu_use_wifi_connection": "Utilizza la connessione Wi-Fi per l'invio di avvisi",
    "menu_config_wifi_network": "Gestisci le reti Wi-Fi",
    "menu_nb_wifi_network_saved": "Numero di reti Wi-Fi salvate: ",
    "menu_send_alert_with_wifi": "La rete Wi-Fi, se disponibile, sarà impostata come prioritaria sulla rete GSM",
    "menu_network_added_with_success": "Rete Wi-Fi aggiunta",
    "menu_network_modified_with_success": "Rete Wi-Fi modificata",
    "menu_remove_all_networks": "Eliminare tutte le reti Wi-Fi salvate?",
    "menu_remove_one_network": "Eliminare questa rete Wi-Fi?",
    "menu_registred_wifi_networks_list": "Elenco delle reti Wi-Fi salvate - Solo 2,4 GHz",
    "menu_ssid": "Nome della rete SSID",
    "menu_security_type": "Tipo di sicurezza",
    "menu_password": "Password",
    "menu_add_a_wifi_network": "Aggiungi una rete Wi-Fi",
    "menu_modify_a_wifi_network": "Modifica una rete Wi-Fi",
    "menu_do_you_want_to_remove_this_network": "Eliminare questa rete?",
    "menu_please_select_security_type": "Selezionare un tipo di sicurezza",
    "menu_hidden": "Rete nascosta",
    "menu_network_already_exists": "La rete esiste già"
  },
  "whitelist": {
    "menu_add_a_recipient_whitelist": "Aggiungi un numero in entrata aggiuntivo",
    "menu_modify_a_recipient_whitelist": "Modifica un numero in entrata aggiuntivo",
    "menu_recipient_whitelist_modified_with_success": "Numero in entrata aggiuntivo modificato correttamente",
    "menu_recipient_whitelist_added_with_success": "Numero in entrata aggiuntivo aggiunto correttamente",
    "menu_nb_recipient_whitelist": "Numeri in entrata aggiuntivi: ",
    "menu_nb_recipient_mirror": "Destinatari degli avvisi: ",
    "menu_remove_all_recipient_whitelist": "Eliminare tutti i numeri in entrata aggiuntivi dalla whitelist?",
    "menu_remove_one_recipient_whitelist": "Eliminare questo numero in entrata aggiuntivo dalla whitelist?",
    "menu_add_whitelist_recipient": "Aggiungi un numero in entrata",
    "menu_nb_total_whitelist_recipient": "Numero totale di numeri in entrata autorizzati nella whitelist: ",
    "menu_whitelist_title": "Configurazione della whitelist"
  },
  "customscreens": {
    "menu_img_upload_info": "La dimensione di un'immagine .png/.gif non deve superare 1 MB. La risoluzione dello schermo dell'orologio è 128x96 pixel. Per una resa ottimale dell'immagine, rispettare l'altezza/larghezza dello schermo (1.33). Se il rapporto non viene rispettato, l'immagine verrà ridimensionata automaticamente.",
    "menu_err_pls_upload_launch_screen": "Scaricare un'immagine.",
    "menu_err_pls_upload_widget_screen": "Scaricare un'immagine o inserire un testo.",
    "menu_err_only_png_gif_accepted": "Sono accettate solo immagini in formato .png/.gif.",
    "menu_err_img_is_too_heavy": "Le dimensioni dell'immagine sono molto importanti, non possono superare 1 MB.",
    "menu_img_upload_success": "Immagine scaricata correttamente",
    "menu_img_upload_fail": "Errore durante il download dell'immagine",
    "menu_custom_screens_overview": "Anteprima",
    "menu_remove_launch_img": "Elimina l'immagine",
    "menu_remove_widget_img": "Elimina l'immagine di sfondo",
    "menu_display_widget_text_in_black": "Visualizza il testo in nero",
    "menu_custom_widget_top_text": "Testo alto (massimo 18 caratteri, spazi inclusi)",
    "menu_custom_widget_bottom_text": "Testo basso (massimo 18 caratteri, spazi inclusi)",
    "menu_upload_launch_screen_img": "Scarica una nuova immagine (.png/.gif)",
    "menu_upload_widget_screen_img": "Scarica una nuova immagine di sfondo (.png/.gif)",
    "menu_title_yes_custom_launch_screen": "Configurazione dell'immagine di avvio",
    "menu_title_no_custom_launch_screen": "Personalizza l'immagine di avvio",
    "menu_title_yes_custom_widget_screen": "Configurazione del widget contatto",
    "menu_title_no_custom_widget_screen": "Aggiungi un widget contatto",
    "menu_custom_screens_title": "Personalizza la visualizzazione"
  },
  "password": {
    "menu_create_new_password_for_model": "Inserire una password di 6 caratteri, di cui almeno 1 lettera minuscola, 1 lettera maiuscola e 1 cifra, senza caratteri speciali. Questa password sarà richiesta a ogni modifica di questo modello.",
    "menu_enter_password": "Immetti la password",
    "menu_enter_deployment_password": "Immetti la password di distribuzione",
    "menu_create_new_password": "Immettere una password di 6 caratteri, che includa almeno 1 lettera minuscola, 1 lettera maiuscola e 1 cifra, senza caratteri speciali. Questa password sarà richiesta a ogni connessione a questo dispositivo.",
    "menu_confirm_password": "Conferma la password",
    "menu_wrong_password": "Password errata",
    "menu_please_retry": "Riprovare",
    "menu_empty_password": "Password vuota",
    "menu_passwords_dont_match": "Password non corrispondenti",
    "menu_at_least_one_password_is_empty": "Almeno una password è vuota",
    "menu_password_dont_meet_requirements": "La password non è conforme ai requisiti",
    "menu_modify_password": "Modifica password",
    "menu_password_creation_warning": "Collegandosi a emerit-console e dopo aver creato la password, si diviene amministratore del dispositivo identificato sopra"
  },
  "contact": {
    "menu_contact_link": "https://www.magneta.fr/contactez-nous/",
    "menu_if_you_have_any_question_please_click_on_the_folowing_button": "Per qualsiasi domanda relativa all'utilizzo o alla configurazione, fare clic sul pulsante seguente:",
    "menu_technical_support_email": "E-mail dell'assistenza tecnica:",
    "menu_online_support_request_form": "Modulo di richiesta di assistenza online"
  },
  "deploy": {
    "menu_upload_failed": "Distribuzione non riuscita, controlla la connessione a Internet e riprova",
    "menu_device_updated": "Dispositivo aggiornato",
    "menu_fill_server_ids": "Configurazione ID client",
    "menu_fill_personal_fields": "Compila i dati personalizzati",
    "menu_target_a_device": "Dispositivo da configurare",
    "menu_client_id_for_tls_main_server": "ID client per il server principale di monitoraggio remoto",
    "menu_client_id_for_tls_backup_server": "ID client per il server secondario di monitoraggio remoto",
    "menu_client_id_for_supervision_main_server": "ID client per il server principale di supervisione",
    "menu_client_id_for_supervision_backup_server": "ID client per il server secondario di Supervisione",
    "menu_from_a_configuration_model": "A partire dal modello di configurazione",
    "menu_five_last_digits_of_serial_number": "Ultime 5 cifre del numero di serie (SN)",
    "menu_five_last_digits_of_imei_number": "Ultime 5 cifre del numero IMEI",
    "menu_deploy_not_allowed": "Non si dispone dell'autorizzazione per configurare questo dispositivo mediante distribuzione"
  },
  "side_menu": {
    "configuration": "Configurazione",
    "info": "Manuali",
    "contact": "Contatto"
  },
  "watchhelp": {
    "menu_custom_screens_view": "https://www.support.magneta.fr/docs/en/watch-system-options#ii-customize-screen",
    "menu_locex_help": "https://www.support.magneta.fr/docs/en/beacon-loc-ex-01",
    "menu_wifi_connection_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#i-use-wifi-connection",
    "menu_sip_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#ii-activate-voice-over-ip-voip-with-sip-account",
    "menu_whitelist_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#iii-whitelist-incoming-call-limitation",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/en/watch-main-page#i-device-user-name",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/en/watch-main-page#ii-alerts-processing",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/en/watch-main-page#iii-alerts-triggering-mode",
    "menu_loc": "https://www.support.magneta.fr/docs/en/watch-main-page#iv-alerts-localization",
    "menu_communication_option": "https://www.support.magneta.fr/docs/en/watch-main-page#v-communication-options",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/en/watch-main-page#vi-battery-monitoring",
    "menu_device_notif_management": "https://www.support.magneta.fr/docs/en/watch-main-page#vii-device-notification-management",
    "menu_system_option": "https://www.support.magneta.fr/docs/en/watch-main-page#viii-system-options",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9#associating-a-type-to-beacons",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#configuring-personal-settings",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#entering-the-deployment-password",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#templates-modification",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#configuration-template-creation",
    "menu_model_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#ii-multidevice-setup",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#iii-deployment-of-a-configuration",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#enter-password",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#password-creation",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#i-gps-outdoor-location",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#ii-wifi-indoor-location",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#iii-bluetooth-indoor-location",
    "menu_main_page": "https://www.support.magneta.fr/docs/en/watch-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/en/watch-commissioning#iv-first-boot"
  },
  "phonehelp": {
    "menu_locex_help": "https://www.support.magneta.fr/docs/en/beacon-loc-ex-01",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/en/smartphones-main-page#i-device-user-name",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/en/smartphones-main-page#ii-alerts-processing",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/en/smartphones-main-page#iii-alerts-triggering-mode",
    "menu_loc": "https://www.support.magneta.fr/docs/en/smartphones-main-page#iv-alerts-localization",
    "menu_communication_option": "https://www.support.magneta.fr/docs/en/smartphones-main-page#v-communication-options",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/en/smartphones-main-page#vi-battery-monitoring",
    "menu_deactivate_alert_system": "https://www.support.magneta.fr/docs/en/smartphones-main-page#vii-alert-system-deactivation-by-the-user",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9#associating-a-type-to-beacons",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#configuring-personal-settings",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#entering-the-deployment-password",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#templates-modification",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#configuration-template-creation",
    "menu_model_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#ii-multidevice-setup",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#iii-deployment-of-a-configuration",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#enter-password",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#password-creation",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#i-gps-outdoor-location",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#ii-wifi-indoor-location",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#iii-bluetooth-indoor-location",
    "menu_main_page": "https://www.support.magneta.fr/docs/en/smartphones-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/en/smartphones-commissioning#ii-first-boot"
  }
}
