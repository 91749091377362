<template>
  <div>
    <Title 
      title="locationindoorble.menu_ble_based_indoor_location_transparent"
      help-locale="menu_loc_indoor_ble"
    />
     <div class="grid grid-cols-12 gap-6 mt-6">
      <BleBeaconSettings
      ref=bleBeaconSettings 
      />
      <AddBleFiltered 
        :beacon-filtered-list="beaconFilteredList"
        @add-beacon-filtered-success="addNewBeacon"
      />
    </div>
     <BleFilteredList 
        :beacon-filtered-list="beaconFilteredList"
        @remove-beacon-filtered="initModalConfirm"
      />
    <ModalAlertConfirmChoice
      ref="modalAlertConfirmChoice"
      modal-id="modal-alert-confirm"
      alert-title="common.menu_are_you_sure"
      :alert-text="modalAlertText"
      icon-component-name="AlertTriangleIcon"
      left-button-component-name="ButtonDismissModal"
      left-button-text="common.menu_cancel"
      left-button-custom-class="btn btn-outline-secondary mr-1"
      right-button-component-name="ButtonDismissModal"
      right-button-text="common.menu_remove"
      right-button-custom-class="btn btn-danger"
      @right-button="remove"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import AddBleFiltered from './local-components/lc-add-ble-filtered.vue'
import BleFilteredList from './local-components/lc-ble-filtered-list.vue'
import BleBeaconSettings from './local-components/lc-ble-settings-transparent.vue'

import {
  getBleTransparent,
  addBleTransparent,
  deleteAllBleTransparent,
  deleteBleTransparent
} from '../../dao/transparent-indoor-ble-dao'
import { createBeaconFromDbEntry } from './local-components/utils'

const REMOVE_EVERYTHING = 0;
const REMOVE_ONE_ITEM = 1;

const ALERT_REMOVE_EVERYTHING = "locationindoorble.menu_remove_all_beacons"
const ALERT_REMOVE_ONE_BEACON = "locationindoorble.menu_remove_one_beacon_transparent"

const BODY = "BODY";

const MODAL_ID_ALERT_CONFIRM = "modal-alert-confirm";

export default defineComponent({
  
  components: {
  
      BleBeaconSettings,
      AddBleFiltered,
      BleFilteredList,
  },

  beforeRouteLeave (to, from, next) {
    this.$refs.bleBeaconSettings.save();
    this.$refs.modalAlertConfirmChoice.dismiss();
    if (this.showImportExportButtons)
      this.$refs.ImportExportBleBeacon.dismissModal();
    let intervalId = setInterval(() => {  
      const modalAlertConfirm = document.getElementById(MODAL_ID_ALERT_CONFIRM);
      let modalAlertConfirmUpload = undefined;
      if (modalAlertConfirm.parentElement.tagName.valueOf() != BODY
          && ((this.showImportExportButtons 
              && modalAlertConfirmUpload.parentElement.tagName.valueOf() != BODY)
              || !this.showImportExportButtons)) {
        clearInterval(intervalId);
        next();
      }
    }, 100);
	},

  data() {
      return {
        removeType: undefined,
        beaconFilteredList: new Array(),
        currentIndex: 0,
      }
  },

  computed: {
    modalAlertText() {
      return this.removeType == REMOVE_ONE_ITEM ? 
        ALERT_REMOVE_ONE_BEACON : ALERT_REMOVE_EVERYTHING;
    },
  },

  created() {
    const dbBeacons = getBleTransparent();
    if (dbBeacons != undefined) {
      for (let i = 0; i < dbBeacons.values.length; ++i) {
        const value = dbBeacons.values[i][1];
          this.beaconFilteredList.push(createBeaconFromDbEntry(dbBeacons.values[i]));
      }
    }
  },

  methods: {
    addNewBeacon(newBeacon) {
      let newFilteredBeaconName = [newBeacon[0]]
      this.beaconFilteredList.push(newFilteredBeaconName);
      // TODO: Update la BDD
      addBleTransparent(newBeacon);
    },

    remove() {
      if (this.removeType === REMOVE_ONE_ITEM) {
        deleteBleTransparent(this.beaconFilteredList[this.currentIndex]);
        this.beaconFilteredList.splice(this.currentIndex, 1);
      }
      else {
        deleteAllBleTransparent();
        this.beaconFilteredList = [];
      }
    },


    initModalConfirm(index) {
      this.currentIndex = index == undefined ? this.currentIndex : index; 
      this.removeType = index == undefined ? REMOVE_EVERYTHING : REMOVE_ONE_ITEM;
    },
  },
})

</script>