<template>
  <!-- BEGIN: AddWifiHotspot local component-->
  <div class="intro-y lg:col-span-5 lg:col-start-6 2xl:col-span-4 2xl:col-start-5 col-span-12">
    <div class="clearfix box mb-6">
      <BoxTitle
        color="bg-red-300"
        title="locationindoorwifi.menu_add_manually_wifi_hotspots"
      />
      <div class="clearfix p-3 border-b border-gray-200">
        <div class="grid grid-cols-10 gap-3">
          <Tippy
            v-for="(field, index) in getHotspotFields"
            :key="index"
            :content="$t(field.tippy)"
            :options="{
              theme: 'light',
              delay: index,
              onShow: updateTippyContent,
            }"
            :class="field.class"
          >
            <CustomTextInput
              v-model="field.value"
              :title="field.title"
              :max-length="field.len"
            />
          </Tippy>
          <CustomButton
            custom-class="lg:mt-6 col-start-9 col-span-2 btn btn-primary mb-2 mt-4"
            text="common.menu_add"
            @click="check()"
          />
          <ErrorMsg
            v-for="(field, index) in getHotspotFields"
            :key="index"
            :error-msg="field.errorMsg"
            class="col-start-1 col-span-10"
          />
        </div>
      </div>
    </div>
    <CustomToast
      ref="toastAddHotspotSuccess"
      toast-id="toast-add-hotspot-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="locationindoorwifi.menu_add_hotspot_success"
    />
    <CustomToast
      ref="toastAddHotspotFailed"
      toast-id="toast-add-hotspot-failed"
      icon-component-name="XCircleIcon"
      icon-component-class="text-theme-6"
      text="locationindoorwifi.menu_add_hotspot_failed"
    />
  </div>
  <!-- END: AddWifiHotspot local component-->
</template>

<script>
import { defineComponent } from 'vue'
import { formatLevel } from './utils'
import { regexCheck } from '../../../utils/fieldManagement'


const SSID = 0;
const BSSID = 1;
const LEVEL = 2;
const DESC = 3;
const CODE_ZONE = 4;

const SSID_TABLE_COMPONENT_INDEX = 0;
const BSSID_TABLE_COMPONENT_INDEX = 1;
const CODE_ZONE_TABLE_COMPONENT_INDEX = 2;
const DESC_TABLE_COMPONENT_INDEX = 3;
const LEVEL_TABLE_COMPONENT_INDEX = 4;

const BSSID_LEN = 17;
const WRONG_CODE_ZONE = "000";

const ERR_SSID = "locationindoorwifi.menu_err_empty_ssid";
const ERR_BSSID = "locationindoorwifi.menu_err_wrong_bssid";
const ERR_BSSID_ALREADY_EXIST = "locationindoorwifi.menu_err_bssid_already_exist";
const ERR_LEVEL = "locationindoorwifi.menu_err_wrong_level";
const ERR_DESC = "locationindoorwifi.menu_err_empty_desc";
const ERR_CODE_ZONE = "locationindoorwifi.menu_err_wrong_code_zone";

const SSID_TITLE = 'locationindoorwifi.menu_hotspot_ssid';
const BSSID_TITLE = 'locationindoorwifi.menu_hotspot_bssid';
const LEVEL_TITLE = 'locationindoorwifi.menu_hotspot_level';
const DESC_TITLE = 'locationindoorwifi.menu_hotspot_description';
const CODE_ZONE_TITLE = 'locationindoorwifi.menu_hotspot_code_zone';

const SSID_CLASS = "col-span-10 lg:col-span-4 lg:col-start-1";
const BSSID_CLASS = "col-span-10 lg:col-span-4 lg:col-start-5";
const LEVEL_CLASS = "col-span-10 lg:col-span-2 lg:col-start-9";
const DESC_CLASS = "col-span-10 lg:col-span-6 lg:col-start-1";
const CODE_ZONE_CLASS = "col-span-10 lg:col-span-2 lg:col-start-7";

const SSID_TIPPY = 'locationindoorwifi.menu_tippy_ssid';
const BSSID_TIPPY = 'locationindoorwifi.menu_tippy_bssid';
const LEVEL_TIPPY = 'locationindoorwifi.menu_tippy_level';
const DESC_TIPPY = 'locationindoorwifi.menu_tippy_description';
const CODE_ZONE_TIPPY = 'locationindoorwifi.menu_tippy_code_zone';


export default defineComponent({

  props: {
    hotspotList: {
      type: Array,
      required: true,
    },
    isBleLevelActivated: {
      type: Boolean,
      required: true,
    }
  },

  emits: ['addHotspotSuccess'],

  data() {
    return {
      hotspotFields: [
        { title: SSID_TITLE, class: SSID_CLASS, tippy: SSID_TIPPY, value: '', len: 50, errorMsg: '' },
        { title: BSSID_TITLE, class: BSSID_CLASS, tippy: BSSID_TIPPY, value: '', len: 17, errorMsg: '' },
        { title: LEVEL_TITLE, class: LEVEL_CLASS, tippy: LEVEL_TIPPY, value: '', len: 3, errorMsg: '' },
        { title: DESC_TITLE, class: DESC_CLASS, tippy: DESC_TIPPY, value: '', len: 50, errorMsg: '' },
        { title: CODE_ZONE_TITLE, class: CODE_ZONE_CLASS, tippy: CODE_ZONE_TIPPY, value: '', len: 3, errorMsg: '' },
      ],
    }
  },

  computed: {
    getHotspotFields() {
      const len = this.isBleLevelActivated ? this.hotspotFields.length : this.hotspotFields.length - 1;
      let newHotspotFields = new Array(len);
      for (let i = 0, j = 0; i < this.hotspotFields.length; ++i, ++j) {
        if (!this.isBleLevelActivated && i === LEVEL)
          --j;
        else 
          newHotspotFields[j] = this.hotspotFields[i];
      }
      return newHotspotFields;
    },
  },

  methods: {

    updateTippyContent(instance) {
      const index = instance.props.delay;
      instance.setContent(this.$t(this.getHotspotFields[index].tippy));
    },

    check() {
      let error = this.hotspotFields.length;
      error -= this.checkSsid();
      error -= this.checkBssid();
      error -= this.checkLevel();
      error -= this.checkCodeZone();
      error -= this.checkDescription();
      if (!error)
        this.emitAddHotspotSuccess();
      else
        this.$refs.toastAddHotspotFailed.trigger();
    },

    emitAddHotspotSuccess() {
      this.$emit('addHotspotSuccess', this.createNewHotspot());
      this.$refs.toastAddHotspotSuccess.trigger();
      this.resetAllFields();
    },

    createNewHotspot() {
      let newHotspot = new Array(this.hotspotFields.length);
      newHotspot[SSID_TABLE_COMPONENT_INDEX] = this.hotspotFields[SSID].value;
      newHotspot[BSSID_TABLE_COMPONENT_INDEX] = this.hotspotFields[BSSID].value.toUpperCase();
      newHotspot[CODE_ZONE_TABLE_COMPONENT_INDEX] = this.hotspotFields[CODE_ZONE].value;
      newHotspot[DESC_TABLE_COMPONENT_INDEX] = this.hotspotFields[DESC].value;
      newHotspot[LEVEL_TABLE_COMPONENT_INDEX] = formatLevel(this.hotspotFields[LEVEL].value);
      return newHotspot
    },

    checkSsid() {
      const ssid = this.hotspotFields[SSID].value;
      if (ssid.length < 1 || ssid.length > 50)
        return this.setError(SSID, ERR_SSID);
      return this.resetError(SSID);
    },

    checkBssid() {
      const bssid = this.hotspotFields[BSSID].value.toUpperCase();
      if (bssid.length != BSSID_LEN)
        return this.setError(BSSID, ERR_BSSID);
      for (let i = 0; i < BSSID_LEN; ++i) {
        if (!((i + 1) % 3) && bssid[i] != ':')
          return this.setError(BSSID, ERR_BSSID);
        if (((i + 1) % 3) && !String(bssid[i]).match(/[A-F0-9]/))
          return this.setError(BSSID, ERR_BSSID);
      }
      for (let i = 0; i < this.hotspotList.length; ++i)
        if (bssid.valueOf() === this.hotspotList[i][BSSID].toUpperCase().valueOf())
          return this.setError(BSSID, ERR_BSSID_ALREADY_EXIST)
      return this.resetError(BSSID);
    },

    checkLevel() {
      if (!this.isBleLevelActivated)
        return true;
      const level = this.hotspotFields[LEVEL].value;
      if (!level.length || !regexCheck(level, /^(?:-?\d+)?$/))
        return this.setError(LEVEL, ERR_LEVEL);
      return this.resetError(LEVEL);
    },

    checkCodeZone() {
      const codeZone = this.hotspotFields[CODE_ZONE].value;
      if (!regexCheck(codeZone, /^[0-9]{3}$/) || codeZone.valueOf() === WRONG_CODE_ZONE)
        return this.setError(CODE_ZONE, ERR_CODE_ZONE);
      return this.resetError(CODE_ZONE);
    },

    checkDescription() {
      const desc = this.hotspotFields[DESC].value;
      if (desc.length < 1 || desc.length > 50)
        return this.setError(DESC, ERR_DESC);
      return this.resetError(DESC);
    },

    setError(index, err) {
      this.hotspotFields[index].errorMsg = err;
      return false;
    },

    resetAllFields() {
      for (let i = 0; i < this.hotspotFields.length; ++i)
        this.hotspotFields[i].value = '';
    },

    resetAllError() {
      for (let i = 0; i < this.hotspotFields.length; ++i)
        this.resetError(i);
      return true;
    },

    resetError(index) {
      if (this.hotspotFields[index].errorMsg.length)
        this.hotspotFields[index].errorMsg = '';
      return true;
    }
  }

})
</script>
