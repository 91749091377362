<template>
  <!-- BEGIN: CustomTable -->
  <div class="grid grid-cols-10">
    <div :class="`intro-y col-span-10 xl:col-span-${screenPercentWidth}`">
      <div :class="`intro-y col-span-10 xl:col-span-${screenPercentWidth} overflow-auto`">
        <table class="table table-report">
          <thead>
            <tr>
              <th
                v-for="(header, index) in headers"
                :key="index"
              >
                {{ $t(header) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <component
              :is="listElementComponentName" 
              v-for="(elem, index) in elemsList"
              :key="index"
              :elems-text="elem"
              :icons="getIcons(index)"
              :showing-errors="showingErrors"
              :green-button-text="greenButtonText"
              :red-button-text="redButtonText"
              :green-button-bootstrap-target="greenButtonBootstrapTarget"
              :red-button-bootstrap-target="redButtonBootstrapTarget"
              @green-button-clicked="$emit('elementGreenButtonClicked', index)"
              @red-button-clicked="$emit('elementRedButtonClicked', index)"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- END: CustomTable component -->
</template>


<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A table rendering a list of elements. Each element can have one or 
 * several text fields prompted to the user with optional header fields, and a
 * green and red button that can launch 2 different actions using boostrap
 * attribute data-target, like modifying or removing the element for example.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {Array[X Strings] | undefined} headers
 *    An array of Strings representing the headers of the table.
 *    Strings should be keys for i18 locale. Undefined by default, if 
 *    you do not need headers do not fill this props.
 * 
 * @param {Array[Array[X Strings]]} elemsList
 *    A double array, with each element being an array of Strings that will be 
 *    passed to the dynamic list element component, and used to render the 
 *    description of the element.
 * 
 * @param {Array[Array[X Strings]] | undefined} iconsList
 *    A double array, with each element being an array of Strings containing 
 *    name of dynamic icons components. Icons will be rendered at the right 
 *    of elemsList fields description. iconsList length should match elemsList
 *    length. Undefined by default, if you do not need icons do not fill this props.
 * 
 * @param {Boolean} showingErrors
 *    If sets to true, every empty fields in elementsText will be marked with
 *    red 'X'. If sets to false, emtpy fields will remain empty.
 * 
 * @param {Number} screenPercentWidth
 *    A number between 1 and 10 indicating the width of the table proportional
 *    to the screen, 10 being full width (table take full screen) and 1 
 *    the minimal width.
 * 
 * @param {String} listElementComponentName
 *    The name of the dynamic component that will be use to render the
 *    elements of the table as a list (ex : 'ListElement').
 * 
 * @param {String} greenButtonText
 *    The text prompted on the green button. Should be key for i18 locale.
 * 
 * @param {String} redButtonText
 *    The text prompted on the red button. Should be key for i18 locale.
 * 
 * @param {String} greenButtonBootstrapTarget
 *    The id of an HTML element that will be targeted by bootstrap when
 *    the user will click on the green button.
 * 
 * @param {String} redButtonBootstrapTarget
 *    The id of an HTML element that will be targeted by bootstrap when
 *    the user will click on the red button
 * 
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits elementGreenButtonClicked
 * @return {Number}
 *    Emits the index of the element inside elemsList when element's
 *    green button is clicked.
 * 
 * @emits elementRedButtonClicked
 * @return {Number}
 *    Emits the index of the element inside elemsList when element's
 *    red button is clicked.
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  props: {
    headers: {
      type: Array,
      default: undefined,
    },
    elemsList: {
      type: Array,
      required: true,
    },
    iconsList: {
      type: Array,
      default: undefined,
    },
    showingErrors: {
      type: Boolean,
      required: true,
    },
    screenPercentWidth: {
      type: Number,
      required: true,
    },
    listElementComponentName: {
      type: String,
      required: true,
    },
    greenButtonText: {
      type: String,
      required: true,
    },
    redButtonText: {
      type: String,
      required: true,
    },
    greenButtonBootstrapTarget: {
      type: String,
      required: true,
    },
    redButtonBootstrapTarget: {
      type: String,
      required: true,
    }
  },
  
  emits: ["elementGreenButtonClicked", 'elementRedButtonClicked'],

  methods: {
    
    getIcons(index) {
      if (!this.iconsList)
        return undefined;
      return this.iconsList[index];
    }
  }
})
</script>
