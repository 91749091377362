<template>
  <div>
    <div class="sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <LoginInfo :image-source="imageSource" />
        <div class="h-screen xl:h-auto flex py-5 xl:py-0">
          <div
            class="my-auto flex-none mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 md:w-3/4 lg:w-2/4 xl:w-3/5"
          >
            <DeviceInfo />
            <NotifDeviceUpdated :notif-state="isUpLoadSucceeded" @hide-notif="isUpLoadSucceeded = 0" />
            <h5 class="text-lg text-theme-1">
              {{ $t("deploy.menu_fill_personal_fields") }}
            </h5>
            <div @input="reset">
              <DeployDeviceId ref="DeployDeviceId" class="mt-3" />
              <DeployServerId v-if="isServerActivated" ref="DeployServerId" class="mt-3" />
              <SipDeploySettings v-if="isSipActivated" ref="SipDeploySettings" class="mt-4 w-full" />
            </div>
            <div class="flex flex-col items-center mt-5">
              <CustomButton
                v-if="!isUpLoadSucceeded"
                custom-class="btn btn-primary w-full"
                text="common.menu_confirm"
                @click="onConfirmButtonClicked"
              />
            </div>
          </div>
        </div>
      </div>
      <CustomToast
        ref="ToastWrongImeiSn"
        toast-id="toast-wrong-imei-sn"
        icon-component-name="XCircleIcon"
        icon-component-class="text-theme-6"
        text="login.menu_entered_numbers_dont_match_with_available_device"
      />
      <CustomToast
        ref="ToastDeployNotAllowed"
        toast-id="toast-deploy-is-not-allowed-on-the-target-device"
        icon-component-name="XCircleIcon"
        icon-component-class="text-theme-6"
        text="deploy.menu_deploy_not_allowed"
      />
      <CustomToast
        ref="ToastUploadFailed"
        toast-id="toast-upload-failed"
        icon-component-name="XCircleIcon"
        icon-component-class="text-theme-6"
        text="deploy.menu_upload_failed"
      />
      <CustomToast
        ref="ToastWrongConfig"
        toast-id="toast-wrong-config"
        icon-component-name="XCircleIcon"
        icon-component-class="text-theme-6"
        text="common.menu_wrong_config"
      />
      <CustomToast
        ref="ToastImgUploadFailure"
        toast-id="toast-img-upload-failure"
        icon-component-name="XCircleIcon"
        icon-component-class="text-theme-6"
        text="configuration.menu_img_upload_failure"
      />
      <CustomToast
        ref="ToastUpdateSuccess"
        toast-id="toast-update-success"
        icon-component-name="CheckCircleIcon"
        icon-component-class="text-theme-9"
        text="deploy.menu_device_updated"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { getBoolPtiValue } from "../../dao/parametre-pti";
import { getTargetDeviceDeployCredValue, getDeployCredValue, getTargetDeviceCredValue, setTargetDeviceCredValue } from "../../dao/cred-dao";
import {
  NAME_EWG100,
  STORE_CLONE_MODEL_DB,
  STORE_GET_TARGET_DB_FROM_S3,
  STORE_S3_IMG_FILES_INFO,
  STORE_S3_CERT_FILE_INFO,
  STORE_UPDATE_LOGIN_STATUS,
  STORE_UPLOAD_IMG_FILE_TO_S3,
  STORE_UPLOAD_TARGET_DB_TO_S3,
  STORE_UPLOAD_WIFI_PEAP_CERTIFICATE_FILE_TO_S3
} from "../../store/const_store";
import { useStore } from "@/store";
import DeployDeviceId from "./local-components/lc-deploy-device-id.vue";
import DeployServerId from "./local-components/lc-deploy-server-id.vue";
import SipDeploySettings from "./local-components/lc-sip-deploy-settings.vue";

const PTI_PARAM_SIP = "SipActivationState";

const PARAM_PTI_IS_TLS_IP = "TraitementServiceTelesurveillance";
const PARAM_PTI_IS_SUP_IP = "TraitementServeurSupervision";

export default defineComponent({
  components: {
    DeployDeviceId,
    DeployServerId,
    SipDeploySettings
  },

  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });
  },

  data() {
    return {
      isUpLoadSucceeded: 0,
      targetDeviceSavedPasswd: undefined,
      imageSource: undefined,
      isServerActivated: undefined,
      isSipActivated: undefined
    };
  },

  watch: {
    isUpLoadSucceeded() {
      if (!this.isUpLoadSucceeded) this.$refs.DeployDeviceId.resetImeiSnField();
    }
  },

  created() {
    this.deviceModelName = useStore().state.emeritValues.deviceInfo.deviceModelName;
    this.imageSource = useStore().state.emeritValues.modelImageSource;
    this.isServerActivated = getBoolPtiValue(PARAM_PTI_IS_TLS_IP) || getBoolPtiValue(PARAM_PTI_IS_SUP_IP);
    this.isSipActivated = getBoolPtiValue(PTI_PARAM_SIP);
  },

  methods: {
    reset() {
      if (this.isUpLoadSucceeded) this.isUpLoadSucceeded = 0;
    },

    onConfirmButtonClicked() {
      let error = 0;
      error += Number(!this.$refs.DeployDeviceId.check());
      if (this.isServerActivated) error += Number(!this.$refs.DeployServerId.check());
      if (this.isSipActivated) error += Number(!this.$refs.SipDeploySettings.check());
      if (error) {
        this.$refs.ToastWrongConfig.trigger();
        return;
      }

      this.$refs.DeployDeviceId.updateTargetDeviceInfo();
      this.$store.dispatch(STORE_GET_TARGET_DB_FROM_S3, {
        self: this
      });
    },

    onTargetDbDownloadError() {
      this.$store.commit(STORE_UPDATE_LOGIN_STATUS, false);
      this.$refs.ToastWrongImeiSn.trigger();
    },

    onTargetDbDownloadCompleted() {
      this.targetDeviceSavedPasswd = getTargetDeviceCredValue();
      if (
        this.targetDeviceSavedPasswd === useStore().state.emeritValues.secrets.initialCred ||
        getDeployCredValue() === getTargetDeviceDeployCredValue()
      )
        this.$store.dispatch(STORE_CLONE_MODEL_DB, { self: this });
      else this.$refs.ToastDeployNotAllowed.trigger();
    },

    onModelDBClonedToTargetDB() {
      if (this.targetDeviceSavedPasswd.valueOf() != useStore().state.emeritValues.secrets.initialCred)
        setTargetDeviceCredValue(this.targetDeviceSavedPasswd);
      this.$refs.DeployDeviceId.save();
      if (this.isServerActivated) this.$refs.DeployServerId.save();
      if (this.isSipActivated) this.$refs.SipDeploySettings.save();
      this.$store.dispatch(STORE_UPLOAD_TARGET_DB_TO_S3, {
        self: this
      });
      if (useStore().state.emeritValues.deviceInfo.deviceModelName.valueOf() === NAME_EWG100) {
        this.pushCustomScreensToS3();
        this.pushWifiPEAPCertificateToS3();
      }
    },

    pushCustomScreensToS3() {
      this.$refs.DeployDeviceId.updateDeviceInfo();
      for (let i = 0; i < STORE_S3_IMG_FILES_INFO.length; ++i) {
        this.$store.dispatch(STORE_UPLOAD_IMG_FILE_TO_S3, {
          self: this,
          fileName: STORE_S3_IMG_FILES_INFO[i].fileName
        });
      }
    },

    pushWifiPEAPCertificateToS3() {
      this.$refs.DeployDeviceId.updateDeviceInfo();
      for (let i = 0; i < STORE_S3_CERT_FILE_INFO.length; ++i) {
        this.$store.dispatch(STORE_UPLOAD_WIFI_PEAP_CERTIFICATE_FILE_TO_S3, {
          self: this,
          fileName: STORE_S3_CERT_FILE_INFO[i].fileName
        });
      }
    },

    onTargetDbUploadError() {
      this.$refs.ToastUploadFailed.trigger();
    },

    onTargetDbUploadCompleted() {
      this.isUpLoadSucceeded = 1;
      this.$refs.ToastUpdateSuccess.trigger();
    },

    onImgUploadError() {
      this.$refs.ToastImgUploadFailure.trigger();
    },

    onImgUploadCompleted(fileName) {
      console.log(fileName + " succesfully uploaded");
    },

    onCertificateUploadError() {
      this.$refs.toastCertUploadFailure.trigger();
    },

    onCertificateUploadCompleted(fileName) {
      console.log(fileName + " succesfully uploaded");
    }
  }
});
</script>
