<template>
  <div>
    <Title
      title="locationindoorwifi.menu_wifi_based_indoor_location"
      help-locale="menu_loc_indoor_wifi"
    />
    <div class="grid grid-cols-10 gap-6 mt-6">
      <WifiHotspotSettings 
        ref=wifiHotspotSettings
        :nb-wifi-hotspots="hotspotList.length"
        :is-ble-level-activated="isBleLevelActivated"
        :ble-level-precision-error-msg="bleLevelPrecisionErrorMsg"
        @import-export-block-status-changed="showImportExportButtons = $event"
        @remove-all-hotspots="initModalConfirm"
      />
      <ImportExportWifiHotspot
        v-if="showImportExportButtons"
        ref="ImportExportWifiHotspot"
        :hotspot-list="hotspotList"
        @import-success="addImportList($event)"
      />
      <AddWifiHotspot
        v-if="!showImportExportButtons"
        :hotspot-list="hotspotList"
        :is-ble-level-activated="isBleLevelActivated"
        @add-hotspot-success="addNewHotspot"
      />
    </div>
    <WifiHotspotList
      :hotspot-list="hotspotList"
      :is-ble-level-activated="isBleLevelActivated"
      @modify-hotspot="initModalModify"
      @remove-hotspot="initModalConfirm"
    />
    <ModalModifyWifiHotspot
      ref="modalModifyWifiHotspot"
      modal-id="modal-wifi-hotspot-modify"
      :hotspot-list="hotspotList"
      :current-index="currentIndex"
      :is-ble-level-activated="isBleLevelActivated"
      @modify-hotspot-success="saveModifiedHotspot($event)"
    />
    <ModalAlertConfirmChoice
      ref="modalAlertConfirmChoice"
      modal-id="modal-alert-confirm"
      alert-title="common.menu_are_you_sure"
      :alert-text="modalAlertText"
      icon-component-name="AlertTriangleIcon"
      left-button-component-name="ButtonDismissModal"
      left-button-text="common.menu_cancel"
      left-button-custom-class="btn btn-outline-secondary mr-1"
      right-button-component-name="ButtonDismissModal"
      right-button-text="common.menu_remove"
      right-button-custom-class="btn btn-danger"
      @right-button="remove"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import WifiHotspotSettings from './local-components/lc-wifi-hotspot-settings.vue'
import ImportExportWifiHotspot from './local-components/lc-import-export-wifi-hotspot.vue'
import AddWifiHotspot from './local-components/lc-add-wifi-hotspot.vue'
import WifiHotspotList from './local-components/lc-wifi-hotspot-list.vue'
import ModalModifyWifiHotspot from './local-components/lc-modal-modify-wifi-hotspot.vue'
import { 
  getWifiBeacons,
  addWifiBeacon,
  deleteWifiBeacon,
  deleteAllWifiBeacons,
  updateWifiBeacon,
} from '../../dao/indoor-wifi-dao'
import {
  getBoolPtiValue,
} from "../../dao/parametre-pti";


const ALERT_REMOVE_EVERYTHING = "locationindoorwifi.menu_remove_all_hotspots"
const ALERT_REMOVE_ONE_HOTSPOT = "locationindoorwifi.menu_remove_one_hotspot"

const ERR_ONE_ELEM_IS_MISSING_FLOOR = "locationindoorwifi.menu_err_one_elem_missing_level";

const SSID = 0;
const BSSID = 1;
const CODE_ZONE = 2;
const DESC = 3;
const LEVEL = 4;

const DB_SSID_COLUMN_INDEX = 1;
const DB_BSSID_COLUMN_INDEX = 2;
const DB_CODE_ZONE_COLUMN_INDEX = 3;
const DB_DESC_COLUMN_INDEX = 4;
const DB_LEVEL_COLUMN_INDEX = 6;

const REMOVE_ONE_ITEM = 0;
const REMOVE_EVERYTHING = 1;

const MODAL_ID_ALERT_CONFIRM = "modal-alert-confirm";
const MODAL_ID_MODIFY_HOTSPOT = "modal-wifi-hotspot-modify";
const MODAL_ID_ALERT_CONFIRM_UPLOAD = "modal-alert-confirm-csv-upload";

const PARAM_PTI_IS_BLE_ACTIVATED = "isBluetoothLocParamEnabled";
const PARAM_PTI_IS_BLE_LEVEL_ACTIVATED = "IndoorBleLevelPrecisionActivated";

const BODY = "BODY";


export default defineComponent({

  components: {
    AddWifiHotspot,
    WifiHotspotSettings,
    ImportExportWifiHotspot,
    WifiHotspotList,
    ModalModifyWifiHotspot,
  },

  /**
   * Dismiss the modals if they're opened by clicking on their right buttons, 
   * and wait X milliseconds until the modals are correctly close by checking
   * if they're still present in the <body></body> HTML element, then route
   * to the new view.
   * 
   * Fix a bug when opening a modal and then using navigation arrows (next, prev).
   * The modal wasn't correctly closed and provoquing a crash with all the other modals
   * until next reload.
   */
  beforeRouteLeave (to, from, next) {
    this.$refs.wifiHotspotSettings.save();
    this.$refs.modalAlertConfirmChoice.dismiss();
    this.$refs.modalModifyWifiHotspot.dismiss();
    if (this.showImportExportButtons)
      this.$refs.ImportExportWifiHotspot.dismissModal();
    let intervalId = setInterval(() => {  
      const modalAlertConfirm = document.getElementById(MODAL_ID_ALERT_CONFIRM);
      const modalModify = document.getElementById(MODAL_ID_MODIFY_HOTSPOT);
      let modalAlertConfirmUpload = undefined;
      if (this.showImportExportButtons)
        modalAlertConfirmUpload = document.getElementById(MODAL_ID_ALERT_CONFIRM_UPLOAD);
      if (modalAlertConfirm.parentElement.tagName.valueOf() != BODY
          && modalModify.parentElement.tagName.valueOf() != BODY
          && ((this.showImportExportButtons 
              && modalAlertConfirmUpload.parentElement.tagName.valueOf() != BODY)
              || !this.showImportExportButtons)) {
        clearInterval(intervalId);
        next();
      }
    }, 100);
	},

  data() {
    return {
      isBleLevelActivated: undefined,
      showImportExportButtons: false,
      removeType: undefined,
      hotspotList: new Array(),
      currentIndex: 0,
    }
  },

  computed: {
    modalAlertText() {
      return this.removeType == REMOVE_ONE_ITEM ? 
          ALERT_REMOVE_ONE_HOTSPOT : ALERT_REMOVE_EVERYTHING;
    },

    bleLevelPrecisionErrorMsg() {
      if (!this.isBleLevelActivated)
        return '';
      for (let i = 0; i < this.hotspotList.length; ++i)
        if (!this.hotspotList[i][LEVEL].length)
          return ERR_ONE_ELEM_IS_MISSING_FLOOR;
      return '';
    },
  },

  created() {
    const dbHotspots = getWifiBeacons();
    if (dbHotspots != undefined)
      for (let i = 0; i < dbHotspots.values.length; ++i)
        this.hotspotList.push(this.createHotspotFromDbEntry(dbHotspots.values[i]));
    this.isBleLevelActivated = getBoolPtiValue(PARAM_PTI_IS_BLE_ACTIVATED)
        && getBoolPtiValue(PARAM_PTI_IS_BLE_LEVEL_ACTIVATED);
  },

  methods: {

    addImportList(wifiImportList){
      deleteAllWifiBeacons();
      this.hotspotList = wifiImportList;
      for (let i = 0; i < wifiImportList.length; ++i)
        addWifiBeacon(wifiImportList[i]);
    },

    addNewHotspot(newHotspot) {
      this.hotspotList.push(newHotspot);
      addWifiBeacon(newHotspot);
    },

    saveModifiedHotspot(hotspotModified) {
      updateWifiBeacon(hotspotModified);
      this.hotspotList[this.currentIndex] = hotspotModified.newHotspot;
    },

    remove() {
      if (this.removeType === REMOVE_ONE_ITEM) {
        deleteWifiBeacon(this.hotspotList[this.currentIndex][BSSID]);
        this.hotspotList.splice(this.currentIndex, 1)
      }
      else {
        deleteAllWifiBeacons();
        this.hotspotList = new Array();
      }
    },

    initModalConfirm(index) {
      this.currentIndex = index == undefined ? this.currentIndex : index;
      this.removeType = index == undefined ? REMOVE_EVERYTHING : REMOVE_ONE_ITEM;
    },

    initModalModify(index) {
      this.currentIndex = index;
      this.$refs.modalModifyWifiHotspot.initValues(index)
    },

    createHotspotFromDbEntry(dbHotspot) {
      return [
        dbHotspot[DB_SSID_COLUMN_INDEX],
        dbHotspot[DB_BSSID_COLUMN_INDEX].toUpperCase(),
        dbHotspot[DB_CODE_ZONE_COLUMN_INDEX],
        dbHotspot[DB_DESC_COLUMN_INDEX],
        dbHotspot[DB_LEVEL_COLUMN_INDEX],
      ]
    }

  }
})

</script>