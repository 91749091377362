<template>
  <!-- BEGIN: RecipientsList local component-->
  <div>
    <Pagination
      v-if="recipientList.length"
      :current-page="page"
      :total-pages="getTotalPages"
      @begin="page = 1"
      @end="page = getTotalPages"
      @decrement="page = page === 1 ? 1 : --page"
      @increment="page = page === getTotalPages ? page : ++page"
    />
    <CustomTable
      v-if="recipientList.length"
      :headers="getHeaders"
      :elems-list="getRecipientPage"
      :icons-list="getRecipientIconsPage"
      :screen-percent-width="7"
      :showing-errors="true"
      list-element-component-name="ListElement"
      green-button-text="common.menu_modify"
      red-button-text="common.menu_remove"
      :green-button-bootstrap-target="'#' + modalAddModifyRecipientId"
      :red-button-bootstrap-target="'#' + modalAlertConfirmId"
      @element-green-button-clicked="$emit('modify-recipient', $event + (page - 1) * 10)"
      @element-red-button-clicked="$emit('remove-recipient', $event + (page - 1) * 10)"
    />
  </div>
  <!-- END: RecipientsList local component-->
</template>

<script>
import { defineComponent } from 'vue'


const NB_ITEMS = 10;

const I18_HEADER_NAME = 'internalrecipients.menu_name';
const I18_HEADER_PHONE_NB = 'internalrecipients.menu_call_number';
const I18_HEADER_PHONE_NB_OR_SIP_ID = 'internalrecipients.menu_number_or_sip_id';

const MSG_ICON = "MessageSquareIcon";
const PHONE_ICON = "PhoneCallIcon";
const SIP_ICON = "SipIcon";
const EWG100_ICON = "Ewg100WatchIcon";

const EV_REMOVE_RECIPIENT = 'remove-recipient'
const EV_MODIFY_RECIPIENT = 'modify-recipient'
const EV_GET_ICONS_RENDERED_LIST = 'get-icons-rendered-list';

export default defineComponent({

  props: {
    recipientList: {
      type: Array,
      required: true,
    },
    isPecActivated: {
      type: Boolean,
      required: true,
    },
    isSipActivated: {
      type: Boolean,
      required: true,
    },
    modalAddModifyRecipientId: {
      type: String,
      required: true,
    },
    modalAlertConfirmId: {
      type: String,
      required: true,
    },
  },

  emits: [
    EV_REMOVE_RECIPIENT,
    EV_MODIFY_RECIPIENT,
    EV_GET_ICONS_RENDERED_LIST,
  ],

  data() {
    return {
      page: 1,
    }
  },

  computed: {

    getHeaders() {
      if (this.isSipActivated)
        return [I18_HEADER_NAME, I18_HEADER_PHONE_NB_OR_SIP_ID];
      return [I18_HEADER_NAME, I18_HEADER_PHONE_NB];
    },

    getRecipientPage() {
      const startIndex = (this.page - 1) * NB_ITEMS;
      const length = startIndex + NB_ITEMS < this.recipientList.length ?
          NB_ITEMS : this.recipientList.length - startIndex;
      let recipientPage = new Array(length);
      for (let i = 0; i < length; ++i)
        recipientPage[i] = this.formatRecipientInfo(this.recipientList[i + startIndex]);
      return recipientPage;
    },

    getRecipientIconsPage() {
      const startIndex = (this.page - 1) * NB_ITEMS;
      const length = startIndex + NB_ITEMS < this.recipientList.length ?
          NB_ITEMS : this.recipientList.length - startIndex;
      let recipientIconsPage = new Array(length);
      for (let i = 0; i < length; ++i)
        recipientIconsPage[i] = this.createRecipientIcons(this.recipientList[i + startIndex]);
      this.$emit(EV_GET_ICONS_RENDERED_LIST, this.getIconsRenderedList(recipientIconsPage));
      return recipientIconsPage;
    },

    getTotalPages() {
      return Math.ceil(this.recipientList.length / NB_ITEMS);
    },
  },

  watch: {
    
    /**
     * Update the page number dynamically when recipients are added / removed.
     */
    'recipientList.length': {
      handler: function updatePageNumber(newVal, oldVal) {
        if (this.page > this.getTotalPages)
          --this.page;
        else if (newVal > oldVal)
          this.page = this.getTotalPages;
      }
    }

  },

  methods: {

    getIconsRenderedList(recipientIconsPage) {
      let iconsRenderedList = [];
      for (let i = 0; i < recipientIconsPage.length; ++i) {
        for (let j = 0; j < recipientIconsPage[i].length; ++j)
          if (!this.isIconAlreadyPresentInList(recipientIconsPage[i][j], iconsRenderedList))
            iconsRenderedList.push(recipientIconsPage[i][j]);
      }
      this.displaySameOrderThanCreateRecipientIcons(iconsRenderedList);
      return iconsRenderedList;
    },

    displaySameOrderThanCreateRecipientIcons(iconsRenderedList) {
      iconsRenderedList.sort();
      if (iconsRenderedList.length && iconsRenderedList[0].valueOf() === EWG100_ICON) {
        iconsRenderedList.splice(0, 1);
        iconsRenderedList.push(EWG100_ICON);
      }
    },

    isIconAlreadyPresentInList(icon, iconsRenderedList) {
      for (let i = 0; i < iconsRenderedList.length; ++i)
        if (icon.valueOf() === iconsRenderedList[i].valueOf())
          return true;
      return false;
    },

    formatRecipientInfo(recipient) {
      let recipientInfo = new Array(2);
      recipientInfo[0] = recipient.name;
      if (recipient.number)
        recipientInfo[1] = recipient.number;
      else
        recipientInfo[1] = recipient.sipAccountId;
      return recipientInfo;
    },

    createRecipientIcons(recipient) {
      let icons = [];
      if (this.isPecActivated) {
        icons.push(MSG_ICON);
        icons.push(PHONE_ICON);
      }
      else {
        if (recipient.pSms)
          icons.push(MSG_ICON);
        if (recipient.pCall)
          icons.push(PHONE_ICON);
        if (recipient.isSipRecipient)
          icons.push(SIP_ICON);
        if (recipient.pWatch)
          icons.push(EWG100_ICON);
      }
      return icons;
    }

  }

})
</script>
