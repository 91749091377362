<template>
  <!-- BEGIN: Ewg100WatchIcon component-->
  <img
    class="w-6 h-6"
    :src="require(`@/assets/images/e-WG100_icon.png`)"
  >
  <!-- END: Ewg100WatchIcon component-->
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({})
</script>
