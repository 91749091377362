<template>
  <!-- BEGIN: AlertMode local component -->
  <div class="grid grid-cols-12 gap-6 mt-6">
    <div class="intro-y col-span-12 lg:col-span-6">
      <BoxTitle
        color="bg-red-300"
        title="wristalert.menu_choose_alert_mode"
      />
      <div class="clearfix flex-row p-3 border-b border-gray-200 box">
        <CustomRadioInput
          v-for="(desc, index) in alertModeFieldsDesc"
          :key="index"
          v-model.number="alertMode"
          :custom-id="radioGroupName + index"
          :radio-group-name="radioGroupName"
          :description="desc"
          :value="index"
        />
      </div>
    </div>
  </div>
  <!-- END: AlertMode local component -->
</template>

<script>
import { defineComponent } from 'vue'
import { setNumberPtiValue } from '../../../dao/parametre-pti';


const NOISE_MODE = "wristalert.menu_noise_mode";
const DISCRET_MODE = "wristalert.menu_discret_mode";

const PARAM_PTI_WRIST_DISCRET_MODE = "isWristMouvementDiscretModeEnabled";


export default defineComponent({

  props: {
    isDiscretModeEnabled: {
      type: Number,
      required: true,
    }
  },

  emits: ['newDiscretModeState'],

  data() {
    return {
      alertModeFieldsDesc: [NOISE_MODE, DISCRET_MODE],
      radioGroupName: 'alert-mode-radio',
      alertMode: this.isDiscretModeEnabled,
    }
  },

  watch: {
    alertMode() {
      this.$emit('newDiscretModeState', this.alertMode);
    }
  },

  mounted() {
    document.getElementById(this.radioGroupName + this.isDiscretModeEnabled).checked = true;
  },

  methods: {

    save() {
      setNumberPtiValue(PARAM_PTI_WRIST_DISCRET_MODE, this.isDiscretModeEnabled);
    },
  }

})
</script>
