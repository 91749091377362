export const PATH_LOGIN = "/login";
export const PATH_MODEL_LOGIN = "/model-login";
export const PATH_DEPLOY_LOGIN = "/deploy-login";
export const PATH_PASSWORD = "/password";
export const PATH_CREATE_PASSWORD = "/create-modify-password";
export const PATH_TARGET_DB = "/target-db";
export const PATH_ERROR_PAGE = "/error-page"; 
export const PATH_MAIN_CONFIG = "/"
export const PATH_CONTACT = "/contact";
export const PATH_WIFI = "/wifi";
export const PATH_WEARING_STYLE = "/wearing-style";
export const PATH_ALERTS = "/alerts";
export const PATH_WRIST_ALERT = "/wrist-alert";
export const PATH_LOC_OUTDOOR = "/location-outdoor";
export const PATH_LOC_INDOOR_BLE = "/location-indoor-ble";
export const PATH_LOC_INDOOR_WIFI = "/location-indoor-wifi";
export const PATH_INTERNAL_RECIPIENTS_SETTINGS = "/internal-recipients-settings";
export const PATH_SIP = "/sip-configuration";
export const PATH_WHITELIST = "/whitelist";
export const PATH_INTERNAL_RECIPIENTS = "/internal-recipients";
export const PATH_TLS_RECIPIENTS = "/tls-recipients";
export const PATH_IP_SERVER = "/ip-server";
export const PATH_IP_SERVER_SETTINGS = "/ip-server-settings";