import { useStore } from "@/store";

export const addRecipientSup = recipient => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'INSERT INTO  serveur_supervision_gprs('
    + '_id,'
    + 'nom_serveur,' 
    + 'url_serveur,'
    + 'port_serveur,'
    + 'nom_serveur_backup,'
    + 'url_serveur_backup,'
    + 'port_serveur_backup,'
    + 'nom_sms_backup,'
    + 'sms_backup,'
    + 'p_alarme,'
    + 'p_marche_arret,'
    + 'p_batterie_basse,'
    + 'p_station_accueil,'
    + 'p_backup_url,'
    + 'p_backup_sms,'
    + 'p_securite_positive,'
    + 'p_post_alarme,'
    + 'p_pti,'
    + 'p_gestion_hors_zone,'
    + 'protocol_identifier,'
    + 'p_nfc,'
    + 'sia_id_token,'
    + 'sia_ex_data_format,'
    + 'sia_gps_format,'
    + 'sia_ex_data_battery_level,'
    + 'sia_ex_data_event_timestamp,'
    + 'sia_ex_data_gps_timestamp,'
    + 'sia_ex_data_gps_accuracy,'
    + 'sia_ex_data_gps_speed,'
    + 'sia_ex_data_gps_altitude,'
    + 'sia_ex_data_gps_validity,'
    + 'sia_time_stamp,'
    + 'sia_account_prefix_lpref,'
    + 'p_prise_arret_service,'
    + 'sia_ex_data_device_imei,'
    + 'sia_ex_data_beacon_id,'
    + 'p_beacon_batterie_basse,'
    + 'p_securite_positive_link_test)'
    + 'VALUES( 0,"' +
    recipient[1] +
    '","' +
    recipient[2] +
    '","' +
    recipient[3] +
    '","' +
    recipient[4] +
    '","' +
    recipient[5] +
    '","' +
    recipient[6] +
    '","' +
    recipient[7] +
    '","' +
    recipient[8] +
    '","' +
    recipient[9] +
    '","' +
    recipient[10] +
    '","' +
    recipient[11] +
    '","' +
    recipient[12] +
    '","' +
    recipient[13] +
    '","' +
    recipient[14] +
    '","' +
    recipient[15] +
    '","' +
    recipient[16] +
    '","' +
    recipient[17] +
    '","' +
    recipient[18] +
    '","' +
    recipient[19] +
    '","' +
    recipient[20] +
    '","' +
    recipient[21] +
    '","' +
    recipient[22] +
    '","' +
    recipient[23] +
    '","' +
    recipient[24] +
    '","' +
    recipient[25] +
    '","' +
    recipient[26] +
    '","' +
    recipient[27] +
    '","' +
    recipient[28] +
    '","' +
    recipient[29] +
    '","' +
    recipient[30] +
    '","' +
    recipient[31] +
    '","' +
    recipient[32] +
    '","' +
    recipient[33] +
    '","' +
    recipient[34] +
    '","' +
    recipient[35] +
    '","' +
    recipient[36] +
    '","' +
    recipient[37] +
    '")';
    
  try {
    return dbFile.exec(sqlScript);
  } catch (error) {
    console.log('addRecipientSup failed: ' + error);
  }
};

export const updateRecipientSupIpSmsBackup = isActivated => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript =
    'UPDATE serveur_supervision_gprs SET p_backup_sms="' +
    isActivated +
    '" where _id=0';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('updateRecipientSupIpSmsBackup failed: ' + error);
  }
};

export const getAllRecipientSup = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * from serveur_supervision_gprs";
  try {
    var recipient = dbFile.exec(sqlScript)[0];
    return recipient;
  } catch (error) {
    console.log('getAllRecipientSup failed: ' + error);
  }
};

export const getRecipienSupIp = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * from serveur_supervision_gprs where _id=0";
  try {
    var recipient = dbFile.exec(sqlScript)[0].values[0];
    return recipient;
  } catch (error) {
    console.log('getRecipienSupIp failed: ' + error);
  }
};

export const getRecipienSupIpProtocotolDatasOnly = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    "SELECT sia_id_token,sia_ex_data_format,sia_gps_format,sia_ex_data_battery_level,sia_ex_data_event_timestamp,sia_ex_data_gps_timestamp, " +
    "sia_ex_data_gps_accuracy,sia_ex_data_gps_speed,sia_ex_data_gps_altitude,sia_ex_data_gps_validity,sia_time_stamp,sia_account_prefix_lpref," +
    "sia_ex_data_device_imei,sia_ex_data_beacon_id from serveur_supervision_gprs where _id=0";
  try {
    var recipient = dbFile.exec(sqlScript)[0].values[0];
    return recipient;
  } catch (error) {
    console.log('getRecipienSupIpProtocotolDatasOnly failed: ' + error);
  }
};

export const updateSupIpRecipient = recipient => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'UPDATE serveur_supervision_gprs SET nom_serveur="' +
    recipient[1] +
    '", url_serveur="' +
    recipient[2] +
    '",port_serveur="' +
    recipient[3] +
    '",nom_serveur_backup="' +
    recipient[4] +
    '",url_serveur_backup="' +
    recipient[5] +
    '",port_serveur_backup="' +
    recipient[6] +
    '",nom_sms_backup="' +
    recipient[7] +
    '",sms_backup="' +
    recipient[8] +
    '",p_alarme="' +
    recipient[9] +
    '",p_marche_arret="' +
    recipient[10] +
    '",p_batterie_basse="' +
    recipient[11] +
    '",p_station_accueil="' +
    recipient[12] +
    '",p_backup_url="' +
    recipient[13] +
    '",p_backup_sms="' +
    recipient[14] +
    '",p_securite_positive="' +
    recipient[15] +
    '",p_nfc="' +
    recipient[20] +
    '",p_beacon_batterie_basse="' +
    recipient[36] +
    '",p_securite_positive_link_test="' +
    recipient[37] +
    '" where _id=0';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('updateSupIpRecipient failed: ' + error);
  }
};

export const updateTargetDeviceSupIpRecipient = recipient => {
  // la plupart des champs dans cette fonction sont useless
  // il ne faudrait update que les champs des identifiants serveurs
  var dbFile = useStore().state.emeritValues.targetDbFile;
  let sqlScript =
    'UPDATE serveur_supervision_gprs SET nom_serveur="' +
    recipient[1] +
    '", url_serveur="' +
    recipient[2] +
    '",port_serveur="' +
    recipient[3] +
    '",nom_serveur_backup="' +
    recipient[4] +
    '",url_serveur_backup="' +
    recipient[5] +
    '",port_serveur_backup="' +
    recipient[6] +
    '",nom_sms_backup="' +
    recipient[7] +
    '",sms_backup="' +
    recipient[8] +
    '",p_alarme="' +
    recipient[9] +
    '",p_marche_arret="' +
    recipient[10] +
    '",p_batterie_basse="' +
    recipient[11] +
    '",p_station_accueil="' +
    recipient[12] +
    '",p_backup_url="' +
    recipient[13] +
    '",p_backup_sms="' +
    recipient[14] +
    '",p_securite_positive="' +
    recipient[15] +
    '",p_nfc="' +
    recipient[20] +
    '",p_beacon_batterie_basse="' +
    recipient[36] +
    '",p_securite_positive_link_test="' +
    recipient[37] +
    '" where _id=0';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('updateTargetDeviceSupIpRecipient failed: ' + error);
  }
};

export const updateSupIpRecipientSiaEvents = recipient => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'UPDATE serveur_supervision_gprs SET sia_id_token="' +
    recipient[0] +
    '", sia_ex_data_format="' +
    recipient[1] +
    '",sia_gps_format="' +
    recipient[2] +
    '",sia_ex_data_battery_level="' +
    recipient[3] +
    '",sia_ex_data_event_timestamp="' +
    recipient[4] +
    '",sia_ex_data_gps_timestamp="' +
    recipient[5] +
    '",sia_ex_data_gps_accuracy="' +
    recipient[6] +
    '",sia_ex_data_gps_speed="' +
    recipient[7] +
    '",sia_ex_data_gps_altitude="' +
    recipient[8] +
    '",sia_ex_data_gps_validity="' +
    recipient[9] +
    '",sia_time_stamp="' +
    recipient[10] +
    '",sia_account_prefix_lpref="' +
    recipient[11] +
    '",sia_ex_data_device_imei="' +
    recipient[12] +
    '",sia_ex_data_beacon_id="' +
    recipient[13] +
    '" where _id=0';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('updateSupIpRecipientSiaEvents failed: ' + error);
  }
};

export const updateSupIpExDataBeaconId = isEnabled => {
  var dbFile = useStore().state.emeritValues.dbFile;
  var valueEnabled = 0;
  if(isEnabled)
  valueEnabled =1;
  let sqlScript ='UPDATE serveur_supervision_gprs SET sia_ex_data_beacon_id="'+ valueEnabled +'" where _id=0';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('updateTlsIpRecipientSiaEvents failed: ' + error);
  }
};

export const getCountRecipientSupIp = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT COUNT(*) FROM serveur_supervision_gprs";
  try {
    return Number(dbFile.exec(sqlScript)[0].values[0]);
  } catch (error) {
    console.log('getCountRecipientSupIp failed: ' + error);
  }
};

export const deleteSupIpRecipient = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "DELETE FROM serveur_supervision_gprs where _id=0";
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('deleteSupIpRecipient failed: ' + error);
  }
};

export const deleteTlsIpRecipient1 = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "DELETE FROM serveur_supervision_gprs where _id=1";
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('deleteTlsIpRecipient1 failed: ' + error);
  }
};

export const getCountRecipientSupIpAlarm = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT COUNT(*) FROM serveur_supervision_gprs where p_alarme=1";
  try {
    return Number(dbFile.exec(sqlScript)[0].values[0]);
  } catch (error) {
    console.log('getCountRecipientSupIpAlarm failed: ' + error);
  }
};

export const getRecipientSupIpCyclicTest = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT p_securite_positive from serveur_supervision_gprs ";
  try {
    return dbFile.exec(sqlScript)[0].values[0];
  } catch (error) {
    console.log('getRecipientSupIpCyclicTest failed: ' + error);
  }
};

export const getRecipientSupIpLinkTest = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT p_securite_positive_link_test from serveur_supervision_gprs ";
  try {
    return dbFile.exec(sqlScript)[0].values[0];
  } catch (error) {
    console.log('getRecipientSupIpLinkTest failed: ' + error);
  }
};

export const resetRecipientSupIpBeaconBleLowBatteryEvent = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript = "UPDATE serveur_supervision_gprs SET p_beacon_batterie_basse=0";
  try {
    dbFile.exec(sqlScript);
  }
  catch (error) {
    console.log('resetRecipientSupIpBeaconBleLowBatteryEvent failed: ' + error);
  }
}

export const getCountRecipientSupIpWithNoEvent = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript = "SELECT COUNT(p_beacon_batterie_basse) FROM serveur_supervision_gprs "
      + "WHERE p_alarme=0 "
      + "and p_batterie_basse=0 "
      + "and p_marche_arret=0 "
      + "and p_station_accueil=0 "
      + "and p_nfc=0 "
      + "and p_beacon_batterie_basse=0";
  try {
    return dbFile.exec(sqlScript)[0].values[0][0];
  }
  catch (error) {
    console.log('getCountRecipientSupIpWithNoEvent failed: ' + error);
  }
}