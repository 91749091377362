<template>
  <!-- BEGIN: CheckBoxTitle component-->
  <div 
    :class="color" 
    class="flex flex-raw sm:flex-row pt-3 p-3 bg-color-200 border-gray-200 dark:border-dark-5 rounded-t"
  >
    <div class="form-check w-full">
      <input
        name="show-immobility-settings"
        class="show-code form-check-switch bg-white"
        type="checkbox"
        :checked="modelValue"
        @input="$emit('update:modelValue', $event.target.checked)"
      >
      <div>
        <h2 
          v-if="!modelValue"
          class="font-medium text-base pl-3"
        >
          {{ $t(titleNo) }}
        </h2>
      </div>
      <div>
        <h2 
          v-if="modelValue"
          class="font-medium text-base pl-3"
        >
          {{ $t(titleYes) }}
        </h2>
      </div>
    </div>
  </div>
  <!-- END: CheckBoxTitle component-->
</template>

<script>
import { defineComponent } from 'vue';

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A checkbox with a title, represents the upper part of a box component.
 * v-model MUST be used on this component, the value of the checkbox will 
 * be automatically bind to the parent variable.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {Boolean} modelValue
 *    Value of the checkbox. Automatically sets when using v-model on this
 *    component. For example, v-model="parentVariable" will bind this 
 *    modelValue to parentVariable. Will be initialized with parent variable
 *    value.
 * 
 * @param {String} color
 *    The class representing the background color of the checkbox part 
 *    for this component.
 * 
 * @param {String} titleYes
 *    The title of this component when the checkbox is checked.
 *    Should be i18 key for the locale (ex: 'common.menu_save').
 * 
 * @param {String} titleNo
 *    The title of this component when the checkbox is not checked.
 *    Should be i18 key for the locale (ex: 'common.menu_save').
 * 
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits update:modelValue
 * @return {Boolean}
 *    Emits the value of the checkbox (true or false). When v-model is
 *    used on this component, v-model automatically update with this
 *    value.
 *              
 * ------------------------------------------------------------------
 */
export default defineComponent ({
  
  name: 'CheckBoxTitle',
  
  props: {
    // eslint-disable-next-line
    modelValue: {
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    titleYes: {
      type: String,
      required: true,
    },
    titleNo: {
      type: String,
      required: true,
    },
  },
  
  emits: ['update:modelValue'],
})
</script>