<template>
  <!-- BEGIN: AddIbeaconInputFields local component-->
  <div class="grid grid-cols-10 gap-3 mt-3">
    <Tippy
      v-for="(field, index) in getIbeaconFields"
      :key="index"
      :ref="field.title"
      :content="$t(field.tippy)"
      :options="{
        theme: 'light',
        delay: index,
        onShow: updateTippyContent,
      }"
      :class="field.class"
    >
      <CustomTextInput
        v-model="field.value"
        :title="field.title"
        :max-length="field.len"
      />
    </Tippy>
    <CustomButton
      custom-class="lg:mt-6 col-start-9 col-span-2 mb-2 mt-4 btn btn-primary "
      text="common.menu_add"
      @click="check()"
    />
    <ErrorMsg
      v-for="(field, index) in getIbeaconFields"
      :key="index"
      :error-msg="field.errorMsg"
      class="col-start-1 col-span-10"
    />
  </div>
  <!-- END: AddIbeaconInputFields local component-->
</template>

<script>
import { defineComponent } from 'vue'
import {
  checkLevel,
  checkCodeZone,
  beaconAlreadyExisting,
  checkDescription,
} from './utils'
import {
  setError,
  resetError,
  resetAllFields,
  regexCheck,
} from '../../../utils/fieldManagement'


const COMPANY_ID = 0;
const BEACON_ID = 1;
const CODE_ZONE = 2;
const LEVEL = 3;
const DESC = 4;

const UUID_LEN = 32;
const MAJOR_MINOR_LEN = 8;

const ERR_IBEACON_ALREADY_EXIST = "locationindoorble.menu_err_ibeacon_already_exist";
const ERR_IBEACON_COMP_ID = "locationindoorble.menu_err_ibeacon_company_id";
const ERR_IBEACON_BEAC_ID = "locationindoorble.menu_err_ibeacon_beacon_id";
const ERR_CODE_ZONE = "locationindoorble.menu_err_wrong_code_zone";
const ERR_LEVEL = "locationindoorble.menu_err_wrong_level";
const ERR_DESC = "locationindoorble.menu_err_empty_desc";

const COMP_ID_TITLE = "locationindoorble.menu_beacon_company_id";
const BEAC_ID_TITLE = "locationindoorble.menu_beacon_id";
const CODE_ZONE_TITLE = "locationindoorble.menu_beacon_code_zone";
const LEVEL_TITLE = "locationindoorble.menu_beacon_level";
const DESC_TITLE = "locationindoorble.menu_beacon_description";

const COMP_ID_CLASS = "col-span-10 lg:col-span-10 lg:col-start-1 2xl:col-span-8 2xl:col-start-1";
const BEAC_ID_CLASS = "col-span-10 lg:col-span-6 lg:col-start-1 2xl:col-span-4 2xl:col-start-1";
const CODE_ZONE_CLASS = "col-span-10 lg:col-span-2 lg:col-start-7 2xl:col-span-2 2xl:col-start-5";
const LEVEL_CLASS = "col-span-10 lg:col-span-2 lg:col-start-9 2xl:col-span-2 2xl:col-start-7";
const DESC_CLASS = "col-span-10 lg:col-span-8 lg:col-start-1";

const UUID_TIPPY = "locationindoorble.menu_tippy_uuid";
const IBEACON_MAJOR_MINOR_TIPPY = "locationindoorble.menu_tippy_ibeacon_major_minor";
const CODE_ZONE_TIPPY = "locationindoorble.menu_tippy_code_zone";
const LEVEL_TIPPY = "locationindoorble.menu_tippy_level";
const DESC_TIPPY = "locationindoorble.menu_tippy_description";


export default defineComponent({

  props: {
    hideLevelField: {
      type: Boolean,
      required: true,
    },
    beaconList: {
      type: Array,
      required: true,
    }
  },

  emits: ['addIbeaconFailed', 'addIbeaconSuccess'],

  data() {
    return {
      ibeaconFields: [
        { title: COMP_ID_TITLE, class: COMP_ID_CLASS, tippy: UUID_TIPPY, value: '', len: UUID_LEN, errorMsg: ''},
        { title: BEAC_ID_TITLE, class: BEAC_ID_CLASS, tippy: IBEACON_MAJOR_MINOR_TIPPY, value: '', len: MAJOR_MINOR_LEN, errorMsg: ''},
        { title: CODE_ZONE_TITLE, class: CODE_ZONE_CLASS, tippy: CODE_ZONE_TIPPY, value: '', len: 3, errorMsg: ''},
        { title: LEVEL_TITLE, class: LEVEL_CLASS, tippy: LEVEL_TIPPY, value: '', len: 3, errorMsg: ''},
        { title: DESC_TITLE, class: DESC_CLASS, tippy: DESC_TIPPY, value: '', len: 50, errorMsg: ''},
      ],
    }
  },

  computed: {
    getIbeaconFields() {
      const len = this.hideLevelField ? this.ibeaconFields.length - 1 : this.ibeaconFields.length;
      let newIbeaconFields = new Array(len);
      for (let i = 0, j = 0; i < this.ibeaconFields.length; ++i, ++j) {
        if (this.hideLevelField && i === LEVEL)
          --j
        else 
          newIbeaconFields[j] = this.ibeaconFields[i];
      }
      return newIbeaconFields;
    },
  },

  watch: {
    hideLevelField() {
      this.ibeaconFields[LEVEL].value = '';
      resetError(this.ibeaconFields, LEVEL);
    }
  },

  methods: {
    
    updateTippyContent(instance) {
      const index = instance.props.delay;
      instance.setContent(this.$t(this.getIbeaconFields[index].tippy));
    },

    check() {
      let error = this.ibeaconFields.length;
      error -= this.checkIbeaconCompanyId();
      error -= this.checkIbeaconBeaconId();
      error -= checkLevel(this.hideLevelField, this.ibeaconFields, LEVEL, ERR_LEVEL);
      error -= checkCodeZone(this.ibeaconFields, CODE_ZONE, ERR_CODE_ZONE);
      error -= checkDescription(this.ibeaconFields, DESC, ERR_DESC);
      if (!error && !beaconAlreadyExisting(this.beaconList, this.ibeaconFields[COMPANY_ID].value, this.ibeaconFields[BEACON_ID].value, this.ibeaconFields, ERR_IBEACON_ALREADY_EXIST))
        this.emitAddIbeaconSuccess();
      else
        this.$emit('addIbeaconFailed');
    },

    checkIbeaconCompanyId() {
      const companyId = this.ibeaconFields[COMPANY_ID].value.toUpperCase();
      if (!companyId.length || !regexCheck(companyId, /^[A-F0-9]{32}/))
        return setError(this.ibeaconFields, COMPANY_ID, ERR_IBEACON_COMP_ID);
      return resetError(this.ibeaconFields, COMPANY_ID);
    },

    checkIbeaconBeaconId() {
      const beaconId = this.ibeaconFields[BEACON_ID].value.toUpperCase();
      if (!beaconId.length || !regexCheck(beaconId, /^[A-F0-9]{8}/))
        return setError(this.ibeaconFields, BEACON_ID, ERR_IBEACON_BEAC_ID);
      return resetError(this.ibeaconFields, BEACON_ID);
    },

    emitAddIbeaconSuccess() {
      this.$emit('addIbeaconSuccess', this.createNewBeacon());
      resetAllFields(this.ibeaconFields);
    },

    createNewBeacon() {
      return [
        this.ibeaconFields[COMPANY_ID].value.toUpperCase(),
        this.ibeaconFields[BEACON_ID].value.toUpperCase(),
        this.ibeaconFields[CODE_ZONE].value,
        this.ibeaconFields[DESC].value,
        this.ibeaconFields[LEVEL].value,
      ]
    },

  }
})
</script>
