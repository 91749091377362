<template>
  <div>
    <Title
      title="configuration.menu_custom_on_off"
      help-locale="menu_wifi_connection_view"
    />

    <StopDevice
      ref="stopDevice"
    />

    <ActionDevice
      ref="ActionDevice"
      :is-sip-activated="isSipActivated"
    />

    <CustomButton
      text="common.menu_save"
      @click="saveForm()"
    />
  </div>
</template>

<script>
import StopDevice from './local-components/lc-custom-stop-device.vue'
import ActionDevice from './local-components/lc-custom-action-device.vue'
import { useStore } from "@/store";

import {
  getBoolPtiValue,

} from '../../dao/parametre-pti'


const I18_NAME = "internalrecipients.menu_name_recipient";
const I18_PHONE_NUMBER = "internalrecipients.menu_phone_number_international_format";

const MODEL_LOGIN_PATH = "model-login";

const PARAM_PTI_IS_SIP_AVAILABLE = "SipManagerAvailable";
const PARAM_PTI_IS_SIP_ACTIVATED = "SipActivationState";


export default {
  components: {
    StopDevice,
    ActionDevice,
  },

  data(){
    return {
      isSipActivated: false,
      inputFields: [{
          value: '',
          title: I18_NAME,
          maxLen: 50,
          errorMsg: '',
        },
        {
          value: '',
          title: I18_PHONE_NUMBER,
          maxLen: 16,
          errorMsg: '',
        },],

    }
  },
  mounted() {
    this.init();
  },

  created() {
    this.initSipValue();
  },
  

  methods: {
    initSipValue() {
      let isDeployCred = false; 
      if (useStore().state.emeritValues.disconnectionPath === MODEL_LOGIN_PATH)
        isDeployCred = true;
      const isSipAvailable = isDeployCred ? true : getBoolPtiValue(PARAM_PTI_IS_SIP_AVAILABLE);
      if (isSipAvailable === undefined)
        this.isSipActivated = false;
      else if (isSipAvailable)
        this.isSipActivated = getBoolPtiValue(PARAM_PTI_IS_SIP_ACTIVATED)
    },

    init(){
      this.$refs.ActionDevice.initinputfield();
    },
    saveForm() {
      this.$refs.stopDevice.onCheckBoxChange();
      this.$refs.ActionDevice.saveRadio();
      this.$refs.ActionDevice.saveInputFields();
    },
  }
}
</script>