{
  "common": {
    "menu_nothing":"Nothing",
    "menu_min_message": "The minimum message is 1",
    "menu_field_cannot_be_empty_need_one": "At least one field must be entered",
    "menu_affichage": "Display",
    "menu_son": "Sound",
    "menu_post_alert": "Post-alerte",
    "menu_vibration_5_secondes": "Vibrations every 5 seconds",
    "menu_phone_number_must_start": "The phone number must only contain numbers",
    "menu_wrong_file_certificate": "Incorrect file format. The format must be .pfx or .p12.",
    "menu_wrong_config": "The configuration is wrong",
    "menu_see_help_for_more_info": "Please see online help for more info.",
    "menu_back": "Back",
    "menu_save": "Save",
    "menu_settings": "Settings",
    "menu_notification_prealert": "Pre-alert notification",
    "menu_notification_by_vibration": "Notification by vibration",
    "menu_notification_by_sound": "Notification by sound",
    "menu_notification_by_flash": "Notification by flash",
    "menu_notification_duration": "Pre-alert notification duration",
    "menu_seconds": "seconds",
    "menu_minutes": "minutes",
    "menu_degrees": "degrees",
    "menu_meters": "meters",
    "menu_settings_for": "Settings for ",
    "menu_confirm": "Confirm",
    "menu_field_cannot_be_empty": "Field cannot be empty",
    "menu_select_at_least_one_triggering_mode": "Select at least one triggering mode",
    "menu_phone_number_must_start_with_+": "The phone number must start with + and contain only digits",
    "menu_phone_number_is_too_short": "Phone number is too short",
    "menu_phone_number_already_used": "Phone number already used",
    "menu_select_at_least_one_event": "Select at least one event",
    "menu_are_you_sure": "Are you sure?",
    "menu_add": "Add",
    "menu_modify": "Modify",
    "menu_remove": "Remove",
    "menu_remove_everything": "Remove everything",
    "menu_wrong_flie_format": "Wrong file format",
    "menu_cancel": "Cancel"
  },
  "configuration": {
    "menu_no_checked_standard": "No parameter has been checked for the standard mode",
    "menu_no_checked_transparent": "No parameter has been checked for the transparent mode",
    "menu_location_standard": "Standard Mode",
    "menu_location_transparent": "Transparent Mode",
    "menu_location_indoor": "Indoor",
    "menu_type_action": "Type of action associated with three quick taps",
    "menu_device_stop": "Device stop",
    "menu_custom_on_off": "Customize On/Off button",
    "menu_trigger_alert":"Trigger an alarm",
    "menu_trigger_call": "Trigger a call by triple-tapping the following number:",
    "menu_tippy_text_on_off":"Go to [Customize power button] to enable the functionality",
    "menu_show_last_location":  "Save the last known location at each device stop",
    "menu_force_alert_reactivated_system": "Force the reactivation of the system each time the device is turned on",
    "menu_err_serv_with_no_events": "No events have been configured",
    "menu_err_one_or_several_recipient_have_message_but_no_events": "One or more recipients receive events by SMS but no events have been configured",
    "menu_img_upload_failure": "Image upload failed, check your internet connection",
    "menu_choose_at_least_one_custom_screen": "No configuration done",
    "menu_custom_screens": "Customize screen",
    "menu_communication_option": "Communication options",
    "menu_system_option": "System options",
    "menu_disable_on_off_btn": "Disable power button to prevent device from being turned off",
    "menu_model_check_emerit_version_unstopable_call": "This setting requires the device firmware to be compatible. You can check the ROM version via the About widget.",
    "menu_tippy_unstopable_call_not_available": "This setting is not compatible with your device's firmware. Please contact Technical Support for more information.",
    "menu_call_cant_be_stop": "Alert calls cannot be stopped by the user",
    "menu_whitelist": "Whitelist (incoming call limitation)",
    "menu_wifi_configuration": "Wifi connection",
    "menu_tippy_sip_not_available": "VoIP activation is not available on your device",
    "menu_tippy_widget_loc": "This parameter requires the activation of at least one of the localization modes",
    "menu_model_check_emerit_version": "VOIP requires the Emerit SIP application to be present on this device. You can check if it is present with the About widget.",
    "menu_enabling_sip_will_remove_recipients": "Enabling VoIP will delete registered internal recipients. You will need to enter a new list of recipients.",
    "menu_disabling_sip_will_remove_recipients": "Disabling VoIP will delete registered internal recipients. You will need to enter a new list of recipients.",
    "menu_wrong_character_in_user_id": "User identifiant can't contain '_' character when there is at least one Emerit watch selected as internal recipient",
    "menu_choose_notif_mode": "Notification mode selection for incoming SMS",
    "menu_err_choose_at_least_one_notif_mode": "Choose at least one notification mode",
    "menu_alerts_processing": "Alerts processing",
    "menu_internal_recipients": "Internal recipients",
    "menu_alarm_monitoring_center": "Alarm monitoring center",
    "menu_alarm_monitoring_server": "Alarm monitoring server",
    "menu_alerts_localization": "Alerts localization",
    "menu_location_never": "Never",
    "menu_location_outdoor": "Outdoor",
    "menu_alerts_triggering_modes": "Alerts triggering mode",
    "menu_alert_activation_sos_button": "SOS button",
    "menu_alert_activation_sos_button_and_programmable_button": "SOS button and programmables button",
    "menu_alert_activation_side_black_button": "Side black button",
    "menu_alert_activation_side_yellow_button": "Side yellow button",
    "menu_alert_activation_front_programmable_button": "Front programmable button",
    "menu_alert_activation_side_programmable_button": "Side programmable button",
    "menu_alert_activation_automatic_detection": "Automatic detections",
    "menu_wrist_alert_mode": "Fast wrist movements",
    "menu_wearing_style_wrist": "Wrist",
    "menu_wearing_style_waist": "Waist",
    "menu_wearing_style_arms": "Arm",
    "menu_wearing_style_neck": "Neck",
    "menu_alert_activation_power_button": "Power ON/OFF button",
    "menu_update_configuration": "Update configuration",
    "menu_configuration_update_has_succeeded": "Configuration update has succeeded",
    "menu_device_must_be_restarted": "Start the device or start the procedure to load the new configuration",
    "menu_no_alert_recipient_was_selected": "No alert recipient was selected",
    "menu_no_alert_processing_was_selected": "No alert processing was selected",
    "menu_no_recipient_or_server_was_created": "No recipient or server was created",
    "menu_no_recipient_was_created": "No recipient was created",
    "menu_no_server_was_created": "No server was created",
    "menu_validate_configuration": "Validate configuration",
    "menu_wifi": "Wifi",
    "menu_select_at_least_one_automatic_detection": "Select at least one automatic detection",
    "menu_save_at_least_one_wifi_connection": "Save at least one Wifi network",
    "menu_configuration_update_has_failed": "Update has failed",
    "menu_check_internet_connection_and_retry": "Check internet connection and retry",
    "menu_download_configuration_model_to_computer": "Download configuration template to computer",
    "menu_configuration_model_name": "Configuration template name",
    "menu_device_notification": "Device notification management",
    "menu_password_for_deployment": "Password for configuration by deployment: 6 characters including at least 1 lowercase, 1 uppercase, and 1 number, no special character",
    "menu_enter_a_password": "Enter a password",
    "menu_password_must_be_different_from_access_password": "Password must be different from access password",
    "menu_location_indoor_wifi": "Indoor using Wifi",
    "menu_location_indoor_ble": "Indoor using Bluetooth",
    "menu_location_automatic_incoming_call_pickup_after_three_rings": "Automatic incoming call pickup after 3 rings",
    "menu_err_ble_level_precision_not_activated": "Level management using Bluetooth beacon has been selected for Wifi indoor location. This parameter is mandatory.",
    "menu_deployment_password": "Deployment password",
    "menu_no_wifi_access_point_has_been_registered": "No Wifi access point has been registered",
    "menu_no_ble_beacon_has_been_registered": "No Bluetooth beacon has been registered",
    "menu_deactivation_of_alert_system_by_the_user": "Deactivation of alert system by the user",
    "menu_freely_disable_enable_alert_system": "Allow to freely disable/enable alert system",
    "menu_temporarily_disable_enable_alert_system": "Allow to temporarily disable/enable alert system",
    "menu_duration_of_deactivation": "Duration of deactivation",
    "menu_GSM_data_wifi_issue_notification": "GSM, data and Wifi issue notification",
    "menu_battery_monitoring": "Battery monitoring",
    "menu_networks_monitoring": "Networks monitoring",
    "menu_low_battery_notification_triggering_threshold": "Low battery notification triggering threshold",
    "menu_name_is_not_displayed": "User name is not displayed on the device",
    "menu_confirm_deactivation_of_localisation": "Confirm deactivation of location",
    "menu_ask_to_activate_sip": "Activate voice over IP (VoIP) with SIP account",
    "menu_wrong_sip_configuration": "All VoIP fields must be filled",
    "menu_show_fragment_location": "Activate actual location widget",
    "menu_5_percent": "5%",
    "menu_10_percent": "10%",
    "menu_20_percent": "20%",
    "menu_name_is_displayed": "User name is displayed on the device",
    "menu_name_is_battery_percentage_is_displayed": "Show battery percentage",
    "menu_name_is_wake_up_screen_when_charging_enabled": "Wake up the screen every 10 seconds during the charge when watch is powered on",
    "menu_name_is_sound_notif_securite_positive_enabled": "Enable sound for notifications"
  },
  "alerttrigerring": {
    "menu_enable_selecte_switch":"Please Select at least one detection",
    "menu_agression": "Assault",
    "menu_demande_secours": "Asking for Help",
    "menu_message_alert": "Alert message to send",
    "menu_communication_unidirectionnelle": "One-way communication (speaker disabled)",
    "menu_communication_bidirectionnelle": "Two-Way Communication",
    "menu_cycle_destinataires_télésurveillance": "Automatic call cycle (internal recipients or remote monitoring call)",
    "menu_sos_button_settings": "SOS button settings",
    "menu_power_button_settings": "Power ON/OFF button settings",
    "menu_automatic_detection_settings": "Automatic detection settings",
    "menu_trigger_mode": "Trigger mode",
    "menu_one_long_press": "One long press",
    "menu_three_short_presses": "Three short presses",
    "menu_alert_mode": "Alert mode",
    "menu_speaker_on_request_for_help": "Speaker ON > request for help",
    "menu_silent_assault": "Silent > assault context",
    "menu_select_at_least_one_notification_mode": "Select at least one notification mode",
    "menu_front_programmable_button_settings": "Front programmable button settings",
    "menu_side_black_button_settings": "Side black button settings",
    "menu_side_yellow_button_settings": "Side yellow button settings",
    "menu_side_programmable_button_settings": "Side programmable buttons settings",
    "menu_sos_button_and_programmable_button_settings": "SOS button and programmable buttons settings"
  },
  "locationoutdoor": {
    "menu_outdoor_location": "Outdoor location settings",
    "menu_gps_based": "GPS based outdoor location",
    "menu_refreseh_only_when_event_sending": "Refresh only when event sending",
    "menu_update_at_regular_intervals": "Update at regular intervals",
    "menu_automatic_refresh_interval": "Automatic update interval",
    "menu_location_update_method": "Location update method"
  },
  "locationindoorwifi": {
    "menu_err_ssid_already_exist": "The SSID already exists in the list",
    "menu_no_ble_transparent": "No Bluetooth UUID has been registered",
    "menu_no_wifi_hotspot_transparent": "No WiFi SSID has been registered",
    "menu_no_param_found_indoord": "No parameter has been selected",
    "menu_list_ssid_filter": "List of SSIDs to filter",
    "menu_add_ssid_filter": "Add SSIDs to filter",
    "menu_wifi_based_indoor_location_transparent": "Indoor Location based on WiFi (Transparent Mode)",
    "menu_disable_ble_loc_to_handle_floors": "This setting must be disabled from the Bluetooth indoor location setup page",
    "menu_enable_ble_loc_to_handle_floors": "This setting must be enabled from the indoor location setup page via Bluetooth",
    "menu_wifi_based_indoor_location": "Wifi based indoor location",
    "menu_enable_wifi_based_indoor_location": "Enable Wifi based indoor location",
    "menu_import_a_new_wifi_hotspot_file": "Import a new Wifi hotspots .csv file",
    "menu_import_succeeded": "Import succeeded",
    "menu_confirm_import": "Confirm the importation",
    "menu_import_will_replace_existing_list": "Import from a .csv file will replace the actual Wifi hotposts list saved",
    "menu_error_while_importing": "Error while importing",
    "menu_export_to_csv_wifi_configuration": "Export to .csv file the wifi hotposts list",
    "menu_excel_file_is_empty": "Excel file is empty",
    "menu_check_your_excel_file": ". Check your Excel file.",
    "menu_download_excel_file_template_for_importation": "Download Excel template for import",
    "menu_number_of_imported_wifi_hotspots": "Number of imported WiFi hotspots: ",
    "menu_an_error_was_detected_line": "An error was detected line ",
    "menu_number_of_registered_wifi_hotspots": "Number of registered WiFi hotspots: ",
    "menu_incorrect_column_headers": "Incorrect column headers. Please use the Excel template to sease Wifi hotposts, and save it to .csv format with ';' character as separator.",
    "menu_wifi_hotspot_for_indoor_location": "Wifi hotspot for indoor location",
    "menu_no_wifi_hotspot_recorded": "No Wifi hotspot recorded",
    "menu_no_wifi_hotspot_imported": "No Wifi hotspot imported",
    "menu_add_hotspot": "Add Wifi hotspot",
    "menu_modify_hotspot": "Modify Wifi hotspot",
    "menu_hotspot_ssid": "SSID",
    "menu_hotspot_bssid": "BSSID",
    "menu_hotspot_code_zone": "Zone code",
    "menu_hotspot_level": "Level",
    "menu_hotspot_description": "Description",
    "menu_tippy_ssid": "Wifi network name",
    "menu_tippy_bssid": "MAC address emitted by the Wifi hotspot (6 pairs of hexadecimal characters separated by ':' character)",
    "menu_tippy_code_zone": "Information sent by SIA protocol",
    "menu_tippy_level": "0 for ground floor, negative number for basements (ex: -3)",
    "menu_tippy_description": "Location associated to the hotspot. It is forbidden to use the '_' character when the option to send messages to Emerit watches is activated due to a bug (this bug will be fixed in the next update)",
    "menu_err_empty_ssid": "SSID field can't be empty.",
    "menu_err_wrong_bssid": "Wrong BSSID. BSSID must be a sequence of 6 pairs of digits / letters separated by a ':' character. Example: 11:22:33:44:55:66.",
    "menu_err_bssid_already_exist": "The BSSID entered has already been registered.",
    "menu_err_wrong_level": "Wrong level number. Must be a positive or negative number.",
    "menu_err_empty_desc": "Description field can't be empty.",
    "menu_err_wrong_code_zone": "Code zone must be a sequence of 3 numbers between 001 and 999. Example: 007.",
    "menu_err_one_elem_missing_level": "The level has not been entered for all Wifi hotspots. Please check the registered list.",
    "menu_add_hotspot_success": "Wifi hotspot added with success",
    "menu_add_hotspot_failed": "Error while trying to add a new Wifi hotspot",
    "menu_modify_hotspot_success": "Wifi hotspot modified with success",
    "menu_level_precision_with_ble": "Handle floor changes using Bluetooth beacons",
    "menu_enable_import_export_csv": "Import / export .csv file",
    "menu_add_manually_wifi_hotspots": "Manually add Wifi hotspots",
    "menu_remove_all_hotspots": "Are you sure you want to delete all registered Wifi hotspots ?",
    "menu_remove_one_hotspot": "Are you sure you want to delete this Wifi hotspot ?",
    "menu_precise_wifi_location_with_ble": "Wifi location precised using Bluetooth",
    "menu_title_refresh_choice":" Frequence d'actualisation"
  },
  "locationindoorble": {
    "menu_remove_one_beacon_transparent": "Are you sure you want to delete this UUID",
    "menu_add_beacon_transparent_success": "UUID added successfully",
    "menu_condition_uuid_transparent": "The UUID format must be 32 characters in hexadecimal",
    "menu_add_beacon_transparent_failed": "UUID already registered",
    "menu_list_ssid_filtered": "List of SSIDs to filter",
    "menu_custom_model": "Other UUID",
    "menu_ble_based_indoor_location_transparent": "Indoor Location based on Bluetooth (Transparent Mode)",
    "menu_list_uuid_filtered": "List of UUIDs to filter",
    "menu_add_uuid_filtered": "Add Company ID (UUID) to filter",
    "menu_ble_based_indoor_location": "Bluetooth based indoor location",
    "menu_enable_ble_based_indoor_location": "Enable Bluetooth based indoor location",
    "menu_download_excel_file_template_for_importation": "Download Excel template for import",
    "menu_import_a_new_ble_beacons_file": "Import a new Bluetooth beacons .csv file",
    "menu_import_succeeded": "Import succeeded",
    "menu_confirm_import": "Confirm the importation",
    "menu_import_will_replace_existing_list": "Import from a .csv file will replace the actual Bluetooth beacons list saved",
    "menu_error_while_importing": "Error while importing",
    "menu_excel_file_is_empty": "Excel file is empty",
    "menu_check_your_excel_file": ". Check your Excel file.",
    "menu_export_to_csv_ble_configuration": "Export to .csv file the Bluetooth beacons list",
    "menu_no_ble_beacon_recorded": "No Bluetooth beacon recorded",
    "menu_no_ble_beacon_imported": "No Bluetooth beacon imported",
    "menu_number_of_registered_ble_beacons": "Number of registered Bluetooth beacons: ",
    "menu_incorrect_column_headers_ble": "Incorrect column headers. Please use the Excel template to sease Bluetooth beacons and save it to .csv format with ';' character as separator.",
    "menu_number_of_imported_ble_beacons": "Number of imported Bluetooth beacons: ",
    "menu_an_error_was_detected_line": "An error was detected line ",
    "menu_add_beacon": "Add Bluetooth beacon",
    "menu_modify_beacon": "Modify Bluetooth beacon",
    "menu_add_manually_ble_beacons": "Manually add Bluetooth beacon",
    "menu_remove_all_beacons": "Are you sure you want to delete all registered Bluetooth beacons ?",
    "menu_remove_one_beacon": "Are you sure you want to delete this Bluetooth beacon ?",
    "menu_add_beacon_success": "Bluetooth beacon added with success",
    "menu_add_beacon_failed": "Error while trying to add a new Bluetooth beacon",
    "menu_modify_beacon_success": "Bluetooth beacon modified with success",
    "menu_beacon_model": "Bluetooth beacon model",
    "menu_beacon_company_id": "Company ID",
    "menu_beacon_id": "Beacon ID",
    "menu_beacon_type": "Type",
    "menu_beacon_level": "Level",
    "menu_beacon_code_zone": "Zone code",
    "menu_beacon_description": "Description",
    "menu_tippy_uuid": "Corresponds to UUID from iBeacon protocol, which is a sequence of 32 hexadecimal characters",
    "menu_tippy_namespace_id": "Corresponds to namespace ID from Eddystone protocol, which is a sequence of 20 hexadecimal characters",
    "menu_tippy_bbe9_major_minor": "Corresponds to the 8 hexadecimal characters printed on e-BBE9 Bluetooth beacon",
    "menu_tippy_loc_ex_mac_address": "Corresponds to the MAC address printed on the Bluetooth LOC-EX 01 beacon (6 pairs of hexadecimal characters separated by ':')",
    "menu_tippy_ibeacon_major_minor": "Corresponds to the concatenation of Major + Minor from iBeacon protocol, which is a sequence of 8 hexadecimal characters",
    "menu_tippy_instance_id": "Corresponds to instance ID from Eddystone protocol, which is a sequence of 12 hexadecimal characters",
    "menu_tippy_level": "0 for ground floor, negative number for basements (ex: -3)",
    "menu_tippy_code_zone": "Information sent by SIA protocol",
    "menu_tippy_description": "Location associated to the beacon. It is forbidden to use the '_' character when the option to send messages to Emerit watches is activated due to a bug (this bug will be fixed in the next update)",
    "menu_err_ibeacon_company_id": "Wrong company ID. This corresponds to the UUID. It must therefore consist of 32 hexadecimal characters.",
    "menu_err_ibeacon_beacon_id": "Wrong beacon ID. This corresponds to Major + Minor. It must therefore consist of 8 hexadecimal characters.",
    "menu_err_eddystone_company_id": "Wrong company ID. This corresponds to the namespace ID. It must therefore consist of 20 hexadecimal characters.",
    "menu_err_eddystone_beacon_id": "Wrong beacon ID. This corresponds to instance ID. It must therefore consist of 12 hexadecimal characters.",
    "menu_err_bbe9_beacon_id": "Wrong beacon ID. This corresponds to the 8 hexadecimal characters printed on the e-BBE9 Bluetooth beacon.",
    "menu_err_bbe9_already_exist": "An e-BBE9 Bluetooth beacon with this identifiant is already exists.",
    "menu_err_loc_ex_beacon_id": "Wrong beacon ID. The beacon ID corresponds to the MAC address printed on the LOC-EX 01 Bluetooth Beacon.",
    "menu_err_loc_ex_already_exist": "A Bluetooth LOC-EX 01 beacon with this identifier already exists.",
    "menu_err_ibeacon_already_exist": "An iBeacon Bluetooth beacon with this UUID and this Major + Minor already exists",
    "menu_err_eddy_already_exist": "An Eddystone Bluetooth beacon with this namespace ID and this instance ID already exists",
    "menu_err_wrong_level": "Wrong level number. Must be a positive or negative number.",
    "menu_err_empty_desc": "Description field can't be empty.",
    "menu_err_wrong_code_zone": "Code zone must be a sequence of 3 numbers between 001 and 999. Example: 007.",
    "menu_level_precision_with_ble": "Handle floor changes",
    "menu_err_one_elem_missing_level": "Please indicate level for each Bluetooth beacon.",
    "menu_err_wifi_level_precision_is_activated": "Floor changes management using Bluetooth beacons has been selected in Wifi indoor location. This parameter is mandatory.",
    "menu_err_no_level_beacon": "At least one beacon with type set as LEVEL is needed to handle floor changes",
    "menu_enable_import_export_csv": "Import / export .csv file",
    "menu_title_refresh_ble_choice": "Refresh Frequency",
    "menu_scan_mode_fastest": "Refresh at regular intervals (6 seconds, high consumption)",
    "menu_scan_mode_average": "Refresh at regular intervals (10 seconds, moderate consumption)",
    "menu_scan_mode_on_demand": "Refresh only upon the transmission of an event",
    "menu_ble_new_algo_activation_title":"Algorithm configuration",
    "menu_ble_new_algo_activation":"Enable the new beacon localization algorithm"
  },
  "internalrecipients": {
    "menu_name_recipient": "Recipient name",
    "menu_duration_of_call_attempt_before_next_number": "Duration of the alert call attempt before switching to the next number",
    "menu_event_select_call_and_or_sip_messaging": "Select SIP Call and/or Message",
    "menu_request_loc_with_sms_command": "This recipient can request location by the following SMS command: ?LOC",
    "menu_sip_scenario": "Transmission of alerts using SIP account",
    "menu_alert_configuration_not_available_with_pec": "When the 'SMS with acknowledgment then call' alert scenario is activated, the configuration for receiving alerts is done automatically.",
    "menu_sens_sms_with_sip_not_possible": "Sending SMS to a phone number using SIP account is not available. Only calling is available.",
    "menu_err_sip_account_already_exist": "A recipient with this SIP ID already exists",
    "menu_receive_events_by_sip_messaging": "This recipient receives Events by SIP Messaging",
    "menu_switch_watch_is_using_sip_account": "This recipient is using a SIP account",
    "menu_recipient_added_with_success": "Recipient added",
    "menu_recipient_modified_with_success": "Recipient modified",
    "menu_modify_recipient": "Modify Recipient",
    "menu_sip_account_id": "SIP ID",
    "menU_sip_servel_url": "SIP Server URL",
    "menu_icon_sms_desc": ": This recipient receives SMS alerts",
    "menu_icon_phone_desc": ": This recipient receives call alerts",
    "menu_icon_watch_desc": ": This recipient is an Emerit watch",
    "menu_icon_sip_desc": ": This recipient is using a SIP account",
    "menu_number_or_sip_id": "Call number / SIP ID",
    "menu_remove_all_recipients": "Are you sure you want to remove all registered recipients?",
    "menu_remove_one_recipient": "Are you sure you want to remove this recipient?",
    "menu_add_recipient": "Add Recipient",
    "menu_sms_and_calls_cycle": "SMS and calls cycle",
    "menu_sms_then_calls_cycle": "SMS then calls cycle",
    "menu_sms_with_acknowledgment_then_call": "SMS with acknowledge then call",
    "menu_scenario_is": "Alert Scenario: ",
    "menu_settings": "Settings",
    "menu_nb_recipients_saved": "Number of internal recipients saved: ",
    "menu_is_emerit_watch": "This recipient is an Emerit watch",
    "menu_internal_recipients": "Internal recipients",
    "menu_monitoring_center_call_numbers": "Monitoring center call numbers",
    "menu_recipient": "Recipient",
    "menu_enable_recipient": "Enable recipient",
    "menu_call_number": "Call number",
    "menu_enable_call_number": "Enable call number",
    "menu_name": "Name",
    "menu_phone_number_international_format": "Phone number (international format)",
    "menu_receive_alerts_by_call": "This recipient receives alerts by call",
    "menu_receive_events_by_sms": "This recipient receives events by SMS",
    "menu_event_alerts": "Alerts",
    "menu_event_system_status": "System status",
    "menu_event_charge_status": "Charge status",
    "menu_event_low_battery": "Low battery",
    "menu_event_beacon_ble_low_battery": "Bluetooth Beacon Low Battery",
    "menu_event_select_call_and_or_sms": "Select call and / or SMS",
    "menu_nfc_reading": "NFC tag reading"
  },
  "internalrecipientssettings": {
    "menu_internal_recipients_settings": "Internal recipients settings",
    "menu_sms_language_selection": "SMS language selection",
    "menu_alert_scenario_type": "Alert scenario type",
    "menu_sms_and_calls_cycle": "SMS and calls cycle",
    "menu_sms_then_calls_cycle": "SMS then calls cycle",
    "menu_sms_with_acknowledgment_then_call": "SMS with acknowledgment then call",
    "menu_maximum_waiting_time_for_acknowledgment_reception": "Maximum waiting time for acknowledgment reception",
    "menu_call_cycle_stopped_by_sms": "Call cycle stopped by SMS",
    "menu_show_sms_data_selection": "Show SMS data selection",
    "menu_sms_data_selection": "SMS data selection",
    "menu_basic_data": "Basic data",
    "menu_gps_data": "GPS data",
    "menu_device_user": "Device user name",
    "menu_battery_level": "Battery level",
    "menu_event_timestamp": "Event time stamp",
    "menu_google_map_link": "Google Map link",
    "menu_timestamp": "Timestamp",
    "menu_adress_google_reverse_geocoding": "Address (Google reverse geocoding)",
    "menu_accuracy": "Accuracy",
    "menu_coordinates_DMS_format": "Coordinates in DMS format",
    "menu_coordinates_DD_format": "Coordinates in DD format",
    "menu_alert_labels_customization": "Alert labels customization",
    "menu_show_alert_labels_customization": "Show alert labels customization",
    "menu_request_for_help": "request for help (default)",
    "menu_assault": "assault (default)",
    "menu_no_movement": "no movement (default)",
    "menu_tilt": "tilt (default)"
  },
  "sipconfiguration": {
    "menu_title": "VOIP",
    "menu_sip_need_configuration": "Configure VOIP",
    "menu_account_name": "Account description",
    "menu_user_name": "User name",
    "menu_user_password": "Password",
    "menu_server_url": "Server url"
  },
  "ipserversettings": {
    "menu_std_gps_timestamp(O)": "[H] GPS position timestamp",
    "menu_extended_data_T2i_format_selection": "Extended data selection in T2i© format",
    "menu_T2i_format": "T2i© Format ",
    "menu_sia_protocol_settings": "Réglages protocole SIA DC-09",
    "menu_encapsulated_alarm_protol": "Encapsulated alarm protocol",
    "menu_admeco_cid_contact_id": "Ademco Contact ID (CID)",
    "menu_sia_dcs": "SIA DCS",
    "menu_extended_data_format_and_selection": "Extended data format and selection",
    "menu_standard_format": "Standard format",
    "menu_esi_format": "ESI© format",
    "menu_add_the_message_ending_timestamp": "Add the meesage ending timestamp",
    "menu_extended_data_standard_format_selection": "Extended data Standard format selection",
    "menu_extended_data_esi_format_selection": "Extended data ESI© format selection",
    "menu_std_battery": "[B] battery level",
    "menu_std_event_timestamp": "[H] event timestamp",
    "menu_std_imei": "[D] Device's IMEI",
    "menu_std_beacon_id": "[C] Wifi hotspot / Bluetooth beacon identifiant",
    "menu_std_gps_accuracy": "[M] GPS location accuracy",
    "menu_std_gps_timestamp": "[O] GPS location timestamp",
    "menu_std_gps_speed": "[S] GPS location speed",
    "menu_std_gps_validity": "[V] GPS location validity",
    "menu_std_gps_altitude": "[Z] GPS location altitude",
    "menu_esi_battery_level": "$B battery level",
    "menu_esi_gps_timestamp": "$T GPS location timestamp",
    "menu_gps_coordinates_format": "GPS coordinates format",
    "menu_gps_DD": "DD Decimal degrees",
    "menu_gps_DDM": "DDM degrees decimal minutes",
    "menu_customed_field": "Customed field",
    "menu_lpref": "Lpref",
    "menu_monitoring_center_server_sia_protocol_settings": "Monitoring center server SIA DC-09 protocol settings",
    "menu_monitoging_server_sia_protocol_settings": "Monitoring server SIA DC-09 protocol settings",
    "menu_location_maximum_age": "Location maximum age",
    "menu_location_maximum_accuracy": "Location maximum accuracy"
  },
  "ipserver": {
    "menu_unit_description_message": "Messages",
    "menu_event_end_tracking_stop_post_alert": "End Tracking on Post Alert Stop",
    "menu_event_end_tracking_after_x_message": "End Tracking After Sending",
    "menu_event_permanents": "Permanent",
    "menu_event_trigger_alert": "On Alert Trigger",
    "menu_enable_link_test": "Enable Link Test",
    "menu_link_test": "Link Test",
    "menu_alarm_monitoring_center_server": "Alarm monitoring center server",
    "menu_enable_main_server": "Enable main server",
    "menu_main_server": "Main server",
    "menu_events_to_be_transmitted": "Events to be transmitted",
    "menu_client_id": "Client ID",
    "menu_ip_url": "IP address or URL",
    "menu_port": "Port number",
    "menu_enable_cyclic_test": "Enable Tracking",
    "menu_cyclic_test": "Tracking",
    "menu_test_interval": "Test interval",
    "menu_backup_server": "Backup server",
    "menu_backup_sms": "Backup SMS",
    "menu_enable_backup_server": "Enable backup server",
    "menu_enable_backup_sms": "Enable backup SMS",
    "menu_port_must_be_between_0_and_65536": "Port number must be between 0 and 65536"
  },
  "wearingways": {
    "menu_waist_worn": "waist-worn",
    "menu_enable_no_movement_detection": "Enable no motion detection",
    "menu_enable_tilt_detection": "Enable tilt detection",
    "menu_no_movement_detection": "No motion detection",
    "menu_tilt_detection": "Tilt detection",
    "menu_detection_duration_before_prealert": "Detection duration before pre-alert",
    "menu_detection_threshold": "Detection threshold",
    "menu_detection_mode": "Detection mode",
    "menu_tilt_only": "Tilt only",
    "menu_tilt_without_movement": "Tilt without motion",
    "menu_select_a_wearing_way_and_modify_settings_if_needed": "select a wearing-way and modify settings if needed"
  },
  "wristalert": {
    "menu_noise_mode": "Speaker ON > request for help",
    "menu_discret_mode": "Silent > assault context",
    "menu_choose_alert_mode": "Alert mode",
    "menu_title_wrist_alert": "Alert configuration using wrist movement",
    "menu_choose_threshold": "Motion intensity to trigger alert",
    "menu_thresold_value_low": "Low",
    "menu_thresold_value_middle": "Medium (recommended)",
    "menu_thresold_value_high": "High"
  },
  "login": {
    "menu_first_device_boot_error": "If this is the first configuration of a device, it is necessary to follow a commissioning procedure before it can be configured.",
    "menu_quick_start_guide": "Online help",
    "menu_home_help": "https://magneta.document360.io/v1/en",
    "menu_connexion_error": "A connection problem has occurred. Please check your internet connection.",
    "menu_enter_five_last_digits_of_serial_number": "Enter the 5 last digits of serial number (SN)",
    "menu_enter_five_last_digits_of_imei_number": "Enter the 5 last digits of IMEI number",
    "menu_login": "Login",
    "menu_select_device_model": "Select the device model",
    "menu_please_select_the_device_model": "Please first select the device model",
    "menu_entered_numbers_dont_match_with_available_device": "Entered numbers don't match with any device or a connection issue has occurred.",
    "menu_please_read_quick_start_guide_first": "Please read quick start guide first.",
    "menu_configure_this_device": "Configure this device",
    "menu_configuration_models_management": "Configuration templates management",
    "menu_deploy_a_configuration_model": "Deploy a configuration template",
    "menu_invalid_file": "Invalid file",
    "menu_only_file_with_emerit_extension_are_supported": "Only file with .emerit extension are supported",
    "menu_create_configuration_model": "Create configuration template",
    "menu_modify_a_configuration_model": "Modify a configuration template",
    "menu_drag_and_drop_file_or_click_to_select": "Drag and drop or click to select the configuration template file",
    "menu_configuration_model": "Configuration template",
    "menu_configuration_model_creation": "Create a configuration template",
    "menu_connect_to_a_device": "Connect to a device",
    "menu_models_dont_match": "The selected file does not match or the configuration model is not compatible with the selected model.",
    "menu_select_language": "Select language"
  },
  "wifi": {
    "menu_mode_utilisation": "Updating the time zone according to usage mode",
    "menu_select_utc": "Select a time zone",
    "menu_error_utc_none": "Please select a time zone",
    "menu_mode_wifi_alone": "WiFi only:",
    "menu_mode_gsm_plus_wifi": "WiFi + GSM (SIM card): auto update",
    "menu_certificate_upload_success": "Certificate successfully uploaded",
    "menu_certificate_upload_fail": "Certificate download failed",
    "menu_err_pls_upload_certificate": "Please download a certificate",
    "menu_err_only_pfx_p12_accepted": "Only certificates in .pfx/.p12 format are accepted.",
    "menu_err_certificate_is_too_heavy": "The size of the certificate is too large, it cannot exceed 5Mb.",
    "menu_upload_certificat": "Import the PEAP certificate (.pfx/.p12)",
    "menu_certificate_password": "Password",
    "menu_certificate_identifiant": "Identifiant",
    "menu_certificate_type": "CA Certificat",
    "menu_name_is_update_app_by_wifi_enabled": "Retrieve configurations and install updates only if wifi is active",
    "menu_use_wifi_connection": "Use wifi connection for alerts sending",
    "menu_config_wifi_network": "Manage Wifi networks",
    "menu_nb_wifi_network_saved": "Number of WiFi networks saved: ",
    "menu_send_alert_with_wifi": "The Wifi network if available will be prioritized over the GSM network",
    "menu_network_added_with_success": "Wifi Network Added",
    "menu_network_modified_with_success": "Wifi Network Modified",
    "menu_remove_all_networks": "Are you sure you want to remove all saved WiFi networks?",
    "menu_remove_one_network": "Are you sure you want to remove this WiFi network?",
    "menu_registred_wifi_networks_list": "Registered Wifi networks list - 2.4 GHz only",
    "menu_ssid": "Network name SSID",
    "menu_security_type": "Security type",
    "menu_password": "Password",
    "menu_add_a_wifi_network": "Add a Wifi network",
    "menu_modify_a_wifi_network": "Modify a Wifi network",
    "menu_do_you_want_to_remove_this_network": "Do you want to remove this network?",
    "menu_please_select_security_type": "Please select security type",
    "menu_hidden": "Hidden network",
    "menu_network_already_exists": "Network already exist"
  },
  "whitelist": {
    "menu_add_a_recipient_whitelist": "Add additional incoming number",
    "menu_modify_a_recipient_whitelist": "Modify additional incoming number",
    "menu_recipient_whitelist_modified_with_success": "Additional incoming number modified successfully",
    "menu_recipient_whitelist_added_with_success": "Additional incoming number added successfully",
    "menu_nb_recipient_whitelist": "Additional incoming numbers: ",
    "menu_nb_recipient_mirror": "Alert recipients: ",
    "menu_remove_all_recipient_whitelist": "Are you sure you want to remove all additional incoming numbers from the whitelist?",
    "menu_remove_one_recipient_whitelist": "Are you sure you want to remove this additional incoming number from the whitelist?",
    "menu_add_whitelist_recipient": "Add incoming number",
    "menu_nb_total_whitelist_recipient": "Total number of incoming numbers allowed in the whitelist: ",
    "menu_whitelist_title": "Whitelist configuration"
  },
  "customscreens": {
    "menu_img_upload_info": "The size of a .png/.gif image must not exceed 1 MB. The screen resolution of the watch is 128x96 pixels. For optimal rendering of the image, respect the ratio of the screen between height/width (1.33). If the ratio is not respected, the image will be automatically resized.",
    "menu_err_pls_upload_launch_screen": "Please upload an image.",
    "menu_err_pls_upload_widget_screen": "Please upload an image or enter text.",
    "menu_err_only_png_gif_accepted": "Only images in .png/.gif format are accepted.",
    "menu_err_img_is_too_heavy": "The image size is too large, it cannot exceed 1MB.",
    "menu_img_upload_success": "Image uploaded successfully",
    "menu_img_upload_fail": "Failed to upload image",
    "menu_custom_screens_overview": "Preview",
    "menu_remove_launch_img": "Remove image",
    "menu_remove_widget_img": "Remove background image",
    "menu_display_widget_text_in_black": "Display text in black",
    "menu_custom_widget_top_text": "Top text (max 18 characters including spaces)",
    "menu_custom_widget_bottom_text": "Bottom text (max 18 characters including spaces)",
    "menu_upload_launch_screen_img": "Upload new image (.png/.gif)",
    "menu_upload_widget_screen_img": "Upload new background image (.png/.gif)",
    "menu_title_yes_custom_launch_screen": "Launch image setup",
    "menu_title_no_custom_launch_screen": "Customize launch screen",
    "menu_title_yes_custom_widget_screen": "Contact widget setup",
    "menu_title_no_custom_widget_screen": "Add contact widget",
    "menu_custom_screens_title": "Customize screen"
  },
  "password": {
    "menu_create_new_password_for_model": "Enter a 6-character password including at least 1 lowercase, 1 uppercase and 1 number, with no special characters. This password will be requested each time this model is modified.",
    "menu_enter_password": "Enter password",
    "menu_enter_deployment_password": "Enter deployment password",
    "menu_create_new_password": "Enter a 6 characters password including at least 1 lowercase, 1 uppercase, and 1 number, no special character. This password will be requested each time you connect to this device.",
    "menu_confirm_password": "Confirm password",
    "menu_wrong_password": "Wrong password",
    "menu_please_retry": "Please retry",
    "menu_empty_password": "Empty password",
    "menu_passwords_dont_match": "Passwords don't match",
    "menu_at_least_one_password_is_empty": "At least one password is empty",
    "menu_password_dont_meet_requirements": "Password doesn't meet requirements",
    "menu_modify_password": "Modify password",
    "menu_password_creation_warning": "By logging into emerit-console and after creating the password, you become the de facto administrator of the device identified above"
  },
  "contact": {
    "menu_contact_link": "https://www.magneta.fr/en/contact/",
    "menu_if_you_have_any_question_please_click_on_the_folowing_button": "For any question about device configuration or usage, please click on following button:",
    "menu_technical_support_email": "Technical support email:",
    "menu_online_support_request_form": "Online support request form"
  },
  "deploy": {
    "menu_upload_failed": "Deployment failed, check internet connection and try again",
    "menu_device_updated": "Device updated",
    "menu_fill_server_ids": "Configuring client IDs",
    "menu_fill_personal_fields": "Fill in the personalized datas",
    "menu_target_a_device": "Device to configure",
    "menu_client_id_for_tls_main_server": "Client ID for monitoring center main server",
    "menu_client_id_for_tls_backup_server": "Client ID for monitoring center backup server",
    "menu_client_id_for_supervision_main_server": "Client ID for main monitoring server",
    "menu_client_id_for_supervision_backup_server": "Client ID for backup monitoring server",
    "menu_from_a_configuration_model": "From configuration template",
    "menu_five_last_digits_of_serial_number": "5 last digits of serial number (SN)",
    "menu_five_last_digits_of_imei_number": "5 last digits of IMEI number",
    "menu_deploy_not_allowed": "You are not allowed to configure this device by deployment, deployment passwords do not match"
  },
  "side_menu": {
    "configuration": "Configuration",
    "info": "Manuals",
    "contact": "Contact"
  },
  "watchhelp": {
    "menu_custom_screens_view": "https://www.support.magneta.fr/docs/en/watch-system-options#ii-customize-screen",
    "menu_locex_help": "https://www.support.magneta.fr/docs/en/beacon-loc-ex-01",
    "menu_wifi_connection_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#i-use-wifi-connection",
    "menu_sip_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#ii-activate-voice-over-ip-voip-with-sip-account",
    "menu_whitelist_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#iii-whitelist-incoming-call-limitation",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/en/watch-main-page#i-device-user-name",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/en/watch-main-page#ii-alerts-processing",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/en/watch-main-page#iii-alerts-triggering-mode",
    "menu_loc": "https://www.support.magneta.fr/docs/en/watch-main-page#iv-alerts-localization",
    "menu_communication_option": "https://www.support.magneta.fr/docs/en/watch-main-page#v-communication-options",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/en/watch-main-page#vi-battery-monitoring",
    "menu_device_notif_management": "https://www.support.magneta.fr/docs/en/watch-main-page#vii-device-notification-management",
    "menu_system_option": "https://www.support.magneta.fr/docs/en/watch-main-page#viii-system-options",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9#associating-a-type-to-beacons",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#configuring-personal-settings",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#entering-the-deployment-password",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#templates-modification",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#configuration-template-creation",
    "menu_model_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#ii-multidevice-setup",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#iii-deployment-of-a-configuration",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#enter-password",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#password-creation",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#i-gps-outdoor-location",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#ii-wifi-indoor-location",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#iii-bluetooth-indoor-location",
    "menu_main_page": "https://www.support.magneta.fr/docs/en/watch-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/en/watch-commissioning#iv-first-boot"
  },
  "phonehelp": {
    "menu_locex_help": "https://www.support.magneta.fr/docs/en/beacon-loc-ex-01",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/en/smartphones-main-page#i-device-user-name",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/en/smartphones-main-page#ii-alerts-processing",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/en/smartphones-main-page#iii-alerts-triggering-mode",
    "menu_loc": "https://www.support.magneta.fr/docs/en/smartphones-main-page#iv-alerts-localization",
    "menu_communication_option": "https://www.support.magneta.fr/docs/en/smartphones-main-page#v-communication-options",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/en/smartphones-main-page#vi-battery-monitoring",
    "menu_deactivate_alert_system": "https://www.support.magneta.fr/docs/en/smartphones-main-page#vii-alert-system-deactivation-by-the-user",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9#associating-a-type-to-beacons",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#configuring-personal-settings",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#entering-the-deployment-password",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#templates-modification",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#configuration-template-creation",
    "menu_model_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#ii-multidevice-setup",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#iii-deployment-of-a-configuration",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#enter-password",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#password-creation",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#i-gps-outdoor-location",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#ii-wifi-indoor-location",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#iii-bluetooth-indoor-location",
    "menu_main_page": "https://www.support.magneta.fr/docs/en/smartphones-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/en/smartphones-commissioning#ii-first-boot"
  }
}
