<template>
  <div
    :id="modalId"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <component
              :is="iconComponentName" 
              class="w-16 h-16 text-theme-6 mx-auto mt-3" 
            />
            <div class="text-3xl mt-5">
              {{ $t(alertTitle) }}
            </div>
            <div class="text-gray-600 mt-2">
              {{ $t(alertText) }}
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <component
              :is="leftButtonComponentName"
              ref="leftButton"
              :text="leftButtonText"
              :custom-class="leftButtonCustomClass"
              @click="$emit('leftButton')"
            />
            <component
              :is="rightButtonComponentName"
              :text="rightButtonText"
              :custom-class="rightButtonCustomClass"
              @click="$emit('rightButton')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * An alert box with a title and a text, and letting the user choose
 * between two options. The alert box is using bootstrap modal.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} modalId 
 *    The HTML id attribute of the alert that bootstrap will target
 *    in order to pop the alert. Should be the same than data-target 
 *    Bootstrap HTML attribute.
 * 
 * @param {String} alertTitle
 *    The title of the alert. Should be key for i18 locale.
 * 
 * @param {String} alertText
 *    The text describing the action that the user must confirm.
 *    Should be key for i18 locale.
 * 
 * @param {String} iconComponentName
 *    Name of the icon to load the dynamic icon component. Ex: 'SlidersIcon'.
 *
 * @param {String} leftButtonComponentName
 *    Name of the left button to load dynamic button component. 
 *    This button component should be a ButtonDismissModal type.
 *    Ex: 'ButtonDismissModal'.
 * 
 * @param {String} leftButtonText
 *    The button's text. Should be key for i18 locale.
 * 
 * @param {String} leftButtonCustomClass
 *    A custom class that can override the default class for left button
 *    (by default a blue button). Some examples:
 *      - yellow: 'btn btn-warning'
 *      - black: 'btn btn-dark'
 *      - blue: 'btn btn-primary'
 *      - white: 'btn btn-outline-secondary'
 *      - red: 'btn btn-danger' 
 * 
 * @param {String} rightButtonComponentName
 *    Name of the right button to load dynamic button component. 
 *    This button component should be a ButtonDismissModal type.
 *    Ex: 'ButtonDismissModal'.
 * 
 * @param {String} rightButtonText
 *    The button's text. Should be key for i18 locale.
 * 
 * @param {String} rightButtonCustomClass
 *    A custom class that can override the default class for right button
 *    (by default a blue button). Some examples:
 *      - yellow: 'btn btn-warning'
 *      - black: 'btn btn-dark'
 *      - blue: 'btn btn-primary'
 *      - white: 'btn btn-outline-secondary'
 *      - red: 'btn btn-danger' 

 *    
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits leftButton
 *    Empty event emitted when clicking on left button.
 * 
 * @emits rightButton
 *    Empty event emitted when clicking on right button.
 * 
 * ---------------- METHODS ACCESSIBLE USING REFS -------------------
 * @method dismiss
 * @param void
 *    Dismiss the modal if showed to the user.
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  name: 'ModalAlertConfirmChoice',
  
  props: {
    modalId: {
      type: String,
      required: true,
    },
    alertTitle: {
      type: String,
      required: true,
    },
    alertText: {
      type: String,
      required: true,
    },
    iconComponentName: {
      type: String,
      required: true,
    },
    leftButtonComponentName: {
      type: String,
      required: true,
    },
    leftButtonText: {
      type: String,
      required: true,
    },
    leftButtonCustomClass: {
      type: String,
      default: 'btn btn-primary mr-1'
    },
    rightButtonComponentName: {
      type: String,
      required: true,
    },
    rightButtonText: {
      type: String,
      required: true,
    },
    rightButtonCustomClass: {
      type: String,
      default: 'btn btn-primary'
    },
  },
  
  emits: ['leftButton', 'rightButton'],

  methods: {
    dismiss() {
      this.$refs.leftButton.click();
    },
  }
})
</script>
