export const STORE_RESET_EMERIT_VALUES = "emeritValues/resetEmeritValues";
export const STORE_UPDATE_LOGIN_STATUS = "emeritValues/updateLoginStatus";
export const STORE_SAVE_DEVICE_INFO = "emeritValues/saveDeviceInfo";
export const STORE_SET_MODEL_FILE_AS_TEMPLATE_CONF =
  "emeritValues/setModelFileAsTemplateConf";
export const STORE_SET_PATH_DISCONNECTION = "emeritValues/setDisconnectionPath";
export const STORE_SET_IS_CUSTOM_SCREENS = "emeritValues/setIsCustomScreens";
export const STORE_SET_LAUNCH_SCREEN_IMG_FILE =
  "emeritValues/setLaunchScreenImgFile";
export const STORE_SET_WIDGET_SCREEN_IMG_FILE =
  "emeritValues/setWidgetScreenImgFile";
export const STORE_SET_WIFI_PEAP_CERTIFICATE_FILE =
  "emeritValues/setWifiPEAPCertificateFile"; //Certificate PEAP
export const STORE_SET_WIDGET_SCREEN_IMG_UPDATE_S3 =
  "emeritValues/setWidgetScreenImgUpdateS3";
export const STORE_SET_LAUNCH_SCREEN_IMG_UPDATE_S3 =
  "emeritValues/setLaunchScreenImgUpdateS3";
export const STORE_SET_WIFI_PEAP_CERTIFICATE_UPDATE_S3 =
  "emeritValues/setWifiPEAPCertificateUpdateS3"; //Certificate PEAP
export const STORE_SET_PEAP_CERTIFICATE_OBJ =
  "emeritValues/setCertificateObjet"; //Certificate PEAP
export const STORE_SET_TARGET_DEVICE_INFO = "emeritValues/setTargetDeviceInfo";
export const STORE_CLONE_MODEL_DB = "emeritValues/cloneModelDB";

export const STORE_SAVE_SECRETS = "emeritValues/setSecrets";
export const STORE_SAVE_S3_ACCESS_KEY_ID = "emeritValues/sets3AccessKeyID";

export const STORE_GET_DB_FILE_FROM_S3 = "emeritValues/getDbFileFromS3";
export const STORE_GET_IMG_FILE_FROM_S3 = "emeritValues/getImgFileFromS3";
export const STORE_GET_TARGET_DB_FROM_S3 =
  "emeritValues/getTargetDeviceDBFileFromS3";

export const STORE_UPLOAD_DB_FILE_TO_S3 = "emeritValues/uploadDbFileToS3";
export const STORE_UPLOAD_IMG_FILE_TO_S3 = "emeritValues/uploadImgFileToS3";
export const STORE_UPLOAD_WIFI_PEAP_CERTIFICATE_FILE_TO_S3 =
  "emeritValues/uploadWifiPEAPCertificateFileToS3"; //Certificate PEAP
export const STORE_UPLOAD_TARGET_DB_TO_S3 =
  "emeritValues/uploadTargetDeviceDBFileToS3";

export const STORE_DOWNLOAD_ZIP_CONFIG_TO_PC =
  "emeritValues/downloadZipConfigToPc";
export const STORE_REPLACE_PEAP_CERTIFICAT =
  "emeritValues/setReplaceCertificateTable"; //Certificate PEAP

export const FILENAME_DB = "/Emerit.db";
export const FILENAME_LAUNCH_IMG = "/custom_launch_screen_img";
export const FILENAME_WIDGET_IMG = "/custom_widget_screen_img";
export const FILENAME_WIFI_CERT = "/peap_certificate";

export const EMERIT_BUCKET_NAME = "emerit-android-database-bucket";

export const STORE_S3_IMG_FILES_INFO = [
  {
    storeVarName: "launchScreenImg",
    fileName: FILENAME_LAUNCH_IMG,
    storeMutateFuncName: "setLaunchScreenImgFile"
  },
  {
    storeVarName: "widgetScreenImg",
    fileName: FILENAME_WIDGET_IMG,
    storeMutateFuncName: "setWidgetScreenImgFile"
  }
];

export const STORE_S3_CERT_FILE_INFO = [
  {
    storeVarName: "wifiPEAPCertificate",
    fileName: FILENAME_WIFI_CERT,
    storeMutateFuncName: "setWifiPEAPCertificateFile"
  }
];

export const NAME_EWG100 = "E-WG100";
export const NAME_RG170 = "RG170";
export const NAME_RG650 = "RG650";
export const NAME_RG655 = "RG655";
export const NAME_IS330 = "IS330";
export const NAME_IS530 = "IS530";
export const NAME_IS655 = "IS655";

export const STORE_SIGMA_IMG = require("@/assets/images/sigma-emerit-web.png");

export const STORE_IMG_FILES = [
  {
    modelName: NAME_EWG100,
    img: require("@/assets/images/e-WG100.png"),
    smallImg: require("@/assets/images/e-WG100_small.png")
  },
  {
    modelName: NAME_RG170,
    img: require("@/assets/images/e-RG170.png"),
    smallImg: require("@/assets/images/e-RG170_small.png")
  },
  {
    modelName: NAME_RG650,
    img: require("@/assets/images/e-RG650.png"),
    smallImg: require("@/assets/images/e-RG650_small.png")
  },
  {
    modelName: NAME_RG655,
    img: require("@/assets/images/e-RG655.png"),
    smallImg: require("@/assets/images/e-RG655_small.png")
  },
  {
    modelName: NAME_IS330,
    img: require("@/assets/images/e-IS330.png"),
    smallImg: require("@/assets/images/e-IS330_small.png")
  },
  {
    modelName: NAME_IS530,
    img: require("@/assets/images/e-RG530.png"),
    smallImg: require("@/assets/images/e-RG530_small.png")
  },
  {
    modelName: NAME_IS655,
    img: require("@/assets/images/e-IS655.png"),
    smallImg: require("@/assets/images/e-IS655_small.png")
  }
];
