<template>
  <!-- BEGIN: AlertRecipientsSettings local component -->
  <div class="clearfix box mt-6">
    <BoxTitle
      title="configuration.menu_alerts_processing"
      color="bg-red-300"
      help-locale="menu_alerts_processing"
    />
    <CheckBoxFieldAndXIcons
      v-for="(field, index) in fields"
      :key="index"
      v-model="field.value"
      :custom-id="`checkbox-alert-recipient-${index}`"
      :check-box-text="field.title"
      :error-msg="field.errorMsg"
      :icon-params="field.iconParams"
      @change="onCheckBoxChange(index)"
    />

  </div>
  <!-- END: AlertRecipientsSettings local component -->
</template>

<script>
import { defineComponent } from "vue";
import {
getNumberPtiValue,
  getParameterPTIValue,
  setParameterPTIValue
} from "../../../dao/parametre-pti";
import {
  getCountRecipientAlarmSms,
  getCountRecipientAlarmCall,
  getCountRecipient,
  deleteAllRecipient,
  getCountRecipientWithSmsButNoEvent,
} from "../../../dao/recipient-dao";
import { 
  getCountRecipientTakenIntoAccount 
} from "../../../dao/recipient-taken-into-account";
import {
  getCountRecipientTlsIpAlarm,
  getCountRecipientTlsIp,
  updateTlsRecipientSmsBackup,
  getCountRecipientTlsIpWithNoEvent,
} from "../../../dao/tls-ip-dao";
import {
  getCountRecipientSupIpAlarm,
  getCountRecipientSupIp,
  updateRecipientSupIpSmsBackup,
  getCountRecipientSupIpWithNoEvent,
} from "../../../dao/sup-ip-dao";
import { getCountRecipientTlsCall } from "../../../dao/tls-call-dao";


const INTERNAL_RECIPIENTS = 0;
const TLS_RECIPIENTS = 1;
const IP_SERVER = 2;

const ERR_NO_ALERT_RECIPIENT = 'configuration.menu_no_alert_recipient_was_selected';
const ERR_NO_ALERT_PROCESS = 'configuration.menu_no_alert_processing_was_selected';
const ERR_NO_RECIPIENT = 'configuration.menu_no_recipient_was_created';
const ERR_NO_RECIPIENT_OR_SERVER = 'configuration.menu_no_recipient_or_server_was_created';
const ERR_NO_SERVER = 'configuration.menu_no_server_was_created';
const ERR_RECIPIENT_WITH_SMS_BUT_NO_EVENTS_SELECTED = "configuration.menu_err_one_or_several_recipient_have_message_but_no_events";
const ERR_SERV_WITH_NO_EVENTS_SELECTED = "configuration.menu_err_serv_with_no_events";

const CHECKBOX_PTI_PARAMS = [
  'TraitementAppelEtSms',
  'TraitementServiceTelesurveillance',
  'TraitementServeurSupervision',
];

const PTI_PARAM_SMS_CASCADE = "TraitementSmsAppelsCascade";
const PTI_PARAM_SMS_GROUPE_CASCADE = "TraitementSmsGroupeAppelEnCascade";
const PTI_PARAM_SMS_PEC = "TraitementSmsPriseEnCompteAppel";


export default defineComponent({
  
  props: {
    isSipAvailable: {
      type: Boolean,
      required: true,
    },
    isSipActivated: {
      type: Boolean,
      required: true,
    },
  },
  
  data() {
    return {
      fields: [
        {
          value: false,
          title: 'configuration.menu_internal_recipients', 
          errorMsg: '', 
          isDisabled: false,
          iconParams: [
            { componentName: 'SlidersIcon', routeName: 'emerit-internal-recipients-settings', paramsType: '' },
            { componentName: 'UsersIcon', routeName: 'emerit-internal-recipients', paramsType: '' },
          ],
        },
        {
          value: false,
          title: 'configuration.menu_alarm_monitoring_center',
          errorMsg: '', 
          isDisabled: false,
          iconParams: [
            { componentName: 'ServerIcon', routeName: 'emerit-ip-server', paramsType: 'tls' },
            { componentName: 'UsersIcon', routeName: 'emerit-tls-recipients', paramsType: '' },
          ],
        },
        {
          value: false,
          title: 'configuration.menu_alarm_monitoring_server',
          isDisabled: false,
          errorMsg: '', 
          iconParams: [
            { componentName: 'ServerIcon', routeName: 'emerit-ip-server', paramsType: 'supervision' },
          ],
        },
      ],
      settingsIcon: {
        componentName: 'SlidersIcon',
        routeName: ['emerit-internal-recipients-settings', 'emerit-ip-server-settings', 'emerit-ip-server-settings'],
        paramsType: ['', 'tls', 'supervision'],
      },
    }
  },

  watch: {
    isSipActivated: 'deactivateSmsOptionsIfSipActivated',
  },

  created() {
    for (let i = 0; i < this.fields.length; ++i) {
      this.updateSettingsIcons(i);
      this.fields[i].value = this.getBoolPtiValue(CHECKBOX_PTI_PARAMS[i]);
    }
  },

  methods: {
    getBoolPtiValue(ptiParam) {
      return Boolean(Number(getParameterPTIValue(ptiParam)));
    },

    setPtiValue(ptiParam, value) {
      setParameterPTIValue(ptiParam, Number(value))
    },

    deactivateSmsOptionsIfSipActivated() {
      if (this.isSipAvailable) {
        deleteAllRecipient();
        if (this.isSipActivated) {
          updateTlsRecipientSmsBackup(0);
          updateRecipientSupIpSmsBackup(0);
          if (this.getBoolPtiValue(PTI_PARAM_SMS_PEC)) {
            setParameterPTIValue(PTI_PARAM_SMS_PEC, 0);
            setParameterPTIValue(PTI_PARAM_SMS_CASCADE, 1);
          }
        }
        this.updateSettingsIcons(INTERNAL_RECIPIENTS);
      }
    },

    onCheckBoxChange(i) {
      this.setPtiValue(CHECKBOX_PTI_PARAMS[i], this.fields[i].value);
      this.updateCheckBoxes(i);
      this.resetAllErrorFields();
    },

    updateSettingsIcons(i) {
      if (i == TLS_RECIPIENTS && getCountRecipientTlsIp()
          || i == IP_SERVER && getCountRecipientSupIp())
        this.fields[i].iconParams.splice(0, 0, this.getSettingsIcon(i));
        
      if (i == INTERNAL_RECIPIENTS && this.isSipAvailable && !this.isSipActivated
          && this.fields[i].iconParams.length == 1)
        this.fields[i].iconParams.splice(0, 0, this.getSettingsIcon(i));
      else if (i == INTERNAL_RECIPIENTS && this.isSipAvailable && this.isSipActivated
          && this.fields[i].iconParams.length == 2)
        this.fields[i].iconParams.splice(0, 1);
    },

    getSettingsIcon(i) {
      return {
        componentName: this.settingsIcon.componentName, 
        routeName: this.settingsIcon.routeName[i], 
        paramsType: this.settingsIcon.paramsType[i], 
      };
    },

    updateCheckBoxes(i) {
      if (i == INTERNAL_RECIPIENTS && this.fields[i].value
          && this.fields[TLS_RECIPIENTS].value) {
        this.fields[TLS_RECIPIENTS].value = false;
        this.setPtiValue(CHECKBOX_PTI_PARAMS[TLS_RECIPIENTS], this.fields[TLS_RECIPIENTS].value);
      }
      else if (i == TLS_RECIPIENTS && this.fields[i].value
          && this.fields[INTERNAL_RECIPIENTS].value) {
        this.fields[INTERNAL_RECIPIENTS].value = false;
        this.setPtiValue(CHECKBOX_PTI_PARAMS[INTERNAL_RECIPIENTS], this.fields[INTERNAL_RECIPIENTS].value);
      }
    },

    getNbActiveFields() {
      let nbActive = 0;
      for (let i = 0; i < this.fields.length; ++i)
        if (this.fields[i].value)
          ++nbActive;
      return nbActive;
    },

    check() {
      if (this.allFieldsAreEmpty())
        return this.setError(IP_SERVER, ERR_NO_ALERT_PROCESS);
      if (!this.checkEachField())
        return false;
      if (!this.getNbAlarmRecipient())
        return this.setError(IP_SERVER, ERR_NO_ALERT_RECIPIENT);
      return true;
    },

    getNbAlarmRecipient() {
      const getNbFunctions = [
        this.getNbAlarmInternalRecipient,
        this.getNbAlarmTls,
        getCountRecipientSupIpAlarm,
      ]
      let nbAlarmRecipient = 0;
      for (let i = 0; i < this.fields.length; ++i)
        if (this.fields[i].value)
          nbAlarmRecipient += getNbFunctions[i]();
      return nbAlarmRecipient;
    },

    getNbAlarmInternalRecipient() {
      if (this.getBoolPtiValue(PTI_PARAM_SMS_PEC))
        return getCountRecipientTakenIntoAccount();
      return getCountRecipientAlarmCall() + getCountRecipientAlarmSms();
    },

    getNbAlarmTls() {
      return getCountRecipientTlsIpAlarm() + getCountRecipientTlsCall();
    },

    checkEachField() {
      const checkFunctions = [
        this.checkInternalRecipient, 
        this.checkTlsRecipient, 
        this.checkIpServer
      ];
      let nbError = this.getNbActiveFields();
      for (let i = 0; i < this.fields.length; ++i) {
        if (this.fields[i].value)
          nbError -= checkFunctions[i]();
        else
          this.resetErrorField(i);
      }
      return !nbError;
    },

    checkInternalRecipient() {
      const isRecipient = this.getBoolPtiValue(PTI_PARAM_SMS_CASCADE)
          || this.getBoolPtiValue(PTI_PARAM_SMS_GROUPE_CASCADE);
      const isRecipientTakenIntoAccount = this.getBoolPtiValue(PTI_PARAM_SMS_PEC);
      
      if ((isRecipient || this.isSipActivated) && !getCountRecipient()
          || isRecipientTakenIntoAccount && !getCountRecipientTakenIntoAccount())
        return this.setError(INTERNAL_RECIPIENTS, ERR_NO_RECIPIENT);

      if ((isRecipient || this.isSipActivated) && getCountRecipientWithSmsButNoEvent() > 0)
        return this.setError(INTERNAL_RECIPIENTS, ERR_RECIPIENT_WITH_SMS_BUT_NO_EVENTS_SELECTED);        
        
      return this.resetErrorField(INTERNAL_RECIPIENTS);
    },

    checkTlsRecipient() {
      if (!getCountRecipientTlsCall() && !getCountRecipientTlsIp())
        return this.setError(TLS_RECIPIENTS, ERR_NO_RECIPIENT_OR_SERVER);
      if (getCountRecipientTlsIpWithNoEvent() > 0)
        return this.setError(TLS_RECIPIENTS, ERR_SERV_WITH_NO_EVENTS_SELECTED);
      return this.resetErrorField(TLS_RECIPIENTS);
    },

    checkIpServer() {
      if (!getCountRecipientSupIp())
        return this.setError(IP_SERVER, ERR_NO_SERVER);
      if (getCountRecipientSupIpWithNoEvent() > 0)
        return this.setError(IP_SERVER, ERR_SERV_WITH_NO_EVENTS_SELECTED);
      return this.resetErrorField(IP_SERVER);
    },

    setError(index, errorMsg) {
      this.fields[index].errorMsg = errorMsg;
      return 0;
    },

    allFieldsAreEmpty() {
      for (let i = 0; i < this.fields.length; ++i)
        if (this.fields[i].value)
          return 0;
      return 1;
    },

    resetErrorField(i) {
      if (this.fields[i].errorMsg.length)
        this.fields[i].errorMsg = '';
      return 1;
    },

    resetAllErrorFields() {
      for (let i = 0; i < this.fields.length; ++i) 
        this.resetErrorField(i);
    },
  }

});
</script>
