<template>
  <!-- BEGIN: ButtonTargetModal component-->
  <a
    ref="aHtmlElement"
    href="javascript:;"
    data-toggle="modal"
    :data-target="targetId"
    :class="customClass"
  >
    {{ $t(text) }}
  </a>
  <!-- END: ButtonTargetModal component-->
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A colored button with a text that will trigger a tailwind modal when 
 * clicked by an user.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} targetId
 *    The id attribute of the tailwind modal HTML element to trigger,
 *    starting with '#' character (ex: target-id="#modal-confirm").
 * 
 * @param {String} text
 *    The text of the button. Should be i18 key for the locale 
 *    (ex: 'common.menu_save').
 * 
 * @param {String} customClass
 *    A custom class that can override the default class (a blue button). 
 *    Some examples:
 *      - yellow: 'btn btn-warning'
 *      - black: 'btn btn-dark'
 *      - blue: 'btn btn-primary'
 *      - white: 'btn btn-outline-secondary'
 *      - red: 'btn btn-danger' 
 * 
 * ---------------- METHODS ACCESSIBLE USING REFS -------------------
 * @method click
 * @param void
 *    Allows to trigger a click on the button from the outside.
 *    (ex: this.$refs.xxx.click()).
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  props: {
    targetId: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: 'btn btn-primary mt-6',
    }
  },

  methods: {
    click() {
      this.$refs.aHtmlElement.click()
    }
  }
})
</script>

