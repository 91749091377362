<template>
  <div>
    <Title 
      title="locationindoorwifi.menu_wifi_based_indoor_location_transparent"
      help-locale="menu_loc_indoor_wifi"
    />
    <div class="grid grid-cols-12 gap-6 mt-6">
      <WifiBeaconSettings
      ref="wifiBeaconSettings"
      />
      <AddWifiFilter 
        :ssid-filtered-list="ssidFilteredList"
        @add-beacon-filtered-success="addNewWifiFiltered"
      />
    </div>
      <WifiFilteredList 
        :ssid-filtered-list="ssidFilteredList"
        @remove-ssid-filtered="initModalConfirm"
      />
      <ModalAlertConfirmChoice
        ref="modalAlertConfirmChoice"
        modal-id="modal-alert-confirm"
        alert-title="common.menu_are_you_sure"
        :alert-text="modalAlertText"
        icon-component-name="AlertTriangleIcon"
        left-button-component-name="ButtonDismissModal"
        left-button-text="common.menu_cancel"
        left-button-custom-class="btn btn-outline-secondary mr-1"
        right-button-component-name="ButtonDismissModal"
        right-button-text="common.menu_remove"
        right-button-custom-class="btn btn-danger"
        @right-button="remove"
      />
    
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import AddWifiFilter from './local-components/lc-add-wifi-filter.vue'
import WifiFilteredList from './local-components/lc-wifi-filter-list.vue'
import WifiBeaconSettings from './local-components/lc-wifi-hotspot-settings.vue'

import {
  deleteTransparentAllWifiBeacons,
  deleteTransparentWifiBeacon,
  getTransparentWifiBeacons,
  addTransparentWifiBeacon,
} from '../../dao/transparent-indoor-wifi-dao'
import { createBeaconFromDbEntry } from './local-components/utils'

const ALERT_REMOVE_ONE_HOTSPOT = "locationindoorwifi.menu_remove_one_hotspot"
const ALERT_REMOVE_EVERYTHING = "locationindoorwifi.menu_remove_all_hotspots"

const REMOVE_ONE_ITEM = 0;
const REMOVE_EVERYTHING = 1;

const WIFI_ID = 0;
const SSID_ID = 1;

export default defineComponent({
  components: {
    AddWifiFilter,
    WifiFilteredList,
    WifiBeaconSettings
  },

  beforeRouteLeave (to, from, next) {
    this.$refs.wifiBeaconSettings.save();
    next();
    
   
	},

  data() {
    return {
      showImportExportButtons: false,
      removeType: undefined,
      ssidFilteredList: new Array(),
      currentIndex: 0,
    }
  },

  computed: {
    modalAlertText() {
      return this.removeType == REMOVE_ONE_ITEM ? 
          ALERT_REMOVE_ONE_HOTSPOT : ALERT_REMOVE_EVERYTHING;
    },
  },
  created() {
    const dbBeacons = getTransparentWifiBeacons();
    if (dbBeacons != undefined)
      for (let i = 0; i < dbBeacons.values.length; ++i)
        this.ssidFilteredList.push(createBeaconFromDbEntry(dbBeacons.values[i]));
  },

  methods: {
    addNewWifiFiltered(newHotspot) {
      this.ssidFilteredList.push(newHotspot);
      addTransparentWifiBeacon(newHotspot);
    },

    
    remove() {
      if (this.removeType === REMOVE_ONE_ITEM) {
        // Action pour supprimer 1 Wifi
        deleteTransparentWifiBeacon(this.ssidFilteredList[this.currentIndex][WIFI_ID]);
        this.ssidFilteredList.splice(this.currentIndex, 1)
      }
      else {
        // Action pour supprimer tous les Wifi
        deleteTransparentAllWifiBeacons();
        this.ssidFilteredList = new Array();
      }
    },

    initModalConfirm(index) {
      this.currentIndex = index == undefined ? this.currentIndex : index;
      this.removeType = index == undefined ? REMOVE_EVERYTHING : REMOVE_ONE_ITEM;
    },
  },
})
</script>