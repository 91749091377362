<template>
  <div>
    <div class="sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <LoginInfo :image-source="imageSource" />
        <div class="h-screen xl:h-auto flex  py-5 xl:py-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 "
          >
            <DeviceInfo />
            <div class=" flex items-center border rounded p-5  ">
              <div class="items-center">
                <CustomTextInput
                  v-model="deviceCred"
                  :title="passwordLabel"
                  :is-password-field="true"
                  :max-length="6"
                />
              </div>
            </div>
            <div class="flex flex-col items-center mt-5">
              <CustomButton
                text="common.menu_confirm"
                custom-class="w-full btn btn-primary"
                @click="onCredButtonClick"
              />
            </div>
          </div>
        </div>
      </div>
      <CustomToast
        ref="CustomToastInputError"
        toast-id="custom-toast-error"
        icon-component-name="XCircleIcon"
        icon-component-class="text-theme-6"
        text="password.menu_wrong_password"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { getCredValue, getDeployCredValue } from "../../dao/cred-dao";
import { useStore } from "@/store";
import {
  PATH_CREATE_PASSWORD,
  PATH_DEPLOY_LOGIN,
  PATH_MAIN_CONFIG,
  PATH_TARGET_DB
} from "../../utils/const_path";
import { STORE_UPDATE_LOGIN_STATUS } from "../../store/const_store";

const I18_DEPLOY_PASSWORD = "password.menu_enter_deployment_password";
const I18_PASSWORD = "password.menu_enter_password";

export default defineComponent({
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });
  },

  data() {
    return {
      deviceCred: "",
      passwordLabel: I18_PASSWORD,
      imageSource: undefined,
      admin_password_modify: undefined,
      admin_password_connect: undefined
    };
  },

  created() {
    this.imageSource = useStore().state.emeritValues.modelImageSource;
    if (
      useStore().state.emeritValues.disconnectionPath.valueOf() ===
      PATH_DEPLOY_LOGIN
    )
      this.passwordLabel = I18_DEPLOY_PASSWORD;
  },

  methods: {
    onCredButtonClick() {
      this.admin_password_modify = useStore().state.emeritValues.secrets.adminPasswordModify;
      this.admin_password_connect = useStore().state.emeritValues.secrets.adminPasswordConnect;

      const isDeployment =
        useStore().state.emeritValues.disconnectionPath === PATH_DEPLOY_LOGIN;
      if (
        this.deviceCred === this.admin_password_modify ||
        this.deviceCred === this.admin_password_connect ||
        (!isDeployment && this.deviceCred === getCredValue()) ||
        (isDeployment && this.deviceCred === getDeployCredValue())
      ) {
        let pathToRoute;
        if (this.deviceCred === this.admin_password_modify)
          pathToRoute = PATH_CREATE_PASSWORD;
        else if (isDeployment) pathToRoute = PATH_TARGET_DB;
        else pathToRoute = PATH_MAIN_CONFIG;
        this.$store.commit(STORE_UPDATE_LOGIN_STATUS, true);
        this.$router.push(pathToRoute);
      } else {
        this.$refs.CustomToastInputError.trigger();
      }
    }
  }
});
</script>
