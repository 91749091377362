import { useStore } from "@/store";

const SSID = 0;
const BSSID = 1;
const CODE_ZONE = 2;
const DESC = 3;
const LEVEL = 4;

export const getWifiBeacons = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "select * from wifiBeacons";
  try {
    return dbFile.exec(sqlScript)[0];
  } catch (error) {
    console.log('getWifiBeacons failed: ' + error);
  }
};

export const addWifiBeacon = hotspot => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'INSERT INTO  wifiBeacons (ssid,bssid,code_zone,description,last_known_timestamp,level)  VALUES("' +
    hotspot[SSID] +
    '","' +
    hotspot[BSSID] +
    '","' +
    hotspot[CODE_ZONE] +
    '","' +
    hotspot[DESC] +
    '","' +
    "-1" +
    '","' +
    hotspot[LEVEL] +
    '")';

  try {
    return dbFile.exec(sqlScript);
  } catch (error) {
    console.log("addWifiBeacon failed: " + error);
  }
};

export const deleteAllWifiBeacons = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "DELETE FROM wifiBeacons";
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("deleteWifiBeacons failed: " + error);
  }
};

export const deleteWifiBeacon = bssid => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = 'DELETE FROM wifiBeacons WHERE bssid="' + bssid + '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("deleteWifiBeacon failed: " + error);
  }
};

export const getWifiBeaconsCount = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT COUNT(*) FROM wifiBeacons";
  try {
    return dbFile.exec(sqlScript)[0].values[0];
  } catch (error) {
    console.log("getWifiBeaconsCount failed: " + error);
  }
};

export const getWifiBeaconId = bssid => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = 'SELECT _id FROM wifiBeacons WHERE bssid="' + bssid + '" COLLATE NOCASE';
  try {
    return dbFile.exec(sqlScript)[0].values;
  } catch (error) {
    console.log("getWifiBeaconId failed: " + error);
  }
};

export const updateWifiBeacon = hotspotModified => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'UPDATE wifiBeacons SET ssid="' +
    hotspotModified.newHotspot[SSID] +
    '", bssid="' +
    hotspotModified.newHotspot[BSSID] +
    '", code_zone="' +
    hotspotModified.newHotspot[CODE_ZONE] +
    '", description="' +
    hotspotModified.newHotspot[DESC] +
    '", level="' +
    hotspotModified.newHotspot[LEVEL] +
    '" WHERE _id=' +
    hotspotModified.id;
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("updateWifiBeacon failed: " + error);
  }
};
