<template>
  <!-- BEGIN: ModifyLocExInputFields local component-->
  <div class="mt-3">
    <Tippy
      v-for="(field, index) in getLocExFields"
      :key="index"
      :ref="field.title"
      :content="$t(field.tippy)"
      :options="{
        theme: 'light',
        delay: index,
        onShow: updateTippyContent,
      }"
    >
      <CustomTextInput
        v-model="field.value"
        class="mb-3"
        :title="field.title"
        :max-length="field.len"
        :error-msg="field.errorMsg"
      />
    </Tippy>
  </div>
  <!-- END: ModifyLocExInputFields local component-->
</template>

<script>
import { defineComponent } from 'vue'
import {
  checkLevel,
  checkCodeZone,
  beaconModifiedAlreadyExisting,
  getModel,
  checkMacAddress,
  LOC_EX_UUID,
  MODEL_LOC_EX,
} from './utils'
import {
  resetError,
  resetAllFields,
  resetAllError,
  checkFieldIsNotEmpty,
} from '../../../utils/fieldManagement'


const TABLE_INDEX_COMPANY_ID = 0;
const TABLE_INDEX_BEACON_ID = 1;

const BEACON_ID = 0;
const CODE_ZONE = 1;
const DESC = 2;
const LEVEL = 3;

const MAC_ADDRESS_LEN = 17;

const ERR_LOC_EX_ALREADY_EXIST = "locationindoorble.menu_err_loc_ex_already_exist";
const ERR_LOC_EX_BEAC_ID = "locationindoorble.menu_err_loc_ex_beacon_id";
const ERR_CODE_ZONE = "locationindoorble.menu_err_wrong_code_zone";
const ERR_DESC = "locationindoorble.menu_err_empty_desc";
const ERR_LEVEL = "locationindoorble.menu_err_wrong_level";

const BEAC_ID_TITLE = "locationindoorble.menu_beacon_id";
const CODE_ZONE_TITLE = "locationindoorble.menu_beacon_code_zone";
const DESC_TITLE = "locationindoorble.menu_beacon_description";
const LEVEL_TITLE = "locationindoorble.menu_beacon_level";

const LOC_EX_MAC_ADDRESS_TIPPY = "locationindoorble.menu_tippy_loc_ex_mac_address";
const CODE_ZONE_TIPPY = "locationindoorble.menu_tippy_code_zone";
const DESC_TIPPY = "locationindoorble.menu_tippy_description";
const LEVEL_TIPPY = "locationindoorble.menu_tippy_level";

const EV_MODIFY_LOC_EX_SUCCESS = "modifyLocExSuccess";


export default defineComponent({

  props: {
    hideLevelField: {
      type: Boolean,
      required: true,
    },
    beaconList: {
      type: Array,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    nbTimesShow: {
      type: Number,
      required: true,
    }
  },

  emits: [EV_MODIFY_LOC_EX_SUCCESS],

  data() {
    return {
      locExFields: [
        { title: BEAC_ID_TITLE, tippy: LOC_EX_MAC_ADDRESS_TIPPY, value: '', len: MAC_ADDRESS_LEN, errorMsg: ''},
        { title: CODE_ZONE_TITLE, tippy: CODE_ZONE_TIPPY, value: '', len: 3, errorMsg: ''},
        { title: DESC_TITLE, tippy: DESC_TIPPY, value: '', len: 50, errorMsg: ''},
        { title: LEVEL_TITLE, tippy: LEVEL_TIPPY, value: '', len: 3, errorMsg: ''},
      ],
    }
  },

  computed: {
    getLocExFields() {
      const len = this.hideLevelField ? this.locExFields.length - 1 : this.locExFields.length;
      let newLocExFields = new Array(len);
      for (let i = 0, j = 0; i < this.locExFields.length; ++i, ++j) {
        if (this.hideLevelField && i === LEVEL)
          --j
        else 
          newLocExFields[j] = this.locExFields[i];
      }
      return newLocExFields;
    },
  },

  watch: {
    hideLevelField() {
      this.locExFields[LEVEL].value = '';
      resetError(this.locExFields, LEVEL);
    },

    nbTimesShow: {
      immediate: true,
      handler: function() {
        if (!this.beaconList.length)
          return ;
        const beaconToModify = this.beaconList[this.currentIndex];
        resetAllError(this.locExFields);
        const startIndex = TABLE_INDEX_BEACON_ID;
        const model = getModel(beaconToModify[TABLE_INDEX_COMPANY_ID]);
        const isModelSelectedMatchingInputFieldsType = model.valueOf() === MODEL_LOC_EX ? true : false;
        for (let i = 0; i < this.locExFields.length; ++i) {
          if (!isModelSelectedMatchingInputFieldsType && (i === BEACON_ID)) {
            this.locExFields[i].value = '';
            continue ;  
          }
          this.locExFields[i].value = beaconToModify[startIndex + i];
        }
      }
    },
  },

  methods: {

    updateTippyContent(instance) {
      const index = instance.props.delay;
      instance.setContent(this.$t(this.getLocExFields[index].tippy));
    },

    check() {
      let error = this.locExFields.length;
      error -= checkMacAddress(this.locExFields, BEACON_ID, ERR_LOC_EX_BEAC_ID);
      error -= checkLevel(this.hideLevelField, this.locExFields, LEVEL, ERR_LEVEL);
      error -= checkCodeZone(this.locExFields, CODE_ZONE, ERR_CODE_ZONE);
      error -= checkFieldIsNotEmpty(this.locExFields, DESC, ERR_DESC);
      if (!error && !beaconModifiedAlreadyExisting(this.currentIndex, this.beaconList, LOC_EX_UUID, this.locExFields[BEACON_ID].value.valueOf(), this.locExFields, ERR_LOC_EX_ALREADY_EXIST)) {
        this.$emit(EV_MODIFY_LOC_EX_SUCCESS, this.createNewBeacon());
        resetAllFields(this.locExFields);
      }
    },

    createNewBeacon() {
      return [
        LOC_EX_UUID,
        this.locExFields[BEACON_ID].value.toUpperCase(),
        this.locExFields[CODE_ZONE].value,
        this.locExFields[DESC].value,
        this.locExFields[LEVEL].value,
      ]
    },

  }
})
</script>
