<template>
  <!-- BEGIN: DeviceInfo component-->
  <div class="flex items-center pb-3">
    <div class="w-16 h-16 flex-none relative items-center">
      <img
        :src="imageSource"
      >
    </div>
    <div class=" align-left pl-3 font-sm w-48">
      <p 
        v-for="(line, index) in deviceTextLines"
        :key="index"
        class="text-sm font-light text-black"
      >
        {{ line }}<br>
      </p>
    </div>
    <div class="flex flex-col mt-3">
      <button
        class="dropdown-toggle btn btn-warning "
        @click="logout"
      >
        <LogOutIcon />
      </button>
      <CustomButton
        v-if="getHelpLocale"
        ref="helpButton"
        v-shine="{ event: 'load', time: 1, delay:0.5, opacity: 0, ease: 'linear', blur: 1 }"
        icon-component-name="HelpCircleIcon"
        custom-class="dropdown-toggle btn btn-warning mt-3"
        @click="openLink($t(deviceStr + 'help.' + getHelpLocale))"
      />
    </div>
  </div>
  <!-- END: DeviceInfo component-->
</template>

<script>
import { defineComponent } from 'vue'
import { useStore } from "@/store";
import { getParameterPTIValue } from "../../../dao/parametre-pti";
import { getOptionsValue } from "../../../dao/options-dao";
import { 
  PATH_MODEL_LOGIN,
  PATH_DEPLOY_LOGIN,
  PATH_PASSWORD,
  PATH_CREATE_PASSWORD,
  PATH_TARGET_DB,
  PATH_MAIN_CONFIG,
} from "../../../utils/const_path";


const IMEI = "Imei";
const SN = "SerialNumber";

const RG170 = "RG170";
const RG170_IS170 = "RG170 / IS170";
const IS530 = "IS530";
const RG530_IS530 = "RG530 / IS530";

const I18_CREATING_MODEL = "login.menu_configuration_model_creation";
const I18_MODIFYING_MODEL = "login.menu_configuration_model";

const PARAM_PTI_IDENTIFIANT = "Identifiant";

const HELP_LOCALES_NORMAL_LOGIN = [
  [PATH_PASSWORD, 'menu_enter_password_normal_login'],
  [PATH_CREATE_PASSWORD, 'menu_create_password_normal_login'],
  [PATH_MAIN_CONFIG, 'menu_main_page'],
]

const HELP_LOCALES_MODEL_LOGIN = [
  [PATH_PASSWORD, 'menu_enter_template_password'],
  [PATH_CREATE_PASSWORD, 'menu_create_template_password'],
  [PATH_MAIN_CONFIG, 'menu_main_page'],
]

const HELP_LOCALES_DEPLOY_LOGIN = [
  [PATH_PASSWORD, 'menu_enter_deploy_password'],
  [PATH_MAIN_CONFIG, 'menu_main_page'],
  [PATH_TARGET_DB, 'menu_add_personal_info'],
]

const JS_EV_LOAD = 'load';


/**
 * -------------------------- DESCRIPTION ---------------------------
 * Device information like picture, SN, IMEI, associated with an help
 * button and a logout button. 
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({

  data() {
    return {
      deviceStr: useStore().state.emeritValues.deviceStr,
      deviceTextLines: [],
      modelTitle: '',
      deviceModelName: '',
      deviceIMEI: '',
      deviceSerialNumber: '',
      imageSource: undefined,
      notYetMounted: true,
    }
  },

  computed: {

     getHelpLocale() {
      let helpLocales = this.initHelpLocales();
      for (let i = 0; i < helpLocales.length; ++i)
        if (helpLocales[i][0] === this.$route.path)
          return helpLocales[i][1];
      return null;
    }
  },

  watch: {
    '$route.path': {
      handler: function triggerVShine() {
        if (this.$refs.helpButton && !this.notYetMounted)
            this.$refs.helpButton.triggerEvent(JS_EV_LOAD);
      }
    }
  },

  created() {
    this.deviceIMEI = getOptionsValue(IMEI);
    this.deviceSerialNumber = getOptionsValue(SN);
    this.imageSource= useStore().state.emeritValues.smallModelImageSource;
    this.initDeviceModelName();
    this.initModelTitle();
    this.initDeviceTextLines();
 },

  mounted() {
    this.$refs.helpButton.triggerEvent(JS_EV_LOAD);
    this.notYetMounted = false;
  },

  methods: {

    initHelpLocales() {
      const disconnectionPath = useStore().state.emeritValues.disconnectionPath;
      if (disconnectionPath === PATH_MODEL_LOGIN)
        return HELP_LOCALES_MODEL_LOGIN;
      else if (disconnectionPath === PATH_DEPLOY_LOGIN)
        return HELP_LOCALES_DEPLOY_LOGIN
      else
        return HELP_LOCALES_NORMAL_LOGIN;
    },

    initDeviceTextLines() {
      const disconnectionPath = useStore().state.emeritValues.disconnectionPath;
      const id = getParameterPTIValue(PARAM_PTI_IDENTIFIANT)
      this.deviceTextLines.push(this.deviceModelName);
      if (disconnectionPath.valueOf() === PATH_MODEL_LOGIN) {
        this.deviceTextLines.push(this.modelTitle);
      }
      else if (disconnectionPath.valueOf() === PATH_DEPLOY_LOGIN) {
        this.deviceTextLines.push(this.modelTitle);
        this.deviceTextLines.push(id);
      }
      else {
        this.deviceTextLines.push(this.deviceIMEI);
        this.deviceTextLines.push(this.deviceSerialNumber);
        if (this.$route.path === PATH_PASSWORD 
            || this.$route.path === PATH_CREATE_PASSWORD) {
          this.deviceTextLines.push(id);
        }
      }
      return this.deviceTextLines;
    },

    initDeviceModelName() {
      this.deviceModelName = useStore().state.emeritValues.deviceInfo.deviceModelName;
      if (this.deviceModelName == RG170)
        this.deviceModelName = RG170_IS170;
      else if (this.deviceModelName == IS530)
        this.deviceModelName = RG530_IS530;
    },

    initModelTitle() {
      if (getParameterPTIValue(PARAM_PTI_IDENTIFIANT) === "")
        this.modelTitle = this.$t(I18_CREATING_MODEL);
      else
        this.modelTitle = this.$t(I18_MODIFYING_MODEL);
    },

    openLink(link) {
      window.open(link);
    },
    
    logout() {
      const disconnectionPath = useStore().state.emeritValues.disconnectionPath;
      this.$router.push(disconnectionPath);
    },
  }
  
})
</script>
