<template>
  <!-- BEGIN: ListElementNotGreen component -->
  <tr>
    <td 
      v-for="(elem, index) in elemsTextFilledWithError"
      :key="index"
      class="font-medium whitespace-nowrap" 
    > 
      {{ elem }}
    </td>
    <td 
      v-if="icons" 
      class="flex flex-row-reverse"
    >
      <component 
        :is="icon"
        v-for="(icon, index) in icons"
        :key="index"
        class="mr-3"
        style="box-shadow: 0px 0px white;"
      />
    </td>
    <td class="table-report__action w-24">
      <a
        class="flex items-center text-theme-6"
        href="javascript:;"
        data-toggle="modal"
        :data-target="redButtonBootstrapTarget"
        @click="$emit('redButtonClicked')"
      >
        <Trash2Icon 
          class="w-4 h-4 mr-1" 
        />
        {{ $t(redButtonText) }}
      </a>
    </td>
  </tr>
  <!-- END: ListElement component -->
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A list element for a table, with several text field prompted to the user,
 * marking optionally empty fields with a red 'X', and a red button that
 * can launch an action using bootstrap attribute data-target, like
 * removing the element for example.
 *
 * ----------------------------- PROPS ------------------------------
 * @param {Array[X String]} elemsText
 *    The different elements filling a line of the table. If props
 *    showingErrors is set to true, the last element of this array will be
 *    interpreted as an error msg, meaning printed in red if not empty.
 *
 * @param {Array[X String]} icons
 *    An array of Strings containing name of dynamic icons components. Icons
 *    will be rendered at the right of elemsText fields description. Undefined
 *    by default, if you do not need icons do not fill this props.
 *
 * @param {Boolean} showingErrors
 *    If sets to true, every empty fields in elementsText will be marked with
 *    red 'X'. If sets to false, emtpy fields will remain empty.
 *
 * @param {String} redButtonText
 *    The text prompted on the red button. Should be key for i18 locale.
 *
 * @param {String} redButtonBootstrapTarget
 *    The id of an HTML element that will be targeted by bootstrap when
 *    the user will click on the red button.
 *
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits redButtonClicked
 *    Emits an empty event when element's red button is clicked.
 *
 * ------------------------------------------------------------------
 */

const RED_COLOR = 'text-theme-6'

export default defineComponent({
  props: {
    elemsText: {
      type: Array,
      required: true,
    },
    icons: {
      type: Array,
      default: undefined,
    },
    showingErrors: {
      type: Boolean,
      required: true,
    },
    redButtonText: {
      type: String,
      required: true,
    },
    redButtonBootstrapTarget: {
      type: String,
      required: true,
    },
  },

  emits: ['redButtonClicked'],

  computed: {
    elemsTextFilledWithError() {
      if (!this.showingErrors) return this.elemsText
      const elemsWithErrors = [...this.elemsText]
      const lastIndex = elemsWithErrors.length - 1
      if (elemsWithErrors[lastIndex] === '') elemsWithErrors[lastIndex] = 'X'
      return elemsWithErrors
    },
  },
})
</script>

