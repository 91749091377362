export const createRecipientFromDb = dbLine => {
  let i = 0;
  return {
    id: dbLine[i++],
    name: dbLine[i++],
    number: dbLine[i++],
    pCall: Boolean(dbLine[i++]),
    pSms: Boolean(dbLine[i++]),
    pAlarm: Boolean(dbLine[i++]),
    pLowBattery: Boolean(dbLine[i++]),
    pPowerOnOff: Boolean(dbLine[i++]),
    pChargeStation: Boolean(dbLine[i++]),
    pLoc: Boolean(dbLine[i++]),
    sortId: dbLine[i++],
    pPostAlarm: Boolean(dbLine[i++]),
    pPti: Boolean(dbLine[i++]),
    pWatch: Boolean(dbLine[i++]),
    sipAccountId: dbLine[i++],
    sipServerUrl: dbLine[i++],
    isSipRecipient: Boolean(dbLine[i++]),
    pBleBeaconLowBattery: Boolean(dbLine[i++]),
  }
}

export const createRecipientFromDbTakenInAccount = dbLine => {
  let i = 0;
  return {
    id: dbLine[i++],
    name: dbLine[i++],
    number: dbLine[i++],
    pCall: true,
    pSms: true,
    pAlarm: true,
    pLowBattery: Boolean(dbLine[i++]),
    pPowerOnOff: Boolean(dbLine[i++]),
    pChargeStation: Boolean(dbLine[i++]),
    pLoc: Boolean(dbLine[i++]),
    sortId: dbLine[i++],
    pPostAlarm: Boolean(dbLine[i++]),
    pPti: Boolean(dbLine[i++]),
    pWatch: false,
    sipAccountId: '',
    sipServerUrl: '',
    isSipRecipient: false,
    pBleBeaconLowBattery: Boolean(dbLine[i++]),
  }
}
