import { useStore } from "@/store";

export const getCredValue = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = 'select * from password where default_pwd = "1122"';
  try {
    return dbFile.exec(sqlScript)[0].values[0][2];
  } catch (error) {
    console.log('getCredValue failed: ' + error);
  }
};

export const getDeployCredValue = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = 'select * from password where default_pwd = "deploy"';
  try {
    return dbFile.exec(sqlScript)[0].values[0][2];
  } catch (error) {
    console.log('getDeployCredValue failed: ' + error);
  }
};

export const getTargetDeviceDeployCredValue = () => {
  var dbFile = useStore().state.emeritValues.targetDbFile;
  let sqlScript = 'select * from password where default_pwd = "deploy"';
  try {
    return dbFile.exec(sqlScript)[0].values[0][2];
  } catch (error) {
    console.log('getTargetDeviceDeployCredValue failed: ' + error);
  }
};

export const getTargetDeviceCredValue = () => {
  var dbFile = useStore().state.emeritValues.targetDbFile;
  let sqlScript = 'select * from password where default_pwd = "1122"';
  try {
    return dbFile.exec(sqlScript)[0].values[0][2];
  } catch (error) {
    console.log('getTargetDeviceCredValue failed: ' + error);
  }
};

export const setCredValue = value => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'update password set pwd = "' + value + '" where default_pwd = "1122"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('setCredValue failed: ' + error);
  }
};

export const setDeployCredValue = value => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'update password set pwd = "' + value + '" where default_pwd = "deploy"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('setDeployCredValue failed: ' + error);
  }
};

export const setTargetDeviceCredValue = value => {
  var dbFile = useStore().state.emeritValues.targetDbFile;
  let sqlScript =
    'update password set pwd = "' + value + '" where default_pwd = "1122"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("setTargetDeviceCredValue failed: " + error);
  }
};
