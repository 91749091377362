import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "@/store";
import SideMenu from "../layouts/side-menu/Main.vue";
import EmeritLogin from "../views/emerit-login/emerit-login.vue";
import ErrorPage from "../views/error-page.vue";
import EmeritMainConfig from "../views/emerit-main-config/emerit-main-config.vue";
import EmeritWearingStyle from "../views/emerit-wearing-style/emerit-wearing-style.vue";
import EmeritAlerts from "../views/emerit-alerts/emerit-alerts.vue";
import EmeritWristAlert from "../views/emerit-wrist-alert/emerit-wrist-alert.vue"
import EmeritLocationIndoorWifi from "../views/emerit-location-indoor-wifi/emerit-location-indoor-wifi.vue";
import EmeritLocationIndoorWifiTransparent from "../views/emerit-location-indoor-wifi-transparent/emerit-location-indoor-wifi-transparent.vue";
import EmeritLocationIndoorBle from "../views/emerit-location-indoor-ble/emerit-location-indoor-ble.vue";
import EmeritLocationIndoorBleTransparent from "../views/emerit-location-indoor-ble-transparent/emerit-location-indoor-ble-transparent.vue";
import EmeritLocationOutdoor from "../views/emerit-location-outdoor/emerit-location-outdoor.vue";
import EmeritContact from "../views/emerit-contact/emerit-contact.vue";
import EmeritInternalRecipientsSettings from "../views/emerit-internal-recipients-settings/emerit-internal-recipients-settings.vue";
import EmeritInternalRecipients from "../views/emerit-internal-recipients/emerit-internal-recipients.vue";
import EmeritSipConfiguration from "../views/emerit-sip-configuration/emerit-sip-configuration.vue";
import EmeritTlsRecipients from "../views/emerit-tls-recipient/emerit-tls-recipients.vue";
import EmeritIpserver from "../views/emerit-ip-server/emerit-ip-server.vue";
import EmeritIpserverSettings from "../views/emerit-ip-server-settings/emerit-ip-server-settings.vue";
import EmeritPassword from "../views/emerit-password/emerit-password.vue";
import EmeritCreateModifyPassword from "../views/emerit-create-modify-password/emerit-create-modify-password.vue";
import EmeritTargetDeviceDb from "../views/emerit-target-device-db/emerit-target-device-db.vue";
import EmeritWifi from "../views/emerit-wifi/emerit-wifi.vue";
import EmeritWhitelist from "../views/emerit-whitelist/emerit-whitelist.vue";
import EmeritModelLogin from "../views/emerit-model-login/emerit-model-login.vue";
import EmeritDeployLogin from "../views/emerit-deploy-login/emerit-deploy-login.vue";
import EmeritCustomScreens from "../views/emerit-custom-screens/emerit-custom-screens.vue"
import EmeritCustomButtonOnOff from "../views/emerit-custom-button-on-off/emerit-custom-button-on-off.vue"
import { isLoginPaths } from "../utils/store_utils";
import { STORE_RESET_EMERIT_VALUES } from "../store/const_store";

const routes = [
  {
    path: "/",
    component: SideMenu,
    meta: { auth: true },
    children: [
      {
        path: "/",
        name: "side-menu-emerit-main-config",
        component: EmeritMainConfig
      },
      {
        path: "custom-button-on-off",
        name: "emerit-custom-button-on-off",
        component: EmeritCustomButtonOnOff
      },
      {
        path: "contact",
        name: "side-menu-emerit-contact",
        component: EmeritContact
      },
      {
        path: "wifi",
        name: "emerit-wifi",
        component: EmeritWifi
      },
      {
        path: "wearing-style",
        name: "emerit-wearing-style",
        component: EmeritWearingStyle,
        props: true
      },
      {
        path: "alerts",
        name: "emerit-alerts",
        component: EmeritAlerts,
        props: true
      },
      {
        path: "wrist-alert",
        name: "emerit-wrist-alert",
        component: EmeritWristAlert,
      },
      {
        path: "location-outdoor",
        name: "emerit-location-outdoor",
        component: EmeritLocationOutdoor,
      },
      {
        path: "location-indoor-ble",
        name: "emerit-location-indoor-ble",
        component: EmeritLocationIndoorBle,
      },
      {
        path: "location-indoor-ble-transparent",
        name: "emerit-location-indoor-ble-transparent",
        component: EmeritLocationIndoorBleTransparent,
      },
      {
        path: "location-indoor-wifi",
        name: "emerit-location-indoor-wifi",
        component: EmeritLocationIndoorWifi,
      },
      {
        path: "location-indoor-wifi-transparent",
        name: "emerit-location-indoor-wifi-transparent",
        component: EmeritLocationIndoorWifiTransparent,
      },
      {
        path: "internal-recipients-settings",
        name: "emerit-internal-recipients-settings",
        component: EmeritInternalRecipientsSettings
      },
      {
        path: 'sip-configuration',
        name: 'emerit-sip-configuration',
        component: EmeritSipConfiguration
      },
      {
        path: 'whitelist',
        name: 'emerit-whitelist',
        component: EmeritWhitelist
      },
      {
        path: "internal-recipients",
        name: "emerit-internal-recipients",
        component: EmeritInternalRecipients
      },
      {
        path: "tls-recipients",
        name: "emerit-tls-recipients",
        component: EmeritTlsRecipients
      },
      {
        path: "ip-server",
        name: "emerit-ip-server",
        component: EmeritIpserver,
        props: true
      },
      {
        path: "ip-server-settings",
        name: "emerit-ip-server-settings",
        component: EmeritIpserverSettings,
        props: true
      },
      {
        path: "custom-screens",
        name: "emerit-custom-screens",
        component: EmeritCustomScreens,
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: EmeritLogin,
    meta: { auth: false },
    props: true
  },
  {
    path: "/model-login",
    name: "model-login",
    component: EmeritModelLogin,
    meta: { auth: false },
    props: true
  },
  {
    path: "/deploy-login",
    name: "deploy-login",
    component: EmeritDeployLogin,
    meta: { auth: false },
    props: true
  },
  {
    path: "/password",
    name: "password",
    component: EmeritPassword,
    meta: { auth: false },
    props: true
  },
  {
    path: "/create-modify-password",
    name: "create-modify-password",
    component: EmeritCreateModifyPassword,
    meta: { auth: false },
    props: true
  },
  {
    path: "/target-db",
    name: "emerit-target-device-db",
    component: EmeritTargetDeviceDb,
    meta: { auth: false },
    props: true
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage,
    meta: { auth: false }
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
    meta: { auth: false }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  let isAuthNeeded = to.meta.auth;
  let isUserAuthenticated = useStore().state.emeritValues.isUserAuthenticated;

  if (isLoginPaths(to.path))
    useStore().commit(STORE_RESET_EMERIT_VALUES);
  if (isAuthNeeded && !isUserAuthenticated) {
    next("login");
  } else {
    next();
  }
});

export default router;
