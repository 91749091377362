<template>
  <!-- BEGIN: NotifDeviceUpdated component -->
  <div
    v-if="notifState != 0"
    :class="getNotifClass"
  >
    <div class="flex-none mr-2">
      <AlertTriangleIcon class="w-6 h-6" />
    </div>
    <div class="flex-1">
      {{ getNotifText }}
      <br>
      {{ getNotifText2 }}
    </div>
    <button 
      type="button"
      class="flex-none pl-2"
      @click="$emit('hide-notif')" 
    >
      <XIcon 
        class="w-4 h-4"
      />
    </button>
  </div>
  <!-- END: NotifDeviceUpdated component -->
</template>

<script>
import { defineComponent } from 'vue'


const CLASS_NOTIF_SUCCESS = "p-3 rounded-md alert-success alert-dismissible show flex items-center mb-6 font-medium";
const CLASS_NOTIF_FAILED = "p-3 rounded-md alert-danger show flex items-center mb-6 font-medium";

const I18_NOTIF_UPDATE_SUCCESS = "configuration.menu_configuration_update_has_succeeded"
const I18_NOTIF_UPDATE_SUCCESS_2 = "configuration.menu_device_must_be_restarted"
const I18_NOTIF_UPDATE_FAIL = "configuration.menu_configuration_update_has_failed"
const I18_NOTIF_UPDATE_FAIL_2 = "configuration.menu_check_internet_connection_and_retry"

const NOTIF_STATE_SUCCESS = 1;


export default defineComponent({

  props: {
    notifState: {
      type: Number,
      required: true,
    }
  },

  emits: ['hide-notif'],

  computed: {
    getNotifText() {
      if (this.notifState === NOTIF_STATE_SUCCESS)
        return this.$t(I18_NOTIF_UPDATE_SUCCESS);
      return this.$t(I18_NOTIF_UPDATE_FAIL);
    },

    getNotifText2() {
      if (this.notifState === NOTIF_STATE_SUCCESS)
        return this.$t(I18_NOTIF_UPDATE_SUCCESS_2);
      return this.$t(I18_NOTIF_UPDATE_FAIL_2);
    },

    getNotifClass() {
      if (this.notifState === NOTIF_STATE_SUCCESS)
        return CLASS_NOTIF_SUCCESS;
      return CLASS_NOTIF_FAILED
    },
  }
})
</script>
