<template>
  <!-- BEGIN: BoldText component-->
  <div 
    class="font-bold"
    :class="color"
  >
    {{ $t(text) }}
  </div>
  <!-- END: BoldText component-->
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A bold text.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} text
 *    The text prompted to the user. Should be i18 key for the locale 
 *    (ex: 'common.menu_save').
 * 
 * @param {String} color   
 *    The class representing the color of the text. Optional props,
 *    Black by default.
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'text-theme-0',
    }
  }
  
})
</script>
