<template>
  <!-- BEGIN: ModifyEddystoneInputFields local component-->
  <div class="mt-3">
    <Tippy
      v-for="(field, index) in getEddystoneFields"
      :key="index"
      :ref="field.title"
      :content="$t(field.tippy)"
      :options="{
        theme: 'light',
        delay: index,
        onShow: updateTippyContent,
      }"
    >
      <CustomTextInput
        v-model="field.value"
        class="mb-3"
        :title="field.title"
        :max-length="field.len"
        :error-msg="field.errorMsg"
      />
    </Tippy>
  </div>
  <!-- END: ModifyEddystoneInputFields local component-->
</template>

<script>
import { defineComponent } from 'vue'
import {
  checkLevel,
  checkCodeZone,
  beaconModifiedAlreadyExisting,
  getModel,
} from './utils'
import {
  setError,
  resetError,
  resetAllFields,
  resetAllError,
  checkFieldIsNotEmpty,
  regexCheck,
} from '../../../utils/fieldManagement'


const COMPANY_ID = 0;
const BEACON_ID = 1;
const CODE_ZONE = 2;
const DESC = 3;
const LEVEL = 4;

const NAMESPACE_ID_LEN = 20;
const INSTANCE_ID_LEN = 12;
const MODEL_EDDYSTONE = "Eddystone";

const ERR_EDDY_ALREADY_EXIST = "locationindoorble.menu_err_eddy_already_exist";
const ERR_EDDY_COMP_ID = "locationindoorble.menu_err_eddystone_company_id";
const ERR_EDDY_BEAC_ID = "locationindoorble.menu_err_eddystone_beacon_id";
const ERR_CODE_ZONE = "locationindoorble.menu_err_wrong_code_zone";
const ERR_DESC = "locationindoorble.menu_err_empty_desc";
const ERR_LEVEL = "locationindoorble.menu_err_wrong_level";

const COMP_ID_TITLE = "locationindoorble.menu_beacon_company_id";
const BEAC_ID_TITLE = "locationindoorble.menu_beacon_id";
const CODE_ZONE_TITLE = "locationindoorble.menu_beacon_code_zone";
const DESC_TITLE = "locationindoorble.menu_beacon_description";
const LEVEL_TITLE = "locationindoorble.menu_beacon_level";

const NAMESPACE_ID_TIPPY = "locationindoorble.menu_tippy_namespace_id";
const INSTANCE_ID_TIPPY = "locationindoorble.menu_tippy_instance_id";
const CODE_ZONE_TIPPY = "locationindoorble.menu_tippy_code_zone";
const DESC_TIPPY = "locationindoorble.menu_tippy_description";
const LEVEL_TIPPY = "locationindoorble.menu_tippy_level";


export default defineComponent({

  props: {
    hideLevelField: {
      type: Boolean,
      required: true,
    },
    beaconList: {
      type: Array,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    nbTimesShow: {
      type: Number,
      required: true,
    },
  },

  emits: ['modifyEddystoneSuccess'],

  data() {
    return {
      eddystoneFields: [
        { title: COMP_ID_TITLE, tippy: NAMESPACE_ID_TIPPY, value: '', len: NAMESPACE_ID_LEN, errorMsg: ''},
        { title: BEAC_ID_TITLE, tippy: INSTANCE_ID_TIPPY, value: '', len: INSTANCE_ID_LEN, errorMsg: ''},
        { title: CODE_ZONE_TITLE, tippy: CODE_ZONE_TIPPY, value: '', len: 3, errorMsg: ''},
        { title: DESC_TITLE, tippy: DESC_TIPPY, value: '', len: 50, errorMsg: ''},
        { title: LEVEL_TITLE, tippy: LEVEL_TIPPY, value: '', len: 3, errorMsg: ''},
      ],
    }
  },

  computed: {
    getEddystoneFields() {
      const len = this.hideLevelField ? this.eddystoneFields.length - 1 : this.eddystoneFields.length;
      let newEddystoneFields = new Array(len);
      for (let i = 0, j = 0; i < this.eddystoneFields.length; ++i, ++j) {
        if (this.hideLevelField && i === LEVEL)
          --j
        else 
          newEddystoneFields[j] = this.eddystoneFields[i];
      }
      return newEddystoneFields;
    },
  },

  watch: {
    hideLevelField() {
      this.eddystoneFields[LEVEL].value = '';
      resetError(this.eddystoneFields, LEVEL);
    },

    nbTimesShow: {
      immediate: true,
      handler: function() {
        if (!this.beaconList.length)
          return ;
        const beaconToModify = this.beaconList[this.currentIndex];
        resetAllError(this.eddystoneFields);
        const model = getModel(beaconToModify[COMPANY_ID]);
        const isModelSelectedMatchingInputFieldsType = model.valueOf() === MODEL_EDDYSTONE ? true : false;
        for (let i = 0; i < this.eddystoneFields.length; ++i) {
          if (!isModelSelectedMatchingInputFieldsType && (i === COMPANY_ID || i === BEACON_ID)) {
            this.eddystoneFields[i].value = '';
            continue ;  
          }
          this.eddystoneFields[i].value = beaconToModify[i];
        }
      },
    }
  },

  methods: {
    
    updateTippyContent(instance) {
      const index = instance.props.delay;
      instance.setContent(this.$t(this.getEddystoneFields[index].tippy));
    },

    check() {
      let error = this.eddystoneFields.length;
      error -= this.checkEddystoneCompanyId();
      error -= this.checkEddystoneBeaconId();
      error -= checkLevel(this.hideLevelField, this.eddystoneFields, LEVEL, ERR_LEVEL);
      error -= checkCodeZone(this.eddystoneFields, CODE_ZONE, ERR_CODE_ZONE);
      error -= checkFieldIsNotEmpty(this.eddystoneFields, DESC, ERR_DESC);
      if (!error && !beaconModifiedAlreadyExisting(this.currentIndex, this.beaconList, this.eddystoneFields[COMPANY_ID].value, this.eddystoneFields[BEACON_ID].value, this.eddystoneFields, ERR_EDDY_ALREADY_EXIST))
        this.emitModifyEddystoneSuccess();
    },

    checkEddystoneCompanyId() {
      const companyId = this.eddystoneFields[COMPANY_ID].value.toUpperCase();
      if (!companyId.length || !regexCheck(companyId, /^[A-F0-9]{20}/))
        return setError(this.eddystoneFields, COMPANY_ID, ERR_EDDY_COMP_ID);
      return resetError(this.eddystoneFields, COMPANY_ID);
    },

    checkEddystoneBeaconId() {
      const beaconId = this.eddystoneFields[BEACON_ID].value.toUpperCase();
      if (!beaconId.length || !regexCheck(beaconId, /^[A-F0-9]{12}/))
        return setError(this.eddystoneFields, BEACON_ID, ERR_EDDY_BEAC_ID);
      return resetError(this.eddystoneFields, BEACON_ID);
    },

    emitModifyEddystoneSuccess() {
      this.$emit('modifyEddystoneSuccess', this.createNewBeacon());
      resetAllFields(this.eddystoneFields);
    },

    createNewBeacon() {
      return [
        this.eddystoneFields[COMPANY_ID].value.toUpperCase(),
        this.eddystoneFields[BEACON_ID].value.toUpperCase(),
        this.eddystoneFields[CODE_ZONE].value,
        this.eddystoneFields[DESC].value,
        this.eddystoneFields[LEVEL].value,
      ]
    },

  }
})
</script>
