<template>
    <div>
      <Pagination
        v-if="ssidFilteredList.length"
        :current-page="page"
        :total-pages="getTotalPages"
        @begin="page = 1"
        @end="page = getTotalPages"
        @decrement="page = page === 1 ? 1 : --page"
        @increment="page = page === getTotalPages ? page : ++page"
      />
    <CustomTableGreenRedOptional
      v-if="ssidFilteredList.length"
      :headers="getHeaders"
      :elems-list="getSSIDFilteredPage"
      :screen-percent-width="10"
      :showing-errors="true"
      list-element-component-name="ListElementNotGreen"
      red-button-text="common.menu_remove"
      red-button-bootstrap-target="#modal-alert-confirm"
      @element-red-button-clicked="$emit('remove-ssid-filtered', $event + (page - 1) * 10)"
    />
  </div>
</template>


<script>
import { defineComponent } from 'vue'

const NB_ITEMS = 10;

const MENU_LIST_UUID_FILTERED = "locationindoorble.menu_list_ssid_filtered";

const EV_REMOVE_SSID_FILTERED = 'remove-ssid-filtered';

export default defineComponent({

  props: { 
    ssidFilteredList: {
      type: Array,
      required: true,
    },
    // isBleLevelActivated: {
    //   type: Boolean,
    //   required: true,
    // },
  },

  emits: [
    EV_REMOVE_SSID_FILTERED,
  ],

  data() {
    return {
      page: 1,
      currentIndex: undefined,
    }
  },

  computed: {
    getSSIDFilteredPage() {
      const startIndex = (this.page - 1) * NB_ITEMS;
      const length = startIndex + NB_ITEMS < this.ssidFilteredList.length ?
          NB_ITEMS : this.ssidFilteredList.length - startIndex;
      let ssidFilteredPage = new Array(length);
      for (let i = 0; i < length; ++i)
        ssidFilteredPage[i] = this.formatSSIDFileteredInfo(this.ssidFilteredList[i + startIndex]);
      return ssidFilteredPage;
    },

    getHeaders() {
      return [MENU_LIST_UUID_FILTERED];
    },

    getTotalPages() {
      return Math.ceil(this.ssidFilteredList.length / NB_ITEMS);
    },
  },
  
  watch: {

    /**
     * Update the page number dynamically when wifi ssid filter are added / removed.
     */
    'ssidFilteredList.length': {      
      handler: function updatePageNumber(newVal, oldVal) {
        if (this.page > this.getTotalPages)
          --this.page;
        else if (newVal > oldVal)
          this.page = this.getTotalPages;
      }
    },
  },

  methods: {
    formatSSIDFileteredInfo(ssidFiltered) {
      const len = ssidFiltered.length;
      let newSsidFileterdFields = new Array(len);
      for (let i = 0; i < len; ++i)
        newSsidFileterdFields[i] = ssidFiltered[i];
      return newSsidFileterdFields;
    },
  },
  
})
</script>