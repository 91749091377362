<template>
  <!-- BEGIN: CustomSwitch -->
  <div class="clearfix flex-row">
    <div class="input-group">
      <input
        :checked="modelValue"
        type="checkbox"
        :class="getSwitchClass"
        :disabled="isDisabled"
        @change="$emit('update:modelValue', $event.target.checked)"
      >
      <Tippy
        v-if="isDisabled && tippyText.length"
        :content="$t(tippyText)"
        :options="{
          theme: 'light',
        }"
      >
        <div :class="getSwitchTextClass">
          {{ $t(text) }}
        </div>
      </Tippy>
      <div
        v-else 
        :class="getSwitchTextClass"
      >
        {{ $t(text) }}
      </div>
    </div>
    <ErrorMsg
      class="mt-2"
      :error-msg="errorMsg"
    />
  </div>
  <!-- END: CustomSwitch -->
</template>

<script>
import { defineComponent } from 'vue'


const DISABLED_CLASS = " opacity-50";
const SWITCH_CLASS = "show-code form-check-switch pr-9 bg-white";
const SWITCH_TEXT_CLASS = "font-bold text-theme-0 mt-1 pl-3";


/**
 * -------------------------- DESCRIPTION ---------------------------
 * A swith with a description and an optional error value. v-model MUST 
 * be used on this component, the original value of the switch will be 
 * automatically bind to the parent variable.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} modelValue
 *    Value of the switch. Automatically sets when using v-model on this
 *    component. For example, v-model="parentVariable" will bind this 
 *    modelValue to parentVariable, and the switch will be initialized with
 *    this value.
 * 
 * @param {String} text
 *    The text describing the switch. Should be i18 key for the locale 
 *    (ex: 'common.menu_save').
 * 
 * @param {String} errorMsg
 *    If empty, nothing appears. Otherwise, this string will appear 
 *    as an error in red to the user. Should be key for i18 locale.
 *    Empty by default, if no error management is needed do not fill it.
 * 
 * @param {String} customTextClass
 *    A custom class that can override the default switch text class. 
 *    Do not fill this props if you're ok with the default class.
 * 
 * @param {Boolean} isDisabled
 *    If sets to true, disables the switch. False by default. Do not fill
 *    this props if you do not need this feature.
 * 
 * @param {String} tippyText
 *    The text of the tippy shown in case the component is disabled.
 *    If tippyText is empty, the tippy will not be shown. Should be key 
 *    for i18 locale. Empty string by default. Do not fill this props 
 *    if you do not need this feature.
 * 
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits update:modelValue
 * @return {String}
 *    Emits the value of the switch when clicked on it. When v-model is
 *    used on this component, v-model automatically updates with this
 *    value. A data check should be performed on this value, and if 
 *    this one isn't corret errorMsg should be set with the appropriate 
 *    error message to inform the user.
 *              
 * ------------------------------------------------------------------
 */
export default defineComponent({

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    customTextClass: {
      type: String,
      default: SWITCH_TEXT_CLASS,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    tippyText: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  computed: {
    getSwitchClass() {
      if (this.isDisabled)
        return SWITCH_CLASS + DISABLED_CLASS;
      return SWITCH_CLASS;
    },

    getSwitchTextClass() {
      if (this.isDisabled)
        return this.customTextClass + DISABLED_CLASS;
      return this.customTextClass;
    }
  }
})
</script>
