<template>
  <!-- BEGIN: BatteryMonitoring local component -->
  <div class="clearfix box mt-6">
    <BoxTitle
      title="configuration.menu_battery_monitoring"
      color="bg-gray-400"
      help-locale="menu_battery_monitoring"
    />
    <FieldXHorizontalCheckBoxes
      v-model="percentCheckboxes"
      title="configuration.menu_low_battery_notification_triggering_threshold"
      :descriptions="getCheckBoxesDescriptions"
      :ids="getCheckBoxesids"
      @change="onPercentCheckBoxesChange"
    />
    <CheckBoxFieldAndXIcons
      v-for="(param, index) in getCheckBoxFields"
      :key="index"
      v-model="param.value"
      :custom-id="`checkbox-battery-networks-${index}`"
      :check-box-text="param.title"
      :error-msg="param.errorMsg"
      :icon-params="param.iconParams"
      @change="onCheckBoxChange(index)"
    />
  </div>
  <!-- END: BatteryMonitoring local component -->
</template>

<script>
import { defineComponent } from 'vue'
import {
  getBoolPtiValue,
  setNumberPtiValue,
} from '../../../dao/parametre-pti'


const PTI_PARAM_SHOW_BATTERY_LEVEL = "isShowBatteryLevelEnabled";
const PTI_PARAM_WAKE_UP_SCREEN_DURING_CHARGE = "isWakeUpScreenWhenChargingEnabled";

const PTI_PARAM_PERCENT = [
  "BatterieSeuil5",
  "BatterieSeuil10",
  "BatterieSeuil20",
];

const PTI_PARAM_BATTERY_CONTROL = "BatterieControle";

const I18_TITLE_5_PERCENT = 'configuration.menu_5_percent';
const I18_TITLE_10_PERCENT = 'configuration.menu_10_percent';
const I18_TITLE_20_PERCENT = 'configuration.menu_20_percent';

const I18_TITLE__BATTERY_PERCENTAGE_DISPLAYED = 'configuration.menu_name_is_battery_percentage_is_displayed';
const I18_TITLE__WAKE_SCREEN_DURING_CHARGE = 'configuration.menu_name_is_wake_up_screen_when_charging_enabled';

const CHECKBOX_ID_1 = 'percent-id-1';
const CHECKBOX_ID_2 = 'percent-id-2';
const CHECKBOX_ID_3 = 'percent-id-3';

const INDEX_5_PERCENT = 0;
const INDEX_10_PERCENT = 1;
const INDEX_20_PERCENT = 2;


export default defineComponent({

  props: {
    isEwg100: {
      type: Boolean,
      required: true,
    }
  },

  data() {
    return {
      descriptions: [
        I18_TITLE_5_PERCENT,
        I18_TITLE_10_PERCENT,
        I18_TITLE_20_PERCENT,
      ],
      ids: [
        CHECKBOX_ID_1,
        CHECKBOX_ID_2,
        CHECKBOX_ID_3,
      ],
      percentCheckboxes: [ undefined, undefined, undefined ],
      notifyNetworksProblem: false,
      batteryParams: [
        { 
          title: I18_TITLE__BATTERY_PERCENTAGE_DISPLAYED, 
          value: false, 
          ptiParam: PTI_PARAM_SHOW_BATTERY_LEVEL,
          iconParams: undefined 
        },
        { 
          title: I18_TITLE__WAKE_SCREEN_DURING_CHARGE, 
          value: false, 
          ptiParam: PTI_PARAM_WAKE_UP_SCREEN_DURING_CHARGE,
          iconParams: undefined 
        },
       
      ],
    }
  },

  computed: {

    getCheckBoxesDescriptions() {
      if (this.isEwg100)
        return [this.descriptions[INDEX_10_PERCENT], this.descriptions[INDEX_20_PERCENT]];
      return this.descriptions;
    },

    getCheckBoxesids() {
      if (this.isEwg100)
        return [this.ids[INDEX_10_PERCENT], this.ids[INDEX_20_PERCENT]];
      return this.ids;
    },

    getCheckBoxesPtiParam() {
      if (this.isEwg100)
        return [PTI_PARAM_PERCENT[INDEX_10_PERCENT], PTI_PARAM_PERCENT[INDEX_20_PERCENT]];
      return PTI_PARAM_PERCENT;
    },

    getCheckBoxFields() {
      if (!this.isEwg100)
        return [];
      return this.batteryParams;
    },
  },

  created() {
    for (let i = 0; i < this.percentCheckboxes.length; ++i)
      this.percentCheckboxes[i] = getBoolPtiValue(PTI_PARAM_PERCENT[i]);
    for (let i = 0; i < this.batteryParams.length; ++i)
      this.batteryParams[i].value = getBoolPtiValue(this.batteryParams[i].ptiParam);
    if (this.isEwg100)
      this.percentCheckboxes.splice(INDEX_5_PERCENT, 1);
  },

  methods: {
     onCheckBoxChange(index) {
      const param = this.batteryParams[index];
      setNumberPtiValue(param.ptiParam, param.value);
    },

    onPercentCheckBoxesChange() {
      let isBatteryControl = false;
      for (let i = 0; i < this.percentCheckboxes.length; ++i) {
        if (this.percentCheckboxes[i])
          isBatteryControl = true;
        setNumberPtiValue(this.getCheckBoxesPtiParam[i], this.percentCheckboxes[i]);
      }
      setNumberPtiValue(PTI_PARAM_BATTERY_CONTROL, isBatteryControl)
    }
  }, 

})
</script>
