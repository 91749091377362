import AWS from "aws-sdk";
import { getParameterPTIValue } from "../dao/parametre-pti";
import {
  FILENAME_DB,
  STORE_S3_IMG_FILES_INFO,
  STORE_IMG_FILES,
  STORE_SIGMA_IMG,
  NAME_RG655,
  NAME_IS655,
  NAME_IS330,
  NAME_IS530,
  NAME_EWG100,
  EMERIT_BUCKET_NAME
} from "./const_store";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { createDbFile, extractZipFolder } from "../utils/store_utils";
import { regexCheck } from "../utils/fieldManagement";

export const siteTypes = {
  BASE: "base",
  MODEL: "model",
  DEPLOY: "deploy"
};

const state = () => {
  return {
    strict: true,
    deviceStr: "watch",

    isUserAuthenticated: false,
    siteType: siteTypes.LOGIN,
    disconnectionPath: "",
    isNfcModel: false,
    modelImageSource: undefined,
    smallModelImageSource: undefined,
    alertAutoDetecState: false,

    deviceInfo: undefined,
    targetDeviceInfo: undefined,
    targetDbFile: undefined,

    isCustomScreens: undefined,
    dbFile: undefined,
    widgetScreenImg: {
      file: undefined,
      updateS3: false
    },
    launchScreenImg: {
      file: undefined,
      updateS3: false
    },
    secrets: {
      s3AccessKeyID: undefined,
      s3SecretAccessKey: undefined,
      initialCred: undefined,
      adminPassword: undefined,
      adminPasswordModify: undefined,
      adminPasswordConnect: undefined
    },
    wifiPEAPCertificate: {
      file: undefined,
      updateS3: false
    },
    certificateList: []
  };
};
const getters = {
  getS3StoragePath: state => {
    let deviceInfo = state.deviceInfo;
    return (
      deviceInfo.deviceModelName +
      "/" +
      deviceInfo.deviceIMEI +
      "_" +
      deviceInfo.deviceSerialNumber
    );
  },

  getTargetDeviceS3StoragePath: state => {
    let targetDeviceInfo = state.targetDeviceInfo;
    return (
      targetDeviceInfo.deviceModelName +
      "/" +
      targetDeviceInfo.deviceIMEI +
      "_" +
      targetDeviceInfo.deviceSerialNumber
    );
  }
};

const mutations = {
  saveDeviceInfo: (state, deviceInfo) => {
    state.deviceInfo = deviceInfo;

    // device model with nfc option?
    if (
      state.deviceInfo.deviceModelName == NAME_RG655 ||
      state.deviceInfo.deviceModelName == NAME_IS655 ||
      state.deviceInfo.deviceModelName == NAME_IS330 ||
      state.deviceInfo.deviceModelName == NAME_IS530
    )
      state.isNfcModel = true;
    else state.isNfcModel = false;

    state.deviceStr =
      deviceInfo.deviceModelName == NAME_EWG100 ? "watch" : "phone";

    // update img model source
    state.modelImageSource = STORE_SIGMA_IMG;
    const deviceModelName = state.deviceInfo.deviceModelName;
    for (let i = 0; i < STORE_IMG_FILES.length; ++i) {
      if (deviceModelName == STORE_IMG_FILES[i].modelName) {
        state.modelImageSource = STORE_IMG_FILES[i].img;
        state.smallModelImageSource = STORE_IMG_FILES[i].smallImg;
      }
    }
  },

  setDisconnectionPath: (state, path) => {
    state.disconnectionPath = path;
  },

  setTargetDeviceInfo: (state, deviceInfo) => {
    state.targetDeviceInfo = deviceInfo;
  },

  updateLoginStatus: (state, isUserAuthenticated) => {
    state.isUserAuthenticated = isUserAuthenticated;
  },

  updateAlertAutoDetecState: (state, newActivationState) => {
    state.alertAutoDetecState = newActivationState;
  },

  setIsCustomScreens: (state, newValue) => {
    state.isCustomScreens = newValue;
  },

  setDbFile: (state, dbFile) => {
    state.dbFile = dbFile;
  },

  setTargetDbFile: (state, dbFile) => {
    state.targetDbFile = dbFile;
  },

  setLaunchScreenImgFile: (state, imgFile) => {
    state.launchScreenImg.file = imgFile;
  },

  setWidgetScreenImgFile: (state, imgFile) => {
    state.widgetScreenImg.file = imgFile;
  },

  setLaunchScreenImgUpdateS3: (state, updateS3) => {
    state.launchScreenImg.updateS3 = updateS3;
  },

  setWidgetScreenImgUpdateS3: (state, updateS3) => {
    state.widgetScreenImg.updateS3 = updateS3;
  },

  setSecrets: (state, secrets) => {
    state.secrets = secrets;
  },

  setWifiPEAPCertificateFile: (state, certificateFile) => {
    state.wifiPEAPCertificate.file = certificateFile;
  },

  setWifiPEAPCertificateUpdateS3: (state, updateS3) => {
    state.wifiPEAPCertificate.updateS3 = updateS3;
  },

  setCertificateObjet: (state, object) => {
    state.certificateList.push(object);
  },

  setReplaceCertificateTable: (state, table) => {
    state.certificateList = table ;
  },

  resetEmeritValues: state => {
    state.deviceStr = "watch";
    state.isUserAuthenticated = false;
    state.siteType = siteTypes.LOGIN;
    state.isNfcModel = false;
    state.modelImageSource = undefined;
    state.smallModelImageSource = undefined;
    state.alertAutoDetecState = false;

    state.deviceInfo = undefined;
    state.targetDeviceInfo = undefined;
    state.targetDbFile = undefined;

    state.isCustomScreens = undefined;
    state.dbFile = undefined;
    state.widgetScreenImg = {
      file: undefined,
      updateS3: false
    };
    state.launchScreenImg = {
      file: undefined,
      updateS3: false
    };
    state.wifiPEAPCertificate = {
      file: undefined,
      updateS3: false
    };
  }
};

const STORE_MUTATE_DB_FILE = "setDbFile";
const STORE_MUTATE_TARGET_DB_FILE = "setTargetDbFile";
const PARAM_PTI_IDENTIFIANT = "Identifiant";

const actions = {
  getDbFileFromS3: ({ state, getters, commit }, { self }) => {
    const s3 = new AWS.S3({
      accessKeyId: state.secrets.s3AccessKeyID,
      secretAccessKey: state.secrets.s3SecretAccessKey,
      region: "eu-west-3"
    });

    const s3StoragePath = getters.getS3StoragePath + FILENAME_DB;

    const s3RequestObj = {
      Bucket: EMERIT_BUCKET_NAME,
      Key: s3StoragePath
    };
    const s3Callback = (error, data) => {
      if (error) {
        self.onDbDownloadError();
      } else {
        let binaryData = new Uint8Array(data.Body);
        createDbFile(
          commit,
          STORE_MUTATE_DB_FILE,
          binaryData,
          self.onDbDownloadCompleted
        );
      }
    };
    s3.getObject(s3RequestObj, s3Callback);
  },

  getImgFileFromS3: ({ state, getters, commit }, { fileName }) => {
    const s3StoragePath = getters.getS3StoragePath + fileName;

    const s3 = new AWS.S3({
      accessKeyId: state.secrets.s3AccessKeyID,
      secretAccessKey: state.secrets.s3SecretAccessKey,
      region: "eu-west-3"
    });

    let storeMutateFuncName;
    for (let i = 0; i < STORE_S3_IMG_FILES_INFO.length; ++i)
      if (fileName === STORE_S3_IMG_FILES_INFO[i].fileName)
        storeMutateFuncName = STORE_S3_IMG_FILES_INFO[i].storeMutateFuncName;

    const s3RequestObj = {
      Bucket: EMERIT_BUCKET_NAME,
      Key: s3StoragePath
    };
    const s3Callback = (error, data) => {
      if (!error && data.Body.length) commit(storeMutateFuncName, data.Body);
    };

    s3.getObject(s3RequestObj, s3Callback);
  },

  uploadDbFileToS3: ({ state, getters }, { self }) => {
    const s3StoragePath = getters.getS3StoragePath + FILENAME_DB;

    const s3 = new AWS.S3({
      accessKeyId: state.secrets.s3AccessKeyID,
      secretAccessKey: state.secrets.s3SecretAccessKey,
      region: "eu-west-3"
    });

    const s3RequestObj = {
      Bucket: EMERIT_BUCKET_NAME,
      Key: s3StoragePath,
      Body: state.dbFile.export()
    };
    const s3Callback = error => {
      if (error) self.onDbUploadError();
      else self.onDbUploadCompleted();
    };

    s3.putObject(s3RequestObj, s3Callback);
  },

  uploadImgFileToS3: ({ state, getters }, { self, fileName }) => {
    const s3StoragePath = getters.getS3StoragePath + fileName;

    const s3 = new AWS.S3({
      accessKeyId: state.secrets.s3AccessKeyID,
      secretAccessKey: state.secrets.s3SecretAccessKey,
      region: "eu-west-3"
    });

    let file;
    for (let i = 0; i < STORE_S3_IMG_FILES_INFO.length; ++i)
      if (STORE_S3_IMG_FILES_INFO[i].fileName.valueOf() === fileName.valueOf())
        file = state[STORE_S3_IMG_FILES_INFO[i].storeVarName].file;

    const s3RequestObj = {
      Bucket: EMERIT_BUCKET_NAME,
      Key: s3StoragePath,
      Body: file
    };
    const s3Callback = error => {
      if (error) self.onImgUploadError();
      else self.onImgUploadCompleted(fileName);
    };

    s3.putObject(s3RequestObj, s3Callback);
  },

  uploadWifiPEAPCertificateFileToS3: ({ state, getters },{ self, fileName, certificateFile }) => {
    const s3StoragePath = getters.getS3StoragePath + "/" + fileName;
    let file = certificateFile;

    const s3 = new AWS.S3({
      accessKeyId: state.secrets.s3AccessKeyID,
      secretAccessKey: state.secrets.s3SecretAccessKey,
      region: "eu-west-3"
    });

    const s3RequestObj = {
      Bucket: EMERIT_BUCKET_NAME,
      Key: s3StoragePath,
      Body: file
    };
    const s3Callback = error => {
      if (error) self.onCertificateUploadError();
      else self.onCertificateUploadCompleted(fileName);
    };

    s3.putObject(s3RequestObj, s3Callback);
  },

  getTargetDeviceDBFileFromS3: ({ state, getters, commit }, { self }) => {
    const s3StoragePath = getters.getTargetDeviceS3StoragePath + FILENAME_DB;
    const s3RequestObj = {
      Bucket: EMERIT_BUCKET_NAME,
      Key: s3StoragePath
    };

    const s3 = new AWS.S3({
      accessKeyId: state.secrets.s3AccessKeyID,
      secretAccessKey: state.secrets.s3SecretAccessKey,
      region: "eu-west-3"
    });

    const s3Callback = (error, data) => {
      if (error) {
        self.onTargetDbDownloadError();
      } else {
        let binaryData = new Uint8Array(data.Body);
        createDbFile(
          commit,
          STORE_MUTATE_TARGET_DB_FILE,
          binaryData,
          self.onTargetDbDownloadCompleted
        );
      }
    };
    s3.getObject(s3RequestObj, s3Callback);
  },

  cloneModelDB: ({ state, commit }, { self }) => {
    let exportedModelDb = state.dbFile.export();
    let binaryData = new Uint8Array(exportedModelDb);
    createDbFile(
      commit,
      STORE_MUTATE_TARGET_DB_FILE,
      binaryData,
      self.onModelDBClonedToTargetDB
    );
  },

  uploadTargetDeviceDBFileToS3: ({ state, getters }, { self }) => {
    const s3StoragePath = getters.getTargetDeviceS3StoragePath + FILENAME_DB;
    const binaryDB = state.targetDbFile.export();

    const s3 = new AWS.S3({
      accessKeyId: state.secrets.s3AccessKeyID,
      secretAccessKey: state.secrets.s3SecretAccessKey,
      region: "eu-west-3"
    });

    const s3RequestObj = {
      Bucket: EMERIT_BUCKET_NAME,
      Key: s3StoragePath,
      Body: binaryDB
    };
    const s3Callback = error => {
      if (error) self.onTargetDbUploadError();
      else self.onTargetDbUploadCompleted();
    };
    s3.putObject(s3RequestObj, s3Callback);
  },

  downloadZipConfigToPc: ({ state }) => {
    var binaryDB = state.dbFile.export();

    let deviceId = getParameterPTIValue(PARAM_PTI_IDENTIFIANT);
    let deviceModelName = state.deviceInfo.deviceModelName.toLowerCase();
    let dbFileName = deviceId + ".emerit_" + deviceModelName;
    dbFileName = dbFileName.trim().replaceAll(" ", "_");

    let zip = new JSZip();
    zip.file(dbFileName, binaryDB, { binary: true });

    for (let i = 0; i < STORE_S3_IMG_FILES_INFO.length; ++i) {
      let imgFile = state[STORE_S3_IMG_FILES_INFO[i].storeVarName].file;
      if (imgFile) zip.file(STORE_S3_IMG_FILES_INFO[i].fileName, imgFile);
    }

    for (let i = 0; i < state.certificateList.length; ++i) {
      zip.file(state.certificateList[i].name, state.certificateList[i].file);
    }

    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, dbFileName + ".zip");
    });
  },

  setModelFileAsTemplateConf: ({ state, commit }, { self, modelFile }) => {
    let deviceModelName = state.deviceInfo.deviceModelName.toLowerCase();

    const zipRegex = new RegExp(
      `.*\\.emerit_${deviceModelName}( \\(\\d*\\))?\\.zip$`,
      "g"
    );
    if (regexCheck(modelFile.name, zipRegex)) {
      extractZipFolder(
        state,
        commit,
        self,
        modelFile,
        deviceModelName,
        STORE_MUTATE_DB_FILE
      );
    } else if (modelFile.name.endsWith(".emerit_" + deviceModelName)) {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        let binaryData = new Uint8Array(fileReader.result);
        createDbFile(
          commit,
          STORE_MUTATE_DB_FILE,
          binaryData,
          self.onTempConfUpdateSuccess
        );
      };
      fileReader.readAsArrayBuffer(modelFile);
    } else {
      self.onTempConfUpdateFailed();
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
