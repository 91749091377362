{
  "common": {
    "menu_nothing":"Intet",
    "menu_min_message": "Minimumbesked er 1",
    "menu_field_cannot_be_empty_need_one": "Mindst ét felt skal udfyldes",
    "menu_affichage": "Skærm",
    "menu_son": "Hans",
    "menu_post_alert": "Post-alarm",
    "menu_vibration_5_secondes": "Vibrationer hvert 5. sekund",
    "menu_phone_number_must_start": "Telefonnummeret må kun indeholde numre",
    "menu_wrong_file_certificate": "Forkert filformat. Formatet skal være .pfx eller .p12.",
    "menu_wrong_config": "Konfigurationen er forkert",
    "menu_see_help_for_more_info": "Se venligst online-hjælpen for mere info.",
    "menu_back": "Tilbage",
    "menu_save": "Gem",
    "menu_settings": "Indstillinger",
    "menu_notification_prealert": "Pre-advarselsnotifikation",
    "menu_notification_by_vibration": "Notifikation via vibration",
    "menu_notification_by_sound": "Notifikation via lyd",
    "menu_notification_by_flash": "Notifikation via flash",
    "menu_notification_duration": "Pre-alarm notifikation varighed",
    "menu_seconds": "sekunder",
    "menu_minutes": "minutter",
    "menu_degrees": "grader",
    "menu_meters": "meter",
    "menu_settings_for": "Indstillinger for",
    "menu_confirm": "Bekræft",
    "menu_field_cannot_be_empty": "Feltet må ikke være tomt",
    "menu_select_at_least_one_triggering_mode": "Vælg mindst en udløsningstilstand",
    "menu_phone_number_must_start_with_+": "Telefonnummeret skal starte med + og kun indeholde cifre",
    "menu_phone_number_is_too_short": "Telefonnummeret er for kort",
    "menu_phone_number_already_used": "Telefonnummer allerede anvendt",
    "menu_select_at_least_one_event": "Vælg mindst en begivenhed",
    "menu_are_you_sure": "Er du sikker?",
    "menu_add": "Tilføje",
    "menu_modify": "Tilpas",
    "menu_remove": "Fjern",
    "menu_remove_everything": "Fjern alt",
    "menu_wrong_flie_format": "Forkert filformat",
    "menu_cancel": "Annuller"
  },
  "configuration": {
    "menu_no_checked_standard": "Ingen parametre er markeret for standardtilstand",
    "menu_no_checked_transparent": "Ingen parametre er markeret for gennemsigtig tilstand",
    "menu_location_standard": "Standardtilstand",
    "menu_location_transparent": "Gennemsigtig tilstand",
    "menu_location_indoor": "Indendørs",
    "menu_type_action": "Type af handling forbundet med tre hurtige tryk",
    "menu_device_stop": "Stop enhed",
    "menu_custom_on_off": "Tilpas tænd/sluk-knap",
    "menu_trigger_alert":"Udløs en alarm",
    "menu_trigger_call": "Udløs et opkald ved at trykke hurtigt tre gange på følgende nummer:",
    "menu_tippy_text_on_off":"Gå til [Tilpas tænd / sluk-knap] for at aktivere funktionaliteten",
    "menu_show_last_location": "Gem den sidst kendte position ved hvert enhedsstop",
    "menu_force_alert_reactivated_system": "Tvinger systemet til at blive genaktiveret, hver gang enheden tændes",
    "menu_err_serv_with_no_events": "Ingen begivenheder er blevet konfigureret",
    "menu_err_one_or_several_recipient_have_message_but_no_events": "En eller flere modtagere modtager begivenheder via SMS, men ingen begivenheder er blevet konfigureret",
    "menu_img_upload_failure": "Billedupload mislykkedes, tjek din internetforbindelse",
    "menu_choose_at_least_one_custom_screen": "Ingen konfiguration udført",
    "menu_custom_screens": "Tilpas skærm",
    "menu_communication_option": "Kommunikationsmuligheder",
    "menu_system_option": "Systemindstillinger",
    "menu_disable_on_off_btn": "Deaktiver tænd/sluk-knap for at forhindre enheden i at slukke",
    "menu_model_check_emerit_version_unstopable_call": "Denne indstilling kræver, at enhedens firmware er kompatibel. Du kan tjekke ROM-versionen via Om-widgetten.",
    "menu_tippy_unstopable_call_not_available": "Denne indstilling er ikke kompatibel med din enheds firmware. Kontakt venligst teknisk support for mere information.",
    "menu_call_cant_be_stop": "Alarmopkald kan ikke stoppes af brugeren",
    "menu_whitelist": "Hvidliste (begrænsning af indgående opkald)",
    "menu_wifi_configuration": "Wifi forbindelse",
    "menu_tippy_sip_not_available": "VoIP-aktivering er ikke tilgængelig på din enhed",
    "menu_tippy_widget_loc": "Denne indstilling kræver, at mindst én af placeringstilstandene er aktiveret",
    "menu_model_check_emerit_version": "VOIP kræver, at Emerit SIP-appen er til stede på denne enhed. Du kan kontrollere, om den er til stede via Om-widgetten.",
    "menu_enabling_sip_will_remove_recipients": "Aktivering af VoIP vil fjerne registrerede interne modtagere. Du skal indtaste en ny modtagerliste.",
    "menu_disabling_sip_will_remove_recipients": "Hvis du deaktiverer VoIP, fjernes registrerede interne modtagere. Du skal indtaste en ny modtagerliste.",
    "menu_wrong_character_in_user_id": "Brugeridentifikator kan ikke indeholde '_'-tegn, når der er mindst ét Emerit-ur valgt som intern modtager",
    "menu_choose_notif_mode": "Valg af notifikationstilstand for indgående SMS",
    "menu_err_choose_at_least_one_notif_mode": "Vælg mindst én meddelelsestilstand",
    "menu_alerts_processing": "Behandling af advarslerne",
    "menu_internal_recipients": "Interne modtagere",
    "menu_alarm_monitoring_center": "Kontrolcentral ",
    "menu_alarm_monitoring_server": "Alarm overvågningsserver",
    "menu_alerts_localization": "Advarsel lokalisering",
    "menu_location_never": "Aldrig",
    "menu_location_outdoor": "Udendørs",
    "menu_alerts_triggering_modes": "Advarsel udløsningstilstand",
    "menu_alert_activation_sos_button": "SOS-knap",
    "menu_alert_activation_sos_button_and_programmable_button": "SOS-knap og programmerbare knapper",
    "menu_alert_activation_side_black_button": "Sort side knap",
    "menu_alert_activation_side_yellow_button": "Gul side knap",
    "menu_alert_activation_front_programmable_button": "Programmerbar knap foran",
    "menu_alert_activation_side_programmable_button": "Programmerbar sideknap",
    "menu_alert_activation_automatic_detection": "Automatisk registrering",
    "menu_wrist_alert_mode": "Hurtige håndledsbevægelser",
    "menu_wearing_style_wrist": "Håndled",
    "menu_wearing_style_waist": "Talje",
    "menu_wearing_style_arms": "Arm",
    "menu_wearing_style_neck": "Hals",
    "menu_alert_activation_power_button": "Tænd/Sluk-knap",
    "menu_update_configuration": "Opdateringskonfiguration",
    "menu_configuration_update_has_succeeded": "Konfigurationsopdatering er lykkes",
    "menu_device_must_be_restarted": "Start enheden eller start proceduren for at indlæse den nye konfiguration",
    "menu_no_alert_recipient_was_selected": "Ingen modtager er valgt",
    "menu_no_alert_processing_was_selected": "Ingen behandling af advarsel er valgt",
    "menu_no_recipient_or_server_was_created": "Ingen modtager eller server er lavet",
    "menu_no_recipient_was_created": "Ingen modtager eller lavet",
    "menu_no_server_was_created": "Ingen modtager eller server",
    "menu_validate_configuration": "Godkend konfigurationen ",
    "menu_wifi": "Wifi",
    "menu_select_at_least_one_automatic_detection": "Vælg mindst en automatisk registrering",
    "menu_save_at_least_one_wifi_connection": "Gem mindst en Wifi forbindelse",
    "menu_configuration_update_has_failed": "Opdatering er mislykket",
    "menu_check_internet_connection_and_retry": "Tjek internetforbindelsen og prøv igen",
    "menu_download_configuration_model_to_computer": "Download konfigurationsskabelonen til computer",
    "menu_configuration_model_name": "Konfigurationsskabelon navn",
    "menu_device_notification": "Administration af enhedsmeddelelser",
    "menu_password_for_deployment": "Konfigurations password ved opsætning: 6 cifret kodeord med mindst 1 stort bogstav, 1 lille bogstav og 1 nummer. Ingen special tegn",
    "menu_enter_a_password": "Indtast et kodeord",
    "menu_password_must_be_different_from_access_password": "Kodeordet skal være forskellig fra adgangskode",
    "menu_location_indoor_wifi": "Indendørs ved hjælp af Wifi",
    "menu_location_indoor_ble": "Indendørs ved hjælp af Bluetooth",
    "menu_location_automatic_incoming_call_pickup_after_three_rings": "Ingående opkald bliver automatisk taget efter 3 ring",
    "menu_err_ble_level_precision_not_activated": "Niveaustyring ved hjælp af Bluetooth-beacon er blevet valgt til Wifi indendørs placering. Denne parameter er obligatorisk.",
    "menu_deployment_password": "implementering adgangskode",
    "menu_no_wifi_access_point_has_been_registered": "Der er ikke registreret nogen Wifi-terminal",
    "menu_no_ble_beacon_has_been_registered": "Der er ikke registreret nogen Bluetooth-beacon",
    "menu_deactivation_of_alert_system_by_the_user": "Deaktivering af alarmsystem af brugeren",
    "menu_freely_disable_enable_alert_system": "Tillad frit at deaktivere/aktivere alarmsystem",
    "menu_temporarily_disable_enable_alert_system": "Tillad midlertidigt at deaktivere/aktivere advarselssystem",
    "menu_duration_of_deactivation": "Varighed af deaktivering",
    "menu_GSM_data_wifi_issue_notification": "Underretning om GSM-, data- og WiFi-netværksproblemer",
    "menu_battery_monitoring": "Batteriovervågning",
    "menu_networks_monitoring": "Overvågning af netværk",
    "menu_low_battery_notification_triggering_threshold": "Udløsningstærskel for meddelelse om lavt batteri",
    "menu_name_is_not_displayed": "Brugernavnet vises ikke på enheden",
    "menu_confirm_deactivation_of_localisation": "bekræfte placeringsdeaktivering",
    "menu_ask_to_activate_sip": "Aktiver voice over IP (VoIP) med SIP-konto",
    "menu_wrong_sip_configuration": "Alle VoIP-felter skal udfyldes",
    "menu_show_fragment_location": "Aktiver widget for aktuel placering",
    "menu_5_percent": "5%",
    "menu_10_percent": "10%",
    "menu_20_percent": "20%",
    "menu_name_is_displayed": "Brugernavnet vises på enheden",
    "menu_name_is_battery_percentage_is_displayed": "Vis batteriprocent",
    "menu_name_is_wake_up_screen_when_charging_enabled": "Væk skærmen hvert 10. sekund under opladningen, når uret er tændt",
    "menu_name_is_sound_notif_securite_positive_enabled": "Aktiver lyd for meddelelser"
  },
  "alerttrigerring": {
    "menu_enable_selecte_switch":"Vælg venligst mindst én detektion",
    "menu_agression": "Angreb",
    "menu_demande_secours": "At bede om hjælp",
    "menu_message_alert": "Alarmbesked, der skal sendes",
    "menu_communication_unidirectionnelle": "Envejskommunikation (højttaler deaktiveret)",
    "menu_communication_bidirectionnelle": "Tovejskommunikation",
    "menu_cycle_destinataires_télésurveillance": "Automatisk opkaldscyklus (interne modtagere eller fjernovervågningsopkald)",
    "menu_sos_button_settings": "Indstillinger for SOS-knappen",
    "menu_power_button_settings": "Indstillinger for Tænd/Sluk-knappen",
    "menu_automatic_detection_settings": "Indstillinger for automatisk registrering",
    "menu_trigger_mode": "Trigger tilstand ",
    "menu_one_long_press": "Et langt tryk",
    "menu_three_short_presses": "Tre korte tryk",
    "menu_alert_mode": "Advarselstilstand",
    "menu_speaker_on_request_for_help": "Højtaler TIL > anmodning om hjælp",
    "menu_silent_assault": "Lydløs > overfaldskontekst",
    "menu_select_at_least_one_notification_mode": "Vælg mindst en af notifikation tilstandene",
    "menu_front_programmable_button_settings": "Programmerbare knapindstillinger foran",
    "menu_side_black_button_settings": "Indstillinger for sort sideknap",
    "menu_side_yellow_button_settings": "Indstillinger for gul sideknap",
    "menu_side_programmable_button_settings": "Programmerbare sideknap indstillinger",
    "menu_sos_button_and_programmable_button_settings": "SOS-knap og programmerbare knap indstillinger"
  },
  "locationoutdoor": {
    "menu_outdoor_location": "Indstillinger for udendørs placering",
    "menu_gps_based": "GPS baseret indendørs placering",
    "menu_refreseh_only_when_event_sending": "Opdater kun ved afsendelse af event",
    "menu_update_at_regular_intervals": "Opdater ved jævne mellemrum",
    "menu_automatic_refresh_interval": "Automatisk opdateringsinterval",
    "menu_location_update_method": "Placering opdateringsmetode"
  },
  "locationindoorwifi": {
    "menu_err_ssid_already_exist": "SSID'en eksisterer allerede i listen",
    "menu_no_ble_transparent": "Ingen Bluetooth UUID er blevet registreret",
    "menu_no_wifi_hotspot_transparent": "Ingen WiFi SSID er blevet registreret",
    "menu_no_param_found_indoord": "Ingen parameter er blevet valgt",
    "menu_list_ssid_filter": "Liste over SSID'er der skal filtreres",
    "menu_add_ssid_filter": "Tilføj SSID'er til filtrering",
    "menu_wifi_based_indoor_location_transparent": "Indendørs placering baseret på WiFi (Transparent tilstand)",
    "menu_disable_ble_loc_to_handle_floors": "Diese Einstellung muss auf der Einrichtungsseite für den Bluetooth-Standort im Innenbereich deaktiviert werden",
    "menu_enable_ble_loc_to_handle_floors": "Diese Einstellung muss über Bluetooth auf der Einrichtungsseite für den Innenbereich aktiviert werden",
    "menu_wifi_based_indoor_location": "Wifi baseret indendørs placering",
    "menu_enable_wifi_based_indoor_location": "Aktiver Wifi baseret indendørs placering",
    "menu_import_a_new_wifi_hotspot_file": "Importer en ny .csv-fil med Wifi hotspots",
    "menu_import_succeeded": "Import er lykkes",
    "menu_confirm_import": "Bekræft importen",
    "menu_import_will_replace_existing_list": "Import fra en .csv-fil erstatter den faktisk gemte liste over Wifi hotposts",
    "menu_error_while_importing": "En fejl opstod under import",
    "menu_export_to_csv_wifi_configuration": "Eksporter listen over wifi hotposts til en .csv-fil",
    "menu_excel_file_is_empty": "Excel-filen er tom",
    "menu_check_your_excel_file": ". Tjek din Excel-fil.",
    "menu_download_excel_file_template_for_importation": "Download excel skabelon til import",
    "menu_number_of_imported_wifi_hotspots": "Antal importerede WiFi-hotspots:",
    "menu_an_error_was_detected_line": "Der blev fundet en fejl linje",
    "menu_number_of_registered_wifi_hotspots": "Antal registrerede WiFi-hotspots: ",
    "menu_incorrect_column_headers": "Forkerte kolonneoverskrifter. Brug Excel-skabelonen til at registrere Wifi hotposts, og gem den i .csv-format med ';'-tegn som separator.",
    "menu_wifi_hotspot_for_indoor_location": "Wifi hotspot for indendørs placering",
    "menu_no_wifi_hotspot_recorded": "Ingen Wifi-hotspot registreret",
    "menu_no_wifi_hotspot_imported": "Ingen importeret Wifi-terminal",
    "menu_add_hotspot": "Tilføj Wifi-hotspot",
    "menu_modify_hotspot": "Rediger Wifi-hotspot",
    "menu_hotspot_ssid": "SSID",
    "menu_hotspot_bssid": "BSSID",
    "menu_hotspot_code_zone": "Områdenummer",
    "menu_hotspot_level": "Scene",
    "menu_hotspot_description": "Beskrivelse",
    "menu_tippy_ssid": "Navn på Wifi-netværk",
    "menu_tippy_bssid": "MAC-adresse, der udsendes af Wifi-hotspottet (6 par hexadecimale tegn adskilt af ':'-tegn)",
    "menu_tippy_code_zone": "Oplysninger sendt via SIA-protokollen",
    "menu_tippy_level": "0 for stueetagen, negativt tal for kælderetagen (ex: -3)",
    "menu_tippy_description": "Placering i forbindelse med hotspottet. Det er forbudt at bruge tegnet '_', når muligheden for at sende beskeder til Emerit-ure er aktiveret på grund af en fejl (denne fejl vil blive rettet i næste opdatering)",
    "menu_err_empty_ssid": "SSID'et må ikke være tomt.",
    "menu_err_wrong_bssid": "Dårligt BSSID. BSSID'et skal være en sekvens af 6 par cifre / bogstaver adskilt af tegnet ':'. Eksempel: 11:22:33:44:55:66.",
    "menu_err_bssid_already_exist": "Det indtastede BSSID er allerede blevet registreret.",
    "menu_err_wrong_level": "Forkert niveaunummer. Skal være et positivt eller negativt tal.",
    "menu_err_empty_desc": "Beskrivelsen må ikke være tom.",
    "menu_err_wrong_code_zone": "Kodezonen skal være en sekvens af 3 tal mellem 001 og 999. Eksempel: 007.",
    "menu_err_one_elem_missing_level": "Gulvet er ikke gået ind for alle Wifi-terminaler. Tjek venligst den registrerede liste.",
    "menu_add_hotspot_success": "Wifi-hotspot tilføjet med succes",
    "menu_add_hotspot_failed": "Fejl under forsøg på at tilføje et nyt Wifi-hotspot",
    "menu_modify_hotspot_success": "Wifi-hotspot blev ændret med succes",
    "menu_level_precision_with_ble": "Håndtering af gulvændringer ved hjælp af Bluetooth-beacons",
    "menu_enable_import_export_csv": "Importer / eksporter ved hjælp af CSV-format",
    "menu_add_manually_wifi_hotspots": "Tilføj manuelt Wifi-hotspots",
    "menu_remove_all_hotspots": "Er du sikker på, at du vil slette alle registrerede Wifi-hotspots?",
    "menu_remove_one_hotspot": "Er du sikker på, at du vil slette dette Wifi-hotspot?",
    "menu_precise_wifi_location_with_ble": "Wifi-placering præciseret ved hjælp af Bluetooth",
    "menu_title_refresh_choice":" Frequence d'actualisation"
  },
  "locationindoorble": {
    "menu_remove_one_beacon_transparent": "Er du sikker på, at du vil slette denne UUID?",
    "menu_add_beacon_transparent_success": "UUID tilføjet med succes",
    "menu_condition_uuid_transparent": "UUID-formatet skal være 32 tegn i hexadecimal",
    "menu_add_beacon_transparent_failed": "UUID allerede registreret",
    "menu_list_ssid_filtered": "Liste over SSID'er der skal filtreres",
    "menu_custom_model": "Anden UUID",
    "menu_ble_based_indoor_location_transparent": "Indendørs placering baseret på Bluetooth (Transparent tilstand)",
    "menu_list_uuid_filtered": "Liste over UUID'er der skal filtreres",
    "menu_add_uuid_filtered": "Tilføj Company ID (UUID) til filtrering",
    "menu_ble_based_indoor_location": "Bluetooth baseret indendørs placering",
    "menu_enable_ble_based_indoor_location": "Aktiver Bluetooth baseret indendørs placering",
    "menu_download_excel_file_template_for_importation": "Download excel skabelon til import",
    "menu_import_a_new_ble_beacons_file": "Importer en ny Bluetooth-beacons .csv-fil",
    "menu_import_succeeded": "Import er lykkes",
    "menu_confirm_import": "Bekræft importen",
    "menu_import_will_replace_existing_list": "Import fra en .csv-fil erstatter den faktisk gemte liste over Bluetooth-beacons",
    "menu_error_while_importing": "En fejl opstod under import",
    "menu_excel_file_is_empty": "Excel-filen er tom",
    "menu_check_your_excel_file": ". Tjek din Excel-fil.",
    "menu_export_to_csv_ble_configuration": "Eksporter listen over Bluetooth-beacons til en .csv-fil",
    "menu_no_ble_beacon_recorded": "Ingen Bluetooth-beacon registreret",
    "menu_no_ble_beacon_imported": "Ingen importeret Bluetooth-beacon",
    "menu_number_of_registered_ble_beacons": "Antal registrerede Bluetooth-beacons: ",
    "menu_incorrect_column_headers_ble": "Forkerte kolonneoverskrifter. Brug Excel-skabelonen til at registrere Bluetooth-beacons, og gem den i .csv-format med ';'-tegn som separator.",
    "menu_number_of_imported_ble_beacons": "Antal importerede Bluetooth-beacons:",
    "menu_an_error_was_detected_line": "Der blev fundet en fejl linje",
    "menu_add_beacon": "Tilføj Bluetooth-beacon",
    "menu_modify_beacon": "Rediger Bluetooth-beacon",
    "menu_add_manually_ble_beacons": "Tilføj manuelt Bluetooth-beacon",
    "menu_remove_all_beacons": "Er du sikker på, at du vil slette alle registrerede Bluetooth-beacons?",
    "menu_remove_one_beacon": "Er du sikker på, at du vil slette dette Bluetooth-beacon?",
    "menu_add_beacon_success": "Bluetooth-beacon tilføjet med succes",
    "menu_add_beacon_failed": "Fejl under forsøg på at tilføje et nyt Bluetooth-beacon",
    "menu_modify_beacon_success": "Bluetooth-beacon blev ændret med succes",
    "menu_beacon_model": "Bluetooth-beacon type",
    "menu_beacon_company_id": "Virksomhedsidentifikation",
    "menu_beacon_id": "Beacon identifikator",
    "menu_beacon_type": "Type",
    "menu_beacon_level": "Scene",
    "menu_beacon_code_zone": "Områdenummer",
    "menu_beacon_description": "Beskrivelse",
    "menu_tippy_uuid": "Svarer til UUID fra iBeacon-protokollen, som er en sekvens af 32 hexadecimale tegn",
    "menu_tippy_namespace_id": "Svarer til navnerums-id fra Eddystone-protokollen, som er en sekvens af 20 hexadecimale tegn",
    "menu_tippy_bbe9_major_minor": "Svarer til de 8 hexadecimale tegn, der er trykt på e-BBE9 Bluetooth-beacon",
    "menu_tippy_loc_ex_mac_address": "Svarer til MAC-adressen trykt på Bluetooth LOC-EX 01 beacon (6 par hexadecimale tegn adskilt af ':')",
    "menu_tippy_ibeacon_major_minor": "Svarer til sammenkædningen af Major + Minor fra iBeacon-protokollen, som er en sekvens af 8 hexadecimale tegn.",
    "menu_tippy_instance_id": "Svarer til instans-ID fra Eddystone-protokollen, som er en sekvens af 12 hexadecimale tegn",
    "menu_tippy_level": "0 for stueetagen, negativt tal for kælderetagen (ex: -3)",
    "menu_tippy_code_zone": "Oplysninger sendt via SIA-protokollen",
    "menu_tippy_description": "Placering i forbindelse med hotspottet. Det er forbudt at bruge tegnet '_', når muligheden for at sende beskeder til Emerit-ure er aktiveret på grund af en fejl (denne fejl vil blive rettet i næste opdatering)",
    "menu_err_ibeacon_company_id": "Forkert firma-id. Dette svarer til UUID. Den skal derfor bestå af 32 hexadecimale tegn.",
    "menu_err_ibeacon_beacon_id": "Forkert tag-id. Dette svarer til Major + Mol. Den skal derfor bestå af 8 hexadecimale tegn.",
    "menu_err_eddystone_company_id": "Forkert firma-id. Dette svarer til namespace ID. Den skal derfor bestå af 20 hexadecimale tegn.",
    "menu_err_eddystone_beacon_id": "Forkert tag-id. Dette svarer til instance ID. Den skal derfor bestå af 12 hexadecimale tegn.",
    "menu_err_bbe9_beacon_id": "Forkert tag-id. Dette svarer til de 8 hexadecimale tegn, der er trykt på e-BBE9 Bluetooth beacon.",
    "menu_err_bbe9_already_exist": "Der findes allerede et e-BBE9 Bluetooth-beacon med denne identifikator.",
    "menu_err_loc_ex_beacon_id": "Forkert beacon-id. Beacon-id'et svarer til den MAC-adresse, der er trykt på LOC-EX 01 Bluetooth-beacon.",
    "menu_err_loc_ex_already_exist": "Der findes allerede et Bluetooth LOC-EX 01 beacon med denne identifikator.",
    "menu_err_ibeacon_already_exist": "Der findes allerede et iBeacon Bluetooth-beacon med dette UUID og denne Major + Minor",
    "menu_err_eddy_already_exist": "Der findes allerede en Eddystone Bluetooth-beacon med dette navnerums-id og dette instans-id",
    "menu_err_wrong_level": "Forkert niveaunummer. Skal være et positivt eller negativt tal.",
    "menu_err_empty_desc": "Beskrivelsesfeltet må ikke være tomt.",
    "menu_err_wrong_code_zone": "Kodezonen skal være en sekvens af 3 tal mellem 001 og 999. Eksempel: 007.",
    "menu_level_precision_with_ble": "Håndtering af gulvændringer ved hjælp af Bluetooth-beacons",
    "menu_err_one_elem_missing_level": "Gulvet er ikke gået ind for alle Bluetooth-beacons. Tjek venligst den registrerede liste.",
    "menu_err_wifi_level_precision_is_activated": "Der er valgt forvaltning af gulvændringer ved hjælp af Bluetooth-beacons i Wifi indendørs placering. Denne parameter er obligatorisk.",
    "menu_err_no_level_beacon": "Mindst ét fyr med typen LEVEL er nødvendigt for at håndtere gulvskift.",
    "menu_enable_import_export_csv": "Import / eksport af .csv-fil",
    "menu_title_refresh_ble_choice": "Opdateringsfrekvens",
    "menu_scan_mode_fastest": "Opdatering ved regelmæssige intervaller (6 sekunder, højt forbrug)",
    "menu_scan_mode_average": "Opdatering ved regelmæssige intervaller (10 sekunder, moderat forbrug)",
    "menu_scan_mode_on_demand": "Opdater kun ved overførsel af en begivenhed",
    "menu_ble_new_algo_activation_title":"Algoritme konfiguration",
    "menu_ble_new_algo_activation":"Aktiver den nye algoritme til lokalisering af beacons"

  },
  "internalrecipients": {
    "menu_name_recipient": "Modtagernavn",
    "menu_duration_of_call_attempt_before_next_number": "Varigheden af alarmopkaldsforsøget, før der skiftes til det næste nummer",
    "menu_event_select_call_and_or_sip_messaging": "Vælg SIP-opkald og/eller besked",
    "menu_request_loc_with_sms_command": "Denne modtager kan anmode om placering ved hjælp af følgende SMS-kommando: ?LOC",
    "menu_sip_scenario": "Transmission af advarsler via SIP-konto",
    "menu_alert_configuration_not_available_with_pec": "Når advarselsscenariet 'SMS med bekræftelse og opkald' er aktiveret, udføres konfigurationen til modtagelse af advarsler automatisk.",
    "menu_sens_sms_with_sip_not_possible": "At sende SMS til et telefonnummer via en SIP-konto er ikke tilgængelig. Det er kun muligt at ringe.",
    "menu_err_sip_account_already_exist": "Der findes allerede en modtager med dette SIP-id",
    "menu_receive_events_by_sip_messaging": "Denne modtager modtager advarsler via SIP-besked",
    "menu_switch_watch_is_using_sip_account": "Denne modtager bruger en SIP-konto",
    "menu_recipient_added_with_success": "Modtager tilføjet",
    "menu_recipient_modified_with_success": "Modtager ændret",
    "menu_modify_recipient": "Rediger modtager",
    "menu_sip_account_id": "SIP ID",
    "menU_sip_servel_url": "SIP Server URL",
    "menu_icon_sms_desc": ": Denne modtager modtager SMS-beskeder",
    "menu_icon_phone_desc": ": Denne modtager modtager opkaldsalarmer",
    "menu_icon_watch_desc": ": Denne modtager er et emerit-ur",
    "menu_icon_sip_desc": ": Denne modtager bruger en SIP-konto",
    "menu_number_or_sip_id": "Opkaldsnummer / SIP ID",
    "menu_remove_all_recipients": "Er du sikker på, at du vil fjerne alle registrerede modtagere?",
    "menu_remove_one_recipient": "Er du sikker på, at du vil fjerne denne modtager?",
    "menu_add_recipient": "Tilføj modtager",
    "menu_sms_and_calls_cycle": "SMS og opkald falder sammen",
    "menu_sms_then_calls_cycle": "SMS kalder derefter cascade",
    "menu_sms_with_acknowledgment_then_call": "SMS med bekræft derefter ring",
    "menu_scenario_is": "Advarselsscenarie: ",
    "menu_settings": "Indstillinger",
    "menu_nb_recipients_saved": "Antal interne gemte modtagere: ",
    "menu_is_emerit_watch": "Denne modtager er et Emerit-ur",
    "menu_internal_recipients": "Interne modtagere",
    "menu_monitoring_center_call_numbers": "Kontrolcentral kaldenumre",
    "menu_recipient": "Modtager",
    "menu_enable_recipient": "Aktiver modtager",
    "menu_call_number": "Kaldenummer",
    "menu_enable_call_number": "Aktiver kaldenummer",
    "menu_name": "Navn",
    "menu_phone_number_international_format": "Telefonnummer (international format)",
    "menu_receive_alerts_by_call": "Denne modtager modtager advarsler ved opkald",
    "menu_receive_events_by_sms": "Denne modtager modtager SMS-beskeder",
    "menu_event_alerts": "Advarsler",
    "menu_event_system_status": "System status",
    "menu_event_charge_status": "Oplad batteriet",
    "menu_event_low_battery": "Lavt batteriniveau",
    "menu_event_beacon_ble_low_battery": "Bluetooth Beacon lavt batteri",
    "menu_event_select_call_and_or_sms": "Vælg opkald og/eller SMS",
    "menu_nfc_reading": "Læser NFC-tags"
  },
  "internalrecipientssettings": {
    "menu_internal_recipients_settings": "Indstillinger for interne modtagere",
    "menu_sms_language_selection": "Udvalg af SMS sprog",
    "menu_alert_scenario_type": "Advarsels scenarie type",
    "menu_sms_and_calls_cycle": "SMS og opkald cyklus",
    "menu_sms_then_calls_cycle": "SMS derefter opkald cyklus",
    "menu_sms_with_acknowledgment_then_call": "SMS med bekræftelse derefter opkald",
    "menu_maximum_waiting_time_for_acknowledgment_reception": "Maksimum ventetid på bekræftelse af modtagelse",
    "menu_call_cycle_stopped_by_sms": "Opkaldscyklus stoppet af SMS",
    "menu_show_sms_data_selection": "Vis SMS data udvalg",
    "menu_sms_data_selection": "SMS data udvalg",
    "menu_basic_data": "Basis data",
    "menu_gps_data": "GPS data",
    "menu_device_user": "Enhedens brugernavn",
    "menu_battery_level": "Batteriniveau",
    "menu_event_timestamp": "Hændelses tidsstempel",
    "menu_google_map_link": "Google Map link",
    "menu_timestamp": "Tidsstempel",
    "menu_adress_google_reverse_geocoding": "Adresse (Google reverse geocoding)",
    "menu_accuracy": "Nøjagtighed",
    "menu_coordinates_DMS_format": "Koordinater i DMS-format",
    "menu_coordinates_DD_format": "Koordinater i DD-format",
    "menu_alert_labels_customization": "Tilpasset alarmtekst",
    "menu_show_alert_labels_customization": "Vis tilpasset alarmtekst",
    "menu_request_for_help": "Anmodning om hjælp (standard)",
    "menu_assault": "Overfald (standard)",
    "menu_no_movement": "Ingen bevægelse (standard)",
    "menu_tilt": "Hældning (standard)"
  },
  "sipconfiguration": {
    "menu_title": "VOIP",
    "menu_sip_need_configuration": "Konfigurere VOIP",
    "menu_account_name": "Kontobeskrivelse",
    "menu_user_name": "Brugernavn",
    "menu_user_password": "Adgangskode",
    "menu_server_url": "Server url"
  },
  "ipserversettings": {
    "menu_std_gps_timestamp(O)": "[H] GPS position tidsstempel",
    "menu_extended_data_T2i_format_selection": "Udvidet datavalg i T2i©-format",
    "menu_T2i_format": "T2i© format",
    "menu_sia_protocol_settings": "SIA DC-09 protokolindstillinger",
    "menu_encapsulated_alarm_protol": "Indkapslet alarm protokol",
    "menu_admeco_cid_contact_id": "Ademco Contact ID (CID)",
    "menu_sia_dcs": "SIA DCS",
    "menu_extended_data_format_and_selection": "Udvidet data format og udvalg",
    "menu_standard_format": "Standard format",
    "menu_esi_format": "ESI© format",
    "menu_add_the_message_ending_timestamp": "Tilføj besked afslutning tidsstempel",
    "menu_extended_data_standard_format_selection": "Udvidet data standard format udvalg",
    "menu_extended_data_esi_format_selection": "Udvidet data ESI© format udvalg",
    "menu_std_battery": "[B] batteriniveau",
    "menu_std_event_timestamp": "[H] hændelses tidsstempel",
    "menu_std_imei": "[D] Enhedens IMEI-nummer",
    "menu_std_beacon_id": "[C] Wifi hotspot / Bluetooth beacon identifikator",
    "menu_std_gps_accuracy": "[M] GPS placering nøjagtighed",
    "menu_std_gps_timestamp": "[O] GPS placering tidsstempel",
    "menu_std_gps_speed": "[S] GPS placering hastighed",
    "menu_std_gps_validity": "[V] GPS placering gyldighed ",
    "menu_std_gps_altitude": "[Z] GPS placering højde",
    "menu_esi_battery_level": "$B batteriniveau",
    "menu_esi_gps_timestamp": "$T GPS placering tidsstempel",
    "menu_gps_coordinates_format": "GPS koordinationer format",
    "menu_gps_DD": "DD Decimal grader",
    "menu_gps_DDM": "DDM grader decimal minutter",
    "menu_customed_field": "Tilpasset felt",
    "menu_lpref": "Lpref",
    "menu_monitoging_server_sia_protocol_settings": "Kontrolcentral server SIA DC-09 protokol indstillinger",
    "menu_monitoring_center_server_sia_protocol_settings": "Overvågningsserver SIA DC-09 protokol indstillinger",
    "menu_location_maximum_age": "Placeringens maksimale alder",
    "menu_location_maximum_accuracy": "Maksimal lokaliseringsnøjagtighed"
  },
  "ipserver": {
    "menu_unit_description_message":"Beskeder",
    "menu_event_end_tracking_stop_post_alert":"Slut på tracking ved stop af post-advarsel",
    "menu_event_end_tracking_after_x_message":"Slut på tracking efter afsendelse af ",
    "menu_event_permanents":"Permanent",
    "menu_event_trigger_alert":"Ved udløsning af en alarm",
    "menu_enable_link_test":"Aktiver link test",
    "menu_link_test":"Link test",
    "menu_alarm_monitoring_center_server": "Server til alarmovervågning",
    "menu_enable_main_server": "Aktiver hovedserver",
    "menu_main_server": "Hovedserver",
    "menu_events_to_be_transmitted": "Hændelser, der skal transmitteres",
    "menu_client_id": "Kunde-ID",
    "menu_ip_url": "IP-adresse eller URL",
    "menu_port": "Portnummer",
    "menu_enable_cyclic_test": "Aktiver cyklisk test",
    "menu_cyclic_test": "Cyklisk test",
    "menu_test_interval": "Testinterval",
    "menu_backup_server": "Backup-server",
    "menu_backup_sms": "SMS-backup",
    "menu_enable_backup_server": "Aktiver backup-server",
    "menu_enable_backup_sms": "Aktiver SMS-backup",
    "menu_port_must_be_between_0_and_65536": "Portnummeret skal være mellem 0 og 65536"
  },
  "wearingways": {
    "menu_waist_worn": "båret på taljen",
    "menu_enable_no_movement_detection": "Aktiver ingen bevægelsesregistrering",
    "menu_enable_tilt_detection": "Aktiver hældningsregistrering",
    "menu_no_movement_detection": "Ingen bevægelse registrering",
    "menu_tilt_detection": "Hældning registrering",
    "menu_detection_duration_before_prealert": "Registreringsperiode før pre-advarsel",
    "menu_detection_threshold": "Registreringstærskel",
    "menu_detection_mode": "Registreringstilstand",
    "menu_tilt_only": "Hældning kun",
    "menu_tilt_without_movement": "Hældning uden bevægelse",
    "menu_select_a_wearing_way_and_modify_settings_if_needed": "Vælg bæringsmåde og ændre indstillingerne hvis nødvendigt"
  },
  "wristalert": {
    "menu_noise_mode": "Højtaler TIL > anmodning om hjælp",
    "menu_discret_mode": "Lydløs > overfaldskontekst",
    "menu_choose_alert_mode": "Advarselstilstand",
    "menu_title_wrist_alert": "Konfiguration af alarm ved hjælp af håndledsbevægelser",
    "menu_choose_threshold": "Bevægelsesintensitet for at udløse alarm",
    "menu_thresold_value_low": "Lav hurtigt",
    "menu_thresold_value_middle": "Mellem hurtigt (anbefales)",
    "menu_thresold_value_high": "Meget hurtigt"
  },
  "login": {
    "menu_first_device_boot_error": "Hvis dette er den første konfiguration af en enhed, er det nødvendigt at følge en idriftsættelsesprocedure, før den kan konfigureres.",
    "menu_quick_start_guide": "Online hjælp",
    "menu_home_help": "https://magneta.document360.io/v1/da",
    "menu_connexion_error": "Der er opstået et forbindelsesproblem. Tjek venligst din internetforbindelse.",
    "menu_enter_five_last_digits_of_serial_number": "Indtast de sidste 5 cifre i serienummeret (SN)",
    "menu_enter_five_last_digits_of_imei_number": "Indtast de sidste 5 cifre i IMEI nummeret",
    "menu_login": "Log ind",
    "menu_select_device_model": "Vælg modellen",
    "menu_please_select_the_device_model": "Vælg venligst først modellen",
    "menu_entered_numbers_dont_match_with_available_device": "De indtastede numre passer ikke med nogle enheder eller er der opstået en forbindelsesproblem.",
    "menu_please_read_quick_start_guide_first": "Læs venligst den hurtige start guide først.",
    "menu_configure_this_device": "Konfigurer denne enhed",
    "menu_configuration_models_management": "Konfigurationsskabelon administration",
    "menu_deploy_a_configuration_model": "Implementer en konfigurationsskabelon",
    "menu_invalid_file": "Ugyldig fil",
    "menu_only_file_with_emerit_extension_are_supported": "Kun filer med .emerit udvidelser er understøttet",
    "menu_create_configuration_model": "Skab konfigurationsskabelon",
    "menu_modify_a_configuration_model": "Tilpas en konfigurationsskabelon",
    "menu_drag_and_drop_file_or_click_to_select": "Træk og slip eller klik for at vælge konfigurationsskabelon filen",
    "menu_configuration_model": "Konfigurationsskabelon administration",
    "menu_configuration_model_creation": "Oprettelse af en konfigurationsskabelon",
    "menu_connect_to_a_device": "Opret forbindelse til en enhed",
    "menu_models_dont_match": "Den valgte fil er ikke kompatibel, eller konfigurationsskabelonen er ikke kompatibel med den valgte skabelon.",
    "menu_select_language": "Vælg sprog"
  },
  "wifi": {
    "menu_mode_utilisation": "Opdatering af tidszone i henhold til brugstilstand",
    "menu_select_utc": "Vælg en tidszone",
    "menu_error_utc_none": "Vælg venligst en tidszone",
    "menu_mode_wifi_alone": "Kun WiFi:",
    "menu_mode_gsm_plus_wifi": "WiFi + GSM (SIM-kort): automatisk opdatering",
    "menu_certificate_upload_success": "Certifikat uploadet med succes",
    "menu_certificate_upload_fail": "Download af certifikat mislykkedes",
    "menu_err_pls_upload_certificate": "Download venligst et certifikat",
    "menu_err_only_pfx_p12_accepted": "Kun certifikater i .pfx/.p12-format accepteres.",
    "menu_err_certificate_is_too_heavy": "Certifikatets størrelse er for stor, det må ikke overstige 5 MB.",
    "menu_upload_certificat": "Importer PEAP-certifikatet (.pfx/.p12)",
    "menu_certificate_password": "Adgangskode",
    "menu_certificate_identifiant": "Brugernavn",
    "menu_certificate_type": "CA Certificat",
    "menu_name_is_update_app_by_wifi_enabled": "Hent konfigurationer og installer kun opdateringer, hvis wifi er aktivt",
    "menu_use_wifi_connection": "Brug Wifi forbindelse",
    "menu_config_wifi_network": "Administrer Wifi-netværk",
    "menu_nb_wifi_network_saved": "Antal gemte WiFi-netværk: ",
    "menu_send_alert_with_wifi": "Wifi-netværket, hvis det er tilgængeligt, vil blive prioriteret over GSM-netværket",
    "menu_network_added_with_success": "Wifi-netværk tilføjet",
    "menu_network_modified_with_success": "Wifi-netværk ændret",
    "menu_remove_all_networks": "Er du sikker på, at du vil fjerne alle gemte WiFi-netværk?",
    "menu_remove_one_network": "Er du sikker på, at du vil fjerne dette WiFi-netværk?",
    "menu_registred_wifi_networks_list": "Registeret Wifi netværksliste - kun 2.4 GHz",
    "menu_ssid": "Netværksnavn SSID",
    "menu_security_type": "Sikkerhedstype",
    "menu_password": "Kodeord",
    "menu_add_a_wifi_network": "Tilføj et Wifi netværk",
    "menu_modify_a_wifi_network": "Rediger et Wifi-netværk",
    "menu_do_you_want_to_remove_this_network": "Vil du fjerne dette netværk?",
    "menu_please_select_security_type": "Vælg venligst en sikkerhedstype",
    "menu_hidden": "Skjult netværk",
    "menu_network_already_exists": "Netværk eksistere allerede"
  },
  "whitelist": {
    "menu_add_a_recipient_whitelist": "Tilføj yderligere indgående nummer",
    "menu_modify_a_recipient_whitelist": "Rediger yderligere indgående nummer",
    "menu_recipient_whitelist_modified_with_success": "Yderligere indgående nummer blev ændret",
    "menu_recipient_whitelist_added_with_success": "Yderligere indgående nummer blev tilføjet",
    "menu_nb_recipient_whitelist": "Yderligere indgående numre: ",
    "menu_nb_recipient_mirror": "Advarselsmodtagere: ",
    "menu_remove_all_recipient_whitelist": "Er du sikker på, at du vil fjerne alle yderligere indgående numre fra hvidlisten?",
    "menu_remove_one_recipient_whitelist": "Er du sikker på, at du vil fjerne dette ekstra indgående nummer fra hvidlisten?",
    "menu_add_whitelist_recipient": "Tilføj indgående nummer",
    "menu_nb_total_whitelist_recipient": "Samlet antal indgående numre tilladt på hvidlisten: ",
    "menu_whitelist_title": "Konfiguration af hvidliste"
  },
  "customscreens": {
    "menu_img_upload_info": "Størrelsen af ​​et .png/.gif-billede må ikke overstige 1 MB. Urets skærmopløsning er 128x96 pixels. For optimal gengivelse af billedet skal du respektere højden/skærmbredden (1,33). Hvis forholdet ikke respekteres, ændres billedet automatisk.",
    "menu_err_pls_upload_launch_screen": "Upload venligst et billede.",
    "menu_err_pls_upload_widget_screen": "Upload venligst et billede eller indtast tekst.",
    "menu_err_only_png_gif_accepted": "Kun billeder i .png/.gif-format accepteres.",
    "menu_err_img_is_too_heavy": "Billedstørrelsen er for stor, den må ikke overstige 1MB.",
    "menu_img_upload_success": "Billedet blev uploadet",
    "menu_img_upload_fail": "Kunne ikke uploade billede",
    "menu_custom_screens_overview": "Preview",
    "menu_remove_launch_img": "Fjern billede",
    "menu_remove_widget_img": "Fjern baggrundsbillede",
    "menu_display_widget_text_in_black": "Vis tekst i sort",
    "menu_custom_widget_top_text": "Toptekst (maks. 18 tegn inklusive mellemrum)",
    "menu_custom_widget_bottom_text": "Bundtekst (maks. 18 tegn inklusive mellemrum)",
    "menu_upload_launch_screen_img": "Download nyt billede (.png/.gif)",
    "menu_upload_widget_screen_img": "Download nyt baggrundsbillede (.png/.gif)",
    "menu_title_yes_custom_launch_screen": "Start billedopsætning",
    "menu_title_no_custom_launch_screen": "Tilpas startskærm",
    "menu_title_yes_custom_widget_screen": "Kontakt widget opsætning",
    "menu_title_no_custom_widget_screen": "Tilføj kontakt-widget",
    "menu_custom_screens_title": "Tilpas skærme"
  },
  "password": {
    "menu_create_new_password_for_model": "Indtast en adgangskode på 6 tegn inklusive mindst 1 lille bogstav, 1 stor bogstav og 1 tal, uden specialtegn. Denne adgangskode vil blive anmodet hver gang denne model ændres.",
    "menu_enter_password": "Indtast kodeordet",
    "menu_enter_deployment_password": "Intast installations adgangskode",
    "menu_create_new_password": "Indtast et 6 cifret kodeord med mindst 1 stort bogstav, 1 lille bogstav og 1 nummer. Ingen special tegn. Dette kodeord vil blive påkrævet hver gang du forbinder til enheden",
    "menu_confirm_password": "Bekræft kodeord",
    "menu_wrong_password": "Forkert kodeord",
    "menu_please_retry": "Prøv igen",
    "menu_empty_password": "Tomt kodeord",
    "menu_passwords_dont_match": "Kodeorderne er ikke ens",
    "menu_at_least_one_password_is_empty": "Mindst et af kodeordrene er tomme",
    "menu_password_dont_meet_requirements": "Kodeordet opfylder ikke kravene",
    "menu_modify_password": "Tilpas kodeordet",
    "menu_password_creation_warning": "Ved at logge ind på emerit-console og efter at have skabt et kodeord, bliver du de facto administrator af enheden, som er identificeret ovenover "
  },
  "contact": {
    "menu_contact_link": "https://www.magneta.fr/en/contact/",
    "menu_if_you_have_any_question_please_click_on_the_folowing_button": "Klik på den følgende knap, hvis du har nogle spørgsmål omkring konfigureringen af enheden eller brug af enheden",
    "menu_technical_support_email": "Teknisk support email:",
    "menu_online_support_request_form": "Online support anmodningsform"
  },
  "deploy": {
    "menu_upload_failed": "Implementeringen mislykkedes, tjek internetforbindelsen og prøv igen",
    "menu_device_updated": "Enhed opdateret",
    "menu_fill_server_ids": "Konfiguration af klient-id'er",
    "menu_fill_personal_fields": "Udfyld dataene for at tilpasse",
    "menu_target_a_device": "Enhed der skal konfigureres",
    "menu_client_id_for_tls_main_server": "Klient ID for kontrolcentrals hovedserver",
    "menu_client_id_for_tls_backup_server": "Klient ID for kontrolcentrals backup server",
    "menu_client_id_for_supervision_main_server": "Klient ID for hovedserveren for overvågning",
    "menu_client_id_for_supervision_backup_server": "Klient ID for backupserveren for overvågning",
    "menu_from_a_configuration_model": "Fra konfigurationsskabelon",
    "menu_five_last_digits_of_serial_number": "5 sidste cifre af serienummeret (SN)",
    "menu_five_last_digits_of_imei_number": "5 sidste cifre af IMEI nummeret",
    "menu_deploy_not_allowed": "Du har nu tilladelse til at konfiguere denne enhed ved installation, installations kodeord matcher ikke"
  },
  "side_menu": {
    "configuration": "Konfiguration",
    "info": "Manualer",
    "contact": "Kontakt"
  },
  "watchhelp": {
    "menu_custom_screens_view": "https://www.support.magneta.fr/docs/en/watch-system-options#ii-customize-screen",
    "menu_locex_help": "https://www.support.magneta.fr/docs/en/beacon-loc-ex-01",
    "menu_wifi_connection_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#i-use-wifi-connection",
    "menu_sip_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#ii-activate-voice-over-ip-voip-with-sip-account",
    "menu_whitelist_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#iii-whitelist-incoming-call-limitation",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/en/watch-main-page#i-device-user-name",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/en/watch-main-page#ii-alerts-processing",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/en/watch-main-page#iii-alerts-triggering-mode",
    "menu_loc": "https://www.support.magneta.fr/docs/en/watch-main-page#iv-alerts-localization",
    "menu_communication_option": "https://www.support.magneta.fr/docs/en/watch-main-page#v-communication-options",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/en/watch-main-page#vi-battery-monitoring",
    "menu_device_notif_management": "https://www.support.magneta.fr/docs/en/watch-main-page#vii-device-notification-management",
    "menu_system_option": "https://www.support.magneta.fr/docs/en/watch-main-page#viii-system-options",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9#associating-a-type-to-beacons",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#configuring-personal-settings",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#entering-the-deployment-password",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#templates-modification",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#configuration-template-creation",
    "menu_model_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#ii-multidevice-setup",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#iii-deployment-of-a-configuration",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#enter-password",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#password-creation",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#i-gps-outdoor-location",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#ii-wifi-indoor-location",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#iii-bluetooth-indoor-location",
    "menu_main_page": "https://www.support.magneta.fr/docs/en/watch-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/en/watch-commissioning#iv-first-boot"
  },
  "phonehelp": {
    "menu_locex_help": "https://www.support.magneta.fr/docs/en/beacon-loc-ex-01",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/en/smartphones-main-page#i-device-user-name",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/en/smartphones-main-page#ii-alerts-processing",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/en/smartphones-main-page#iii-alerts-triggering-mode",
    "menu_loc": "https://www.support.magneta.fr/docs/en/smartphones-main-page#iv-alerts-localization",
    "menu_communication_option": "https://www.support.magneta.fr/docs/en/smartphones-main-page#v-communication-options",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/en/smartphones-main-page#vi-battery-monitoring",
    "menu_deactivate_alert_system": "https://www.support.magneta.fr/docs/en/smartphones-main-page#vii-alert-system-deactivation-by-the-user",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9#associating-a-type-to-beacons",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#configuring-personal-settings",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#entering-the-deployment-password",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#templates-modification",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#configuration-template-creation",
    "menu_model_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#ii-multidevice-setup",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#iii-deployment-of-a-configuration",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#enter-password",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#password-creation",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#i-gps-outdoor-location",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#ii-wifi-indoor-location",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#iii-bluetooth-indoor-location",
    "menu_main_page": "https://www.support.magneta.fr/docs/en/smartphones-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/en/smartphones-commissioning#ii-first-boot"
  }
}
