<template>
  <!-- BEGIN: CustomToast component-->
  <div 
    :id="toastId" 
    class="toastify-content hidden flex"
  >
    <div class="w-4 h-4">
      <component
        :is="iconComponentName"
        :class="iconComponentClass"
      />
    </div>
    <div>
      <div class="ml-4 mr-4 font-medium">
        {{ $t(text) }} 
        <a 
          v-if="helpLink" 
          class="text-blue-700 no-underline hover:underline"
          :href="helpLink"
          target="_blank"
        >{{ $t(helpText) }}</a>
      </div>
    </div>
  </div>
  <!-- END: CustomToast component-->
</template>

<script>
import { defineComponent } from 'vue'
import Toastify from "toastify-js";


/**
 * -------------------------- DESCRIPTION ---------------------------
 * A toast with an icon and a text that will be shown to the user for 
 * a few seconds, and then disappears.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} toastId
 *    The id of the toast. Should be unique.
 * 
 * @param {String} iconComponentName
 *    Name of the icon to load for the dynamic icon component. 
 *    Ex: 'XCircleIcon'.
 * 
 * @param {String} iconComponentClass
 *    The class used to style the dynamic icon component. Usefull for
 *    changing icon's color (ex: 'text-theme-6).
 * 
 * @param {String} text
 *    The toast's text. Should be key for i18 locale.
 * 
 * @param {String | undefined} helpText
 *    Optional props. Additional text which when clicked, open a new page
 *    using helpLink props URL. Should be key for i18 locale. Undefined 
 *    by default.
 * 
 * @param {String | undefined} helpLink
 *    Optional props. When helpText props will be clicked, a new page
 *    will be opened in the browser using this props URL. Undefined 
 *    by default. 
 * 
 * ---------------- METHODS ACCESSIBLE USING $REFS -------------------
* @method trigger
 * @param void
 *    Creates and shows the toast to the user for a few seconds.
 *              
 * ------------------------------------------------------------------
 */
export default defineComponent({

  props: {
    toastId: {
      type: String,
      required: true,
    },
    iconComponentName: {
      type: String,
      required: true,
    },
    iconComponentClass: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    helpText: {
      type: String,
      default: undefined,
    },
    helpLink: {
      type: String,
      default: undefined,
    }
  },

  methods: {
    trigger() {
      Toastify({
        node: cash('#' + this.toastId)
          .clone()
          .removeClass("hidden")[0],
        duration: 10000,
        newWindow: true,
        close: true,
        gravity: "left",
        position: "right",
        stopOnFocus: true
      }).showToast();
    },
  }
})
</script>
