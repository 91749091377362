<template>
  <!-- BEGIN: ListElementPreviewOnly component -->
  <tr>
    <td 
      v-for="(elem, index) in elemsText"
      :key="index"
      class="font-medium whitespace-nowrap" 
    > 
      {{ elem }}
    </td>
    <td 
      v-if="icons" 
      class="flex flex-row-reverse"
    >
      <component 
        :is="icon"
        v-for="(icon, index) in icons"
        :key="index"
        class="mr-3"
        style="box-shadow: 0px 0px white;"
      />
    </td>
  </tr>
  <!-- END: ListElementPreviewOnly component -->
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A list element for a preview only table, with several text field 
 * prompted to the user. It is not possible to modify or remove this element.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {Array[X String]} elemsText
 *    The different elements filling a line of the table.
 * 
 * @param {Array[X String]} icons
 *    An array of Strings containing name of dynamic icons components. Icons
 *    will be rendered at the right of elemsText fields description. Undefined
 *    by default, if you do not need icons do not fill this props.
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  props: {
    elemsText: {
      type: Array,
      required: true,
    },
    icons: {
      type: Array,
      default: undefined,
    },
  },
  
})
</script>
