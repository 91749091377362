import AWS from "aws-sdk";
import { STORE_SAVE_SECRETS } from "../store/const_store";
import { useStore } from "@/store";

const secretsManager = new AWS.SecretsManager({
  accessKeyId: "AKIAY3CKHRNQP4STRKO4",
  secretAccessKey: "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",
  region: "eu-west-3"
});

export function fetchSecrets() {
  getSecret("emeritConsole/prod/secrets")
    .then(secretValue => {
      const secretObject = JSON.parse(secretValue);
      useStore().commit(STORE_SAVE_SECRETS, secretObject);
      //console.log(secretObject);
    })
    .catch(error => {
      console.log("fetchSecrets error : " + error);
    });
}

function getSecret(secretName) {
  return new Promise((resolve, reject) => {
    secretsManager.getSecretValue(
      { SecretId: secretName },
      (error, secretValue) => {
        if (error) {
          reject(error);
        }

        if ("SecretString" in secretValue) {
          resolve(secretValue.SecretString);
        }
      }
    );
  });
}
