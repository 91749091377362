<template>
  <!-- BEGIN: CheckBoxAndXTextInputs component-->
  <div class="grid grid-cols-12 gap-6 mt-6">
    <div class="intro-y col-span-12 lg:col-span-6">
      <div class="clearfix box">
        <CheckBoxTitle
          v-model="isActivated"
          :color="checkBoxColor"
          :title-yes="checkBoxTitleYes"
          :title-no="checkBoxTitleNo"
        />
        <div v-if="isActivated">
          <div class="clearfix flex-row p-3 border-b border-gray-200">
            <CustomTextInput
              v-for="(field, index) in inputFieldsParams"
              :key="index"
              v-model="inputFieldsValue[index]"
              class="mb-3"
              :title="field.title"
              :error-msg="field.errorMsg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: CheckBoxAndXTextInputs component-->
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A checkbox with x text input fields, which will be shown if the 
 * checkbox is checked. Each field has its own title and owm error 
 * message in case the input is wrong.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {Boolean} checkBoxIsActivated
 *    Boolean representing the state of the checkbox. If true, input
 *    fields will appear to the user, otherwise they will be hidden.
 * 
 * @param {String} checkBoxColor
 *    The class representing the background color of the checkbox part 
 *    for this component.
 * 
 * @param {String} checkBoxTitleYes
 *    The title of this component when the checkbox is checked.
 *    Should be i18 key for the locale (ex: 'common.menu_save').
 * 
 * @param {String} checkBoxTitleNo
 *    The title of this component when the checkbox is not checked.
 *    Should be i18 key for the locale (ex: 'common.menu_save').
 * 
 * @param {Array[x Object]} inputFieldsParams
 *    An array of x objects reprensenting text input fields. Object 
 *    should be the following: 
 * 
 *    inputFieldsParams: {
 *      title: 'sip.accountName',  // Key for i18 locale.
 *      value: 'Lucas',            // Text shown to the user before he modifies it.
 *      errorMsg: '',              // If empty, nothing appears. Otherwise, this string 
 *    }                               will appear as an error in red to the user.
 *                                    Should be key for i18 locale.
 * 
 * ---------------- METHODS ACCESSIBLE USING $REFS ------------------
 * @method getData
 * @param void
 * @return {Boolean, Array[x String]}
 *    Returns an object containing the state of the checkbox and the data 
 *    from the x text input fields. The fields can be filled or empty. 
 *    A data check should be performed on this object, and if one of 
 *    the inputs is wrong, the prop inputFieldsParams[i].errorMsg should be set 
 *    with the appropriate error message to inform the user.
 * 
 *    Object return is the following:
 *    obj: {
 *      isActivated: false            // Boolean representing the state of the checkbox
 *      inputFieldsValue: String[x]   // Array of x String, each string is the value
 *    }                                  of one text input field.
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({

  name: 'CheckBoxAndXTextInputs',
  
  props: {
    // eslint-disable-next-line
    checkBoxIsActivated: {
      required: true,
    },
    checkBoxColor: {
      type: String,
      required: true,
    },
    checkBoxTitleYes: {
      type: String,
      required: true,
    },
    checkBoxTitleNo: {
      type: String,
      required: true,
    },
    inputFieldsParams: {
      type: Array,
      required: true,
    },
  },
  
  data() {
    return {
      isActivated: this.checkBoxIsActivated,
      inputFieldsValue: new Array(this.inputFieldsParams.length),
    }
  },
  
  created() {
    for(let i = 0; i < this.inputFieldsParams.length; ++i)
      this.inputFieldsValue[i] = this.inputFieldsParams[i].value; 
  },

  methods: {
    getData() {
      return {
          isActivated: this.isActivated,
          inputFieldsValue: this.inputFieldsValue
      };
    }
  }
})
</script>
