<template>
  <div>
    <div class="sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <LoginInfo :image-source="imageSource" />
        <div class="h-screen xl:h-auto flex  py-5 xl:py-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 "
          >
            <DeviceInfo />
            <div class=" flex-row items-center border rounded p-5 ">
              <div v-if="isFirstConnection" class="alert alert-danger-soft show  items-center mb-5" role="alert">
                <AlertOctagonIcon class="w-6 h-6 mr-2" />
                {{ $t("password.menu_password_creation_warning") }}
              </div>
              <CustomTextInput v-model="deviceCred" :title="passwordCreationText" :is-password-field="true" :max-length="6" @input="errorMsg = ''" />
              <CustomTextInput
                v-model="deviceCredConf"
                title="password.menu_confirm_password"
                :is-password-field="true"
                :max-length="6"
                class="mt-2"
                :error-msg="errorMsg"
                @input="errorMsg = ''"
              />
            </div>
            <div class="flex flex-col items-center mt-5">
              <CustomButton custom-class="btn btn-primary w-full" text="common.menu_save" @click="onpasswordbuttonclick" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { getParameterPTIValue } from "../../dao/parametre-pti";
import { getCredValue, setCredValue } from "../../dao/cred-dao";
import { useStore } from "@/store";
import { setOptionsValue } from "../../dao/options-dao";
import { PATH_MAIN_CONFIG, PATH_MODEL_LOGIN, PATH_PASSWORD } from "../../utils/const_path";
import { STORE_UPDATE_LOGIN_STATUS } from "../../store/const_store";

const I18_ERR_ONE_PASSWORD_IS_EMPTY = "password.menu_at_least_one_password_is_empty";
const I18_ERR_PASSWORDS_DONT_MATCH = "password.menu_passwords_dont_match";
const I18_ERR_PASSWORD_DONT_MEET_REQUIREMENT = "password.menu_password_dont_meet_requirements";

const I18_CREATE_MODEL_PASSWORD = "password.menu_create_new_password_for_model";
const I18_CREATE_DEVICE_PASSWORD = "password.menu_create_new_password";

const PARAM_PTI_IDENTIFIANT = "Identifiant";
const PARAM_LANGUAGE_CODE = "LanguageCode";
const LANGUAGES_SUPPORTED = ["fr", "en", "es"];
const DEFAULT_LANGUAGE = "en";

export default defineComponent({
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });
  },

  data() {
    return {
      errorMsg: "",
      deviceCred: "",
      deviceCredConf: "",
      isFirstConnection: false,
      imageSource: null,
      passwordCreationText: I18_CREATE_DEVICE_PASSWORD
    };
  },

  created() {
    this.imageSource = useStore().state.emeritValues.modelImageSource;
    const isModelLogin = useStore().state.emeritValues.disconnectionPath.valueOf() === PATH_MODEL_LOGIN;
    if (getCredValue() === useStore().state.emeritValues.secrets.initialCred && !isModelLogin) this.isFirstConnection = true;
    if (isModelLogin) this.passwordCreationText = I18_CREATE_MODEL_PASSWORD;
  },

  methods: {
    onpasswordbuttonclick() {
      const ADMIN_PASSWORD = useStore().state.emeritValues.secrets.adminPassword;
      if (!this.checkPasswords()) return;
      this.$store.commit(STORE_UPDATE_LOGIN_STATUS, true);
      if (this.deviceCred !== ADMIN_PASSWORD && this.deviceCredConf !== ADMIN_PASSWORD) {
        setCredValue(this.deviceCredConf);
        if (getParameterPTIValue(PARAM_PTI_IDENTIFIANT) === "") this.setSmsLanguageForFirstLogin();
        this.$router.push(PATH_PASSWORD);
      } else if (this.deviceCred === ADMIN_PASSWORD && this.deviceCredConf === ADMIN_PASSWORD) {
        this.$router.push(PATH_MAIN_CONFIG);
      }
    },

    checkPasswords() {
      const regex = new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6}$");
      if (this.deviceCred === "" || this.deviceCredConf === "") this.errorMsg = I18_ERR_ONE_PASSWORD_IS_EMPTY;
      else if (this.deviceCred !== this.deviceCredConf) this.errorMsg = I18_ERR_PASSWORDS_DONT_MATCH;
      else if (!regex.test(this.deviceCred)) this.errorMsg = I18_ERR_PASSWORD_DONT_MEET_REQUIREMENT;
      return !this.errorMsg.length;
    },

    setSmsLanguageForFirstLogin() {
      for (let i = 0; i < LANGUAGES_SUPPORTED.length; ++i) {
        if (this.$i18n.locale === LANGUAGES_SUPPORTED[i]) {
          setOptionsValue(PARAM_LANGUAGE_CODE, this.$i18n.locale);
          return;
        }
      }
      setOptionsValue(PARAM_LANGUAGE_CODE, DEFAULT_LANGUAGE);
    }
  }
});
</script>
