<template>
  <div>
    <Title
      title="customscreens.menu_custom_screens_title"
      help-locale="menu_custom_screens_view"
    />
  </div>
  <div class="grid grid-cols-12 gap-6 mt-6">
    <div class="font-bold  intro-y col-span-12 lg:col-span-6 2xl:col-span-4 col-start-1">
      {{ $t('customscreens.menu_img_upload_info') }}
    </div>
    <CustomLaunchScreen 
      ref="CustomLaunchScreen"
      class="intro-y col-span-12 lg:col-span-6 2xl:col-span-4 col-start-1 lg:col-start-1 2xl:col-start-1"
      @upload-success="$refs.ToastImgUploadSuccess.trigger()"
      @upload-fail="$refs.ToastImgUploadFail.trigger()"
    />
    <CustomWidgetScreen
      ref="CustomWidgetScreen"
      class="intro-y col-span-12 lg:col-span-6 2xl:col-span-4 lg:col-start-1 2xl:col-start-1"
      @upload-success="$refs.ToastImgUploadSuccess.trigger()"
      @upload-fail="$refs.ToastImgUploadFail.trigger()"
    />
  </div>
  <CustomButton
    text="common.menu_save"
    @click="check()"
  />
  <CustomToast
    ref="ToastImgUploadSuccess"
    toast-id="toast-upload-img-success"
    icon-component-name="CheckCircleIcon"
    icon-component-class="text-theme-9"
    text="customscreens.menu_img_upload_success"
  />
  <CustomToast
    ref="ToastImgUploadFail"
    toast-id="toast-upload-img-fail"
    icon-component-name="XCircleIcon"
    icon-component-class="text-theme-6"
    text="customscreens.menu_img_upload_fail"
  />
  <CustomToast
    ref="ToastWrongConfig"
    toast-id="toast-wrong-config"
    icon-component-name="XCircleIcon"
    icon-component-class="text-theme-6"
    text="common.menu_wrong_config"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { PATH_MAIN_CONFIG } from "../../utils/const_path";
import CustomLaunchScreen from './local-components/lc-custom-launch-screen.vue'
import CustomWidgetScreen from './local-components/lc-custom-widget-screen.vue'


export default defineComponent({
  
  components: {
    CustomLaunchScreen,
    CustomWidgetScreen,
  },
  
  methods: {
    check() {
      let error = 0;
      error += !this.$refs.CustomLaunchScreen.check();
      error += !this.$refs.CustomWidgetScreen.check();
      if (error) {
        this.$refs.ToastWrongConfig.trigger();
        return ;
      }
      this.$refs.CustomLaunchScreen.save();
      this.$refs.CustomWidgetScreen.save();
      this.$router.push(PATH_MAIN_CONFIG);
    },
  }
  
})
</script>
