<template>
  <!-- BEGIN: GpsSettings local component-->
  <div class="grid grid-cols-12 gap-6 mt-6">
    <div class="intro-y col-span-12 lg:col-span-6">
      <BoxTitle
        color="bg-red-300"
        title="locationoutdoor.menu_gps_based"
      />
      <div class="clearfix flex-row p-3 border-b border-gray-200 box">
        <CustomRadioInput
          v-for="(radio, index) in radioInputParams"
          :key="index"
          v-model="gpsRefreshMode"
          :custom-id="radioGroupName + index"
          :description="radio.description"
          :radio-group-name="radioGroupName"
          :value="index"
        />
        <CustomRangeInput
          v-if="gpsRefreshMode == 1"
          v-model="gpsRefreshValue"
          title="locationoutdoor.menu_automatic_refresh_interval"
          unit-description="common.menu_seconds"
          :min-value="gpsRefreshRange.minValue"
          :max-value="gpsRefreshRange.maxValue"
          :step-value="gpsRefreshRange.stepValue"
        />
      </div>
    </div>
  </div>
  <!-- END: GpsSettings local component-->
</template>

<script>
import { defineComponent } from 'vue'
import {
  getParameterPTIValue,
  setParameterPTIValue
} from "../../../dao/parametre-pti";

export default defineComponent({

  name: 'LcGpsSettings',

  data() {
    return {
      gpsRefreshMode: undefined,
      radioGroupName: 'radioOutdoor',
      radioInputParams: [
        { description: 'locationoutdoor.menu_refreseh_only_when_event_sending' },
        { description: 'locationoutdoor.menu_update_at_regular_intervals' },
        ],
      gpsRefreshValue: undefined,
      gpsRefreshRange: { minValue: 30, maxValue: 600, stepValue: 10 },
    }
  },

  created() {
    this.gpsRefreshMode = getParameterPTIValue("isGpsLocRefreshUpdateEnabled");
    this.gpsRefreshValue = getParameterPTIValue("GpsLocRefreshDelayInSeconds");
  },

  mounted() {
    document.getElementById(this.radioGroupName + this.gpsRefreshMode).checked = true;
  },

  methods: {

    save() {
      setParameterPTIValue("isGpsLocRefreshUpdateEnabled", this.gpsRefreshMode);
      if (this.gpsRefreshMode == 1)
        setParameterPTIValue("GpsLocRefreshDelayInSeconds", this.gpsRefreshValue);
      return true;
    }
  },

})
</script>
