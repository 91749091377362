import { useStore } from "@/store";

export const getOptionsValue = parameter => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = 'select * from options where param = "' + parameter + '"';
  try {
    return dbFile.exec(sqlScript)[0].values[0][2];
  } catch (error) {
    console.log('getOptionsValue failed: ' + error);
  }
};

export const getTargetDeviceOptionsValue = parameter => {
  var dbFile = useStore().state.emeritValues.targetDbFile;
  let sqlScript = 'select * from options where param = "' + parameter + '"';
  try {
    return dbFile.exec(sqlScript)[0].values[0][2];
  } catch (error) {
    console.log("getTargetDeviceOptionsValue failed: " + error);
  }
};

export const setOptionsValue = (parameter, value) => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'update options set valeur = "' +
    value +
    '" where param="' +
    parameter +
    '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("setOptionsValue failed: " + error);
  }
};

export const setTargetDeviceOptionsValue = (parameter, value) => {
  var dbFile = useStore().state.emeritValues.targetDbFile;
  let sqlScript =
    'update options set valeur = "' +
    value +
    '" where param="' +
    parameter +
    '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("setTargetDeviceOptionsValue failed: " + error);
  }
};
