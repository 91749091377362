/* eslint-disable */

<template>
  <div>
    <div class="grid grid-cols-12 gap-6 ">
      <!-- BEGIN: left column -->
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: notification préalerte -->
        <div v-if="showPreAlert" class="clearfix box mt-6">
          <!-- entete de parametre -->
          <div
            class="flex flex-raw sm:flex-row pt-3 p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div class="form-check w-full">
              <CustomSwitch
                v-model="isButtonSwitchPre"
                class="pr-3 show-code"
                text="common.menu_notification_prealert"
              /> 
            </div>
          </div>
          <!-- BEGUIN: bloc parametres -->
          <div>
            <!-- BEGUIN: parametre range slider -->
            <div v-if="isButtonSwitchPre==1"
            class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("common.menu_notification_duration") }}
              </label>
              <div class="input-group pb-1 mt-2">
                <input
                  v-model="sosNotificationDurationRange"
                  type="range"
                  :min="sosNotificationDurationMin"
                  :max="sosNotificationDurationMax"
                  step="1"
                />
                <div class="form-control w-20 text-center ml-5">
                  {{ sosNotificationDurationRange }}
                </div>
                <div id="input-group-3" class="input-group-text h-10 font-sm">
                  {{ $t("common.menu_seconds") }}
                </div>
              </div>
              <div class="flex flex-col">
                <div class="form-check mt-3 mr-3 ">
                  <input
                    id="checkbox-switch-4"
                    v-model="isButtonNotificationVibration"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-4">
                    {{ $t("common.menu_notification_by_vibration") }}
                  </label>
                </div>
                <div class="form-check mt-3 mr-3">
                  <input
                    id="checkbox-switch-5"
                    v-model="isButtonNotificationSound"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-5">
                    {{ $t("common.menu_notification_by_sound") }}
                  </label>
                </div>
            </div>
            <!-- END: parametre range slider -->
          </div>
                 <!-- END: bloc parametres -->
        </div>
      </div>
      <div class="intro-y col-span-12 lg:col-span-6">
               <!-- BEGIN: mode d'alerte -->
        <div class="clearfix box mt-6">
          <!-- entete de parametre -->
          <div
            class="flex flex-col sm:flex-row  p-3 bg-red-300 border-gray-200 dark:border-dark-5 mt-6 rounded-t"
          >
            <h2 class="font-medium text-base  mr-auto">
              {{ $t("alerttrigerring.menu_cycle_destinataires_télésurveillance") }}
            </h2>
          </div>
          <!-- BEGUIN: bloc parametres -->
          <div>
            <!-- BEGUIN: parametre avec radio button -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <div>
                <div class="form-check">
                  <input
                    id="radiobutton3"
                    v-model="isButtonloudSpeakerPre"
                    class="form-check-input"
                    type="radio"
                    value="1"
                    name="radio-bidirectionnelle-pre"
                  />
                  <label class="form-check-label" for="radiobutton3">
                    {{ $t("alerttrigerring.menu_communication_bidirectionnelle") }}
                  </label>
                </div>
                <div class="form-check mt-3">
                  <input
                    id="radiobutton4"
                    v-model="isButtonloudSpeakerPre"
                    class="form-check-input"
                    type="radio"
                    value="2"
                    name="radio-unidirectionnelle-pre"
                  />
                  <label class="form-check-label" for="radiobutton4">
                    {{ $t("alerttrigerring.menu_communication_unidirectionnelle") }}
                  </label>
                </div>
              </div>
            </div>
            <!-- END: parametre avec radio button -->
          </div>
          <!-- END: bloc parametres -->
        </div>
        <!-- END: mode d'alerte -->
      </div>
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: notification postalerte -->
        <div v-if="showPreAlert" class="clearfix box mt-6">
          <!-- entete de parametre -->
          <div
            class="flex flex-raw sm:flex-row pt-3 p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div class="form-check w-full">

              <CustomSwitch
                v-model="isButtonSwitchPost"
                text="common.menu_post_alert"
                class="pr-3 show-code"
              /> 
          </div>
          </div>
          <!-- BEGUIN: bloc parametres -->
          <div>
            <!-- BEGUIN: parametre range slider -->
            <div v-if="isButtonSwitchPost==1"
            class="clearfix flex-row p-3 border-b border-gray-200">
            <div class="form-check mt-3 mr-3 ">
                  <input
                    id="checkbox-switch-7"
                    v-model="isButtonVibrationSeconde"
                    class="p-1 mt-1"
                    type="checkbox"
                    :disabled="true"
                  />
                  <label class="form-check-label" for="checkbox-switch-7">
                    {{ $t("common.menu_vibration_5_secondes") }}
                  </label>
                </div>
              <div class="flex flex-col">
                <div class="form-check mt-3 mr-3 ">
                  <input
                    id="checkbox-switch-8"
                    v-model="isButtonAffichagePost"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-8">
                    {{ $t("common.menu_affichage") }}
                  </label>
                </div>
              </div>
                <div class="form-check mt-3 mr-3">
                  <input
                    id="checkbox-switch-9"
                    v-model="isButtonSoundPost"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-9">
                    {{ $t("common.menu_son") }}
                  </label>
                </div>
                  <!-- BEGUIN: parametre avec radio button -->
                  <div class="pt-3">
                    <div class="form-check">
                      <input
                        id="radiobutton5"
                        v-model="isButtonloudSpeakerPost"
                        class="form-check-input"
                        type="radio"
                        value="1"
                        name="radio-bidirectionnelle-post"
                      />
                      <label class="form-check-label" for="radiobutton5">
                        {{ $t("alerttrigerring.menu_communication_bidirectionnelle") }}
                      </label>
                    </div>
                    <div class="form-check mt-3">
                      <input
                        id="radiobutton6"
                        v-model="isButtonloudSpeakerPost"
                        class="form-check-input"
                        type="radio"
                        value="2"
                        name="radio-unidirectionnelle-post"
                      />
                      <label class="form-check-label" for="radiobutton6">
                        {{ $t("alerttrigerring.menu_communication_unidirectionnelle") }}
                      </label>
                  </div>
                </div>
                <!-- END: parametre avec radio button -->
              </div>
            </div>
            <!-- END: parametre range slider -->
          </div>
          <!-- END: bloc parametres -->
        </div>
          <!-- BEGIN: mode d'alerte -->
          <div class="clearfix box mt-6">
              <!-- entete de parametre -->
              <div
                class="flex flex-col sm:flex-row  p-3 bg-red-300 border-gray-200 dark:border-dark-5 mt-6 rounded-t"
              >
                <h2 class="font-medium text-base  mr-auto">
                  {{ $t("alerttrigerring.menu_message_alert") }}
                </h2>
              </div>
              <!-- BEGUIN: bloc parametres -->
              <div>
                <!-- BEGUIN: parametre avec radio button -->
                <div class="clearfix flex-row p-3 border-b border-gray-200">
                  <div>
                    <div class="form-check">
                      <input
                        id="radiobutton7"
                        v-model="isBouttonAgression"
                        class="form-check-input"
                        type="radio"
                        value="1"
                        name="radio-demande-secours"
                      />
                      <label class="form-check-label" for="radiobutton7">
                        {{ $t("alerttrigerring.menu_demande_secours") }}
                      </label>
                    </div>
                    <div class="form-check mt-3">
                      <input
                        id="radiobutton8"
                        v-model="isBouttonAgression"
                        class="form-check-input"
                        type="radio"
                        value="2"
                        name="radio-agression"
                      />
                      <label class="form-check-label" for="radiobutton8">
                        {{ $t("alerttrigerring.menu_agression") }}
                      </label>
                    </div>
                  </div>
                </div>
                <!-- END: parametre avec radio button -->
              </div>
              <!-- END: bloc parametres -->
            </div>
            <!-- END: mode d'alerte -->
          </div>
          <!-- END: groupe notification prealert boutons -->

       <!-- END: left column -->
    </div>
  </div>
</template>

<script>
import {
  getParameterPTIValue,
  getParameterPTIMax,
  getParameterPTIMin,
  setParameterPTIValue
} from "../../../dao/parametre-pti";
import Toastify from "toastify-js";
import { useStore } from "@/store";

const PRE_ALERTE = "common.menu_notification_prealert";
const POST_ALERTE = "common.menu_post_alert";

export default {
  props: ["type"],

  data() {
    return {
      isPecActivated: false,
      activationMode: -1,
      showTriggerMode: false,
      showAlertType: false,
      showPreAlert: false,
      showLongPress: false,
      isSosButtonDiscreetAlert: 0,

      isButtonSwitchPre: 0,
      isButtonSwitchPost: 0,
      isButtonAffichagePost:false,
      isButtonloudSpeakerPre: undefined,
      isButtonloudSpeakerPost: undefined,
      isButtonSoundPost:false ,
      isBouttonAgression: undefined,


      isButtonCommunicationAmplifie: false,
      isButtonNotificationSound: false,
      isButtonVibrationSeconde:true,
      isButtonNotificationVibration: false,
      sosNotificationDurationRange: 5,
      sosNotificationDurationMin: 0,
      sosNotificationDurationMax: 30,
      actualRange: 0,
      minRange: 3,
      maxRange: 30,
      errorNotification: false,
      pageTitle: "values",
      isNotEwg100:false,
      switches: [
         {

          value: undefined,
          text: PRE_ALERTE,
          disabled: false,
        },
        {
          value: undefined,
          text: POST_ALERTE,
          disabled: false,
        },
        ],
      bottomSwitchesErrorMsg: '',
    };
  },

  mounted() {
    this.updateUI();
  },
  methods: {


    updateUI() {

      if (useStore().state.emeritValues.deviceInfo.deviceModelName !== "E-WG100"){
          this.isNotEwg100 = true
        } else {
          this.isNotEwg100 = false          
        }
          this.init();
      if (useStore().state.emeritValues.deviceInfo.deviceModelName == "E-WG100"){
          this.pageTitle = this.$t("alerttrigerring.menu_power_button_settings")
          this.showLongPress = false;
          } else if(useStore().state.emeritValues.deviceInfo.deviceModelName == "RG170"){  
          this.pageTitle = this.$t("alerttrigerring.menu_side_programmable_button_settings");
          this.showLongPress = true;
          } else if(useStore().state.emeritValues.deviceInfo.deviceModelName == "RG650" || useStore().state.emeritValues.deviceInfo.deviceModelName == "RG655"  ){  
          this.pageTitle = this.$t("alerttrigerring.menu_side_yellow_button_settings")
          this.showLongPress = true;                
          } else {  
          this.pageTitle = this.$t("alerttrigerring.menu_sos_button_and_programmable_button_settings")
          this.showLongPress = true;          
          }
          
      
    },

    init() {
        this.showPreAlert = true;
        this.sosNotificationDurationRange = getParameterPTIValue(
          "WristMouvementNotifTime"
        );
        this.sosNotificationDurationMin = getParameterPTIMin(
          "WristMouvementNotifTime"
        );
        this.sosNotificationDurationMax = getParameterPTIMax(
          "WristMouvementNotifTime"
        );          

        if (getParameterPTIValue("isLoudSpeakerPostAlertWrist") == 1) {
        this.isButtonloudSpeakerPost = 1;
        }else if (getParameterPTIValue("isLoudSpeakerPostAlertWrist") == 0) {
        this.isButtonloudSpeakerPost = 2;
        }
        if (getParameterPTIValue("isLoudSpeakerPreAlertWrist") == 1) {
        this.isButtonloudSpeakerPre = 1;
        }else if (getParameterPTIValue("isLoudSpeakerPreAlertWrist") == 0) {
        this.isButtonloudSpeakerPre = 2;
        }
        if (getParameterPTIValue("isAgressionWrist") == 1) {
        this.isBouttonAgression = 2;
        }else if (getParameterPTIValue("isAgressionWrist") == 0) {
        this.isBouttonAgression = 1;
        }


        this.isButtonNotificationSound = this.intToBoolean(getParameterPTIValue("isNotifWristMouvementSoundEnabled"));
        this.isButtonNotificationVibration = this.intToBoolean(getParameterPTIValue("isNotifWristMouvementVibrationEnabled"));

        this.isButtonSwitchPost = this.intToBoolean(getParameterPTIValue("isPostSwitchWrist"))
        this.isButtonSwitchPre = this.intToBoolean(getParameterPTIValue("isPreSwitchWrist"))
        this.isButtonAffichagePost = this.intToBoolean(getParameterPTIValue("isDisplayPostAlertWrist"))
        this.isButtonSoundPost = this.intToBoolean(getParameterPTIValue("isSoundPostAlertWrist"))

    },




    save() {
      if(this.isButtonloudSpeakerPost == 2){
        setParameterPTIValue("isLoudSpeakerPostAlertWrist", 0);
      }else  if(this.isButtonloudSpeakerPost == 1){
        setParameterPTIValue("isLoudSpeakerPostAlertWrist", 1);
      }
      if(this.isButtonloudSpeakerPre == 2){
        setParameterPTIValue("isLoudSpeakerPreAlertWrist", 0);
      }else  if(this.isButtonloudSpeakerPre == 1){
        setParameterPTIValue("isLoudSpeakerPreAlertWrist", 1);
      }
      if(this.isBouttonAgression == 2){
        setParameterPTIValue("isAgressionWrist", 1);
      }else  if(this.isBouttonAgression == 1){
        setParameterPTIValue("isAgressionWrist", 0);
      }

      setParameterPTIValue("WristMouvementNotifTime",this.sosNotificationDurationRange);

      setParameterPTIValue("isPostSwitchWrist", this.booleanToInt(this.isButtonSwitchPost));
      setParameterPTIValue("isPreSwitchWrist", this.booleanToInt(this.isButtonSwitchPre));
      setParameterPTIValue("isNotifWristMouvementSoundEnabled", this.booleanToInt(this.isButtonNotificationSound));
      setParameterPTIValue("isNotifWristMouvementVibrationEnabled", this.booleanToInt(this.isButtonNotificationVibration));
      
      setParameterPTIValue("isDisplayPostAlertWrist", this.booleanToInt(this.isButtonAffichagePost));
      setParameterPTIValue("isSoundPostAlertWrist", this.booleanToInt(this.isButtonSoundPost));

      this.$router.push("/");
    },


    booleanToInt(value) {
      if (value == true) {
        return "1";
      }
      return "0";
    },

    intToBoolean(value) {
      if (value === "1") {
        return true;
      }
      return false;
    },    
    
  }
};
</script>
