<template>
  <!-- BEGIN: WifiNetworkList local component-->
  <div>
    <Pagination
      v-if="networkList.length"
      :current-page="page"
      :total-pages="getTotalPages"
      @begin="page = 1"
      @end="page = getTotalPages"
      @decrement="page = page === 1 ? 1 : --page"
      @increment="page = page === getTotalPages ? page : ++page"
    />
    <CustomTable 
      v-if="networkList.length"
      :headers="['wifi.menu_ssid', 'wifi.menu_security_type']"
      :elems-list="getNetworkPage"
      :showing-errors="false"
      :screen-percent-width="7"
      list-element-component-name="ListElement"
      green-button-text="common.menu_modify"
      red-button-text="common.menu_remove"
      :green-button-bootstrap-target="'#' + modalIdAddModifyNetwork"
      :red-button-bootstrap-target="'#' + modalIdAlertConfirm"
      @element-green-button-clicked="$emit('modify-network', $event + (page - 1) * 10)"
      @element-red-button-clicked="$emit('remove-network', $event + (page - 1) * 10)"
    />
  </div>
  <!-- END: WifiNetworkList local component-->
</template>

<script>
import { defineComponent } from 'vue'


const NB_ITEMS = 10;

const INDEX_NAME = 0;
const INDEX_SECU_TYPE = 2;

const EV_REMOVE_NETWORK = 'remove-network';
const EV_MODIFY_NETWORK = 'modify-network';


export default defineComponent({

  props: {
    networkList: {
      type: Array,
      required: true,
    },
    modalIdAddModifyNetwork: {
      type: String,
      required: true,
    },
    modalIdAlertConfirm: {
      type: String,
      required: true,
    },
  },

  emits: [
    EV_REMOVE_NETWORK,
    EV_MODIFY_NETWORK,
  ],

    data() {
    return {
      page: 1,
    }
  },

  computed: {

    getNetworkPage() {
      const startIndex = (this.page - 1) * NB_ITEMS;
      const length = startIndex + NB_ITEMS < this.networkList.length ?
          NB_ITEMS : this.networkList.length - startIndex;
      let networkPage = new Array(length);
      for (let i = 0; i < length; ++i)
        networkPage[i] = this.formatNetworkInfo(this.networkList[i + startIndex]);
      return networkPage;
    },

    getTotalPages() {
      return Math.ceil(this.networkList.length / 10);
    },
  },

  watch: {
    
    /**
     * Update the page number dynamically when wifi networks are added / removed.
     */
    'networkList.length': {
      handler: function updatePageNumber(newVal, oldVal) {
        if (this.page > this.getTotalPages)
          --this.page;
        else if (newVal > oldVal)
          this.page = this.getTotalPages;
      }
    }
  },

  methods: {
    formatNetworkInfo(network) {
      return [network[INDEX_NAME], network[INDEX_SECU_TYPE]];
    },
  }
})

</script>