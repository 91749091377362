<template>
  <!-- BEGIN: DeployServerId local component -->
  <div class="clearfix box">
    <BoxTitle
      title="deploy.menu_fill_server_ids"
      color="bg-red-300"
    />
    <div class="clearfix flex-row pl-3 pb-3 pr-3 border-b border-gray-200">
      <CustomTextInput
        v-for="(field, index) in serverIdsFields"
        :key="index"  
        v-model="field.value"
        :title="field.title"
        :error-msg="field.errorMsg"
        class="mt-2"
      />      
    </div>
  </div>
  <!-- END: DeployServerId local component -->
</template>

<script>
import { defineComponent } from 'vue'
import { getBoolPtiValue } from '../../../dao/parametre-pti';
import { getRecipienSupIp, updateTargetDeviceSupIpRecipient } from '../../../dao/sup-ip-dao';
import { getRecipienTLSIp, updateTargetDeviceTlsIpRecipient } from '../../../dao/tls-ip-dao';


const I18_TITLE_TLS_IP_ID_MAIN_SERV = "deploy.menu_client_id_for_tls_main_server";
const I18_TITLE_TLS_IP_ID_BACKUP_SERV = "deploy.menu_client_id_for_tls_backup_server";
const I18_TITLE_SUP_IP_ID_MAIN_SERV = "deploy.menu_client_id_for_supervision_main_server";
const I18_TITLE_SUP_IP_ID_BACKUP_SERV = "deploy.menu_client_id_for_supervision_backup_server";

const I18_ERR_FIELD_CANNOT_BE_EMPTY = "common.menu_field_cannot_be_empty";

const PARAM_PTI_IS_TLS_IP_SERV = "TraitementServiceTelesurveillance";
const PARAM_PTI_IS_SUP_IP_SERV = "TraitementServeurSupervision";

const TLS_IP_MAIN_SERV_INDEX = 0;
const TLS_IP_BACKUP_SERV_INDEX = 1;
const SUP_IP_MAIN_SERV_INDEX = 2;
const SUP_IP_BACKUP_SERV_INDEX = 3;

const MAIN_SERV_DB_COL_INDEX = 1;
const BACKUP_SERV_DB_COL_INDEX = 4;


export default defineComponent({
  
  data() {
    return {
      serverIds: [
        {
          title: I18_TITLE_TLS_IP_ID_MAIN_SERV,
          value: '',
          errorMsg: '',
        },
        {
          title: I18_TITLE_TLS_IP_ID_BACKUP_SERV,
          value: '',
          errorMsg: '',
        },
        {
          title: I18_TITLE_SUP_IP_ID_MAIN_SERV,
          value: '',
          errorMsg: '',
        },
        {
          title: I18_TITLE_SUP_IP_ID_BACKUP_SERV,
          value: '',
          errorMsg: '',
        },
      ],
      serverIdsFields: [], 
      isTlsIpServ: false,
      isTlsIpBackupServ: false,
      isSupIpServ: false,
      isSupIpBackupServ: false,
    }

  },

  created() {
    this.isTlsIpServ = getBoolPtiValue(PARAM_PTI_IS_TLS_IP_SERV);
    this.isSupIpServ = getBoolPtiValue(PARAM_PTI_IS_SUP_IP_SERV);
    if (this.isTlsIpServ) {
      this.serverIdsFields.push(this.serverIds[TLS_IP_MAIN_SERV_INDEX]);
      this.isTlsIpBackupServ = this.isBackupServActivated(getRecipienTLSIp());
      if (this.isTlsIpBackupServ)
        this.serverIdsFields.push(this.serverIds[TLS_IP_BACKUP_SERV_INDEX]);
    }
    if (this.isSupIpServ) {
      this.serverIdsFields.push(this.serverIds[SUP_IP_MAIN_SERV_INDEX]);
      this.isSupIpBackupServ = this.isBackupServActivated(getRecipienSupIp());
      if (this.isSupIpBackupServ)
        this.serverIdsFields.push(this.serverIds[SUP_IP_BACKUP_SERV_INDEX]);
    }
  },

  methods: {
    
    isBackupServActivated(recipient) {
      return recipient[BACKUP_SERV_DB_COL_INDEX] != "";
    },

    check() {
      let checkStatus = true;
      for (let i = 0; i < this.serverIdsFields.length; ++i) {
         if (!this.serverIdsFields[i].value.length) {
          this.serverIdsFields[i].errorMsg = I18_ERR_FIELD_CANNOT_BE_EMPTY;
          checkStatus = false;
        }
        else {
          this.serverIdsFields[i].errorMsg = '';
        }
      }
      return checkStatus;
    },

    save() {
      let i = 0;
      if (this.isTlsIpServ) {
        let tlsIpRecipient = getRecipienTLSIp();
        tlsIpRecipient[MAIN_SERV_DB_COL_INDEX] = this.serverIdsFields[i++].value;
        if (this.isTlsIpBackupServ)
          tlsIpRecipient[BACKUP_SERV_DB_COL_INDEX] = this.serverIdsFields[i++].value;
        updateTargetDeviceTlsIpRecipient(tlsIpRecipient);
      }
      if (this.isSupIpServ) {
        let supIpRecipient = getRecipienSupIp();
        supIpRecipient[MAIN_SERV_DB_COL_INDEX] = this.serverIdsFields[i++].value;
        if (this.isSupIpBackupServ)
          supIpRecipient[BACKUP_SERV_DB_COL_INDEX] = this.serverIdsFields[i++].value;
        updateTargetDeviceSupIpRecipient(supIpRecipient);
      }
    },
    
  }
  
})
</script>
