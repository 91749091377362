import { useStore } from "@/store";

export const addWifi = wifi => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    `INSERT INTO wifi (ssid, password, network_type, hidden_ssid, identifiant_cert, password_cert, certificate) VALUES (?, ?, ?, ?, ?, ?, ?)`;

  try {
    return dbFile.exec(sqlScript, wifi);
  } catch (error) {
    console.log("addWifi failed: " + error);
  }
};

export const getAllWifi = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * from wifi";
  try {
    return dbFile.exec(sqlScript)[0];
  } catch (error) {
    console.log("getAllWifi failed: " + error);
  }
};

export const getWifiId = wifiName => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = 'SELECT _id from wifi WHERE ssid="' + wifiName + '"';
  try {
    return dbFile.exec(sqlScript)[0].values[0];
  } catch (error) {
    console.log("getWifiId failed: " + error);
  }
};

export const editWifi = wifiModified => {
  var dbFile = useStore().state.emeritValues.dbFile;

  let sqlScript =
    'UPDATE wifi SET ssid="' +
    wifiModified.info[0] +
    '", password="' +
    wifiModified.info[1] +
    '",network_type="' +
    wifiModified.info[2] +
    '",hidden_ssid="' +
    wifiModified.info[3] +
    '",identifiant_cert="' +
    wifiModified.info[4] +
    '",password_cert="' +
    wifiModified.info[5] +
    '",certificate="' +
    wifiModified.info[6] +
    '" where _id="' +
    wifiModified.id +
    '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("editWifi failed: " + error);
  }
};

export const getCountWifi = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT COUNT(*) FROM wifi";
  try {
    return dbFile.exec(sqlScript)[0].values[0];
  } catch (error) {
    console.log("getCountWifi failed: " + error);
  }
};

export const deleteWifi = wifiName => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = 'DELETE FROM wifi where ssid="' + wifiName + '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("deleteWifi failed: " + error);
  }
};

export const deleteAllWifi = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "DELETE FROM wifi";
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("deleteAllWifi failed: " + error);
  }
};
