<template>
  <!-- BEGIN: DeployCred local component -->
  <div>
    <BoxTitle
      title="configuration.menu_deployment_password"
      color="bg-green-200"
      class="mt-6"
    />
    <div class="clearfix box p-3">
      <CustomTextInput
        v-model="deployCred"
        title="configuration.menu_password_for_deployment"
        :bold-title="false"
        :max-length="6"
        :is-password-field="true"
        :error-msg="errMsgDeployCred"
      />
    </div>
  </div>
  <!-- END: DeployCred local component -->
</template>

<script>
import { defineComponent } from 'vue'
import {
  getCredValue,
  getDeployCredValue,
  setDeployCredValue
} from "../../../dao/cred-dao";


const I18_ERR_ENTER_CRED = "configuration.menu_enter_a_password";
const I18_ERR_SAME_CRED_THAN_LOGIN_CRED = "configuration.menu_password_must_be_different_from_access_password";
const I18_ERR_WRONG_CRED = "password.menu_password_dont_meet_requirements";

const REGEX_CRED = "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6}$";


export default defineComponent({

  data() {
    return {
      deployCred: "",
      errMsgDeployCred: "",
    }
  },

  watch: {
    deployCred(newVal) {
      setDeployCredValue(newVal);
      this.errMsgDeployCred = "";
    }
  },

  created() {
    this.deployCred = getDeployCredValue();
  },

  methods: {

    check() {
      const regexCred = new RegExp(REGEX_CRED);
      if (!this.deployCred.length)
        this.errMsgDeployCred = I18_ERR_ENTER_CRED;
      else if (this.deployCred.valueOf() === getCredValue().valueOf())
        this.errMsgDeployCred = I18_ERR_SAME_CRED_THAN_LOGIN_CRED;
      else if (!regexCred.test(this.deployCred))
        this.errMsgDeployCred = I18_ERR_WRONG_CRED;
      else
        this.errMsgDeployCred = "";
      return this.errMsgDeployCred.length === 0;
    },

  }

})
</script>
