<template>
 <div class="intro-y col-span-12 lg:col-span-6 lg:col-start-7 2xl:col-span-5 2xl:col-start-6">
    <div class="clearfix box mb-6">
      <BoxTitle
        title="locationindoorble.menu_add_uuid_filtered"
        color="bg-red-300"
      />
      <div class="p-3">
        <CustomSelect
          v-model="selectFields[0].value"
          :title="selectFields[0].title"
          :options="selectFields[0].options"
          :class="selectFields[0].class"
        />
        <div 
        v-if="selectFields[0].value === 'Autre UUID'"
        >
          <CustomTextInput
            v-for="field in getInputFields"
            :key="field.id"
            v-model="field.value"
            :title="field.title"
            :is-password-field="field.isPasswordField"
            :error-msg="field.errorMsg"
            :max-length="field.maxLength" 
            class="col-span-12 py-3"
            @input="handleInput(field)"
          />
        </div>
        <div class="text-right ">
          <CustomButton
            text="common.menu_add"
            @click="add()"
          />
        </div>
      </div>
    </div>
    <CustomToast
      ref="toastaddBeaconFilteredSuccess"
      toast-id="toast-add-beacon-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="locationindoorble.menu_add_beacon_transparent_success"
    />
    <CustomToast
      ref="toastAddBeaconFilteredFailed"
      toast-id="toast-add-beacon-failed"
      icon-component-name="XCircleIcon"
      icon-component-class="text-theme-6"
      text="locationindoorble.menu_add_beacon_transparent_failed"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import {
  BBE9_UUID,
  LOC_EX_UUID,
  MODEL_BBE9,
  MODEL_LOC_EX,
  MODEL_CUSTOM,
} from './utils';

const ERR_UUID_TRANSPARENT = "locationindoorble.menu_condition_uuid_transparent";
const MODEL_CLASS = "col-span-10 lg:col-span-5 lg:col-start-1 2xl:col-span-4 2xl:col-start-1"; 
const MODEL_TITLE = "locationindoorble.menu_beacon_model";
const EV_ADD_BEACON_FILTERED_SUCCESS = 'addBeaconFilteredSuccess';
const I18_MODEL_CUSTOM = "locationindoorble.menu_custom_model";

let id = 0;

export default defineComponent({
  props: {
    beaconFilteredList: {
      type: Array,
      required: true
    },
  },
  emits: [EV_ADD_BEACON_FILTERED_SUCCESS],
  data() {
    return {
      selectFields: [
        { 
          value: MODEL_BBE9, 
          options: [MODEL_BBE9, MODEL_LOC_EX, MODEL_CUSTOM], 
          title: MODEL_TITLE, 
          class: MODEL_CLASS,
        },
      ],
      inputFields: [
        { 
          id: id++,
          title: I18_MODEL_CUSTOM, 
          value: '', 
          isPasswordField: false,
          errorMsg: '' ,
          maxLength: 32
        },
      ],
    };
  },
  computed: {
    getInputFields() {
      return this.inputFields;
    },
  },
  methods: {
    handleInput(field) {
      field.value = field.value.toUpperCase();
    },
    add() {
    let beaconValue = [this.selectFields[0].value];
    const beaconExists = this.beaconFilteredList.some(beacon => beacon[0] === beaconValue[0]);
    if (beaconExists) {
      // Trigger error
      this.$refs.toastAddBeaconFilteredFailed.trigger();
    } else {
      if (beaconValue[0] === MODEL_BBE9) {
        beaconValue[0] = BBE9_UUID;
      }
      if (beaconValue[0] === MODEL_LOC_EX) {
        beaconValue[0] = LOC_EX_UUID;
      }
      if (beaconValue[0] === MODEL_CUSTOM) {
          if (!/^[0-9A-F]{32}$/.test(this.inputFields[0].value.toUpperCase())  && !/^[0-9A-F]{20}$/.test(this.inputFields[0].value.toUpperCase())) {
            this.inputFields[0].errorMsg = ERR_UUID_TRANSPARENT;
            return false;
          }
          this.inputFields[0].errorMsg = "";
          beaconValue[0] = this.inputFields[0].value.toUpperCase();
        }
      const beaconToCheck = beaconValue[0];
      const beaconExistsInList = this.beaconFilteredList.some(beacon => beacon[0] === beaconToCheck);
      if (beaconExistsInList) {
        // Trigger error
        this.$refs.toastAddBeaconFilteredFailed.trigger();
      } else {
        this.addBeaconFilteredSuccess(beaconValue);
      }
    }
  },

    addBeaconFilteredSuccess(beacon) {
      let type = '0';
      beacon.push(type);
      this.$emit(EV_ADD_BEACON_FILTERED_SUCCESS, beacon);
      this.$refs.toastaddBeaconFilteredSuccess.trigger();
    },
  },
})

</script>
