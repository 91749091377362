import { useStore } from "@/store";

export const addRecipient = recipient => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'INSERT INTO  destinataire_internalise (nom,numero,p_appel,p_sms,p_alarme,p_batterie_basse,p_marche_arret,p_station_accueil,p_demande_localisation,id_tri,p_post_alarme,p_pti,p_emerit_watch,sip_account_id,sip_server_url,is_sip_recipient,p_beacon_batterie_basse)  VALUES( "' +
    recipient.name +
    '", "' +
    recipient.number +
    '", "' +
    Number(recipient.pCall) +
    '", "' +
    Number(recipient.pSms) +
    '", "' +
    Number(recipient.pAlarm) +
    '", "' +
    Number(recipient.pLowBattery) +
    '", "' +
    Number(recipient.pPowerOnOff) +
    '", "' +
    Number(recipient.pChargeStation) +
    '", "' +
    Number(recipient.pLoc) +
    '", "' +
    Number(recipient.sortId) +
    '", "' +
    Number(recipient.pPostAlarm) +
    '", "' +
    Number(recipient.pPti) +
    '", "' +
    Number(recipient.pWatch) +
    '", "' +
    recipient.sipAccountId +
    '", "' +
    recipient.sipServerUrl +
    '", "' +
    Number(recipient.isSipRecipient) +
    '", "' +
    Number(recipient.pBleBeaconLowBattery) +
    '")';
    
  try {
    return dbFile.exec(sqlScript);
  } catch (error) {
    console.log('addRecipient failed: ' + error);
  }
};

export const getAllRecipient = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * from destinataire_internalise";
  try {
    var recipient = dbFile.exec(sqlScript)[0];
    return recipient;
  } catch (error) {
    console.log('getAllRecipient failed: ' + error);
  }
};

export const getRecipient = id => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * from destinataire_internalise";
  try {
    var recipient = dbFile.exec(sqlScript)[0].values[id];
    return recipient;
  } catch (error) {
    console.log('getRecipient failed: ' + error);
  }
};

export const updateRecipient = recipient => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'UPDATE destinataire_internalise SET nom="' +
    recipient.name +
    '", numero="' +
    recipient.number +
    '",p_appel="' +
    Number(recipient.pCall) +
    '",p_sms="' +
    Number(recipient.pSms) +
    '",p_alarme="' +
    Number(recipient.pAlarm) +
    '",p_batterie_basse="' +
    Number(recipient.pLowBattery) +
    '",p_marche_arret="' +
    Number(recipient.pPowerOnOff) +
    '",p_station_accueil="' +
    Number(recipient.pChargeStation) +
    '",p_demande_localisation="' +
    Number(recipient.pLoc) +
    '",id_tri="' +
    Number(recipient.sortId) +
    '",p_post_alarme="' +
    Number(recipient.pPostAlarm) +
    '",p_pti="' +
    Number(recipient.pPti) +
    '",p_emerit_watch="' +
    Number(recipient.pWatch) +
    '",sip_account_id="' +
    recipient.sipAccountId +
    '",sip_server_url="' +
    recipient.sipServerUrl +
    '",is_sip_recipient="' +
    Number(recipient.isSipRecipient) +
    '",p_beacon_batterie_basse="' +
    Number(recipient.pBleBeaconLowBattery) +
    '" where _id="' +
    recipient.id +
    '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('updateRecipient failed: ' + error);
  }
};

export const getCountRecipient = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT COUNT(*) FROM destinataire_internalise";
  try {
    return Number(dbFile.exec(sqlScript)[0].values[0]);
  } catch (error) {
    console.log('getCountRecipient failed: ' + error);
  }
};

export const deleteRecipient = id => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = 'DELETE FROM destinataire_internalise where _id="' + id + '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('deleteRecipient failed: ' + error);
  }
};

export const deleteAllRecipient = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "DELETE FROM destinataire_internalise";
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('deleteAllRecipient failed: ' + error);
  }
};

export const getCountRecipientAlarmSms = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    "SELECT COUNT(p_alarme) FROM destinataire_internalise WHERE p_alarme=1 ";
  try {
    return Number(dbFile.exec(sqlScript)[0].values[0]);
  } catch (error) {
    console.log('getCountRecipientAlarmSms failed: ' + error);
  }
};

export const getCountRecipientAlarmCall = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    "SELECT COUNT(p_appel) FROM destinataire_internalise WHERE p_appel=1 ";
  try {
    return Number(dbFile.exec(sqlScript)[0].values[0]);
  } catch (error) {
    console.log('getCountRecipientAlarmCall failed: ' + error);
  }
};

export const getMessagingWatchState = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript = "SELECT * FROM destinataire_internalise WHERE p_emerit_watch == '1'";
  try {
    return Boolean(dbFile.exec(sqlScript).length);
  }
  catch (error) {
    console.log('getMessagingWatchState failed: ' + error);
  }
}

export const getLastRecipientIdCreated = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript = "SELECT * FROM destinataire_internalise ORDER BY _id DESC LIMIT 1";
  try {
    return dbFile.exec(sqlScript)[0].values[0][0];
  }
  catch (error) {
    console.log('getLastRecipientIdCreated failed: ' + error);
  }
}

export const resetRecipientsBeaconBleLowBatteryEvent = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript = "UPDATE destinataire_internalise SET p_beacon_batterie_basse=0";
  try {
    dbFile.exec(sqlScript);
  }
  catch (error) {
    console.log('resetRecipientsBeaconBleLowBatteryEvent failed: ' + error);
  }
}

export const getCountRecipientWithSmsButNoEvent = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript = "SELECT COUNT(p_sms) FROM destinataire_internalise "
      + "WHERE p_sms=1 "
      + "and p_alarme=0 "
      + "and p_batterie_basse=0 "
      + "and p_marche_arret=0 "
      + "and p_station_accueil=0 "
      + "and p_beacon_batterie_basse=0";
  try {
    return dbFile.exec(sqlScript)[0].values[0][0];
  }
  catch (error) {
    console.log('getCountRecipientWithSmsButNoEvent failed: ' + error);
  }
}