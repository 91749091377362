<template>
  <!-- BEGIN: NetworkModalForm local component-->
  <div>
    <div
      :id="modalId"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ $t(getModalTitle) }}
            </h2>
          </div>
        
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <CustomSelect
              v-model="select.value"
              class="col-span-12"
              :title="select.title"
              :options="select.options"
              :error-msg="select.errorMsg"
            />  
            <CustomTextInput
              v-for="(field, index) in getInputFields"
              :key="index"
              :ref="'customTextInput' + index"
              v-model="field.value"
              :title="field.title"
              :is-password-field="field.isPasswordField"
              :error-msg="field.errorMsg"
              :max-length="field.maxLength"
              class="col-span-12"
            />
            <div class="col-span-12">
              <div v-if="select.value === 'PEAP'">
                <div class="">
                  <CheckBoxFieldAndXIcons
                    v-model="isUpload"
                    custom-id="Certificate"
                    check-box-text="wifi.menu_certificate_type"
                    custom-class="pl-3 my-5"
                  />
                  <!-- add button to upload image -->
                  <div 
                    v-if="isUpload === true || isUpload === 1" 
                    class="mb-5"
                  >
                    <button 
                      class="btn btn-warning"
                      @click="openFileDialog"
                      @change="checkUpload"
                    >
                      {{ $t("wifi.menu_upload_certificat") }}
                    </button>
                    <h1 class="ml-2 mt-3">
                      {{ certificateName }}
                    </h1>
                    <input
                      ref="fileInput"
                      type="file"
                      accept=".p12, .pfx"
                      style="display: none"
                      @change="uploadFile"
                    >
                    <ErrorMsg
                      :error-msg="certUploadErrorMsg"
                      class="mt-2"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-4 gap-y-3">
                  <CustomTextInput
                    v-for="field in getInputFieldsCert"
                    :key="field.id"
                    v-model="field.value"
                    :title="field.title"
                    :is-password-field="field.isPasswordField"
                    :error-msg="field.errorMsg"
                    class="col-span-12"
                  />
                </div>
              </div>
            </div>
          </div>
          <CheckBoxFieldAndXIcons
            v-model="isHidden"
            custom-id="checkbox-wifi-hidden"
            check-box-text="wifi.menu_hidden"
            custom-class="pl-5"
          />
          <div class="mt-5 modal-footer text-right">
            <ButtonDismissModal
              ref="leftButton"
              custom-class="btn btn-outline-secondary mr-1"
              text="common.menu_cancel"
            />
            <CustomButton
              text="common.menu_confirm"
              @click="saveForm()"
            />
          </div>
        </div>
      </div>
    </div>
    <CustomToast
      ref="toastAddNetworkSuccess"
      toast-id="toast-add-network-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="wifi.menu_network_added_with_success"
    />
    <CustomToast
      ref="toastModifyNetworkSuccess"
      toast-id="toast-modify-network-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="wifi.menu_network_modified_with_success"
    />  
    <CustomToast
      ref="ToastCertificateUploadFail"
      toast-id="toast-upload-certificate-fail"
      icon-component-name="XCircleIcon"
      icon-component-class="text-theme-6"
      text="wifi.menu_certificate_upload_fail"
    />
    <CustomToast
      ref="toastCertificateUploadSuccess"
      toast-id="toast-upload-certificate-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="wifi.menu_certificate_upload_success"
    />
  </div>

  <!-- END: NetworkModalForm local component-->
</template>

<script>
import { 
  STORE_SET_WIFI_PEAP_CERTIFICATE_FILE,
  STORE_SET_WIFI_PEAP_CERTIFICATE_UPDATE_S3,
  STORE_SET_PEAP_CERTIFICATE_OBJ,
 } from '../../../store/const_store';
import { defineComponent } from 'vue'
import { getWifiId } from '../../../dao/wifi-dao';

const ERR_SELECT_WIFI_SECURITY_TYPE = "wifi.menu_please_select_security_type";
const ERR_FIELD_IS_EMPTY = "common.menu_field_cannot_be_empty"; 
const ERR_WIFI_ALREADY_EXIST = "wifi.menu_network_already_exists"

const INDEX_NAME = 0;
const INDEX_SSID_FIELD = 0;

const EV_EDIT_NETWORK_SUCCESS = 'edit-network-success';
const EV_ADD_NETWORK_SUCCESS = 'add-network-success';

// const SEC_TYPE_Trust = 'Trust on first use';
const SEC_TYPE_WPA = 'WPA/WPA2';
const SEC_TYPE_WEP = 'WEP';
const SEC_TYPE_OPEN = 'OPEN';
const SEC_TYPE_PEAP = 'PEAP';

const I18_TITLE_CER_IDENTIFIANT = "wifi.menu_certificate_identifiant";
const I18_TITLE_CER_PASSWORD = "wifi.menu_certificate_password";

const ERR_PLS_UPLOAD_CERT="wifi.menu_err_pls_upload_certificate";
const ERR_CERT_UPLOAD = [
  "wifi.menu_err_only_pfx_p12_accepted",
  "wifi.menu_err_certificate_is_too_heavy",
];

const I18_TITLE_SEC_TYPE = "wifi.menu_security_type";
const I18_TITLE_SSID = "wifi.menu_ssid";
const I18_TITLE_PASSWORD = "wifi.menu_password";

const I18_MODAL_TITLE_ADD = "wifi.menu_add_a_wifi_network";
const I18_MODAL_TITLE_MODIFY = "wifi.menu_modify_a_wifi_network";
let id = 0;

export default defineComponent({

   props: {
    modalId: {
      type: String,
      required: true,
    },
    networkList: {
      type: Array,
      required: true,
    }
  },

  emits: [
    EV_EDIT_NETWORK_SUCCESS, 
    EV_ADD_NETWORK_SUCCESS
  ],

  data() {
    return {
      certificate: '',
      certificateName: '',
      certUploadErrorMsg: '',
      certificateFile: '',
      updateS3Cert:true,
      select: {
        title: I18_TITLE_SEC_TYPE,
        value: '',
        options: [
          SEC_TYPE_WPA,  
          SEC_TYPE_WEP, 
          SEC_TYPE_OPEN,
          SEC_TYPE_PEAP
        ],
        errorMsg: '',
      },
      inputFields: [
        { 
          id:id++,
          title: I18_TITLE_SSID, 
          value: '', 
          isPasswordField: false,
          errorMsg: '',
          maxLength: 50
        },
        { 
          id:id++,
          title: I18_TITLE_PASSWORD, 
          value: '', 
          isPasswordField: true,
          errorMsg: '',
          maxLength: 63
        }
      ],
      inputFieldsCert: [
        { 
          id:id++,
          title: I18_TITLE_CER_IDENTIFIANT, 
          value: '', 
          isPasswordField: false,
          errorMsg: '',
          maxLength: 50
        },
        { 
          id:id++,
          title: I18_TITLE_CER_PASSWORD, 
          value: '', 
          isPasswordField: true,
          errorMsg: '',
          maxLength: 63
        },
      ],
      isUpload:undefined,
      isHidden: undefined,
      edit: false,
      currentIndex: undefined,
    }
  },

  computed: {
    getModalTitle() {
      if (this.edit)
        return I18_MODAL_TITLE_MODIFY;
      return I18_MODAL_TITLE_ADD;
    },

    getInputFields() {
      if (this.select.value.valueOf() === SEC_TYPE_OPEN || this.select.value.valueOf() === SEC_TYPE_PEAP)
        return [ this.inputFields[INDEX_SSID_FIELD]];
      return this.inputFields;
     },
    getInputFieldsCert(){
      return this.inputFieldsCert;
    },
  },

  methods: {
    saveForm() {
      if (!this.checkForm())
        return ;
      let tmpWifi = this.createWifiObject();
      let ifButtonUpload = tmpWifi.info[6];
      let eventName = this.edit ? EV_EDIT_NETWORK_SUCCESS : EV_ADD_NETWORK_SUCCESS;
      let SSID_name = tmpWifi.info[0];
      // let nameFormat = this.certificateFile.name.slice(this.certificateFile.name.lastIndexOf('.'));
      // let nameCertificateInS3 = SSID_name + nameFormat;
      const certificateList = {
        name: SSID_name,
        file: this.certificateFile
      }

      if(ifButtonUpload === 1)
        this.$store.commit(STORE_SET_PEAP_CERTIFICATE_OBJ, certificateList)
      this.$emit(eventName, tmpWifi);
      this.triggerToast();
      this.$refs.leftButton.click();
    },

    triggerToast() {
      if (this.edit)
        this.$refs.toastModifyNetworkSuccess.trigger();
      else
        this.$refs.toastAddNetworkSuccess.trigger();
    },
    
    createWifiObject() {
      let tmpWifi = {}; 
      tmpWifi.info = new Array(7);
      tmpWifi.info[0] = this.inputFields[0].value;
      tmpWifi.info[1] = (this.select.value.valueOf() === SEC_TYPE_OPEN || this.select.value.valueOf() === SEC_TYPE_PEAP) ?  '' : this.inputFields[1].value;
      tmpWifi.info[2] = this.select.value;
      tmpWifi.info[3] = Number(this.isHidden);
      tmpWifi.info[4] = (this.select.value.valueOf() === SEC_TYPE_PEAP) ? this.inputFieldsCert[0].value : '';
      tmpWifi.info[5] = (this.select.value.valueOf() === SEC_TYPE_PEAP) ? this.inputFieldsCert[1].value : '';
      tmpWifi.info[6] =  Number(this.isUpload);
      tmpWifi.id = this.dbId;
      return tmpWifi;
    },

    checkForm() {
      let error = 0;
      error += this.checkField(this.select, ERR_SELECT_WIFI_SECURITY_TYPE);
      error += this.checkField(this.inputFields[0], ERR_FIELD_IS_EMPTY);
      if (this.select.value != SEC_TYPE_OPEN && this.select.value != SEC_TYPE_PEAP)
        error += this.checkField(this.inputFields[1], ERR_FIELD_IS_EMPTY);
      if (this.select.value === SEC_TYPE_PEAP){
        error += this.checkField(this.inputFieldsCert[0], ERR_FIELD_IS_EMPTY);
        error += this.checkField(this.inputFieldsCert[1], ERR_FIELD_IS_EMPTY);
        if(this.isUpload===true)
          error += this.checkUpload();
      }
      error += this.isWifiAlreadyExisting(this.inputFields[0].value);
      return !error;
    },        

    checkField(field, msg) {
      if (field.value.length === 0) {
        field.errorMsg = msg;
        return 1;
      }
      else if (field.errorMsg.length != 0)
        field.errorMsg = "";
      return 0;
    },

    isWifiAlreadyExisting(ssid) {
      for (let i = 0; i < this.networkList.length; ++i) {
        if ((!this.edit && this.networkList[i][0] === ssid) ||
            this.edit && i != this.currentIndex && this.networkList[i][0] === ssid) {
          this.inputFields[0].errorMsg = ERR_WIFI_ALREADY_EXIST;
          return 1;
        }
      }
      if (ssid.length != 0 && this.inputFields[0].errorMsg.length != 0)
        this.inputFields[0].errorMsg = "";
      return 0;
    },

    initAdd() {
      this.resetField(this.select);
      this.edit = false;
      for (let i = 0; i < this.inputFields.length; ++i)
        this.resetField(this.inputFields[i]);
      for (let j = 0; j < this.inputFieldsCert.length; ++j)
        this.resetField(this.inputFieldsCert[j]);
      this.isHidden = false;
      this.isUpload = false;
      this.certificateName = '';
      this.dbId = -1;
      if (this.$refs.customTextInput1)
        this.$refs.customTextInput1.resetHidePassword();
    },

    initEdit(index) {
      this.resetInputField();
      this.currentIndex = index;
      this.edit = true;
      this.dbId = getWifiId(this.networkList[index][INDEX_NAME]);
      const currWifi = this.networkList[index];
      this.select.value = currWifi[2];
      this.inputFields[0].value = currWifi[0];
      if (this.select.value != SEC_TYPE_OPEN && this.select.value != SEC_TYPE_PEAP)
        this.inputFields[1].value = currWifi[1];
      if (this.select.value === SEC_TYPE_PEAP){
        this.inputFieldsCert[0].value = currWifi[4];
        this.inputFieldsCert[1].value = currWifi[5];
      }
      if (this.$refs.customTextInput1)
        this.$refs.customTextInput1.resetHidePassword();
      this.isHidden = currWifi[3];
      this.resetAllErrorFields();
    },

    resetField(field) {
      field.value = '';
      field.errorMsg = '';
      },

    resetInputField(){
      if(this.select.value === SEC_TYPE_OPEN || this.select.value === SEC_TYPE_PEAP){
        this.resetField(this.inputFields[1]);
      }
      if(this.select.value === SEC_TYPE_WEP || this.select.value === SEC_TYPE_WPA || this.select.value === SEC_TYPE_OPEN){
        this.resetField(this.inputFieldsCert[0]);
        this.resetField(this.inputFieldsCert[1]);
      }
    },

    resetAllErrorFields() {
      this.select.errorMsg = '';
      for (let i = 0; i < this.inputFields.length; ++i)
        this.inputFields[i].errorMsg = '';
      for (let j = 0; j < this.inputFieldsCert.length; ++j)
        this.inputFieldsCert[j].errorMsg = '';
    },

    // <-- BEGIN : Upload du certificat -->
    checkUpload() {
      if (this.certificateName === '' || this.certUploadErrorMsg === ERR_PLS_UPLOAD_CERT[0] || this.certUploadErrorMsg === ERR_PLS_UPLOAD_CERT[1])
      {
        this.certUploadErrorMsg = ERR_PLS_UPLOAD_CERT;
        return 1;
      }
      else
      {
        return 0;
      }
    },
      
    openFileDialog() {
      this.$refs.fileInput.click();
    },

    uploadFile() {  
      const MAX_SIZE = 5048576;
      const ACCEPTED_CERTIFICATE_TYPES = ['application/x-pkcs12','application/x-pkcs8'];
      const certificateFile = this.$refs.fileInput.files[0];

      if ((certificateFile.type === ACCEPTED_CERTIFICATE_TYPES[0] || certificateFile.type === ACCEPTED_CERTIFICATE_TYPES[1]) && certificateFile.size < MAX_SIZE)
      {
        this.$refs.toastCertificateUploadSuccess.trigger();
        this.certificateName = certificateFile.name;
        this.certUploadErrorMsg='';
        this.certificateFile=certificateFile;
        this.$store.commit(STORE_SET_WIFI_PEAP_CERTIFICATE_FILE, certificateFile);
        this.$store.commit(STORE_SET_WIFI_PEAP_CERTIFICATE_UPDATE_S3, this.updateS3Cert);
      } 
      else if((certificateFile.type === ACCEPTED_CERTIFICATE_TYPES[0] || certificateFile.type === ACCEPTED_CERTIFICATE_TYPES[1]) && certificateFile.size > MAX_SIZE)
      {
        this.$refs.ToastCertificateUploadFail.trigger();
        this.certificateName = '';
        this.certUploadErrorMsg = ERR_CERT_UPLOAD[1];
      }
      else
      {
        this.$refs.ToastCertificateUploadFail.trigger();
        this.certificateName = '';
        this.certUploadErrorMsg = ERR_CERT_UPLOAD[0];
      }
    },
    // <-- END : Upload du certificat -->
    dismiss() { 
      this.$refs.leftButton.click();
    }
  },
})
</script>
