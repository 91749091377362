<template>
  <!-- BEGIN: WhitelistSettings local component-->
  <div class="grid grid-cols-10 intro-y mt-3">
    <div class="xl:col-span-4 lg:col-span-5 col-span-10">
      <BoxTitle
        title="common.menu_settings"
        color="bg-indigo-300"
      />
      <div class="clearfix box p-3">
        <div class="font-bold text-theme-0">
          {{ $t("whitelist.menu_nb_total_whitelist_recipient") + nbTotalWhiteListRecipient }}
        </div>
        <div class="flex mt-3">
          <div class="pr-3">
            <ButtonTargetModal
              :target-id="'#' + modalIdAddModifyRecipientWhiteList"
              text="whitelist.menu_add_whitelist_recipient"
              custom-class="btn btn-primary"
              @click="$emit('add-recipient-whitelist')"
            />
          </div>
          <div>
            <ButtonTargetModal
              v-if="lenRecipientWhiteList"
              :target-id="'#' + modalIdAlertConfirm"
              custom-class="btn btn-danger"
              text="common.menu_remove_everything"
              @click="$emit('remove-all-recipient-whitelist')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: WhitelistSettings local component-->
</template>

<script>
import { defineComponent } from 'vue'


const EV_ADD_RECIPIENT_WHITELIST = 'add-recipient-whitelist';
const EV_REMOVE_ALL_RECIPIENT_WHITELIST = 'remove-all-recipient-whitelist';


export default defineComponent({

    props: {
    lenRecipientWhiteList: {
      type: Number,
      required: true,
    },
    nbTotalWhiteListRecipient: {
      type: Number,
      required: true,
    },
    modalIdAddModifyRecipientWhiteList: {
      type: String,
      required: true,
    },
    modalIdAlertConfirm: {
      type: String,
      required: true,
    },
  },

  emits: [
    EV_ADD_RECIPIENT_WHITELIST,
    EV_REMOVE_ALL_RECIPIENT_WHITELIST
  ],

})
</script>
