<template>
  <!-- BEGIN: ModalModifyBleBeacon local component-->
  <div>
    <div
      :id="modalId"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ $t("locationindoorble.menu_modify_beacon") }}
            </h2>
          </div>
          <div class="modal-body grid grid-cols-12">
            <div class="col-span-12">
              <CustomSelect
                v-model="selectFields[0].value"
                :title="selectFields[0].title"
                :options="selectFields[0].options"
                class="mt-2"
                :help-locale="getBeaconHelpLocale"
              />
              <CustomSelect
                v-if="isLevelActivated"
                v-model="selectFields[1].value"
                :title="selectFields[1].title"
                :options="selectFields[1].options"
                class="mt-2"
                help-locale="menu_level_types_help"
              />
            </div>
            <div class="col-span-12">
              <ModifyBbe9InputFields
                v-if="selectFields[0].value.valueOf() === 'e-BBE9'"
                ref="e-BBE9"
                :hide-level-field="hideLevelField"
                :beacon-list="beaconList"
                :current-index="currentIndex"
                :nb-times-show="nbTimesShow"
                @modify-bbe9-success="emitModifyBeaconSuccess"
              />
              <ModifyLocExInputFields
                v-if="selectFields[0].value.valueOf() === 'LOC-EX 01'"
                ref="LOC-EX"
                :hide-level-field="hideLevelField"
                :beacon-list="beaconList"
                :current-index="currentIndex"
                :nb-times-show="nbTimesShow"
                @modify-loc-ex-success="emitModifyBeaconSuccess"
              />
              <ModifyIbeaconInputFields
                v-if="selectFields[0].value.valueOf() === 'iBeacon'"
                ref="iBeacon"
                :hide-level-field="hideLevelField"
                :beacon-list="beaconList"
                :current-index="currentIndex"
                :nb-times-show="nbTimesShow"
                @modify-ibeacon-success="emitModifyBeaconSuccess"
              />
              <ModifyEddystoneInputFields
                v-if="selectFields[0].value.valueOf() === 'Eddystone'"
                ref="Eddystone"
                :hide-level-field="hideLevelField"
                :beacon-list="beaconList"
                :current-index="currentIndex"
                :nb-times-show="nbTimesShow"
                @modify-eddystone-success="emitModifyBeaconSuccess"
              />
            </div>
          </div>
          <div class="mt-5 modal-footer text-right">
            <ButtonDismissModal
              ref="leftButton"
              custom-class="btn btn-outline-secondary mr-1"
              text="common.menu_cancel"
            />
            <CustomButton
              text="common.menu_confirm"
              @click="$refs[getRef].check()"
            />
          </div>
        </div>
      </div>
    </div>
    <CustomToast
      ref="toastModifyBeaconSuccess"
      toast-id="toast-modify-beacon-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="locationindoorble.menu_modify_beacon_success"
    />
  </div>
  <!-- END: ModalModifyBleBeacon local component-->
</template>

<script>
import { defineComponent } from 'vue'
import { getBleBeaconId } from '../../../dao/indoor-ble-dao'
import {
  formatLevel,
  getModel,
  MODEL_BBE9,
  MODEL_LOC_EX,
  MODEL_IBEACON,
  MODEL_EDDY,
} from './utils'
import ModifyBbe9InputFields from './lc-modify-bbe9-input-fields'
import ModifyLocExInputFields from './lc-modify-loc-ex-input-fields.vue'
import ModifyIbeaconInputFields from './lc-modify-ibeacon-input-fields'
import ModifyEddystoneInputFields from './lc-modify-eddystone-input-fields'


const SELECT_MODEL = 0;
const SELECT_TYPE = 1;

const COMPANY_ID = 0;
const BEACON_ID = 1;
const CODE_ZONE = 2;
const DESC = 3;
const LEVEL = 4;
const TYPE = 5;

const TYPE_ZONE = "ZONE";
const TYPE_LEVEL = "LEVEL";
const TYPE_CONFIRM = "CONFIRM";

const MODEL_TITLE = "locationindoorble.menu_beacon_model";
const TYPE_TITLE = "locationindoorble.menu_beacon_type";

const EV_MODIFY_BEACON_SUCCESS = 'modifyBeaconSuccess';

const I18_BBE9_HELP_LINK = "menu_bbe9_help";


export default defineComponent({

  components: {
    ModifyBbe9InputFields,
    ModifyLocExInputFields,
    ModifyIbeaconInputFields,
    ModifyEddystoneInputFields,
  },

  props: {
    modalId: {
      type: String,
      required: true,
    },
    beaconList: {
      type: Array,
      required: true,
    },
    isLevelActivated: {
      type: Boolean,
      required: true,
    },
  },

  emits: [EV_MODIFY_BEACON_SUCCESS],

  data() {
    return {
      selectFields: [
        { 
          value: MODEL_BBE9, 
          options: [MODEL_BBE9, MODEL_LOC_EX, MODEL_IBEACON, MODEL_EDDY], 
          title: MODEL_TITLE, 
        },
        { 
          value: TYPE_ZONE, 
          options: [TYPE_ZONE, TYPE_LEVEL, TYPE_CONFIRM], 
          title: TYPE_TITLE, 
        },
      ],
      currentIndex: 0,
      currentId: undefined,
      nbTimesShow: 0,
    }
  },

  computed: {

    hideLevelField() {
      return !this.isLevelActivated 
          || this.selectFields[SELECT_TYPE].value.valueOf() === TYPE_LEVEL;
    },

    getBeaconHelpLocale() {
      if (this.selectFields[SELECT_MODEL].value.valueOf() === MODEL_BBE9)
        return I18_BBE9_HELP_LINK;
      return undefined;
    },

    getRef() {
      if (this.selectFields[0].value.valueOf() === MODEL_LOC_EX)
        return 'LOC-EX'
      return this.selectFields[0].value;
    }
  },

  methods: {

    initValues(index) {
      this.nbTimesShow++;
      this.currentIndex = index;
      const beaconToModify = this.beaconList[index];
      this.currentId = getBleBeaconId(beaconToModify[COMPANY_ID], beaconToModify[BEACON_ID]);
      this.selectFields[SELECT_MODEL].value = getModel(beaconToModify[COMPANY_ID]);
      let selectTypeFields = this.selectFields[SELECT_TYPE];
      const indexType = Number(beaconToModify[TYPE]); 
      selectTypeFields.value = selectTypeFields.options[indexType];
    },

    emitModifyBeaconSuccess(beacon) {
      let type = '0';
      if (this.isLevelActivated) {
        let typeFields = this.selectFields[SELECT_TYPE];
        for (let i = 0; i < typeFields.options.length; ++i)
          if (typeFields.value.valueOf() === typeFields.options[i].valueOf())
            type = String(i);
      }
      beacon.push(type);
      beacon[LEVEL] = formatLevel(beacon[LEVEL]);
      const beaconModified = {
        id: this.currentId,
        newBeacon: beacon,
      }
      this.$emit(EV_MODIFY_BEACON_SUCCESS, beaconModified);
      this.$refs.toastModifyBeaconSuccess.trigger();
      this.$refs.leftButton.click();
    },

    dismiss() {
      this.$refs.leftButton.click();
    },

  },

})
</script>
