<template>
  <div>
    <Title
      title="whitelist.menu_whitelist_title"
      help-locale="menu_whitelist_view"
    />
    <WhitelistSettings
      :len-recipient-white-list="recipientWhiteList.length"
      :nb-total-white-list-recipient="recipientMirrorList.length + recipientWhiteList.length"
      :modal-id-add-modify-recipient-white-list="modalIdAddModifyRecipientWhiteList"
      :modal-id-alert-confirm="modalIdAlertConfirm"
      @add-recipient-whitelist="$refs.modalAddModifyRecipientWhiteList.initAdd()"
      @remove-all-recipient-whitelist="initModalConfirm"
    />
    <div class="intro-y grid grid-cols-10 mt-6">
      <InternalRecipientMirrorList 
        class="lg:col-span-4 xl:col-span-3 col-span-12 mr-6"
        :recipient-mirror-list="recipientMirrorList"
        :is-tls-activated="isTlsActivated"
      />
      <RecipientWhiteList
        class="lg:col-span-6 mt-3 lg:mt-0 lg:col-start-5 xl:col-span-5 xl:col-start-4 col-span-12"
        :recipient-white-list="recipientWhiteList"
        :modal-id-add-modify-recipient-white-list="modalIdAddModifyRecipientWhiteList"
        :modal-id-alert-confirm="modalIdAlertConfirm"
        @modify-recipient-whitelist="initModalModify"
        @remove-recipient-whitelist="initModalConfirm"
      />
    </div>
    <ModalAddModifyRecipientWhiteList
      ref="modalAddModifyRecipientWhiteList"
      :modal-id="modalIdAddModifyRecipientWhiteList"
      :recipient-white-list="recipientWhiteList"
      @add-recipient-white-list="addNewRecipientWhiteList"
      @modify-recipient-white-list="modifyExistingRecipientWhiteList"
    />
    <ModalAlertConfirmChoice
      ref="modalAlertConfirmChoice"
      :modal-id="modalIdAlertConfirm"
      alert-title="common.menu_are_you_sure"
      :alert-text="getModalAlertText"
      icon-component-name="AlertTriangleIcon"
      left-button-component-name="ButtonDismissModal"
      left-button-text="common.menu_cancel"
      left-button-custom-class="btn btn-outline-secondary mr-1"
      right-button-component-name="ButtonDismissModal"
      right-button-text="common.menu_remove"
      right-button-custom-class="btn btn-danger"
      @right-button="remove"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { getBoolPtiValue } from '../../dao/parametre-pti';
import { getAllRecipient } from '../../dao/recipient-dao';
import { getAllRecipientTakenIntoAccount } from '../../dao/recipient-taken-into-account';
import { getAllTlsRecipient } from '../../dao/tls-call-dao';
import {
  addWhitelistRecipient,
  updateWhitelistRecipient,
  getAllWhitelistRecipient,
  deleteWhitelistRecipient,
  deleteAllWhitelistRecipient,
} from '../../dao/whitelist-dao'
import InternalRecipientMirrorList from './local-components/lc-internal-recipient-mirror-list.vue'
import RecipientWhiteList from './local-components/lc-recipient-whitelist.vue'
import WhitelistSettings from './local-components/lc-whitelist-settings.vue'
import ModalAddModifyRecipientWhiteList from './local-components/lc-modal-add-modify-recipient-whitelist.vue'


const PARAM_PTI_IS_INTERNAL_RECIPIENT = "TraitementAppelEtSms";
const PARAM_PTI_IS_PEC = "TraitementSmsPriseEnCompteAppel";
const PARAM_PTI_IS_TLS = 'TraitementServiceTelesurveillance';

const NAME_DB_WHITELIST_INDEX = 1;
const PHONE_NUMBER_DB_WHITELIST_INDEX = 2;

const NAME_DB_RECIPIENT_INDEX = 1;
const PHONE_NUMBER_DB_RECIPIENT_INDEX = 2;
const SIP_ID_DB_RECIPIENT_INDEX = 14;

const REMOVE_ONE_ITEM = 0;
const REMOVE_EVERYTHING = 1;

const I18_REMOVE_ONE_RECIPIENT_WHITELIST = "whitelist.menu_remove_one_recipient_whitelist";
const I18_REMOVE_EVERYTHING = "whitelist.menu_remove_all_recipient_whitelist";

const BODY = "BODY";
const MODAL_ID_ADD_MODIFY_RECIPIENT_WHTIE_LIST = "modal-add-modify-recipient-whitelist";
const MODAL_ID_ALERT_CONFIRM = "modal-alert-confirm";



export default defineComponent({

  components: {
    ModalAddModifyRecipientWhiteList,
    WhitelistSettings,
    InternalRecipientMirrorList,
    RecipientWhiteList,
  },

  /**
   * Dismiss the modals if they're opened by clicking on their right buttons, 
   * and wait X milliseconds until the modals are correctly close by checking
   * if they're still present in the <body></body> HTML element, then route
   * to the new view.
   * 
   * Fix a bug when opening a modal and then using navigation arrows (next, prev).
   * The modal wasn't correctly closed and provoquing a crash with all the other modals
   * until next reload.
   */
  beforeRouteLeave (to, from, next) {
    this.$refs.modalAlertConfirmChoice.dismiss();
    this.$refs.modalAddModifyRecipientWhiteList.dismiss();
    let intervalId = setInterval(() => {  
      const modalAlertConfirm = document.getElementById(MODAL_ID_ALERT_CONFIRM);
      const modalAddModifyRecipientWhiteList = document.getElementById(MODAL_ID_ADD_MODIFY_RECIPIENT_WHTIE_LIST);
      if (modalAlertConfirm.parentElement.tagName.valueOf() != BODY
          && modalAddModifyRecipientWhiteList.parentElement.tagName.valueOf() != BODY) {
        clearInterval(intervalId);
        next();
      }
    }, 100);
	},

  data() {
    return {
      recipientMirrorList: [],
      recipientWhiteList: [],
      recipientToDeleteId: undefined,
      isTlsActivated: undefined,
      currentIndex: undefined,
      removeType: undefined,
      modalIdAddModifyRecipientWhiteList: MODAL_ID_ADD_MODIFY_RECIPIENT_WHTIE_LIST,
      modalIdAlertConfirm: MODAL_ID_ALERT_CONFIRM,
    }
  },

  computed: {
    getModalAlertText() {
      return this.removeType == REMOVE_ONE_ITEM ? 
          I18_REMOVE_ONE_RECIPIENT_WHITELIST : I18_REMOVE_EVERYTHING;
    },
  },

  created() {
    const isPecActivated = getBoolPtiValue(PARAM_PTI_IS_PEC);
    const isInternalRecipActivated = getBoolPtiValue(PARAM_PTI_IS_INTERNAL_RECIPIENT);
    this.isTlsActivated = getBoolPtiValue(PARAM_PTI_IS_TLS);
    this.initRecipientMirrorList(isInternalRecipActivated, isPecActivated, this.isTlsActivated);
    this.initRecipientWhitelist();
  },

  methods: {
    initRecipientWhitelist() {
      const whitelistFromDb = getAllWhitelistRecipient();
      if (whitelistFromDb)
        for (let i = 0; i < whitelistFromDb.values.length; ++i)
          this.recipientWhiteList.push(this.createRecipientWhitelist(whitelistFromDb.values[i]));
    },
    
    initRecipientMirrorList(isInternalRecipActivated, isPecActivated, isTlsActivated) {
      let daoGetAllRecipients;
      if (isTlsActivated)
        daoGetAllRecipients = getAllTlsRecipient;
      else if (isPecActivated && isInternalRecipActivated)
        daoGetAllRecipients = getAllRecipientTakenIntoAccount;
      else if (isInternalRecipActivated)
        daoGetAllRecipients = getAllRecipient;
      else
        return ;
      const recipientFromDb = daoGetAllRecipients();
      if (recipientFromDb)
        for (let i = 0; i < recipientFromDb.values.length; ++i)
          this.recipientMirrorList.push(this.createRecipientMirror(recipientFromDb.values[i]));
    },

    createRecipientWhitelist(whitelistRecipientFromDb) {
      return [
        whitelistRecipientFromDb[NAME_DB_WHITELIST_INDEX],
        whitelistRecipientFromDb[PHONE_NUMBER_DB_WHITELIST_INDEX]
      ]      
    },

    createRecipientMirror(recipientFromDb) {
      let recipient = [];
      recipient.push(recipientFromDb[NAME_DB_RECIPIENT_INDEX]);
      if (recipientFromDb[PHONE_NUMBER_DB_RECIPIENT_INDEX].length)
        recipient.push(recipientFromDb[PHONE_NUMBER_DB_RECIPIENT_INDEX]);
      else
        recipient.push(recipientFromDb[SIP_ID_DB_RECIPIENT_INDEX]);
      return recipient;
    },

    initModalModify(index) {
      this.currentIndex = index;
      this.$refs.modalAddModifyRecipientWhiteList.initEdit(index);
    },

    initModalConfirm(index) {
      this.currentIndex = index == undefined ? this.currentIndex : index;
      this.removeType = index == undefined ? REMOVE_EVERYTHING : REMOVE_ONE_ITEM;
    },

    addNewRecipientWhiteList(event) {
      addWhitelistRecipient(event.recipient);
      this.recipientWhiteList.push(event.recipient);
    },

    modifyExistingRecipientWhiteList(event) {
      updateWhitelistRecipient(event.recipient, event.id);
      this.recipientWhiteList[this.currentIndex] = event.recipient;
    },

    remove() {
      if (this.removeType === REMOVE_ONE_ITEM) {
        deleteWhitelistRecipient(this.recipientWhiteList[this.currentIndex]);
        this.recipientWhiteList.splice(this.currentIndex, 1)
      }
      else {
        this.recipientWhiteList = new Array();
        deleteAllWhitelistRecipient();
      }
    },

  }
  
})
</script>
