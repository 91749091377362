<template>
  <!-- BEGIN: FirstBootHelpNotif local component -->
  <div
    v-if="showNotif != false"
    class="p-3 rounded-md bg-gray-300 alert-dismissible show flex items-center font-medium"
  >
    <div class="flex-1">
      {{ $t("login.menu_first_device_boot_error") }}
      <a 
        :href="$t(deviceStr + 'help.menu_first_boot')"
        class="text-blue-600 visited:text-purple-600"
      >
        {{ $t("login.menu_please_read_quick_start_guide_first") }}
      </a>
    </div>    
    <button 
      type="button"
      class="flex-none pl-2"
      @click="$emit('hide-notif')" 
    >
      <XIcon 
        class="w-4 h-4"
      />
    </button>
  </div>
  <!-- END: FirstBootHelpNotif local component -->
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  
  props: {
    showNotif: {
      type: Boolean,
      required: true,
    },
    deviceStr: {
      type: String,
      required: true,
    }
  },

  emits: ['hide-notif'],
  
})
</script>
