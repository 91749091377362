<template>
  <!-- BEGIN: DeployDeviceId local component -->
  <div class="clearfix box">
    <BoxTitle
      title="deploy.menu_target_a_device"
      color="bg-green-200"
    />
    <div class="clearfix flex-row pl-3 pb-3 pr-3 border-b border-gray-200">
      <CustomTextInput
        v-for="(field, index) in deviceTargetFields"
        :key="index"  
        v-model="field.value"
        :title="field.title"
        :input-field-class="field.customClass"
        :max-length="field.maxLength"
        :error-msg="field.errorMsg"
        class="mt-2"
      />      
    </div>
  </div>
  <!-- END: DeployDeviceId local component -->
</template>

<script>
import { defineComponent } from 'vue'
import { useStore } from '@/store';
import { 
  STORE_SAVE_DEVICE_INFO, 
  STORE_SET_TARGET_DEVICE_INFO 
} from '../../../store/const_store';
import {
  setTargetDeviceOptionsValue,
} from '../../../dao/options-dao'
import {
  setTargetDeviceParameterPTIValue,
} from '../../../dao/parametre-pti'

const I18_DEVICE_IMEI_TITLE = "deploy.menu_five_last_digits_of_imei_number";
const I18_DEVICE_SN_TITLE = "deploy.menu_five_last_digits_of_serial_number";
const I18_USER_ID_TITLE = "internalrecipientssettings.menu_device_user";

const I18_ERR_FIELD_CANNOT_BE_EMPTY = "common.menu_field_cannot_be_empty";

const INPUT_FIELD_5_NUMBERS_CLASS = "form-control w-20 text-left";
const INPUT_FIELD_DEFAULT_CLASS = "form-control w-full text-left";

const INDEX_IMEI = 0;
const INDEX_SN = 1;

const PARAM_PTI_IDENTIFIANT = "Identifiant";
const PARAM_OPT_IMEI = "Imei";
const PARAM_OPT_SN = "SerialNumber";


export default defineComponent({

  data() {
    return {
      deviceTargetFields: [
        {
          title: I18_DEVICE_IMEI_TITLE,
          value: '',
          paramUpdateDb: PARAM_OPT_IMEI,
          functionUpdateDb: setTargetDeviceOptionsValue,
          customClass: INPUT_FIELD_5_NUMBERS_CLASS,
          maxLength: 5,
          errorMsg: '',
        },
        {
          title: I18_DEVICE_SN_TITLE,
          value: '',
          paramUpdateDb: PARAM_OPT_SN,
          functionUpdateDb: setTargetDeviceOptionsValue,
          customClass: INPUT_FIELD_5_NUMBERS_CLASS,
          maxLength: 5,
          errorMsg: '',
        },
        {
          title: I18_USER_ID_TITLE,
          value: '',
          paramUpdateDb: PARAM_PTI_IDENTIFIANT,
          functionUpdateDb: setTargetDeviceParameterPTIValue,
          customClass: INPUT_FIELD_DEFAULT_CLASS,
          maxLength: 50,
          errorMsg: '',
        },
      ],
    }
  },
  
  methods: {
    check() {
      let checkStatus = true;
      for (let i = 0; i < this.deviceTargetFields.length; ++i) {
        if (!this.deviceTargetFields[i].value.length) {
          this.deviceTargetFields[i].errorMsg = I18_ERR_FIELD_CANNOT_BE_EMPTY;
          checkStatus = false;
        }
        else {
          this.deviceTargetFields[i].errorMsg = '';
        }
      }
      return checkStatus;
    },

    save() {
      for (let i = 0; i < this.deviceTargetFields.length; ++i) {
        let field = this.deviceTargetFields[i];
        field.functionUpdateDb(field.paramUpdateDb, field.value);
      }
    },

    updateTargetDeviceInfo() {
      const targetDeviceInfo = {
        deviceModelName: useStore().state.emeritValues.deviceInfo.deviceModelName,
        deviceIMEI: this.deviceTargetFields[INDEX_IMEI].value,
        deviceSerialNumber: this.deviceTargetFields[INDEX_SN].value,
      };
      this.$store.commit(STORE_SET_TARGET_DEVICE_INFO, targetDeviceInfo);
    },

    updateDeviceInfo() {
      const deviceInfo = {
        deviceModelName: useStore().state.emeritValues.deviceInfo.deviceModelName,
        deviceIMEI: this.deviceTargetFields[INDEX_IMEI].value,
        deviceSerialNumber: this.deviceTargetFields[INDEX_SN].value,
      };
      this.$store.commit(STORE_SAVE_DEVICE_INFO, deviceInfo);
    },

    resetImeiSnField() {
      this.deviceTargetFields[INDEX_IMEI].value = '';
      this.deviceTargetFields[INDEX_SN].value = '';
    }
  }

})
</script>
