<template>
  <!-- BEGIN: WifiHotspotSettings local component -->
  <div class="intro-y col-span-12 lg:col-span-6 lg:col-start-1 2xl:col-span-5 2xl:col-start-1">
    <BoxTitle
      color="bg-red-300"
      title="common.menu_settings"
    />
    <div class="clearfix box mb-6">
      <div class="clearfix p-3 border-b border-gray-200">
    
        <div class="col-span-8 col-start-1 mt-3 font-bold text-theme-0">
        
            {{ $t("locationindoorwifi.menu_title_refresh_choice") }}
        </div>
      <div>
      </div>
        <div class="col-span-8 col-start-1 mt-3">
          <CustomRadioInput
            v-for="(radio, index) in radioInputParams"
            :key="index"
            v-model="wifiRefreshMode"
            :custom-id="radioGroupName + index"
            :description="radio.description"
            :radio-group-name="radioGroupName"
            :value="index"
          />
        </div>
         
      </div>
    </div>
  </div>
  <!-- END: WifiHotspotSettings local component -->
</template>

<script>
import { defineComponent } from 'vue'
import {
  getParameterPTIValue,
  setParameterPTIValue
} from "../../../dao/parametre-pti";

const NO_HOTSPOTS_SAVED = "locationindoorwifi.menu_no_wifi_hotspot_recorded";
const NB_HOTSPOT_SAVED = "locationindoorwifi.menu_number_of_registered_wifi_hotspots";
const REMOVE_ONE_ITEM = 0;
const REMOVE_EVERYTHING = 1;


export default defineComponent({

  created(){
     this.wifiRefreshMode = getParameterPTIValue("isWifiLocRefreshUpdateEnabled");
  },

   mounted() {
    document.getElementById(this.radioGroupName + this.wifiRefreshMode).checked = true;
  },

  data() {
    return {
      wifiRefreshMode: undefined,
      radioGroupName: 'radioOutdoor',
       radioInputParams: [
        { description: 'locationoutdoor.menu_refreseh_only_when_event_sending' },
        { description: 'locationoutdoor.menu_update_at_regular_intervals' },
        ],
      showImportExportButtons: false,
      removeEverything: REMOVE_EVERYTHING,
    }
  },


  methods: {
      save() {
        setParameterPTIValue("isWifiLocRefreshUpdateEnabled", this.wifiRefreshMode);
        return true;
    }
  },


})
</script>
