<template>
  <!-- BEGIN: FieldXHorizontalCheckBoxes component-->
  <div class="clearfix border-b border-gray-200 pt-3">
    <div 
      v-if="title"
      class="text-gray-700 ml-3"
    >
      {{ $t(title) }}
    </div>
    <div class="flex flex-col sm:flex-row pt-2 ml-3 mb-3">
      <div 
        v-for="(elem, index) in vmodelValues"
        :key="index"
        class="form-check mr-5 sm:mt-0"
      >
        <input
          :id="ids[index]"
          :checked="elem"
          type="checkbox"
          class="form-check-input float-left"
          @change="onCheckBoxChange(index, $event.target.checked)"
        >
        <label 
          class="form-check-label"
          :for="ids[index]"
        >
          {{ $t(descriptions[index] ) }}
        </label>
      </div>
    </div>
    <ErrorMsg
      class="pl-3 mb-2"
      :error-msg="errorMsg"
    />
  </div>
  <!-- END: FieldXHorizontalCheckBoxes component-->
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A field with a text, and under it several horizontal checkboxes,
 * each one being associated with a clickable description. v-model MUST 
 * be used on this component, it will be updated with checkboxes values 
 * each time the user clicks on one checkbox.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {Array[Boolean]} modelValue
 *    Values of the checkboxes. Automatically sets when using v-model on this
 *    component. For example, v-model="parentVariable" will bind this 
 *    modelValue to parentVariable. Checkboxes will be initialized with 
 *    parentVariable values.
 * 
 * @param {String} title
 *    The title above the checkboxes describing them. Should be key 
 *    for i18 locale. If empty, nothing appears. Empty by default, 
 *    if no title is needed do not fill it.
 * 
 * @param {Array[String]} descriptions
 *    The descriptions associated with each checkbox. Should be keys 
 *    for i18 locale.
 * 
 * @param {Array[String]} ids
 *    HTML ids for each checkbox, in order to link the descriptions to
 *    them. When user clicks on a description, it will checked
 *    the associated checkbox. Each ID must be unique on the view.
 * 
 * @param {String} errorMsg
 *    If empty, nothing appears. Otherwise, this string will appear 
 *    as an error in red to the user. Should be key for i18 locale.
 *    Empty by default, if no error management is needed do not fill it.
 * 
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits update:modelValue
 * @return {Array[Boolean]}
 *    Emits the values of the checkboxes (true or false). When v-model is
 *    used on this component, v-model automatically update with those
 *    values.
 *                    
 * ------------------------------------------------------------------
 */
export default defineComponent({

  props: {
    title: {
      type: String,
      default: undefined,
    },
    descriptions: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    ids: {
      type: Array,
      required: true,
    },
    errorMsg: {
      type: String,
      default: '',
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      vmodelValues: [],
    }
  },

  watch: {
    modelValue: {
      deep: true,
      handler: function updateCheckBoxesValues() {
        for (let i = 0; i < this.modelValue.length; ++i)  
          this.vmodelValues[i] = this.modelValue[i];
      }
    }
  },

  created() {
    for (let i = 0; i < this.modelValue.length; ++i)
      this.vmodelValues[i] = this.modelValue[i];
  },

  methods: {
    onCheckBoxChange(index, newValue) {
      this.vmodelValues[index] = newValue;
      this.$emit('update:modelValue', this.vmodelValues);
    },
  },
  
})
</script>
