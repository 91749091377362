import { getBleBeacons } from '../../../dao/indoor-ble-dao';
import {
  setError,
  resetError,
  regexCheck
} from '../../../utils/fieldManagement'


const COMP_ID = 0;
const BEACON_ID = 1;
const WRONG_CODE_ZONE = "000";

const NAMESPACE_ID_LEN = 20;
const MAC_ADDRESS_LEN = 17;

const DB_COMPANY_ID_COLUMN_INDEX = 1;
const DB_BEACON_ID_COLUMN_INDEX = 2;
const DB_CODE_ZONE_COLUMN_INDEX = 3;
const DB_DESC_COLUMN_INDEX = 4;
const DB_LEVEL_COLUMN_INDEX = 5;
const DB_TYPE_COLUMN_INDEX = 6;

export const BBE9_UUID = "4D41474E54414027BDC419D9D8CE069E"
export const LOC_EX_UUID = "2673DAF8E45E4D85A91D6DF91D9BCD79"

export const MODEL_BBE9 = "e-BBE9";
export const MODEL_LOC_EX = "LOC-EX 01";
export const MODEL_IBEACON = "iBeacon";
export const MODEL_EDDY = "Eddystone";


export const checkMacAddress = (fields, index, errorMsg) => {
  const macAddress = fields[index].value.toUpperCase();
  if (!macAddress.length) 
    return setError(fields, index, errorMsg);
  for (let i = 0; i < MAC_ADDRESS_LEN; ++i) {
    if ((!((i + 1) % 3) && macAddress[i] != ':')
        || ((i + 1) % 3) && !String(macAddress[i]).match(/[A-F0-9]/))
      return setError(fields, index, errorMsg);
  }
  return resetError(fields, index);
}

export const checkLevel = (hideLevel, fields, index, errorMsg) => {
  if (hideLevel)
    return true;
  const level = fields[index].value;
  if (!level.length || !regexCheck(level, /^(?:-?\d+)?$/))
    return setError(fields, index, errorMsg);
  return resetError(fields, index);
}

export const checkCodeZone = (fields, index, errorMsg) => {
  const codeZone = fields[index].value;
  if (!regexCheck(codeZone, /^[0-9]{3}$/) || codeZone.valueOf() === WRONG_CODE_ZONE)
    return setError(fields, index, errorMsg);
  return resetError(fields, index);
}

export const checkDescription = (fields, index, errorMsg) => {
  const desc = fields[index].value;
  if (desc.length < 1 || desc.length > 50)
    return setError(fields, index, errorMsg);
  return resetError(fields, index);
}

export const beaconAlreadyExisting = (beaconList, companyId, beaconId, fields, errorMsg) => {
  for (let i = 0; i < beaconList.length; ++i) {
    if (beaconList[i][COMP_ID].valueOf() === companyId.toUpperCase().valueOf()
        && beaconList[i][BEACON_ID].valueOf() === beaconId.toUpperCase().valueOf())
      return !setError(fields, COMP_ID, errorMsg);
  }
  return false;
}

export const formatLevel = level => {
  let i = 0;
  if (level[i] === '-')
    ++i;
  while (i < level.length - 1 && level[i] === '0')
    ++i;
  if (i && level[0] != '-')
    level = level.substr(i);
  else if (i)
    level = '-' + level.substr(i);
  if (level === "-0")
    level = "0";
  return level;
}

export const getModel = (companyId) => {
  if (companyId.length === NAMESPACE_ID_LEN)
    return MODEL_EDDY;
  else if (companyId.valueOf() === BBE9_UUID)
    return MODEL_BBE9;
  else if (companyId.valueOf() === LOC_EX_UUID)
    return MODEL_LOC_EX;
  return MODEL_IBEACON;
}

export const beaconModifiedAlreadyExisting = (currentIndex, beaconList, 
    companyId, beaconId, fields, errorMsg) => {
  for (let i = 0; i < beaconList.length; ++i) {
    if (i != currentIndex 
        && beaconList[i][COMP_ID].valueOf() === companyId.toUpperCase().valueOf()
        && beaconList[i][BEACON_ID].valueOf() === beaconId.toUpperCase().valueOf())
      return !setError(fields, COMP_ID, errorMsg);
  }
  return false;
}

export const createBeaconFromDbEntry = (dbBeacon) => {
  return [
    dbBeacon[DB_COMPANY_ID_COLUMN_INDEX].toUpperCase(),
    dbBeacon[DB_BEACON_ID_COLUMN_INDEX].toUpperCase(),
    dbBeacon[DB_CODE_ZONE_COLUMN_INDEX],
    dbBeacon[DB_DESC_COLUMN_INDEX],
    dbBeacon[DB_LEVEL_COLUMN_INDEX],
    dbBeacon[DB_TYPE_COLUMN_INDEX],
  ]
};

export const isBbe9BeaconRegisteredInDb = () => {
  const dbBeacons = getBleBeacons();
  if (dbBeacons == undefined)
    return false;
  for (let i = 0; i < dbBeacons.values.length; ++i) {
    let beacon = createBeaconFromDbEntry(dbBeacons.values[i]);
    if (beacon[COMP_ID].valueOf() === BBE9_UUID.valueOf())
      return true;
  }
  return false;
};