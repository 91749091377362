import { useStore } from "@/store";

export const addRecipientTakenIntoAccount = recipient => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'INSERT INTO  destinataire_sms_prise_en_compte (nom,numero,p_batterie_basse,p_marche_arret,p_station_accueil,p_demande_localisation,id_tri,p_post_alarme,p_pti,p_beacon_batterie_basse)  VALUES( "' +
    recipient.name +
    '", "' +
    recipient.number +
    '", "' +
    Number(recipient.pLowBattery) +
    '", "' +
    Number(recipient.pPowerOnOff) +
    '", "' +
    Number(recipient.pChargeStation) +
    '", "' +
    Number(recipient.pLoc) +
    '", "' +
    Number(recipient.sortId) +
    '", "' +
    Number(recipient.pPostAlarm) +
    '", "' +
    Number(recipient.pPti) +
    '", "' +
    Number(recipient.pBleBeaconLowBattery) +
    '")';

  try {
    return dbFile.exec(sqlScript);
  } catch (error) {
    console.log('addRecipientTakenIntoAccount failed: ' + error);
  }
};

export const getAllRecipientTakenIntoAccount = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * from destinataire_sms_prise_en_compte";
  try {
    var recipient = dbFile.exec(sqlScript)[0];
    return recipient;
  } catch (error) {
    console.log('getAllRecipientTakenIntoAccount failed: ' + error);
  }
};

export const getRecipientTakenIntoAccount = id => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * from destinataire_sms_prise_en_compte";
  try {
    var recipient = dbFile.exec(sqlScript)[0].values[id];
    return recipient;
  } catch (error) {
    console.log('getRecipientTakenIntoAccount failed: ' + error);
  }
};

export const updateRecipientTakenIntoAccount = recipient => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'UPDATE destinataire_sms_prise_en_compte SET nom="' +
    recipient.name +
    '", numero="' +
    recipient.number +
    '",p_batterie_basse="' +
    Number(recipient.pLowBattery) +
    '",p_marche_arret="' +
    Number(recipient.pPowerOnOff) +
    '",p_station_accueil="' +
    Number(recipient.pChargeStation) +
    '",p_demande_localisation="' +
    Number(recipient.pLoc) +
    '",id_tri="' +
    Number(recipient.sortId) +
    '",p_post_alarme="' +
    Number(recipient.pPostAlarm) +
    '",p_pti="' +
    Number(recipient.pPti) +
    '",p_beacon_batterie_basse="' +
    Number(recipient.pBleBeaconLowBattery) +
    '" where _id="' +
    recipient.id +
    '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('updateRecipientTakenIntoAccount failed: ' + error);
  }
};

export const getCountRecipientTakenIntoAccount = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT COUNT(*) FROM destinataire_sms_prise_en_compte";
  try {
    return Number(dbFile.exec(sqlScript)[0].values[0]);
  } catch (error) {
    console.log('getCountRecipientTakenIntoAccount failed: ' + error);
  }
};

export const deleteRecipientTakenIntoAccount = id => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'DELETE FROM destinataire_sms_prise_en_compte where _id="' + id + '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('deleteRecipientTakenIntoAccount failed: ' + error);
  }
};

export const deleteAllRecipientTakenIntoAccount = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    "DELETE FROM destinataire_sms_prise_en_compte";
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('deleteAllRecipientTakenIntoAccount failed: ' + error);
  }
};

export const getLastRecipientTakenInAccountIdCreated = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript = "SELECT * FROM destinataire_sms_prise_en_compte ORDER BY _id DESC LIMIT 1";
  try {
    return dbFile.exec(sqlScript)[0].values[0][0];
  }
  catch (error) {
    console.log('getLastRecipientTakenInAccountIdCreated failed: ' + error);
  }
}

export const resetRecipientsTakenInAccountBeaconBleLowBatteryEvent = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript = "UPDATE destinataire_sms_prise_en_compte SET p_beacon_batterie_basse=0";
  try {
    dbFile.exec(sqlScript);
  }
  catch (error) {
    console.log('resetRecipientsTakenInAccountBeaconBleLowBatteryEvent failed: ' + error);
  }
}