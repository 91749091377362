/* eslint-disable */
<template>
  <div>
    <div class="intro-y flex items-center mt-6">
      <h2 class="text-lg font-medium mr-auto">
        <label>{{
          $t(
            "wearingways.menu_select_a_wearing_way_and_modify_settings_if_needed"
          )
        }}</label>
      </h2>
    </div>

    <div class="grid grid-cols-12 gap-6 mt-3">
      <!-- BEGIN: left column -->
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: wearing-style -->
        <div class="clearfix box p-3">
          <div v-if="isEwg100" class="clearfix">
            <input
              id="radio-switch-5"
              v-model="wearingStyle"
              type="radio"
              class="form-check-input float-left"
              name="radio_button-wear"
              value="1"
              @change="onWearingStyleChange"
            />
            <label class="form-check-label text-md" for="radio-switch-5">
              {{ $t("configuration.menu_wearing_style_wrist") }}
            </label>
          </div>
          <div class="clearfix mt-3 ">
            <input
              id="radio-switch-6"
              v-model="wearingStyle"
              type="radio"
              class="form-check-input float-left"
              name="radio_button-wear"
              value="2"
              @change="onWearingStyleChange"
            />
            <label class="form-check-label text-md" for="radio-switch-6">
              {{ $t("configuration.menu_wearing_style_waist") }}
            </label>
          </div>
          <div class="clearfix  mt-3 ">
            <input
              id="radio-switch-7"
              v-model="wearingStyle"
              type="radio"
              class="form-check-input float-left"
              name="radio_button-wear"
              value="3"
              @change="onWearingStyleChange"
            />
            <label class="form-check-label text-md" for="radio-switch-7">
              {{ $t("configuration.menu_wearing_style_arms") }}
            </label>
          </div>
          <div v-if="isEwg100" class="clearfix mt-3 ">
            <input
              id="radio-switch-8"
              v-model="wearingStyle"
              type="radio"
              class="form-check-input float-left"
              name="radio_button-wear"
              value="4"
              @change="onWearingStyleChange"
            />
            <label class="form-check-label text-md" for="radio-switch-8">
              {{ $t("configuration.menu_wearing_style_neck") }}</label
            >
          </div>
        </div>
        <!-- END: wearing-style -->
        <!-- BEGIN: immobilite -->
        <div class="clearfix box mt-6">
          <!-- entete de parametre avec switch box -->
          <div
            class="flex flex-col sm:flex-row pt-3 p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div class="form-check w-full">
              <input
                id="show-example-1"
                v-model="immobilitychecked"
                name="show-immobility-settings"
                data-target="#input"
                class="show-code form-check-switch bg-white"
                type="checkbox"
                @change="onImmobilitySwitchChange"
              />
              <div>
                <h2
                  v-if="!immobilitychecked"
                  class="font-medium text-base pl-3"
                >
                  {{ $t("wearingways.menu_enable_no_movement_detection") }}
                </h2>
              </div>
              <div>
                <h2 v-if="immobilitychecked" class="font-medium text-base pl-3">
                  {{ $t("wearingways.menu_no_movement_detection") }}
                </h2>
              </div>
            </div>
          </div>
          <!-- BEGUIN: bloc parametres masquable -->
          <div v-if="immobilitychecked">
            <!-- BEGUIN: duree de detection -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("wearingways.menu_detection_duration_before_prealert") }}
              </label>
              <div class="input-group pb-1 mt-2">
                <input
                  v-model="ImmobilityDetectionDurationRange"
                  type="range"
                  :min="ImmobilityDetectionDurationMin"
                  :max="ImmobilityDetectionDurationMax"
                  step="5"
                />
                <div class="form-control w-20 text-center ml-5">
                  {{ ImmobilityDetectionDurationRange }}
                </div>
                <div id="input-group-3" class="input-group-text h-10 font-sm">
                  {{ $t("common.menu_seconds") }}
                </div>
              </div>
            </div>
            <!-- END: duree de detection -->
            <!-- BEGUIN: duree de notification -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("common.menu_notification_duration") }}
              </label>
              <div class="input-group pb-1 mt-2">
                <input
                  v-model="ImmobilityNotificationDurationRange"
                  type="range"
                  :min="ImmobilityNotificationDurationMin"
                  :max="ImmobilityNotificationDurationMax"
                  :step="step"
                />
                <div class="form-control w-20 text-center ml-5">
                  {{ ImmobilityNotificationDurationRange }}
                </div>
                <div id="input-group-3" class="input-group-text h-10 font-sm">
                  {{ $t("common.menu_seconds") }}
                </div>
              </div>
              <div class="flex flex-col">
                <div class="form-check mt-3 mr-3 ">
                  <input
                    id="checkbox-switch-1"
                    v-model="isImmobilityVibrationChecked"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-1">
                    {{ $t("common.menu_notification_by_vibration") }}
                  </label>
                </div>
                <div class="form-check mt-3 mr-3">
                  <input
                    id="checkbox-switch-2"
                    v-model="isImmobilitySoundChecked"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-2">
                    {{ $t("common.menu_notification_by_sound") }}
                  </label>
                </div>
                <div v-if="isNotificationFlash" class="form-check mt-3 mr-3">
                  <input
                    id="checkbox-switch-3"
                    v-model="isImmobilityFlashChecked"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-3">
                    {{ $t("common.menu_notification_by_flash") }}
                  </label>
                </div>
              </div>
            </div>
            <p v-if="this.tabErrorNotifications[0]">
              {{
                $t("alerttrigerring.menu_select_at_least_one_notification_mode")
              }}
            </p>
            <!-- END: duree de notification -->
          </div>
          <!-- END: bloc parametres masquable -->
        </div>
        <!-- END: immobilite -->
        <!-- BEGIN: inclinaison -->
        <div class="clearfix box mt-6">
          <!-- entete de parametre avec switch box -->
          <div
            class="flex flex-col sm:flex-row pt-3 p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div class="form-check w-full">
              <input
                id="show-example-1"
                v-model="tiltchecked"
                name="show-tilt-settings"
                data-target="#input"
                class="show-code form-check-switch bg-white"
                type="checkbox"
                @change="onTiltSwitchChange"
              />
              <div>
                <h2 v-if="!tiltchecked" class="font-medium text-base pl-3">
                  {{ $t("wearingways.menu_enable_tilt_detection") }}
                </h2>
              </div>
              <div>
                <h2 v-if="tiltchecked" class="font-medium text-base pl-3">
                  {{ $t("wearingways.menu_tilt_detection") }}
                </h2>
              </div>
            </div>
          </div>
          <!-- BEGUIN: bloc parametres masquable -->
          <div v-if="tiltchecked">
            <!-- BEGUIN: mode de detection -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("wearingways.menu_detection_mode") }}
              </label>
              <div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    v-model="detectionMode"
                    class="form-check-input"
                    type="radio"
                    value="1"
                  />
                  <label class="form-check-label" for="radio-switch-1">
                    {{ $t("wearingways.menu_tilt_without_movement") }}
                  </label>
                </div>
                <div class="form-check mt-3">
                  <input
                    id="radio-switch-2"
                    v-model="detectionMode"
                    class="form-check-input"
                    type="radio"
                    value="2"
                  />
                  <label class="form-check-label" for="radio-switch-2">
                    {{ $t("wearingways.menu_tilt_only") }}
                  </label>
                </div>
              </div>
            </div>
            <!-- END: mode de detection -->
            <!-- BEGUIN: seuil de detection -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("wearingways.menu_detection_threshold") }}
              </label>
              <div class="input-group pb-1 mt-2">
                <input
                  v-model="tiltThresholdRange"
                  type="range"
                  :min="tiltThresholdMin"
                  :max="tiltThresholdMax"
                  step="5"
                />
                <div class="form-control w-20 text-center ml-5">
                  {{ tiltThresholdRange }}
                </div>
                <div id="input-group-3" class="input-group-text h-10 font-sm">
                  {{ $t("common.menu_degrees") }}
                </div>
              </div>
            </div>
            <!-- END: seuil de detection -->
            <!-- BEGUIN: duree de detection -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("wearingways.menu_detection_duration_before_prealert") }}
              </label>
              <div class="input-group pb-1 mt-2">
                <input
                  v-model="tiltDetectionDurationRange"
                  type="range"
                  :min="tiltDetectionDurationMin"
                  :max="tiltDetectionDurationMax"
                  step="5"
                />
                <div class="form-control w-20 text-center ml-5">
                  {{ tiltDetectionDurationRange }}
                </div>
                <div id="input-group-3" class="input-group-text h-10 font-sm">
                  {{ $t("common.menu_seconds") }}
                </div>
              </div>
            </div>
            <!-- END: duree de detection -->
            <!-- BEGUIN: duree de notification -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("common.menu_notification_duration") }}
              </label>
              <div class="input-group pb-1 mt-2">
                <input
                  v-model="tiltNotificationDurationRange"
                  type="range"
                  :min="tiltNotificationDurationMin"
                  :max="tiltNotificationDurationMax"
                  step="5"
                />
                <div class="form-control w-20 text-center ml-5">
                  {{ tiltNotificationDurationRange }}
                </div>
                <div id="input-group-3" class="input-group-text h-10 font-sm">
                  {{ $t("common.menu_seconds") }}
                </div>
              </div>
              <div class="flex flex-col">
                <div class="form-check mt-3 mr-3 ">
                  <input
                    id="checkbox-switch-4"
                    v-model="isTiltVibrationChecked"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-4">
                    {{ $t("common.menu_notification_by_vibration") }}
                  </label>
                </div>
                <div class="form-check mt-3 mr-3">
                  <input
                    id="checkbox-switch-5"
                    v-model="isTiltSoundChecked"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-5">
                    {{ $t("common.menu_notification_by_sound") }}
                  </label>
                </div>
                <div v-if="isNotificationFlash" class="form-check mt-3 mr-3">
                  <input
                    id="checkbox-switch-6"
                    v-model="isTiltFlashChecked"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-6">
                    {{ $t("common.menu_notification_by_flash") }}
                  </label>
                </div>
                <p v-if="this.tabErrorNotifications[1]">
                  {{
                    $t(
                      "alerttrigerring.menu_select_at_least_one_notification_mode"
                    )
                  }}
                </p>
              </div>
            </div>
            <!-- END: duree de notification -->
          </div>
          <!-- END: bloc parametres masquable -->
        </div>
        <!-- END: inclinaison -->
      </div>
      <!-- END: left column -->
    </div>
    <p v-if="this.tabErrorNotifications[3]">
                  {{
                    $t(
                      "alerttrigerring.menu_enable_selecte_switch"
                    )
                  }}
                </p>
    <button
      type="submit"
      class="btn btn-primary mt-6"
      @click="onSaveButtonClicked"
    >
      {{ $t("common.menu_save") }}
    </button>
  </div>
</template>

<script>
const GPS_OFF = 0;
const GPS_OUTDOOR_ONLY = 1;

import {
  getParameterPTIMin,
  getParameterPTIMax,
  getParameterPTIValue,
  setParameterPTIValue
} from "../../dao/parametre-pti";
import { useStore } from "@/store";

export default {
  data() {
    return {
      locationChecked: GPS_OFF,
      isWaist: false,
      isWrist: false,
      isArm: false,
      isNeck: false,
      wearingStyle: false,
      immobilitychecked: false,
      tiltchecked: false,
      ImmobilityNotificationDurationRange: null,
      ImmobilityNotificationDurationMin: false,
      ImmobilityNotificationDurationMax: false,
      ImmobilityDetectionDurationRange: null,
      ImmobilityDetectionDurationMin: false,
      ImmobilityDetectionDurationMax: false,
      tiltDetectionDurationRange: null,
      tiltDetectionDurationMin: false,
      tiltDetectionDurationMax: false,
      tiltThresholdRange: null,
      tiltThresholdMin: false,
      tiltThresholdMax: false,
      tiltNotificationDurationRange: null,
      tiltNotificationDurationMin: false,
      tiltNotificationDurationMax: false,
      isImmobilityVibrationChecked: false,
      isImmobilitySoundChecked: false,
      isImmobilityFlashChecked: false,
      isTiltVibrationChecked: false,
      isTiltFlashChecked: false,
      isTiltSoundChecked: false,
      detectionMode: 1,
      detectionModeTiltOnly: false,
      detectionModeTiltAndImmobility: false,
      tiltAndImmobility: false,
      tiltAndOrImmobility: false,
      tiltOrImmobility: false,
      tilt: false,
      tabErrorNotifications: [false, false, false],
      step: 5,
      isEwg100: false,
      isNotificationFlash: false
    };
  },

  mounted() {
    this.updateUI();
  },
  methods: {
    updateUI() {
      if(useStore().state.emeritValues.deviceInfo.deviceModelName == "E-WG100"){
        this.isEwg100 = true
        this.isNotificationFlash = false
      } else {
        this.isEwg100 = false
        this.isNotificationFlash = true       
      }

      // port
      if ((getParameterPTIValue("PortAuPoignet")) == 1) {
        this.wearingStyle = 1;
      } else if (( getParameterPTIValue("PortALaTaille")) == 1) {
        this.wearingStyle = 2;
      } else if ((getParameterPTIValue("PortAuBras")) == 1) {
        this.wearingStyle = 3;
      } else if ((getParameterPTIValue("PortAuCou")) == 1) {
        this.wearingStyle = 4;
      }

      // absence de mouvement
      if (
        (getParameterPTIValue("AbsenceMouvement")) ==
          1 ||
        (getParameterPTIValue(
          "PerteVerticaliteOuMouvement"
        )) == 1 ||
        (getParameterPTIValue(
          "PerteVerticaliteEtOuMouvement"
        )) == 1
        ) {
        this.immobilitychecked = true;

        this.ImmobilityNotificationDurationRange = getParameterPTIValue(
          "AbsenceMouvementTempsNotification"
        );
        this.ImmobilityNotificationDurationMin = getParameterPTIMin(
          "AbsenceMouvementTempsNotification"
        );
        this.ImmobilityNotificationDurationMax = getParameterPTIMax(
          "AbsenceMouvementTempsNotification"
        );
        this.ImmobilityDetectionDurationRange = getParameterPTIValue(
          "AbsenceMouvementTempsDetection"
        );
        this.ImmobilityDetectionDurationMin = getParameterPTIMin(
          "AbsenceMouvementTempsDetection"
        );
        this.ImmobilityDetectionDurationMax = getParameterPTIMax(
          "AbsenceMouvementTempsDetection"
        );
        }
        this.isImmobilityVibrationChecked = this.intToBoolean(getParameterPTIValue("AbsenceMouvementNotificationVibration"));
        this.isImmobilitySoundChecked = this.intToBoolean(getParameterPTIValue("AbsenceMouvementNotificationSon"));
        this.isImmobilityFlashChecked = this.intToBoolean(getParameterPTIValue("AbsenceMouvementNotificationFlash"));
       

      // perte de verticalité
      if (
        (getParameterPTIValue("PerteVerticalite")) == 1 ||
        (getParameterPTIValue(
          "PerteVerticaliteEtMouvement"
        )) == 1 ||
        (getParameterPTIValue(
          "PerteVerticaliteEtOuMouvement"
        )) == 1 ||
        (getParameterPTIValue(
          "PerteVerticaliteOuMouvement"
        )) == 1
      ) {
        this.tiltchecked = true;
        this.tiltNotificationDurationRange = getParameterPTIValue(
          "PerteVerticaliteTempsNotification"
        );
        this.tiltThresholdRange = getParameterPTIValue("PerteVerticaliteAngle");
        this.tiltThresholdMin = getParameterPTIMin("PerteVerticaliteAngle");
        this.tiltThresholdMax = getParameterPTIMax("PerteVerticaliteAngle");
        this.tiltNotificationDurationMin = getParameterPTIMin(
          "PerteVerticaliteTempsNotification"
        );
        this.tiltNotificationDurationMax = getParameterPTIMax(
          "PerteVerticaliteTempsNotification"
        );
        this.tiltDetectionDurationRange = getParameterPTIValue(
          "PerteVerticaliteTempsDetection"
        );
        this.tiltDetectionDurationMin = getParameterPTIMin(
          "PerteVerticaliteTempsDetection"
        );
        this.tiltDetectionDurationMax = getParameterPTIMax(
          "PerteVerticaliteTempsDetection"
        );
        if (getParameterPTIValue("PerteVerticaliteNotificationVibration") == 1) {
        this.isTiltVibrationChecked = true;
        }

        if (getParameterPTIValue("PerteVerticaliteNotificationSon") == 1) {
        this.isTiltSoundChecked = true;
        }

        if (getParameterPTIValue("PerteVerticaliteNotificationFlash") == 1) {
        this.isTiltFlashChecked = true;
        }
        if (
          (getParameterPTIValue(
            "PerteVerticaliteEtMouvement"
          )) == 1 ||
          (getParameterPTIValue(
            "PerteVerticaliteEtOuMouvement"
          )) == 1
        ) {
          this.detectionMode = 1;
        } else if (
          (getParameterPTIValue("PerteVerticalite")) == 1
        ) {
          this.detectionMode = 2;
        }
      } else {
        this.tiltchecked = false;
      }
    },

    onImmobilitySwitchChange() {
      this.ImmobilityNotificationDurationRange = getParameterPTIValue(
        "AbsenceMouvementTempsNotification"
      );
      this.ImmobilityNotificationDurationMin = getParameterPTIMin(
        "AbsenceMouvementTempsNotification"
      );
      this.ImmobilityNotificationDurationMax = getParameterPTIMax(
        "AbsenceMouvementTempsNotification"
      );
      this.ImmobilityDetectionDurationRange = getParameterPTIValue(
        "AbsenceMouvementTempsDetection"
      );
      this.ImmobilityDetectionDurationMin = getParameterPTIMin(
        "AbsenceMouvementTempsDetection"
      );
      this.ImmobilityDetectionDurationMax = getParameterPTIMax(
        "AbsenceMouvementTempsDetection"
      );

        this.isImmobilityVibrationChecked = this.intToBoolean(getParameterPTIValue("AbsenceMouvementNotificationVibration"));
        this.isImmobilitySoundChecked = this.intToBoolean(getParameterPTIValue("AbsenceMouvementNotificationSon"));
        this.isImmobilityFlashChecked = this.intToBoolean(getParameterPTIValue("AbsenceMouvementNotificationFlash"));
    },

    onTiltSwitchChange() {
      this.tiltNotificationDurationRange = getParameterPTIValue(
        "PerteVerticaliteTempsNotification"
      );
      this.tiltThresholdRange = getParameterPTIValue("PerteVerticaliteAngle");
      this.tiltThresholdMin = getParameterPTIMin("PerteVerticaliteAngle");
      this.tiltThresholdMax = getParameterPTIMax("PerteVerticaliteAngle");
      this.tiltNotificationDurationMin = getParameterPTIMin(
        "PerteVerticaliteTempsNotification"
      );
      this.tiltNotificationDurationMax = getParameterPTIMax(
        "PerteVerticaliteTempsNotification"
      );
      this.tiltDetectionDurationRange = getParameterPTIValue(
        "PerteVerticaliteTempsDetection"
      );
      this.tiltDetectionDurationMin = getParameterPTIMin(
        "PerteVerticaliteTempsDetection"
      );
      this.tiltDetectionDurationMax = getParameterPTIMax(
        "PerteVerticaliteTempsDetection"
      );
      if(this.isButtonloudSpeakerPost == true){
        setParameterPTIValue("PerteVerticaliteNotificationVibration", 1);
      }else {
        setParameterPTIValue("PerteVerticaliteNotificationVibration", 0);
      }

      if(this.isTiltFlashChecked == true){
        setParameterPTIValue("PerteVerticaliteNotificationSon", 1);
      }else {
        setParameterPTIValue("PerteVerticaliteNotificationSon", 0);
      }

      if(this.isTiltSoundChecked == true){
        setParameterPTIValue("PerteVerticaliteNotificationFlash", 1);
      }else {
        setParameterPTIValue("PerteVerticaliteNotificationFlash", 0);
      }
      if (
        (getParameterPTIValue(
          "PerteVerticaliteEtMouvement"
        )) == 1 ||
        (getParameterPTIValue(
          "PerteVerticaliteEtOuMouvement"
        )) == 1
      ) {
        this.detectionMode = 1;
      } else if ((getParameterPTIValue("PerteVerticalite")) == 1) {
        this.detectionMode = 2;
      }
    },

    onSaveButtonClicked() {
      if(this.immobilitychecked == false && this.tiltchecked == false){
        this.tabErrorNotifications[3] = true;
      return;
      } else {
        this.tabErrorNotifications[3]= false;
      }
        if (this.wearingStyle == 1) {
          if (this.immobilitychecked) {
            if(this.isEwg100 == true) {
              if (this.isImmobilityVibrationChecked == false && this.isImmobilitySoundChecked == false) {
                this.tabErrorNotifications[0] = true;
                return;
              } else {
                  this.tabErrorNotifications[0] = false;
                  //this.saveButton2();
                }
            } 
              else {
                if (
                  this.isImmobilityVibrationChecked == false &&
                  this.isImmobilitySoundChecked == false &&
                  this.isImmobilityFlashChecked == false
                ) {
                  this.tabErrorNotifications[0] = true;
                  return;
                } else {
                  this.tabErrorNotifications[0] = false;
                  //this.saveButton2();
                }
              }
           }
          if (this.tiltchecked) {
            if(this.isEwg100 == true){
              if (this.isTiltSoundChecked == false && this.isTiltVibrationChecked == false){
                this.tabErrorNotifications[1] = true;
                return;
              } else {
                this.tabErrorNotifications[1] = false;
                //this.saveButton2();
              }
            }
            else{
              if (
                this.isTiltSoundChecked == false &&
                this.isTiltVibrationChecked == false &&
                this.isTiltFlashChecked == false
              ) {
                this.tabErrorNotifications[1] = true;
                return;
              } else {
                this.tabErrorNotifications[1] = false;
                //this.saveButton1();
              }
            }
          setParameterPTIValue("PortAuPoignet", 1);
          setParameterPTIValue("PortALaTaille", 0);
          setParameterPTIValue("PortAuBras", 0);
          setParameterPTIValue("PortAuCou", 0);
          }
        }
        if (this.wearingStyle == 2) {
          if (this.immobilitychecked) {
            if(this.isEwg100 == true) {
              if (this.isImmobilityVibrationChecked == false && this.isImmobilitySoundChecked == false) {
                this.tabErrorNotifications[0] = true;
                return;
              } else {
                  this.tabErrorNotifications[0] = false;
                  //this.saveButton2();
                }
            } 
              else {
                if (
                  this.isImmobilityVibrationChecked == false &&
                  this.isImmobilitySoundChecked == false &&
                  this.isImmobilityFlashChecked == false
                ) {
                  this.tabErrorNotifications[0] = true;
                  return;
                } else {
                  this.tabErrorNotifications[0] = false;
                  //this.saveButton2();
                }
              }
           }
          if (this.tiltchecked) {
            if(this.isEwg100 == true){
              if (this.isTiltSoundChecked == false && this.isTiltVibrationChecked == false){
                this.tabErrorNotifications[1] = true;
                return;
              } else {
                this.tabErrorNotifications[1] = false;
                //this.saveButton2();
              }
            }
            else{
              if (
                this.isTiltSoundChecked == false &&
                this.isTiltVibrationChecked == false &&
                this.isTiltFlashChecked == false
              ) {
                this.tabErrorNotifications[1] = true;
                return;
              } else {
                this.tabErrorNotifications[1] = false;
                //this.saveButton1();
              }
            }
          setParameterPTIValue("PortAuPoignet", 0);
          setParameterPTIValue("PortALaTaille", 1);
          setParameterPTIValue("PortAuBras", 0);
          setParameterPTIValue("PortAuCou", 0);
          }
        }
        if (this.wearingStyle == 3) {
          if (this.immobilitychecked) {
            if(this.isEwg100 == true) {
              if (this.isImmobilityVibrationChecked == false && this.isImmobilitySoundChecked == false) {
                this.tabErrorNotifications[0] = true;
                return;
              } else {
                  this.tabErrorNotifications[0] = false;
                  //this.saveButton2();
                }
            } 
              else {
                if (
                  this.isImmobilityVibrationChecked == false &&
                  this.isImmobilitySoundChecked == false &&
                  this.isImmobilityFlashChecked == false
                ) {
                  this.tabErrorNotifications[0] = true;
                  return;
                } else {
                  this.tabErrorNotifications[0] = false;
                  //this.saveButton2();
                }
              }
           }
          if (this.tiltchecked) {
            if(this.isEwg100 == true){
              if (this.isTiltSoundChecked == false && this.isTiltVibrationChecked == false){
                this.tabErrorNotifications[1] = true;
                return;
              } else {
                this.tabErrorNotifications[1] = false;
                //this.saveButton2();
              }
            }
            else{
              if (
                this.isTiltSoundChecked == false &&
                this.isTiltVibrationChecked == false &&
                this.isTiltFlashChecked == false
              ) {
                this.tabErrorNotifications[1] = true;
                return;
              } else {
                this.tabErrorNotifications[1] = false;
                //this.saveButton1();
              }
            }
          setParameterPTIValue("PortAuPoignet", 0);
          setParameterPTIValue("PortALaTaille", 0);
          setParameterPTIValue("PortAuBras", 1);
          setParameterPTIValue("PortAuCou", 0);
          }
        }
        if (this.wearingStyle == 4) {
          if (this.immobilitychecked) {
            if(this.isEwg100 == true) {
              if (this.isImmobilityVibrationChecked == false && this.isImmobilitySoundChecked == false) {
                this.tabErrorNotifications[0] = true;
                return;
              } else {
                  this.tabErrorNotifications[0] = false;
                  //this.saveButton2();
                }
            } 
              else {
                if (
                  this.isImmobilityVibrationChecked == false &&
                  this.isImmobilitySoundChecked == false &&
                  this.isImmobilityFlashChecked == false
                ) {
                  this.tabErrorNotifications[0] = true;
                  return;
                } else {
                  this.tabErrorNotifications[0] = false;
                  //this.saveButton2();
                }
              }
           }
          if (this.tiltchecked) {
            if(this.isEwg100 == true){
              if (this.isTiltSoundChecked == false && this.isTiltVibrationChecked == false){
                this.tabErrorNotifications[1] = true;
                return;
              } else {
                this.tabErrorNotifications[1] = false;
                //this.saveButton2();
              }
            }
            else{
              if (
                this.isTiltSoundChecked == false &&
                this.isTiltVibrationChecked == false &&
                this.isTiltFlashChecked == false
              ) {
                this.tabErrorNotifications[1] = true;
                return;
              } else {
                this.tabErrorNotifications[1] = false;
                //this.saveButton1();
              }
            }
          setParameterPTIValue("PortAuPoignet", 0);
          setParameterPTIValue("PortALaTaille", 0);
          setParameterPTIValue("PortAuBras", 0);
          setParameterPTIValue("PortAuCou", 1);
          }
        }

      // sauvegarde des détections
      if (this.immobilitychecked == false && this.tiltchecked == false) {
        setParameterPTIValue("AbsenceMouvement", 0);
        setParameterPTIValue("PerteVerticalite", 0);
        setParameterPTIValue("PerteVerticaliteEtMouvement", 0);
        setParameterPTIValue("PerteVerticaliteOuMouvement", 0);
        setParameterPTIValue("PerteVerticaliteEtOuMouvement", 0);
      }
      if (this.immobilitychecked == true && this.tiltchecked == false) {
        this.saveDetectionImmobilitySettings();
        setParameterPTIValue("AbsenceMouvement", 1);
        setParameterPTIValue("PerteVerticalite", 0);
        setParameterPTIValue("PerteVerticaliteEtMouvement", 0);
        setParameterPTIValue("PerteVerticaliteOuMouvement", 0);
        setParameterPTIValue("PerteVerticaliteEtOuMouvement", 0);
      }
      if (
        this.tiltchecked == true &&
        this.immobilitychecked == false &&
        this.detectionMode == 2
      ) {
        this.saveDetectionTiltSettings();
        setParameterPTIValue("AbsenceMouvement", 0);
        setParameterPTIValue("PerteVerticalite", 1);
        setParameterPTIValue("PerteVerticaliteEtMouvement", 0);
        setParameterPTIValue("PerteVerticaliteOuMouvement", 0);
        setParameterPTIValue("PerteVerticaliteEtOuMouvement", 0);
      }
      if (
        this.tiltchecked == true &&
        this.immobilitychecked == false &&
        this.detectionMode == 1
      ) {
        this.saveDetectionTiltSettings();
        setParameterPTIValue("AbsenceMouvement", 0);
        setParameterPTIValue("PerteVerticalite", 1);
        setParameterPTIValue("PerteVerticaliteEtMouvement", 1);
        setParameterPTIValue("PerteVerticaliteOuMouvement", 0);
        setParameterPTIValue("PerteVerticaliteEtOuMouvement", 0);
      }
      if (
        this.tiltchecked == true &&
        this.immobilitychecked == true &&
        this.detectionMode == 1
      ) {
        this.saveDetectionTiltSettings();
        this.saveDetectionImmobilitySettings();
        setParameterPTIValue("AbsenceMouvement", 1);
        setParameterPTIValue("PerteVerticalite", 1);
        setParameterPTIValue("PerteVerticaliteEtMouvement", 0);
        setParameterPTIValue("PerteVerticaliteOuMouvement", 0);
        setParameterPTIValue("PerteVerticaliteEtOuMouvement", 1);
      }
      if (
        this.tiltchecked == true &&
        this.immobilitychecked == true &&
        this.detectionMode == 2
      ) {
        this.saveDetectionTiltSettings();
        this.saveDetectionImmobilitySettings();
        setParameterPTIValue("AbsenceMouvement", 1);
        setParameterPTIValue("PerteVerticalite", 1);
        setParameterPTIValue("PerteVerticaliteEtMouvement", 0);
        setParameterPTIValue("PerteVerticaliteOuMouvement", 1);
        setParameterPTIValue("PerteVerticaliteEtOuMouvement", 0);
      }

      this.$router.push("/");
    },
    // sauvegarde des paramètres de perte de verticalité
    saveDetectionTiltSettings() {
      setParameterPTIValue(
        "PerteVerticaliteTempsDetection",
        this.tiltDetectionDurationRange
      );
      setParameterPTIValue(
        "PerteVerticaliteTempsNotification",
        this.tiltNotificationDurationRange
      );
      setParameterPTIValue("PerteVerticaliteAngle", this.tiltThresholdRange);
      
      setParameterPTIValue("PerteVerticaliteNotificationVibration", this.booleanToInt(this.isTiltVibrationChecked));
      setParameterPTIValue("PerteVerticaliteNotificationSon", this.booleanToInt(this.isTiltSoundChecked));
      setParameterPTIValue("PerteVerticaliteNotificationFlash", this.booleanToInt(this.isTiltFlashChecked));      
    },

    // sauvegarde des paramètres d'absence de mouvement
    saveDetectionImmobilitySettings() {
      setParameterPTIValue(
        "AbsenceMouvementTempsDetection",
        this.ImmobilityDetectionDurationRange
      );
      setParameterPTIValue(
        "AbsenceMouvementTempsNotification",
        this.ImmobilityNotificationDurationRange
      );
      setParameterPTIValue("AbsenceMouvementNotificationVibration", this.booleanToInt(this.isImmobilityVibrationChecked));
      setParameterPTIValue("AbsenceMouvementNotificationSon", this.booleanToInt(this.isImmobilitySoundChecked));
      setParameterPTIValue("AbsenceMouvementNotificationFlash", this.booleanToInt(this.isImmobilityFlashChecked)); 
    },

    onWearingStyleChange() {
      if (this.wearingStyle == 1) {
        this.immobilitychecked = true;
        this.tiltchecked = false;
        this.ImmobilityDetectionDurationRange = "120";
        this.ImmobilityNotificationDurationRange = "30";
        this.ImmobilityDetectionDurationMin = getParameterPTIMin(
          "AbsenceMouvementTempsDetection"
        );
        this.ImmobilityDetectionDurationMax = getParameterPTIMax(
          "AbsenceMouvementTempsDetection"
        );
        this.isImmobilityVibrationChecked = true;
        this.isImmobilitySoundChecked = true;
      } else if (this.wearingStyle == 2) {
        this.immobilitychecked = false;
        this.tiltchecked = true;
        this.tiltThresholdRange = "60";
        this.tiltThresholdMin = getParameterPTIMin("PerteVerticaliteAngle");
        this.tiltThresholdMax = getParameterPTIMax("PerteVerticaliteAngle");
        this.tiltNotificationDurationRange = "30";
        this.tiltNotificationDurationMin = getParameterPTIMin(
          "PerteVerticaliteTempsNotification"
        );
        this.tiltNotificationDurationMax = getParameterPTIMax(
          "PerteVerticaliteTempsNotification"
        );
        this.tiltDetectionDurationRange = "30";
        this.tiltDetectionDurationMin = getParameterPTIMin(
          "PerteVerticaliteTempsDetection"
        );
        this.tiltDetectionDurationMax = getParameterPTIMax(
          "PerteVerticaliteTempsDetection"
        );
        this.isTiltVibrationChecked = true;
        this.isTiltSoundChecked = true;
        this.detectionMode = 1;
      } else if (this.wearingStyle == 3) {
        this.immobilitychecked = true;

        this.tiltchecked = false;
        this.ImmobilityDetectionDurationRange = "120";
        this.ImmobilityNotificationDurationRange = "30";
        this.ImmobilityDetectionDurationMin = getParameterPTIMin(
          "AbsenceMouvementTempsDetection"
        );
        this.ImmobilityDetectionDurationMax = getParameterPTIMax(
          "AbsenceMouvementTempsDetection"
        );
        this.isImmobilityVibrationChecked = true;
        this.isImmobilitySoundChecked = true;
      } else if (this.wearingStyle == 4) {
        this.immobilitychecked = false;
        this.tiltchecked = true;
        this.tiltThresholdRange = "60";
        this.tiltThresholdMin = getParameterPTIMin("PerteVerticaliteAngle");
        this.tiltThresholdMax = getParameterPTIMax("PerteVerticaliteAngle");
        this.tiltNotificationDurationRange = "30";
        this.tiltNotificationDurationMin = getParameterPTIMin(
          "PerteVerticaliteTempsNotification"
        );
        this.tiltNotificationDurationMax = getParameterPTIMax(
          "PerteVerticaliteTempsNotification"
        );
        this.tiltDetectionDurationRange = "30";
        this.tiltDetectionDurationMin = getParameterPTIMin(
          "PerteVerticaliteTempsDetection"
        );
        this.tiltDetectionDurationMax = getParameterPTIMax(
          "PerteVerticaliteTempsDetection"
        );
        this.isTiltVibrationChecked = true;
        this.isTiltSoundChecked = true;
        this.detectionMode = 1;
      }
    },

    booleanToInt(value) {
      if (value == true) {
        return "1";
      }
      return "0";
    },

    intToBoolean(value) {
      if (value === "1") {
        return true;
      }
      return false;
    },  

  }
};
</script>
