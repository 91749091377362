{
  "common": {
    "menu_nothing":"Aucune",
    "menu_min_message":"Le minimun de message est de 1",
    "menu_field_cannot_be_empty_need_one":"Un champ minimun doit être saisir",
    "menu_affichage": "Affichage",
    "menu_son": "Son",
    "menu_post_alert": "Post-alerte",
    "menu_vibration_5_secondes": "Vibrations toutes les 5 secondes",
    "menu_phone_number_must_start": "Le numéro de téléphone ne doit contenir que des chiffres",
    "menu_wrong_file_certificate": "Format de fichier incorrect. le format doit être .pfx ou .p12.",
    "menu_wrong_config": "La configuration est erronée",
    "menu_see_help_for_more_info": "Veuillez consulter l'aide en ligne pour plus d'information.",
    "menu_back": "Retour",
    "menu_save": "Enregistrer",
    "menu_settings": "Réglages",
    "menu_notification_prealert": "Pré-alerte",
    "menu_notification_by_vibration": "Notification par vibrations",
    "menu_notification_by_sound": "Notification par son",
    "menu_notification_by_flash": "Notification par flash",
    "menu_notification_duration": "Durée de notification de pré-alerte",
    "menu_seconds": "secondes",
    "menu_minutes": "minutes",
    "menu_degrees": "degrés",
    "menu_meters": "mètres",
    "menu_settings_for": "Réglages pour ",
    "menu_confirm": "Confirmer",
    "menu_field_cannot_be_empty": "Le champ ne peut pas être vide",
    "menu_select_at_least_one_triggering_mode": "Sélectionner au moins un mode d'activation",
    "menu_phone_number_must_start_with_+": "Le numéro de téléphone doit commencer par + et ne contenir que des chiffres",
    "menu_phone_number_is_too_short": "Numéro de téléphone trop court",
    "menu_phone_number_already_used": "Numéro de téléphone déjà utilisé",
    "menu_select_at_least_one_event": "Sélectionner au moins un événement",
    "menu_are_you_sure": "Etes vous sûr ?",
    "menu_add": "Ajouter",
    "menu_modify": "Modifier",
    "menu_remove": "Supprimer",
    "menu_remove_everything": "Supprimer tout",
    "menu_wrong_flie_format": "Format de fichier incorrect. Enregistrer le fichier Excel au format .csv avec séparateur point virgule.",
    "menu_cancel": "Annuler"
  },
  "configuration": {
    "menu_no_checked_standard":"Aucun paramètre  n'a été coché pour le mode standard",
    "menu_no_checked_transparent":"Aucun paramètre n'a été coché pour le mode transparent",
    "menu_location_standard":"Mode standard",
    "menu_location_transparent":"Mode transparent",
    "menu_location_indoor":"En intérieur",
    "menu_tippy_text_on_off":"Aller dans [Personnalisation du bouton marche/arrêt] pour activer la fonctionnalité",
    "menu_trigger_alert":"Déclencher une alarme",
    "menu_trigger_call":"Déclencher un appel par trois appuis rapides vers le numéro suivant :",
    "menu_type_action":"Type d'action associée à trois appuis rapides",
    "menu_device_stop":"Arrêt de l'appareil",
    "menu_custom_on_off":"Personnalisation du bouton marche/arrêt",
    "menu_show_last_location":"Sauvegarder la dernière position connue à chaque arrêt de l'appareil",
    "menu_force_alert_reactivated_system": "Forcer la réactivation du système à chaque allumage de l'appareil",
    "menu_err_serv_with_no_events": "Aucun évènement n'a été configuré",
    "menu_err_one_or_several_recipient_have_message_but_no_events": "Un ou plusieurs destinataires reçoivent les évènements par SMS mais aucun évènement n'a été configuré",
    "menu_img_upload_failure": "Le transfert de l'image a échoué, vérifiez votre connexion internet",
    "menu_choose_at_least_one_custom_screen": "Aucune configuration effectuée",
    "menu_custom_screens": "Personnaliser l'affichage",
    "menu_communication_option": "Options de communication",
    "menu_system_option": "Options système",
    "menu_disable_on_off_btn": "Désactiver le bouton marche/arrêt pour empêcher d'éteindre l'appareil",
    "menu_model_check_emerit_version_unstopable_call": "Ce paramètre nécessite que le firmware de l'appareil soit compatible. Vous pouvez vérifier la version de la ROM via le widget À propos.",
    "menu_tippy_unstopable_call_not_available": "Ce paramètre n'est pas compatible avec le firmware de votre appareil. Veuillez contacter le service technique pour plus d'information.",
    "menu_call_cant_be_stop": "Les appels d'alertes ne peuvent pas être interrompus par l'utilisateur",
    "menu_whitelist": "Liste blanche (limitation des appels entrants)",
    "menu_wifi_configuration": "Connexion Wifi",
    "menu_tippy_sip_not_available": "L'activation de la VoIP n'est pas disponible sur votre appareil",
    "menu_tippy_widget_loc": "Ce paramètre nécessite l'activation d'au moins un des modes de localisation",
    "menu_model_check_emerit_version": "La VOIP nécessite que l'application Emerit SIP soit présente sur cet appareil. Vous pouvez vérifier si celle-ci est présente via le widget À propos.",
    "menu_enabling_sip_will_remove_recipients": "L'activation de la VoIP va entraîner la suppression des destinataires internes enregistrés. Vous devrez saisir une nouvelle liste de destinataires.",
    "menu_disabling_sip_will_remove_recipients": "La désactivation de la VoIP va entraîner la suppression des destinataires internes enregistrés. Vous devrez saisir une nouvelle liste de destinataires.",
    "menu_wrong_character_in_user_id": "Le nom d'utilisateur ne peut pas contenir le caractère '_' lorsqu'il y a au moins une montre Emerit configurée en tant que destinataire interne",
    "menu_choose_notif_mode": "Sélection du mode de notification pour les SMS entrants",
    "menu_err_choose_at_least_one_notif_mode": "Veuillez sélectionner au moins un mode de notification",
    "menu_alerts_processing": "Traitement des alertes",
    "menu_internal_recipients": "Destinataires internes",
    "menu_alarm_monitoring_center": "Centre de télésurveillance",
    "menu_alarm_monitoring_server": "Serveur de supervision",
    "menu_alerts_localization": "Localisation des alertes",
    "menu_location_never": "Jamais",
    "menu_location_outdoor": "En extérieur",
    "menu_alerts_triggering_modes": "Mode d'activation des alertes",
    "menu_alert_activation_sos_button": "Bouton SOS",
    "menu_alert_activation_sos_button_and_programmable_button": "Bouton SOS et boutons programmables",
    "menu_alert_activation_side_black_button": "Bouton latéral noir",
    "menu_alert_activation_side_yellow_button": "Bouton latéral jaune",
    "menu_alert_activation_front_programmable_button": "Bouton programmable frontal",
    "menu_alert_activation_side_programmable_button": "Bouton programmable latéral",
    "menu_alert_activation_automatic_detection": "Détections automatiques",
    "menu_wrist_alert_mode": "Mouvements rapides du poignet",
    "menu_wearing_style_wrist": "Au poignet",
    "menu_wearing_style_waist": "A la taille",
    "menu_wearing_style_arms": "Au bras",
    "menu_wearing_style_neck": "Autour du cou",
    "menu_alert_activation_power_button": "Bouton marche/arrêt",
    "menu_update_configuration": "Mettre à jour la configuration",
    "menu_configuration_update_has_succeeded": "La mise à jour de la configuration a réussi",
    "menu_device_must_be_restarted": "Démarrer l'appareil ou lancer la procédure pour charger la nouvelle configuration",
    "menu_no_alert_recipient_was_selected": "Aucun destinataire d'alerte n'a été sélectionné",
    "menu_no_alert_processing_was_selected": "Aucun traitement d'alerte n'a été sélectionné",
    "menu_no_recipient_or_server_was_created": "Aucun destinataire ou serveur n'a été créé",
    "menu_no_recipient_was_created": "Aucun destinataire n'a été créé",
    "menu_no_server_was_created": "Aucun serveur n'a été créé",
    "menu_validate_configuration": "Valider la configuration",
    "menu_wifi": "Wifi",
    "menu_select_at_least_one_automatic_detection": "Sélectionner au moins une détection automatique",
    "menu_save_at_least_one_wifi_connection": "Enregistrer au moins un réseau Wifi",
    "menu_configuration_update_has_failed": "La mise à jour de la configuration a échoué",
    "menu_check_internet_connection_and_retry": "Vérifier la connexion internet et réessayer",
    "menu_download_configuration_model_to_computer": "Télécharger le modèle de configuration sur l'ordinateur",
    "menu_configuration_model_name": "Nom du modèle de configuration",
    "menu_device_notification": "Gestion des notifications de l'appareil",
    "menu_password_for_deployment": "Mot de passe pour la configuration par déploiement : 6 caractères incluant au moins 1 minuscule, 1 majuscule et 1 chiffre, pas de caractère spécial",
    "menu_enter_a_password": "Saisir un mot de passe",
    "menu_password_must_be_different_from_access_password": "Le mot de passe doit être différent du mot de passe d'accès",
    "menu_location_indoor_wifi": "via Wifi",
    "menu_location_indoor_ble": "via Bluetooth",
    "menu_location_automatic_incoming_call_pickup_after_three_rings": "Décroché automatique des appels entrants après 3 sonneries",
    "menu_err_ble_level_precision_not_activated": "La gestion de l'étage avec des balises Bluetooth a été activé pour la localisation Wifi. Ce paramètre est donc obligatoire.",
    "menu_deployment_password": "Mot de passe déploiement",
    "menu_no_wifi_access_point_has_been_registered": "Aucune borne Wifi n'a été enregistrée",
    "menu_no_ble_beacon_has_been_registered": "Aucune balise Bluetooth n'a été enregistrée",
    "menu_deactivation_of_alert_system_by_the_user": "Désactivation du système d'alerte par l'utilisateur",
    "menu_freely_disable_enable_alert_system": "Autoriser à désactiver/activer librement le système d'alerte",
    "menu_temporarily_disable_enable_alert_system": "Autoriser à désactiver/activer temporairement le système d'alerte",
    "menu_duration_of_deactivation": "Durée de désactivation",
    "menu_GSM_data_wifi_issue_notification": "Notification en cas de problème réseau GSM, data et Wifi",
    "menu_battery_monitoring": "Surveillance batterie",
    "menu_networks_monitoring": "Surveillance réseaux",
    "menu_low_battery_notification_triggering_threshold": "Seuil de déclenchement de notification de batterie faible",
    "menu_name_is_not_displayed": "Le nom d'utilisateur n'est pas affiché sur l'appareil",
    "menu_confirm_deactivation_of_localisation": "Confirmez la désactivation de la localisation",
    "menu_ask_to_activate_sip": "Activer la voix sur IP (VoIP) par compte SIP",
    "menu_wrong_sip_configuration": "Tous les champs du VoIP doivent être remplis",
    "menu_show_fragment_location": "Activer le widget de visualisation de la localisation actuelle",
    "menu_5_percent": "5%",
    "menu_10_percent": "10%",
    "menu_20_percent": "20%",
    "menu_name_is_displayed": "Le nom d'utilisateur est affiché sur l'appareil",
    "menu_name_is_battery_percentage_is_displayed": "Afficher le pourcentage de la batterie",
    "menu_name_is_wake_up_screen_when_charging_enabled": "Réveiller l'écran toutes les 10 secondes lors de la charge lorsque la montre est en marche",
    "menu_name_is_sound_notif_securite_positive_enabled": "Activer le son pour les notifications"
  },
  "alerttrigerring": {
    "menu_enable_selecte_switch":"Veuillez Selectionner au moins une détection",
    "menu_agression": "Agression",
    "menu_demande_secours": "Demande de secours",
    "menu_message_alert": "Message d'alerte à transmettre",
    "menu_communication_unidirectionnelle": "Communication unidirectionnelle (haut-parleur désactivé)",
    "menu_communication_bidirectionnelle": "Communication bidirectionnelle",
    "menu_cycle_destinataires_télésurveillance": "Cycle d'appels automatique (destinataires internes ou appel télésurveillance)",
    "menu_sos_button_settings": "Réglages bouton SOS",
    "menu_power_button_settings": "Réglages bouton marche/arrêt",
    "menu_automatic_detection_settings": "Réglages détections automatiques",
    "menu_trigger_mode": "Activation",
    "menu_one_long_press": "Un appui long",
    "menu_three_short_presses": "Trois appuis rapides",
    "menu_alert_mode": "Mode d'alerte",
    "menu_speaker_on_request_for_help": "Haut-parleur activé > demande de secours",
    "menu_silent_assault": "Silencieux > contexte d'agression",
    "menu_select_at_least_one_notification_mode": "Sélectionnez au moins un mode de notification",
    "menu_front_programmable_button_settings": "Réglages du bouton frontal programmable",
    "menu_side_black_button_settings": "Réglages du bouton latéral noir",
    "menu_side_yellow_button_settings": "Réglages du bouton latéral jaune",
    "menu_side_programmable_button_settings": "Réglages du bouton latéral programmable",
    "menu_sos_button_and_programmable_button_settings": "Réglages du bouton SOS et des boutons programmables"
  },
  "locationoutdoor": {
    "menu_outdoor_location": "Réglage localisation extérieure",
    "menu_gps_based": "Localisation extérieure basée sur le GPS",
    "menu_refreseh_only_when_event_sending": "Actualisation uniquement à la transmission d'un événement",
    "menu_update_at_regular_intervals": "Actualisation à intervalles réguliers",
    "menu_automatic_refresh_interval": "Fréquence d'actualisation automatique",
    "menu_location_update_method": "Méthode d'actualisation de la localisation"
  },
  "locationindoorwifi": {
    "menu_err_ssid_already_exist": "Le SSID existe déjà dans la liste",
    "menu_no_ble_transparent": "Aucun UUID Bluetooth n'a été enregistré",
    "menu_no_wifi_hotspot_transparent": "Aucun SSID Wifi n'a été enregistré",
    "menu_no_param_found_indoord":"Aucun paramètre n'a été sélectionné",
    "menu_list_ssid_filter":"Liste des SSID à filtrer",
    "menu_add_ssid_filter":"Ajout des SSID à filtrer",
    "menu_wifi_based_indoor_location_transparent": "Localisation intérieure basée sur le Wifi (Mode Transparent)",
    "menu_disable_ble_loc_to_handle_floors": "Ce paramètre doit être désactivé depuis la page de configuration de la localisation en intérieur via Bluetooth",
    "menu_enable_ble_loc_to_handle_floors": "Ce paramètre doit être activé depuis la page de configuration de la localisation en intérieur via Bluetooth",
    "menu_wifi_based_indoor_location": "Localisation intérieure basée sur le Wifi",
    "menu_enable_wifi_based_indoor_location": "Activer la localisation intérieure basée sur le Wifi",
    "menu_import_a_new_wifi_hotspot_file": "Importer un nouveau fichier de bornes Wifi au format .csv",
    "menu_import_succeeded": "Importation réussie",
    "menu_confirm_import": "Confimer l'importation",
    "menu_import_will_replace_existing_list": "L'importation depuis un fichier .csv va remplacer la liste des bornes Wifi enregistrées.",
    "menu_error_while_importing": "Erreur lors de l'importation",
    "menu_export_to_csv_wifi_configuration": "Exporter au format .csv l'ensemble des bornes Wifi",
    "menu_excel_file_is_empty": "Le fichier Excel est vide",
    "menu_check_your_excel_file": ". Vérifiez votre fichier Excel.",
    "menu_download_excel_file_template_for_importation": "Télécharger le modèle Excel pour l'importation",
    "menu_number_of_imported_wifi_hotspots": "Nombre de bornes Wifi importées : ",
    "menu_an_error_was_detected_line": "Une erreur a été détectée ligne ",
    "menu_number_of_registered_wifi_hotspots": "Nombre de bornes Wifi enregistrées : ",
    "menu_incorrect_column_headers": "Entêtes de colonnes incorrectes. Veuillez utiliser le modèle Excel pour saisir les bornes Wifi puis enregistrer le fichier au format .csv avec séparateur point virgule.",
    "menu_wifi_hotspot_for_indoor_location": "Bornes Wifi pour la localisation intérieure",
    "menu_no_wifi_hotspot_recorded": "Aucune borne Wifi enregistrée",
    "menu_no_wifi_hotspot_imported": "Aucune borne Wifi importée",
    "menu_add_hotspot": "Ajouter une borne Wifi",
    "menu_modify_hotspot": "Modifer une borne Wifi",
    "menu_hotspot_ssid": "SSID",
    "menu_hotspot_bssid": "BSSID",
    "menu_hotspot_code_zone": "Code zone",
    "menu_hotspot_level": "Etage",
    "menu_hotspot_description": "Description",
    "menu_tippy_ssid": "Nom du réseau Wifi",
    "menu_tippy_bssid": "Adresse MAC diffusée de la borne Wifi (6 paires de caractères hexadécimaux séparées par ':')",
    "menu_tippy_code_zone": "Information transmise via le protocole SIA",
    "menu_tippy_level": "0 pour rdc, chiffre négatif pour les sous-sols (ex: -3)",
    "menu_tippy_description": "Localisation associée à la borne. Il est interdit d'utiliser le caractère '_' lorsque l'option envoi de messages vers des montres Emerit est activé pour cause de bug (ce bug sera fix lors de la prochaine mise à jour)",
    "menu_err_empty_ssid": "Le SSID ne peut pas être vide.",
    "menu_err_wrong_bssid": "Mauvais BSSID. Le BSSID doit être une séquence de 6 caractères hexadécimaux séparées par le caractère ':'. Exemple : 11:22:33:44:55:66.",
    "menu_err_bssid_already_exist": "Le BSSID saisi a déjà été enregistré.",
    "menu_err_wrong_level": "Erreur lors de la saisie de l'étage. Celui-ci doit être un chiffre positif ou négatif.",
    "menu_err_empty_desc": "La description ne peut pas être vide.",
    "menu_err_wrong_code_zone": "Le code zone doit être composé de 3 chiffres et compris entre 001 et 999. Exemple : 007.",
    "menu_add_hotspot_success": "Borne Wifi ajoutée avec succès",
    "menu_err_one_elem_missing_level": "L'étage n'a pas été renseigné pour toutes les bornes Wifi. Veuillez vérifier la liste enregistrée.",
    "menu_add_hotspot_failed": "Erreur lors de l'ajout d'une nouvelle borne Wifi",
    "menu_modify_hotspot_success": "Borne Wifi modifiée avec succès",
    "menu_level_precision_with_ble": "Gérer le changement d'étage avec des balises Bluetooth",
    "menu_enable_import_export_csv": "Importer / exporter au format .csv",
    "menu_add_manually_wifi_hotspots": "Ajouter manuellement des bornes Wifi",
    "menu_remove_all_hotspots": "Etes-vous sûr de vouloir supprimer toutes les bornes Wifi enregistrées ?",
    "menu_remove_one_hotspot": "Etes-vous sûr de vouloir supprimer cette borne Wifi ?",
    "menu_precise_wifi_location_with_ble": "Localisation intérieure Wifi affinée via Bluetooth",
    "menu_title_refresh_choice":" Frequence d'actualisation"
  },
  "locationindoorble": {
    "menu_remove_one_beacon_transparent":"Etes-vous sûr de vouloir supprimer cet UUID",
    "menu_add_beacon_transparent_success": "UUID ajouté avec succès",
    "menu_condition_uuid_transparent": "Le format de l'UUID doit être de 32 caractères en hexadécimal",
    "menu_add_beacon_transparent_failed":"UUID déjà enregistré",
    "menu_list_ssid_filtered":"Liste des SSID à filtrer",
    "menu_custom_model":"Autre UUID",
    "menu_ble_based_indoor_location_transparent":"Localisation intérieure basée sur le Bluetooth (Mode Transparent)",
    "menu_list_uuid_filtered":"Liste des UUID à filtrer",
    "menu_add_uuid_filtered":"Ajout des Company ID (UUID) à filtrer",
    "menu_ble_based_indoor_location": "Localisation intérieure basée sur le Bluetooth",
    "menu_enable_ble_based_indoor_location": "Activer la localisation intérieure basée sur le Bluetooth",
    "menu_download_excel_file_template_for_importation": "Télécharger le modèle Excel pour l'importation",
    "menu_import_a_new_ble_beacons_file": "Importer un nouveau fichier de balises Bluetooth au format .csv",
    "menu_import_succeeded": "Importation réussie",
    "menu_confirm_import": "Confimer l'importation",
    "menu_import_will_replace_existing_list": "L'importation depuis un fichier .csv va remplacer la liste des balises Bluetooth enregistrées.",
    "menu_error_while_importing": "Erreur lors de l'importation",
    "menu_excel_file_is_empty": "Le fichier Excel est vide",
    "menu_check_your_excel_file": ". Vérifiez votre fichier Excel.",
    "menu_export_to_csv_ble_configuration": "Exporter au format .csv l'ensemble des balises Bluetooth",
    "menu_no_ble_beacon_recorded": "Aucune balise Bluetooth enregistrée",
    "menu_no_ble_beacon_imported": "Aucune balise Bluetooth importée",
    "menu_number_of_registered_ble_beacons": "Nombre de balises Bluetooth enregistrées : ",
    "menu_incorrect_column_headers_ble": "Entêtes de colonnes incorrectes. Veuillez utiliser le modèle Excel pour saisir les balises Bluetooth puis enregistrer le fichier au format .csv avec séparateur point virgule.",
    "menu_number_of_imported_ble_beacons": "Nombre de balises Bluetooth importées : ",
    "menu_an_error_was_detected_line": "Une erreur a été détectée ligne ",
    "menu_add_beacon": "Ajouter une balise Bluetooth",
    "menu_modify_beacon": "Modifer une balise Bluetooth",
    "menu_add_manually_ble_beacons": "Ajouter manuellement des balises Bluetooth",
    "menu_remove_all_beacons": "Etes-vous sûr de vouloir supprimer toutes les balises Bluetooth enregistrées ?",
    "menu_remove_one_beacon": "Etes-vous sûr de vouloir supprimer cette balise Bluetooth ?",
    "menu_add_beacon_success": "Balise Bluetooth ajoutée avec succès",
    "menu_add_beacon_failed": "Erreur lors de l'ajout d'une nouvelle balise Bluetooth",
    "menu_modify_beacon_success": "Balise Bluetooth modifiée avec succès",
    "menu_beacon_model": "Modèle de balise Bluetooth",
    "menu_beacon_company_id": "Identifiant compagnie",
    "menu_beacon_id": "Identifiant balise",
    "menu_beacon_type": "Type",
    "menu_beacon_level": "Etage",
    "menu_beacon_code_zone": "Code zone",
    "menu_beacon_description": "Description",
    "menu_tippy_uuid": "Correspond à l'UUID du protocole iBeacon, qui est une séquence de 32 caractères hexadécimaux",
    "menu_tippy_namespace_id": "Correspond au namespace ID du protocole Eddystone, qui est une séquence de 20 caractères hexadécimaux",
    "menu_tippy_bbe9_major_minor": "Correspond aux 8 caractères hexadécimaux imprimés sur la balise Bluetooth e-BBE9",
    "menu_tippy_loc_ex_mac_address": "Correspond à la MAC adresse imprimée sur la balise Bluetooth LOC-EX 01 (6 paires de caractères hexadécimaux séparées par ':')",
    "menu_tippy_ibeacon_major_minor": "Correspond au Major + Minor du protocole iBeacon, qui est une suite de 8 caractères hexadécimaux",
    "menu_tippy_instance_id": "Correspond à l'instance ID du protocole Eddystone, qui est une suite de 12 caractères hexadécimaux",
    "menu_tippy_level": "0 pour rdc, chiffre négatif pour les sous-sols (ex: -3)",
    "menu_tippy_code_zone": "Information transmise via le protocole SIA",
    "menu_tippy_description": "Localisation associée à la balise. Il est interdit d'utiliser le caractère '_' lorsque l'option envoi de messages vers des montres Emerit est activé pour cause de bug (ce bug sera fix lors de la prochaine mise à jour)",
    "menu_err_ibeacon_company_id": "Mauvais identifiant de compagnie. Celui-ci correspond à l'UUID. Il doit donc être constitué de 32 caractères hexadécimaux.",
    "menu_err_ibeacon_beacon_id": "Mauvais identifiant de balise. Celui-ci correspond au Major + Minor. Il doit donc être constitué de 8 caractères hexadécimaux.",
    "menu_err_eddystone_company_id": "Mauvais identifiant de compagnie. Celui-ci correspond au namespace ID. Il doit donc être constitué de 20 caractères hexadécimaux.",
    "menu_err_eddystone_beacon_id": "Mauvais identifiant de balise. Celui-ci correspond à l'instance ID. Il doit donc être constitué de 12 caractères hexadécimaux.",
    "menu_err_bbe9_beacon_id": "Mauvais identifiant de balise. Celui-ci correspond aux 8 caractères hexadécimaux imprimés sur la balise Bluetooth e-BBE9.",
    "menu_err_bbe9_already_exist": "Une balise Bluetooth e-BBE9 avec cet identifiant existe déjà.",
    "menu_err_loc_ex_beacon_id": "Mauvais identifiant de balise. Celui-ci correspond à l'adresse MAC imprimée sur la balise Bluetooth LOC-EX 01.",
    "menu_err_loc_ex_already_exist": "Une balise Bluetooth LOC-EX 01 avec cet identifiant existe déjà.",
    "menu_err_ibeacon_already_exist": "Une balise Bluetooth iBeacon avec cet UUID et ce Major Minor existe déjà.",
    "menu_err_eddy_already_exist": "Une balise Bluetooth Eddystone avec ce namespace ID et cet instance ID existe déjà.",
    "menu_err_wrong_level": "Erreur lors de la saisie de l'étage. Celui-ci doit être un chiffre positif ou négatif.",
    "menu_err_empty_desc": "La description ne peut pas être vide.",
    "menu_err_wrong_code_zone": "Le code zone doit être composé de 3 chiffres et compris entre 001 et 999. Exemple : 007.",
    "menu_level_precision_with_ble": "Gérer le changement d'étage",
    "menu_err_one_elem_missing_level": "L'étage n'a pas été renseigné pour toutes les balises Bluetooth. Veuillez vérifier la liste enregistrée.",
    "menu_err_wifi_level_precision_is_activated": "La gestion de l'étage avec des balises Bluetooth a été activé pour la localisation Wifi. Ce paramètre est donc obligatoire.",
    "menu_err_no_level_beacon": "La gestion de l'étage nécessite l'ajout d'au moins une balise Bluetooth de type Level",
    "menu_enable_import_export_csv": "Importer / exporter au format .csv",
    "menu_title_refresh_ble_choice":"Fréquence d'actualisation",
    "menu_scan_mode_fastest":"Actualisation à intervalles réguliers (6 secondes, consommation élevée)",
    "menu_scan_mode_average":"Actualisation à intervalles réguliers (10 secondes, consommation moyenne)",
    "menu_scan_mode_on_demand":"Actualisation uniquement à la transmission d'un événement",
    "menu_ble_new_algo_activation_title":"Paramétrage de l'algorithme",
    "menu_ble_new_algo_activation":"Activer le nouvel algorithme de localisation des balises (recommandé)"
  },
  "internalrecipients": {
    "menu_name_recipient": "Nom du destinataire",
    "menu_duration_of_call_attempt_before_next_number": "Durée de la tentative d'appel d'alerte avant bascule sur le numéro suivant",
    "menu_event_select_call_and_or_sip_messaging": "Sélectionner appel et/ou message SIP",
    "menu_request_loc_with_sms_command": "Ce destinataire peut requêter la localisation par la commande SMS suivante : ?LOC",
    "menu_sip_scenario": "Transmission des alertes via compte SIP",
    "menu_alert_configuration_not_available_with_pec": "Lorsque le scénario d'alerte 'SMS avec prise en compte puis appel' est activé, la configuration de la réception des alertes est faite automatiquement.",
    "menu_sens_sms_with_sip_not_possible": "L'envoi de SMS vers un numéro de télephone via un compte SIP n'est pas disponible. Seul l'appel est disponible.",
    "menu_err_sip_account_already_exist": "Un destinataire avec cet identifiant SIP existe déjà",
    "menu_receive_events_by_sip_messaging": "Ce destinataire reçoit les événements par message SIP",
    "menu_switch_watch_is_using_sip_account": "Ce destinataire utilise un compte SIP",
    "menu_recipient_added_with_success": "Destinataire ajouté",
    "menu_recipient_modified_with_success": "Destinataire modifié",
    "menu_modify_recipient": "Modifier un destinataire",
    "menu_sip_account_id": "Identifiant SIP",
    "menU_sip_servel_url": "URL serveur SIP",
    "menu_icon_sms_desc": ": Ce destinataire reçoit les alertes par SMS",
    "menu_icon_phone_desc": ": Ce destinataire reçoit les alertes par appel",
    "menu_icon_watch_desc": ": Ce destinataire est une montre Emerit",
    "menu_icon_sip_desc": ": Ce destinataire utilise un compte SIP",
    "menu_number_or_sip_id": "Numéro d'appel / Identifiant SIP",
    "menu_remove_all_recipients": "Etes-vous sûr de vouloir supprimer tous les destinataires enregistrés ?",
    "menu_remove_one_recipient": "Etes-vous sûr de vouloir supprimer ce destinataire ?",
    "menu_add_recipient": "Ajouter un destinataire",
    "menu_sms_and_calls_cycle": "SMS et appels en cascade",
    "menu_sms_then_calls_cycle": "SMS puis appels en cascade",
    "menu_sms_with_acknowledgment_then_call": "SMS avec prise en compte puis appel",
    "menu_scenario_is": "Scénario d'alerte : ",
    "menu_settings": "Réglages",
    "menu_nb_recipients_saved": "Nombre de destinataires internes enregistrés: ",
    "menu_is_emerit_watch": "Ce destinataire est une montre Emerit",
    "menu_internal_recipients": "Destinataires internes",
    "menu_monitoring_center_call_numbers": "Numéros d'appel du centre de télésurveillance",
    "menu_recipient": "Destinataire",
    "menu_enable_recipient": "Activer le destinataire",
    "menu_call_number": "Numéro d'appel",
    "menu_enable_call_number": "Activer le numéro d'appel",
    "menu_name": "Nom",
    "menu_phone_number_international_format": "Numéro de téléphone (format international)",
    "menu_receive_alerts_by_call": "Ce destinataire reçoit les alertes par appel",
    "menu_receive_events_by_sms": "Ce destinataire reçoit les événements par SMS",
    "menu_event_alerts": "Alertes",
    "menu_event_system_status": "Etat du système",
    "menu_event_charge_status": "Etat de la charge",
    "menu_event_low_battery": "Batterie basse",
    "menu_event_beacon_ble_low_battery": "Batterie basse balise Bluetooth",
    "menu_event_select_call_and_or_sms": "Sélectionner appel et/ou SMS",
    "menu_nfc_reading": "Lecture de tag NFC"
  },
  "internalrecipientssettings": {
    "menu_internal_recipients_settings": "Réglages destinataires internes",
    "menu_sms_language_selection": "Sélection de la langue des SMS",
    "menu_alert_scenario_type": "Sélection du scénario d'alerte",
    "menu_sms_and_calls_cycle": "SMS et appels en cascade",
    "menu_sms_then_calls_cycle": "SMS puis appels en cascade",
    "menu_sms_with_acknowledgment_then_call": "SMS avec prise en compte puis appel",
    "menu_maximum_waiting_time_for_acknowledgment_reception": "Temps d'attente maximum pour la réception du SMS de prise en compte",
    "menu_call_cycle_stopped_by_sms": "Arrêt du cycle d'appels par SMS",
    "menu_show_sms_data_selection": "Afficher la sélection des données SMS",
    "menu_sms_data_selection": "Sélection des données SMS",
    "menu_basic_data": "Données de base",
    "menu_gps_data": "Données GPS",
    "menu_device_user": "Utilisateur de l'appareil",
    "menu_battery_level": "Niveau de batterie",
    "menu_event_timestamp": "Horodatage de l'événement",
    "menu_google_map_link": "Lien Google Map",
    "menu_timestamp": "Horodatage",
    "menu_adress_google_reverse_geocoding": "Adresse (Google reverse geocoding)",
    "menu_accuracy": "Précision",
    "menu_coordinates_DMS_format": "Coordonnées format DMS",
    "menu_coordinates_DD_format": "Coordonnées format DD",
    "menu_alert_labels_customization": "Personnalisation des libellés d'alerte",
    "menu_show_alert_labels_customization": "Afficher la personnalisation des libellés d'alerte",
    "menu_request_for_help": "demande de secours (défaut)",
    "menu_assault": "agression (défaut)",
    "menu_no_movement": "immobilité (défaut)",
    "menu_tilt": "inclinaison (défaut)"
  },
  "sipconfiguration": {
    "menu_title": "VOIP",
    "menu_sip_need_configuration": "Configuration du VOIP",
    "menu_account_name": "Description du compte",
    "menu_user_name": "Nom d'utilisateur",
    "menu_user_password": "Mot de passe",
    "menu_server_url": "Url du serveur"
  },
  "ipserversettings": {
    "menu_std_gps_timestamp(O)": "[H] horodatage position GPS",
    "menu_extended_data_T2i_format_selection": "Sélection données étendues au format T2i©",
    "menu_T2i_format": "Format T2i©",
    "menu_sia_protocol_settings": "Réglages protocole SIA DC-09",
    "menu_encapsulated_alarm_protol": "Protocole d'alarme encapsulé",
    "menu_admeco_cid_contact_id": "Ademco Contact ID (CID)",
    "menu_sia_dcs": "SIA DCS",
    "menu_extended_data_format_and_selection": "Format et sélection des données étendues",
    "menu_standard_format": "Format standard",
    "menu_esi_format": "Format ESI©",
    "menu_add_the_message_ending_timestamp": "Ajouter l'horodatage de fin du message",
    "menu_extended_data_standard_format_selection": "Sélection données étendues au format standard",
    "menu_extended_data_esi_format_selection": "Sélection données étendues au format ESI©",
    "menu_std_battery": "[B] Niveau de batterie",
    "menu_std_event_timestamp": "[H] horodatage événement",
    "menu_std_imei": "[D] IMEI de l'appareil",
    "menu_std_beacon_id": "[C] identifiant de balise localisation Wifi / Bluetooth",
    "menu_std_gps_accuracy": "[M] précision position GPS",
    "menu_std_gps_timestamp": "[O] horodatage position GPS",
    "menu_std_gps_speed": "[S] vitesse position GPS",
    "menu_std_gps_validity": "[V] validité position GPS",
    "menu_std_gps_altitude": "[Z] altitude position GPS",
    "menu_esi_battery_level": "$B Niveau de batterie",
    "menu_esi_gps_timestamp": "$T horodatage position GPS",
    "menu_gps_coordinates_format": "Format des coordonnées GPS",
    "menu_gps_DD": "DD Degrés décimaux",
    "menu_gps_DDM": "DM Degrés minutes décimales",
    "menu_customed_field": "Champ personnalisé",
    "menu_lpref": "Lpref",
    "menu_monitoging_server_sia_protocol_settings": "Réglages protocole SIA DC-09 pour serveur de supervision",
    "menu_monitoring_center_server_sia_protocol_settings": "Réglages protocole SIA DC-09 pour serveur télésurveillance",
    "menu_location_maximum_age": "Age maximum de la localisation",
    "menu_location_maximum_accuracy": "Précision maximum de la localisation"
  },
  "ipserver": {
    "menu_unit_description_message":"Messages",
    "menu_event_end_tracking_stop_post_alert":"Fin du tracking à l'arrêt du Post-alerte",
    "menu_event_end_tracking_after_x_message":"Fin du tracking après l'envoi de ",
    "menu_event_permanents":"Permanent",
    "menu_event_trigger_alert":"Au déclenchement d'une alerte",
    "menu_enable_link_test":"Activer le Link Test",
    "menu_link_test":"Link Test",
    "menu_alarm_monitoring_center_server": "Serveur du centre de télésurveillance",
    "menu_enable_main_server": "Activer le serveur principal",
    "menu_main_server": "Serveur principal",
    "menu_events_to_be_transmitted": "Evenements à transmettre",
    "menu_client_id": "Identifiant client",
    "menu_ip_url": "Adresse IP ou URL",
    "menu_port": "Numéro de port",
    "menu_enable_cyclic_test": "Activer le Tracking",
    "menu_cyclic_test": "Tracking",
    "menu_test_interval": "Intervalle d'envoi",
    "menu_backup_server": "Serveur Backup",
    "menu_backup_sms": "SMS Backup",
    "menu_enable_backup_server": "Activer le serveur Backup",
    "menu_enable_backup_sms": "Activer le SMS Backup",
    "menu_port_must_be_between_0_and_65536": "Le numéro de port doit être compris entre 0 et 65536"
  },
  "wearingways": {
    "menu_waist_worn": "port à la taille",
    "menu_enable_no_movement_detection": "Activer la détection d'immobilité",
    "menu_enable_tilt_detection": "Activer la détection d'inclinaison",
    "menu_no_movement_detection": "Détection d'immobilité",
    "menu_tilt_detection": "Détection d'inclinaison",
    "menu_detection_duration_before_prealert": "Durée de détection avant notification de pré-alerte",
    "menu_detection_threshold": "Seuil de détection",
    "menu_detection_mode": "Mode de détection",
    "menu_tilt_only": "Inclinaison seule",
    "menu_tilt_without_movement": "Inclinaison sans mouvement",
    "menu_select_a_wearing_way_and_modify_settings_if_needed": "Sélectionner un port et modifier les réglages si besoin"
  },
  "wristalert": {
    "menu_noise_mode": "Haut parleur activé > demande de secours",
    "menu_discret_mode": "Silencieux > contexte d'agression",
    "menu_choose_alert_mode": "Mode d'alerte",
    "menu_title_wrist_alert": "Réglage de l'alerte via mouvement du poignet",
    "menu_choose_threshold": "Intensité du mouvement pour déclencher l'alerte",
    "menu_thresold_value_low": "Peu rapide",
    "menu_thresold_value_middle": "Moyennement rapide (recommandé)",
    "menu_thresold_value_high": "Très rapide"
  },
  "login": {
    "menu_first_device_boot_error": "S'il s'agit de la première configuration d'un appareil, il est nécessaire de suivre une procédure de mise en service avant de pouvoir effectuer la configuration de celui-ci.",
    "menu_quick_start_guide": "Aide en ligne",
    "menu_home_help": "https://magneta.document360.io/v1/fr",
    "menu_connexion_error": "Un problème de connexion est survenu. Veuillez vérifier votre connexion internet.",
    "menu_enter_five_last_digits_of_serial_number": "Entrer les 5 derniers chiffres du numéro de série (SN)",
    "menu_enter_five_last_digits_of_imei_number": "Entrer les 5 derniers chiffres du numéro IMEI",
    "menu_login": "Connexion",
    "menu_select_device_model": "Sélectionner le modèle d'appareil",
    "menu_please_select_the_device_model": "Veuillez d'abord sélectionner le modèle d'appareil",
    "menu_entered_numbers_dont_match_with_available_device": "Les numéros saisis ne correspondent à aucun appareil ou un problème de connexion est survenu.",
    "menu_please_read_quick_start_guide_first": "Veuillez prendre connaissance du guide de démarrage rapide.",
    "menu_configure_this_device": "Configurer cet appareil",
    "menu_configuration_models_management": "Gestion des modèles de configuration",
    "menu_deploy_a_configuration_model": "Déployer un modèle de configuration",
    "menu_invalid_file": "Fichier invalide",
    "menu_only_file_with_emerit_extension_are_supported": "Seuls les fichiers avec l'extention .emerit sont pris en charge",
    "menu_create_configuration_model": "Créer un modèle de configuration",
    "menu_modify_a_configuration_model": "Modifier un modèle de configuration",
    "menu_drag_and_drop_file_or_click_to_select": "Glisser/déposer ou cliquer pour sélectionner le fichier modèle de configuration",
    "menu_configuration_model": "Modèle de configuration",
    "menu_configuration_model_creation": "Création d'un modèle de configuration",
    "menu_connect_to_a_device": "Se connecter à un appareil",
    "menu_models_dont_match": "Le fichier sélectionné n'est pas conforme ou le modèle de configuration n'est pas compatible avec le modèle sélectionné.",
    "menu_select_language": "Sélectionner la langue"
  },
  "wifi": {
    "menu_mode_utilisation":"Mise à jour du fuseau horaire selon le mode d'utilisation",
    "menu_select_utc":"Sélectionner un fuseau horaire",
    "menu_error_utc_none":"Veilliez selectionner un fuseaux horaire",
    "menu_mode_wifi_alone":"Wifi uniquement :",
    "menu_mode_gsm_plus_wifi":" Wifi + GSM (carte SIM) : mise à jour auto",
    "menu_certificate_upload_success": "Certificat téléchargée avec succès",
    "menu_certificate_upload_fail": "Échec du téléchargement du certificat",
    "menu_err_pls_upload_certificate": "Veuillez télécharger un certificat",
    "menu_err_only_pfx_p12_accepted": "Seulement les certificats au format .pfx/.p12 sont acceptées.",
    "menu_err_certificate_is_too_heavy": "La taille du certificat est trop importante, elle ne peut pas excéder 5Mo.",
    "menu_upload_certificat": "Importer le certificat PEAP (.pfx/.p12)",
    "menu_certificate_password": "Password",
    "menu_certificate_identifiant": "Identifiant",
    "menu_certificate_type": "CA Certificat",
    "menu_name_is_update_app_by_wifi_enabled": "Récupérer des configurations et installer des mises à jour uniquement si le wifi est actif",
    "menu_use_wifi_connection": "Utiliser la connexion Wifi pour l'envoi des alertes",
    "menu_config_wifi_network": "Gérer les réseaux Wifi",
    "menu_nb_wifi_network_saved": "Nombre de réseaux Wifi enregistrés : ",
    "menu_send_alert_with_wifi": "Le réseau Wifi si disponible sera priorisé sur le réseau GSM",
    "menu_network_added_with_success": "Réseau Wifi ajouté",
    "menu_network_modified_with_success": "Réseau Wifi modifié",
    "menu_remove_all_networks": "Etes-vous sûr de vouloir supprimer tous les réseaux Wifi enregistrés ?",
    "menu_remove_one_network": "Etes-vous sûr de vouloir supprimer ce réseau Wifi ?",
    "menu_registred_wifi_networks_list": "Liste des réseaux wifi enregistrés - 2.4 GHz uniquement",
    "menu_ssid": "Nom du réseau SSID",
    "menu_security_type": "Type de sécurité",
    "menu_password": "Mot de passe",
    "menu_add_a_wifi_network": "Ajouter un réseau Wifi",
    "menu_modify_a_wifi_network": "Modifier un réseau Wifi",
    "menu_do_you_want_to_remove_this_network": "Voulez vous supprimer ce réseau ?",
    "menu_please_select_security_type": "Veuillez sélectionner un type de sécurité",
    "menu_hidden": "Réseau masqué",
    "menu_network_already_exists": "Le réseau existe déjà"
  },
  "whitelist": {
    "menu_add_a_recipient_whitelist": "Ajouter un numéro entrant additionnel",
    "menu_modify_a_recipient_whitelist": "Modifer un numéro entrant additionnel",
    "menu_recipient_whitelist_modified_with_success": "Numéro entrant additionnel modifé avec succès",
    "menu_recipient_whitelist_added_with_success": "Numéro entrant additionnel ajouté avec succès",
    "menu_nb_recipient_whitelist": "Numéros entrants additionnels : ",
    "menu_nb_recipient_mirror": "Destinataires d'alertes : ",
    "menu_remove_all_recipient_whitelist": "Etes-vous sûr de vouloir supprimer tous les numéros entrants additionnels de la liste blanche ?",
    "menu_remove_one_recipient_whitelist": "Etes-vous sûr de vouloir supprimer ce numéro entrant additionnel de la liste blanche ?",
    "menu_add_whitelist_recipient": "Ajouter un numéro entrant",
    "menu_nb_total_whitelist_recipient": "Nombre total de numéros entrants autorisés dans la liste blanche : ",
    "menu_whitelist_title": "Configuration de la liste blanche"
  },
  "customscreens": {
    "menu_img_upload_info": "La taille d'une image .png/.gif ne doit pas excéder 1 Mo. La résolution de l'écran de la montre est de 128x96 pixels. Pour un rendu optimal de l'image, respecter le ratio hauteur/largeur de l'écran (1,33). Si le ratio n'est pas respecté, l'image sera automatiquement redimensionnée.",
    "menu_err_pls_upload_launch_screen": "Veuillez télécharger une image.",
    "menu_err_pls_upload_widget_screen": "Veuillez télécharger une image ou saisir un texte.",
    "menu_err_only_png_gif_accepted": "Seulement les images au format .png/.gif sont acceptées.",
    "menu_err_img_is_too_heavy": "La taille de l'image est trop importante, elle ne peut pas excéder 1Mo.",
    "menu_img_upload_success": "Image téléchargée avec succès",
    "menu_img_upload_fail": "Échec du téléchargement de l'image",
    "menu_custom_screens_overview": "Prévisualisation",
    "menu_remove_launch_img": "Supprimer l'image",
    "menu_remove_widget_img": "Supprimer l'image d'arrière-plan",
    "menu_display_widget_text_in_black": "Afficher le texte en noir",
    "menu_custom_widget_top_text": "Texte haut (18 caractères maximum, espaces inclus)",
    "menu_custom_widget_bottom_text": "Texte bas (18 caractères maximum, espaces inclus)",
    "menu_upload_launch_screen_img": "Télécharger une nouvelle image (.png/.gif)",
    "menu_upload_widget_screen_img": "Télécharger une nouvelle image d'arrière-plan (.png/.gif)",
    "menu_title_yes_custom_launch_screen": "Configuration de l'image de démarrage",
    "menu_title_no_custom_launch_screen": "Personnaliser l'image de démarrage",
    "menu_title_yes_custom_widget_screen": "Configuration du widget contact",
    "menu_title_no_custom_widget_screen": "Ajouter un widget contact",
    "menu_custom_screens_title": "Personnaliser l'affichage"
  },
  "password": {
    "menu_create_new_password_for_model": "Entrez un mot de passe de 6 caractères dont au moins 1 minuscule, 1 majuscule et 1 chiffre, sans caractères spéciaux. Ce mot de passe sera demandé à chaque modification de ce modèle.",
    "menu_enter_password": "Saisir le mot de passe",
    "menu_enter_deployment_password": "Saisir le mot de passe de déploiement",
    "menu_create_new_password": "Saisir un mot de passe de 6 caractères incluant au moins 1 minuscule, 1 majuscule et 1 chiffre, sans caractère spécial. Ce mot de passe sera demandé à chaque connexion à cet appareil.",
    "menu_confirm_password": "Confirmer le mot de passe",
    "menu_wrong_password": "Mauvais mot de passe",
    "menu_please_retry": "Veuillez réessayer",
    "menu_empty_password": "Mot de passe vide",
    "menu_passwords_dont_match": "Les mots de passe ne correspondent pas",
    "menu_at_least_one_password_is_empty": "Au moins un mot de passe est vide",
    "menu_password_dont_meet_requirements": "Le mot de passe n'est pas conforme aux exigences",
    "menu_modify_password": "Modifier mot de passe",
    "menu_password_creation_warning": "En vous connectant à emerit-console et après avoir créé le mot de passe, vous devenez de fait administrateur de l'appareil identifié ci-dessus"
  },
  "contact": {
    "menu_contact_link": "https://www.magneta.fr/contactez-nous/",
    "menu_if_you_have_any_question_please_click_on_the_folowing_button": "Pour toute question d'utilisation ou de configuraiton, merci de cliquer sur le bouton suivant :",
    "menu_technical_support_email": "Email du support technique :",
    "menu_online_support_request_form": "Formulaire de demande de support en ligne"
  },
  "deploy": {
    "menu_upload_failed": "Le déploiement a échoué, vérifier la connexion internet et réessayer",
    "menu_device_updated": "Appareil mis à jour",
    "menu_fill_server_ids": "Configuration des IDs clients",
    "menu_fill_personal_fields": "Renseigner les données à personnaliser",
    "menu_target_a_device": "Appareil à configurer",
    "menu_client_id_for_tls_main_server": "ID client pour le serveur principal de télésurveillance",
    "menu_client_id_for_tls_backup_server": "ID client pour le serveur secondaire de télésurveillance",
    "menu_client_id_for_supervision_main_server": "ID client pour le serveur principal de supervision",
    "menu_client_id_for_supervision_backup_server": "ID client pour le serveur secondaire de supervision",
    "menu_from_a_configuration_model": "A partir du modèle de configuration",
    "menu_five_last_digits_of_serial_number": "5 derniers chiffres du numéro de série (SN)",
    "menu_five_last_digits_of_imei_number": "5 derniers chiffres du numéro IMEI",
    "menu_deploy_not_allowed": "Vous n'êtes pas autorisé à configurer cet appareil par déploiement, les mots de passe de déploiement ne correspondent pas"
  },
  "side_menu": {
    "configuration": "Configuration",
    "info": "Manuels",
    "contact": "Contact"
  },
  "watchhelp": {
    "menu_custom_screens_view": "https://www.support.magneta.fr/docs/fr/watch-system-options#ii-personnaliser-laffichage",
    "menu_locex_help": "https://www.support.magneta.fr/docs/fr/beacon-loc-ex-01",
    "menu_wifi_connection_view": "https://www.support.magneta.fr/docs/fr/watch-communication-options#i-utiliser-la-connexion-wifi",
    "menu_sip_view": "https://www.support.magneta.fr/docs/fr/watch-communication-options#ii-activer-la-voix-sur-ip-voip-par-compte-sip",
    "menu_whitelist_view": "https://www.support.magneta.fr/docs/fr/watch-communication-options#iii-liste-blanche-limitation-des-appels-entrants",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/fr/watch-main-page#i-utilisateur-de-lappareil",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/fr/watch-main-page#ii-traitement-des-alertes",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/fr/watch-main-page#iii-mode-dactivation-des-alertes",
    "menu_loc": "https://www.support.magneta.fr/docs/fr/watch-main-page#iv-localisation-des-alertes",
    "menu_communication_option": "https://www.support.magneta.fr/docs/fr/watch-main-page#v-options-de-communication",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/fr/watch-main-page#vi-surveillance-batterie",
    "menu_device_notif_management": "https://www.support.magneta.fr/docs/fr/watch-main-page#vii-gestion-des-notifications-de-lappareil",
    "menu_system_option": "https://www.support.magneta.fr/docs/fr/watch-main-page#viii-options-syst%C3%A8me",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/fr/beacon-e-bbe9#associer-un-type-aux-balises",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/fr/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/fr/watch-configuration-methods#configuration-des-param%C3%A8tres-personnels",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/fr/watch-configuration-methods#saisie-du-mot-de-passe-de-d%C3%A9ploiement",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/fr/watch-configuration-methods#modification-dun-mod%C3%A8le-de-configuration",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/fr/watch-configuration-methods#cr%C3%A9ation-dun-mod%C3%A8le-de-configuration",
    "menu_model_login": "https://www.support.magneta.fr/docs/fr/watch-configuration-methods#ii-configuration-multiappareils",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/fr/watch-configuration-methods#iii-d%C3%A9ploiement-de-configuration",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/fr/watch-configuration-methods#saisie-du-mot-de-passe-",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/fr/watch-configuration-methods#cr%C3%A9ation-de-mot-de-passe",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/fr/watch-alerts-localization#i-localisation-ext%C3%A9rieure-gps",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/fr/watch-alerts-localization#ii-localisation-int%C3%A9rieure-wifi",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/fr/watch-alerts-localization#iii-localisation-int%C3%A9rieure-bluetooth",
    "menu_main_page": "https://www.support.magneta.fr/docs/fr/watch-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/fr/watch-commissioning#iv-premier-d%C3%A9marrage"
  },
  "phonehelp": {
    "menu_locex_help": "https://www.support.magneta.fr/docs/fr/beacon-loc-ex-01",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/fr/smartphones-main-page#i-utilisateur-de-lappareil",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/fr/smartphones-main-page#ii-traitement-des-alertes",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/fr/smartphones-main-page#iii-mode-dactivation-des-alertes",
    "menu_loc": "https://www.support.magneta.fr/docs/fr/smartphones-main-page#iv-localisation-des-alertes",
    "menu_communication_option": "https://www.support.magneta.fr/docs/fr/smartphones-main-page#v-options-de-communication",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/fr/smartphones-main-page#vi-surveillance-batterie",
    "menu_deactivate_alert_system": "https://www.support.magneta.fr/docs/fr/smartphones-main-page#vii-d%C3%A9sactivation-du-syst%C3%A8me-dalerte-par-lutilisateur",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/fr/beacon-e-bbe9#associer-un-type-aux-balises",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/fr/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/fr/smartphones-configuration-methods#configuration-des-param%C3%A8tres-personnels",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/fr/smartphones-configuration-methods#saisie-du-mot-de-passe-de-d%C3%A9ploiement",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/fr/smartphones-configuration-methods#modification-dun-mod%C3%A8le-de-configuration",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/fr/smartphones-configuration-methods#cr%C3%A9ation-dun-mod%C3%A8le-de-configuration",
    "menu_model_login": "https://www.support.magneta.fr/docs/fr/smartphones-configuration-methods#ii-configuration-multiappareils",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/fr/smartphones-configuration-methods#iii-d%C3%A9ploiement-de-configuration",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/fr/smartphones-configuration-methods#saisie-du-mot-de-passe-",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/fr/smartphones-configuration-methods#cr%C3%A9ation-de-mot-de-passe",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/fr/smartphones-alerts-localization#i-localisation-ext%C3%A9rieure-gps",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/fr/smartphones-alerts-localization#ii-localisation-int%C3%A9rieure-wifi",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/fr/smartphones-alerts-localization#iii-localisation-int%C3%A9rieure-bluetooth",
    "menu_main_page": "https://www.support.magneta.fr/docs/fr/smartphones-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/fr/smartphones-commissioning#ii-premier-d%C3%A9marrage"
  }
}
