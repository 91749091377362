{
  "common": {
    "menu_nothing":"Nada",
    "menu_min_message": "El mínimo de mensajes es 1",
    "menu_field_cannot_be_empty_need_one": "Debe ingresar al menos un campo",
    "menu_affichage": "Mostrar",
    "menu_son": "Su",
    "menu_post_alert": "Post-alerta",
    "menu_vibration_5_secondes": "Vibraciones cada 5 segundos",
    "menu_phone_number_must_start": "El número de teléfono solo debe contener números",
    "menu_wrong_file_certificate": "Formato de archivo incorrecto. El formato debe ser .pfx o .p12.",
    "menu_wrong_config": "La configuración es incorrecta",
    "menu_see_help_for_more_info": "Consulte la ayuda en línea para obtener más información.",
    "menu_back": "Regreso",
    "menu_save": "Guardar",
    "menu_settings": "Ajustes",
    "menu_notification_prealert": "Notificación pre-alerta",
    "menu_notification_by_vibration": "Notificación por vibración",
    "menu_notification_by_sound": "Notificación por sonido",
    "menu_notification_by_flash": "Notificación por flash",
    "menu_notification_duration": "Duración de la notificación pre-alerta",
    "menu_seconds": "secundos",
    "menu_minutes": "minutos",
    "menu_degrees": "grados",
    "menu_meters": "metros",
    "menu_settings_for": "Configuración por ",
    "menu_confirm": "Confirmar",
    "menu_field_cannot_be_empty": "El campo no puede estar vacío",
    "menu_select_at_least_one_triggering_mode": "Seleccione al menos un modo de activación",
    "menu_phone_number_must_start_with_+": "El número de teléfono debe comenzar con + y contener solo dígitos",
    "menu_phone_number_is_too_short": "El número de teléfono es demasiado corto",
    "menu_phone_number_already_used": "Número de teléfono ya utilizado",
    "menu_select_at_least_one_event": "Seleccione al menos un evento",
    "menu_are_you_sure": "¿Está seguro?",
    "menu_add": "Agregar",
    "menu_modify": "Modificar",
    "menu_remove": "Borrar",
    "menu_remove_everything": "Quitar todo",
    "menu_wrong_flie_format": "Formato de archivo incorrecto",
    "menu_cancel": "Cancelar"
  },
  "configuration": {
    "menu_no_checked_standard": "No se han marcado parámetros para el modo estándar",
    "menu_no_checked_transparent": "No se han marcado parámetros para el modo transparente",
    "menu_location_standard": "Modo estándar",
    "menu_location_transparent": "Modo transparente",
    "menu_location_indoor": "En interiores",  
    "menu_type_action": "Tipo de acción asociada con tres toques rápidos",
    "menu_device_stop": "Detener dispositivo",
    "menu_custom_on_off": "Personalización del botón de encendido/apagado",
    "menu_trigger_alert":"Activar alarma",
    "menu_trigger_call": "Activar una llamada mediante triple toque al siguiente número:",
    "menu_tippy_text_on_off":"Ir a [Personalizar botón de encendido / apagado] para activar la funcionalidad",
    "menu_show_last_location": "Guardar la última ubicación conocida en cada parada del dispositivo",
    "menu_force_alert_reactivated_system": "Forzar la reactivación del sistema cada vez que se enciende el dispositivo",
    "menu_err_serv_with_no_events": "No se han configurado eventos",
    "menu_err_one_or_several_recipient_have_message_but_no_events": "Uno o más destinatarios reciben eventos por SMS pero no se han configurado eventos",
    "menu_img_upload_failure": "Error al cargar la imagen, verifique su conexión a Internet",
    "menu_choose_at_least_one_custom_screen": "Ninguna configuración realizada",
    "menu_custom_screens": "Personalizar pantalla",
    "menu_communication_option": "Opciones de comunicación",
    "menu_system_option": "Opciones de sistema",
    "menu_disable_on_off_btn": "Deshabilitar el botón de encendido para evitar que el dispositivo se apague",
    "menu_model_check_emerit_version_unstopable_call": "Esta configuración requiere que el firmware del dispositivo sea compatible. Puede verificar la versión de ROM a través del widget Acerca de.",
    "menu_tippy_unstopable_call_not_available": "Esta configuración no es compatible con el firmware de su dispositivo. Comuníquese con el Soporte técnico para obtener más información.",
    "menu_call_cant_be_stop": "El usuario no puede detener las llamadas de alerta",
    "menu_whitelist": "Lista blanca (limitación de llamadas entrantes)",
    "menu_wifi_configuration": "Conexión Wifi",
    "menu_tippy_sip_not_available": "La activación de VoIP no está disponible en su dispositivo",
    "menu_tippy_widget_loc": "Este parámetro requiere la activación de al menos uno de los modos de localización",
    "menu_model_check_emerit_version": "VOIP requiere que la aplicación Emerit SIP esté presente en este dispositivo. Puede verificar si está presente a través del widget Acerca de.",
    "menu_enabling_sip_will_remove_recipients": "Habilitar VoIP eliminará los destinatarios internos registrados. Deberá ingresar una nueva lista de destinatarios.",
    "menu_disabling_sip_will_remove_recipients": "Deshabilitar VoIP eliminará los destinatarios internos registrados. Deberá ingresar una nueva lista de destinatarios.",
    "menu_wrong_character_in_user_id": "El identificador de usuario no puede contener el carácter '_' cuando hay al menos un reloj Emerit seleccionado como destinatario interno",
    "menu_choose_notif_mode": "Selección del modo de notificación para los SMS entrantes",
    "menu_err_choose_at_least_one_notif_mode": "Elija al menos un modo de notificación",
    "menu_alerts_processing": "Procesamiento de alertas",
    "menu_internal_recipients": "Destinatarios internos",
    "menu_alarm_monitoring_center": "Vigilancia Remota",
    "menu_alarm_monitoring_server": "Servidor de supervisión",
    "menu_alerts_localization": "Ubicación de las alertas",
    "menu_location_never": "Nunca",
    "menu_location_outdoor": "En exterior",
    "menu_alerts_triggering_modes": "Modo de activacion de las alertas ",
    "menu_alert_activation_sos_button": "Botón SOS",
    "menu_alert_activation_sos_button_and_programmable_button": "Botón SOS y botónes programables",
    "menu_alert_activation_side_black_button": "Botón lateral negro",
    "menu_alert_activation_side_yellow_button": "Botón lateral amarillo",
    "menu_alert_activation_front_programmable_button": "Botón programable frontal",
    "menu_alert_activation_side_programmable_button": "Botón lateral programable",
    "menu_alert_activation_automatic_detection": "Detecciones automáticas",
    "menu_wrist_alert_mode": "Movimientos rápidos de la muñeca",
    "menu_wearing_style_wrist": "Muñeca",
    "menu_wearing_style_waist": "Cintura",
    "menu_wearing_style_arms": "Brazo",
    "menu_wearing_style_neck": "Cuello",
    "menu_alert_activation_power_button": "Botón on/off",
    "menu_update_configuration": "Actualizar configuración",
    "menu_configuration_update_has_succeeded": "La actualización de la configuración se ha realizado correctamente",
    "menu_device_must_be_restarted": "Inicie el dispositivo o inicie el procedimiento para cargar la nueva configuración",
    "menu_no_alert_recipient_was_selected": "No se seleccionó ningún destinatario de alerta",
    "menu_no_alert_processing_was_selected": "No se seleccionó ningún procesamiento de alertas",
    "menu_no_recipient_or_server_was_created": "No se creó ningún destinatario ni servidor",
    "menu_no_recipient_was_created": "No se creó ningún destinatario",
    "menu_no_server_was_created": "No se creó ningún servidor",
    "menu_validate_configuration": "Validar configuración",
    "menu_wifi": "Wifi",
    "menu_select_at_least_one_automatic_detection": "Seleccione al menos una detección automática",
    "menu_save_at_least_one_wifi_connection": "Registre al menos una conexión Wifi",
    "menu_configuration_update_has_failed": "La actualización falló",
    "menu_check_internet_connection_and_retry": "Verifique la conexión a Internet y vuelva a intentarlo",
    "menu_download_configuration_model_to_computer": "Descargar la plantilla de configuración a la computadora",
    "menu_configuration_model_name": "Nombre de la plantilla de configuración",
    "menu_device_notification": "Administración de notificaciones de dispositivos",
    "menu_password_for_deployment": "Contraseña para la configuración por implementación: 6 caracteres que incluya al menos 1 minúscula, 1 mayúscula y 1 número, sin carácter especial",
    "menu_enter_a_password": "Ingrese una contraseña",
    "menu_password_must_be_different_from_access_password": "La contraseña debe ser diferente a la contraseña de acceso",
    "menu_location_indoor_wifi": "Interior usando Wifi",
    "menu_location_indoor_ble": "Interior usando Bluetooth",
    "menu_location_automatic_incoming_call_pickup_after_three_rings": "Respuesta automática de llamadas entrantes después de 3 timbres",
    "menu_err_ble_level_precision_not_activated": "Se ha seleccionado la gestión del nivel mediante baliza Bluetooth para la localización interior Wifi. Este parámetro es obligatorio.",
    "menu_deployment_password": "Contraseña de implementación",
    "menu_no_wifi_access_point_has_been_registered": "No se ha registrado ningún terminal Wifi",
    "menu_no_ble_beacon_has_been_registered": "No se ha registrado ningún baliza Bluetooth",
    "menu_deactivation_of_alert_system_by_the_user": "Desactivación del sistema de alerta por el usuario",
    "menu_freely_disable_enable_alert_system": "Permitir deshabilitar/habilitar libremente el sistema de alerta",
    "menu_temporarily_disable_enable_alert_system": "Permitir deshabilitar/habilitar temporalmente el sistema de alerta",
    "menu_duration_of_deactivation": "Duración de la desactivación",
    "menu_GSM_data_wifi_issue_notification": "Notificación de problemas de redes GSM, datos y Wifi",
    "menu_battery_monitoring": "Monitoreo de batería",
    "menu_networks_monitoring": "Seguimiento de las redes",
    "menu_low_battery_notification_triggering_threshold": "Umbral de activación de notificación de batería baja",
    "menu_name_is_not_displayed": "El nombre de usuario no se muestra en el dispositivo",
    "menu_confirm_deactivation_of_localisation": "Confirmar desactivación de ubicación",
    "menu_ask_to_activate_sip": "Activar la voz sobre IP (VoIP) con cuenta SIP",
    "menu_wrong_sip_configuration": "Todos los campos de VoIP deben ser completados",
    "menu_show_fragment_location": "Activar el widget de ubicación real",
    "menu_5_percent": "5%",
    "menu_10_percent": "10%",
    "menu_20_percent": "20%",
    "menu_name_is_displayed": "El nombre de usuario se muestra en el dispositivo",
    "menu_name_is_battery_percentage_is_displayed": "Mostrar porcentaje de batería",
    "menu_name_is_wake_up_screen_when_charging_enabled": "Activa la pantalla cada 10 segundos durante la carga cuando el reloj está encendido",
    "menu_name_is_sound_notif_securite_positive_enabled": "Habilitar sonido para notificaciones"
  },
  "alerttrigerring": {
    "menu_enable_selecte_switch":"Seleccione al menos una detección",
    "menu_agression": "Agresión",
    "menu_demande_secours": "Pidiendo ayuda",
    "menu_message_alert": "Mensaje de alerta para enviar",
    "menu_communication_unidirectionnelle": "Comunicación unidireccional (altavoz desactivado)",
    "menu_communication_bidirectionnelle": "Comunicación bidireccional",
    "menu_cycle_destinataires_télésurveillance": "Ciclo de llamada automático (destinatarios internos o llamada de monitoreo remoto)",
    "menu_sos_button_settings": "Configuración botón SOS",
    "menu_power_button_settings": "Configuración botón on/off",
    "menu_automatic_detection_settings": "Configuración detecciones automáticas",
    "menu_trigger_mode": "Modo de activación",
    "menu_one_long_press": "Apoyo largo",
    "menu_three_short_presses": "Tres apoyos rápidos",
    "menu_alert_mode": "Modo de alerta",
    "menu_speaker_on_request_for_help": "Altavoz activado > solicitud de rescate",
    "menu_silent_assault": "Silencio > agresión",
    "menu_select_at_least_one_notification_mode": "Seleccione al menos un modo de notificación",
    "menu_front_programmable_button_settings": "Configuración del botón programable frontal",
    "menu_side_black_button_settings": "Configuración del botón lateral negro",
    "menu_side_yellow_button_settings": "Configuración del botón amarillo lateral",
    "menu_side_programmable_button_settings": "Configuración del botón amarillo lateral",
    "menu_sos_button_and_programmable_button_settings": "Configuración de botones SOS y botones programables"
  },
  "locationoutdoor": {
    "menu_outdoor_location": "Configuración de ubicación en exterior",
    "menu_gps_based": "Ubicación exterior basada en GPS",
    "menu_refreseh_only_when_event_sending": "Actualización únicamente en la transmisión de un evento",
    "menu_update_at_regular_intervals": "Actualización a intervalos regulares",
    "menu_automatic_refresh_interval": "Frecuencia de actualización automática",
    "menu_location_update_method": "Método de actualización de ubicación"
  },
  "locationindoorwifi": {
    "menu_err_ssid_already_exist": "El SSID ya existe en la lista",
    "menu_no_ble_transparent": "Ningún UUID Bluetooth ha sido registrado",
    "menu_no_wifi_hotspot_transparent": "Ningún SSID de Wifi ha sido registrado",
    "menu_no_param_found_indoord": "Ningún parámetro ha sido seleccionado",
    "menu_list_ssid_filter": "Lista de SSID a filtrar",
    "menu_add_ssid_filter": "Agregar SSID a filtrar",
    "menu_wifi_based_indoor_location_transparent": "Localización interior basada en Wifi (Modo Transparente)",
    "menu_disable_ble_loc_to_handle_floors": "Esta configuración debe desactivarse desde la página de configuración de la ubicación interior de Bluetooth",
    "menu_enable_ble_loc_to_handle_floors": "Esta configuración debe habilitarse desde la página de configuración de la ubicación interior a través de Bluetooth",
    "menu_wifi_based_indoor_location": "Ubicación interior basada en wifi",
    "menu_enable_wifi_based_indoor_location": "Activar la ubicación interior basada en Wifi",
    "menu_import_a_new_wifi_hotspot_file": "Importar un nuevo archivo .csv de puntos de acceso Wifi",
    "menu_import_succeeded": "Importación exitosa",
    "menu_confirm_import": "Confirmar la importación",
    "menu_import_will_replace_existing_list": "La importación desde un archivo .csv sustituirá a la lista real de hotposts Wifi guardada",
    "menu_error_while_importing": "Error al importar",
    "menu_export_to_csv_wifi_configuration": "Exportar a un archivo .csv la lista de hotposts wifi",
    "menu_excel_file_is_empty": "El archivo de Excel está vacío",
    "menu_check_your_excel_file": ". Verifique su archivo de Excel.",
    "menu_download_excel_file_template_for_importation": "Descargue la plantilla de Excel para importar",
    "menu_number_of_imported_wifi_hotspots": "Número de puntos de acceso Wifi importados: ",
    "menu_an_error_was_detected_line": "Se detectó un error en la línea ",
    "menu_number_of_registered_wifi_hotspots": "Número de puntos de acceso Wifi registrados: ",
    "menu_incorrect_column_headers": "Encabezados de columna incorrectos. Por favor, utilice la plantilla de Excel para buscar Wifi hotposts, y guárdela en formato .csv con el carácter ';' como separador.",
    "menu_wifi_hotspot_for_indoor_location": "Puntos de acceso wifi para ubicación en interiores",
    "menu_no_wifi_hotspot_recorded": "No se registró ningún punto de acceso Wifi",
    "menu_no_wifi_hotspot_imported": "No se importó ningún punto de acceso Wifi",
    "menu_add_hotspot": "Agregar un punto de acceso Wifi",
    "menu_modify_hotspot": "Modificar un punto de acceso Wifi",
    "menu_hotspot_ssid": "SSID",
    "menu_hotspot_bssid": "BSSID",
    "menu_hotspot_code_zone": "Codigo de area",
    "menu_hotspot_level": "Escenario",
    "menu_hotspot_description": "Descripción",
    "menu_tippy_ssid": "Nombre de la red Wifi",
    "menu_tippy_bssid": "Dirección MAC emitida por el punto de acceso Wifi (6 pares de caracteres hexadecimales separados por el carácter ':')",
    "menu_tippy_code_zone": "Información enviada por el protocolo SIA",
    "menu_tippy_level": "0 para la planta baja, número negativo para los sótanos (por ejemplo: -3)",
    "menu_tippy_description": "Ubicación asociada al hotspot. Está prohibido usar el carácter '_' cuando la opción de enviar mensajes a los relojes Emerit está activada debido a un error (este error se solucionará en la próxima actualización)",
    "menu_err_empty_ssid": "El campo SSID no puede estar vacío.",
    "menu_err_wrong_bssid": "BSSID erróneo. El BSSID debe ser una secuencia de 6 pares de números / letras separados por el carácter ':'. Ejemplo: 11:22:33:44:55:66.",
    "menu_err_bssid_already_exist": "El BSSID ingresado ya ha sido registrado.",
    "menu_err_wrong_level": "Error al entrar al piso. Este debe ser un número positivo o negativo.",
    "menu_err_empty_desc": "La descripción no puede estar vacío.",
    "menu_err_wrong_code_zone": "La zona de código debe ser una secuencia de 3 números entre 001 y 999. Ejemplo: 007.",
    "menu_err_one_elem_missing_level": "No se ha ingresado el piso para todos los puntos de acceso Wifi. Por favor, consulte la lista de registrados.",
    "menu_add_hotspot_success": "Punto de acceso Wifi agregado con éxito",
    "menu_add_hotspot_failed": "Error al agregar un nuevo punto de acceso Wifi",
    "menu_modify_hotspot_success": "Punto de acceso wifi modificado con éxito",
    "menu_level_precision_with_ble": "Gestionar los cambios de piso mediante balizas Bluetooth",
    "menu_enable_import_export_csv": "Importar / exportar usando formato CSV",
    "menu_add_manually_wifi_hotspots": "Agregar puntos de acceso WiFi manualmente",
    "menu_remove_all_hotspots": "¿Está seguro de que desea eliminar todos los puntos de acceso WiFi registrados?",
    "menu_remove_one_hotspot": "¿Está seguro de que desea eliminar este punto de acceso Wi-Fi?",
    "menu_precise_wifi_location_with_ble": "Ubicación wifi precisada mediante Bluetooth"
  },
  "locationindoorble": {
    "menu_remove_one_beacon_transparent": "¿Estás seguro de que quieres eliminar este UUID?",
    "menu_add_beacon_transparent_success": "UUID agregado exitosamente",
    "menu_condition_uuid_transparent": "El formato del UUID debe tener 32 caracteres en hexadecimal",
    "menu_add_beacon_transparent_failed": "UUID ya registrado",
    "menu_list_ssid_filtered": "Lista de SSID a filtrar",
    "menu_custom_model": "Otro UUID",
    "menu_ble_based_indoor_location_transparent": "Localización interior basada en Bluetooth (Modo Transparente)",
    "menu_list_uuid_filtered": "Lista de UUID a filtrar",
    "menu_add_uuid_filtered": "Agregar ID de empresa (UUID) a filtrar",
    "menu_ble_based_indoor_location": "Ubicación interior basada en Bluetooth",
    "menu_enable_ble_based_indoor_location": "Activar la ubicación interior basada en Bluetooth",
    "menu_download_excel_file_template_for_importation": "Descargue la plantilla de Excel para importar",
    "menu_import_a_new_ble_beacons_file": "Importar un nuevo archivo .csv de balizas Bluetooth",
    "menu_import_succeeded": "Importación exitosa",
    "menu_confirm_import": "Confirmar la importación",
    "menu_import_will_replace_existing_list": "La importación desde un archivo .csv sustituirá la lista real de balizas Bluetooth guardada",
    "menu_error_while_importing": "Error al importar",
    "menu_excel_file_is_empty": "El archivo de Excel está vacío",
    "menu_check_your_excel_file": ". Verifique su archivo de Excel.",
    "menu_export_to_csv_ble_configuration": "Exportar a un archivo .csv la lista de balizas Bluetooth",
    "menu_no_ble_beacon_recorded": "No se registró ningún baliza Bluetooth",
    "menu_no_ble_beacon_imported": "No se importó ningún baliza Bluetooth",
    "menu_number_of_registered_ble_beacons": "Número de balizas Bluetooth registrados: ",
    "menu_incorrect_column_headers_ble": "Encabezados de columna incorrectos. Por favor, utilice la plantilla de Excel para registrar las balizas Bluetooth y guárdela en formato .csv con el carácter ';' como separador.",
    "menu_number_of_imported_ble_beacons": "Número de balizas Bluetooth importados: ",
    "menu_an_error_was_detected_line": "Se detectó un error en la línea ",
    "menu_add_beacon": "Añadir una baliza Bluetooth",
    "menu_modify_beacon": "Editar una baliza Bluetooth",
    "menu_add_manually_ble_beacons": "Agregar balizas Bluetooth manualmente",
    "menu_remove_all_beacons": "¿Está seguro de que desea eliminar todas las balizas Bluetooth almacenadas?",
    "menu_remove_one_beacon": "¿Está seguro de que desea eliminar esta baliza Bluetooth?",
    "menu_add_beacon_success": "Baliza Bluetooth añadida con éxito",
    "menu_add_beacon_failed": "Error al agregar una nueva baliza Bluetooth",
    "menu_modify_beacon_success": "Baliza Bluetooth cambió con éxito",
    "menu_beacon_model": "Modelo de baliza Bluetooth",
    "menu_beacon_company_id": "Empresa identificador",
    "menu_beacon_id": "Baliza identificadora",
    "menu_beacon_type": "Escribe",
    "menu_beacon_level": "Escenario",
    "menu_beacon_code_zone": "Codigo de area",
    "menu_beacon_description": "Descripción",
    "menu_tippy_uuid": "Corresponde al UUID del protocolo iBeacon, que es una secuencia de 32 caracteres hexadecimales",
    "menu_tippy_namespace_id": "Corresponde al namespace ID del protocolo Eddystone, que es una secuencia de 20 caracteres hexadecimales",
    "menu_tippy_bbe9_major_minor": "Corresponde a los 8 caracteres hexadecimales impresos en la baliza Bluetooth e-BBE9",
    "menu_tippy_loc_ex_mac_address": "Corresponde a la dirección MAC impresa en la baliza Bluetooth LOC-EX 01 (6 pares de caracteres hexadecimales separados por ':')",
    "menu_tippy_ibeacon_major_minor": "Corresponde a la concatenación de Major + Minor del protocolo iBeacon, que es una secuencia de 8 caracteres hexadecimales",
    "menu_tippy_instance_id": "Corresponde al instance ID del protocolo Eddystone, que es una secuencia de 12 caracteres hexadecimales",
    "menu_tippy_level": "0 para la planta baja, número negativo para los sótanos (por ejemplo: -3)",
    "menu_tippy_code_zone": "Información enviada por el protocolo SIA",
    "menu_tippy_description": "Ubicación asociada al hotspot. Está prohibido usar el carácter '_' cuando la opción de enviar mensajes a los relojes Emerit está activada debido a un error (este error se solucionará en la próxima actualización)",
    "menu_err_ibeacon_company_id": "ID de compañía incorrecta. Esto corresponde al UUID. Por lo tanto, debe constar de 32 caracteres hexadecimales.",
    "menu_err_ibeacon_beacon_id": "Identificación de baliza incorrecta. Esto corresponde a Major + Minor. Por lo tanto, debe constar de 8 caracteres hexadecimales.",
    "menu_err_eddystone_company_id": "ID de compañía incorrecta. Esto corresponde al namespace ID. Por lo tanto, debe constar de 20 caracteres hexadecimales.",
    "menu_err_eddystone_beacon_id": "Identificación de baliza incorrecta. Esto corresponde al instance ID. Por lo tanto, debe constar de 12 caracteres hexadecimales.",
    "menu_err_bbe9_beacon_id": "Identificación de etiqueta incorrecta. Esto corresponde a los 8 caracteres hexadecimales impresos en la baliza Bluetooth e-BBE9.",
    "menu_err_bbe9_already_exist": "Hay una baliza Bluetooth con identificadores.",
    "menu_err_loc_ex_beacon_id": "ID de baliza incorrecto. El ID de baliza corresponde a la dirección MAC impresa en la baliza Bluetooth LOC-EX 01.",
    "menu_err_loc_ex_already_exist": "Ya existe una baliza Bluetooth LOC-EX 01 con este identificador.",
    "menu_err_eddy_already_exist": "Ya existe una baliza Bluetooth e-BBE9 con este identificador.",
    "menu_err_ibeacon_already_exist": "Ya existe una baliza Bluetooth iBeacon con este UUID y este Major + Minor",
    "menu_err_wrong_level": "Error al entrar al piso. Este debe ser un número positivo o negativo.",
    "menu_err_empty_desc": "El campo de descripción no puede estar vacío.",
    "menu_err_wrong_code_zone": "La zona de código debe ser una secuencia de 3 números entre 001 y 999. Ejemplo: 007.",
    "menu_level_precision_with_ble": "Manejar los cambios de piso",
    "menu_err_one_elem_missing_level": "No se ha ingresado el piso para todas las balizas Bluetooth. Por favor, consulte la lista de registrados.",
    "menu_err_wifi_level_precision_is_activated": "Se ha seleccionado la gestión de los cambios de piso mediante balizas Bluetooth en la localización interior Wifi. Este parámetro es obligatorio.",
    "menu_err_no_level_beacon": "Se necesita al menos una baliza con el tipo establecido como NIVEL para manejar los cambios de piso",
    "menu_enable_import_export_csv": "Importar / exportar archivo .csv",
    "menu_title_refresh_ble_choice": "Frecuencia de actualización",
    "menu_scan_mode_fastest": "Actualización a intervalos regulares (6 segundos, consumo alto)",
    "menu_scan_mode_average": "Actualización a intervalos regulares (10 segundos, consumo medio)",
    "menu_scan_mode_on_demand": "Actualización solo al transmitir un evento",
    "menu_ble_new_algo_activation_title":"Configuración del algoritmo",
    "menu_ble_new_algo_activation":"Activar el nuevo algoritmo de localización de balizas"
  },
  "internalrecipients": {
    "menu_name_recipient": "Nombre del destinatario",
    "menu_duration_of_call_attempt_before_next_number": "Duración del intento de llamada de alerta antes de pasar al siguiente número",
    "menu_event_select_call_and_or_sip_messaging": "Seleccionar llamada y/o mensaje SIP",
    "menu_request_loc_with_sms_command": "Este destinatario puede solicitar la ubicación mediante el siguiente comando de SMS: ?LOC",
    "menu_sip_scenario": "Transmisión de alertas vía cuenta SIP",
    "menu_alert_configuration_not_available_with_pec": "Cuando se activa el escenario de alerta 'SMS con acuse de recibo y luego llamada', la recepción de alertas se configura automáticamente.",
    "menu_sens_sms_with_sip_not_possible": "El envío de SMS a un número de teléfono a través de una cuenta SIP no está disponible. Sólo está disponible la llamada.",
    "menu_err_sip_account_already_exist": "Ya existe un destinatario con este ID SIP",
    "menu_receive_events_by_sip_messaging": "Este destinatario recibe eventos por mensaje SIP",
    "menu_switch_watch_is_using_sip_account": "Este destinatario está usando una cuenta SIP",
    "menu_recipient_added_with_success": "Destinatario añadido",
    "menu_recipient_modified_with_success": "Destinatario cambiado",
    "menu_modify_recipient": "Modificar un destinatario",
    "menu_sip_account_id": "Identificador de SIP",
    "menU_sip_servel_url": "URL del servidor SIP",
    "menu_icon_sms_desc": ": Este destinatario recibe alertas por SMS",
    "menu_icon_phone_desc": ": Este destinatario recibe alertas por llamada",
    "menu_icon_watch_desc": ": Este destinatario es un reloj Emerit",
    "menu_icon_sip_desc": ": Este destinatario utiliza una cuenta SIP",
    "menu_number_or_sip_id": "Número de teléfono / identificador SIP",
    "menu_remove_all_recipients": "¿Está seguro de que desea eliminar todos los destinatarios registrados?",
    "menu_remove_one_recipient": "¿Está seguro de que desea eliminar este destinatario?",
    "menu_add_recipient": "Agregar un destinatario",
    "menu_sms_and_calls_cycle": "SMS y llamadas en cascada",
    "menu_sms_then_calls_cycle": "SMS y luego llamadas en cascada",
    "menu_sms_with_acknowledgment_then_call": "SMS con consideración luego llamar",
    "menu_scenario_is": "Escenario de alerta:",
    "menu_settings": "Ajustes",
    "menu_nb_recipients_saved": "Número de destinatarios internos registrados:",
    "menu_is_emerit_watch": "Este receptor es un reloj Emerit",
    "menu_internal_recipients": "Destinatarios internos",
    "menu_monitoring_center_call_numbers": "Número de llamada del vigilancia remota",
    "menu_recipient": "Destinatario",
    "menu_enable_recipient": "Activar el destinatario",
    "menu_call_number": "Número de llamada",
    "menu_enable_call_number": "Activar el número de llamada",
    "menu_name": "Nombre",
    "menu_phone_number_international_format": "Número de teléfono (formato internacional)",
    "menu_receive_alerts_by_call": "Este destinatario recibe alertas por llamada",
    "menu_receive_events_by_sms": "Este destinatario recibe eventos por SMS",
    "menu_event_alerts": "Alertas",
    "menu_event_system_status": "Estado del sistema",
    "menu_event_charge_status": "Estado de la carga",
    "menu_event_low_battery": "Batería Baja",
    "menu_event_beacon_ble_low_battery": "Batería baja de baliza Bluetooth",
    "menu_event_select_call_and_or_sms": "Seleccionar llamada y / o SMS",
    "menu_nfc_reading": "Lectura de etiquetas NFC"
  },
  "internalrecipientssettings": {
    "menu_internal_recipients_settings": "Programación destinatarios internos",
    "menu_sms_language_selection": "Selección del idioma de los SMS",
    "menu_alert_scenario_type": "Selección del escenario de alerta",
    "menu_sms_and_calls_cycle": "SMS y llamadas en cascada",
    "menu_sms_then_calls_cycle": "SMS después llamadas en cascada",
    "menu_sms_with_acknowledgment_then_call": "SMS con absolucion despues llamada",
    "menu_maximum_waiting_time_for_acknowledgment_reception": "Tiempo de espera máximo para la recepción del SMS de absolución",
    "menu_call_cycle_stopped_by_sms": "Fin del ciclo de llamadas por SMS",
    "menu_show_sms_data_selection": "Enseñar la selección de los datos SMS",
    "menu_sms_data_selection": "Selección de los datos SMS",
    "menu_basic_data": "Datos básicos",
    "menu_gps_data": "Datos GPS",
    "menu_device_user": "Usuario del aparato",
    "menu_battery_level": "Nivel de bateria",
    "menu_event_timestamp": "Marca de tiempo del evento",
    "menu_google_map_link": "Enlace Google Map",
    "menu_timestamp": "Marca de tiempo",
    "menu_adress_google_reverse_geocoding": "Dirección (Google reverse geocoding)",
    "menu_accuracy": "Precisión",
    "menu_coordinates_DMS_format": "Detalles formato DMS",
    "menu_coordinates_DD_format": "Detalles formato DD",
    "menu_alert_labels_customization": "Personalización del denominado de alerta",
    "menu_show_alert_labels_customization": "Enseñar la personalización del denominado de alerta",
    "menu_request_for_help": "Solicitud de rescate (defecto)",
    "menu_assault": "Agresión (defecto)",
    "menu_no_movement": "Inmovilidad (defecto)",
    "menu_tilt": "Inclinación (defecto)"
  },
  "sipconfiguration": {
    "menu_title": "VOIP",
    "menu_sip_need_configuration": "Configurar VOIP",
    "menu_account_name": "Descripción de cuenta",
    "menu_user_name": "Nombre de usuario",
    "menu_user_password": "Contraseña",
    "menu_server_url": "Url del servidor"
  },
  "ipserversettings": {
    "menu_std_gps_timestamp(O)": "[H] Marca de tiempo de posición GPS",
    "menu_extended_data_T2i_format_selection": "Selección de datos extendida en T2i© formato ",
    "menu_T2i_format": "T2i© Formato ",
    "menu_sia_protocol_settings": "Programacion Protocolo SIA DC-09",
    "menu_encapsulated_alarm_protol": "Protocolo de alarma encapsulado",
    "menu_admeco_cid_contact_id": "Ademco Contact ID (CID)",
    "menu_sia_dcs": "SIA DCS",
    "menu_extended_data_format_and_selection": "Formato y selección de los extended data",
    "menu_standard_format": "Formato estandar",
    "menu_esi_format": "ESI© formato",
    "menu_add_the_message_ending_timestamp": "Anadir el marca de hora al final del mensage",
    "menu_extended_data_standard_format_selection": "selección datos extendidos formato estandar",
    "menu_extended_data_esi_format_selection": "selección datos extendidos formato ESI",
    "menu_std_battery": "[B] nivel de bateria",
    "menu_std_event_timestamp": "[H] marca de hora evento",
    "menu_std_imei": "[D] IMEI del dispositivo",
    "menu_std_beacon_id": "[C] Identificador de punto de acceso Wifi / baliza Bluetooth",
    "menu_std_gps_accuracy": "[M] precisión de posicion GPS",
    "menu_std_gps_timestamp": "[O] marca de hora posicion GPS",
    "menu_std_gps_speed": "[S] ] velocidad posicion GPS",
    "menu_std_gps_validity": "[V] ] validez posicion GPS",
    "menu_std_gps_altitude": "[Z]  altitud posicion GPS",
    "menu_esi_battery_level": "$B nivel de bateria",
    "menu_esi_gps_timestamp": "$T marca de hora posicion GPS",
    "menu_gps_coordinates_format": "Formato posicion GPS",
    "menu_gps_DD": "DD grados decimales",
    "menu_gps_DDM": "DDM grados minutos decimales",
    "menu_customed_field": "Campo perzonalizado",
    "menu_lpref": "Lpref",
    "menu_monitoring_center_server_sia_protocol_settings": "Configuración protocolo SIA DC-09 para servidor del centro de monitoreo de alarmas",
    "menu_monitoging_server_sia_protocol_settings": "Configuración protocolo SIA DC-09para servidor de supervision",
    "menu_location_maximum_age": "Edad máxima de la ubicación",
    "menu_location_maximum_accuracy": "Máxima precisión de ubicación"
  },
  "ipserver": {
    "menu_unit_description_message":"Mensajes",
    "menu_event_end_tracking_stop_post_alert":"Fin del seguimiento al detener la alerta de envío",
    "menu_event_end_tracking_after_x_message":"Fin del seguimiento después de enviar ",
    "menu_event_permanents":"Permanentes",
    "menu_event_trigger_alert":"Al dispararse una alerta",
    "menu_enable_link_test":"Activar la prueba de enlace",
    "menu_link_test":"Prueba de enlace",
    "menu_alarm_monitoring_center_server": "Servidor del centro de monitoreo de alarmas",
    "menu_enable_main_server": "Activar el servidor principal",
    "menu_main_server": "Servidor principal",
    "menu_events_to_be_transmitted": "Eventos a transmitir",
    "menu_client_id": "Identificador de cliente",
    "menu_ip_url": "Dirección IP o URL",
    "menu_port": "Número de puerto",
    "menu_enable_cyclic_test": "Activar el seguimiento",
    "menu_cyclic_test": "Seguimiento",
    "menu_test_interval": "Intervalo de envío",
    "menu_backup_server": "Servidor de respaldo",
    "menu_backup_sms": "Respaldo de SMS",
    "menu_enable_backup_server": "Activar el servidor de respaldo",
    "menu_enable_backup_sms": "Activar el respaldo de SMS",
    "menu_port_must_be_between_0_and_65536": "El número de puerto debe estar entre 0 y 65536"
  },
  "wearingways": {
    "menu_waist_worn": "llevado a la muñeca",
    "menu_enable_no_movement_detection": "Activar la detección de inmovilidad",
    "menu_enable_tilt_detection": "Activar la detección de inclinación",
    "menu_no_movement_detection": "Detección de inmovilidad",
    "menu_tilt_detection": "Detección de inclinación",
    "menu_detection_duration_before_prealert": "Duración de detección antes de notificación prealerta",
    "menu_detection_threshold": "Umbral de detección",
    "menu_detection_mode": "Modo de detección",
    "menu_tilt_only": "Inclinación solo",
    "menu_tilt_without_movement": "Inclinación con ausencia de movimiento",
    "menu_select_a_wearing_way_and_modify_settings_if_needed": "seleccione una forma de uso y modifique la configuración si es necesario"
  },
  "wristalert": {
    "menu_noise_mode": "Altavoz activado > solicitud de rescate",
    "menu_discret_mode": "Silencio > agresión",
    "menu_choose_alert_mode": "Modo de alerta",
    "menu_title_wrist_alert": "Configuración de la alerta mediante el movimiento de la muñeca",
    "menu_choose_threshold": "Intensidad de movimiento para activar alerta",
    "menu_thresold_value_low": "Bajo rápido",
    "menu_thresold_value_middle": "Medio rápido (recomendado)",
    "menu_thresold_value_high": "Muy rápido"
  },
  "login": {
    "menu_first_device_boot_error": "Si esta es la primera configuración de un dispositivo, es necesario seguir un procedimiento de puesta en marcha antes de poder configurarlo.",
    "menu_quick_start_guide": "Ayuda en linea",
    "menu_home_help": "https://magneta.document360.io/v1/es",
    "menu_connexion_error": "Ha ocurrido un problema de conexión. Verifique su conexión a Internet.",
    "menu_enter_five_last_digits_of_serial_number": "Entrar los 5 últimos números de serie (SN)",
    "menu_enter_five_last_digits_of_imei_number": "Entrar los 5 últimos números IMEI",
    "menu_login": "Login",
    "menu_select_device_model": "Seleccionar el modelo del dispositivo",
    "menu_please_select_the_device_model": "Primero seleccione el modelo de dispositivo",
    "menu_entered_numbers_dont_match_with_available_device": "Los números ingresados no coinciden con ningún dispositivo o se ha producido un problema de conexión.",
    "menu_please_read_quick_start_guide_first": "Lea primero la guía de inicio rápido.",
    "menu_configure_this_device": "Configurar este dispositivo",
    "menu_configuration_models_management": "Gestión de plantillas de configuración",
    "menu_deploy_a_configuration_model": "Implementar un plantilla de configuración",
    "menu_invalid_file": "Archivo inválido",
    "menu_only_file_with_emerit_extension_are_supported": "Solo se admiten archivos con extensión .emerit",
    "menu_create_configuration_model": "Crear un plantilla de configuración",
    "menu_modify_a_configuration_model": "Modificar un plantilla de configuración",
    "menu_drag_and_drop_file_or_click_to_select": "Arrastre y suelte o haga clic para seleccionar el archivo del plantilla de configuración",
    "menu_configuration_model": "Plantilla de configuración",
    "menu_configuration_model_creation": "Creación de un plantilla de configuración",
    "menu_connect_to_a_device": "Conectarse a un dispositivo",
    "menu_models_dont_match": "El archivo seleccionado no coincide o el modelo de configuración no es compatible con el modelo seleccionado.",
    "menu_select_language": "Seleccione el idioma"
  },
  "wifi": {
    "menu_mode_utilisation": "Actualización del huso horario según el modo de uso",
    "menu_select_utc": "Seleccione una zona horaria",
    "menu_error_utc_none": "Seleccione una zona horaria, por favor",
    "menu_mode_wifi_alone": "Solo WiFi:",
    "menu_mode_gsm_plus_wifi": "WiFi + GSM (tarjeta SIM): actualización automática",
    "menu_certificate_upload_success": "Certificado cargado correctamente",
    "menu_certificate_upload_fail": "Error en la descarga del certificado",
    "menu_err_pls_upload_certificate": "Por favor, descargue un certificado",
    "menu_err_only_pfx_p12_accepted": "Sólo se aceptan certificados en formato .pfx/.p12.",
    "menu_err_certificate_is_too_heavy": "El tamaño del certificado es demasiado grande, no puede superar los 5Mb.",
    "menu_upload_certificat": "Importar el certificado PEAP (.pfx/.p12)",
    "menu_certificate_password": "Contraseña",
    "menu_certificate_identifiant": "Identificador",
    "menu_certificate_type": "CA Certificat",
    "menu_name_is_update_app_by_wifi_enabled": "Recupere configuraciones e instale actualizaciones solo si wifi está activo",
    "menu_use_wifi_connection": "Usar la conexión wifi para enviar alertas",
    "menu_config_wifi_network": "Administrar redes Wifi",
    "menu_nb_wifi_network_saved": "Número de redes WiFi guardadas: ",
    "menu_send_alert_with_wifi": "La red Wifi, si está disponible, tendrá prioridad sobre la red GSM",
    "menu_network_added_with_success": "Red Wi-Fi añadida",
    "menu_network_modified_with_success": "Red Wifi modificada",
    "menu_remove_all_networks": "¿Está seguro de que desea eliminar todas las redes WiFi guardadas?",
    "menu_remove_one_network": "¿Está seguro de que desea eliminar esta red WiFi?",
    "menu_registred_wifi_networks_list": "Lista de redes wifi registradas - unicamente 2.4 GHz",
    "menu_ssid": "Nombre de red SSID",
    "menu_security_type": "Tipo de seguridad",
    "menu_password": "Contraseña",
    "menu_add_a_wifi_network": "Agregar una red Wifi",
    "menu_modify_a_wifi_network": "Modificar una red Wifi",
    "menu_do_you_want_to_remove_this_network": "¿Quieres eliminar esta red?",
    "menu_please_select_security_type": "Seleccione un tipo de seguridad",
    "menu_hidden": "Red oculta",
    "menu_network_already_exists": "La red ya existe"
  },
  "whitelist": {
    "menu_add_a_recipient_whitelist": "Agregar número entrante adicional",
    "menu_modify_a_recipient_whitelist": "Modificar número entrante adicional",
    "menu_recipient_whitelist_modified_with_success": "Número entrante adicional modificado con éxito",
    "menu_recipient_whitelist_added_with_success": "Número entrante adicional agregado con éxito",
    "menu_nb_recipient_whitelist": "Números entrantes adicionales: ",
    "menu_nb_recipient_mirror": "Destinatarios de alertas: ",
    "menu_remove_all_recipient_whitelist": "¿Está seguro de que desea eliminar todos los números entrantes adicionales de la lista blanca?",
    "menu_remove_one_recipient_whitelist": "¿Está seguro de que desea eliminar este número entrante adicional de la lista blanca?",
    "menu_add_whitelist_recipient": "Agregar número entrante",
    "menu_nb_total_whitelist_recipient": "Número total de números entrantes permitidos en la lista blanca: ",
    "menu_whitelist_title": "Configuración de la lista blanca"
  },
  "customscreens": {
    "menu_img_upload_info": "El tamaño de una imagen .png/.gif no debe exceder 1 MB. La resolución de la pantalla del reloj es de 128x96 píxeles. Para una reproducción óptima de la imagen, respete la altura/ancho de la pantalla (1,33). Si la relación no se respeta, la imagen se redimensionará automáticamente.",
    "menu_err_pls_upload_launch_screen": "Cargue una imagen.",
    "menu_err_pls_upload_widget_screen": "Cargue una imagen o ingrese texto.",
    "menu_err_only_png_gif_accepted": "Solo se aceptan imágenes en formato .png/.gif.",
    "menu_err_img_is_too_heavy": "El tamaño de la imagen es demasiado grande, no puede superar 1 MB.",
    "menu_img_upload_success": "Imagen cargada correctamente",
    "menu_img_upload_fail": "Error al cargar la imagen",
    "menu_custom_screens_overview": "Vista previa",
    "menu_remove_launch_img": "Eliminar imagen",
    "menu_remove_widget_img": "Eliminar imagen de fondo",
    "menu_display_widget_text_in_black": "Mostrar texto en negro",
    "menu_custom_widget_top_text": "Texto superior (máximo 18 caracteres incluidos los espacios)",
    "menu_custom_widget_bottom_text": "Texto inferior (máximo 18 caracteres incluidos los espacios)",
    "menu_upload_launch_screen_img": "Descargar nueva imagen (.png/.gif)",
    "menu_upload_widget_screen_img": "Descargar nueva imagen de fondo (.png/.gif)",
    "menu_title_yes_custom_launch_screen": "Iniciar configuración de imagen",
    "menu_title_no_custom_launch_screen": "Personalizar pantalla de inicio",
    "menu_title_yes_custom_widget_screen": "Configuración del widget de contacto",
    "menu_title_no_custom_widget_screen": "Agregar widget de contacto",
    "menu_custom_screens_title": "Personalizar pantallas"
  },
  "password": {
    "menu_create_new_password_for_model": "Ingrese una contraseña de 6 caracteres que incluya al menos 1 minúscula, 1 mayúscula y 1 número, sin caracteres especiales. Esta contraseña se solicitará cada vez que se modifique este modelo.",
    "menu_enter_password": "Introducir la contraseña",
    "menu_enter_deployment_password": "Ingrese la contraseña de implementación",
    "menu_create_new_password": "Ingrese una contraseña de 6 caracteres que incluya al menos 1 minúscula, 1 mayúscula y 1 número, sin carácter especial. Esta contraseña se solicitará cada vez que se conecte a este dispositivo.",
    "menu_confirm_password": "Confirmar Contraseña",
    "menu_wrong_password": "Contraseña incorrecta",
    "menu_please_retry": "Por favor, intenta de nuevo",
    "menu_empty_password": "Contraseña vacía",
    "menu_passwords_dont_match": "Las contraseñas no coinciden",
    "menu_at_least_one_password_is_empty": "Al menos una contraseña está vacía",
    "menu_password_dont_meet_requirements": "La contraseña no cumple con los requisitos",
    "menu_modify_password": "Modificar la contraseña",
    "menu_password_creation_warning": "Al iniciar sesión en emerit-console y después de crear la contraseña, se convierte en el administrador de facto del dispositivo identificado anteriormente"
  },
  "contact": {
    "menu_contact_link": "https://www.magneta.fr/es/contacto/",
    "menu_if_you_have_any_question_please_click_on_the_folowing_button": "For any question about device configuration or usage, please click on following button:",
    "menu_technical_support_email": "Correo electrónico de soporte técnico:",
    "menu_online_support_request_form": "Formulario de solicitud de soporte en línea"
  },
  "deploy": {
    "menu_upload_failed": "La implementación falló, verifique la conexión a Internet y vuelva a intentarlo",
    "menu_device_updated": "Dispositivo actualizado",
    "menu_fill_server_ids": "Configuración de ID de cliente",
    "menu_fill_personal_fields": "Rellene los datos a personalizar",
    "menu_target_a_device": "Dispositivo a configurar",
    "menu_client_id_for_tls_main_server": "ID de cliente para el servidor principal del centro de monitoreo",
    "menu_client_id_for_tls_backup_server": "ID de cliente para el servidor de respaldo del centro de monitoreo",
    "menu_client_id_for_supervision_main_server": "ID de cliente para el servidor de supervisión principal",
    "menu_client_id_for_supervision_backup_server": "ID de cliente para el servidor de supervisión de respaldo",
    "menu_from_a_configuration_model": "Desde la plantilla de configuración",
    "menu_five_last_digits_of_serial_number": "5 últimos dígitos del número de serie (SN)",
    "menu_five_last_digits_of_imei_number": "5 últimos dígitos del número IMEI",
    "menu_deploy_not_allowed": "No se le permite configurar este dispositivo por implementación, las contraseñas de implementación no coinciden"
  },
  "side_menu": {
    "configuration": "Configuración",
    "info": "Manuales",
    "contact": "Contacto"
  },
  "watchhelp": {
    "menu_custom_screens_view": "https://www.support.magneta.fr/docs/es/watch-system-options#ii-personalizar-pantalla",
    "menu_locex_help": "https://www.support.magneta.fr/docs/es/beacon-loc-ex-01",
    "menu_wifi_connection_view": "https://www.support.magneta.fr/docs/es/watch-communication-options#i-utilizar-conexi%C3%B3n-wifi",
    "menu_sip_view": "https://www.support.magneta.fr/docs/es/watch-communication-options#ii-activar-voz-sobre-ip-voip-con-cuenta-sip",
    "menu_whitelist_view": "https://www.support.magneta.fr/docs/es/watch-communication-options#iii-lista-blanca-limitaci%C3%B3n-de-llamadas-entrantes",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/es/watch-main-page#i-nombre-de-usuario-del-dispositivo",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/es/watch-main-page#ii-procesamiento-de-alertas",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/es/watch-main-page#iii-modo-de-activaci%C3%B3n-de-alertas",
    "menu_loc": "https://www.support.magneta.fr/docs/es/watch-main-page#iv-localizaci%C3%B3n-de-alertas",
    "menu_communication_option": "https://www.support.magneta.fr/docs/es/watch-main-page#v-opciones-de-comunicaci%C3%B3n",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/es/watch-main-page#vi-monitoreo-de-la-bater%C3%ADa",
    "menu_device_notif_management": "https://www.support.magneta.fr/docs/es/watch-main-page#vii-gesti%C3%B3n-de-notificaciones-de-dispositivos",
    "menu_system_option": "https://www.support.magneta.fr/docs/es/watch-main-page#viii-opciones-del-sistema",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/es/beacon-e-bbe9#asociar-un-tipo-a-balizas",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/es/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/es/watch-configuration-methods#configuraci%C3%B3n-de-los-ajustes-personales",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/es/watch-configuration-methods#introducci%C3%B3n-de-la-contrase%C3%B1a-de-implementaci%C3%B3n",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/es/watch-configuration-methods#modificaci%C3%B3n-de-la-plantilla",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/es/watch-configuration-methods#creaci%C3%B3n-de-plantillas-de-configuraci%C3%B3n",
    "menu_model_login": "https://www.support.magneta.fr/docs/es/watch-configuration-methods#ii-configuraci%C3%B3n-multidispositivo",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/es/watch-configuration-methods#iii-despliegue-de-una-configuraci%C3%B3n",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/es/watch-configuration-methods#introduzca-la-contrase%C3%B1a",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/es/watch-configuration-methods#creaci%C3%B3n-de-contrase%C3%B1as",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/es/watch-alerts-localization#i-ubicaci%C3%B3n-gps-al-aire-libre",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/es/watch-alerts-localization#ii-ubicaci%C3%B3n-interior-wifi",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/es/watch-alerts-localization#iii-ubicaci%C3%B3n-interior-bluetooth",
    "menu_main_page": "https://www.support.magneta.fr/docs/es/watch-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/es/watch-commissioning#iv-primer-arranque"
  },
  "phonehelp": {
    "menu_locex_help": "https://www.support.magneta.fr/docs/es/beacon-loc-ex-01",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/es/smartphones-main-page#i-nombre-de-usuario-del-dispositivo",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/es/smartphones-main-page#ii-procesamiento-de-alertas",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/es/smartphones-main-page#iii-modo-de-activaci%C3%B3n-de-alertas",
    "menu_loc": "https://www.support.magneta.fr/docs/es/smartphones-main-page#iv-localizaci%C3%B3n-de-alertas",
    "menu_communication_option": "https://www.support.magneta.fr/docs/es/smartphones-main-page#v-opciones-de-comunicaci%C3%B3n",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/es/smartphones-main-page#vi-monitoreo-de-la-bater%C3%ADa",
    "menu_deactivate_alert_system": "https://www.support.magneta.fr/docs/es/smartphones-main-page#vii-desactivaci%C3%B3n-del-sistema-de-alerta-por-parte-del-usuario",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/es/beacon-e-bbe9#asociar-un-tipo-a-balizas",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/es/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/es/smartphones-configuration-methods#configuraci%C3%B3n-de-los-ajustes-personales",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/es/smartphones-configuration-methods#introducci%C3%B3n-de-la-contrase%C3%B1a-de-implementaci%C3%B3n",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/es/smartphones-configuration-methods#modificaci%C3%B3n-de-la-plantilla",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/es/smartphones-configuration-methods#creaci%C3%B3n-de-plantillas-de-configuraci%C3%B3n",
    "menu_model_login": "https://www.support.magneta.fr/docs/es/smartphones-configuration-methods#ii-configuraci%C3%B3n-multidispositivo",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/es/smartphones-configuration-methods#iii-despliegue-de-una-configuraci%C3%B3n",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/es/smartphones-configuration-methods#introduzca-la-contrase%C3%B1a",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/es/smartphones-configuration-methods#creaci%C3%B3n-de-contrase%C3%B1as",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/es/smartphones-alerts-localization#i-ubicaci%C3%B3n-gps-al-aire-libre",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/es/smartphones-alerts-localization#ii-ubicaci%C3%B3n-interior-wifi",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/es/smartphones-alerts-localization#iii-ubicaci%C3%B3n-interior-bluetooth",
    "menu_main_page": "https://www.support.magneta.fr/docs/es/smartphones-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/es/smartphones-commissioning#ii-primer-arranque"
  }
}
