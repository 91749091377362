<template>
  <!-- BEGIN: WifiHotspotSettings local component -->
  <div class="intro-y col-span-12 lg:col-span-5 lg:col-start-1 2xl:col-span-4 2xl:col-start-1">
    <BoxTitle
      color="bg-red-300"
      title="common.menu_settings"
    />
    <div class="clearfix box mb-6">
      <div class="clearfix p-3 border-b border-gray-200">
        <div class="grid grid-cols-12 mt-1">
          <div class="col-span-8 col-start-1 mt-3 font-bold text-theme-0">
            {{ getNbWifiHotspotsSaved }}
          </div>
          <ButtonTargetModal
            v-if="nbWifiHotspots"
            target-id="#modal-alert-confirm"
            custom-class="btn btn-danger col-start-10 col-span-3 text-center"
            text="common.menu_remove_everything"
            @click="$emit('remove-all-hotspots')"
          />
        </div>
        <Tippy
          :content="$t(getTippyText)"
          :options="{
            theme: 'light',
          }"
        >
          <CustomSwitch
            :model-value="isBleLevelActivated"
            text="locationindoorwifi.menu_level_precision_with_ble"
            :is-disabled="true"
            :error-msg="bleLevelPrecisionErrorMsg"
            class="p-2 mt-1"
          />
        </Tippy>
        <CustomSwitch
          v-model="showImportExportButtons"
          class="mb-1 p-2"
          text="locationindoorwifi.menu_enable_import_export_csv"
        />
         <div class="col-span-8 col-start-1 mt-3 font-bold text-theme-0">
          
               {{ $t("locationindoorwifi.menu_title_refresh_choice") }}
          </div>
      <div>
      </div>
        <div class="col-span-8 col-start-1 mt-3">
          <CustomRadioInput
            v-for="(radio, index) in radioInputParams"
            :key="index"
            v-model="wifiRefreshMode"
            :custom-id="radioGroupName + index"
            :description="radio.description"
            :radio-group-name="radioGroupName"
            :value="index"
          />
        </div>
         
      </div>
    </div>
  </div>
  <!-- END: WifiHotspotSettings local component -->
</template>

<script>
import { defineComponent } from 'vue'
import {
  getParameterPTIValue,
  setParameterPTIValue
} from "../../../dao/parametre-pti";

const NO_HOTSPOTS_SAVED = "locationindoorwifi.menu_no_wifi_hotspot_recorded";
const NB_HOTSPOT_SAVED = "locationindoorwifi.menu_number_of_registered_wifi_hotspots";
const REMOVE_ONE_ITEM = 0;
const REMOVE_EVERYTHING = 1;

const I18_TIPPY_DISABLE_HANDLING_FLOORS = "locationindoorwifi.menu_disable_ble_loc_to_handle_floors";
const I18_TIPPY_ENABLE_HANDLING_FLOORS = "locationindoorwifi.menu_enable_ble_loc_to_handle_floors";

export default defineComponent({

  props: {
    nbWifiHotspots: {
      type: Number,
      required: true,
    },
    isBleLevelActivated: {
      type: Boolean,
      required: true,
    },
    bleLevelPrecisionErrorMsg: {
      type: String,
      required: true,
    }
  },

  emits: [
    'remove-all-hotspots',
    "ImportExportBlockStatusChanged",
    "bleStateChanged"
  ],

  created(){
     this.wifiRefreshMode = getParameterPTIValue("isWifiLocRefreshUpdateEnabled");
  },

   mounted() {
    document.getElementById(this.radioGroupName + this.wifiRefreshMode).checked = true;
  },

  data() {
    return {
      wifiRefreshMode: undefined,
      radioGroupName: 'radioOutdoor',
       radioInputParams: [
        { description: 'locationoutdoor.menu_refreseh_only_when_event_sending' },
        { description: 'locationoutdoor.menu_update_at_regular_intervals' },
        ],
      showImportExportButtons: false,
      removeEverything: REMOVE_EVERYTHING,
    }
  },

  computed: {
    
    getNbWifiHotspotsSaved() {
      if (!this.nbWifiHotspots)
        return this.$t(NO_HOTSPOTS_SAVED);
      return this.$t(NB_HOTSPOT_SAVED) + this.nbWifiHotspots;
    },

    getTippyText() {
      if (this.isBleLevelActivated)
        return I18_TIPPY_DISABLE_HANDLING_FLOORS;
      return I18_TIPPY_ENABLE_HANDLING_FLOORS;
    }
  },

  methods: {
      save() {
        setParameterPTIValue("isWifiLocRefreshUpdateEnabled", this.wifiRefreshMode);
        return true;
    }
  },

  watch: {

    showImportExportButtons(newVal) {
      this.$emit('ImportExportBlockStatusChanged', newVal);
    }
  },

})
</script>
