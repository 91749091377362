<template>
  <div class="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3">
    <ul class="pagination">
      <li>
        <a 
          class="pagination__link"
          @click="$emit('begin')"
        >
          <ChevronsLeftIcon class="w-4 h-4" />
        </a>
      </li>
      <li>
        <a 
          class="pagination__link"
          @click="$emit('decrement')"
        >
          <ChevronLeftIcon class="w-4 h-4" />
        </a>
      </li>
      <li>
        <div class="mt-2 pr-1">
          {{ currentPage }} / {{ totalPages }}
        </div>
      </li>
      <li>
        <a 
          class="pagination__link"
          @click="$emit('increment')"
        >
          <ChevronRightIcon class="w-4 h-4" />
        </a>
      </li>
      <li>
        <a 
          class="pagination__link"
          @click="$emit('end')"
        >
          <ChevronsRightIcon class="w-4 h-4" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A pagination for a table (ex: 2 / 3), with arrows to navigate inside 
 * it. 
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {Number} currentPage
 *    Current page of the table (ex: 2 / totalPages).
 * 
 * @param {Number} totalPages
 *    Total number of pages in the table (ex: currentPage / 10).
 * 
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits begin
 *    Emits an empty event when double left arrow is clicked. currentPage 
 *    should be updated to 1.
 * 
 * @emits end
 *    Emits an empty event event when double right arrow is clicked. 
 *    currentPage should be updated to totalPages.
 * 
 * @emits decrement
 *    Emits an empty event event when single left arrow is clicked. 
 *    currentPage should be decremented by 1 (except if currentPage is 
 *    already equals to 1).
 * 
 * @emits increment
 *    Emits an empty event event when single right arrow is clicked. 
 *    currentPage should be incremented by 1 (except if currentPage is 
 *    already equals to totalPages).
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({

  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    }
  },

  emits: ['begin', 'decrement', 'increment', 'end'],
})
</script>
