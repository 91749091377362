
<template>
  <!-- BEGIN: BleBeaconSettings local component-->
  <div class="intro-y col-span-12 lg:col-span-6 lg:col-start-1 2xl:col-span-5 2xl:col-start-1">
    <BoxTitle
      color="bg-red-300"
      title="common.menu_settings"
    />
    <div class="clearfix box mb-6">
      <div class="clearfix p-3 border-b border-gray-200">
         <div class="col-span-8 col-start-1 mt-3 font-bold text-theme-0">
          
               {{ $t("locationindoorble.menu_title_refresh_ble_choice") }}
          </div>
      <div>
      </div>
        <div class="col-span-8 col-start-1 mt-3">
          <CustomRadioInput
            v-for="(radio, index) in radioInputParams"
            :key="index"
            v-model="bleMode"
            :custom-id="radioGroupName + index"
            :description="radio.description"
            :radio-group-name="radioGroupName"
            :value="index"
          />
        </div>
       
      </div>
    
    </div>
  </div>
  <!-- END: BleBeaconSettings local component-->
</template>

<script>
import { defineComponent } from 'vue'
import {
  getBoolPtiValue,
  setNumberPtiValue,
  getParameterPTIValue,
  setParameterPTIValue,
} from "../../../dao/parametre-pti";

const NO_BEACONS_SAVED = "locationindoorble.menu_no_ble_beacon_recorded";
const NB_BEACON_SAVED = "locationindoorble.menu_number_of_registered_ble_beacons";

export default defineComponent({


   mounted() {
    document.getElementById(this.radioGroupName + this.bleMode).checked = true;
  },

  data() {
    return {
      bleMode:undefined,
        radioGroupName: 'radioBleScanMode',
       radioInputParams: [
        { description: 'locationindoorble.menu_scan_mode_fastest' },
        { description: 'locationindoorble.menu_scan_mode_average' },
        { description: 'locationindoorble.menu_scan_mode_on_demand' },
        ],
  
    }
  },

  watch:{
  
  },

  created() {
    this.bleMode = getParameterPTIValue("bleScanMode");
  },

   methods: {
      save() {
        setParameterPTIValue("bleScanMode", this.bleMode);
        return true;
    },


    intToBoolean(value) {
      if (value === "1") {
        return true;
      }
      return false;
    },   

      booleanToInt(value) {
      if (value == true) {
        return "1";
      }
      return "0";
    },
   },


})
</script>
