<template>
  <!-- BEGIN: WifiHotpostList local component -->
  <div>
    <Pagination
      v-if="hotspotList.length"
      :current-page="page"
      :total-pages="getTotalPages"
      @begin="page = 1"
      @end="page = getTotalPages"
      @decrement="page = page === 1 ? 1 : --page"
      @increment="page = page === getTotalPages ? page : ++page"
    />
    <CustomTable
      v-if="hotspotList.length"
      :headers="getHeaders"
      :elems-list="getWifiHotspotPage"
      :screen-percent-width="10"
      :showing-errors="true"
      list-element-component-name="ListElement"
      green-button-text="common.menu_modify"
      red-button-text="common.menu_remove"
      green-button-bootstrap-target="#modal-wifi-hotspot-modify"
      red-button-bootstrap-target="#modal-alert-confirm"
      @element-green-button-clicked="$emit('modify-hotspot', $event + (page - 1) * 10)"
      @element-red-button-clicked="$emit('remove-hotspot', $event + (page - 1) * 10)"
    />
  </div>
  <!-- END: WifiHotpostList local component -->
</template>

<script>
import { defineComponent } from 'vue'


const NB_ITEMS = 10;

const MENU_SSID = "locationindoorwifi.menu_hotspot_ssid";
const MENU_BSSID = "locationindoorwifi.menu_hotspot_bssid";
const MENU_CODE_ZONE = "locationindoorwifi.menu_hotspot_code_zone";
const MENU_LEVEL = "locationindoorwifi.menu_hotspot_level";
const MENU_DESC = "locationindoorwifi.menu_hotspot_description";

const EV_MODIFY_HOTSPOT = 'modify-hotspot';
const EV_REMOVE_HOTSPOT = 'remove-hotspot';


export default defineComponent({

  props: {
    hotspotList: {
      type: Array,
      required: true
    },
    isBleLevelActivated: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    EV_MODIFY_HOTSPOT,
    EV_REMOVE_HOTSPOT,
  ],

  data() {
    return {
      page: 1,
      currentIndex: undefined,
    }
  },

  computed: {
    getWifiHotspotPage() {
      const startIndex = (this.page - 1) * NB_ITEMS;
      const length = startIndex + NB_ITEMS < this.hotspotList.length ?
          NB_ITEMS : this.hotspotList.length - startIndex;
      let hotspotPage = new Array(length);
      for (let i = 0; i < length; ++i)
        hotspotPage[i] = this.formatHotspotInfo(this.hotspotList[i + startIndex]);
      return hotspotPage;
    },

    getHeaders() {
      if (this.isBleLevelActivated)
        return [MENU_SSID, MENU_BSSID, MENU_CODE_ZONE, MENU_DESC, MENU_LEVEL];
      return [MENU_SSID, MENU_BSSID, MENU_CODE_ZONE, MENU_DESC];
    },

    getTotalPages() {
      return Math.ceil(this.hotspotList.length / NB_ITEMS);
    }
  },

  watch: {

    /**
     * Update the page number dynamically when wifi hotspots are added / removed.
     */
    'hotspotList.length': {      
      handler: function updatePageNumber(newVal, oldVal) {
        if (this.page > this.getTotalPages)
          --this.page;
        else if (newVal > oldVal)
          this.page = this.getTotalPages;
      }
    },
  },

  methods: {
    formatHotspotInfo(hotspot) {
      const len = this.isBleLevelActivated ? hotspot.length : hotspot.length - 1;
      let newHotspotFields = new Array(len);
      for (let i = 0; i < len; ++i)
          newHotspotFields[i] = hotspot[i];
      return newHotspotFields;
    },

  }

})
</script>
