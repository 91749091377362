<template>
  <!-- BEGIN: AlertTriggeringMode local component -->
  <div class="clearfix box mt-6">
    <BoxTitle title="configuration.menu_alerts_triggering_modes" color="bg-red-300"
      help-locale="menu_alerts_triggering" />
    <CheckBoxFieldAndXIcons v-for="(field, index) in getFields" :key="index" v-model="field.value"
      :custom-id="`checkbox-alert-triggering-${index}`" :check-box-text="field.title" :is-disabled="field.isDisabled"
      :tippy-text="field.tippyText" :error-msg="field.errorMsg" :icon-params="field.iconParams"
      @change="onCheckBoxChange(index)" />
  </div>
  <!-- END: AlertTriggeringMode local component -->
</template>

<script>
import { defineComponent } from "vue";
import {
  getBoolPtiValue,
  getNumberPtiValue,
  setNumberPtiValue
} from "../../../dao/parametre-pti";
import {
  resetAllError, setError
} from '../../../utils/fieldManagement'
import { useStore } from "@/store";


const ERR_SELECT_AT_LEAST_ONE_TRIGGERING_MODE = "common.menu_select_at_least_one_triggering_mode";
const ERR_SELECT_AT_LEAST_ONE_AUTO_DETECTION = "configuration.menu_select_at_least_one_automatic_detection";

const CHECKBOX_PTI_PARAMS = [
  "SOSButton2",
  "SOSButton1",
  "",
  "isWristDetectionEnabled"
];

const PARAM_PTI_TILT = "PerteVerticalite";
const PARAM_PTI_IMMOBILITY = "AbsenceMouvement";

const SOS_BUTTON2_INDEX = 0;
const SOS_BUTTON1_INDEX = 1
const AUTO_DETEC_INDEX = 2;
const WRIST_INDEX = 3;
const I18_TIPPYTEXT_ON_OFF = "configuration.menu_tippy_text_on_off"

const DEVICE_MODEL_NAME = [
  "E-WG100",
  "RG170",
  "RG650",
  "RG655",
];

const SOS_BUTTON2_TITLE = [
  "configuration.menu_alert_activation_sos_button",
  "configuration.menu_alert_activation_front_programmable_button",
  "configuration.menu_alert_activation_power_button",
  "configuration.menu_alert_activation_power_button",
  "configuration.menu_alert_activation_power_button",
];

const SOS_BUTTON1_TITLE = [
  "configuration.menu_alert_activation_power_button",
  "configuration.menu_alert_activation_side_programmable_button",
  "configuration.menu_alert_activation_side_yellow_button",
  "configuration.menu_alert_activation_side_yellow_button",
  "configuration.menu_alert_activation_sos_button_and_programmable_button",
];


export default defineComponent({

  props: {
    isEwg100: {
      type: Boolean,
      required: true,
    },
    deviceModelName: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      fields: [
        {
          value: false,
          title: undefined,
          errorMsg: '',
          iconParams: [
            { componentName: 'SlidersIcon', routeName: 'emerit-alerts', paramsType: 'sos-button-2' },
          ]
        },
        {
          value: false,
          title: undefined,
          errorMsg: '',
          isDisabled: false,
          tippyText: I18_TIPPYTEXT_ON_OFF,
          iconParams: [
            { componentName: 'SlidersIcon', routeName: 'emerit-alerts', paramsType: 'sos-button-1' },
          ],
        },
        {
          value: false,
          title: 'configuration.menu_alert_activation_automatic_detection',
          errorMsg: '',
          iconParams: [
            { componentName: 'SlidersIcon', routeName: 'emerit-wearing-style', paramsType: '' },
          ]
        },
        {
          value: false,
          title: 'configuration.menu_wrist_alert_mode',
          errorMsg: '',
          iconParams: [
            { componentName: 'SlidersIcon', routeName: 'emerit-wrist-alert', paramsType: '' },
          ]
        },
      ],
    }
  },

  computed: {

    getFields() {
      let renderFields = new Array();
      for (let i = 0; i < this.fields.length; ++i)
        if (i != WRIST_INDEX || (i === WRIST_INDEX && this.isEwg100))
          renderFields.push(this.fields[i]);
      return renderFields;
    }
  },

  created() {
    this.initButtonTitles();
    this.initCheckboxValues();
  },

  methods: {

    initButtonTitles() {
      let i = 0;
      for (; i < DEVICE_MODEL_NAME.length; ++i)
        if (this.deviceModelName.valueOf() === DEVICE_MODEL_NAME[i])
          break;
      this.fields[SOS_BUTTON2_INDEX].title = SOS_BUTTON2_TITLE[i];
      this.fields[SOS_BUTTON1_INDEX].title = SOS_BUTTON1_TITLE[i];
    },

    initCheckboxValues() {
      if (getNumberPtiValue('onOffButtonTriggerType') == 2) {
        this.fields[1].value = false;
        this.fields[1].isDisabled = true;
      }
      for (let i = 0; i < this.fields.length; ++i)
        if (i < AUTO_DETEC_INDEX || (i === WRIST_INDEX && this.isEwg100))
          this.fields[i].value = getBoolPtiValue(CHECKBOX_PTI_PARAMS[i]);
      if (useStore().state.emeritValues.alertAutoDetecState
        || getBoolPtiValue(PARAM_PTI_IMMOBILITY)
        || getBoolPtiValue(PARAM_PTI_TILT)) {
        this.$store.commit("emeritValues/updateAlertAutoDetecState", true);
        this.fields[AUTO_DETEC_INDEX].value = true;
      }
    },

    onCheckBoxChange(i) {
      if(getNumberPtiValue("onOffButtonTriggerType")== 2){
        if (this.fields[0].value == false) {
          setNumberPtiValue("SOSButton1", 0);
        }
        if (this.fields[0].value == true) {
          setNumberPtiValue("SOSButton1", 0);
        }
      }
      if(getNumberPtiValue("onOffButtonTriggerType")!= 2){
        if (this.fields[1].value == false) {
          setNumberPtiValue("onOffButtonTriggerType", 0);
        }
        if (this.fields[1].value == true) {
          setNumberPtiValue("onOffButtonTriggerType", 1);
        }
      }

      if (i < AUTO_DETEC_INDEX || (i === WRIST_INDEX && this.isEwg100)) {
        setNumberPtiValue(CHECKBOX_PTI_PARAMS[i], this.fields[i].value);
      }
      else if (i === AUTO_DETEC_INDEX)
        this.updateAutoDetec();
      resetAllError(this.fields);
    },

    updateAutoDetec() {
      this.$store.commit("emeritValues/updateAlertAutoDetecState", this.fields[AUTO_DETEC_INDEX].value);
      if (!this.fields[AUTO_DETEC_INDEX].value) {
        setNumberPtiValue(PARAM_PTI_TILT, false);
        setNumberPtiValue(PARAM_PTI_IMMOBILITY, false);
      }
    },

    check() {
      const errIndex = this.isEwg100 ? WRIST_INDEX : AUTO_DETEC_INDEX;
      let nbTriggerMode = 0;

      for (let i = 0; i < this.fields.length; ++i)
        nbTriggerMode += Number(this.fields[i].value);

      if (!nbTriggerMode)
        return setError(this.fields, errIndex, ERR_SELECT_AT_LEAST_ONE_TRIGGERING_MODE);

      if (this.fields[AUTO_DETEC_INDEX].value
        && !getBoolPtiValue(PARAM_PTI_TILT)
        && !getBoolPtiValue(PARAM_PTI_IMMOBILITY))
        return setError(this.fields, AUTO_DETEC_INDEX, ERR_SELECT_AT_LEAST_ONE_AUTO_DETECTION);

      return true;
    },
  }
})

</script>