/* eslint-disable */

<template>
  <div>
    <div class="intro-y flex items-center mt-6">
      <h2 class="text-lg font-medium mr-auto">
        {{ pageTitle }}
      </h2>
    </div>

    <!-- BEGIN: ip-server -->
    <div class="grid grid-cols-12 gap-6 ">
      <!-- BEGIN: left column -->
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: serveur principal -->
        <div class="clearfix box mt-6">
          <!-- entete de parametre  -->
          <div
            class="flex flex-col sm:flex-row pt-3 p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div class="form-check w-full">
              <input
                id="show-example-1"
                v-model="this.recipientIpchecked"
                name="show-immobility-settings"
                data-target="#input"
                class="show-code form-check-switch bg-white"
                type="checkbox"
                value="1"
              />
              <div>
                <h2
                  v-if="!this.recipientIpchecked"
                  class="font-medium text-base pl-3"
                >
                  {{ $t("ipserver.menu_enable_main_server") }}
                </h2>
              </div>
              <div>
                <h2
                  v-if="this.recipientIpchecked"
                  class="font-medium text-base pl-3"
                >
                  {{ $t("ipserver.menu_main_server") }}
                </h2>
              </div>
            </div>
          </div>

          <!-- BEGUIN: bloc parametres masquable -->
          <div v-if="this.recipientIpchecked">
            <div>
              <!-- BEGIN: parametre texte -->
              <div class="clearfix flex-row p-3 border-b border-gray-200">
                <label class="font-bold">
                  {{ $t("ipserver.menu_client_id") }}
                </label>
                <div class="input-group pb-2">
                  <input
                    v-model="this.recipient_ip[SERVER_NAME_COLUMN]"
                    type="text"
                    maxlength="50"
                    class="form-control w-full text-left"
                    aria-describedby="input-group-1"
                  />
                </div>
                <p v-if="!this.tab_error_empty[0]">
                  {{ $t("common.menu_field_cannot_be_empty") }}
                </p>
                <label class="font-bold">
                  {{ $t("ipserver.menu_ip_url") }}
                </label>
                <div class="input-group pb-2">
                  <input
                    v-model="this.recipient_ip[SERVER_URL_COLUMN]"
                    type="text"
                    maxlength="100"
                    class="form-control w-full text-left"
                    aria-describedby="input-group-1"
                  />
                </div>
                <p v-if="!this.tab_error_empty[1]">
                  {{ $t("common.menu_field_cannot_be_empty") }}
                </p>
                <label class="font-bold">
                  {{ $t("ipserver.menu_port") }}
                </label>
                <div class="input-group pb-2">
                  <input
                    v-model="this.recipient_ip[SERVER_PORT_COLUMN]"
                    type="text"
                    maxlength="5"
                    class="form-control w-20 text-left"
                    aria-describedby="input-group-1"
                  />
                </div>
                <ErrorMsg
                  :error-msg="checkPortFields[0].errorMsg"
                />
              </div>
              <!-- END: parametre texte -->
            </div>
            <!-- END: bloc parametres -->
          </div>
          <!-- BEGUIN: bloc parametres masquable -->
        </div>
        <!-- END: serveur principal -->
        <!-- BEGIN: evenements a transmettre -->
        <div v-if="this.recipientIpchecked">
          <div class="clearfix box mt-6">
            <!-- entete de parametre avec switch box -->
            <div
              class="flex flex-col sm:flex-row p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
            >
              <div class="form-check w-full">
                <div>
                  <h2 class="font-medium text-base">
                    {{ $t("ipserver.menu_events_to_be_transmitted") }}
                  </h2>
                </div>
              </div>
            </div>
            <!-- BEGIN: bloc parametres -->
            <p v-if="!this.tab_error_empty[3]">
              {{ $t("common.menu_select_at_least_one_event") }}
            </p>
            <div>
              <!-- BEGIN: parametre avec multi check box -->
              <div
                class="clearfix flex-col pl-3 pb-3 border-b mt-3 border-gray-200"
              >
                <div class="flex flex-col sm:flex-col mt-3">
                  <div class="form-check mr-3 sm:mt-0">
                    <input
                      id="checkbox-switch-1"
                      v-model="this.recipient_ip[ALARM_COLUMN]"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label class="form-check-label" for="checkbox-switch-1">
                      {{ $t("internalrecipients.menu_event_alerts") }}
                    </label>
                  </div>
                  <div class="form-check mt-2 mr-3 ">
                    <input
                      id="checkbox-switch-2"
                      v-model="this.recipient_ip[SYSTEM_STATE_COLUMN]"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label class="form-check-label" for="checkbox-switch-2">
                      {{ $t("internalrecipients.menu_event_system_status") }}
                    </label>
                  </div>
                  <div class="form-check mt-2 mr-3  ">
                    <input
                      id="checkbox-switch-3"
                      v-model="this.recipient_ip[CHARGING_COLUMN]"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label class="form-check-label" for="checkbox-switch-3">
                      {{ $t("internalrecipients.menu_event_charge_status") }}
                    </label>
                  </div>
                  <div class="form-check mt-2 mr-3">
                    <input
                      id="checkbox-switch-4"
                      v-model="this.recipient_ip[LOW_BATTERY_COLUMN]"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label class="form-check-label" for="checkbox-switch-4">
                      {{ $t("internalrecipients.menu_event_low_battery") }}
                    </label>
                  </div>
                  <div 
                    v-if="isBbe9Registered"
                    class="form-check mt-2 mr-3"
                  >
                    <input
                      id="checkbox-switch-5"
                      v-model="this.recipient_ip[EV_BEACON_LOW_BATTERY]"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label class="form-check-label" for="checkbox-switch-5">
                      {{ $t("internalrecipients.menu_event_beacon_ble_low_battery") }}
                    </label>
                  </div>
                  <div v-if="isNfcModel" class="form-check mt-2 mr-3">
                    <input
                      id="checkbox-switch-6"
                      v-model="this.recipient_ip[NFC_COLUMN]"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label class="form-check-label" for="checkbox-switch-6">
                      {{ $t("internalrecipients.menu_nfc_reading") }}
                    </label>
                  </div>
                </div>
              </div>
              <!-- END: parametre avec multi check box -->
            </div>
            <!-- END: bloc parametres -->
          </div>
        </div>
        <!-- BEGIN: masquable test cyclique -->
        <div v-if="this.recipientIpchecked">
          <!-- BEGIN: link test -->
          <div class="clearfix box mt-6">
            <!-- entete de parametre avec switch box -->
            <div
              class="flex flex-col sm:flex-row pt-3 p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
            >
              <div class="form-check w-full">
                <input
                  id="show-link-test"
                  v-model="this.recipient_ip[LINK_TEST_COLUMN]"
                  name="show-immobility-settings"
                  data-target="#input"
                  class="show-code form-check-switch bg-white"
                  type="checkbox"
                  value="1"
                />
                <div>
                  <h2
                    v-if="!this.recipient_ip[LINK_TEST_COLUMN]"
                    class="font-medium text-base pl-3"
                  >
                    {{ $t("ipserver.menu_enable_link_test") }}
                  </h2>
                </div>
                <div>
                  <h2
                    v-if="this.recipient_ip[LINK_TEST_COLUMN]"
                    class="font-medium text-base pl-3"
                  >
                    {{ $t("ipserver.menu_link_test") }}
                  </h2>
                </div>
              </div>
            </div>
              <!-- BEGUIN: bloc parametres masquable -->
              <div v-if="this.recipient_ip[LINK_TEST_COLUMN]">
                <!-- BEGUIN: parametre range slider -->
                <div class="clearfix flex-row p-3 border-b border-gray-200">
                  <label class="font-bold">
                    {{ $t("ipserver.menu_test_interval") }}
                  </label>
                  <div class="input-group pb-1 mt-2">
                    <input
                      v-model="linkTestRange"
                      type="range"
                      min="10"
                      max="1440"
                      step="10"
                    />
                    <div class="form-control w-20 text-center ml-5">
                      {{ linkTestRange }}
                    </div>
                    <div id="input-group-3" class="input-group-text h-10 font-sm">
                      {{ $t("common.menu_minutes") }}
                    </div>
                  </div>
                </div>
                <!-- END: parametre range slider -->
              </div>
              <!-- END: bloc parametres masquable -->
          </div>
          <!-- END: link test -->

          <!-- BEGIN: test cyclique -->
          <div class="clearfix box mt-6">
              <!-- entete de parametre avec switch box -->
              <div
                class="flex flex-col sm:flex-row pt-3 p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
              >
                <div class="form-check w-full">
                  <input
                    id="show-example-1"
                    v-model="this.recipient_ip[CYCLIC_TEST_COLUMN]"
                    name="show-immobility-settings"
                    data-target="#input"
                    class="show-code form-check-switch bg-white"
                    type="checkbox"
                    value="1"
                  />
                  <div>
                    <h2
                      v-if="!this.recipient_ip[CYCLIC_TEST_COLUMN]"
                      class="font-medium text-base pl-3"
                    >
                      {{ $t("ipserver.menu_enable_cyclic_test") }}
                    </h2>
                  </div>
                  <div>
                    <h2
                      v-if="this.recipient_ip[CYCLIC_TEST_COLUMN]"
                      class="font-medium text-base pl-3"
                    >
                      {{ $t("ipserver.menu_cyclic_test") }}
                    </h2>
                  </div>
                </div>
              </div>
              <!-- BEGUIN: bloc parametres masquable -->
              <div v-if="this.recipient_ip[CYCLIC_TEST_COLUMN]">
                <!-- BEGUIN: parametre range slider -->
                
                <div class="clearfix flex-row p-3 border-b border-gray-200">
                  <p v-if="!this.tab_error_empty[9]" class="pb-3">
                      {{ $t("common.menu_field_cannot_be_empty_need_one") }}
                    </p>
                  <input
                    id="checkbox-switch-7"
                    v-model="isPermanentTracking"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                  />
                  <label class="form-check-label" for="checkbox-switch-7">
                      {{ $t("ipserver.menu_event_permanents") }}
                    </label>
                    <div 
                      class="pt-3"
                      v-if="isPermanentTracking"
                    >
                      <label class="font-bold">
                        {{ $t("ipserver.menu_test_interval") }}
                      </label>
                      <div class="input-group pb-1 mt-2">
                        <input
                          v-model="range"
                          type="range"
                          min="1"
                          max="1440"
                          step="1"
                        />
                        <div class="form-control w-20 text-center ml-5">
                          {{ range }}
                        </div>
                        <div id="input-group-4" class="input-group-text h-10 font-sm">
                          {{ $t("common.menu_minutes") }}
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <div class="clearfix flex-row p-3 border-b border-gray-200">

                    <input
                      id="checkbox-switch-8"
                      v-model="isAlertTracking"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label class="form-check-label" for="checkbox-switch-8">
                      {{ $t("ipserver.menu_event_trigger_alert") }}
                    </label>
                 
                    <div
                      class="pt-3"
                      v-if="isAlertTracking"
                    >
                      <label class="font-bold">
                        {{ $t("ipserver.menu_test_interval") }}
                      </label>
                      <div class="input-group pb-1 mt-2">
                        <input
                          v-model="alertTrakingRange"
                          type="range"
                          min="1"
                          max="15"
                          step="1"
                        />
                        <div class="form-control w-20 text-center ml-5">
                          {{ alertTrakingRange }}
                        </div>
                        <div id="input-group-5" class="input-group-text h-10 font-sm">
                          {{ $t("common.menu_minutes") }}
                        </div>
                      </div>
                      <div class="form-check pb-3 pt-3">
                        <input
                          id="checkbox-switch-9"
                          v-model="isAlertTrackingEndPostAlert"
                          class="form-check-input"
                          type="radio"
                          value="1"
                        />
                        <label class="form-check-label" for="checkbox-switch-9">
                        {{ $t("ipserver.menu_event_end_tracking_stop_post_alert") }}
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          id="checkbox-switch-10"
                          v-model="isAlertTrackingEndPostAlert"
                          class="form-check-input"
                          type="radio"
                          value="2"
                        />
                        <label class="form-check-label" for="checkbox-switch-10">
                        {{ $t("ipserver.menu_event_end_tracking_after_x_message") }}
                        </label>
                      </div>
                        <div class="input-group pb-1 mt-2">
                          <CustomTextInputWithUnitDescription
                            v-if="isAlertTrackingEndPostAlert == 2"
                            v-model="numberOfMessagesToFinishAlertTracking" 
                            unit-description="ipserver.menu_unit_description_message"
                          />
                        </div>
                          <p v-if="!this.tab_error_message_min_value[0]">
                            {{ $t("common.menu_min_message") }}
                          </p>
                    </div>
                  </div>
                <!-- END: parametre range slider -->
              </div>
              <!-- END: bloc parametres masquable -->
          </div>
          <!-- END: test cyclique -->
        </div>
        
        <!-- BEGIN: bloc masquable backup serveur -->
        <div v-if="this.recipientIpchecked">
          <!-- BEGIN: backup serveur -->
          <div class="clearfix box mt-6">
            <!-- entete de parametre avec switch box -->
            <div
              class="flex flex-col sm:flex-row pt-3 p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
            >
              <div class="form-check w-full">
                <input
                  id="show-example-1"
                  v-model="this.recipient_ip[BACKUP_IP_COLUMN]"
                  name="show-immobility-settings"
                  data-target="#input"
                  class="show-code form-check-switch bg-white"
                  type="checkbox"
                  value="1"
                />
                <div>
                  <h2
                    v-if="!this.recipient_ip[BACKUP_IP_COLUMN]"
                    class="font-medium text-base pl-3"
                  >
                    {{ $t("ipserver.menu_enable_backup_server") }}
                  </h2>
                </div>
                <div>
                  <h2
                    v-if="this.recipient_ip[BACKUP_IP_COLUMN]"
                    class="font-medium text-base pl-3"
                  >
                    {{ $t("ipserver.menu_backup_server") }}
                  </h2>
                </div>
              </div>
            </div>
            <!-- BEGIN: bloc parametres -->
            <div v-if="this.recipient_ip[BACKUP_IP_COLUMN]">
              <!-- BEGIN: parametre texte -->
              <div class="clearfix flex-row p-3 border-b border-gray-200">
                <label class="font-bold">
                  {{ $t("ipserver.menu_client_id") }}
                </label>
                <div class="input-group pb-2">
                  <input
                    v-model="this.recipient_ip[BACKUP_IP_SERVER_NAME_COLUMN]"
                    type="text"
                    maxlength="50"
                    class="form-control w-full text-left"
                    aria-describedby="input-group-1"
                  />
                </div>
                <p v-if="!this.tab_error_empty[4]">
                  {{ $t("common.menu_field_cannot_be_empty") }}
                </p>
                <label class="font-bold">
                  {{ $t("ipserver.menu_ip_url") }}
                </label>
                <div class="input-group pb-2">
                  <input
                    v-model="this.recipient_ip[BACKUP_IP_SERVER_URL_COLUMN]"
                    type="text"
                    maxlength="100"
                    class="form-control w-full text-left"
                    aria-describedby="input-group-1"
                  />
                </div>
                <p v-if="!this.tab_error_empty[5]">
                  {{ $t("common.menu_field_cannot_be_empty") }}
                </p>
                <label class="font-bold">
                  {{ $t("ipserver.menu_port") }}
                </label>
                <div class="input-group pb-2">
                  <input
                    v-model="this.recipient_ip[BACKUP_IP_SERVER_PORT_COLUMN]"
                    type="text"
                    maxlength="5"
                    class="form-control w-20 text-left"
                    aria-describedby="input-group-1"
                  />
                </div>
                <ErrorMsg
                  :error-msg="checkPortFields[1].errorMsg"
                />
              </div>
              <!-- END: parametre texte -->
            </div>
            <!-- END: bloc parametres -->
          </div>
          <!-- END: backup serveur -->
        </div>
        <!-- END: backup serveur masquable-->
        <!-- BEGIN: bloc masquable backup sms -->
        <div v-if="this.recipientIpchecked && !isSipEnabled">
          <!-- BEGIN: backup sms -->
          <div class="clearfix box mt-6">
            <!-- entete de parametre avec switch box -->
            <div
              class="flex flex-raw sm:flex-row p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
            >
              <div class="form-check w-full">
                <input
                  id="show-example-1"
                  v-model="this.recipient_ip[BACKUP_SMS_COLUMN]"
                  name="show-immobility-settings"
                  data-target="#input"
                  class="show-code form-check-switch bg-white"
                  type="checkbox"
                />
                <div>
                  <h2
                    v-if="!this.recipient_ip[BACKUP_SMS_COLUMN]"
                    class="font-medium text-base pl-3"
                  >
                    {{ $t("ipserver.menu_enable_backup_sms") }}
                  </h2>
                </div>
                <div>
                  <h2
                    v-if="this.recipient_ip[BACKUP_SMS_COLUMN]"
                    class="font-medium text-base pl-3"
                  >
                    {{ $t("ipserver.menu_backup_sms") }}
                  </h2>
                </div>
              </div>
            </div>
            <!-- BEGIN: bloc parametres masquable -->
            <div v-if="this.recipient_ip[BACKUP_SMS_COLUMN]">
              <!-- BEGIN: parametre texte -->
              <div class="clearfix flex-row p-3 border-b border-gray-200">
                <label class="font-bold">
                  {{ $t("internalrecipients.menu_name") }}
                </label>
                <div class="input-group mt-2 pb-1">
                  <input
                    v-model="this.recipient_ip[BACKUP_SMS_NAME_COLUMN]"
                    type="text"
                    maxlength="50"
                    class="form-control w-full text-left"
                    aria-describedby="input-group-1"
                  />
                </div>
                <p v-if="!this.tab_error_empty[7]">
                  {{ $t("common.menu_field_cannot_be_empty") }}
                </p>
              </div>
              <!-- END: parametre texte -->
              <!-- BEGIN: parametre texte -->
              <div class="clearfix flex-row p-3 border-b border-gray-200">
                <label class="font-bold">
                  {{
                    $t(
                      "internalrecipients.menu_phone_number_international_format"
                    )
                  }}
                </label>
                <div class="input-group mt-2 pb-1">
                  <input
                    v-model="this.recipient_ip[BACKUP_SMS_NUMBER_COLUMN]"
                    type="text"
                    maxlength="50"
                    class="form-control w-full text-left"
                    aria-describedby="input-group-1"
                  />
                </div>
                <p v-if="!this.tab_error_empty[8]">
                  {{ $t("common.menu_field_cannot_be_empty") }}
                </p>
                <p v-if="!this.error_number_phone_international">
                  {{ $t("common.menu_phone_number_must_start_with_+") }}
                </p>
              </div>
              <!-- END: parametre texte -->
            </div>
            <!-- END: bloc parametres masquable -->
          </div>
          <!-- END: backup sms -->
        </div>
        <!-- END: backup sms masquable-->
      </div>
      <!-- END: left column -->
    </div>
    <!-- END: ip-server -->
    <button
      type="submit"
      class="btn btn-primary mt-6"
      @click="onSaveButtonClicked"
    >
      {{ $t("common.menu_save") }}
    </button>
  </div>
</template>

<script>
import {
  addRecipient,
  getCountRecipientTlsIp,
  getRecipienTLSIp,
  updateTlsIpRecipient,
  deleteTlsIpRecipient,
  deleteTlsIpRecipient1,
  getRecipientTlsIpCyclicTest,
  getRecipientTlsIpLinkTest
} from "../../dao/tls-ip-dao";
import {
  addRecipientSup,
  getAllRecipientSup,
  getRecipienSupIp,
  updateSupIpRecipient,
  getCountRecipientSupIp,
  deleteSupIpRecipient,
  getRecipientSupIpCyclicTest,
  getRecipientSupIpLinkTest
} from "../../dao/sup-ip-dao";
import {
  setParameterPTIValue,
  getParameterPTIValue,
  getBoolPtiValue
} from "../../dao/parametre-pti";
import { useStore } from "@/store";
import { 
  regexCheck, 
  resetError, 
  setError 
} from '../../utils/fieldManagement';
import {
  isBbe9BeaconRegisteredInDb
} from '../emerit-location-indoor-ble/local-components/utils'
import { get } from "http";


const I18_PORT_MUST_BE_BETWEEN_0_AND_65536 = 'ipserver.menu_port_must_be_between_0_and_65536';
const PARAM_PTI_BLE_LOC_ENABLED = "isBluetoothLocParamEnabled";


export default {
  props: ["type"],

  data() {
    return {
      SERVER_NAME_COLUMN: 1,
      SERVER_URL_COLUMN: 2,
      SERVER_PORT_COLUMN: 3,
      BACKUP_IP_SERVER_NAME_COLUMN: 4,
      BACKUP_IP_SERVER_URL_COLUMN: 5,
      BACKUP_IP_SERVER_PORT_COLUMN: 6,
      BACKUP_SMS_NAME_COLUMN: 7,
      BACKUP_SMS_NUMBER_COLUMN: 8,
      ALARM_COLUMN: 9,
      SYSTEM_STATE_COLUMN: 10,
      LOW_BATTERY_COLUMN: 11,
      CHARGING_COLUMN: 12,
      BACKUP_IP_COLUMN: 13,
      BACKUP_SMS_COLUMN: 14,
      CYCLIC_TEST_COLUMN: 15,
      START_SERVICE_COLUMN: 16,
      WHITE_ZONE_COLUMN: 17,
      POST_ALARM_COLUMN: 18,
      PTI_COLUMN_COLUMN: 19,
      EXTERNAL_ZONE_COLUMN: 20,
      PROTOCOL_IDENTIFIER_COLUMN: 21,
      EV_BEACON_LOW_BATTERY: 37,
      NFC_COLUMN: null,
      LINK_TEST_COLUMN: null,

      isPermanentTracking: true,
      isAlertTracking: false,
      isAlertTrackingEndPostAlert: 1,
      alertTrakingRange: 5,
      numberOfMessagesToFinishAlertTracking: 0,

      linkTestRange: 60,
      tab_error_empty: new Array(15).fill(true),
      tab_error_port_ip: new Array(2).fill(true),
      tab_error_port_ip_min_value: new Array(2).fill(true),
      tab_error_message_min_value: new Array(2).fill(true),
      error_number_phone_international: true,

      pageTitle: "values",
      recipientIpchecked: false,
      smseventschecked: 0,
      radiobuttonsmsandcallcyclechecked: 0,
      radiobuttonsmsthencallcyclechecked: 0,
      radiobuttonsmsackchecked: 0,
      smsackchecked: 0,
      smsdatachecked: 0,
      alertlabelchecked: 0,
      range: 30,
      dc09formatchecked: 3,
      cyclictestchecked: 0,
      radioButtonAlertScenarioType: -1,
      backupserverchecked: 0,
      backupsmschecked: 0,
      isNfcModel:false,
      recipient_ip: [
        0,
        "",
        "",
        0,
        "",
        "",
        0,
        "",
        "",
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        "ADM_CID",
        0,
        "ADM_CID",
        "STANDARD",
        "DM",
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        "",
        0,
        0,
        0,
        0 // low battery beacon event
      ],

      isBbe9Registered: false,
      isSipEnabled: false,
      isSipAvailable: false,
      checkPortFields: [
        { errorMsg: '' }, 
        { errorMsg: '' }, 
      ]
    };
  },

  created() {
    if (getBoolPtiValue(PARAM_PTI_BLE_LOC_ENABLED))
      this.isBbe9Registered = isBbe9BeaconRegisteredInDb();
  },

  mounted() {
    this.updateUI();
  },

  methods: {
    updateUI() {
      // deleteTlsIpRecipient();
      // deleteTlsIpRecipient1();
      //addRecipient(this.recipient_ip);
      //console.log("before testcyclique="+this.recipient_ip[this.CYCLIC_TEST_COLUMN]);
      
      // is NFC model?
      this.isNfcModel = (useStore().state.emeritValues.isNfcModel);

      switch (this.type) {
        case "supervision":
          // la colonne NFC n'est pas à la même position dans DB sup et tls
          this.NFC_COLUMN = 20;
          this.EV_BEACON_LOW_BATTERY = 36;
          this.LINK_TEST_COLUMN = 37;
          this.recipient_ip = [
            0,
            "",
            "",
            0,
            "",
            "",
            0,
            "",
            "",
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            "ADM_CID",
            0,
            "ADM_CID",
            "STANDARD",
            "DM",
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            "",
            0,
            0,
            0,
            0,
            0, // low-batteery_baecon_event
            0
          ];

          this.pageTitle = this.$t(
            "configuration.menu_alarm_monitoring_server"
          );
          if (getCountRecipientSupIp() > 0) {
            this.recipientIpchecked = true;
            // console.log("sup >0");
            this.initValuesWithDbSup();
            this.convertIpEventBooleanToInt();
            this.getTrackingRangeFromParametrePti();
            this.getLinkRangeFromParametrePti();
            this.getNumberRangeFromParametrePti();
            this.getAlertTrackingRangeFromParametrePti();
          } else {
            // console.log("sup == 0");
          }
          break;

        case "tls":
          // la colonne NFC n'est pas à la même position dans DB sup et tls
          this.NFC_COLUMN = 22;
          this.LINK_TEST_COLUMN = 38;
          this.pageTitle = this.$t(
            "ipserver.menu_alarm_monitoring_center_server"
          );
          if (getCountRecipientTlsIp() > 0) {
            this.recipientIpchecked = true;
            this.initValuesWithDbTls();
            //console.log("update conversion boolean");
            this.convertIpEventBooleanToInt();
            this.getTrackingRangeFromParametrePti();
            this.getLinkRangeFromParametrePti();
            this.getAlertTrackingRangeFromParametrePti();
            this.getNumberRangeFromParametrePti();
            //console.log("after testcyclique="+this.recipient_ip[this.CYCLIC_TEST_COLUMN]);
          }
          /* if(getCont>0){
                              initWithDbTLS();
                        }else{
                              initFromVar();
                        }*/

          break;
      }

      this.hideSmsBackupIfSipIsActivated();
    },

    hideSmsBackupIfSipIsActivated() {
      this.isSipAvailable = Number(getParameterPTIValue("SipManagerAvailable"));
      this.isSipEnabled = Number(getParameterPTIValue("SipActivationState"));
      if (this.isSipAvailable && this.isSipEnabled)
        this.recipient_ip[this.BACKUP_SMS_COLUMN] = 0;
    },

    onAlertTypeChanged(event) {
      if (event.target.value == 0) {
        this.showInternalRecipients = false;
        this.showTlsCalls = false;
        this.showLocalSettings = false;
        this.showTlsIpSettings = false;
      } else {
        this.showInternalRecipients = true;
        this.showTlsCalls = true;
        this.showLocalSettings = true;
        this.showTlsIpSettings = true;
      }
    },

    onSaveButtonClicked() {
      if (this.recipientIpchecked) {
        //console.log("recipent ip checheked");
        if (!this.controlPage()) {
          //console.log("control is not ok");
          return;
        }
        this.updateLinkTest();
        this.updateTracking();
        this.updateAlertTracking();
        this.updateNumberRange();
        this.updateBackupsIpAndSMS();
        if (this.type == "supervision") {
          if (getCountRecipientSupIp() > 0) {
            //console.log("update recipient sup");
            this.NFC_COLUMN = "20"
            this.LINK_TEST_COLUMN= "37"
            this.convertIpEventIntToBoolean();
            updateSupIpRecipient(this.recipient_ip);
          } else {
            //console.log("create recipient sup");
            this.NFC_COLUMN = "20"       
            this.LINK_TEST_COLUMN= "37"
            this.convertIpEventIntToBoolean();
            addRecipientSup(this.recipient_ip);
          }
        } else {
          if (getCountRecipientTlsIp() > 0) {
            //console.log("update recipient tls");
            this.NFC_COLUMN = "22"
            this.LINK_TEST_COLUMN= "38"
            this.convertIpEventIntToBoolean();
            updateTlsIpRecipient(this.recipient_ip);
          } else {
            //console.log("create recipient tls");
            this.NFC_COLUMN = "22"
            this.LINK_TEST_COLUMN= "38"
            this.convertIpEventIntToBoolean();
            addRecipient(this.recipient_ip);
          }
        }
      } else {
        //console.log("recipent ip  NOOOT checheked");
        if (this.type == "supervision") {
          if (getCountRecipientTlsIp() > 0) {
            if (getRecipientTlsIpCyclicTest() == 0) {
              setParameterPTIValue("SecuritePositiveControle", 0);
              //console.log(" SUP cyclit test control SET TO 0 ");
            }
            if (getRecipientTlsIpLinkTest() == 0) {
              setParameterPTIValue("isLinkTestEnabled", 0);
              //console.log(" SUP cyclit test control SET TO 0 ");
            }
          }

          if (getCountRecipientSupIp() > 0) {
            //console.log("recipent ip  DELETE SUP IP");
            setParameterPTIValue("isLinkTestEnabled", 0);
            setParameterPTIValue("SecuritePositiveControle", 0);
            //console.log(" SUP cyclit test control SET TO 0 ");
            deleteSupIpRecipient();
          }
        } else {
          if (getCountRecipientSupIp() > 0) {
            if (getRecipientSupIpCyclicTest() == 0) {
              setParameterPTIValue("SecuritePositiveControle", 0);
              //console.log(" TLS cyclit test control SET TO 0 ");
            }
            if (getRecipientSupIpLinkTest() == 0) {
              setParameterPTIValue("isLinkTestEnabled", 0);
              //console.log(" TLS cyclit test control SET TO 0 ");
            }
          }
          if (getCountRecipientTlsIp() > 0) {
            setParameterPTIValue("isLinkTestEnabled", 0);
            setParameterPTIValue("SecuritePositiveControle", 0);
            //console.log(" TLS cyclit test control SET TO 0 ");
            deleteTlsIpRecipient();
          }
        }
      }
      this.$router.push("/");
    },

    initValuesWithDbTls() {
      //this.convertIpEventBooleanToInt();
      this.recipient_ip = getRecipienTLSIp();
    },
    initValuesWithDbSup() {
      //this.convertIpEventBooleanToInt();
      this.recipient_ip = getRecipienSupIp();
    },

    updateBackupsIpAndSMS() {
      if (!this.recipient_ip[this.BACKUP_IP_COLUMN]) {
        this.recipient_ip[this.BACKUP_IP_SERVER_NAME_COLUMN] = "";
        this.recipient_ip[this.BACKUP_IP_SERVER_URL_COLUMN] = "";
        this.recipient_ip[this.BACKUP_IP_SERVER_PORT_COLUMN] = 0;
      }

      if (!this.recipient_ip[this.BACKUP_SMS_COLUMN]) {
        this.recipient_ip[this.BACKUP_SMS_NAME_COLUMN] = "";
        this.recipient_ip[this.BACKUP_SMS_NUMBER_COLUMN] = "";
      }
    },
    
    checkPort(portNb, index) {
      if (!regexCheck(String(portNb), /^-?[0-9]{1,5}/))
        return setError(this.checkPortFields, index, I18_PORT_MUST_BE_BETWEEN_0_AND_65536);
      let intPortNb = parseInt(portNb);
      if (intPortNb < 0 || intPortNb > 65536)
        return setError(this.checkPortFields, index, I18_PORT_MUST_BE_BETWEEN_0_AND_65536);
      return resetError(this.checkPortFields, index);
    },

    controlPage() {
      if (this.recipient_ip[this.CYCLIC_TEST_COLUMN] === true && this.isAlertTrackingEndPostAlert == 0){
        if (this.numberOfMessagesToFinishAlertTracking < 1){
          this.tab_error_message_min_value[0] = false;
          return false;
        } else {
            this.tab_error_message_min_value[0] = true;
        }
      }
      if (this.recipient_ip[this.SERVER_NAME_COLUMN] == "") {
        this.tab_error_empty[0] = false;
        return false;
      } else {
        this.tab_error_empty[0] = true;
      }
      if (this.recipient_ip[this.SERVER_URL_COLUMN] == "") {
        this.tab_error_empty[1] = false;
        return false;
      } else {
        this.tab_error_empty[1] = true;
      }
      if (!this.checkPort(this.recipient_ip[this.SERVER_PORT_COLUMN], 0))
        return false;
      if ( (!this.isNfcModel &&
        !this.recipient_ip[this.ALARM_COLUMN] &&
        !this.recipient_ip[this.CHARGING_COLUMN] &&
        !this.recipient_ip[this.LOW_BATTERY_COLUMN] &&
        !this.recipient_ip[this.EV_BEACON_LOW_BATTERY] &&
        !this.recipient_ip[this.SYSTEM_STATE_COLUMN]) || 
        (this.isNfcModel &&
        !this.recipient_ip[this.ALARM_COLUMN] &&
        !this.recipient_ip[this.CHARGING_COLUMN] &&
        !this.recipient_ip[this.LOW_BATTERY_COLUMN] &&
        !this.recipient_ip[this.SYSTEM_STATE_COLUMN] &&
        !this.recipient_ip[this.EV_BEACON_LOW_BATTERY] &&
        !this.recipient_ip[this.NFC_COLUMN])
        ) {
            this.tab_error_empty[3] = false;
            //console.log("event error");
            return false;
          } else {
            //console.log("event ok ");
            this.tab_error_empty[3] = true;
          }
      if (this.recipient_ip[this.BACKUP_IP_COLUMN]) {
        if (this.recipient_ip[this.BACKUP_IP_SERVER_NAME_COLUMN] == "") {
          this.tab_error_empty[4] = false;
          return false;
        } else {
          this.tab_error_empty[4] = true;
        }
        if (this.recipient_ip[this.BACKUP_IP_SERVER_URL_COLUMN] == "") {
          this.tab_error_empty[5] = false;
          return false;
        } else {
          this.tab_error_empty[5] = true;
        }
        if (!this.checkPort(this.recipient_ip[this.BACKUP_IP_SERVER_PORT_COLUMN], 1))
          return false;
      }
      if (this.recipient_ip[this.BACKUP_SMS_COLUMN]) {
        if (this.recipient_ip[this.BACKUP_SMS_NAME_COLUMN] == "") {
          this.tab_error_empty[7] = false;
          return false;
        } else {
          this.tab_error_empty[7] = true;
        }
        if (this.recipient_ip[this.BACKUP_SMS_NUMBER_COLUMN] == "") {
          this.tab_error_empty[8] = false;
          return false;
        } else {
          this.tab_error_empty[8] = true;
        }
        if (!this.recipient_ip[this.BACKUP_SMS_NUMBER_COLUMN].startsWith("+")) {
          this.error_number_phone_international = false;
          return false;
        } else {
          this.error_number_phone_international = true;
        }
      }
      if(this.recipient_ip[this.CYCLIC_TEST_COLUMN] == true){
        if(this.isPermanentTracking == false && this.isAlertTracking == false){
          this.tab_error_empty[9] = false;
          return false;
      } else if(this.isPermanentTracking == true && this.isAlertTracking == true) {
         this.tab_error_empty[9] = true;
        }
      }
      return true;
    },

    convertIpEventIntToBoolean() {
      //console.log("NFC_COLUMN value update DB ="+this.recipient_ip[this.NFC_COLUMN]);
      if (this.recipient_ip[this.ALARM_COLUMN] == true) {
        this.recipient_ip[this.ALARM_COLUMN] = 1;
      } else {
        this.recipient_ip[this.ALARM_COLUMN] = 0;
      }

      if (this.recipient_ip[this.CHARGING_COLUMN] == true) {
        this.recipient_ip[this.CHARGING_COLUMN] = 1;
      } else {
        this.recipient_ip[this.CHARGING_COLUMN] = 0;
      }

      if (this.recipient_ip[this.LOW_BATTERY_COLUMN] == true) {
        this.recipient_ip[this.LOW_BATTERY_COLUMN] = 1;
      } else {
        this.recipient_ip[this.LOW_BATTERY_COLUMN] = 0;
      }

      if (this.recipient_ip[this.EV_BEACON_LOW_BATTERY] == true) {
        this.recipient_ip[this.EV_BEACON_LOW_BATTERY] = 1;
      } else {
        this.recipient_ip[this.EV_BEACON_LOW_BATTERY] = 0;
      }

      if (this.recipient_ip[this.SYSTEM_STATE_COLUMN] == true) {
        this.recipient_ip[this.SYSTEM_STATE_COLUMN] = 1;
      } else {
        this.recipient_ip[this.SYSTEM_STATE_COLUMN] = 0;
      }

      if (this.recipient_ip[this.CYCLIC_TEST_COLUMN] == true) {
        this.recipient_ip[this.CYCLIC_TEST_COLUMN] = 1;
      } else {
        this.recipient_ip[this.CYCLIC_TEST_COLUMN] = 0;
      }

      if (this.recipient_ip[this.BACKUP_IP_COLUMN] == true) {
        this.recipient_ip[this.BACKUP_IP_COLUMN] = 1;
      } else {
        this.recipient_ip[this.BACKUP_IP_COLUMN] = 0;
      }

      if (this.recipient_ip[this.BACKUP_SMS_COLUMN] == true) {
        this.recipient_ip[this.BACKUP_SMS_COLUMN] = 1;
      } else {
        this.recipient_ip[this.BACKUP_SMS_COLUMN] = 0;
      }

      if (this.recipient_ip[this.NFC_COLUMN] == true) {
        this.recipient_ip[this.NFC_COLUMN] = 1;
      } else {
        this.recipient_ip[this.NFC_COLUMN] = 0;
      }

      if (this.recipient_ip[this.LINK_TEST_COLUMN] == true) {
        this.recipient_ip[this.LINK_TEST_COLUMN] = 1;
      } else {
        this.recipient_ip[this.LINK_TEST_COLUMN] = 0;
      }

      if (this.isPermanentTracking == true) {
        setParameterPTIValue("isPermanentTracking", 1);

      } else {
        setParameterPTIValue("isPermanentTracking", 0);
      }

      if (this.isAlertTracking == true) {
        setParameterPTIValue("isAlertTracking", 1);
      } else {
        setParameterPTIValue("isAlertTracking", 0);
      }

      if (this.isAlertTrackingEndPostAlert == 2) {
        setParameterPTIValue("isAlertTrackingEndPostAlert", 0);
      } else {
        setParameterPTIValue("isAlertTrackingEndPostAlert", 1);
      }

     // console.log("test hggf");
     // setParameterPTIValue("isPermanentTracking", 1);


    },

    convertIpEventBooleanToInt() {
      //console.log("NFC_COLUMN value update UI ="+this.recipient_ip[this.NFC_COLUMN]);
      if (this.recipient_ip[this.ALARM_COLUMN] == 1) {
        this.recipient_ip[this.ALARM_COLUMN] = true;
      } else {
        this.recipient_ip[this.ALARM_COLUMN] = false;
      }

      if (this.recipient_ip[this.CHARGING_COLUMN] == 1) {
        this.recipient_ip[this.CHARGING_COLUMN] = true;
      } else {
        this.recipient_ip[this.CHARGING_COLUMN] = false;
      }

      if (this.recipient_ip[this.LOW_BATTERY_COLUMN] == 1) {
        this.recipient_ip[this.LOW_BATTERY_COLUMN] = true;
      } else {
        this.recipient_ip[this.LOW_BATTERY_COLUMN] = false;
      }

      if (this.recipient_ip[this.EV_BEACON_LOW_BATTERY] == 1) {
        this.recipient_ip[this.EV_BEACON_LOW_BATTERY] = true;
      } else {
        this.recipient_ip[this.EV_BEACON_LOW_BATTERY] = false;
      }

      if (this.recipient_ip[this.SYSTEM_STATE_COLUMN] == 1) {
        this.recipient_ip[this.SYSTEM_STATE_COLUMN] = true;
      } else {
        this.recipient_ip[this.SYSTEM_STATE_COLUMN] = false;
      }

      if (this.recipient_ip[this.CYCLIC_TEST_COLUMN] == 1) {
        this.recipient_ip[this.CYCLIC_TEST_COLUMN] = true;
      } else {
        this.recipient_ip[this.CYCLIC_TEST_COLUMN] = false;
      }

      if (this.recipient_ip[this.BACKUP_IP_COLUMN] == 1) {
        this.recipient_ip[this.BACKUP_IP_COLUMN] = true;
      } else {
        this.recipient_ip[this.BACKUP_IP_COLUMN] = false;
      }

      if (this.recipient_ip[this.BACKUP_SMS_COLUMN] == 1) {
        this.recipient_ip[this.BACKUP_SMS_COLUMN] = true;
      } else {
        this.recipient_ip[this.BACKUP_SMS_COLUMN] = false;
      }

      if (this.recipient_ip[this.NFC_COLUMN] == 1) {
        this.recipient_ip[this.NFC_COLUMN] = true;
      } else {
        this.recipient_ip[this.NFC_COLUMN] = false;
      }
 
      if (this.recipient_ip[this.LINK_TEST_COLUMN] == 1) {
        this.recipient_ip[this.LINK_TEST_COLUMN] = true;
      } else {
        this.recipient_ip[this.LINK_TEST_COLUMN] = false;
      }

      // if (getParameterPTIValue("isPermanentTracking") == 1) {
      //   this.isPermanentTracking = true;
      //   console.log("initialise via la bdd : "+this.isPermanentTracking)
      // } else {
      //   this.isPermanentTracking = false;
      //   console.log("initialise via la bdd : "+this.isPermanentTracking)
      // }

      if (getParameterPTIValue("isPermanentTracking") == 1) {
        this.isPermanentTracking = true;
      } else {
        this.isPermanentTracking = false;
      }

      if (getParameterPTIValue("isAlertTracking") == 1) {
        this.isAlertTracking = true;
      } else {
        this.isAlertTracking = false;
      }

      if (getParameterPTIValue("isAlertTrackingEndPostAlert") == 1) {
        this.isAlertTrackingEndPostAlert = 1;
        this.numberOfMessagesToFinishAlertTracking = getParameterPTIValue("numberOfMessagesToFinishAlertTracking")
      } else {
        this.isAlertTrackingEndPostAlert = 2;
      }
    },

    getTrackingRangeFromParametrePti() {
      if (this.recipient_ip[this.CYCLIC_TEST_COLUMN]) {
        //console.log("passsed méthode"+getParameterPTIValue("SecuritePositiveTempsMinute"));
        //console.log("passsed méthode"+getParameterPTIValue("SecuritePositiveTempsHeure"));
        let hourTempToMin =
          60 * parseInt(getParameterPTIValue("SecuritePositiveTempsHeure"), 10);
        let minutesCyclicTest = 0;
        minutesCyclicTest = parseInt(
          getParameterPTIValue("SecuritePositiveTempsMinute"),
          10
        );
      
        minutesCyclicTest = minutesCyclicTest + hourTempToMin;
        this.range = minutesCyclicTest;
      }
    },
    
    updateTracking() {
      if (!this.recipient_ip[this.CYCLIC_TEST_COLUMN]) {
        if (this.type == "supervision") {
          if (getCountRecipientTlsIp() > 0) {
            if (getRecipientTlsIpCyclicTest() == 0) {
              setParameterPTIValue("SecuritePositiveControle", 0);
            }
          }
        } else {
          if (getCountRecipientSupIp() > 0) {
            if (getRecipientSupIpCyclicTest() == 0) {
              setParameterPTIValue("SecuritePositiveControle", 0);
            }
          } else {
            setParameterPTIValue("SecuritePositiveControle", 0);
          }
        }
      } else {
        setParameterPTIValue("SecuritePositiveControle", 1);
      }
      let hourCyclicTest = Math.floor(this.range / 60);
      let minutesCyclicTest = 0;
      //console.log("passsed méthode rannnge = "+this.range);
      if (this.range > 59) {
        minutesCyclicTest = this.range - 60 * hourCyclicTest;
      } else {
        minutesCyclicTest = this.range;
      }
      setParameterPTIValue("SecuritePositiveTempsHeure", hourCyclicTest);
      setParameterPTIValue("SecuritePositiveTempsMinute", minutesCyclicTest);
    },


    getLinkRangeFromParametrePti() {
      if (this.recipient_ip[this.LINK_TEST_COLUMN]) {
        //console.log("passsed méthode"+getParameterPTIValue("SecuritePositiveTempsMinute"));
        //console.log("passsed méthode"+getParameterPTIValue("SecuritePositiveTempsHeure"));
        let minutesLinkTest = 0;
        minutesLinkTest = getParameterPTIValue("linkTestRange"),
       this.linkTestRange = minutesLinkTest;
      }
    },

    updateLinkTest() {
      if (!this.recipient_ip[this.LINK_TEST_COLUMN]) {
        if (this.type == "supervision") {
          if (getCountRecipientTlsIp() > 0) {
            if (getRecipientTlsIpLinkTest() == 0) {
              setParameterPTIValue("isLinkTestEnabled", 0);
            }
          }
        } else {
          if (getCountRecipientSupIp() > 0) {
            if (getRecipientSupIpLinkTest() == 0) {
              setParameterPTIValue("isLinkTestEnabled", 0);
            }
          } else {
            setParameterPTIValue("isLinkTestEnabled", 0);
          }
        }
      } else {
        setParameterPTIValue("isLinkTestEnabled", 1);
      }
      let minutesLinkTest = 0;
      //console.log("passsed méthode rannnge = "+this.range);
        minutesLinkTest = this.linkTestRange;
   
      setParameterPTIValue("linkTestRange", minutesLinkTest);
    },

    getAlertTrackingRangeFromParametrePti() {
      if (this.recipient_ip[this.CYCLIC_TEST_COLUMN]) {
        //console.log("passsed méthode"+getParameterPTIValue("SecuritePositiveTempsMinute"));
        //console.log("passsed méthode"+getParameterPTIValue("SecuritePositiveTempsHeure"));
        let minutesLinkTest = 0;
        minutesLinkTest = getParameterPTIValue("alertTrackingRange")
        this.alertTrakingRange = minutesLinkTest;
      }
    },

    updateAlertTracking() {
      if (!this.recipient_ip[this.CYCLIC_TEST_COLUMN]) {
        if (this.type == "supervision") {
          if (this.alertTrakingRange == true) {
            setParameterPTIValue("isAlertTracking", 1);
          } else{
            setParameterPTIValue("isAlertTracking", 0);
        }
      }
      if (this.alertTrakingRange == true) {
            setParameterPTIValue("isAlertTracking", 1);
          } else{
            setParameterPTIValue("isAlertTracking", 0);
        }
    }
      let minutesLinkTest = 0;
      //console.log("passsed méthode rannnge = "+this.range);
        minutesLinkTest = this.alertTrakingRange;
      setParameterPTIValue("alertTrackingRange", minutesLinkTest);
    },

    getNumberRangeFromParametrePti() {
      if (this.recipient_ip[this.CYCLIC_TEST_COLUMN]) {
        //console.log("passsed méthode"+getParameterPTIValue("SecuritePositiveTempsMinute"));
        //console.log("passsed méthode"+getParameterPTIValue("SecuritePositiveTempsHeure"));
        let GetNumberMessage = 0;
        GetNumberMessage = getParameterPTIValue("numberOfMessagesToFinishAlertTracking")
        this.numberOfMessagesToFinishAlertTracking = GetNumberMessage;
      }
    },

    updateNumberRange() {
      if (!this.recipient_ip[this.CYCLIC_TEST_COLUMN]) {
        if (this.type == "supervision") {
          if (this.isAlertTrackingEndPostAlert == true) {
            setParameterPTIValue("isAlertTrackingEndPostAlert", 1);
          } else{
            setParameterPTIValue("isAlertTrackingEndPostAlert", 0);
        }
      }
      if (this.isAlertTrackingEndPostAlert == true) {
            setParameterPTIValue("isAlertTrackingEndPostAlert", 1);
          } else{
            setParameterPTIValue("isAlertTrackingEndPostAlert", 0);
        }
    }
      let NumberMessage = 0;
      //console.log("passsed méthode rannnge = "+this.range);
        NumberMessage = this.numberOfMessagesToFinishAlertTracking;
      setParameterPTIValue("numberOfMessagesToFinishAlertTracking", NumberMessage);
    },
   
   
   
      intToBoolean(value) {
      if (value === "1") {
        return true;
      }
      return false;
    },

      booleanToInt(value) {
      if (value == true) {
        return "1";
      }
      return "0";
    },

  }
};
</script>

<style>
p {
  color: rgba(211, 41, 41, var(--tw-text-opacity));
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  margin-top: 30px;
  font-style: bold;
}
</style>
