<template>
  <div class="clearfix box">
    <BoxTitle
      color="bg-indigo-300"
      title="sipconfiguration.menu_sip_need_configuration"
    />
    <div class="clearfix flex-row pl-3 pb-3 pr-3 border-b border-gray-200">
      <CustomTextInput
        v-for="(field, index) in sipFields"
        :key="index"
        v-model="field.value"
        :title="field.title"
        :is-password-field="field.isPasswordField"
        :error-msg="field.errorMsg"
        class="mt-2"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { checkFieldIsNotEmpty } from "../../../utils/fieldManagement";
import { updateTargetDbSipAccountInfo } from "../../../dao/sip-dao"


const I18_TITLE_USER_NAME = 'sipconfiguration.menu_user_name';
const I18_TITLE_PASSWORD = 'sipconfiguration.menu_user_password';
const I18_TITLE_SERVER_URL = 'sipconfiguration.menu_server_url';

const I18_ERR_FIELD_CANNOT_BE_EMPTY = "common.menu_field_cannot_be_empty";


export default defineComponent({

  data() {
    return {
      sipFields: [
        { 
          title: I18_TITLE_USER_NAME, 
          value: '', 
          isPasswordField: false,
          errorMsg: '' 
        },
        { 
          title: I18_TITLE_PASSWORD, 
          value: '', 
          isPasswordField: true,
          errorMsg: '' 
        },
        { 
          title: I18_TITLE_SERVER_URL, 
          value: '', 
          isPasswordField: false,
          errorMsg: '' 
        },
      ]

    }
  },

  methods: {
    check() {
      let isCorrect = true;
      for (let i = 0; i < this.sipFields.length; ++i)
        if (!checkFieldIsNotEmpty(this.sipFields, i, I18_ERR_FIELD_CANNOT_BE_EMPTY))
          isCorrect = false;
      return isCorrect;
    },

    save() {
      let sipInfo = [];
      for (let i = 0; i < this.sipFields.length; ++i)
        sipInfo.push(this.sipFields[i].value);
      updateTargetDbSipAccountInfo(sipInfo);
    },
  }
})
</script>
