<template>
  <!-- BEGIN: ModalModifyWifiHotspot local component-->
  <div>
    <div
      :id="modalId"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ $t("locationindoorwifi.menu_modify_hotspot") }}
            </h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <Tippy
              v-for="(field, index) in getHotspotFields"
              :key="index"
              :content="$t(field.tippy)"
              :options="{
                theme: 'light',
              }"
              class="col-span-12"
            >
              <CustomTextInput
                v-model="field.value"
                :title="field.title"
                :max-length="field.len"
                :error-msg="field.errorMsg"
              />
            </Tippy>
          </div>
          <div class="mt-5 modal-footer text-right">
            <ButtonDismissModal
              ref="leftButton"
              custom-class="btn btn-outline-secondary mr-1"
              text="common.menu_cancel"
            />
            <CustomButton
              text="common.menu_confirm"
              @click="check"
            />
          </div>
        </div>
      </div>
    </div>
    <CustomToast
      ref="toastModifyHotspotSuccess"
      toast-id="toast-modify-hotspot-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="locationindoorwifi.menu_modify_hotspot_success"
    />
  </div>
  <!-- END: ModalModifyWifiHotspot local component-->
</template>

<script>
import { defineComponent } from 'vue'
import { getWifiBeaconId } from '../../../dao/indoor-wifi-dao'
import { formatLevel } from './utils'
import { regexCheck } from '../../../utils/fieldManagement'


const SSID = 0;
const BSSID = 1;
const CODE_ZONE = 2;
const DESC = 3;
const LEVEL = 4;

const BSSID_LEN = 17;
const WRONG_CODE_ZONE = "000";
const ERR_SSID = "locationindoorwifi.menu_err_empty_ssid";

const ERR_BSSID = "locationindoorwifi.menu_err_wrong_bssid";
const ERR_BSSID_ALREADY_EXIST = "locationindoorwifi.menu_err_bssid_already_exist";
const ERR_LEVEL = "locationindoorwifi.menu_err_wrong_level";
const ERR_DESC = "locationindoorwifi.menu_err_empty_desc";
const ERR_CODE_ZONE = "locationindoorwifi.menu_err_wrong_code_zone";

const SSID_TITLE = 'locationindoorwifi.menu_hotspot_ssid';
const BSSID_TITLE = 'locationindoorwifi.menu_hotspot_bssid';
const LEVEL_TITLE = 'locationindoorwifi.menu_hotspot_level';
const DESC_TITLE = 'locationindoorwifi.menu_hotspot_description';
const CODE_ZONE_TITLE = 'locationindoorwifi.menu_hotspot_code_zone';

const SSID_TIPPY = 'locationindoorwifi.menu_tippy_ssid';
const BSSID_TIPPY = 'locationindoorwifi.menu_tippy_bssid';
const LEVEL_TIPPY = 'locationindoorwifi.menu_tippy_level';
const DESC_TIPPY = 'locationindoorwifi.menu_tippy_description';
const CODE_ZONE_TIPPY = 'locationindoorwifi.menu_tippy_code_zone';


export default defineComponent({

  props: {
    modalId: {
      type: String,
      required: true,
    },
    hotspotList: {
      type: Array,
      required: true,
    },
    isBleLevelActivated: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['modifyHotspotSuccess'],

  data() {
    return {
      hotspotFields: [
        { title: SSID_TITLE, tippy: SSID_TIPPY, value: '', len: 50, errorMsg: '' },
        { title: BSSID_TITLE, tippy: BSSID_TIPPY, value: '', len: 17, errorMsg: '' },
        { title: CODE_ZONE_TITLE, tippy: CODE_ZONE_TIPPY, value: '', len: 3, errorMsg: '' },
        { title: DESC_TITLE, tippy: DESC_TIPPY, value: '', len: 50, errorMsg: '' },
        { title: LEVEL_TITLE, tippy: LEVEL_TIPPY, value: '', len: 3, errorMsg: '' },
      ],
      currentIndex: undefined,
      currentId: undefined,
    }
  },

  computed: {
    getHotspotFields() {
      const len = this.isBleLevelActivated ? this.hotspotFields.length : this.hotspotFields.length - 1;
      let newHotspotFields = new Array(len);
      for (let i = 0, j = 0; i < this.hotspotFields.length; ++i, ++j) {
        if (!this.isBleLevelActivated && i === LEVEL)
          --j;
        else 
          newHotspotFields[j] = this.hotspotFields[i];
      }
      return newHotspotFields;
    },
  },

  methods: {

    initValues(newCurrentIndex) {
      this.resetAllError();
      this.currentIndex = newCurrentIndex;
      const hotspotToModify = this.hotspotList[this.currentIndex];
      for (let i = 0; i < this.hotspotFields.length; ++i)
        this.hotspotFields[i].value = hotspotToModify[i];
      this.currentId = getWifiBeaconId(this.hotspotFields[BSSID].value);
    },
    
    check() {
      let error = this.hotspotFields.length;
      error -= this.checkSsidIsNotEmpty();
      error -= this.checkBssid();
      error -= this.checkLevel();
      error -= this.checkCodeZone();
      error -= this.checkDescIsNotEmpty();
      if (!error)
        this.emitModifyHotspotSuccess();
    },

    emitModifyHotspotSuccess() {
      const hotspotModified = {
        id: this.currentId,
        newHotspot: this.createModifiedHotspot(),
      }
      this.$emit('modifyHotspotSuccess', hotspotModified);
      this.$refs.toastModifyHotspotSuccess.trigger();
      this.$refs.leftButton.click();
    },

    createModifiedHotspot() {
      let newHotspot = new Array(5);
      for (let i = 0; i < this.hotspotFields.length; ++i) {
        if (i == BSSID)
          newHotspot[i] = this.hotspotFields[i].value.toUpperCase();
        else if (i == LEVEL)
          newHotspot[i] = formatLevel(this.hotspotFields[i].value);
        else
          newHotspot[i] = this.hotspotFields[i].value;
      }
      return newHotspot
    },

    checkSsidIsNotEmpty() {
      const ssid = this.hotspotFields[SSID].value;
      if (ssid.length < 1 || ssid.length > 50)
        return this.setError(SSID, ERR_SSID);
      return this.resetError(SSID);
    },

    checkBssid() {
      const bssid = this.hotspotFields[BSSID].value.toUpperCase();
      if (bssid.length != BSSID_LEN)
        return this.setError(BSSID, ERR_BSSID);
      for (let i = 0; i < BSSID_LEN; ++i) {
        if (!((i + 1) % 3) && bssid[i] != ':')
          return this.setError(BSSID, ERR_BSSID);
        if (((i + 1) % 3) && !String(bssid[i]).match(/[A-F0-9]/))
          return this.setError(BSSID, ERR_BSSID);
      }
      for (let i = 0; i < this.hotspotList.length; ++i)
        if (i != this.currentIndex && bssid.valueOf() === this.hotspotList[i][BSSID].toUpperCase().valueOf())
          return this.setError(BSSID, ERR_BSSID_ALREADY_EXIST)
      return this.resetError(BSSID);
    },

    checkLevel() {
      if (!this.isBleLevelActivated)
        return true;
      const level = this.hotspotFields[LEVEL].value;
      if (!level.length || !regexCheck(level, /^(?:-?\d+)?$/))
        return this.setError(LEVEL, ERR_LEVEL);
      return this.resetError(LEVEL);
    },

    checkDescIsNotEmpty() {
      const desc = this.hotspotFields[DESC].value;
      if (desc.length < 1 || desc.length > 50)
        return this.setError(DESC, ERR_DESC);
      return this.resetError(DESC);
    },

    checkCodeZone() {
      const codeZone = this.hotspotFields[CODE_ZONE].value;
      if (!regexCheck(codeZone, /^[0-9]{3}$/) || codeZone.valueOf() === WRONG_CODE_ZONE)
        return this.setError(CODE_ZONE, ERR_CODE_ZONE);
      return this.resetError(CODE_ZONE);
    },

    setError(index, err) {
      this.hotspotFields[index].errorMsg = err;
      return false;
    },

    resetAllError() {
      for (let i = 0; i < this.hotspotFields.length; ++i)
        this.resetError(i);
      return true;
    },

    resetError(index) {
      if (this.hotspotFields[index].errorMsg.length)
        this.hotspotFields[index].errorMsg = '';
      return true;
    },

    dismiss() {
      this.$refs.leftButton.click();
    }
  },

})
</script>
