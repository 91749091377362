<template>
  <!-- BEGIN: CustomLaunchScreen local component-->
  <div>
    <CheckBoxTitle
      v-model="isCustomLaunchScreen"
      color="bg-gray-400"
      title-yes="customscreens.menu_title_yes_custom_launch_screen"
      title-no="customscreens.menu_title_no_custom_launch_screen"
    />
    <div 
      v-if="isCustomLaunchScreen"
      class="clearfix box"
    >
      <div class="clearfix p-3 border-b border-gray-200">
        <ButtonUploadFile
          text="customscreens.menu_upload_launch_screen_img"
          accept=".png,.gif"
          custom-class="btn btn-warning"
          @file-uploaded="checkFile($event[0])"
        />
        <ErrorMsg
          :error-msg="imgUploadErrorMsg"
          class="mt-2"
        />
        <div v-if="imgArrayBuffer">
          <CustomButton
            text="customscreens.menu_remove_launch_img"
            custom-class="btn btn-danger mt-3"
            @click="removeImg()"
          />
          <div class="font-bold italic ml-3 mt-3">
            {{ $t("customscreens.menu_custom_screens_overview") }}
          </div>
          <div class="relative h-24 w-32 mt-1 bg-black">
            <img
              :src="getImgBlob"
              style="width: 128px; height: 96px;"
              class="mt-1"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: CustomLaunchScreen local component-->
</template>

<script>
import { defineComponent } from 'vue'
import { useStore } from '@/store';
import { 
  getBoolPtiValue, 
  setNumberPtiValue 
} from '../../../dao/parametre-pti'
import {
  checkIfFileIsImg, 
  checkSize,
} from './utils'
import { 
  STORE_SET_LAUNCH_SCREEN_IMG_FILE, 
  STORE_SET_LAUNCH_SCREEN_IMG_UPDATE_S3, 
} from '../../../store/const_store';

const PARAM_PTI_IS_CUSTOM_LAUNCH_SCREEN_ENABLED = "isCustomLaunchScreenEnabled";
const PARAM_PTI_IS_LAUNCH_SCREEN_IMG_UPLOADED = "isLaunchScreenImgUploaded";

const EV_UPLOAD_SUCCESS = 'upload-success';
const EV_UPLOAD_FAIL = 'upload-fail';

const checkFunctions = [
  checkIfFileIsImg,
  checkSize,
];

const I18_ERR_IMG_UPLOAD = [
  "customscreens.menu_err_only_png_gif_accepted",
  "customscreens.menu_err_img_is_too_heavy",
];
const I18_ERR_PLS_UPLOAD_IMG = "customscreens.menu_err_pls_upload_launch_screen";


export default defineComponent({

  emits: [
    EV_UPLOAD_SUCCESS,
    EV_UPLOAD_FAIL    
  ],

  data() {
    return {
      isCustomLaunchScreen: undefined,
      imgArrayBuffer: undefined,
      updateS3Img: undefined,      
      imgUploadErrorMsg: '',
    }
  },

  computed: {
    getImgBlob() {
      return URL.createObjectURL(
        new Blob([this.imgArrayBuffer], { type: 'image' })
      );
    }
  },

  watch: {
    isCustomLaunchScreen() {
      this.updateS3Img = true;
      this.imgUploadErrorMsg = '';
    },
  },

  created() {
    this.isCustomLaunchScreen = getBoolPtiValue(PARAM_PTI_IS_CUSTOM_LAUNCH_SCREEN_ENABLED);
    this.updateS3Img = useStore().state.emeritValues.launchScreenImg.updateS3;
    if (getBoolPtiValue(PARAM_PTI_IS_LAUNCH_SCREEN_IMG_UPLOADED))
      this.imgArrayBuffer = useStore().state.emeritValues.launchScreenImg.file;
  },

  methods: {

    removeImg() {
      this.imgArrayBuffer = undefined;
      this.updateS3Img = true;
    },

    checkFile(file) {
      for (let i = 0; i < checkFunctions.length; ++i) {
        if (!checkFunctions[i](file)) {
          this.$emit(EV_UPLOAD_FAIL)  
          this.imgUploadErrorMsg = I18_ERR_IMG_UPLOAD[i];
          return ;
        }
      }
      this.imgUploadErrorMsg = '';
      this.updateS3Img = true;
      this.createImage(file);
      this.$emit(EV_UPLOAD_SUCCESS);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imgArrayBuffer = e.target.result;
      };
      reader.readAsArrayBuffer(file);
    },
    
    check() {
      if (this.isCustomLaunchScreen && !this.imgArrayBuffer) {
        this.imgUploadErrorMsg = I18_ERR_PLS_UPLOAD_IMG;
        return false;
      }
      this.imgUploadErrorMsg = '';
      return true;
    },

    save() {
      if (!this.isCustomLaunchScreen)
        this.imgArrayBuffer = undefined;
      let isImgUploaded = this.imgArrayBuffer != undefined;
      setNumberPtiValue(PARAM_PTI_IS_CUSTOM_LAUNCH_SCREEN_ENABLED, this.isCustomLaunchScreen);
      setNumberPtiValue(PARAM_PTI_IS_LAUNCH_SCREEN_IMG_UPLOADED, isImgUploaded);
      this.$store.commit(STORE_SET_LAUNCH_SCREEN_IMG_FILE, this.imgArrayBuffer);
      this.$store.commit(STORE_SET_LAUNCH_SCREEN_IMG_UPDATE_S3, this.updateS3Img);
    },
  }
})
</script>
