<template>
  <!-- BEGIN: NotifPreAlert local component -->
  <div class="grid grid-cols-12 gap-6 mt-6">
    <div class="intro-y col-span-12 lg:col-span-6">
      <BoxTitle
        color="bg-red-300"
        title="common.menu_notification_prealert"
      />
      <div class="clearfix flex-row p-3 border-b border-gray-200 box">
        <CustomRangeInput
          v-model="notifPreAlertTime"
          title="common.menu_notification_duration"
          :min-value="5"
          :max-value="30"
          :step-value="1"
          unit-description="common.menu_seconds"
        />
        <FieldXVerticalCheckBoxes
          v-model="notifFields.value"
          :descriptions="notifFields.desc"
          :ids="notifFields.ids"
          @change="resetError"
        />
        <ErrorMsg
          :error-msg="errorMsg"
        />
      </div>
    </div>
  </div>
  <!-- END: NotifPreAlert local component -->
</template>

<script>
import { defineComponent } from 'vue'
import { 
  getBoolPtiValue,
  getNumberPtiValue,
  setNumberPtiValue,
} from '../../../dao/parametre-pti';


const PARAM_PTI_NOTIF_TYPE = [
  "isNotifWristMouvementVibrationEnabled",
  "isNotifWristMouvementSoundEnabled",
];

const PARAM_PTI_NOTIF_TIME = "WristMouvementNotifTime";

const NOTIF_VIB_INDEX = 0;
const NOTIF_SOUND_INDEX = 1;

const NOTIF_CHECKBOX_ID1 = "notif-checkbox-id1";
const NOTIF_CHECKBOX_ID2 = "notif-checkbox-id2";

const NOTIF_VIB_DESC = "common.menu_notification_by_vibration";
const NOTIF_SOUND_DESC = "common.menu_notification_by_sound";

const ERR_SELECT_ONE_NOTIF = "alerttrigerring.menu_select_at_least_one_notification_mode";


export default defineComponent({

  data() {
    return {
      notifFields: {
        value: [undefined, undefined],
        desc: [NOTIF_VIB_DESC, NOTIF_SOUND_DESC],
        ids: [NOTIF_CHECKBOX_ID1, NOTIF_CHECKBOX_ID2],
      },
      notifPreAlertTime: undefined,
      errorMsg: '',
    }
  },

  created() {
    for (let i = 0; i < this.notifFields.value.length; ++i)
      this.notifFields.value[i] = getBoolPtiValue(PARAM_PTI_NOTIF_TYPE[i]);
    this.notifPreAlertTime = getNumberPtiValue(PARAM_PTI_NOTIF_TIME);
  },

  methods: {
    check() {
      if (this.notifFields.value[NOTIF_VIB_INDEX]
          || this.notifFields.value[NOTIF_SOUND_INDEX])
        return this.resetError();
      return this.setError();
    },

    save() {
      for (let i = 0; i < this.notifFields.value.length; ++i)
        setNumberPtiValue(PARAM_PTI_NOTIF_TYPE[i], this.notifFields.value[i]);
      setNumberPtiValue(PARAM_PTI_NOTIF_TIME, this.notifPreAlertTime);
    },

    resetError() {
      if (this.errorMsg.length)
        this.errorMsg = '';
      return true;
    },

    setError() {
      this.errorMsg = ERR_SELECT_ONE_NOTIF;
      return false;
    }
  }
})
</script>
