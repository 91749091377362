import { useStore } from "@/store";

export const getDbVersion = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "PRAGMA user_version";
  try {
    return dbFile.exec(sqlScript)[0].values[0][0];
  } catch (error) {
    console.log('getCredValue failed: ' + error);
  }
};

export const setDbVersion = pragmaVersion => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "PRAGMA user_version = " + pragmaVersion;
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('setDbVersion failed: ' + error);
  }
};