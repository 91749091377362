<template>
  <!-- BEGIN: CommunicationOption local component -->
  <div class="clearfix box">
    <BoxTitle title="configuration.menu_communication_option" color="bg-indigo-300" help-locale="menu_communication_option" />
    <CheckBoxFieldAndXIcons
      v-for="(fields, index) in getCheckBoxFields"
      :key="index"
      v-model="fields.value"
      :custom-id="`checkbox-other-params-${index}`"
      :check-box-text="fields.title"
      :is-disabled="fields.isDisabled"
      :tippy-text="fields.tippyText"
      :error-msg="fields.errorMsg"
      :icon-params="fields.iconParams"
      @change="onCheckBoxChange(index)"
    />
    <CustomRangeInputNotBoldTitle
      v-if="!isIS530 && !isIS330 && !isIS655"
      v-model="durationCallAttempt"
      title="internalrecipients.menu_duration_of_call_attempt_before_next_number"
      :min-value="durationCallAttemptMin"
      :max-value="durationCallAttemptMax"
      :step-value="1"
      unit-description="common.menu_seconds"
    />
    <CustomToast
      ref="ToastUnstopableCall"
      toast-id="custom-toast-unstopable-call"
      icon-component-name="alert-triangle-icon"
      icon-component-class="text-yellow-500"
      text="configuration.menu_model_check_emerit_version_unstopable_call"
    />
  </div>
  <!-- END: CommunicationOption local component -->
</template>

<script>
import { defineComponent } from "vue";
import { getSipAccount } from "../../../dao/sip-dao";
import { getCountWifi } from "../../../dao/wifi-dao";
import {
  getBoolPtiValue,
  setNumberPtiValue,
  getParameterPTIValue,
  getParameterPTIMax,
  getParameterPTIMin,
  setParameterPTIValue
} from "../../../dao/parametre-pti";
import { useStore } from "@/store";

const PTI_PARAM_WIFI = "isWifiWanted";
const PTI_PARAM_AUTO_PICKUP = "isAutomaticCallPickup";
const PTI_PARAM_WHITELIST = "isWhitelistEnabled";
const PTI_PARAM_SIP = "SipActivationState";
const PTI_PARAM_UNSTOPABLE_CALL_IS_AVAILABLE = "isUnstopableCallAvailable";
const PTI_PARAM_UNSTOPABLE_CALL_IS_ACTIVATED = "isUnstopableCallActivated";

const EV_TRIGGER_MODAL_CONFIRM_SIP = "triggerModalConfirmSip";

const ERR_SAVE_AT_LEAST_ONE_WIFI = "configuration.menu_save_at_least_one_wifi_connection";
const ERR_WRONG_SIP_CONFIG = "configuration.menu_wrong_sip_configuration";

const I18_TIPPY_SIP = "configuration.menu_tippy_sip_not_available";
const I18_TIPPY_UNSTOPABLE_CALL = "configuration.menu_tippy_unstopable_call_not_available";

const I18_TITLE_SIP = "configuration.menu_ask_to_activate_sip";
const I18_TITLE_AUTO_PICKUP_CALL = "configuration.menu_location_automatic_incoming_call_pickup_after_three_rings";
const I18_UNSTOPABLE_CALL = "configuration.menu_call_cant_be_stop";
const I18_TITLE_WIFI_CONFIG = "configuration.menu_wifi_configuration";
const I18_TITLE_WHITELIST = "configuration.menu_whitelist";

const I18_ERROR_WIFI_UTC = "wifi.menu_error_utc_none";

const WIFI_INDEX = 0;
const SIP_INDEX = 1;
const WHITELIST_INDEX = 2;
const AUTO_CALL_PICKUP_INDEX = 3;
const UNSTOPABLE_CALL_INDEX = 4;

const MODEL_IS655 = "IS655";
const MODEL_IS330 = "IS330";
const MODEL_IS530 = "IS530";

export default defineComponent({
  props: {
    isEwg100: {
      type: Boolean,
      required: true
    },
    isDeployment: {
      type: Boolean,
      required: true
    },
    isSipAvailable: {
      type: Boolean,
      required: true
    }
  },

  emits: [EV_TRIGGER_MODAL_CONFIRM_SIP],

  data() {
    return {
      communicationOption: [
        {
          title: I18_TITLE_WIFI_CONFIG,
          ptiParam: PTI_PARAM_WIFI,
          value: false,
          isDisabled: false,
          tippyText: "",
          errorMsg: "",
          iconParams: [{ componentName: "SlidersIcon", routeName: "emerit-wifi", paramsType: "" }]
        },
        {
          title: I18_TITLE_SIP,
          ptiParam: PTI_PARAM_SIP,
          value: false,
          isDisabled: false,
          tippyText: I18_TIPPY_SIP,
          errorMsg: "",
          iconParams: [{ componentName: "SlidersIcon", routeName: "emerit-sip-configuration", paramsType: "" }]
        },
        {
          title: I18_TITLE_WHITELIST,
          ptiParam: PTI_PARAM_WHITELIST,
          value: false,
          isDisabled: false,
          tippyText: "",
          errorMsg: "",
          iconParams: [{ componentName: "UsersIcon", routeName: "emerit-whitelist", paramsType: "" }]
        },
        {
          title: I18_TITLE_AUTO_PICKUP_CALL,
          ptiParam: PTI_PARAM_AUTO_PICKUP,
          value: false,
          isDisabled: false,
          tippyText: "",
          errorMsg: "",
          iconParams: undefined
        },
        {
          title: I18_UNSTOPABLE_CALL,
          ptiParam: PTI_PARAM_UNSTOPABLE_CALL_IS_ACTIVATED,
          value: false,
          isDisabled: false,
          tippyText: I18_TIPPY_UNSTOPABLE_CALL,
          errorMsg: "",
          iconParams: undefined
        }
      ],
      durationCallAttempt: 20,
      durationCallAttemptMin: 20,
      durationCallAttemptMax: 60,

      isIS330: false,
      isIS530: false,
      isIS655: false
    };
  },

  computed: {
    getCheckBoxFields() {
      if (!this.isEwg100) {
        let checkBoxFields = new Array();
        for (let i = 0; i < this.communicationOption.length; ++i) if (i === AUTO_CALL_PICKUP_INDEX) checkBoxFields.push(this.communicationOption[i]);
        return checkBoxFields;
      }
      return this.communicationOption;
    }
  },

  watch: {
    durationCallAttempt(newValue) {
      this.UpdateNewValue(newValue);
    }
  },

  created() {
    for (let i = 0; i < this.communicationOption.length; ++i)
      this.communicationOption[i].value = getBoolPtiValue(this.communicationOption[i].ptiParam);
    if (!this.isSipAvailable) this.communicationOption[SIP_INDEX].isDisabled = true;
    if (!this.isDeployment && !getBoolPtiValue(PTI_PARAM_UNSTOPABLE_CALL_IS_AVAILABLE))
      this.communicationOption[UNSTOPABLE_CALL_INDEX].isDisabled = true;
    this.durationCallAttempt = getParameterPTIValue("TimeOutCall");
    this.durationCallAttemptMin = getParameterPTIMin("TimeOutCall");
    this.durationCallAttemptMax = getParameterPTIMax("TimeOutCall");
    this.deviceModelName = useStore().state.emeritValues.deviceInfo.deviceModelName;
    this.isIS655 = this.deviceModelName === MODEL_IS655;
    this.isIS330 = this.deviceModelName === MODEL_IS330;
    this.isIS530 = this.deviceModelName === MODEL_IS530;
  },

  methods: {
    UpdateNewValue() {
      setParameterPTIValue("TimeOutCall", this.durationCallAttempt);
    },
    onCheckBoxChange(i) {
      if (i == SIP_INDEX) {
        let sip = this.communicationOption[SIP_INDEX];
        this.$emit(EV_TRIGGER_MODAL_CONFIRM_SIP, sip.value);
        sip.value = !sip.value;
      } else {
        this.resetAllErrorMsg();
        setNumberPtiValue(this.getCheckBoxFields[i].ptiParam, this.getCheckBoxFields[i].value);
        if (i === UNSTOPABLE_CALL_INDEX && this.isDeployment && this.getCheckBoxFields[i].value) this.$refs.ToastUnstopableCall.trigger();
      }
    },

    updateSipStatus(newSipValue) {
      this.communicationOption[SIP_INDEX].value = newSipValue;
      setNumberPtiValue(this.communicationOption[SIP_INDEX].ptiParam, newSipValue);
      this.resetAllErrorMsg();
    },

    check() {
      if (!this.isEwg100) return true;
      this.checkWifi();
      this.checkSip();
      return this.errorState();
    },

    checkWifi() {
      let wifi = this.communicationOption[WIFI_INDEX];
      if (wifi.value && getCountWifi() == 0) wifi.errorMsg = ERR_SAVE_AT_LEAST_ONE_WIFI;
    },

    checkSip() {
      let sip = this.communicationOption[SIP_INDEX];
      if (this.isSipAvailable && sip.value) {
        const sipTable = getSipAccount();
        if (sipTable == undefined) sip.errorMsg = ERR_WRONG_SIP_CONFIG;
        else {
          let i = 0;
          while (++i < 5 && sip.errorMsg === "") if (sipTable.values[0][i] === "") sip.errorMsg = ERR_WRONG_SIP_CONFIG;
        }
      }
    },
    saveUTC() {
      if (this.communicationOption[0].value == true && getParameterPTIValue("isUseWifiAlone") == "1") {
        if (getParameterPTIValue("UtcTimeAllCharacter") == "" || getParameterPTIValue("UtcTime") == "") {
          return (this.communicationOption[0].errorMsg = I18_ERROR_WIFI_UTC);
        }
      }
    },

    resetAllErrorMsg() {
      for (let i = 0; i < this.communicationOption.length; ++i)
        if (this.communicationOption[i].errorMsg.length) this.communicationOption[i].errorMsg = "";
    },

    errorState() {
      for (let i = 0; i < this.communicationOption.length; ++i) if (this.communicationOption[i].errorMsg.length) return false;
      return true;
    }
  }
});
</script>
