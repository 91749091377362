<template>
  <!-- BEGIN: CustomTablePreviewOnly -->
  <div class="grid grid-cols-10">
    <div :class="`intro-y col-span-10 xl:col-span-${screenPercentWidth}`">
      <div :class="`intro-y col-span-10 xl:col-span-${screenPercentWidth} overflow-auto`">
        <table class="table table-report">
          <thead>
            <tr>
              <th
                v-for="(header, index) in headers"
                :key="index"
              >
                {{ $t(header) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <component
              :is="listElementComponentName" 
              v-for="(elem, index) in elemsList"
              :key="index"
              :elems-text="elem"
              :icons="getIcons(index)"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- END: CustomTablePreviewOnly component -->
</template>


<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A table rendering a list of elements. Each element can have one or 
 * several text fields prompted to the user with optional header fields.
 * This table is for preview only, it's not possible to modify element
 * by clicking on it.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {Array[X Strings] | undefined} headers
 *    An array of Strings representing the headers of the table.
 *    Strings should be keys for i18 locale. Undefined by default, if 
 *    you do not need headers do not fill this props.
 * 
 * @param {Array[Array[X Strings]]} elemsList
 *    A double array, with each element being an array of Strings that will be 
 *    passed to the dynamic list element component, and used to render the 
 *    description of the element.
 * 
 * @param {Array[Array[X Strings]] | undefined} iconsList
 *    A double array, with each element being an array of Strings containing 
 *    name of dynamic icons components. Icons will be rendered at the right 
 *    of elemsList fields description. iconsList length should match elemsList
 *    length. Undefined by default, if you do not need icons do not fill this props.
 * 
 * @param {Number} screenPercentWidth
 *    A number between 1 and 10 indicating the width of the table proportional
 *    to the screen, 10 being full width (table take full screen) and 1 
 *    the minimal width.
 * 
 * @param {String} listElementComponentName
 *    The name of the dynamic component that will be use to render the
 *    elements of the table as a list (ex : 'ListElementOnlyPreview').
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  props: {
    headers: {
      type: Array,
      default: undefined,
    },
    elemsList: {
      type: Array,
      required: true,
    },
    iconsList: {
      type: Array,
      default: undefined,
    },
    screenPercentWidth: {
      type: Number,
      required: true,
    },
    listElementComponentName: {
      type: String,
      required: true,
    },
  },
  
  methods: {
    
    getIcons(index) {
      if (!this.iconsList)
        return undefined;
      return this.iconsList[index];
    }
  }
})
</script>
