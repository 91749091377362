<template>
  <!-- BEGIN: RecipientWhiteList local component-->
  <div>
    <div class="font-bold text-theme-0">
      {{ $t("whitelist.menu_nb_recipient_whitelist") + recipientWhiteList.length }}
    </div>
    <Pagination
      v-if="recipientWhiteList.length"
      :current-page="page"
      :total-pages="getTotalPages"
      @begin="page = 1"
      @end="page = getTotalPages"
      @decrement="page = page === 1 ? 1 : --page"
      @increment="page = page === getTotalPages ? page : ++page"
    />
    <CustomTable
      v-if="recipientWhiteList.length"
      :headers="['internalrecipients.menu_name', 'internalrecipients.menu_call_number']"
      :elems-list="getRecipientWhiteListPage"
      :screen-percent-width="10"
      list-element-component-name="ListElement"
      green-button-text="common.menu_modify"
      red-button-text="common.menu_remove"
      :green-button-bootstrap-target="'#' + modalIdAddModifyRecipientWhiteList"
      :red-button-bootstrap-target="'#' + modalIdAlertConfirm"
      @element-green-button-clicked="$emit('modify-recipient-whitelist', $event + (page - 1) * 10)"
      @element-red-button-clicked="$emit('remove-recipient-whitelist', $event + (page - 1) * 10)"
    />
  </div>
  <!-- END: RecipientWhiteList local component-->
</template>

<script>
import { defineComponent } from 'vue'


const NB_ITEMS = 10;

const EV_MODIFY_RECIPIENT_WHITELIST = 'modify-recipient-whitelist';
const EV_REMOVE_RECIPIENT_WHITELIST = 'remove-recipient-whitelist';


export default defineComponent({
  
  props: {
    recipientWhiteList: {
      type: Array,
      required: true,
    },
    modalIdAddModifyRecipientWhiteList: {
      type: String,
      required: true,
    },
    modalIdAlertConfirm: {
      type: String,
      required: true,
    },
  },

  emits: [
    EV_MODIFY_RECIPIENT_WHITELIST,
    EV_REMOVE_RECIPIENT_WHITELIST
  ],

  data() {
    return {
      page: 1,
    }
  },

  computed: {
    getRecipientWhiteListPage() {
      const startIndex = (this.page - 1) * NB_ITEMS;
      const length = startIndex + NB_ITEMS < this.recipientWhiteList.length ?
          NB_ITEMS : this.recipientWhiteList.length - startIndex;
      let recipientWhiteListPage = new Array(length);
      for (let i = 0; i < length; ++i)
        recipientWhiteListPage[i] = this.recipientWhiteList[i + startIndex];
      return recipientWhiteListPage;
    },

    getTotalPages() {
      return Math.ceil(this.recipientWhiteList.length / 10);
    },
  },

  watch: {

    /**
     * Update the page number dynamically when whitelist recipients are 
     * added / removed.
     */
    'recipientWhiteList.length': {
      handler: function updatePageNumber(newVal, oldVal) {
        if (this.page > this.getTotalPages)
          --this.page;
        else if (newVal > oldVal)
          this.page = this.getTotalPages;
      }
    }
  }
  
})
</script>
