import { createStore } from "vuex";
import main from "./main";
import sideMenu from "./side-menu";
import emeritValues from "./emerit-values";

const store = createStore({
  modules: {
    main,
    sideMenu,
    emeritValues,
  }
});

export function useStore() {
  return store;
}

export default store;
