import { useStore } from "@/store";

const INDEX_NAME = 0;
const INDEX_PHONE_NUMBER = 1;

export const addWhitelistRecipient = recipient => {
  const dbFile = useStore().state.emeritValues.dbFile;
  
  let sqlScript =
  'INSERT INTO  whitelist_recipient (name,phone_number)  VALUES("' +
  recipient[INDEX_NAME] +
  '", "' +
  recipient[INDEX_PHONE_NUMBER] +
  '")';

  try {
    return dbFile.exec(sqlScript);
  } catch (error) {
    console.log('addWhitelistRecipient failed: ' + error);
  }
};

export const getAllWhitelistRecipient = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * from whitelist_recipient";
  try {
    var recipient = dbFile.exec(sqlScript)[0];
    return recipient;
  } catch (error) {
    console.log('getAllWhitelistRecipient failed: ' + error);
  }
}

export const updateWhitelistRecipient = (recipient, id) => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript =
    'UPDATE whitelist_recipient SET name="' +
    recipient[INDEX_NAME] +
    '", phone_number="' +
    recipient[INDEX_PHONE_NUMBER] +
    '" where _id="' +
    id +
    '"';

  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('updateWhitelistRecipient failed: ' + error);
  }
};

export const deleteWhitelistRecipient = recipient => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = 'DELETE FROM whitelist_recipient where phone_number="' + recipient[INDEX_PHONE_NUMBER] + '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('deleteWhitelistRecipient failed: ' + error);
  }
};

export const deleteAllWhitelistRecipient = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "DELETE FROM whitelist_recipient";
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log('deleteAllWhitelistRecipient failed: ' + error);
  }
};

export const getWhitelistRecipientId = phoneNumber => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = 'SELECT _id from whitelist_recipient WHERE phone_number="' + phoneNumber + '"';
  try {
    return dbFile.exec(sqlScript)[0].values[0][0];
  } catch (error) {
    console.log('getWhitelistRecipientId failed: ' + error);
  }
}
