import {
  STORE_S3_IMG_FILES_INFO
} from '../store/const_store';
import {
  PATH_LOGIN,
  PATH_MODEL_LOGIN,
  PATH_DEPLOY_LOGIN,
} from './const_path';
const initSqlJs = require("sql.js");
import JSZip from 'jszip';


const LAUNCH_SCREEN_INFO_INDEX = 0;
const WIDGET_SCREEN_INFO_INDEX = 1;

const sqlConfig = {
  locateFile: (file) =>
    `https://cdnjs.cloudflare.com/ajax/libs/sql.js/1.5.0/${file}`
};


export const extractZipFolder = (state, commit, self, modelFile, deviceModelName, storeMutateFuncName) => {
  let zip = new JSZip();
  zip.loadAsync(modelFile, false)
    .then(zip => {      
      if (!checkZipFiles(zip.files, deviceModelName)) {
        self.onTempConfUpdateFailed();
        return ;
      }
      Object.keys(zip.files).forEach(filename => {
        if (filename.endsWith(".emerit_" + deviceModelName)) {
          zip.files[filename].async('uint8array').then(fileData => {
            createDbFile(commit, storeMutateFuncName, fileData, self.onTempConfUpdateSuccess);
          })
        }
        else {
          for (let i = 0; i < STORE_S3_IMG_FILES_INFO.length; ++i) {
            let imgInfo = STORE_S3_IMG_FILES_INFO[i];
            if (filename.valueOf() === imgInfo.fileName) {
              zip.files[filename].async('arraybuffer').then(fileData => {
                state[imgInfo.storeVarName].file = fileData;
              });
            }
          }
        } 
      })
    })
    .catch(() => self.onTempConfUpdateFailed())
}

export const checkZipFiles = (files, deviceModelName) => {
  let checkStatus = true;
  let fileNames = [];
  Object.keys(files).forEach(filename => fileNames.push(filename));
  for (let i = 0; i < fileNames.length; ++i) {
    if (!(fileNames[i].endsWith(".emerit_" + deviceModelName)
        || fileNames[i].valueOf() === STORE_S3_IMG_FILES_INFO[LAUNCH_SCREEN_INFO_INDEX].fileName
        || fileNames[i].valueOf() === STORE_S3_IMG_FILES_INFO[WIDGET_SCREEN_INFO_INDEX].fileName)) {
      checkStatus = false;
    }
    if (!checkIfNoDoubleFiles(fileNames, i))
      checkStatus = false;
  }
  return checkStatus;
};

function checkIfNoDoubleFiles(fileNames, i) {
  for (let j = i + 1; j < fileNames.length; ++j)
    if (fileNames[i].valueOf() === fileNames[j].valueOf())
      return false;
  return true;
}

export const createDbFile = (commit, storeMutateFuncName, binaryData, selfSuccessCallback) => {
  initSqlJs(sqlConfig).then(SQL => {
    try {
      let sqlFile = new SQL.Database(binaryData);
      commit(storeMutateFuncName, sqlFile);
      selfSuccessCallback();
    }
    catch (err) {
      console.log('error while creating db: ' + err);
    }
  });
}

export const isLoginPaths = (path) => {
  if (path.valueOf() === PATH_LOGIN
      || path.valueOf() === PATH_MODEL_LOGIN
      || path.valueOf() === PATH_DEPLOY_LOGIN)
    return true;
  return false;  
}