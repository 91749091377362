<template>
  <!-- BEGIN: SipIcon component-->
  <img
    class="w-6 h-6"
    :src="require(`@/assets/images/sip.png`)"
  >
  <!-- END: SipIcon component-->
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({})
</script>
