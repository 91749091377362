/* eslint-disable */

<template>
  <div>
    <div class="intro-y flex items-center mt-6">
      <h2 class="text-lg font-medium mr-auto">
        {{ pageTitle }}
      </h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-6">
      <!-- BEGIN: left column -->
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: mode d'activation -->
        <div class="clearfix box">
          <!-- entete de parametre -->
          <div
            class="flex flex-col sm:flex-row  p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <h2 class="font-medium text-base  mr-auto">
              {{ $t("alerttrigerring.menu_trigger_mode") }}
            </h2>
          </div>
          <!-- BEGUIN: bloc parametres -->
          <div>
            <!-- BEGUIN: parametre avec radio button -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <div>
                <div v-if="showLongPress" class="form-check mb-3">
                  <input
                    id="radiobutton1"
                    v-model="activationMode"
                    class="form-check-input"
                    type="radio"
                    value="1"
                  />
                  <label class="form-check-label" for="radiobutton1">
                    {{ $t("alerttrigerring.menu_one_long_press") }}
                  </label>
                </div>
                <div class="form-check">
                  <input
                    id="radiobutton2"
                    v-model="activationMode"
                    class="form-check-input"
                    type="radio"
                    value="2"
                  />
                  <label class="form-check-label" for="radiobutton2">
                    {{ $t("alerttrigerring.menu_three_short_presses") }}
                  </label>
                </div>
              </div>
            </div>
            <!-- END: parametre avec radio button -->
          </div>
          <!-- END: bloc parametres -->
        </div>
        <!-- END: mode d'activation -->
        <!-- BEGIN: notification préalerte -->
        <div v-if="showPreAlert" class="clearfix box mt-6">
          <!-- entete de parametre -->
          <div
            class="flex flex-raw sm:flex-row pt-3 p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div class="form-check w-full">
              <CustomSwitch
                v-model="isButtonSwitchPre"
                class="pr-3 show-code"
                text="common.menu_notification_prealert"
              /> 
            </div>
          </div>
          <!-- BEGUIN: bloc parametres -->
          <div>
            <!-- BEGUIN: parametre range slider -->
            <div v-if="isButtonSwitchPre==1"
            class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("common.menu_notification_duration") }}
              </label>
              <div class="input-group pb-1 mt-2">
                <input
                  v-model="sosNotificationDurationRange"
                  type="range"
                  :min="sosNotificationDurationMin"
                  :max="sosNotificationDurationMax"
                  step="1"
                />
                <div class="form-control w-20 text-center ml-5">
                  {{ sosNotificationDurationRange }}
                </div>
                <div id="input-group-3" class="input-group-text h-10 font-sm">
                  {{ $t("common.menu_seconds") }}
                </div>
              </div>
              <div class="flex flex-col">
                <div class="form-check mt-3 mr-3 ">
                  <input
                    id="checkbox-switch-4"
                    v-model="isButtonNotificationVibration"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-4">
                    {{ $t("common.menu_notification_by_vibration") }}
                  </label>
                </div>
                <div class="form-check mt-3 mr-3">
                  <input
                    id="checkbox-switch-5"
                    v-model="isButtonNotificationSon"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-5">
                    {{ $t("common.menu_notification_by_sound") }}
                  </label>
                </div>
                <div v-if="isNotEwg100" class="form-check mt-3 mr-3">
                  <input
                    id="checkbox-switch-6"
                    v-model="isButtonNotificationFlash"
                    class="form-check-input"
                    type="checkbox"
                
                  />
                  <label class="form-check-label" for="checkbox-switch-6">
                    {{ $t("common.menu_notification_by_flash") }}
                  </label>
                </div>                
                <p v-if="this.errorNotification">
                  {{
                    $t(
                      "alerttrigerring.menu_select_at_least_one_notification_mode"
                    )
                  }}
                </p>
              </div>
            </div>
            <!-- END: parametre range slider -->
          </div>
                 <!-- END: bloc parametres -->
        </div>
               <!-- BEGIN: mode d'alerte -->
        <div class="clearfix box mt-6">
          <!-- entete de parametre -->
          <div
            class="flex flex-col sm:flex-row  p-3 bg-red-300 border-gray-200 dark:border-dark-5 mt-6 rounded-t"
          >
            <h2 class="font-medium text-base  mr-auto">
              {{ $t("alerttrigerring.menu_cycle_destinataires_télésurveillance") }}
            </h2>
          </div>
          <!-- BEGUIN: bloc parametres -->
          <div>
            <!-- BEGUIN: parametre avec radio button -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <div>
                <div class="form-check">
                  <input
                    id="radiobutton3"
                    v-model="isButtonloudSpeakerPre"
                    class="form-check-input"
                    type="radio"
                    value="1"
                    name="radio-bidirectionnelle-pre"
                  />
                  <label class="form-check-label" for="radiobutton3">
                    {{ $t("alerttrigerring.menu_communication_bidirectionnelle") }}
                  </label>
                </div>
                <div class="form-check mt-3">
                  <input
                    id="radiobutton4"
                    v-model="isButtonloudSpeakerPre"
                    class="form-check-input"
                    type="radio"
                    value="2"
                    name="radio-unidirectionnelle-pre"
                  />
                  <label class="form-check-label" for="radiobutton4">
                    {{ $t("alerttrigerring.menu_communication_unidirectionnelle") }}
                  </label>
                </div>
              </div>
            </div>
            <!-- END: parametre avec radio button -->
          </div>
          <!-- END: bloc parametres -->
        </div>
        <!-- END: mode d'alerte -->

        <!-- BEGIN: notification postalerte -->
        <div v-if="showPreAlert" class="clearfix box mt-6">
          <!-- entete de parametre -->
          <div
            class="flex flex-raw sm:flex-row pt-3 p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div class="form-check w-full">

              <CustomSwitch
                v-model="isButtonSwitchPost"
                text="common.menu_post_alert"
                class="pr-3 show-code"
              /> 
          </div>
          </div>
          <!-- BEGUIN: bloc parametres -->
          <div>
            <!-- BEGUIN: parametre range slider -->
            <div v-if="isButtonSwitchPost==1"
            class="clearfix flex-row p-3 border-b border-gray-200">
            <div class="form-check mt-3 mr-3 ">
                  <input
                    id="checkbox-switch-7"
                    v-model="isButtonVibrationSeconde"
                    class="p-1 mt-1"
                    type="checkbox"
                    :disabled="true"
                  />
                  <label class="form-check-label" for="checkbox-switch-7">
                    {{ $t("common.menu_vibration_5_secondes") }}
                  </label>
                </div>
              <div class="flex flex-col">
                <div class="form-check mt-3 mr-3 ">
                  <input
                    id="checkbox-switch-8"
                    v-model="isButtonAffichagePost"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-8">
                    {{ $t("common.menu_affichage") }}
                  </label>
                </div>
                <div class="form-check mt-3 mr-3">
                  <input
                    id="checkbox-switch-9"
                    v-model="isButtonSonPost"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-9">
                    {{ $t("common.menu_son") }}
                  </label>
                </div>
                <div v-if="isNotEwg100" class="form-check mt-3 mr-3">
                  <input
                    id="checkbox-switch-10"
                    v-model="isButtonPostNotificationFlash"
                    class="form-check-input"
                    type="checkbox"
                  
                  />
                  <label class="form-check-label" for="checkbox-switch-10">
                    {{ $t("common.menu_notification_by_flash") }}
                  </label>
                </div>                
                  <!-- BEGUIN: parametre avec radio button -->
                  <div class="pt-3">
                    <div class="form-check">
                      <input
                        id="radiobutton5"
                        v-model="isButtonloudSpeakerPost"
                        class="form-check-input"
                        type="radio"
                        value="1"
                        name="radio-bidirectionnelle-post"
                      />
                      <label class="form-check-label" for="radiobutton5">
                        {{ $t("alerttrigerring.menu_communication_bidirectionnelle") }}
                      </label>
                    </div>
                    <div class="form-check mt-3">
                      <input
                        id="radiobutton6"
                        v-model="isButtonloudSpeakerPost"
                        class="form-check-input"
                        type="radio"
                        value="2"
                        name="radio-unidirectionnelle-post"
                      />
                      <label class="form-check-label" for="radiobutton6">
                        {{ $t("alerttrigerring.menu_communication_unidirectionnelle") }}
                      </label>
                    </div>
                  </div>
                  <!-- END: parametre avec radio button -->
                </div>
              </div>
             <!-- END: parametre range slider -->
            </div>
            <!-- END: bloc parametres -->
        </div>
          <!-- BEGIN: mode d'alerte -->
            <div class="clearfix box mt-6">
              <!-- entete de parametre -->
              <div
                class="flex flex-col sm:flex-row  p-3 bg-red-300 border-gray-200 dark:border-dark-5 mt-6 rounded-t"
              >
                <h2 class="font-medium text-base  mr-auto">
                  {{ $t("alerttrigerring.menu_message_alert") }}
                </h2>
              </div>
              <!-- BEGUIN: bloc parametres -->
              <div>
                <!-- BEGUIN: parametre avec radio button -->
                <div class="clearfix flex-row p-3 border-b border-gray-200">
                  <div>
                    <div class="form-check">
                      <input
                        id="radiobutton7"
                        v-model="isButtonAgression"
                        class="form-check-input"
                        type="radio"
                        value="1"
                        name="radio-demande-secours"
                      />
                      <label class="form-check-label" for="radiobutton7">
                        {{ $t("alerttrigerring.menu_demande_secours") }}
                      </label>
                    </div>
                    <div class="form-check mt-3">
                      <input
                        id="radiobutton8"
                        v-model="isButtonAgression"
                        class="form-check-input"
                        type="radio"
                        value="2"
                        name="radio-agression"
                      />
                      <label class="form-check-label" for="radiobutton8">
                        {{ $t("alerttrigerring.menu_agression") }}
                      </label>
                    </div>
                  </div>
                </div>
                <!-- END: parametre avec radio button -->
              </div>
              <!-- END: bloc parametres -->
            </div>
            <!-- END: mode d'alerte -->
          </div>
          <!-- END: groupe notification prealert boutons -->

       <!-- END: left column -->
    </div>
    <button
      type="submit"
      class="btn btn-primary mt-6"
      @click="onSaveButtonClicked"
    >
      {{ $t("common.menu_save") }}
    </button>
    <!-- BEGIN: notification type notification Content -->
    <div id="notification-type-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">
          {{ $t("alerttrigerring.menu_select_at_least_one_notification_mode") }}
        </div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <!-- END: notification type  notification Content-->
  </div>
</template>

<script>
import {
  getParameterPTIValue,
  getParameterPTIMax,
  getParameterPTIMin,
  setParameterPTIValue
} from "../../dao/parametre-pti";
import Toastify from "toastify-js";
import { useStore } from "@/store";

const PRE_ALERTE = "common.menu_notification_prealert";
const POST_ALERTE = "common.menu_post_alert";

export default {
  props: ["type"],

  data() {
    return {
      isPecActivated: false,
      activationMode: -1,
      showTriggerMode: false,
      showAlertType: false,
      showPreAlert: false,
      showLongPress: false,
      isSosButtonDiscreetAlert: 0,

      isButtonSwitchPre: 0,
      isButtonSwitchPost: 0,
      isButtonAffichagePost:false,
      isButtonloudSpeakerPre: undefined,
      isButtonloudSpeakerPost: undefined,
      isButtonAgression: undefined,
      isButtonSonPost:false ,
      isButtonPostNotificationFlash:false,
     

      isButtonCommunicationAmplifie: false,
      isButtonNotificationSon: false,
      isButtonVibrationSeconde:true,
      isButtonNotificationVibration: false,
      isButtonNotificationFlash: false,
      sosNotificationDurationRange: 5,
      sosNotificationDurationMin: 0,
      sosNotificationDurationMax: 0,
      actualRange: 0,
      minRange: 0,
      maxRange: 0,
      errorNotification: false,
      pageTitle: "values",
      isNotEwg100:false,
      switches: [
         {

          value: undefined,
          text: PRE_ALERTE,
          disabled: false,
        },
        {
          value: undefined,
          text: POST_ALERTE,
          disabled: false,
        },
        ],
      bottomSwitchesErrorMsg: '',
    };
  },

  mounted() {
    this.updateUI();
  },
  methods: {


    updateUI() {

      if (useStore().state.emeritValues.deviceInfo.deviceModelName !== "E-WG100"){
          this.isNotEwg100 = true
        } else {
          this.isNotEwg100 = false          
        }

      switch (this.type) {
        case "sos-button-2":
          this.initButton2();
      if (useStore().state.emeritValues.deviceInfo.deviceModelName == "E-WG100"){
          this.pageTitle = this.$t("alerttrigerring.menu_sos_button_settings")  
          this.showLongPress = true;
          } else if(useStore().state.emeritValues.deviceInfo.deviceModelName == "RG170"){  
          this.pageTitle = this.$t("alerttrigerring.menu_front_programmable_button_settings")  
          this.showLongPress = true;              
          } else {
          this.pageTitle = this.$t("alerttrigerring.menu_power_button_settings")  
          this.showLongPress = false;
          };
          break;

        case "sos-button-1":
          this.initButton1();
      if (useStore().state.emeritValues.deviceInfo.deviceModelName == "E-WG100"){
          this.pageTitle = this.$t("alerttrigerring.menu_power_button_settings")
          this.showLongPress = false;
          } else if(useStore().state.emeritValues.deviceInfo.deviceModelName == "RG170"){  
          this.pageTitle = this.$t("alerttrigerring.menu_side_programmable_button_settings");
          this.showLongPress = true;
          } else if(useStore().state.emeritValues.deviceInfo.deviceModelName == "RG650" || useStore().state.emeritValues.deviceInfo.deviceModelName == "RG655"  ){  
          this.pageTitle = this.$t("alerttrigerring.menu_side_yellow_button_settings")
          this.showLongPress = true;                
          } else {  
          this.pageTitle = this.$t("alerttrigerring.menu_sos_button_and_programmable_button_settings")
          this.showLongPress = true;          
          }
          break;
      }
    },

    initButton1() {
      if (getParameterPTIValue("SOSButton1Mode") == 0) {
        this.activationMode = 2;
      } else {
        this.activationMode = 1;
      }
        this.showPreAlert = true;
        if(getParameterPTIValue("SOSButton1TempsNotification" < 3)){
          this.sosNotificationDurationRange = 3;
        }else{
        this.sosNotificationDurationRange = getParameterPTIValue(
          "SOSButton1TempsNotification" 
        );}
        this.sosNotificationDurationMin = getParameterPTIMin(
          "SOSButton1TempsNotification"
        );
        this.sosNotificationDurationMax = getParameterPTIMax(
          "SOSButton1TempsNotification"
        );          

     

        
        if (getParameterPTIValue("isLoudSpeakerPreAlertOnOff") == 1) {
        this.isButtonloudSpeakerPre = 1;
        }else if (getParameterPTIValue("isLoudSpeakerPreAlertOnOff") == 0) {
        this.isButtonloudSpeakerPre = 2;
        }      
        if (getParameterPTIValue("isLoudSpeakerPostAlertOnOff") == 1) {
        this.isButtonloudSpeakerPost = 1;
        }else if (getParameterPTIValue("isLoudSpeakerPostAlertOnOff") == 0) {
        this.isButtonloudSpeakerPost = 2;
        }

        if (getParameterPTIValue("isAgressionOnOff") == 1) {
        this.isButtonAgression = 2;
        }else if (getParameterPTIValue("isAgressionOnOff") == 0) {
        this.isButtonAgression = 1;
        }


        this.isButtonNotificationSon = this.intToBoolean(getParameterPTIValue("SOSButton1NotificationSon"));
        this.isButtonNotificationVibration = this.intToBoolean(getParameterPTIValue("SOSButton1NotificationVibration"));

        this.isButtonSwitchPost = this.intToBoolean(getParameterPTIValue("isPostSwitchOnOff"))
        this.isButtonSwitchPre = this.intToBoolean(getParameterPTIValue("isPreSwitchOnOff"))
        
        this.isButtonAffichagePost = this.intToBoolean(getParameterPTIValue("isDisplayPostAlertOnOff"))
        this.isButtonSonPost = this.intToBoolean(getParameterPTIValue("isSoundPostAlertOnOff"))
        


        
        if (this.isNotEwg100){
          this.isButtonNotificationFlash = this.intToBoolean(getParameterPTIValue("SOSButton1NotificationFlash"));
          this.isButtonPostNotificationFlash = this.intToBoolean(getParameterPTIValue("isFlashPostAlertOnOff"))
        }
    },

    initButton2() {
      if (getParameterPTIValue("SOSButton2Mode") == 0) {
        this.activationMode = 2;
      } else {
        this.activationMode = 1;
      }
        this.showPreAlert = true;
        if(getParameterPTIValue("SOSButton2TempsNotification")< 3){
          this.sosNotificationDurationRange = 3;
        }else{
        this.sosNotificationDurationRange = getParameterPTIValue(
          "SOSButton2TempsNotification" 
        );}
        this.sosNotificationDurationMin = getParameterPTIMin(
          "SOSButton2TempsNotification"
        );
        this.sosNotificationDurationMax = getParameterPTIMax(
          "SOSButton2TempsNotification"
        );

        if (getParameterPTIValue("isLoudSpeakerPostAlertSos") == 1) {
        this.isButtonloudSpeakerPost = 1;
        }else if (getParameterPTIValue("isLoudSpeakerPostAlertSos") == 0) {
        this.isButtonloudSpeakerPost = 2;
        }
        if (getParameterPTIValue("isLoudSpeakerPreAlertSos") == 1) {
        this.isButtonloudSpeakerPre = 1;
        }else if (getParameterPTIValue("isLoudSpeakerPreAlertSos") == 0) {
        this.isButtonloudSpeakerPre = 2;
        }
        if (getParameterPTIValue("isAgressionSos") == 1) {
        this.isButtonAgression = 2;
        }else if (getParameterPTIValue("isAgressionSos") == 0) {
        this.isButtonAgression = 1;
        }

        this.isButtonNotificationSon = this.intToBoolean(getParameterPTIValue("SOSButton2NotificationSon"));
        this.isButtonNotificationVibration = this.intToBoolean(getParameterPTIValue("SOSButton2NotificationVibration"));

        this.isButtonSwitchPost = this.intToBoolean(getParameterPTIValue("isPostSwitchSos"))
        this.isButtonSwitchPre = this.intToBoolean(getParameterPTIValue("isPreSwitchSos"))
        this.isButtonAffichagePost = this.intToBoolean(getParameterPTIValue("isDisplayPostAlertSos"))
        this.isButtonSonPost = this.intToBoolean(getParameterPTIValue("isSoundPostAlertSos"))
      

        if (this.isNotEwg100){
          this.isButtonNotificationFlash = this.intToBoolean(getParameterPTIValue("SOSButton2NotificationFlash"));  
          this.isButtonPostNotificationFlash = this.intToBoolean(getParameterPTIValue("isFlashPostAlertSos"))
        }
    },

    onSaveButtonClicked() {
      switch (this.type) {
        case "sos-button-2":
          if (
            this.isButtonloudSpeakerPre &&
            this.isButtonSwitchPre == true &&
            this.isButtonNotificationSon == false &&
            this.isButtonNotificationVibration == false &&
            this.isButtonNotificationFlash == false
          ) {
            this.errorNotification = true;
          } else {
            this.errorNotification = false;
            this.saveButton2();
          }
          break;

        case "sos-button-1":
          if (
            this.isButtonloudSpeakerPre &&
            this.isButtonSwitchPre == true &&
            this.isButtonNotificationSon == false &&
            this.isButtonNotificationVibration == false &&
            this.isButtonNotificationFlash == false
          ) {
            this.errorNotification = true;
          } else {
            this.errorNotification = false;
            this.saveButton1();
          }
          break;
      }
    },
    saveButton1() {
      if(this.isButtonloudSpeakerPre == 2){
        setParameterPTIValue("isLoudSpeakerPreAlertOnOff", 0);
      }else  if(this.isButtonloudSpeakerPre == 1){
        setParameterPTIValue("isLoudSpeakerPreAlertOnOff", 1);
      }
      if(this.isButtonloudSpeakerPost == 2){
        setParameterPTIValue("isLoudSpeakerPostAlertOnOff", 0);
      }else  if(this.isButtonloudSpeakerPost == 1){
        setParameterPTIValue("isLoudSpeakerPostAlertOnOff", 1);
      }

      if(this.isButtonAgression == 2){
        setParameterPTIValue("isAgressionOnOff", 1);
      }else  if(this.isButtonAgression == 1){
        setParameterPTIValue("isAgressionOnOff", 0);
      }


      setParameterPTIValue("SOSButton1DiscreetAlert", 0);
      setParameterPTIValue("SOSButton1TempsNotification",this.sosNotificationDurationRange);

      setParameterPTIValue("isPostSwitchOnOff", this.booleanToInt(this.isButtonSwitchPost));
      setParameterPTIValue("isPreSwitchOnOff", this.booleanToInt(this.isButtonSwitchPre));
      setParameterPTIValue("SOSButton1NotificationFlash", this.booleanToInt(this.isButtonNotificationFlash));
      setParameterPTIValue("SOSButton1NotificationSon", this.booleanToInt(this.isButtonNotificationSon));
      setParameterPTIValue("SOSButton1NotificationVibration", this.booleanToInt(this.isButtonNotificationVibration));
      
      setParameterPTIValue("isDisplayPostAlertOnOff", this.booleanToInt(this.isButtonAffichagePost));
      setParameterPTIValue("isSoundPostAlertOnOff", this.booleanToInt(this.isButtonSonPost));
      setParameterPTIValue("isFlashPostAlertOnOff", this.booleanToInt(this.isButtonPostNotificationFlash));

      if (this.activationMode == 1) {
        setParameterPTIValue("SOSButton1Mode", 1);
      } else {
        setParameterPTIValue("SOSButton1Mode", 0);
      }
      this.$router.push("/");
    },

    saveButton2() {
      if(this.isButtonloudSpeakerPre == 2){
        setParameterPTIValue("isLoudSpeakerPreAlertSos", 0);
      }else  if(this.isButtonloudSpeakerPre == 1){
        setParameterPTIValue("isLoudSpeakerPreAlertSos", 1);
      }

      if(this.isButtonloudSpeakerPost == 2){
        setParameterPTIValue("isLoudSpeakerPostAlertSos", 0);
      }else  if(this.isButtonloudSpeakerPost == 1){
        setParameterPTIValue("isLoudSpeakerPostAlertSos", 1);
      }
      if(this.isButtonAgression == 2){
        setParameterPTIValue("isAgressionSos", 1);
      }else  if(this.isButtonAgression == 1){
        setParameterPTIValue("isAgressionSos", 0);
      }

      setParameterPTIValue("SOSButton2DiscreetAlert", 0);
      setParameterPTIValue("SOSButton2TempsNotification",this.sosNotificationDurationRange);
      
      setParameterPTIValue("isPostSwitchSos", this.booleanToInt(this.isButtonSwitchPost));
      setParameterPTIValue("isPreSwitchSos", this.booleanToInt(this.isButtonSwitchPre));
      setParameterPTIValue("SOSButton2NotificationSon", this.booleanToInt(this.isButtonNotificationSon));
      setParameterPTIValue("SOSButton2NotificationVibration", this.booleanToInt(this.isButtonNotificationVibration));
      setParameterPTIValue("SOSButton2NotificationFlash", this.booleanToInt(this.isButtonNotificationFlash));
   

      setParameterPTIValue("isDisplayPostAlertSos", this.booleanToInt(this.isButtonAffichagePost));
      setParameterPTIValue("isSoundPostAlertSos", this.booleanToInt(this.isButtonSonPost));
      setParameterPTIValue("isFlashPostAlertSos", this.booleanToInt(this.isButtonPostNotificationFlash));



      if (this.activationMode == 1) {
        setParameterPTIValue("SOSButton2Mode", 1);
      } else {
        setParameterPTIValue("SOSButton2Mode", 0);
      }
      this.$router.push("/");
    },

    booleanToInt(value) {
      if (value == true) {
        return "1";
      }
      return "0";
    },

    intToBoolean(value) {
      if (value === "1") {
        return true;
      }
      return false;
    },    
    
  }
};
</script>
