<template>
  <!-- BEGIN: BleBeaconList local component-->
  <div>
    <Pagination
      v-if="beaconList.length"
      :current-page="page"
      :total-pages="getTotalPages"
      @begin="page = 1"
      @end="page = getTotalPages"
      @decrement="page = page === 1 ? 1 : --page"
      @increment="page = page === getTotalPages ? page : ++page"
    />
    <CustomTable
      v-if="beaconList.length"
      :headers="getHeaders"
      :elems-list="getBleBeaconPage"
      :screen-percent-width="10"
      :showing-errors="true"
      list-element-component-name="ListElement"
      green-button-text="common.menu_modify"
      red-button-text="common.menu_remove"
      green-button-bootstrap-target="#modal-ble-beacon-modify"
      red-button-bootstrap-target="#modal-alert-confirm"
      @element-green-button-clicked="$emit('modify-beacon', $event + (page - 1) * 10)"
      @element-red-button-clicked="$emit('remove-beacon', $event + (page - 1) * 10)"
    />
  </div>
  <!-- END: BleBeaconList local component-->
</template>

<script>
import { defineComponent } from 'vue'
import { 
  BBE9_UUID,
  LOC_EX_UUID,
  MODEL_BBE9,
  MODEL_LOC_EX,
} from './utils'


const COMPANY_ID = 0;
const BEACON_ID = 1;
const CODE_ZONE = 2;
const DESC = 3;
const LEVEL = 4;
const TYPE = 5;

const NB_ITEMS = 10;

const MENU_COMPANY_ID = "locationindoorble.menu_beacon_company_id";
const MENU_BEACON_ID = "locationindoorble.menu_beacon_id";
const MENU_CODE_ZONE = "locationindoorble.menu_beacon_code_zone";
const MENU_DESC = "locationindoorble.menu_beacon_description";
const MENU_LEVEL = "locationindoorble.menu_beacon_level";
const MENU_TYPE = "locationindoorble.menu_beacon_type";

const ELEM_LIST_TYPES = [
  "ZONE",
  "LEVEL",
  "CONFIRM",
]

const EV_MODIFY_BEACON = 'modify-beacon';
const EV_REMOVE_BEACON = 'remove-beacon';


export default defineComponent({

  props: {
    beaconList: {
      type: Array,
      required: true
    },
    isLevelActivated: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    EV_MODIFY_BEACON,
    EV_REMOVE_BEACON,
  ],

  data() {
    return {
      page: 1,
    }
  },

  computed: {
    getBleBeaconPage() {
      const startIndex = (this.page - 1) * NB_ITEMS;
      const length = startIndex + NB_ITEMS < this.beaconList.length ?
          NB_ITEMS : this.beaconList.length - startIndex;
      let beaconPage = new Array(length);
      for (let i = 0; i < length; ++i)
        beaconPage[i] = this.formatBeaconInfo(this.beaconList[i + startIndex]);
      return beaconPage;
    },

    getHeaders() {
      if (this.isLevelActivated)
        return [MENU_COMPANY_ID, MENU_BEACON_ID, MENU_CODE_ZONE, MENU_DESC, MENU_LEVEL, MENU_TYPE];
      return [MENU_COMPANY_ID, MENU_BEACON_ID, MENU_CODE_ZONE, MENU_DESC];
    },

    getTotalPages() {
      return Math.ceil(this.beaconList.length / NB_ITEMS);
    }
  },

  watch: {

    /**
     * Update the page number dynamically when ble beacons are added / removed.
     */
    'beaconList.length': {
      handler: function updatePageNumber(newVal, oldVal) {
        if (this.page > this.getTotalPages)
          --this.page;
        else if (newVal > oldVal)
          this.page = this.getTotalPages;
      }
    },
  },

  methods: {
    formatBeaconInfo(beacon) {
      const len = this.isLevelActivated ? beacon.length : beacon.length - 2;
      let newBeaconFields = new Array(len);
      for (let i = 0; i < len; ++i) {
        if (i === COMPANY_ID && beacon[i].valueOf() === BBE9_UUID)
          newBeaconFields[i] = MODEL_BBE9;
        else if (i === COMPANY_ID && beacon[i].valueOf() === LOC_EX_UUID)
          newBeaconFields[i] = MODEL_LOC_EX;
        else if (i === TYPE)
          newBeaconFields[i] = ELEM_LIST_TYPES[Number(beacon[i])];
        else if (i === LEVEL)
          newBeaconFields[i] = Number(beacon[TYPE]) === 1 ? '---' : beacon[i];
        else
          newBeaconFields[i] = beacon[i];
      }
      return newBeaconFields;
    },

  }

})
</script>
