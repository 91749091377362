<template>
  <!-- BEGIN: AddBleBeacon local component-->
  <div class="intro-y col-span-12 lg:col-span-6 lg:col-start-7 2xl:col-span-5 2xl:col-start-6">
    <div class="clearfix box mb-6">
      <BoxTitle
        color="bg-red-300"
        title="locationindoorble.menu_add_manually_ble_beacons"
      />
      <div class="clearfix p-3 border-b border-gray-200">
        <div class="grid grid-cols-10 gap-3">
          <CustomSelect
            v-model="selectFields[0].value"
            :title="selectFields[0].title"
            :options="selectFields[0].options"
            :class="selectFields[0].class"
            :help-locale="getBeaconHelpLocale"
          />
          <CustomSelect
            v-if="isLevelActivated"
            v-model="selectFields[1].value"
            :title="selectFields[1].title"
            :options="selectFields[1].options"
            :class="selectFields[1].class"
            help-locale="menu_level_types_help"
          />
        </div>
        <div>
          <AddBbe9InputFields
            v-if="selectFields[0].value.valueOf() === 'e-BBE9'"
            :hide-level-field="hideLevelField"
            :beacon-list="beaconList"
            @add-bbe9-failed="$refs.toastAddBeaconFailed.trigger()"
            @add-bbe9-success="emitAddBeaconSuccess"
          />
          <AddLocExInputFields
            v-if="selectFields[0].value.valueOf() === 'LOC-EX 01'"
            :hide-level-field="hideLevelField"
            :beacon-list="beaconList"
            @add-loc-ex-failed="$refs.toastAddBeaconFailed.trigger()"
            @add-loc-ex-success="emitAddBeaconSuccess"
          />
          <AddIbeaconInputFields
            v-if="selectFields[0].value.valueOf() === 'iBeacon'"
            :hide-level-field="hideLevelField"
            :beacon-list="beaconList"
            @add-ibeacon-failed="$refs.toastAddBeaconFailed.trigger()"
            @add-ibeacon-success="emitAddBeaconSuccess"
          />
          <AddEddystoneInputFields
            v-if="selectFields[0].value.valueOf() === 'Eddystone'"
            :hide-level-field="hideLevelField"
            :beacon-list="beaconList"
            @add-eddystone-failed="$refs.toastAddBeaconFailed.trigger()"
            @add-eddystone-success="emitAddBeaconSuccess"
          />
        </div>
      </div>
    </div>
    <CustomToast
      ref="toastAddBeaconSuccess"
      toast-id="toast-add-beacon-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="locationindoorble.menu_add_beacon_success"
    />
    <CustomToast
      ref="toastAddBeaconFailed"
      toast-id="toast-add-beacon-failed"
      icon-component-name="XCircleIcon"
      icon-component-class="text-theme-6"
      text="locationindoorble.menu_add_beacon_failed"
    />
  </div>
  <!-- END: AddBleBeacon local component-->
</template>

<script>
import { defineComponent } from 'vue'
import { 
  formatLevel, 
  MODEL_BBE9,
  MODEL_LOC_EX,
  MODEL_IBEACON,
  MODEL_EDDY
} from './utils'
import AddBbe9InputFields from './lc-add-bbe9-input-fields'
import AddLocExInputFields from './lc-add-loc-ex-input-fields'
import AddIbeaconInputFields from './lc-add-ibeacon-input-fields'
import AddEddystoneInputFields from './lc-add-eddystone-input-fields'


const SELECT_MODEL = 0;
const SELECT_TYPE = 1;

const COMPANY_ID = 0;
const BEACON_ID = 1;
const CODE_ZONE = 2;
const DESC = 3;
const LEVEL = 4;
const TYPE = 5;

const TYPE_ZONE = "ZONE";
const TYPE_LEVEL = "LEVEL";
const TYPE_CONFIRM = "CONFIRM";

const MODEL_TITLE = "locationindoorble.menu_beacon_model";
const TYPE_TITLE = "locationindoorble.menu_beacon_type";

const MODEL_CLASS = "col-span-10 lg:col-span-5 lg:col-start-1 2xl:col-span-4 2xl:col-start-1"; 
const TYPE_CLASS = "col-span-10 lg:col-span-3 lg:col-start-6 2xl:col-span-2 2xl:col-start-5";

const I18_BBE9_HELP_LINK = "menu_bbe9_help";
const I18_LOC_EX_HELP_LINK = "menu_locex_help";

const EV_ADD_BEACON_SUCCESS = 'addBeaconSuccess';


export default defineComponent({

  components: {
    AddBbe9InputFields,
    AddLocExInputFields,
    AddIbeaconInputFields,
    AddEddystoneInputFields,
  },

  props: {
    isLevelActivated: {
      type: Boolean,
      required: true,
    },
    beaconList: {
      type: Array,
      required: true,
    },
  },

  emits: [EV_ADD_BEACON_SUCCESS],

  data() {
    return {
      selectFields: [
        { 
          value: MODEL_BBE9, 
          options: [MODEL_BBE9, MODEL_LOC_EX, MODEL_IBEACON, MODEL_EDDY], 
          title: MODEL_TITLE, 
          class: MODEL_CLASS, 
        },
        { 
          value: TYPE_ZONE, 
          options: [TYPE_ZONE, TYPE_LEVEL, TYPE_CONFIRM], 
          title: TYPE_TITLE, 
          class: TYPE_CLASS, 
        },
      ],
    }
  },

  computed: {
    hideLevelField() {
      return !this.isLevelActivated 
          || this.selectFields[SELECT_TYPE].value.valueOf() === TYPE_LEVEL;
    },

    getBeaconHelpLocale() {
      if (this.selectFields[SELECT_MODEL].value.valueOf() === MODEL_BBE9)
        return I18_BBE9_HELP_LINK;
      else if (this.selectFields[SELECT_MODEL].value.valueOf() === MODEL_LOC_EX)
        return I18_LOC_EX_HELP_LINK;
      return undefined;
    },
  },

  watch: {
    isLevelActivated() {
      this.selectFields[SELECT_TYPE].value = TYPE_ZONE;
    }
  },

  methods: {

    emitAddBeaconSuccess(beacon) {
      let type = '0';
      if (this.isLevelActivated) {
        let typeFields = this.selectFields[SELECT_TYPE];
        for (let i = 0; i < typeFields.options.length; ++i)
          if (typeFields.value.valueOf() === typeFields.options[i].valueOf())
            type = String(i);
      }
      beacon.push(type);
      beacon[LEVEL] = formatLevel(beacon[LEVEL]);
      this.$emit(EV_ADD_BEACON_SUCCESS, beacon);
      this.$refs.toastAddBeaconSuccess.trigger();
    },

  }
})
</script>
