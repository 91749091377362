<template>
  <!-- BEGIN: SipSettings local component -->
  <BoxOfXTextInputs 
    ref="sipSettings"
    :input-fields-params="sipFields"
    box-title-color="bg-indigo-300"
    title="sipconfiguration.menu_sip_need_configuration"
  />
  <!-- END: SipSettings local component -->
</template>

<script>
import { defineComponent } from 'vue'
import { 
  getSipAccount, 
  updateSipAccountInfo,
  createSipAccount
} from '../../../dao/sip-dao'


const I18_TITLE_ACCOUNT_NAME = 'sipconfiguration.menu_account_name';
const I18_TITLE_USER_NAME = 'sipconfiguration.menu_user_name';
const I18_TITLE_PASSWORD = 'sipconfiguration.menu_user_password';
const I18_TITLE_SERVER_URL = 'sipconfiguration.menu_server_url';

const I18_ERR_FIELD_CANNOT_BE_EMPTY = "common.menu_field_cannot_be_empty";


export default defineComponent({
  
	data() {
		return {
      sipFields: [
        { 
          title: I18_TITLE_ACCOUNT_NAME, 
          value: '', 
          isPasswordField: false,
          errorMsg: '' 
        },
        { 
          title: I18_TITLE_USER_NAME, 
          value: '', 
          isPasswordField: false,
          errorMsg: '' 
        },
        { 
          title: I18_TITLE_PASSWORD, 
          value: '', 
          isPasswordField: true,
          errorMsg: '' 
        },
        { 
          title: I18_TITLE_SERVER_URL, 
          value: '', 
          isPasswordField: false,
          errorMsg: '' 
        },
      ],
		}
	},

  created() {
    const sipTable = getSipAccount();
    if (sipTable != undefined)
      for (let i = 0; i < this.sipFields.length; ++i)
        this.sipFields[i].value = sipTable.values[0][i + 1];
  },

  methods: {
    check() {
      const sipFieldsValue = this.$refs.sipSettings.getData();
      let isCorrect = true;
      for (let i = 0; i < sipFieldsValue.length; ++i) {
        if (sipFieldsValue[i] === '') {
          isCorrect = false;
          this.sipFields[i].errorMsg = I18_ERR_FIELD_CANNOT_BE_EMPTY;
        }
        else
          this.sipFields[i].errorMsg = '';
      }
      return isCorrect;
    },

    save() {
      const sipData = this.$refs.sipSettings.getData()
      if (getSipAccount() == undefined)
        createSipAccount(sipData);
      else
        updateSipAccountInfo(sipData);
    }
  }
})
</script>
