<template>
  <div class="mt-6">
    <BoxTitle
      title="configuration.menu_deactivation_of_alert_system_by_the_user"
      color="bg-gray-400"
      help-locale="menu_deactivate_alert_system"
    />
    <div class="clearfix box">
      <div class="clearfix pt-3 border-b border-gray-200"> 
        <FieldXVerticalCheckBoxes 
          v-model="checkbox_1.value" 
          :descriptions="checkbox_1.description" 
          :ids="checkbox_1.id" 
          @change="updateCheckbox1" 
        /> 
        <FieldXVerticalCheckBoxes 
          v-if="checkbox_1.value[0]" 
          v-model="checkbox_3.value" 
          :descriptions="checkbox_3.description" 
          :ids="checkbox_3.id" 
          class="ml-7" 
          @change="updateCheckbox3" 
        /> 
        <FieldXVerticalCheckBoxes 
          v-model="checkbox_2.value" 
          :descriptions="checkbox_2.description" 
          :ids="checkbox_2.id" 
          :class="getCustomClass"
          @change="updateCheckbox2" 
        /> 
        <CustomRangeInput
          v-if="checkbox_2.value[0]"
          v-model="deactivationTime"
          title="configuration.menu_duration_of_deactivation"
          :min-value="5"
          :max-value="30"
          :step-value="5"
          unit-description="common.menu_minutes"
        />
      </div> 
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { 
  getBoolPtiValue, 
  getNumberPtiValue, 
  setNumberPtiValue 
} from '../../../dao/parametre-pti';


const I18_CHECKBOX_TITLE_FREELY_DISABLE = "configuration.menu_freely_disable_enable_alert_system";
const I18_CHECKBOX_TITLE_TEMPORARILY_DISABLE = "configuration.menu_temporarily_disable_enable_alert_system";
const I18_CHECKBOX_TITLE_FORCE_REACTIVATED_ON_BOOT = "configuration.menu_force_alert_reactivated_system";


const CHECKBOX_ID1 = 'checkbox1';
const CHECKBOX_ID2 = 'checkbox2';
const CHECKBOX_ID3 = 'checkbox3';

const PADDING_CLASS = "pb-3";

// const FREELY_DISABLE_INDEX = 0;
// const TEMPORARILY_DISABLE_INDEX = 1;
// const FORCE_REACTIVATED_ON_BOOT_INDEX = 2;

const PTI_PARAM_CHECKBOX = [
  "ProtectionPermanentWantedByUserAuthorization",
  "ProtectionTemporaryWantedByUserAuthorization",
  "isSystemReactivatedOnBoot"
];
const PTI_PARAM_DEACTIVATION_TIME = "ProtectionTemporaryWantedByUserTime";


export default defineComponent({

  data() {
    return {
      deactivationTime: undefined,
      // prevValues: [
      //   false,
      //   false,
      // ],
      // values: [
      //   false, 
      //   false
      // ],
      // descs: [
      //   I18_CHECKBOX_TITLE_FREELY_DISABLE,
      //   I18_CHECKBOX_TITLE_TEMPORARILY_DISABLE,
      // ],
      // ids: [
      //   CHECKBOX_ID1,
      //   CHECKBOX_ID2,
      // ],

      checkbox_1: { 
        value: [
          false
        ],
        description: [
          I18_CHECKBOX_TITLE_FREELY_DISABLE
        ],
        id: [
          CHECKBOX_ID1
        ],
      },

      checkbox_2: {
        value: [
          false
        ],
        description: [
          I18_CHECKBOX_TITLE_TEMPORARILY_DISABLE
        ],
        id: [
          CHECKBOX_ID2
        ],
      },

      checkbox_3: {
        value: [
          false
        ],
        description: [
          I18_CHECKBOX_TITLE_FORCE_REACTIVATED_ON_BOOT
        ],
        id: [
          CHECKBOX_ID3
        ],
      },
    }
  },

  computed: {
    getCustomClass() {
      if (this.checkbox_2.value[0])
        return "";
      return PADDING_CLASS;
    }
  },

  watch: {
    deactivationTime(newVal) {
      setNumberPtiValue(PTI_PARAM_DEACTIVATION_TIME, newVal);
    },

    // values: {
    //   deep: true,
    //   handler: function saveValues(newVal) {
    //     if (newVal[FREELY_DISABLE_INDEX] && newVal[TEMPORARILY_DISABLE_INDEX])
    //       this.uncheckCheckBoxes();
    //     for (let i = 0; i < PTI_PARAM_CHECKBOX.length; ++i) {
    //       setNumberPtiValue(PTI_PARAM_CHECKBOX[i], newVal[i]);
    //       this.prevValues[i] = newVal[i];
    //     }
      // }
    // }
  },

  created() {
    // for (let i = 0; i < PTI_PARAM_CHECKBOX.length; ++i)
      // this.values[i] = getBoolPtiValue(PTI_PARAM_CHECKBOX[i]);
    this.deactivationTime = getNumberPtiValue(PTI_PARAM_DEACTIVATION_TIME);
    this.checkbox_1.value[0] = getBoolPtiValue(PTI_PARAM_CHECKBOX[0]);
    this.checkbox_2.value[0] = getBoolPtiValue(PTI_PARAM_CHECKBOX[1]);
    this.checkbox_3.value[0] = getBoolPtiValue(PTI_PARAM_CHECKBOX[2]);
  },

  methods: {

    updateCheckbox1(){ 
      if(this.checkbox_2.value[0])
        this.checkbox_2.value[0] = false; 
      if(this.checkbox_1.value[0] === false) 
        this.checkbox_3.value[0] = false; 
      setNumberPtiValue(PTI_PARAM_CHECKBOX[0], this.checkbox_1.value[0]); 
      setNumberPtiValue(PTI_PARAM_CHECKBOX[1], this.checkbox_2.value[0]); 
      setNumberPtiValue(PTI_PARAM_CHECKBOX[2], this.checkbox_3.value[0]); 
    },

    updateCheckbox2(){ 
      if(this.checkbox_1.value[0]){ 
        this.checkbox_1.value[0] = false; 
        this.checkbox_3.value[0] = false; 
      } 
      setNumberPtiValue(PTI_PARAM_CHECKBOX[0], this.checkbox_1.value[0]); 
      setNumberPtiValue(PTI_PARAM_CHECKBOX[1], this.checkbox_2.value[0]); 
      setNumberPtiValue(PTI_PARAM_CHECKBOX[2], this.checkbox_3.value[0]); 
    }, 
 
    updateCheckbox3(){ 
      setNumberPtiValue(PTI_PARAM_CHECKBOX[0], this.checkbox_1.value[0]); 
      setNumberPtiValue(PTI_PARAM_CHECKBOX[1], this.checkbox_2.value[0]); 
      setNumberPtiValue(PTI_PARAM_CHECKBOX[2], this.checkbox_3.value[0]); 
    }, 

    // uncheckCheckBoxes() {
    //   if (this.prevValues[FREELY_DISABLE_INDEX] != this.values[FREELY_DISABLE_INDEX])
    //     this.values[TEMPORARILY_DISABLE_INDEX] = false;
    //   else
    //     this.values[FREELY_DISABLE_INDEX] = false;
    // },

    // For future use if needed
    check() {
      return 0;
    }
  }
  
})
</script>