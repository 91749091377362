<template>
  <!-- BEGIN: RecipientsSettings local component -->
  <div class="intro-y grid grid-cols-10 pt-6">
    <div class="lg:col-span-5 xl:col-span-4 col-span-10">
      <BoxTitle
        title="internalrecipients.menu_settings"
        color="bg-red-300"
      />
      <div class="clearfix box p-3">
        <div class="font-bold text-theme-0">
          {{ $t("internalrecipients.menu_scenario_is") + getScenarioType }}
        </div>
        <div class="font-bold mt-2 text-theme-0">
          {{ $t("internalrecipients.menu_nb_recipients_saved") + recipientList.length }}
        </div>
        <div class="flex mt-3">
          <div class="pr-3">
            <ButtonTargetModal
              :target-id="'#' + modalAddModifyRecipientId"
              text="internalrecipients.menu_add_recipient"
              custom-class="btn btn-primary"
              @click="$emit('add-recipient')"
            />
          </div>
          <div>
            <ButtonTargetModal
              v-if="recipientList.length"
              :target-id="'#' + modalAlertConfirmId"
              custom-class="btn btn-danger"
              text="common.menu_remove_everything"
              @click="$emit('remove-all-recipients')"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="recipientList.length"
      class="mt-3 lg:mt-8 lg:ml-6 lg:col-span-5 lg:col-start-6 xl:col-span-4 xl:col-start-5 col-span-10"
    >
      <div 
        v-for="(icon, index) in getIconsDescription"
        :key="index"
        class="flex mt-1"
      >
        <component 
          :is="icon.name"
          class="mr-3" 
        />
        <div class="mt-2">
          {{ $t(icon.desc) }}
        </div>
      </div>
    </div>
  </div>
  <!-- END: RecipientsSettings local component-->
</template>

<script>
import { defineComponent } from 'vue'
import { getBoolPtiValue } from '../../../dao/parametre-pti';


const I18_SCENARIO_TYPE = [
  "internalrecipients.menu_sms_and_calls_cycle",
  "internalrecipients.menu_sms_then_calls_cycle",
  "internalrecipients.menu_sms_with_acknowledgment_then_call",
  "internalrecipients.menu_sip_scenario"
]

const PARAM_PTI_SCENARIO_TYPE = [
  "TraitementSmsAppelsCascade",
  "TraitementSmsGroupeAppelEnCascade",
  "TraitementSmsPriseEnCompteAppel"
]

const I18_ICON_PHONE_DESC = "internalrecipients.menu_icon_phone_desc";
const I18_ICON_SMS_DESC = "internalrecipients.menu_icon_sms_desc";
const I18_ICON_WATCH_DESC = "internalrecipients.menu_icon_watch_desc";
const I18_ICON_SIP_DESC = "internalrecipients.menu_icon_sip_desc";

const ICON_PHONE = "PhoneCallIcon";
const ICON_SMS = "MessageSquareIcon";
const ICON_WATCH = "Ewg100WatchIcon";
const ICON_SIP = "SipIcon";


export default defineComponent({

  props: {
    recipientList: {
      type: Array,
      required: true,
    },
    iconsRenderedList: {
      type: Array,
      required: true,
    },
    isSipActivated: {
      type: Boolean,
      required: true,
    },
    modalAddModifyRecipientId: {
      type: String,
      required: true,
    },
    modalAlertConfirmId: {
      type: String,
      required: true,
    },
  },

  emits: ['add-recipient', 'remove-all-recipients'],

  data() {
    return {
      iconsDescription: [
        {
          name: ICON_PHONE,
          desc: I18_ICON_PHONE_DESC,
        },
        {
          name: ICON_SMS,
          desc: I18_ICON_SMS_DESC,
        },
        {
          name: ICON_WATCH,
          desc: I18_ICON_WATCH_DESC,
        },
        {
          name: ICON_SIP,
          desc: I18_ICON_SIP_DESC,
        },
      ]
    }
  },

  computed: {

    getIconsDescription() {
      let iconsRenderedDescription = [];
      for (let i = 0; i < this.iconsRenderedList.length; ++i)
        iconsRenderedDescription.push(this.getIconsDescriptionObject(this.iconsRenderedList[i]));
      return iconsRenderedDescription;
    },

    getScenarioType() {
      return this.$t(I18_SCENARIO_TYPE[this.scenarioType]);
    },
  },

  created() {
    if (this.isSipActivated)
      this.scenarioType = I18_SCENARIO_TYPE.length - 1;
    else {
      for (let i = 0; i < PARAM_PTI_SCENARIO_TYPE.length; ++i) {
        if (getBoolPtiValue(PARAM_PTI_SCENARIO_TYPE[i])) {
          this.scenarioType = i;
          break ;
        }
      }
    }
  },

  methods: {
    getIconsDescriptionObject(iconName) {
      let i = 0;
      for (; i < this.iconsDescription.length; ++i)
       if (this.iconsDescription[i].name.valueOf() === iconName.valueOf())
        break ;
      return this.iconsDescription[i];
    }
  }
  
})
</script>
