<template>
  <!-- BEGIN: Title component-->
  <div class="intro-y flex flex-row items-center mt-6">
    <div>
      <h2 class="flex-none text-lg font-medium mr-2">
        {{ $t(title) }}
      </h2>
    </div>
    <CustomButton
      v-if="helpLocale"
      class="hover:translate-y-1"
      icon-component-name="HelpCircleIcon"
      custom-class="transform hover:-translate-y-1"
      @click.stop="openLink($t(deviceStr + 'help.' + helpLocale))"
    />
  </div>
  <!-- END: Title component-->
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "@/store"


/**
 * -------------------------- DESCRIPTION ---------------------------
 * The basic title used in emerit console, with an additional help
 * button if needed.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} title
 *    The text of the title. Should be i18 key for the locale 
 *    (ex: 'common.menu_save').
 * 
 * @param {String} helpLocale
 *    Second part of the i18 locale (ex: 'menu_loc_outdoor') in order to make 
 *    appear a help button at the right of the title which redirects to online 
 *    help. First part of the locale ('watchhelp' or 'phonehelp') is 
 *    automatically managed. If undefined, the button does not appear. 
 *    Undefined by default. 
 * 
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  props: {
    title: {
      type: String,
      required: true,
    },
    helpLocale: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      deviceStr: useStore().state.emeritValues.deviceStr,
    }
  },

  methods: {
    openLink(link) {
      window.open(link);
    },
  }
})
</script>