<template>
    <!-- BEGIN: CustomTextInputWithUnitDescription component-->
    <div class="clearfix flex-row pl-3 pr-3">

      <div class="input-group pb-1 mt-2">
        <input
          :value="modelValue"
          type="number"
          min="0"
          max="999"
          @input="$emit('update:modelValue', $event.target.value)"
        >
        <div
          id="input-group-3"
          class="input-group-text h-10 font-sm"
        >
          {{ $t(unitDescription) }}
        </div>
      </div>
    </div>
    <!-- END: CustomTextInput component-->
  </template>
  
  <script>
  import { defineComponent } from 'vue'
  
  /**
   * -------------------------- DESCRIPTION ---------------------------
   * A text input component with an associated unit description.
   * 
   * ----------------------------- PROPS ------------------------------
   * @param {Number || String} modelValue
   *    The value of the input field. Automatically sets when using v-model on 
   *    this component. For example, v-model="parentVariable" will bind this 
   *    modelValue to parentVariable. Will be initialized with parent variable
   *    value.
   * 
   * @param {String} title
   *    The title above the input field. Should be i18 key for the locale 
   *    (ex: 'common.menu_save').
   * 
   * @param {String} unitDescription
   *    Description of the unit used by the text input field. 
   *    Should be i18 key for the locale (ex: 'common.menu_save').
   * 
   * ------------------------- EVENTS EMITTED -------------------------
   * @emits update:modelValue
   * @return {String}
   *    Emits the new value of the text input field when user inputs new text. 
   *    When v-model is used on this component, v-model automatically updates 
   *    with this value.
   *              
   * ------------------------------------------------------------------
   */
  export default defineComponent({
  
    name: 'CustomTextInputWithUnitDescription',
  
    props: {
      modelValue: {
        required: true,
      },
      // title: {
      //   type: String,
      //   required: true,
      // },
      unitDescription: {
        type: String,
        required: true,
      }
    },
  
    emits: ['update:modelValue'],
  })
  </script>
  