/* eslint-disable */

<template>
  <div>
    <div class="intro-y flex items-center mt-6">
      <h2 class="text-lg font-medium mr-auto">
        {{ pageTitle }}
      </h2>
    </div>

    <!-- BEGIN: ip-server -->
    <div class="grid grid-cols-12 gap-6 mt-6">
      <!-- BEGIN: left column -->
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: protocole d'alarme -->
        <div class="clearfix box">
          <!-- entete de parametre -->
          <div
            class="flex flex-col sm:flex-row  p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <h2 class="text-lg font-medium mr-auto">
              {{ $t("ipserversettings.menu_encapsulated_alarm_protol") }}
            </h2>
          </div>
          <!-- BEGIN: bloc parametres -->
          <div>
            <!-- BEGIN: parametre avec radio button -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <div>
                <div class="form-check">
                  <input
                    id="radiobutton1"
                    v-model="this.sia_id_token"
                    class="form-check-input"
                    type="radio"
                    name="radio-button-alarm-protocol"
                    value="1"
                  />
                  <label class="form-check-label" for="radiobutton1">
                    {{ $t("ipserversettings.menu_admeco_cid_contact_id") }}
                  </label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radiobutton2"
                    v-model="this.sia_id_token"
                    class="form-check-input"
                    type="radio"
                    name="radio-button-alarm-protocol"
                    value="2"
                  />
                  <label class="form-check-label" for="radiobutton2">
                    {{ $t("ipserversettings.menu_sia_dcs") }}
                  </label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="checkbox-switch-1"
                    v-model="this.sia_time_stamp"
                    class="form-check-input"
                    type="checkbox"
                    value="0"
                  />
                  <label class="form-check-label" for="checkbox-switch-1">
                    {{
                      $t(
                        "ipserversettings.menu_add_the_message_ending_timestamp"
                      )
                    }}
                  </label>
                </div>
              </div>
            </div>
            <!-- END: parametre avec radio button -->
          </div>
          <!-- END: bloc parametres -->
        </div>
        <!-- END: protocole d'alarme -->
        <!-- BEGIN: format et selection des données étendues -->
        <div class="clearfix box mt-6">
          <!-- entete de parametre -->
          <div
            class="flex flex-col sm:flex-row  p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <h2 class="text-lg font-medium mr-auto">
              {{
                $t("ipserversettings.menu_extended_data_format_and_selection")
              }}
            </h2>
          </div>
          <!-- BEGIN: format donnees etendues -->
          <div>
            <!-- BEGIN: parametre avec radio button -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <div>
                <div class="form-check">
                  <input
                    id="radiobutton3"
                    v-model="this.sia_ex_data_format"
                    class="form-check-input"
                    type="radio"
                    name="radio-button-extended-data"
                    value="1"
                  />
                  <label class="form-check-label" for="radiobutton3">
                    {{ $t("ipserversettings.menu_standard_format") }}
                  </label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radiobutton4"
                    v-model="this.sia_ex_data_format"
                    class="form-check-input"
                    type="radio"
                    name="radio-button-extended-data"
                    value="2"
                  />
                  <label class="form-check-label" for="radiobutton4">
                    {{ $t("ipserversettings.menu_esi_format") }}
                  </label>
                </div>
                <div class="form-check mt-2">
                <input
                    id="radiobutton5"
                    v-model="this.sia_ex_data_format"
                    class="form-check-input"
                    type="radio"
                    name="radio-button-extended-data"
                    value="3"
                  />
                <label class="form-check-label" for="radiobutton5">
                    {{ $t("ipserversettings.menu_T2i_format") }}
                  </label>
                </div>
              </div>
            </div>
            <!-- END: parametre avec radio button -->
          </div>
          <!-- END: format donnees etendues -->
          <!-- BEGIN: selection extended data-->
          <div>
            <!-- BEGIN: selection extended data standard -->
            <div
              v-if="this.sia_ex_data_format == 1 || this.sia_ex_data_format == 3"
              class="clearfix flex-col pl-3 pb-3 border-b mt-3 border-gray-200"
            >
              <div v-if="this.isGpsWanted == 2">
                <label class="font-bold">
                  {{ $t("ipserversettings.menu_gps_coordinates_format") }}
                </label>
                <div class="clearfix flex-row mt-3">
                  <div>
                    <div class="form-check">
                      <input
                        id="radiobutton5"
                        v-model="this.sia_gps_format"
                        class="form-check-input"
                        type="radio"
                        name="radio-button-gps-format"
                        value="1"
                      />
                      <label class="form-check-label" for="radiobutton5">
                        {{ $t("ipserversettings.menu_gps_DD") }}
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input
                        id="radiobutton6"
                        v-model="this.sia_gps_format"
                        class="form-check-input"
                        type="radio"
                        name="radio-button-gps-format"
                        value="2"
                      />
                      <label class="form-check-label" for="radiobutton6">
                        {{ $t("ipserversettings.menu_gps_DDM") }}
                      </label>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div 
               v-if ="this.sia_ex_data_format == 1"
               class="mt-3" >
                <label class="font-bold">
                  {{
                    $t(
                      "ipserversettings.menu_extended_data_standard_format_selection"
                    )
                  }}
                </label>
              </div>
              <div 
               v-if ="this.sia_ex_data_format == 3"
               class="mt-3" >
                <label class="font-bold">
                  {{
                    $t(
                      "ipserversettings.menu_extended_data_T2i_format_selection"
                    )
                  }}
                </label>
              </div>
              <div class="flex flex-col sm:flex-col mt-3">
                <div class="form-check mr-3 sm:mt-0">
                  <input
                    id="checkbox-switch-2"
                    v-model="this.sia_ex_data_battery_level"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-2">
                    {{ $t("ipserversettings.menu_std_battery") }}
                  </label>
                </div>
                <div 
                 v-if="sia_ex_data_format ==1"
                 class="form-check mt-2 mr-3 "
                >
                  <input
                    id="checkbox-switch-3"
                    v-model="this.sia_ex_data_event_timestamp"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-3">
                    {{ $t("ipserversettings.menu_std_event_timestamp") }}
                  </label>
                </div>
                <div class="form-check mt-2 mr-3 ">
                  <input
                    id="checkbox-switch-a"
                    v-model="this.sia_ex_data_device_imei"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-a">
                    {{ $t("ipserversettings.menu_std_imei") }}
                  </label>
                </div>
                <div 
                  class="form-check mt-2 mr-3 "
                  v-if="isIndoorWifiEnabled || isIndoorBleEnabled"
                >
                <input
                  id="checkbox-switch-b"
                  v-model="sia_ex_data_beacon_id"
                  class="form-check-input"
                  type="checkbox"
                  :disabled="isCheckboxDisabled"
                  :style="{ opacity: isCheckboxDisabled ? '0.5' : '1', cursor: isCheckboxDisabled ? 'not-allowed' : 'auto' }"
                />
                  <label class="form-check-label" for="checkbox-switch-b">
                    {{ $t("ipserversettings.menu_std_beacon_id") }}
                  </label>
                </div>
                <div 
                  class="form-check mt-2 mr-3 "
                  v-else
                >
                <input
                  id="checkbox-switch-b"
                  v-model="sia_ex_data_beacon_id"
                  class="form-check-input"
                  type="checkbox"
                  :disabled="isCheckboxDisabled"
                  :style="{ opacity: isCheckboxDisabled ? '0.5' : '1', cursor: isCheckboxDisabled ? 'not-allowed' : 'auto' }"
                />
                  <label class="form-check-label" for="checkbox-switch-b">
                    {{ $t("ipserversettings.menu_std_beacon_id") }}
                  </label>
                </div>
                <div v-if="this.isGpsWanted == 2">
                  <!-- GPS -->
                  <div class="form-check mt-2 mr-3  ">
                    <input
                      id="checkbox-switch-4"
                      v-model="this.sia_ex_data_gps_accuracy"
                      class="form-check-input"
                      type="checkbox"
                    />
                    <label class="form-check-label" for="checkbox-switch-4">
                      {{ $t("ipserversettings.menu_std_gps_accuracy") }}
                    </label>
                  </div>
                  <div 
                  v-if="sia_ex_data_format == 1"
                  class="form-check mr-3 mt-2">
                    <input
                      id="checkbox-switch-5"
                      v-model="this.sia_ex_data_gps_timestamp"
                      class="form-check-input"
                      type="checkbox"
                    />
                    <label class="form-check-label" for="checkbox-switch-5">
                      {{ $t("ipserversettings.menu_std_gps_timestamp") }}
                    </label>
                  </div>
                  <div 
                  v-if="sia_ex_data_format == 3"
                  class="form-check mr-3 mt-2">
                    <input
                      id="checkbox-switch-5"
                      v-model="this.sia_ex_data_gps_timestamp"
                      class="form-check-input"
                      type="checkbox"
                    />
                    <label class="form-check-label" for="checkbox-switch-5">
                      {{ $t("ipserversettings.menu_std_gps_timestamp(O)") }}
                    </label>
                  </div>
                  <div class="form-check mt-2 mr-3 ">
                    <input
                      id="checkbox-switch-6"
                      v-model="this.sia_ex_data_gps_speed"
                      class="form-check-input"
                      type="checkbox"
                    />
                    <label class="form-check-label" for="checkbox-switch-6">
                      {{ $t("ipserversettings.menu_std_gps_speed") }}
                    </label>
                  </div>
                  <div class="form-check mt-2 mr-3  ">
                    <input
                      id="checkbox-switch-7"
                      v-model="sia_ex_data_gps_validity"
                      class="form-check-input"
                      type="checkbox"
                      @change="onCheckboxChange"
                    />
                    <label class="form-check-label" for="checkbox-switch-7">
                      {{ $t("ipserversettings.menu_std_gps_validity") }}
                    </label>
                  </div>
                  <div v-if="sia_ex_data_gps_validity">
                      <div class="clearfix flex-row mt-2 ml-6 ">
                        <label class="font">
                          {{ $t("ipserversettings.menu_location_maximum_age") }}
                        </label>
                        <div class="input-group pb-1 mt-2">
                          <input
                            v-model="locationSatelliteAge"
                            type="range"
                            :min="locationSatelliteAgeMin"
                            :max="locationSatelliteAgeMax"
                            step="1"
                            @change="onSettingChange"
                          />
                          <div class="form-control w-20 text-center ml-5">
                            {{ locationSatelliteAge }}
                          </div>
                          <div
                            id="input-group-3"
                            class="input-group-text h-10 font-sm"
                            >
                            {{ $t("common.menu_minutes") }}
                          </div>
                        </div>
                      </div>
                      <div class="clearfix flex-row mt-2 ml-6 ">
                        <label class="font">
                          {{ $t("ipserversettings.menu_location_maximum_accuracy") }}                          
                        </label>
                        <div class="input-group pb-1 mt-2">
                          <input
                            v-model="locationSatelliteAccuracy"
                            type="range"
                            :min="locationSatelliteAccuracyMin"
                            :max="locationSatelliteAccuracyMax"
                            step="10"
                            @change="onSettingChange"
                          />
                          <div class="form-control w-20 text-center ml-5">
                            {{ locationSatelliteAccuracy }}
                          </div>
                          <div
                            id="input-group-3"
                            class="input-group-text h-10 font-sm"
                            >
                            {{ $t("common.menu_meters") }}
                          </div>
                        </div>
                      </div>
                  </div>    
                  <div class="form-check mt-2 mr-3 ">
                    <input
                      id="checkbox-switch-8"
                      v-model="this.sia_ex_data_gps_altitude"
                      class="form-check-input"
                      type="checkbox"
                    />
                    <label class="form-check-label" for="checkbox-switch-8">
                      {{ $t("ipserversettings.menu_std_gps_altitude") }}
                    </label>
                  </div>
                </div>
                <!-- GPS -->
              </div>
            </div>
            <!-- END: selection extended data standard -->
            <!-- BEGIN: selection extended data esi -->
            <div
              v-if="this.sia_ex_data_format == 2"
              class="clearfix flex-col pl-3 pb-3 border-b mt-3 border-gray-200"
            >
              <label class="font-bold">
                {{
                  $t("ipserversettings.menu_extended_data_esi_format_selection")
                }}
              </label>
              <div class="flex flex-col sm:flex-col mt-3">
                <div class="form-check mr-3">
                  <input
                    id="checkbox-switch-9"
                    v-model="this.sia_ex_data_battery_level"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="checkbox-switch-9">
                    {{ $t("ipserversettings.menu_esi_battery_level") }}
                  </label>
                </div>
                <div v-if="this.isGpsWanted == 2">
                  <!-- GPS -->
                  <div class="form-check mt-2 mr-3  ">
                    <input
                      id="checkbox-switch-10"
                      v-model="this.sia_ex_data_gps_timestamp"
                      class="form-check-input"
                      type="checkbox"
                    />
                    <label class="form-check-label" for="checkbox-switch-10">
                      {{ $t("ipserversettings.menu_esi_gps_timestamp") }}
                    </label>
                  </div>
                </div>
                <!-- GPS -->
              </div>
            </div>
            <!-- END: selection extended data esi -->
          </div>
          <!-- END: selection extended data-->
        </div>
        <!-- END: format et selection données etendues -->
        <!-- BEGIN: personnalisation champs -->
        <div class="clearfix box mt-6">
          <div
            class="flex flex-col sm:flex-row p-3 bg-red-300 border-gray-200 dark:border-dark-5 rounded-t"
          >
            <div>
              <h2 class="text-lg font-medium mr-auto">
                {{ $t("ipserversettings.menu_customed_field") }}
              </h2>
            </div>
          </div>
          <!-- BEGIN: bloc parametres -->
          <div>
            <!-- BEGIN: parametre texte -->
            <div class="clearfix flex-row p-3 border-b border-gray-200">
              <label class="font-bold">
                {{ $t("ipserversettings.menu_lpref") }}
              </label>
              <div class="input-group pb-2">
                <input
                  v-model="sia_account_prefix_lpref"
                  type="text"
                  maxlength="3"
                  class="form-control w-20 text-center"
                  aria-describedby="input-group-1"
                />
              </div>
            </div>
            <!-- END: parametre texte -->
          </div>
          <!-- END: bloc parametres -->
        </div>
        <!-- END: personnalisation champs -->
      </div>
      <!-- END: left column -->
    </div>
    <!-- END: ip-server -->
    <button
      type="submit"
      class="btn btn-primary mt-6"
      @click="onSavedButtonClick"
    >
      {{ $t("common.menu_save") }}
    </button>
  </div>
</template>

<script>
import { 
  getParameterPTIValue, 
  setParameterPTIValue,
  getBoolPtiValue,
} from "../../dao/parametre-pti";
import {
  updateTlsIpRecipientSiaEvents,
  getRecipienTlsIpProtocotolDatasOnly
} from "../../dao/tls-ip-dao";
import {
  getRecipienSupIpProtocotolDatasOnly,
  updateSupIpRecipientSiaEvents
} from "../../dao/sup-ip-dao";


const PARAM_PTI_INDOOR_WIFI = "isWifiLocParamEnabled";
const PARAM_PTI_INDOOR_BLE = "isBluetoothLocParamEnabled";


export default {
  props: ["type"],

  data() {
    return {
      sia_id_token: 1,
      sia_ex_data_format: 1,
      sia_time_stamp: 1,
      sia_gps_format: 1,
      sia_ex_data_event_timestamp: false,
      sia_ex_data_gps_timestamp: true,
      sia_ex_data_gps_accuracy: -1,
      sia_ex_data_gps_speed: -1,
      sia_ex_data_gps_altitude: true,
      sia_ex_data_gps_validity: -1,
      sia_ex_data_battery_level: true,
      sia_ex_data_device_imei: undefined,
      sia_ex_data_beacon_id: undefined,
      isCheckboxDisabled: false,

      sia_account_prefix_lpref: "",

      SIA_EX_DATA_FORMAT_T2i: "T2i",
      SIA_ID_TOKEN_ADM_CID: "ADM_CID",
      SIA_ID_TOKEN_SIA_DCS: "SIA-DCS",
      SIA_EX_DATA_FORMAT_STANDARD: "STANDARD",
      SIA_EX_DATA_FORMAT_ESI: "ESI",
      SIA_GPS_FORMAT_DD: "DD",
      SIA_GPS_FORMAT_DM: "DM",

      recipient_ip_protocol_datas: ["", "", "", "", 1, 1, 1, 1, 1, 1, 1, 1, "", "", 1,1],

      pageTitle: "values",
      smseventschecked: 0,
      radiobuttonsmsandcallcyclechecked: 0,
      radiobuttonsmsthencallcyclechecked: 0,
      radiobuttonsmsackchecked: 0,
      smsackchecked: 0,
      smsdatachecked: 0,
      alertlabelchecked: 0,
      range: 60,
      dc09formatchecked: 3,
      radioButtonAlertScenarioType: -1,
      isGpsWanted: false,
      locationSatelliteAccuracy:null,
      locationSatelliteAge:null,
      locationSatelliteAccuracyMin:10,
      locationSatelliteAccuracyMax:100,
      locationSatelliteAgeMax:60,
      locationSatelliteAgeMin:1,

      isIndoorWifiEnabled: undefined,
      isIndoorBleEnabled: undefined,
    };
  },

  created() {
    this.isIndoorWifiEnabled = getBoolPtiValue(PARAM_PTI_INDOOR_WIFI);
    this.isIndoorBleEnabled = getBoolPtiValue(PARAM_PTI_INDOOR_BLE);
  },

  mounted() {
    this.updateUI();
  },

  methods: {
    updateUI() {
      if (
        (this.isGpsWanted = getParameterPTIValue("isGpsLocParamEnabled")) == 1) {
        this.isGpsWanted = 2;
        this.locationSatelliteAccuracy = getParameterPTIValue("LocationSatelliteAccuracy");
        this.locationSatelliteAge  = getParameterPTIValue("LocationSatelliteAge");
      } else {
        this.isGpsWanted = 1;
      }

      switch (this.type) {
        case "supervision":
          this.pageTitle = this.$t(
            "ipserversettings.menu_monitoging_server_sia_protocol_settings"
          );
          this.recipient_ip_protocol_datas = getRecipienSupIpProtocotolDatasOnly();
          this.initValues();
          break;

        case "tls":
          this.pageTitle = this.$t(
            "ipserversettings.menu_monitoring_center_server_sia_protocol_settings"
          );
          this.recipient_ip_protocol_datas = getRecipienTlsIpProtocotolDatasOnly();
          this.initValues();
          break;
      }
    },

    onCheckboxChange(){
        this.locationSatelliteAccuracy = getParameterPTIValue("LocationSatelliteAccuracy");
        this.locationSatelliteAge  = getParameterPTIValue("LocationSatelliteAge");
    },

    initValues() {

      if (this.recipient_ip_protocol_datas[0] == this.SIA_ID_TOKEN_ADM_CID) {
        this.sia_id_token = "1";
        //console.log("id token ="+ this.sia_id_token);
      } else{
        this.sia_id_token = "2";
      }

      if (
        this.recipient_ip_protocol_datas[1] == this.SIA_EX_DATA_FORMAT_STANDARD
      ) {
        this.sia_ex_data_format = "1";
      } if(this.recipient_ip_protocol_datas[1] == this.SIA_EX_DATA_FORMAT_ESI){
        this.sia_ex_data_format = "2";
      }
        if(this.recipient_ip_protocol_datas[1] == this.SIA_EX_DATA_FORMAT_T2i){
        this.sia_ex_data_format = "3";
      }

      if (this.recipient_ip_protocol_datas[2] == this.SIA_GPS_FORMAT_DD) {
        this.sia_gps_format = "1";
        //console.log("id sia_gps_format ="+ this.sia_gps_format);
      } else {
        this.sia_gps_format = "2";
      }

      if (this.recipient_ip_protocol_datas[3] == 0) {
        this.sia_ex_data_battery_level = false;
        //console.log("id token ="+ this.sia_id_token);
      } else {
        this.sia_ex_data_battery_level = true;
      }
      if (this.sia_ex_data_format == 3){
        this.recipient_ip_protocol_datas[4] == 0
        this.sia_ex_data_event_timestamp = false;
      }
      else if (this.recipient_ip_protocol_datas[4] == 0) {
        this.sia_ex_data_event_timestamp = false;
        //console.log("id token ="+ this.sia_ex_data_event_timestamp);
      } else {
        this.sia_ex_data_event_timestamp = true;
      }

      if (this.recipient_ip_protocol_datas[5] == 0) {
        this.sia_ex_data_gps_timestamp = false;
      } else {
        this.sia_ex_data_gps_timestamp = true;
      }

      if (this.recipient_ip_protocol_datas[6] == 0) {
        this.sia_ex_data_gps_accuracy = false;
      } else {
        this.sia_ex_data_gps_accuracy = true;
      }

      if (this.recipient_ip_protocol_datas[7] == 0) {
        this.sia_ex_data_gps_speed = false;
      } else {
        this.sia_ex_data_gps_speed = true;
      }

      if (this.recipient_ip_protocol_datas[8] == 0) {
        this.sia_ex_data_gps_altitude = false;
      } else {
        this.sia_ex_data_gps_altitude = true;
      }

      if (this.recipient_ip_protocol_datas[9] == 0) {
        this.sia_ex_data_gps_validity = false;
      } else {
        this.sia_ex_data_gps_validity = true;
      }

      if (this.recipient_ip_protocol_datas[10] == 0) {
        this.sia_time_stamp = false;
      } else {
        this.sia_time_stamp = true;
      }

      if (this.recipient_ip_protocol_datas[11] != "undefined") {
        //console.log("undef = "+this.recipient_ip_protocol_datas[11]);
        this.sia_account_prefix_lpref = this.recipient_ip_protocol_datas[11];
      }

      this.sia_ex_data_device_imei = Boolean(this.recipient_ip_protocol_datas[12])
      if(getParameterPTIValue('isTransparentMode')== true){
        this.sia_ex_data_beacon_id = true;
        this.isCheckboxDisabled = true;
      }else{
        this.sia_ex_data_beacon_id = Boolean(this.recipient_ip_protocol_datas[13])
        this.isCheckboxDisabled = false;
      }
    },

    onAlertTypeChanged(event) {
      if (event.target.value == 0) {
        this.showInternalRecipients = false;
        this.showTlsCalls = false;
        this.showLocalSettings = false;
        this.showTlsIpSettings = false;
      } else {
        this.showInternalRecipients = true;
        this.showTlsCalls = true;
        this.showLocalSettings = true;
        this.showTlsIpSettings = true;
      }
    },

    restoreValuetoRecipientIp() {
      if (this.sia_id_token == "1") {
        this.recipient_ip_protocol_datas[0] = this.SIA_ID_TOKEN_ADM_CID;
      } else {
        this.recipient_ip_protocol_datas[0] = this.SIA_ID_TOKEN_SIA_DCS;
      }
      if (this.sia_ex_data_format == "1") {
        this.recipient_ip_protocol_datas[1] = this.SIA_EX_DATA_FORMAT_STANDARD;
      } if (this.sia_ex_data_format == "3") {
        this.recipient_ip_protocol_datas[1] = this.SIA_EX_DATA_FORMAT_T2i;
      } if(this.sia_ex_data_format == "2") {
        this.recipient_ip_protocol_datas[1] = this.SIA_EX_DATA_FORMAT_ESI;
      }
      // console.log(this.sia_ex_data_format);

      // console.log(this.recipient_ip_protocol_datas);
      if (this.sia_gps_format == "1") {
        this.recipient_ip_protocol_datas[2] = this.SIA_GPS_FORMAT_DD;
      } else {
        this.recipient_ip_protocol_datas[2] = this.SIA_GPS_FORMAT_DM;
      }

      if (this.sia_ex_data_battery_level) {
        this.recipient_ip_protocol_datas[3] = 1;
      } else {
        this.recipient_ip_protocol_datas[3] = 0;
      }
      if (this.sia_ex_data_format == 3){
        if(this.sia_ex_data_event_timestamp){
        this.recipient_ip_protocol_datas[4] = 0;
        }
      }
      else if (this.sia_ex_data_event_timestamp) {
        this.recipient_ip_protocol_datas[4] = 1;
      } else {
        this.recipient_ip_protocol_datas[4] = 0;
      }

      if (this.sia_ex_data_gps_timestamp) {
        this.recipient_ip_protocol_datas[5] = 1;
      } else {
        this.recipient_ip_protocol_datas[5] = 0;
      }

      if (this.sia_ex_data_gps_accuracy) {
        this.recipient_ip_protocol_datas[6] = 1;
      } else {
        this.recipient_ip_protocol_datas[6] = 0;
      }

      if (this.sia_ex_data_gps_speed) {
        this.recipient_ip_protocol_datas[7] = 1;
      } else {
        this.recipient_ip_protocol_datas[7] = 0;
      }

      if (this.sia_ex_data_gps_altitude) {
        this.recipient_ip_protocol_datas[8] = 1;
      } else {
        this.recipient_ip_protocol_datas[8] = 0;
      }

      if (this.sia_ex_data_gps_validity) {
        this.recipient_ip_protocol_datas[9] = 1;
      } else {
        this.recipient_ip_protocol_datas[9] = 0;
      }

      if (this.sia_time_stamp) {
        this.recipient_ip_protocol_datas[10] = 1;
      } else {
        this.recipient_ip_protocol_datas[10] = 0;
      }

      this.recipient_ip_protocol_datas[11] = this.sia_account_prefix_lpref;
      this.recipient_ip_protocol_datas[12] = Number(this.sia_ex_data_device_imei);
      this.recipient_ip_protocol_datas[13] = Number(this.sia_ex_data_beacon_id);
    },

    onSavedButtonClick() {
      if (this.type == "supervision") {
        //console.log("sup recipient = ");
        this.restoreValuetoRecipientIp(this.recipient_ip_protocol_datas);
        //console.log(this.recipient_ip_protocol_datas);
        updateSupIpRecipientSiaEvents(this.recipient_ip_protocol_datas);
        //console.log("amd cid ="+this.amd_cid);
      } else {
        //console.log("tls recipient = ");
        this.restoreValuetoRecipientIp(this.recipient_ip_protocol_datas);
        //console.log(this.recipient_ip_protocol_datas);
        updateTlsIpRecipientSiaEvents(this.recipient_ip_protocol_datas);
        //console.log("amd cid ="+this.amd_cid);
      }
      if (this.sia_ex_data_gps_validity==true && this.isGpsWanted == 2) {
        setParameterPTIValue( "LocationSatelliteAge",this.locationSatelliteAge );
        setParameterPTIValue( "LocationSatelliteAccuracy",this.locationSatelliteAccuracy );
      }

      this.$router.push("/");
      //console.log("sia ="+this.sia_dcs);
    }
  }
};
</script>
