<template>
  <!-- BEGIN: CustomRadioInput -->
  <div>
    <div class="form-check mb-2">
      <input
        :id="customId"
        :value="value"
        :name="radioGroupName"
        class="form-check-input"
        type="radio"
        @change="$emit('update:modelValue', $event.target.value)"
      >
      <label 
        class="form-check-label" 
        :for="customId"
      >
        {{ $t(description) }}
      </label>
    </div>
    <p v-if="errorMsg.length != 0">
      {{ $t(errorMsg) }}
    </p>
  </div>
  <!-- END: CustomRadioInput -->
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A radio input with a description associated. v-model MUST be used
 * on this component, it will be updated with radio value when user
 * clicked on it.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {Number || String} value
 *    Value of the radio that will be emitted to v-model when user
 *    click on it.
 * 
 * @param {String} customId
 *    Id of the radio input, allowing to associate a label to it.
 *    Should be unique (ex for HTML attribute >> :customId="groupName + index"
 *    if CustomRadioInput generated with v-for).
 * 
 * @param {String} radioGroupName
 *    Allows to group several CustomRadioInput together. They should
 *    all have the same radioGroupName.
 * 
 * @param {String} errorMsg
 *    If empty, nothing appears. Otherwise, this string will appear 
 *    as an error in red to the user. Should be key for i18 locale.
 *    Empty by default, if no error management is needed do not fill it.
 * 
 * @param {String} description
 *    Description for the label associated to the radio input. 
 *    Should be i18 key for the locale (ex: 'common.menu_save').
 * 
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits update:modelValue
 * @return {Number || String}
 *    Emits the radio value previously initialized with props value 
 *    when user clicks on this component. v-model automatically 
 *    updates with this value. 
 *              
 * ------------------------------------------------------------------
 */
export default defineComponent({

  props: {
    value: {
      type: Number || String,
      required: true,
    },
    customId: {
      type: String,
      required: true,
    },
    radioGroupName: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },

  emits: ['update:modelValue'],
})
</script>
