<template>
  <!-- BEGIN: CustomRangeInput component-->
  <div class="clearfix flex-row p-3">
    <label class="font-bold">
      {{ $t(title) }}
    </label>
    <div class="input-group pb-1 mt-2">
      <input
        :value="modelValue"
        :min="minValue"
        :max="maxValue"
        :step="stepValue"
        type="range"
        @input="$emit('update:modelValue', $event.target.value)"
      >
      <div class="form-control w-20 text-center ml-5">
        {{ modelValue }}
      </div>
      <div
        id="input-group-3"
        class="input-group-text h-10 font-sm"
      >
        {{ $t(unitDescription) }}
      </div>
    </div>
  </div>
  <!-- BEGIN: CustomRangeInput component-->
</template>

<script>
import { defineComponent } from 'vue'

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A range input from a min value to a max value, with an unit description
 * associated.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {Number || String} modelValue
 *    Value of the input field. Automatically sets when using v-model on this
 *    component. For example, v-model="parentVariable" will bind this 
 *    modelValue to parentVariable. Will be initialized with parent variable
 *    value, which should be between min and max value of the range.
 * 
 * @param {String} title
 *    The title above the range input field. Should be i18 key for the locale 
 *    (ex: 'common.menu_save').
 * 
 * @param {Number} minValue
 *    The range's minimal value.
 * 
 * @param {Number} maxValue
 *    The range's maximal value.
 * 
 * @param {Number} stepValue
 *    Each time user moves the cursor, modelValue will vary of this value.
 * 
 * @param {String} unitDescription
 *    Description of the unit used by the range. 
 *    Should be i18 key for the locale (ex: 'common.menu_save').
 * 
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits update:modelValue
 * @return {String}
 *    Emits the new value of the cursor when user moves it. When v-model 
 *    is used on this component, v-model automatically updates with this
 *    value.
 *              
 * ------------------------------------------------------------------
 */
export default defineComponent({

  name: 'CustomRangeInput',

  props: {
    // eslint-disable-next-line
    modelValue: {
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    minValue: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
    stepValue: {
      type: Number,
      required: true,
    },
    unitDescription: {
      type: String,
      required: true,
    }
  },

  emits: ['update:modelValue'],
})
</script>
