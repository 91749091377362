<template>
  <div>
    <Title
      title="wifi.menu_registred_wifi_networks_list"
      help-locale="menu_wifi_connection_view"
    />
   
    <WifiNetworkSettings
      :network-list="networkList"
      :modal-id-add-modify-network="modalIdAddModifyNetwork"
      :modal-id-alert-confirm="modalIdAlertConfirm"
      @add-network="$refs.modalAddModifyNetwork.initAdd()"
      @remove-all-networks="initModalConfirm"
    />
    <WifiNetworkList
      :network-list="networkList"
      :modal-id-add-modify-network="modalIdAddModifyNetwork"
      :modal-id-alert-confirm="modalIdAlertConfirm"
      @modify-network="initModalModify"
      @remove-network="initModalConfirm"
    />
    <ModalAlertConfirmChoice
      ref="modalAlertConfirmChoice"
      :modal-id="modalIdAlertConfirm"
      alert-title="common.menu_are_you_sure"
      :alert-text="getModalAlertText"
      icon-component-name="AlertTriangleIcon"
      left-button-component-name="ButtonDismissModal"
      left-button-text="common.menu_cancel"
      left-button-custom-class="btn btn-outline-secondary mr-1"
      right-button-component-name="ButtonDismissModal"
      right-button-text="common.menu_remove"
      right-button-custom-class="btn btn-danger"
      @right-button="remove"
    />
    <ModalAddModifyNetwork
      ref="modalAddModifyNetwork"
      :modal-id="modalIdAddModifyNetwork"
      :network-list="networkList"
      @add-network-success="addNewNetwork"
      @edit-network-success="modifyExistingNetwork"
    />
  </div>
</template>

<script>

import {
  getAllWifi,
  addWifi,
  deleteWifi,
  deleteAllWifi,
  editWifi,
} from "../../dao/wifi-dao";
import { useStore } from "@/store";
import WifiNetworkSettings from './local-components/lc-wifi-network-settings.vue'
import WifiNetworkList from './local-components/lc-wifi-network-list.vue'
import ModalAddModifyNetwork from './local-components/lc-wifi-modal-add-modify-network.vue'
import { 
  STORE_REPLACE_PEAP_CERTIFICAT,
 } from '../../store/const_store';

const REMOVE_ONE_ITEM = 0;
const REMOVE_EVERYTHING = 1;

const INDEX_NAME = 0;

const DB_INDEX_NAME = 1;
const DB_INDEX_PASSWORD = 2;
const DB_INDEX_SECU_TYPE = 3;
const DB_INDEX_HIDDEN = 4;
const DB_INDEX_IDENTIFIANT_CERT = 5;
const DB_INDEX_PASSWORD_CERT = 6;
const DB_INDEX_CERTIFICATE = 7;


const BODY = "BODY";
const MODAL_ID_ADD_MODIFY_NETWORK = "modal-add-modify-network";
const MODAL_ID_ALERT_CONFIRM = "modal-alert-confirm";

const I18_REMOVE_ONE_NETWORK = "wifi.menu_remove_one_network";
const I18_REMOVE_EVERYTHING = "wifi.menu_remove_all_networks";


export default {

  components: {
    WifiNetworkSettings,
    WifiNetworkList,
    ModalAddModifyNetwork,
  },

  /**
   * Dismiss the modals if they're opened by clicking on their right buttons, 
   * and wait X milliseconds until the modals are correctly close by checking
   * if they're still present in the <body></body> HTML element, then route
   * to the new view.
   * 
   * Fix a bug when opening a modal and then using navigation arrows (next, prev).
   * The modal wasn't correctly closed and provoquing a crash with all the other modals
   * until next reload.
   */
  beforeRouteLeave (to, from, next) {
    this.$refs.modalAlertConfirmChoice.dismiss();
    this.$refs.modalAddModifyNetwork.dismiss();
    let intervalId = setInterval(() => {  
      const modalAlertConfirm = document.getElementById(MODAL_ID_ALERT_CONFIRM);
      const modalAddModifyNetwork = document.getElementById(MODAL_ID_ADD_MODIFY_NETWORK);
      if (modalAlertConfirm.parentElement.tagName.valueOf() != BODY
          && modalAddModifyNetwork.parentElement.tagName.valueOf() != BODY) {
        clearInterval(intervalId);
        next();
      }
    }, 100);
	},

  data() {
    return {
      networkList: [],
      currentIndex: 0,
      removeType: undefined,
      modalIdAddModifyNetwork: MODAL_ID_ADD_MODIFY_NETWORK,
      modalIdAlertConfirm: MODAL_ID_ALERT_CONFIRM,
    };
  },

  computed: {
    getModalAlertText() {
      return this.removeType == REMOVE_ONE_ITEM ? 
          I18_REMOVE_ONE_NETWORK : I18_REMOVE_EVERYTHING;
    },
  },

  created() {
    this.initNetworkList();
  },

  methods: {

    initNetworkList() {
      let tmpList = getAllWifi();
      if (tmpList)
        for (let i = 0; i < tmpList.values.length; ++i)
          this.networkList.push(this.createWifi(tmpList.values[i]));
    },

    createWifi(wifiFromDb) {
      return [
        wifiFromDb[DB_INDEX_NAME], 
        wifiFromDb[DB_INDEX_PASSWORD], 
        wifiFromDb[DB_INDEX_SECU_TYPE], 
        wifiFromDb[DB_INDEX_HIDDEN],
        wifiFromDb[DB_INDEX_IDENTIFIANT_CERT],
        wifiFromDb[DB_INDEX_PASSWORD_CERT],
        wifiFromDb[DB_INDEX_CERTIFICATE]];
    },

    initModalConfirm(index) {
      this.currentIndex = index == undefined ? this.currentIndex : index;
      this.removeType = index == undefined ? REMOVE_EVERYTHING : REMOVE_ONE_ITEM;
    },

    initModalModify(index) {
      this.currentIndex = index;
      this.$refs.modalAddModifyNetwork.initEdit(index);
    },

    addNewNetwork(newNetwork) {
      addWifi(newNetwork.info);
      this.networkList.push(newNetwork.info);
    },

    modifyExistingNetwork(networkModified) {
      editWifi(networkModified);
      this.networkList[this.currentIndex] = networkModified.info;
    },

    remove() {
      if (this.removeType === REMOVE_ONE_ITEM) {
        let SSIDToDelete = this.networkList[this.currentIndex][INDEX_NAME];
        deleteWifi(SSIDToDelete);
        this.networkList.splice(this.currentIndex, 1)
        let  certificateList = useStore().state.emeritValues['certificateList']

        var filtered = certificateList.filter(function(value, index, arr){ 
          return value.name !== SSIDToDelete;

        });
        this.$store.commit(STORE_REPLACE_PEAP_CERTIFICAT, filtered)
      }
      else {
        this.networkList = [];
        deleteAllWifi();
        let allCertificateDelete = [];
        this.$store.commit(STORE_REPLACE_PEAP_CERTIFICAT, allCertificateDelete);
      }
    },

  }
};
</script>