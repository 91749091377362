<template>
  <!-- BEGIN: AddEddystoneInputFields local component-->
  <div class="grid grid-cols-10 gap-3 mt-3">
    <Tippy
      v-for="(field, index) in getEddystoneFields"
      :key="index"
      :ref="field.title"
      :content="$t(field.tippy)"
      :options="{
        theme: 'light',
        delay: index,
        onShow: updateTippyContent,
      }"
      :class="field.class"
    >
      <CustomTextInput
        v-model="field.value"
        :title="field.title"
        :max-length="field.len"
      />
    </Tippy>
    <CustomButton
      custom-class="lg:mt-6 col-start-9 col-span-2 btn btn-primary mb-2 mt-4"
      text="common.menu_add"
      @click="check()"
    />
    <ErrorMsg
      v-for="(field, index) in getEddystoneFields"
      :key="index"
      :error-msg="field.errorMsg"
      class="col-start-1 col-span-10"
    />
  </div>
  <!-- END: AddEddystoneInputFields local component-->
</template>

<script>
import { defineComponent } from 'vue'
import {
  checkLevel,
  checkCodeZone,
  beaconAlreadyExisting,
  checkDescription,
} from './utils'
import {
  setError,
  resetError,
  resetAllFields,
  regexCheck,
} from '../../../utils/fieldManagement'


const COMPANY_ID = 0;
const BEACON_ID = 1;
const CODE_ZONE = 2;
const LEVEL = 3;
const DESC = 4;

const NAMESPACE_ID_LEN = 20;
const INSTANCE_ID_LEN = 12;

const ERR_EDDY_ALREADY_EXIST = "locationindoorble.menu_err_eddy_already_exist";
const ERR_EDDY_COMP_ID = "locationindoorble.menu_err_eddystone_company_id";
const ERR_EDDY_BEAC_ID = "locationindoorble.menu_err_eddystone_beacon_id";
const ERR_CODE_ZONE = "locationindoorble.menu_err_wrong_code_zone";
const ERR_LEVEL = "locationindoorble.menu_err_wrong_level";
const ERR_DESC = "locationindoorble.menu_err_empty_desc";

const COMP_ID_TITLE = "locationindoorble.menu_beacon_company_id";
const BEAC_ID_TITLE = "locationindoorble.menu_beacon_id";
const CODE_ZONE_TITLE = "locationindoorble.menu_beacon_code_zone";
const LEVEL_TITLE = "locationindoorble.menu_beacon_level";
const DESC_TITLE = "locationindoorble.menu_beacon_description";

const COMP_ID_CLASS = "col-span-10 lg:col-span-10 lg:col-start-1 2xl:col-span-8 2xl:col-start-1";
const BEAC_ID_CLASS = "col-span-10 lg:col-span-6 lg:col-start-1 2xl:col-span-4 2xl:col-start-1";
const CODE_ZONE_CLASS = "col-span-10 lg:col-span-2 lg:col-start-7 2xl:col-span-2 2xl:col-start-5";
const LEVEL_CLASS = "col-span-10 lg:col-span-2 lg:col-start-9 2xl:col-span-2 2xl:col-start-7";
const DESC_CLASS = "col-span-10 lg:col-span-8 lg:col-start-1";

const NAMESPACE_ID_TIPPY = "locationindoorble.menu_tippy_namespace_id";
const INSTANCE_ID_TIPPY = "locationindoorble.menu_tippy_instance_id";
const CODE_ZONE_TIPPY = "locationindoorble.menu_tippy_code_zone";
const LEVEL_TIPPY = "locationindoorble.menu_tippy_level";
const DESC_TIPPY = "locationindoorble.menu_tippy_description";


export default defineComponent({

  props: {
    hideLevelField: {
      type: Boolean,
      required: true,
    },
    beaconList: {
      type: Array,
      required: true,
    }
  },

  emits: ['addEddystoneFailed', 'addEddystoneSuccess'],

  data() {
    return {
      eddystoneFields: [
        { title: COMP_ID_TITLE, class: COMP_ID_CLASS, tippy: NAMESPACE_ID_TIPPY, value: '', len: NAMESPACE_ID_LEN, errorMsg: ''},
        { title: BEAC_ID_TITLE, class: BEAC_ID_CLASS, tippy: INSTANCE_ID_TIPPY, value: '', len: INSTANCE_ID_LEN, errorMsg: ''},
        { title: CODE_ZONE_TITLE, class: CODE_ZONE_CLASS, tippy: CODE_ZONE_TIPPY, value: '', len: 3, errorMsg: ''},
        { title: LEVEL_TITLE, class: LEVEL_CLASS, tippy: LEVEL_TIPPY, value: '', len: 3, errorMsg: ''},
        { title: DESC_TITLE, class: DESC_CLASS, tippy: DESC_TIPPY, value: '', len: 50, errorMsg: ''},
      ],
    }
  },

  computed: {
    getEddystoneFields() {
      const len = this.hideLevelField ? this.eddystoneFields.length - 1 : this.eddystoneFields.length;
      let newEddystoneFields = new Array(len);
      for (let i = 0, j = 0; i < this.eddystoneFields.length; ++i, ++j) {
        if (this.hideLevelField && i === LEVEL)
          --j
        else 
          newEddystoneFields[j] = this.eddystoneFields[i];
      }
      return newEddystoneFields;
    },
  },

  watch: {
    hideLevelField() {
      this.eddystoneFields[LEVEL].value = '';
      resetError(this.eddystoneFields, LEVEL);
    }
  },

  methods: {
    
    updateTippyContent(instance) {
      const index = instance.props.delay;
      instance.setContent(this.$t(this.getEddystoneFields[index].tippy));
    },


    check() {
      let error = this.eddystoneFields.length;
      error -= this.checkEddystoneCompanyId();
      error -= this.checkEddystoneBeaconId();
      error -= checkLevel(this.hideLevelField, this.eddystoneFields, LEVEL, ERR_LEVEL);
      error -= checkCodeZone(this.eddystoneFields, CODE_ZONE, ERR_CODE_ZONE);
      error -= checkDescription(this.eddystoneFields, DESC, ERR_DESC);
      if (!error && !beaconAlreadyExisting(this.beaconList, this.eddystoneFields[COMPANY_ID].value, this.eddystoneFields[BEACON_ID].value, this.eddystoneFields, ERR_EDDY_ALREADY_EXIST))
        this.emitAddEddystoneSuccess();
      else
        this.$emit('addEddystoneFailed');
    },

    checkEddystoneCompanyId() {
      const companyId = this.eddystoneFields[COMPANY_ID].value.toUpperCase();
      if (!companyId.length || !regexCheck(companyId, /^[A-F0-9]{20}/))
        return setError(this.eddystoneFields, COMPANY_ID, ERR_EDDY_COMP_ID);
      return resetError(this.eddystoneFields, COMPANY_ID);
    },

    checkEddystoneBeaconId() {
      const beaconId = this.eddystoneFields[BEACON_ID].value.toUpperCase();
      if (!beaconId.length || !regexCheck(beaconId, /^[A-F0-9]{12}/))
        return setError(this.eddystoneFields, BEACON_ID, ERR_EDDY_BEAC_ID);
      return resetError(this.eddystoneFields, BEACON_ID);
    },

    emitAddEddystoneSuccess() {
      this.$emit('addEddystoneSuccess', this.createNewBeacon());
      resetAllFields(this.eddystoneFields);
    },

    createNewBeacon() {
      return [
        this.eddystoneFields[COMPANY_ID].value.toUpperCase(),
        this.eddystoneFields[BEACON_ID].value.toUpperCase(),
        this.eddystoneFields[CODE_ZONE].value,
        this.eddystoneFields[DESC].value,
        this.eddystoneFields[LEVEL].value,
      ]
    },

  }
})
</script>
