<template>
  <div>
    <Title
      title="sipconfiguration.menu_title"
      help-locale="menu_sip_view"
    />
    <SipSettings
      ref="sipSettings"
    />
    <CustomButton
      text="common.menu_save"
      @click="save"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SipSettings from './local-components/lc-sip-settings.vue'

export default defineComponent({

  components: { SipSettings },

  methods: {
    save() {
      if(this.$refs.sipSettings.check()) {
        this.$refs.sipSettings.save();
        this.$router.push("/");
      }
    },
  },
})

</script>