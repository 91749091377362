const ACCEPTED_IMAGE_TYPES = ['image/gif', 'image/png'];
const MAX_SIZE = 1000000;

export const checkIfFileIsImg = (file) => {
  for (let i = 0; i < ACCEPTED_IMAGE_TYPES.length; ++i)
    if (file.type.valueOf() === ACCEPTED_IMAGE_TYPES[i])
      return true;
  return false;
};

export const checkSize = (file) => {
  if (file.size > MAX_SIZE)
    return false;
  return true;
};