<template>
  <!-- BEGIN: ModifyBbe9InputFields local component-->
  <div class="mt-3">
    <Tippy
      v-for="(field, index) in getBbe9Fields"
      :key="index"
      :ref="field.title"
      :content="$t(field.tippy)"
      :options="{
        theme: 'light',
        delay: index,
        onShow: updateTippyContent,
      }"
    >
      <CustomTextInput
        v-model="field.value"
        class="mb-3"
        :title="field.title"
        :max-length="field.len"
        :error-msg="field.errorMsg"
      />
    </Tippy>
  </div>
  <!-- END: ModifyBbe9InputFields local component-->
</template>

<script>
import { defineComponent } from 'vue'
import {
  checkLevel,
  checkCodeZone,
  beaconModifiedAlreadyExisting,
  getModel,
  BBE9_UUID,
  MODEL_BBE9,
} from './utils'
import {
  setError,
  resetError,
  resetAllFields,
  resetAllError,
  checkFieldIsNotEmpty,
  regexCheck,
} from '../../../utils/fieldManagement'


const TABLE_INDEX_COMPANY_ID = 0;
const TABLE_INDEX_BEACON_ID = 1;

const BEACON_ID = 0;
const CODE_ZONE = 1;
const DESC = 2;
const LEVEL = 3;

const MAJOR_MINOR_LEN = 8;

const ERR_BBE9_ALREADY_EXIST = "locationindoorble.menu_err_bbe9_already_exist";
const ERR_BBE9_BEAC_ID = "locationindoorble.menu_err_bbe9_beacon_id";
const ERR_CODE_ZONE = "locationindoorble.menu_err_wrong_code_zone";
const ERR_DESC = "locationindoorble.menu_err_empty_desc";
const ERR_LEVEL = "locationindoorble.menu_err_wrong_level";

const BEAC_ID_TITLE = "locationindoorble.menu_beacon_id";
const CODE_ZONE_TITLE = "locationindoorble.menu_beacon_code_zone";
const DESC_TITLE = "locationindoorble.menu_beacon_description";
const LEVEL_TITLE = "locationindoorble.menu_beacon_level";

const BBE9_MAJOR_MINOR_TIPPY = "locationindoorble.menu_tippy_bbe9_major_minor";
const CODE_ZONE_TIPPY = "locationindoorble.menu_tippy_code_zone";
const DESC_TIPPY = "locationindoorble.menu_tippy_description";
const LEVEL_TIPPY = "locationindoorble.menu_tippy_level";


export default defineComponent({

  props: {
    hideLevelField: {
      type: Boolean,
      required: true,
    },
    beaconList: {
      type: Array,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    nbTimesShow: {
      type: Number,
      required: true,
    }
  },

  emits: ['modifyBbe9Success'],

  data() {
    return {
      bbe9Fields: [
        { title: BEAC_ID_TITLE, tippy: BBE9_MAJOR_MINOR_TIPPY, value: '', len: MAJOR_MINOR_LEN, errorMsg: ''},
        { title: CODE_ZONE_TITLE, tippy: CODE_ZONE_TIPPY, value: '', len: 3, errorMsg: ''},
        { title: DESC_TITLE, tippy: DESC_TIPPY, value: '', len: 50, errorMsg: ''},
        { title: LEVEL_TITLE, tippy: LEVEL_TIPPY, value: '', len: 3, errorMsg: ''},
      ],
    }
  },

  computed: {
    getBbe9Fields() {
      const len = this.hideLevelField ? this.bbe9Fields.length - 1 : this.bbe9Fields.length;
      let newBbe9Fields = new Array(len);
      for (let i = 0, j = 0; i < this.bbe9Fields.length; ++i, ++j) {
        if (this.hideLevelField && i === LEVEL)
          --j
        else 
          newBbe9Fields[j] = this.bbe9Fields[i];
      }
      return newBbe9Fields;
    },
  },

  watch: {
    hideLevelField() {
      this.bbe9Fields[LEVEL].value = '';
      resetError(this.bbe9Fields, LEVEL);
    },

    nbTimesShow: {
      immediate: true,
      handler: function() {
        if (!this.beaconList.length)
          return ;
        const beaconToModify = this.beaconList[this.currentIndex];
        resetAllError(this.bbe9Fields);
        const startIndex = TABLE_INDEX_BEACON_ID;
        const model = getModel(beaconToModify[TABLE_INDEX_COMPANY_ID]);
        const isModelSelectedMatchingInputFieldsType = model.valueOf() === MODEL_BBE9 ? true : false;
        for (let i = 0; i < this.bbe9Fields.length; ++i) {
          if (!isModelSelectedMatchingInputFieldsType && (i === BEACON_ID)) {
            this.bbe9Fields[i].value = '';
            continue ;  
          }
          this.bbe9Fields[i].value = beaconToModify[startIndex + i];
        }
      }
    },
  },

  methods: {

    updateTippyContent(instance) {
      const index = instance.props.delay;
      instance.setContent(this.$t(this.getBbe9Fields[index].tippy));
    },

    check() {
      let error = this.bbe9Fields.length;
      error -= this.checkBbe9BeaconId();
      error -= checkLevel(this.hideLevelField, this.bbe9Fields, LEVEL, ERR_LEVEL);
      error -= checkCodeZone(this.bbe9Fields, CODE_ZONE, ERR_CODE_ZONE);
      error -= checkFieldIsNotEmpty(this.bbe9Fields, DESC, ERR_DESC);
      if (!error && !beaconModifiedAlreadyExisting(this.currentIndex, this.beaconList, BBE9_UUID, this.bbe9Fields[BEACON_ID].value.valueOf(), this.bbe9Fields, ERR_BBE9_ALREADY_EXIST))
        this.emitModifyBbe9Success();
    },

    checkBbe9BeaconId() {
      const beaconId = this.bbe9Fields[BEACON_ID].value.toUpperCase();
      if (!beaconId.length || !regexCheck(beaconId, /^[A-F0-9]{8}/))
        return setError(this.bbe9Fields, BEACON_ID, ERR_BBE9_BEAC_ID);
      return resetError(this.bbe9Fields, BEACON_ID);
    },

    emitModifyBbe9Success() {
      this.$emit('modifyBbe9Success', this.createNewBeacon());
      resetAllFields(this.bbe9Fields);
    },

    createNewBeacon() {
      return [
        BBE9_UUID,
        this.bbe9Fields[BEACON_ID].value.toUpperCase(),
        this.bbe9Fields[CODE_ZONE].value,
        this.bbe9Fields[DESC].value,
        this.bbe9Fields[LEVEL].value,
      ]
    },

  }
})
</script>
