const state = () => {
  return {
    menu: [
      {
        icon: "SlidersIcon",
        pageName: "side-menu-emerit-main-config",
        title: "configuration"
      },
      {
        icon: "UserIcon",
        pageName: "side-menu-emerit-contact",
        title: "contact"
      }
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
