<template>
  <!-- BEGIN: ModalAddModifyRecipient local component-->
  <div>
    <div
      :id="modalId"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ $t(getTitle) }}
            </h2>
          </div>
          <div class="modal-body grid grid-cols-12">
            <div class="col-span-12">
              <div 
                v-if="getInfoText.length"
                class="text-theme-0 opacity-50" 
              >
                {{ $t(getInfoText) }}
              </div>
              <CustomSwitch
                v-for="(topSwitch, index) in getTopSwitches"
                :key="index"
                v-model="topSwitch.value"
                :text="topSwitch.text"
                :is-disabled="topSwitch.disabled"
                class="p-3"
                @change="updateSwitches(index)"
              />
              <CustomTextInput
                v-for="(field, index) in getInputFields"
                :key="index"
                v-model="field.value"
                class="mb-3"
                :title="field.title"
                :max-length="field.maxLen"
                :error-msg="field.errorMsg"
              />
              <CustomSwitch
                v-for="(bottomSwitch, index) in getBottomSwitches"
                :key="index"
                v-model="bottomSwitch.value"
                :text="bottomSwitch.text"
                :is-disabled="bottomSwitch.disabled"
                class="p-3"
                @change="resetBottoMFieldsErrorMsg(index)"
              />
              <ErrorMsg
                :error-msg="bottomSwitchesErrorMsg"
              />
              <FieldXVerticalCheckBoxes
                v-if="switches[3].value"
                v-model="checkboxes.values"
                :descriptions="checkboxes.descriptions"
                :ids="checkboxes.ids"
                :disabled-check-boxes="checkboxes.disabled"
                :error-msg="checkboxes.errorMsg"
                @change="checkboxes.errorMsg = ''"
              />
              <CustomSwitch
                v-if="isLocationEnabled && !isSipActivated && !switches[0].value"
                v-model="getRequestLocSwitch.value"
                :text="getRequestLocSwitch.text"
                :is-disabled="getRequestLocSwitch.disabled"
                class="p-3"
              />
            </div>
          </div>
          <div class="mt-5 modal-footer text-right">
            <ButtonDismissModal
              ref="leftButton"
              custom-class="btn btn-outline-secondary mr-1"
              text="common.menu_cancel"
            />
            <CustomButton
              text="common.menu_confirm"
              @click="check()"
            />
          </div>
        </div>
      </div>
    </div>
    <CustomToast
      ref="toastAddRecipientSuccess"
      toast-id="toast-add-recipient-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="internalrecipients.menu_recipient_added_with_success"
    />
    <CustomToast
      ref="toastModifyRecipientSuccess"
      toast-id="toast-modify-recipient-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="internalrecipients.menu_recipient_modified_with_success"
    />
  </div>
  <!-- END: ModalAddModifyRecipient local component-->
</template>

<script>
import { defineComponent } from 'vue'
import { getBoolPtiValue } from '../../../dao/parametre-pti';
import { getRecipient } from '../../../dao/recipient-dao';
import { getRecipientTakenIntoAccount } from '../../../dao/recipient-taken-into-account';
import { 
  checkFieldIsNotEmpty, 
  resetAllError, 
  resetError, 
  setError 
} from '../../../utils/fieldManagement';
import { isBbe9BeaconRegisteredInDb } from '../../emerit-location-indoor-ble/local-components/utils';
import {
  createRecipientFromDb,
  createRecipientFromDbTakenInAccount,
} from './utils'


const ADD_STATE = 0;
const MODIFY_STATE = 1;

const EMIT_ADD_RECIPIENT = 'add-recipient';
const EMIT_MODIFY_RECIPIENT = 'modify-recipient';

const I18_ADD_RECIPIENT = "internalrecipients.menu_add_recipient";
const I18_MODIFY_RECIPIENT = "internalrecipients.menu_modify_recipient";

const I18_NO_ALERT_CONFIGURATION_WHEN_PEC = "internalrecipients.menu_alert_configuration_not_available_with_pec";
const I18_NO_SMS_SENDING_WHEN_USING_SIP = "internalrecipients.menu_sens_sms_with_sip_not_possible";

const I18_NAME = "internalrecipients.menu_name";
const I18_PHONE_NUMBER = "internalrecipients.menu_phone_number_international_format";
const I18_SIP_ACCOUNT_ID = "internalrecipients.menu_sip_account_id";

const I18_EMERIT_RECIPIENT = "internalrecipients.menu_is_emerit_watch";
const I18_WATCH_IS_USING_SIP_ACCOUNT = "internalrecipients.menu_switch_watch_is_using_sip_account";
const I18_RECEIVE_ALERTS_BY_CALL = "internalrecipients.menu_receive_alerts_by_call";
const I18_RECEIVE_ALERTS_BY_SMS = "internalrecipients.menu_receive_events_by_sms";
const I18_RECEIVE_ALERT_BY_SIP_MESSAGING = "internalrecipients.menu_receive_events_by_sip_messaging";
const I18_REQUEST_LOC_BY_SMS_CMD = "internalrecipients.menu_request_loc_with_sms_command";

const I18_EVENT_ALERT = "internalrecipients.menu_event_alerts";
const I18_EVENT_SYSTEM_STATE = "internalrecipients.menu_event_system_status";
const I18_EVENT_CHARGE = "internalrecipients.menu_event_charge_status";
const I18_EVENT_LOW_BATTERY = "internalrecipients.menu_event_low_battery";
const I18_EVENT_BLE_BEACON_LOW_BATTERY = "internalrecipients.menu_event_beacon_ble_low_battery";

const I18_ERR_SELECT_AT_LEAST_ONE_EVENT = "common.menu_select_at_least_one_event";
const I18_ERR_FIELD_CANT_BE_EMPTY = "common.menu_field_cannot_be_empty";
const I18_ERR_NUMBER_ALREADY_EXIST = "common.menu_phone_number_already_used";
const I18_ERR_WRONG_NUMBER_SIP_FORMAT ="common.menu_phone_number_must_start";
const I18_ERR_WRONG_NUMBER_FORMAT = "common.menu_phone_number_must_start_with_+";
const I18_ERR_SIP_ALREADY_EXIST = "internalrecipients.menu_err_sip_account_already_exist";
const I18_ERR_SELECT_ONE_RECEIVE_METHOD = "internalrecipients.menu_event_select_call_and_or_sms";
const I18_ERR_SELECT_ONE_SIP_RECEIVE_METHOD = "internalrecipients.menu_event_select_call_and_or_sip_messaging";

const CHECKBOX_ID_1 = "checkox-event-id-1";
const CHECKBOX_ID_2 = "checkox-event-id-2";
const CHECKBOX_ID_3 = "checkox-event-id-3";
const CHECKBOX_ID_4 = "checkox-event-id-4";
const CHECKBOX_ID_5 = "checkox-event-id-5";

let i = 0;
const INDEX_NAME = i++;
const INDEX_PHONE_NUMBER = i++;
const INDEX_SIP_ACCOUNT = i++;

i = 0;
const INDEX_IS_EMERIT_RECIPIENT = i++;
const INDEX_IS_WATCH_SIP_RECIPIENT = i++;
const INDEX_RECEIVE_ALERT_BY_CALL = i++;
const INDEX_RECEIVE_ALERT_BY_SMS = i++;
const INDEX_REQUEST_LOC_BY_SMS_CMD = i++;

i = 0;
const INDEX_EV_ALERT = i++;
const INDEX_EV_SYSTEM_STATE = i++;
const INDEX_EV_CHARGE = i++;
const INDEX_EV_LOW_BATTERY = i++;
const INDEX_EV_BEACON_BLE_LOW_BATTERY = i++;

const PTI_PARAM_BLE_LOC_INDEX = 2;
const LOCATION_PTI_PARAMS = [
  "isGpsLocParamEnabled",
  "isWifiLocParamEnabled",
  "isBluetoothLocParamEnabled",
];


export default defineComponent({

  props: {
    modalId: {
      type: String,
      required: true,
    },
    recipientList: {
      type: Array,
      required: true,
    },
    isSipActivated: {
      type: Boolean,
      required: true,
    },
    isPecActivated: {
      type: Boolean,
      required: true,
    },
  },

  emits: [EMIT_ADD_RECIPIENT, EMIT_MODIFY_RECIPIENT],
  
  data() {
    return {
      state: ADD_STATE,
      isLocationEnabled: false,
      isBbe9Registered: false,
      daoCreateRecipient: undefined,
      recipientDbId: undefined,
      inputFields: [
        {
          value: '',
          title: I18_NAME,
          maxLen: 50,
          errorMsg: '',
        },
        {
          value: '',
          title: I18_PHONE_NUMBER,
          maxLen: 16,
          errorMsg: '',
        },
        {
          value: '',
          title: I18_SIP_ACCOUNT_ID,
          maxLen: 50,
          errorMsg: '',
        },
      ],
      switches: [
         {
          value: false,
          text: I18_EMERIT_RECIPIENT,
          disabled: false,
        },
        {
          value: false,
          text: I18_WATCH_IS_USING_SIP_ACCOUNT,
          disabled: false,
        },
        {
          value: false,
          text: I18_RECEIVE_ALERTS_BY_CALL,
          disabled: false,
        }, 
        {
          value: false,
          text: I18_RECEIVE_ALERTS_BY_SMS,
          disabled: false,
        },
        {
          value: false,
          text: I18_REQUEST_LOC_BY_SMS_CMD,
          disabled: false,
        },
      ],
      bottomSwitchesErrorMsg: '',
      checkboxes: {
        values: [false, false, false, false, false],
        disabled: [false, false, false, false, false],
        errorMsg: '',
        descriptions: [
          I18_EVENT_ALERT,
          I18_EVENT_SYSTEM_STATE,
          I18_EVENT_CHARGE,
          I18_EVENT_LOW_BATTERY,
          I18_EVENT_BLE_BEACON_LOW_BATTERY,
        ],
        ids: [
          CHECKBOX_ID_1,
          CHECKBOX_ID_2,
          CHECKBOX_ID_3,
          CHECKBOX_ID_4,
          CHECKBOX_ID_5,
        ],
      },
    }
  },

  computed: {
    getTitle() {
      if (this.state === ADD_STATE)
        return I18_ADD_RECIPIENT;
      return I18_MODIFY_RECIPIENT;
    },

    getInfoText() {
      if (this.isPecActivated)
        return I18_NO_ALERT_CONFIGURATION_WHEN_PEC;
      if (this.isSipActivated && !this.switches[INDEX_IS_WATCH_SIP_RECIPIENT].value)
        return I18_NO_SMS_SENDING_WHEN_USING_SIP;
      return "";
    },

    getTopSwitches() {
      let topSwitches = [];
      topSwitches.push(this.switches[INDEX_IS_EMERIT_RECIPIENT]);
      if (this.isSipActivated && this.switches[INDEX_IS_EMERIT_RECIPIENT].value)
        topSwitches.push(this.switches[INDEX_IS_WATCH_SIP_RECIPIENT]);
      return topSwitches;
    },

    getBottomSwitches() {
      let bottomSwitches = [];
      for (let i = INDEX_RECEIVE_ALERT_BY_CALL; i < INDEX_REQUEST_LOC_BY_SMS_CMD; ++i)
        bottomSwitches.push(this.switches[i]);
      return bottomSwitches;
    },

    getRequestLocSwitch() {
      return this.switches[INDEX_REQUEST_LOC_BY_SMS_CMD];
    },

    getInputFields() {
      let inputFieldsRendered = [];
      inputFieldsRendered.push(this.inputFields[INDEX_NAME]);
      if (this.isSipActivated 
          && this.switches[INDEX_IS_EMERIT_RECIPIENT].value
          && this.switches[INDEX_IS_WATCH_SIP_RECIPIENT].value) {
        for (let i = INDEX_SIP_ACCOUNT; i < this.inputFields.length; ++i)
          inputFieldsRendered.push(this.inputFields[i]);
      }
      else
        inputFieldsRendered.push(this.inputFields[INDEX_PHONE_NUMBER]);
      return inputFieldsRendered;
    },

  },

  created() {
    this.initDaoFunctions();
    this.initIsLocationEnabled();
    this.initEvBeaconBleLowBattery();
  },

  methods: {

    initDaoFunctions() {
      if (this.isPecActivated) {
        this.daoCreateRecipient = createRecipientFromDbTakenInAccount; 
        this.daoGetRecipient = getRecipient;
      }
      else {
        this.daoCreateRecipient = createRecipientFromDb; 
        this.daoGetRecipient = getRecipientTakenIntoAccount;
      }
    },

    initIsLocationEnabled() {
      let isBleLocEnabled = false;
      for (let i = 0; i < LOCATION_PTI_PARAMS.length; ++i) {
        if (getBoolPtiValue(LOCATION_PTI_PARAMS[i])) {
          this.isLocationEnabled = true;
          if (i === PTI_PARAM_BLE_LOC_INDEX)
            isBleLocEnabled = true;
        }
      }
      if (isBleLocEnabled)
        this.isBbe9Registered = isBbe9BeaconRegisteredInDb();
    },
    
    initEvBeaconBleLowBattery() {
      if (!this.isBbe9Registered) {
        this.checkboxes.values.pop();
        this.checkboxes.disabled.pop();
        this.checkboxes.descriptions.pop();
        this.checkboxes.ids.pop();
      }
    },

    initValues(index) {
      if (index === undefined) {
        this.state = ADD_STATE;
        this.resetValues();
      }
      else {
        this.state = MODIFY_STATE;
        this.initModifyValues(index);
      }
      this.initDisabledFields();
      this.resetAllModalErrorMsg();
    },

    resetAllModalErrorMsg() {
      resetAllError(this.inputFields);
      if (this.checkboxes.errorMsg.length)
        this.checkboxes.errorMsg = ''
      if (this.bottomSwitchesErrorMsg.length)
        this.bottomSwitchesErrorMsg = '';
    },

    updateSwitches(index) {
      if (index === INDEX_IS_EMERIT_RECIPIENT && !this.switches[INDEX_IS_EMERIT_RECIPIENT].value)
        this.switches[INDEX_IS_WATCH_SIP_RECIPIENT].value = false;
      if (this.isSipActivated && this.switches[INDEX_IS_WATCH_SIP_RECIPIENT].value)
        this.switches[INDEX_RECEIVE_ALERT_BY_SMS].text = I18_RECEIVE_ALERT_BY_SIP_MESSAGING;
      else
        this.switches[INDEX_RECEIVE_ALERT_BY_SMS].text = I18_RECEIVE_ALERTS_BY_SMS;
      this.initDisabledFields();
    },

    initDisabledFields() {
      for (let i = 0; i < INDEX_REQUEST_LOC_BY_SMS_CMD; ++i)
        this.switches[i].disabled = this.isPecActivated ? true : false;
      if (this.isSipActivated && !this.switches[INDEX_IS_WATCH_SIP_RECIPIENT].value) {
        this.switches[INDEX_RECEIVE_ALERT_BY_CALL].disabled = true;
        this.switches[INDEX_RECEIVE_ALERT_BY_CALL].value = true;
        this.switches[INDEX_RECEIVE_ALERT_BY_SMS].disabled = true;
        this.switches[INDEX_RECEIVE_ALERT_BY_SMS].value = false;
        this.bottomSwitchesErrorMsg = '';
      }
      this.checkboxes.disabled[INDEX_EV_ALERT] = this.isPecActivated ? true : false;
    },

    initModifyValues(index) {
      const recipient = this.recipientList[index];
      this.recipientDbId = recipient.id;
      this.inputFields[INDEX_NAME].value = recipient.name;
      this.inputFields[INDEX_PHONE_NUMBER].value = recipient.number;
      this.inputFields[INDEX_SIP_ACCOUNT].value = recipient.sipAccountId;
      this.switches[INDEX_IS_EMERIT_RECIPIENT].value = recipient.pWatch;
      this.switches[INDEX_IS_WATCH_SIP_RECIPIENT].value = recipient.isSipRecipient;
      this.switches[INDEX_RECEIVE_ALERT_BY_CALL].value = recipient.pCall;
      this.switches[INDEX_RECEIVE_ALERT_BY_SMS].value = recipient.pSms;
      if (this.isPecActivated)
        this.checkboxes.values[INDEX_EV_ALERT] = true;
      else
        this.checkboxes.values[INDEX_EV_ALERT] = recipient.pAlarm;
      this.checkboxes.values[INDEX_EV_SYSTEM_STATE] = recipient.pPowerOnOff;
      this.checkboxes.values[INDEX_EV_CHARGE] = recipient.pChargeStation;
      this.checkboxes.values[INDEX_EV_LOW_BATTERY] = recipient.pLowBattery;
      if (this.isBbe9Registered)
        this.checkboxes.values[INDEX_EV_BEACON_BLE_LOW_BATTERY] = recipient.pBleBeaconLowBattery;
      if (this.isLocationEnabled)
        this.switches[INDEX_REQUEST_LOC_BY_SMS_CMD].value = recipient.pLoc;
    },

    resetValues() {
      this.recipientDbId = undefined;
      for (let i = 0; i < this.inputFields.length; ++i)
        this.inputFields[i].value = '';
      for (let i = 0; i < this.switches.length; ++i) {
        if ((this.isPecActivated && (i === INDEX_RECEIVE_ALERT_BY_CALL || i === INDEX_RECEIVE_ALERT_BY_SMS))
            || (this.isSipActivated && i === INDEX_RECEIVE_ALERT_BY_CALL))
          this.switches[i].value = true;
        else
          this.switches[i].value = false;
      }
      for (let i = 0; i < this.checkboxes.values.length; ++i)
        this.checkboxes.values[i] = false;
      if (this.isLocationEnabled)
        this.switches[INDEX_REQUEST_LOC_BY_SMS_CMD].value = false;
      if (this.isPecActivated)
        this.checkboxes.values[INDEX_EV_ALERT] = true
    },

    check() {
      let nbErrors = 0;
      nbErrors += Number(this.checkInputFields());
      nbErrors += Number(this.checkSwitches());
      nbErrors += Number(this.checkCheckboxes());
      if (!nbErrors)
        this.save();
    },

    save() {
      const newRecipient = this.createRecipientFromModalFields();
      const eventName = this.state === ADD_STATE ? EMIT_ADD_RECIPIENT : EMIT_MODIFY_RECIPIENT;
      this.$emit(eventName, newRecipient);
      this.triggerToast();
      this.$refs.leftButton.click();
    },

    triggerToast() {
      if (this.state === ADD_STATE)
        this.$refs.toastAddRecipientSuccess.trigger();
      else
        this.$refs.toastModifyRecipientSuccess.trigger();
    },

    createRecipientFromModalFields() {
      let recipient = {};
      recipient.id = this.state === ADD_STATE ? -1 : this.recipientDbId; 
      recipient.sortId = false;
      recipient.pPostAlarm = false;
      recipient.pPti = false;
      recipient.sipServerUrl = '';      
      this.addInfoFromInputFields(recipient);
      this.addInfoFromSwitches(recipient);
      this.addInfoFromCheckboxes(recipient);      
      return recipient;
    },

    addInfoFromInputFields(recipient) {
      recipient.name = this.inputFields[INDEX_NAME].value;
      if (this.switches[INDEX_IS_WATCH_SIP_RECIPIENT].value) {
        recipient.number = '';
        recipient.sipAccountId = this.inputFields[INDEX_SIP_ACCOUNT].value;
      }
      else {
        recipient.number = this.inputFields[INDEX_PHONE_NUMBER].value;
        recipient.sipAccountId = '';
      }
    },

    addInfoFromSwitches(recipient) {
      recipient.pCall = this.switches[INDEX_RECEIVE_ALERT_BY_CALL].value;
      recipient.pSms = this.switches[INDEX_RECEIVE_ALERT_BY_SMS].value;
      if (this.isLocationEnabled && !this.isSipActivated && !this.switches[INDEX_IS_EMERIT_RECIPIENT].value)
        recipient.pLoc = this.switches[INDEX_REQUEST_LOC_BY_SMS_CMD].value;
      else
        recipient.pLoc = false;
      recipient.pWatch = this.switches[INDEX_IS_EMERIT_RECIPIENT].value;
      recipient.isSipRecipient = this.switches[INDEX_IS_WATCH_SIP_RECIPIENT].value;
    },

    addInfoFromCheckboxes(recipient) {
      recipient.pAlarm = this.checkboxes.values[INDEX_EV_ALERT];
      recipient.pPowerOnOff = this.checkboxes.values[INDEX_EV_SYSTEM_STATE];
      recipient.pChargeStation = this.checkboxes.values[INDEX_EV_CHARGE];
      recipient.pLowBattery = this.checkboxes.values[INDEX_EV_LOW_BATTERY];
      if (this.isBbe9Registered)
        recipient.pBleBeaconLowBattery = this.checkboxes.values[INDEX_EV_BEACON_BLE_LOW_BATTERY];
      else
        recipient.pBleBeaconLowBattery = false;
    },

    checkInputFields() {
      const inputFieldsComputed = this.getInputFields;
      let nbErrors = inputFieldsComputed.length;
      for (let i = 0; i < inputFieldsComputed.length; ++i){
        nbErrors -= checkFieldIsNotEmpty(inputFieldsComputed, i, I18_ERR_FIELD_CANT_BE_EMPTY);
      }
      if (this.isSipActivated && !this.switches[INDEX_IS_WATCH_SIP_RECIPIENT].value){
        if (this.switches[INDEX_IS_WATCH_SIP_RECIPIENT].value
          && !this.inputFields[INDEX_SIP_ACCOUNT].errorMsg.length)
        nbErrors += !this.checkSip();
      }else{
      if (!this.switches[INDEX_IS_WATCH_SIP_RECIPIENT].value 
          && !this.inputFields[INDEX_PHONE_NUMBER].errorMsg.length){
            nbErrors += !this.checkPhoneNb();
          }
      }
      return nbErrors;
    },

    checkPhoneNb() {  
      const phoneNb = this.inputFields[INDEX_PHONE_NUMBER].value;
      if (phoneNb[0] != '+' || phoneNb.length === 1)
        return setError(this.inputFields, INDEX_PHONE_NUMBER, I18_ERR_WRONG_NUMBER_FORMAT);
      for (let i = 1; i < phoneNb.length; ++i)
        if (phoneNb[i] < '0' || phoneNb[i] > '9')
          return setError(this.inputFields, INDEX_PHONE_NUMBER, I18_ERR_WRONG_NUMBER_FORMAT); 
     
     return true;
    },


    checkSip() {
      const sipInfos = this.inputFields[INDEX_SIP_ACCOUNT].value;
      if (sipInfos[0]!= ''|| sipInfos.length ===1)
       return setError(this.inputFields, INDEX_SIP_ACCOUNT, I18_ERR_WRONG_NUMBER_SIP_FORMAT);
      if (this.state === ADD_STATE)
        return this.checkAddSip(sipInfos);
      return this.checkModifySip(sipInfos);
    },

    checkAddSip(sipInfos) {
      for (let i = 0; i < this.recipientList.length; ++i)
        if (sipInfos.valueOf() === this.recipientList[i].sipAccountId.valueOf())
          return setError(this.inputFields, INDEX_SIP_ACCOUNT, I18_ERR_SIP_ALREADY_EXIST);
      return resetError(this.inputFields, INDEX_SIP_ACCOUNT);
    },

    checkModifySip(sipInfos) {
      for (let i = 0; i < this.recipientList.length; ++i) {
        if (this.recipientList[i].id === this.recipientDbId)
          continue ;
        if (sipInfos.valueOf() === this.recipientList[i].sipAccountId.valueOf())
          return setError(this.inputFields, INDEX_SIP_ACCOUNT, I18_ERR_SIP_ALREADY_EXIST);
      }
      return resetError(this.inputFields, INDEX_SIP_ACCOUNT);
    },

    checkSwitches() {
      if (!this.switches[INDEX_RECEIVE_ALERT_BY_CALL].value
          && !this.switches[INDEX_RECEIVE_ALERT_BY_SMS].value)
        this.bottomSwitchesErrorMsg = this.getBottomSwitchesErrorMsg();
      else
        this.bottomSwitchesErrorMsg = '';
      return this.bottomSwitchesErrorMsg.length > 0;
    },

    getBottomSwitchesErrorMsg() {
      if (this.isSipActivated && this.switches[INDEX_IS_EMERIT_RECIPIENT].value
          && this.switches[INDEX_IS_WATCH_SIP_RECIPIENT].value)
        return I18_ERR_SELECT_ONE_SIP_RECEIVE_METHOD;
      return I18_ERR_SELECT_ONE_RECEIVE_METHOD;
    },

    checkCheckboxes() {
      let nbError = 0;
      if (this.switches[INDEX_RECEIVE_ALERT_BY_SMS].value) {
        let nbTrue = 0;
        for (let i = 0; i < this.checkboxes.values.length; ++i)
          nbTrue += Number(this.checkboxes.values[i])
        nbError = !nbTrue;
      }
      if (nbError)
        this.checkboxes.errorMsg = I18_ERR_SELECT_AT_LEAST_ONE_EVENT;
      else
        this.checkboxes.errorMsg = '';
      return this.checkboxes.errorMsg.length > 0;
    },

    resetBottoMFieldsErrorMsg(index) {
      this.bottomSwitchesErrorMsg = '';
      if (index === 1)
        this.checkboxes.errorMsg = '';
    },

    dismiss() {
      this.$refs.leftButton.click();
    },
  },

})
</script>
