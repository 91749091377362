<template>
  <!-- BEGIN: CustomWidgetScreen local component-->
  <div>
    <CheckBoxTitle
      v-model="isCustomWidgetScreen"
      color="bg-gray-400"
      title-yes="customscreens.menu_title_yes_custom_widget_screen"
      title-no="customscreens.menu_title_no_custom_widget_screen"
    />
    <div 
      v-if="isCustomWidgetScreen"
      class="clearfix box"
    >
      <div class="clearfix p-3 border-b border-gray-200">
        <ButtonUploadFile
          text="customscreens.menu_upload_widget_screen_img"
          accept=".png,.gif"
          custom-class="btn btn-warning"
          @file-uploaded="checkFile($event[0])"
        />
        <ErrorMsg
          :error-msg="imgUploadErrorMsg"
          class="mt-2"
        />
        <div
          v-if="imgArrayBuffer"
        >
          <CustomButton
            text="customscreens.menu_remove_widget_img"
            custom-class="btn btn-danger mt-3"
            @click="removeImg()"
          />
        </div>
        <div class="font-bold italic ml-3 mt-3">
          {{ $t("customscreens.menu_custom_screens_overview") }}
        </div>
        <div class="relative h-24 w-32 mt-1 bg-black">
          <img
            v-if="imgArrayBuffer"
            :src="getImgBlob"
            style="width: 128px; height: 96px;"
          >
          <div 
            v-for="(field, index) in inputFields"
            :key="index"
            class="absolute text-center inset-x-0"
            :class="getTextColor + ' ' + getTextPosition(index)"
            style="font-size: 11px; line-height: 16px;"
          >
            {{ inputFields[index].value }}
          </div>
        </div>
      </div>
      <div class="clearfix p-3 border-b border-gray-200">
        <CustomTextInput
          v-for="(field, index) in inputFields"
          :key="index"
          v-model="field.value"
          :title="field.title"
          :max-length="18"
          class="mb-3"
        />
        <CustomSwitch
          v-model="isTextInBlack"
          text="customscreens.menu_display_widget_text_in_black"
          class="mt-3"
        />
      </div>
    </div>
  </div>
  <!-- END: CustomWidgetScreen local component-->
</template>

<script>
import { defineComponent } from 'vue'
import { 
  getBoolPtiValue, 
  getParameterPTIValue, 
  setNumberPtiValue, 
  setParameterPTIValue
} from '../../../dao/parametre-pti'
import { resetAllFields } from '../../../utils/fieldManagement';
import {
  checkIfFileIsImg, 
  checkSize,
} from './utils';
import { useStore } from '@/store';
import { 
  STORE_SET_WIDGET_SCREEN_IMG_FILE, 
  STORE_SET_WIDGET_SCREEN_IMG_UPDATE_S3 
} from '../../../store/const_store';


const I18_TITLE_TOP_TEXT = "customscreens.menu_custom_widget_top_text";
const I18_TITLE_BOTTOM_TEXT = "customscreens.menu_custom_widget_bottom_text";

const EV_UPLOAD_SUCCESS = 'upload-success';
const EV_UPLOAD_FAIL = 'upload-fail';

const PARAM_PTI_IS_CUSTOM_WIDGET_SCREEN_ENABLED = "isCustomWidgetScreenEnabled";
const PARAM_PTI_IS_WIDGET_SCREEN_IMG_UPLOADED = "isWidgetScreenImgUploaded";

const PARAM_PTI_WIDGET_TOP_TEXT = "customWidgetTopText";
const PARAM_PTI_WIDGET_BOTTOM_TEXT = "customWidgetBottomText";
const PARAM_PTI_IS_CUSTOM_WIDGET_TEXT_IN_BLACK = "isCustomWidgetTextInBlack";

const CLASS_TEXT_WHITE = "text-white";
const CLASS_TEXT_BLACK = "text-black";

const CLASS_POSITION_TOP = "top-2";
const CLASS_POSITION_BOTTOM = "bottom-1";

const INDEX_TEXT_TOP = 0;
const INDEX_TEXT_BOTTOM = 1;

const checkFunctions = [
  checkIfFileIsImg,
  checkSize,
];

const I18_ERR_IMG_UPLOAD = [
  "customscreens.menu_err_only_png_gif_accepted",
  "customscreens.menu_err_img_is_too_heavy",
];
const I18_ERR_PLS_UPLOAD_IMG_OR_TEXT = "customscreens.menu_err_pls_upload_widget_screen";


export default defineComponent({

  emits: [
    EV_UPLOAD_SUCCESS,
    EV_UPLOAD_FAIL    
  ],

  data() {
    return {
      isCustomWidgetScreen: undefined,
      imgArrayBuffer: undefined,
      updateS3Img: undefined,
      imgUploadErrorMsg: '',
      inputFields: [
        {
          title: I18_TITLE_TOP_TEXT,
          value: '',
          ptiParam: PARAM_PTI_WIDGET_TOP_TEXT,
        },
        {
          title: I18_TITLE_BOTTOM_TEXT,
          value: '',
          ptiParam: PARAM_PTI_WIDGET_BOTTOM_TEXT,
        }
      ],
      isTextInBlack: undefined,
    }
  },

  computed: {
    getTextColor() {
      if (this.isTextInBlack)
        return CLASS_TEXT_BLACK;
      return CLASS_TEXT_WHITE;
    },

    getImgBlob() {
      return URL.createObjectURL(
        new Blob([this.imgArrayBuffer], { type: 'image' })
      );
    }
  },

  watch: {
    isCustomWidgetScreen() {
      this.updateS3Img = true;
      this.imgUploadErrorMsg = '';
    },
  },

  created() {
    this.isCustomWidgetScreen = getBoolPtiValue(PARAM_PTI_IS_CUSTOM_WIDGET_SCREEN_ENABLED);
    this.updateS3Img = useStore().state.emeritValues.widgetScreenImg.updateS3;
    if (getBoolPtiValue(PARAM_PTI_IS_WIDGET_SCREEN_IMG_UPLOADED))
      this.imgArrayBuffer = useStore().state.emeritValues.widgetScreenImg.file;
    this.isTextInBlack = getBoolPtiValue(PARAM_PTI_IS_CUSTOM_WIDGET_TEXT_IN_BLACK);
    for (let i = 0; i < this.inputFields.length; ++i)
      this.inputFields[i].value = getParameterPTIValue(this.inputFields[i].ptiParam);
  },

  methods: {

    removeImg() {
      this.imgArrayBuffer = undefined;
      this.updateS3Img = true;
    },

    getTextPosition(index) {
      if (index === INDEX_TEXT_TOP)
        return CLASS_POSITION_TOP;
      return CLASS_POSITION_BOTTOM;
    },

    checkFile(file) {
      for (let i = 0; i < checkFunctions.length; ++i) {
        if (!checkFunctions[i](file)) {
          this.$emit(EV_UPLOAD_FAIL)  
          this.imgUploadErrorMsg = I18_ERR_IMG_UPLOAD[i];
          return ;
        }
      }
      this.imgUploadErrorMsg = '';
      this.updateS3Img = true;
      this.createImage(file);
      this.$emit(EV_UPLOAD_SUCCESS);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imgArrayBuffer = e.target.result;
      };
      reader.readAsArrayBuffer(file);
    },
    
    check() {
      if (this.isCustomWidgetScreen && !this.imgArrayBuffer
          && !this.inputFields[INDEX_TEXT_TOP].value.length
          && !this.inputFields[INDEX_TEXT_BOTTOM].value.length) {
        this.imgUploadErrorMsg = I18_ERR_PLS_UPLOAD_IMG_OR_TEXT;
        return false;
      }
      this.imgUploadErrorMsg = '';
      return true;
    },

    save() {
      if (!this.isCustomWidgetScreen)
        this.resetWidgetParams();
      let isImgUploaded = this.imgArrayBuffer != undefined;
      setNumberPtiValue(PARAM_PTI_IS_CUSTOM_WIDGET_SCREEN_ENABLED, this.isCustomWidgetScreen);
      setNumberPtiValue(PARAM_PTI_IS_WIDGET_SCREEN_IMG_UPLOADED, isImgUploaded);
      this.$store.commit(STORE_SET_WIDGET_SCREEN_IMG_FILE, this.imgArrayBuffer);
      this.$store.commit(STORE_SET_WIDGET_SCREEN_IMG_UPDATE_S3, this.updateS3Img);
      for (let i = 0; i < this.inputFields.length; ++i)
        setParameterPTIValue(this.inputFields[i].ptiParam, this.inputFields[i].value);
      setNumberPtiValue(PARAM_PTI_IS_CUSTOM_WIDGET_TEXT_IN_BLACK, this.isTextInBlack);
    },

    resetWidgetParams() {
      resetAllFields(this.inputFields);
      this.isTextInBlack = false;
      this.imgArrayBuffer = undefined;
    }

  }
})
</script>
