<template>
  <!-- BEGIN: CustomSelect -->
  <div>
    <div class="flex flex-row">
      <label 
        :class="titleClass"
        class="mr-1 mt-1"
      >
        {{ $t(title) }}
      </label>
      <CustomButton
        v-if="helpLocale"
        class="hover:translate-y-1"
        icon-component-name="HelpCircleIcon"
        custom-class="transform hover:-translate-y-1 pb-2"
        @click.stop="openLink($t(deviceStr + 'help.' + helpLocale))"
      />
    </div>
    <select
      class="form-select"
      :value="modelValue"
      :disabled="disabled"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="getValue(index)"
      >
        {{ option }}
      </option>
    </select>
    <p 
      v-if="errorMsg.length != 0"
      class="mt-1"
    >
      {{ $t(errorMsg) }}
    </p>
  </div>
  <!-- END: CustomSelect -->
</template>

<script>
import { defineComponent } from 'vue'
import { useStore } from "@/store"

/**
 * -------------------------- DESCRIPTION ---------------------------
 * A select with a title and several choices. v-model MUST be used on 
 * this component, the original value of the select will be automatically 
 * bind to the parent variable. Additional help button can be added next
 * to the title.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} modelValue
 *    Value of the select. Automatically sets when using v-model on this
 *    component. For example, v-model="parentVariable" will bind this 
 *    modelValue to parentVariable. Will be initialized with parent variable
 *    value if it matches one option; otherwise select will be in a 
 *    non-initialized state.
 * 
 * @param {String} title
 *    The title above the select. Should be i18 key for the locale 
 *    (ex: 'common.menu_save').
 * 
 * @param {String} titleClass
 *    A custom class that can override default title class. Do not fill this 
 *    props if you're ok with default style.   
 * 
 * @param {Array[String]} options
 *    The differents options for select that the user can choose. Number 
 *    of options can be from 1 to x options.
 * 
 * @param {Array[String]} values
 *    An optionnal props to associate custom values to select fields.
 *    If this props is filled, v-model will be uptaded with those values,
 *    otherwise it will be updated with options props.
 * 
 * @param {Boolean} disabled
 *    If sets to true, this component is disabled. False by default, do 
 *    not fill this props if you do not need it.
 * 
 * @param {String} helpLocale
 *    Second part of the i18 locale (ex: 'menu_loc_outdoor') in order to make 
 *    appear a help button at the right of the title which redirects to online 
 *    help. First part of the locale ('watchhelp' or 'phonehelp') is 
 *    automatically managed. If undefined, the button does not appear. 
 *    Undefined by default. 
 * 
 * @param {String} errorMsg
 *    If empty, nothing appears. Otherwise, this string will appear 
 *    as an error in red to the user. Should be key for i18 locale.
 *    Empty by default, if no error management is needed do not fill it.
 * 
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits update:modelValue
 * @return {String}
 *    Emits the value of the select field. When v-model is
 *    used on this component, v-model automatically updates with this
 *    value. A data check should be performed on this string, and if 
 *    this one isn't corret errorMsg should be set with the appropriate 
 *    error message to inform the user.
 *              
 * ------------------------------------------------------------------
 */
export default defineComponent({
  
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    titleClass: {
      type: String,
      default: 'form-label font-bold',
    },
    options: {
      type: Array,
      required: true,
    },
    values: {
      type: Array,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    helpLocale: {
      type: String,
      default: undefined,
    },
    errorMsg: {
      type: String,
      default: '',
    }
  },
  
  emits: ["update:modelValue"],

  data() {
    return {
      deviceStr: useStore().state.emeritValues.deviceStr,
    }
  },

  methods: {
    getValue(index) {
      if (this.values)
        return this.values[index];
      return this.options[index];
    },

    openLink(link) {
      window.open(link);
    },

  }
})
</script>
