<template>
  <!-- BEGIN: BleBeaconSettings local component-->
  <div class="intro-y col-span-12 lg:col-span-6 lg:col-start-1 2xl:col-span-5 2xl:col-start-1">
    <BoxTitle
      color="bg-red-300"
      title="common.menu_settings"
    />
    <div class="clearfix box mb-6">
      <div class="clearfix p-3 border-b border-gray-200">
        <div class="grid grid-cols-12 mt-1">
          <div class="col-span-8 col-start-1 mt-3 font-bold text-theme-0">
            {{ showNbBleBeaconsSaved }}
          </div>
          <ButtonTargetModal
            v-if="nbBleBeacons"
            target-id="#modal-alert-confirm"
            custom-class="btn btn-danger col-start-10 col-span-3 text-center"
            text="common.menu_remove_everything"
            @click="$emit('remove-all-beacons')"
          />
        </div>
        <CustomSwitch
          v-model="isLevelActivated"
          text="locationindoorble.menu_level_precision_with_ble"
          :error-msg="levelErrorMsg"
          :is-disabled="bleMode === '2'"
          class="mt-1 p-2"
     
        />
        <CustomSwitch
          v-model="showImportExportButtons"
          class="mb-1 p-2"
          text="locationindoorble.menu_enable_import_export_csv"
          error-msg=""
        />
         <div class="col-span-8 col-start-1 mt-3 font-bold text-theme-0">
          
               {{ $t("locationindoorble.menu_title_refresh_ble_choice") }}
          </div>
      <div>
      </div>
        <div class="col-span-8 col-start-1 mt-3">
          <CustomRadioInput
            v-for="(radio, index) in radioInputParams"
            :key="index"
            v-model="bleMode"
            :custom-id="radioGroupName + index"
            :description="radio.description"
            :radio-group-name="radioGroupName"
            :value="index"
             @change="handleBleModeChange"
          />
        </div>
      </div>
      
    </div>
  </div>
  <!-- END: BleBeaconSettings local component-->
</template>

<script>
import { defineComponent } from 'vue'
import {
  getBoolPtiValue,
  setNumberPtiValue,
  getParameterPTIValue,
  setParameterPTIValue,
} from "../../../dao/parametre-pti";

const NO_BEACONS_SAVED = "locationindoorble.menu_no_ble_beacon_recorded";
const NB_BEACON_SAVED = "locationindoorble.menu_number_of_registered_ble_beacons";

const REMOVE_ONE_ITEM = 0;
const REMOVE_EVERYTHING = 1;

const PARAM_PTI_IS_BLE_LEVEL_ACTIVATED = "IndoorBleLevelPrecisionActivated";


export default defineComponent({

  props: {
    nbBleBeacons: {
      type: Number,
      required: true,
    },
    levelErrorMsg: {
      type: String,
      required: true,
    }
  },

  emits: [
    'remove-all-beacons',
    "ImportExportBlockStatusChanged",
    "bleStateChanged"
  ],

   mounted() {
    document.getElementById(this.radioGroupName + this.bleMode).checked = true;
     console.log("After Initialization - bleMode:", this.bleMode);
  },

  data() {
    return {
      bleMode:undefined,
      isbuttonNewAlgoGeoloc: undefined,
        radioGroupName: 'radioBleScanMode',
       radioInputParams: [
        { description: 'locationindoorble.menu_scan_mode_fastest' },
        { description: 'locationindoorble.menu_scan_mode_average' },
        { description: 'locationindoorble.menu_scan_mode_on_demand' },
        ],
      isLevelActivated: undefined,
      showImportExportButtons: false,
      removeEverything: REMOVE_EVERYTHING,
    }
  },

  computed: {

    showNbBleBeaconsSaved() {
      if (!this.nbBleBeacons)
        return this.$t(NO_BEACONS_SAVED);
      return this.$t(NB_BEACON_SAVED) + this.nbBleBeacons;
    },
  },

  watch: {
    isLevelActivated: {
      handler: function(newVal) {
        setNumberPtiValue(PARAM_PTI_IS_BLE_LEVEL_ACTIVATED, newVal);
        this.$emit('bleStateChanged', newVal);
      }
    },

    showImportExportButtons(newVal) {
      this.$emit('ImportExportBlockStatusChanged', newVal);
    }
  },

  created() {
    this.bleMode = getParameterPTIValue("bleScanMode");
    this.isLevelActivated = getBoolPtiValue(PARAM_PTI_IS_BLE_LEVEL_ACTIVATED);
  },

   methods: {
      save() {
        setParameterPTIValue("bleScanMode", this.bleMode);
        return true;
    },
      handleBleModeChange(newBleMode) {
        if(this.bleMode === '2'){
           this.isLevelActivated = false;
        }
     },
     intToBoolean(value) {
      if (value === "1") {
        return true;
      }
      return false;
    },   

      booleanToInt(value) {
      if (value == true) {
        return "1";
      }
      return "0";
    },
    
   },


})
</script>
