<template>
  <!-- BEGIN: ThresholdValues local component -->
  <div class="grid grid-cols-12 gap-6 mt-6">
    <div class="intro-y col-span-12 lg:col-span-6">
      <BoxTitle
        color="bg-red-300"
        title="wristalert.menu_choose_threshold"
      />
      <div class="clearfix flex-row p-3 border-b border-gray-200 box">
        <CustomRadioInput
          v-for="(desc, index) in thresholdWristFieldsDesc"
          :key="index"
          v-model.number="thresoldValue"
          :custom-id="radioGroupNameThresoldValues + index"
          :radio-group-name="radioGroupNameThresoldValues"
          :description="desc"
          :value="index"
        />
      </div>
    </div>
  </div>
  <!-- END: ThresholdValues local component -->
</template>

<script>
import { defineComponent } from 'vue'
import { 
  setNumberPtiValue,
  getNumberPtiValue 
} from '../../../dao/parametre-pti';


const LOW_THRESOLD_VALUE = "wristalert.menu_thresold_value_low";
const MIDDLE_THRESOLD_VALUE = "wristalert.menu_thresold_value_middle";
const HIGH_THRESOLD_VALUE = "wristalert.menu_thresold_value_high";

const PARAM_PTI_WRIST_THRESHOLD_VALUE = "WristMouvementThreshold";
const THRESHOLD_RADIO_GROUP_NAME = 'alert-mode-radio-Thresold';

export default defineComponent({

  data() {
    return {
      thresholdWristFieldsDesc: [LOW_THRESOLD_VALUE, MIDDLE_THRESOLD_VALUE,HIGH_THRESOLD_VALUE],
      radioGroupNameThresoldValues: THRESHOLD_RADIO_GROUP_NAME,
      thresoldValue: undefined,
    }
  },

  created(){
    this.thresoldValue = getNumberPtiValue(PARAM_PTI_WRIST_THRESHOLD_VALUE);
  },

  mounted() {
    document.getElementById(this.radioGroupNameThresoldValues + this.thresoldValue).checked = true;
  },

  methods: {

    save() {
      setNumberPtiValue(PARAM_PTI_WRIST_THRESHOLD_VALUE, this.thresoldValue);
    },
  }

})
</script>
