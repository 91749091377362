import { useStore } from "@/store";

const TABLE_NAME_WIFI_TRANSPARENT = "wifi_transparent";
const COLUMN_WIFI_SSID_FILTER = "ssid_filter";
const SSID = 0;

export const getTransparentWifiBeacons = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * FROM " + TABLE_NAME_WIFI_TRANSPARENT;
  try {
    return dbFile.exec(sqlScript)[0];
  } catch (error) {
    console.log("getTransparentWifiBeacons failed: " + error);
  }
};

export const addTransparentWifiBeacon = hotspot => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "INSERT INTO " + TABLE_NAME_WIFI_TRANSPARENT + ' ("' + COLUMN_WIFI_SSID_FILTER + '") VALUES("' + hotspot[SSID] + '")';

  try {
    return dbFile.exec(sqlScript);
  } catch (error) {
    console.log("addTransparentWifiBeacon failed: " + error);
  }
};

export const deleteTransparentAllWifiBeacons = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "DELETE FROM " + TABLE_NAME_WIFI_TRANSPARENT;
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("deleteTransparentAllWifiBeacons failed: " + error);
  }
};

export const deleteTransparentWifiBeacon = wifi_id => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "DELETE FROM " + TABLE_NAME_WIFI_TRANSPARENT + " WHERE " + COLUMN_WIFI_SSID_FILTER + '="' + wifi_id + '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("deleteTransparentWifiBeacon failed: " + error);
  }
};

export const getTransparentWifiBeaconsCount = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT COUNT(*) FROM " + TABLE_NAME_WIFI_TRANSPARENT;
  try {
    return dbFile.exec(sqlScript)[0].values[0];
  } catch (error) {
    console.log("getTransparentWifiBeaconsCount failed: " + error);
  }
};

export const getTransparentWifiBeaconId = ssid_filter => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT _id FROM " + TABLE_NAME_WIFI_TRANSPARENT + " WHERE " + COLUMN_WIFI_SSID_FILTER + '="' + ssid_filter + '"';
  try {
    return dbFile.exec(sqlScript)[0].values;
  } catch (error) {
    console.log("getTransparentWifiBeaconId failed: " + error);
  }
};

export const updateTransparentWifiBeacon = (id, ssid_filter) => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "UPDATE " + TABLE_NAME_WIFI_TRANSPARENT + " SET " + COLUMN_WIFI_SSID_FILTER + '="' + ssid_filter + '" WHERE _id=' + id;
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("updateTransparentWifiBeacon failed: " + error);
  }
};
