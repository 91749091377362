/* eslint-disable */
<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-6">
      <!-- BEGIN: left column-->
      <div class="intro-y col-span-12 lg:col-span-6">
        <NotifDeviceUpdated
          :notif-state="notifState"
          @hide-notif="notifState = 0"
        />
        <UserDeviceId
          ref="UserDeviceId"
          :is-deployment="isDeployment"
          @reset-validation-button="resetValidationButton()"
        />
        <DeployCred
          v-if="isDeployment"
          ref="DeployCred"
          @input="resetValidationButton()"
        />
        <AlertRecipientSettings
          ref="AlertRecipientSettings"
          :is-sip-available="sip.isAvailable"
          :is-sip-activated="sip.isActivated"
          @change="resetValidationButton()"
        />
        <AlertTriggeringMode
          ref="AlertTriggeringMode"
          :is-ewg-100="isEwg100"
          :device-model-name="deviceModelName"
          @change="resetValidationButton()"
        />
        <LocationSettings
          ref="LocationSettings"
          @change="resetValidationButton()"
        />
      </div>
      <!-- END: left column-->
      <!-- BEGIN: right column-->
      <div class="intro-y col-span-12 lg:col-span-6">
        <CommunicationOption
          ref="CommunicationOption"
          :is-ewg100="isEwg100"
          :is-deployment="isDeployment"
          :is-sip-available="sip.isAvailable"
          @trigger-modal-confirm-sip="triggerModalConfirmSip"
          @change="resetValidationButton()"          
        />
        <BatteryMonitoring
          :is-ewg-100="isEwg100"
          @change="resetValidationButton()"
        />
        <EnableDisableAlertSystem
          v-if="!isEwg100"
          ref="EnableDisableAlertSystem"
          @change="resetValidationButton()"
        />
        <DeviceNotification
          ref="DeviceNotification"
          :is-ewg-100="isEwg100"
          @change="resetValidationButton()"
        />
        <SystemOption
          v-if="isEwg100"
          ref="SystemOption"
          :is-sip-activated="sip.isActivated"
          @change="resetValidationButton()"
        />
      </div>
      <!-- END: right column-->
    </div>
    <div class="intro-y lg:flex">
      <CustomButton
        v-if="btnState != 0"
        class="mr-3 mt-6"
        :text="getValidateButtonText"
        :custom-class="getValidateButtonClass"
        @click="onValidateButtonClicked"
      />
      <CustomButton
        v-if="isShowDownloadButton"
        text="configuration.menu_download_configuration_model_to_computer"
        @click="onDownloadToPcButtonClicked"
      />
    </div>
    <ModalAlertConfirmChoice
      ref="modalAlertConfirmChoice"
      modal-id="modal-alert-confirm"
      alert-title="common.menu_are_you_sure"
      :alert-text="modalAlertConfirmText"
      icon-component-name="AlertTriangleIcon"
      left-button-component-name="ButtonDismissModal"
      left-button-text="common.menu_cancel"
      left-button-custom-class="btn btn-outline-secondary mr-1"
      right-button-component-name="ButtonDismissModal"
      right-button-text="common.menu_confirm"
      right-button-custom-class="btn btn-danger"
      @right-button="updateSipIsActivated"
    />
    <ButtonTargetModal
      ref="buttonTargetSipModal"
      target-id="#modal-alert-confirm"
      text="common.menu_save"
      custom-class="btn btn-primary invisible"
    />
    <CustomToast
      ref="toastCheckEmeritVersion"
      toast-id="toast-check-emerit-version"
      icon-component-name="alert-triangle-icon"
      icon-component-class="text-yellow-500"
      text="configuration.menu_model_check_emerit_version"
    />
    <CustomToast
      ref="toastImgUploadFailure"
      toast-id="toast-img-upload-failure"
      icon-component-name="XCircleIcon"
      icon-component-class="text-theme-6"
      text="configuration.menu_img_upload_failure"
    />
  </div>
</template>

<script>

import {
  getBoolPtiValue, 
  getParameterPTIValue, 
  setNumberPtiValue,
  setParameterPTIValue,
  getNumberPtiValue
} from "../../dao/parametre-pti";
import { getOptionsValue } from "../../dao/options-dao";
import { useStore } from "@/store";
import { PATH_MODEL_LOGIN } from "../../utils/const_path";
import { 
  FILENAME_LAUNCH_IMG,
  FILENAME_WIFI_CERT,
  STORE_DOWNLOAD_ZIP_CONFIG_TO_PC,
  STORE_S3_IMG_FILES_INFO, 
  STORE_SET_LAUNCH_SCREEN_IMG_FILE, 
  STORE_SET_LAUNCH_SCREEN_IMG_UPDATE_S3, 
  STORE_SET_WIDGET_SCREEN_IMG_FILE, 
  STORE_SET_WIDGET_SCREEN_IMG_UPDATE_S3,
  STORE_SET_WIFI_PEAP_CERTIFICATE_UPDATE_S3,
  STORE_UPLOAD_DB_FILE_TO_S3,
  STORE_UPLOAD_IMG_FILE_TO_S3,
  STORE_UPLOAD_WIFI_PEAP_CERTIFICATE_FILE_TO_S3,
} from '../../store/const_store';
import UserDeviceId from './local-components/lc-user-device-id.vue'
import AlertRecipientSettings from './local-components/lc-alerts-recipients-settings.vue'
import AlertTriggeringMode from './local-components/lc-alert-triggering-mode.vue'
import LocationSettings from './local-components/lc-location-settings.vue'
import BatteryMonitoring from './local-components/lc-battery-monitoring.vue'
import SystemOption from './local-components/lc-system-option.vue'
import EnableDisableAlertSystem from './local-components/lc-enable-disable-alert-system.vue'
import DeployCred from './local-components/lc-deploy-cred.vue'
import CommunicationOption from './local-components/lc-communication-option.vue'
// import test from './local-components/test.vue'
import DeviceNotification from './local-components/lc-device-notification.vue'
import { isLoginPaths } from '../../utils/store_utils';
import { resetRecipientsBeaconBleLowBatteryEvent } from '../../dao/recipient-dao';
import { resetRecipientsTakenInAccountBeaconBleLowBatteryEvent } from '../../dao/recipient-taken-into-account';
import { resetRecipientSupIpBeaconBleLowBatteryEvent,getCountRecipientSupIp,updateSupIpExDataBeaconId } from '../../dao/sup-ip-dao';
import { resetRecipientTlsIpBeaconBleLowBatteryEvent,updateTlsIpExDataBeaconId,getCountRecipientTlsIp } from '../../dao/tls-ip-dao';

const MODEL_EWG100 = "E-WG100";
const PTI_IS_SIP_AVAILABLE = "SipManagerAvailable"
const PTI_IS_SIP_ACTIVATED = "SipActivationState";

const I18_BTN_VALIDATE = "configuration.menu_validate_configuration";
const I18_BTN_UPDATE = "configuration.menu_update_configuration";

const YELLOW_BTN_CLASS = "btn btn-warning";
const BLUE_BTN_CLASS = "btn btn-primary";

const I18_ENABLING_SIP_WILL_REMOVE_RECIPIENTS = "configuration.menu_enabling_sip_will_remove_recipients";
const I18_DISABLING_SIP_WILL_REMOVE_RECIPIENTS = "configuration.menu_disabling_sip_will_remove_recipients";

const MODAL_ID_ALERT_CONFIRM = "modal-alert-confirm";
const BODY = "BODY";

const BTN_STATE_HIDE = 0;
const BTN_STATE_VALIDATE = 1;
const BTN_STATE_UPDATE = 2;

const NOTIF_STATE_HIDE = 0;
const NOTIF_STATE_SUCCESS = 1;
const NOTIF_STATE_FAIL = 2;

const PARAM_PTI_IS_BLE_LOC_ENABLED = "isBluetoothLocParamEnabled";

const PARAM_PTI_IS_CUSTOM_LAUNCH_SCREEN_ENABLED =  "isCustomLaunchScreenEnabled";
const PARAM_PTI_IS_CUSTOM_WIDGET_SCREEN_ENABLED =  "isCustomWidgetScreenEnabled";
const PARAM_PTI_IS_LAUNCH_SCREEN_IMG_UPLOADED = "isLaunchScreenImgUploaded";
const PARAM_PTI_IS_WIDGET_SCREEN_IMG_UPLOADED = "isWidgetScreenImgUploaded";
const PARAM_PTI_WIDGET_TOP_TEXT = "customWidgetTopText";
const PARAM_PTI_WIDGET_BOTTOM_TEXT = "customWidgetBottomText";
const PARAM_PTI_IS_CUSTOM_WIDGET_TEXT_IN_BLACK = "isCustomWidgetTextInBlack";
const PARAM_PTI_IS_TRANSPARENT_MODE = "isTransparentMode";


export default {

  components: { 
    UserDeviceId,
    CommunicationOption,
    AlertRecipientSettings,
    AlertTriggeringMode,
    LocationSettings,
    BatteryMonitoring,
    EnableDisableAlertSystem,
    DeviceNotification,
    SystemOption,
    DeployCred,
  },

  beforeRouteLeave(to, from, next) {
    if (!isLoginPaths(to.path) && this.$refs.UserDeviceId.check(false))
      this.$refs.UserDeviceId.save();
    this.$refs.modalAlertConfirmChoice.dismiss();
    let intervalId = setInterval(() => {  
      const modalAlertConfirm = document.getElementById(MODAL_ID_ALERT_CONFIRM);
      if (modalAlertConfirm.parentElement.tagName.valueOf() != BODY) {
        clearInterval(intervalId);
        next();
      }
    }, 100);
  },
  
  data() {
    return {
      btnState: BTN_STATE_VALIDATE,
      notifState: NOTIF_STATE_HIDE,
      isEwg100: false,
      isDeployment: false,
      deviceIMEI: null,
      isShowDownloadButton: false,
      sip: {
        isAvailable: false,
        isActivated: false,
        tmpIsActivated: false,
      },
      modalAlertConfirmText: I18_ENABLING_SIP_WILL_REMOVE_RECIPIENTS,
      cloneDeviceIMEI: null,
      cloneDeviceSN: null,
    };
  },

  computed: {
    
    getValidateButtonText() {
      if (this.btnState == BTN_STATE_VALIDATE)
        return I18_BTN_VALIDATE;
      return I18_BTN_UPDATE;
    },

    getValidateButtonClass() {
      if (this.btnState == BTN_STATE_VALIDATE)
        return YELLOW_BTN_CLASS;
      return BLUE_BTN_CLASS;
    }
  },

  created() {
    this.deviceModelName = useStore().state.emeritValues.deviceInfo.deviceModelName;
    this.isEwg100 = this.deviceModelName === MODEL_EWG100;
    this.deviceIMEI = getOptionsValue("Imei");
    if (useStore().state.emeritValues.disconnectionPath === PATH_MODEL_LOGIN)
      this.isDeployment = true;
    this.initSipValues();
  },

  methods: {

    initSipValues() {
      if (this.isDeployment)
        setNumberPtiValue(PTI_IS_SIP_AVAILABLE, 1);
      this.sip.isAvailable = getBoolPtiValue(PTI_IS_SIP_AVAILABLE);
      if (this.sip.isAvailable === undefined)
        this.sip.isAvailable = false;
      else if (this.sip.isAvailable)
        this.sip.isActivated = getBoolPtiValue(PTI_IS_SIP_ACTIVATED)
    },

    triggerModalConfirmSip(newSipValue) {
      if (newSipValue)
        this.modalAlertConfirmText = I18_ENABLING_SIP_WILL_REMOVE_RECIPIENTS;
      else
        this.modalAlertConfirmText = I18_DISABLING_SIP_WILL_REMOVE_RECIPIENTS;
      this.sip.tmpIsActivated = newSipValue;
      this.$refs.buttonTargetSipModal.click();
    },

    updateSipIsActivated() {
      this.sip.isActivated = this.sip.tmpIsActivated;
      this.$refs.CommunicationOption.updateSipStatus(this.sip.tmpIsActivated);
      if (this.isDeployment && this.sip.isActivated)
        this.$refs.toastCheckEmeritVersion.trigger();
    },

    updateBeaconIdSiaDataIfTransparentModeEnabled(){

        if(getNumberPtiValue(PARAM_PTI_IS_TRANSPARENT_MODE) == 1){

          if(getCountRecipientSupIp() > 0){
            console.log("sup > 0 avec transparent mode ")
            updateSupIpExDataBeaconId(true);
          }

          if(getCountRecipientTlsIp() > 0){
            console.log("tls > 0 avec transparent mode ");
            updateTlsIpExDataBeaconId(true);
          }
        }

    },

    onValidateButtonClicked() {
      this.$refs.CommunicationOption.saveUTC()
      if (this.btnState === BTN_STATE_UPDATE) {
        this.onSaveButtonClicked();
        this.onSaveButtonClickedWithPEAP();
        return ;
      }
      if (!getBoolPtiValue(PARAM_PTI_IS_BLE_LOC_ENABLED))
      this.resetBeaconBleLowBatteryEvent();
      
      let error = 0;
      error += Number(!this.$refs.UserDeviceId.check(true))
      error += Number(!this.$refs.AlertRecipientSettings.check());
      error += Number(!this.$refs.CommunicationOption.check());
      error += Number(!this.$refs.AlertTriggeringMode.check());
      error += Number(!this.$refs.LocationSettings.check());
      if (this.isDeployment)
        error += Number(!this.$refs.DeployCred.check());
      if (this.isEwg100) {
        error += Number(!this.$refs.DeviceNotification.check());
        error += Number(!this.$refs.SystemOption.check());
      }
      if (error)
        return ;

      this.$refs.UserDeviceId.save();
      this.btnState = this.isDeployment ? BTN_STATE_HIDE : BTN_STATE_UPDATE;
      this.isShowDownloadButton = true;
      this.updateBeaconIdSiaDataIfTransparentModeEnabled();
      if (!useStore().state.emeritValues.isCustomScreens)
        this.resetCustomScreenParamsPti();
    },

    resetCustomScreenParamsPti() {
      setNumberPtiValue(PARAM_PTI_IS_CUSTOM_LAUNCH_SCREEN_ENABLED, false);
      setNumberPtiValue(PARAM_PTI_IS_LAUNCH_SCREEN_IMG_UPLOADED, false);
      if (useStore().state.emeritValues.launchScreenImg.file) {
        this.$store.commit(STORE_SET_LAUNCH_SCREEN_IMG_FILE, undefined);
        this.$store.commit(STORE_SET_LAUNCH_SCREEN_IMG_UPDATE_S3, true);
      }

      setNumberPtiValue(PARAM_PTI_IS_CUSTOM_WIDGET_SCREEN_ENABLED, false);
      setNumberPtiValue(PARAM_PTI_IS_WIDGET_SCREEN_IMG_UPLOADED, false);
      setParameterPTIValue(PARAM_PTI_WIDGET_TOP_TEXT, '');
      setParameterPTIValue(PARAM_PTI_WIDGET_BOTTOM_TEXT, '');
      setNumberPtiValue(PARAM_PTI_IS_CUSTOM_WIDGET_TEXT_IN_BLACK, false);
      if (useStore().state.emeritValues.widgetScreenImg.file) {
        this.$store.commit(STORE_SET_WIDGET_SCREEN_IMG_FILE, undefined);
        this.$store.commit(STORE_SET_WIDGET_SCREEN_IMG_UPDATE_S3, true);
      }
    },

    resetBeaconBleLowBatteryEvent() {
      resetRecipientsBeaconBleLowBatteryEvent();
      resetRecipientsTakenInAccountBeaconBleLowBatteryEvent();
      resetRecipientTlsIpBeaconBleLowBatteryEvent();
      resetRecipientSupIpBeaconBleLowBatteryEvent();
    },

    /**
     * Uploading the valid dbFile to the s3, and then uploading img files
     * for custom launch/widget screen if their property updateS3 is set to
     * true.
     */
    onSaveButtonClicked() {
      this.$store.dispatch(STORE_UPLOAD_DB_FILE_TO_S3, { 
        self: this,
      });
      for (let i = 0; i < STORE_S3_IMG_FILES_INFO.length; ++i) {
        let imgFileInfo = STORE_S3_IMG_FILES_INFO[i];
        let imgFile = useStore().state.emeritValues[imgFileInfo.storeVarName]
        if (imgFile.updateS3) {
          this.$store.dispatch(STORE_UPLOAD_IMG_FILE_TO_S3, { 
            self: this,
            fileName: imgFileInfo.fileName,
          });
        }
      }
    },

    onSaveButtonClickedWithPEAP(){
      let certificateList = useStore().state.emeritValues['certificateList']
      certificateList.forEach(element => {
        this.$store.dispatch(STORE_UPLOAD_WIFI_PEAP_CERTIFICATE_FILE_TO_S3, { 
          self: this,
          fileName: element.name,
          certificateFile: element.file,
        })
      })
    },

    onDownloadToPcButtonClicked() {
      this.$store.dispatch(STORE_DOWNLOAD_ZIP_CONFIG_TO_PC);
    },

    onDbUploadError() {
      this.notifState = NOTIF_STATE_FAIL;
    },

    onDbUploadCompleted() {
      this.notifState = NOTIF_STATE_SUCCESS;
      this.btnState = BTN_STATE_HIDE;
    },

    onImgUploadError() {
      this.$refs.toastImgUploadFailure.trigger();
    },

    onImgUploadCompleted(imgFileName) {
      if (imgFileName.valueOf() === FILENAME_LAUNCH_IMG.valueOf())
        this.$store.commit(STORE_SET_LAUNCH_SCREEN_IMG_UPDATE_S3, false);
      else
        this.$store.commit(STORE_SET_WIDGET_SCREEN_IMG_UPDATE_S3, false);
    },

    onCertificateUploadError() {
      this.$refs.toastCertUploadFailure.trigger();
    },

    onCertificateUploadCompleted(CertFileName) {
      if (CertFileName.valueOf() === FILENAME_WIFI_CERT.valueOf())
        this.$store.commit(STORE_SET_WIFI_PEAP_CERTIFICATE_UPDATE_S3, false);
    },

    resetValidationButton(){
      this.isShowDownloadButton = false;
      this.btnState = BTN_STATE_VALIDATE;
      this.notifState = NOTIF_STATE_HIDE;
    },
  }
};
</script>