<template>
  <!-- BEGIN: ImportExportBleBeacon local component -->
  <div class="intro-y col-span-12 lg:col-span-6 lg:col-start-7 2xl:col-span-5 2xl:col-start-6">
    <BoxTitle
      color="bg-red-300"
      title="locationindoorble.menu_enable_import_export_csv"
    />
    <div class="clearfix box mb-6">
      <div class="clearfix p-3 border-b border-gray-200">
        <CustomButton
          text="locationindoorble.menu_download_excel_file_template_for_importation"
          custom-class="btn btn-warning"
          @click="downloadTemplate()"
        />
        <div>
          <ButtonTargetModal
            v-if="beaconList.length"
            target-id="#modal-alert-confirm-csv-upload"
            text="locationindoorble.menu_import_a_new_ble_beacons_file"
            custom-class="btn btn-warning mt-4"
          />
          <ButtonUploadFile
            v-else
            text="locationindoorble.menu_import_a_new_ble_beacons_file"
            accept=".csv"
            custom-class="btn btn-warning mt-4"
            @file-uploaded="checkCsvFile($event[0])"
          />
        </div>
        <CustomButton
          v-if="beaconList.length"
          text="locationindoorble.menu_export_to_csv_ble_configuration"
          custom-class="btn btn-dark mt-4"
          @click="exportBeaconsList()"
        />
        <p 
          v-if="errorMsg.length"
          class="mt-2"
        >
          {{ errorMsg }}
        </p>
      </div>
    </div>
    <CustomToast
      ref="toastSuccess"
      toast-id="toast-success"
      icon-component-name="CheckCircleIcon"
      icon-component-class="text-theme-9"
      text="locationindoorble.menu_import_succeeded"
    />
    <CustomToast
      ref="toastFailed"
      toast-id="toast-failed"
      icon-component-name="XCircleIcon"
      icon-component-class="text-theme-6"
      text="locationindoorble.menu_error_while_importing"
    />
    <ModalAlertConfirmChoice
      ref="modalAlertConfirmCsvUpload"
      modal-id="modal-alert-confirm-csv-upload"
      alert-title="common.menu_are_you_sure"
      alert-text="locationindoorble.menu_import_will_replace_existing_list"
      icon-component-name="AlertTriangleIcon"
      left-button-component-name="ButtonDismissModal"
      left-button-text="common.menu_cancel"
      left-button-custom-class="btn btn-outline-secondary mr-1"
      right-button-component-name="ButtonUploadFile"
      right-button-text="locationindoorble.menu_confirm_import"
      right-button-custom-class="btn btn-danger"
      @change="checkCsvFile($event.target.files[0])"
      @click="$refs.modalAlertConfirmCsvUpload.dismiss()"
    />
  </div>
  <!-- END: ImportExportBleBeacon local component -->
</template>

<script>
import { defineComponent } from 'vue'
import papa from "papaparse";
import { regexCheck } from '../../../utils/fieldManagement'
import { 
  BBE9_UUID, 
  LOC_EX_UUID, 
  MODEL_BBE9,
  MODEL_LOC_EX
} from './utils'


const TEMPLATE_URL = 'https://www.dropbox.com/scl/fi/g50lg7vlfeo6yibv1n9od/ble_beacon_list_template.xlsx?dl=1&rlkey=8w3wcnpjw1y3p7bgvwlqn0pzf';

const COMPANY_ID = 0;
const BEACON_ID = 1;
const CODE_ZONE = 2;
const DESC = 3;
const LEVEL = 4;
const TYPE = 5;

const TYPE_ZONE = '0';
const WRONG_CODE_ZONE = 0;

const IBEACON_UUID_LEN = 32;
const EDDYSTONE_NAMESPACE_ID_LEN = 20;

const HEADERS = ["Company_id","Beacon_id","Code_zone","Description","Level","Type"];

const WRONG_FILE_FORMAT = "common.menu_wrong_flie_format";
const ERROR_DETECTED_LINE = "locationindoorble.menu_an_error_was_detected_line";
const CHECK_EXCEL_FILE = "locationindoorble.menu_check_your_excel_file";
const EXCEL_FILE_IS_EMPTY = "locationindoorble.menu_excel_file_is_empty";
const INCORRECT_HEADERS = "locationindoorble.menu_incorrect_column_headers_ble";

const EV_IMPORT_SUCCESS = 'import-success';

const VALID_HEADER_FIELDS = [
  "Company_id",
  "Beacon_id",
  "Code_zone",
  "Description",
  "Level",
  "Type",
];


export default defineComponent({

  props: {  
    beaconList: {
      type: Array,
      required: true,
    }
  }, 

  emits : [EV_IMPORT_SUCCESS],

  data(){
    return {
      errorMsg : "",
      bleBeaconsList: new Array(),
    }
  },

  methods: {

    dismissModal() {
      this.$refs.modalAlertConfirmCsvUpload.dismiss();
    },

    downloadTemplate() {
      if (this.errorMsg.length)
        this.errorMsg = '';
      window.open(TEMPLATE_URL);
    },

    setError(str, parser) {
      this.errorMsg = str;
      this.$refs.toastFailed.trigger();
      if (parser)
        parser.abort();
      return false;
    },

    checkCsvFile(csvFile) {
      if (this.errorMsg.length)
        this.errorMsg = '';
      if (!csvFile.name.endsWith(".csv"))
        this.setError(this.$t(WRONG_FILE_FORMAT))
      else
        this.parseCsvFile(csvFile);
    }, 

    parseCsvFile(csvFile) {
      let isHeaderFieldsChecked = false;
      let line = 1;
      this.bleBeaconsList = new Array();

      papa.parse(csvFile, {
        worker: true,
        header: true,
        
        step: (row, parser) => {
          if (!isHeaderFieldsChecked) {
            if (!this.checkHeaderFields(parser, row.meta.fields))
              return ;
            isHeaderFieldsChecked = true;
          }
          if (this.emptyLine(row.data)) {
            parser.abort();
          }
          else {
            ++line;
            this.checkRow(parser, row.data, line);
          }
        },
        complete: () => {
          if (!this.errorMsg.length)
            this.onCsvParseSuccess();
        },
      });
    },

    emptyLine(data) {
      if (data.Company_id == undefined)
        return true;
      return (!data.Company_id.length &&
          (!data.Beacon_id ||
          !data.Code_zone ||
          !data.Description ||
          !data.Level ||
          !data.Type));
    },

    checkRow(parser, data, line) {
      if (this.checkBeacon(data) && !this.beaconAlreadyExisting(data))
          this.bleBeaconsList.push(this.createBleBeacon(data)); 
      else
        this.setError(this.$t(ERROR_DETECTED_LINE) 
            + line 
            + this.$t(CHECK_EXCEL_FILE),
            parser);
    },
    
   checkBeacon(beacon) {
    let beaconIdRegex = '^[a-fA-F0-9]{8}';
    if (regexCheck(beacon.Company_id, /^(e-BBE9)$/i)) {
      beacon.Company_id = BBE9_UUID;
    }
    else if (regexCheck(beacon.Company_id, /^(LOC-EX 01)$/i)) {
      beacon.Company_id = LOC_EX_UUID;
      beaconIdRegex = '^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$';
    }
    if (beacon.Company_id.length === IBEACON_UUID_LEN){
      return (
        regexCheck(beacon.Company_id, /^[a-fA-F0-9]{32}/) &&
        regexCheck(beacon.Beacon_id, new RegExp(beaconIdRegex, 'g')) &&
        (regexCheck(beacon.Code_zone, /^[0-9]{1,3}$/) && (Number(beacon.Code_zone) != WRONG_CODE_ZONE)) &&
        (beacon.Description.length >= 1 && beacon.Description.length <= 50) &&
        (regexCheck(beacon.Level, /^-?[0-9]{1,2}/) || !beacon.Level.length) &&
        (regexCheck(beacon.Type, /^[0-2]/) || !beacon.Type.length)
      );
    }
    else if (beacon.Company_id.length === EDDYSTONE_NAMESPACE_ID_LEN){
      return (
        regexCheck(beacon.Company_id, /^[a-zA-Z0-9]{20}/) &&
        regexCheck(beacon.Beacon_id, /^[a-zA-Z0-9]{12}/) &&
        (regexCheck(beacon.Code_zone, /^[0-9]{1,3}$/) && (Number(beacon.Code_zone) != WRONG_CODE_ZONE)) &&
        (beacon.Description.length >= 1 && beacon.Description.length <= 50) &&
        (regexCheck(beacon.Level, /^-?[0-9]{1,2}/) || !beacon.Level.length) &&
        (regexCheck(beacon.Type, /^[0-2]/) || !beacon.Type.length)
      );
    }
    else
      return false;
    },

    beaconAlreadyExisting(data) {
      for (let i = 0; i < this.bleBeaconsList.length; ++i)
        if (this.bleBeaconsList[i][COMPANY_ID] === data.Company_id
            && this.bleBeaconsList[i][BEACON_ID] === data.Beacon_id)
          return true;
      return false;
    },

    createBleBeacon(data) {
      let currentBeacon = new Array();
      currentBeacon[COMPANY_ID] = data.Company_id.toUpperCase();
      currentBeacon[BEACON_ID] = data.Beacon_id.toUpperCase();
      currentBeacon[CODE_ZONE] = this.addPaddingZeros(data.Code_zone);
      currentBeacon[DESC] = data.Description;
      if (data.Type === "1" || !data.Type.length) 
        currentBeacon[LEVEL] = "";
      else 
        currentBeacon[LEVEL] = data.Level;
      if (!data.Type.length)
        currentBeacon[TYPE] = TYPE_ZONE;
      else
        currentBeacon[TYPE] = data.Type;
      return currentBeacon;
    },

    addPaddingZeros(strCodeZone) {
      while (strCodeZone.length < 3)
        strCodeZone = '0' + strCodeZone;
      return strCodeZone;
    },

    onCsvParseSuccess() {
      if (this.bleBeaconsList.length) {
        this.$refs.toastSuccess.trigger();
        this.$emit(EV_IMPORT_SUCCESS,this.bleBeaconsList);
      }
      else
        this.setError(this.$t(EXCEL_FILE_IS_EMPTY) 
            + this.$t(CHECK_EXCEL_FILE));
    },

    checkHeaderFields(parser, headerFields) {
      if (!this.isValidCsvFileHeader(headerFields))
        return this.setError(this.$t(INCORRECT_HEADERS), parser);
      return true;
    },
    
    isValidCsvFileHeader(headerFields) {
      return (
        headerFields[0] === VALID_HEADER_FIELDS[0] &&
        headerFields[1] === VALID_HEADER_FIELDS[1] &&
        headerFields[2] === VALID_HEADER_FIELDS[2] &&
        headerFields[3] === VALID_HEADER_FIELDS[3] &&
        headerFields[4] === VALID_HEADER_FIELDS[4] &&
        headerFields[5] === VALID_HEADER_FIELDS[5]
      );
    },

    exportBeaconsList() {
      if (this.errorMsg.length)
        this.errorMsg = '';
      const csvContent = this.createCsvFile();
      let encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "exportedBleBeacons.csv");
      document.body.appendChild(link); 
      link.click();   
    },

    createCsvFile() {
      let csvContent = "data:text/csv;charset=utf-8,";
      let tmpList = this.beaconList.slice()
      tmpList.splice(0, 0, HEADERS);
      for (let i = 0; i < tmpList.length; ++i) {
        if (tmpList[i][COMPANY_ID].valueOf() === BBE9_UUID) {
          tmpList[i] = tmpList[i].slice();
          tmpList[i][COMPANY_ID] = MODEL_BBE9;
        }
        else if (tmpList[i][COMPANY_ID].valueOf() === LOC_EX_UUID) {
          tmpList[i] = tmpList[i].slice();
          tmpList[i][COMPANY_ID] = MODEL_LOC_EX;
        }
        csvContent += tmpList[i].join(";");
        if (i < tmpList.length - 1)
          csvContent += "\n";
      }
      return csvContent;
    }

  },
  
})
</script>
