<template>
  <div class="clearfix box mt-6">
    <BoxTitle
      title="configuration.menu_type_action"
      color="bg-gray-400"
    />
    <div class="p-3">
      <CustomRadioInput
        v-for="(radio, index) in radioInputParams"
        :key="index"
        v-model.number="typeActionMode"
        :custom-id="radioGroupNameTypeActionValues + index"
        :radio-group-name="radioGroupNameTypeActionValues"
        :description="radio"
        :value="index"
      />
      <div
        v-if="typeActionMode == 2 "
        class="ml-12"
      >
        <CustomTextInput
          v-for="(field, index) in getInputFields"
          :key="index"
          v-model="field.value"
          class="mb-3"
          :title="field.title"
          :max-length="field.maxLen"
          :error-msg="field.errorMsg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import {
  checkFieldIsNotEmpty,
  setError 
} from '../../../utils/fieldManagement';


import {
  setParameterPTIValue,
  getParameterPTIValue,
  getNumberPtiValue,
  setNumberPtiValue,
} from '../../../dao/parametre-pti'

const PARAM_PTI_GET_TRIGGER_TYPE = "onOffButtonTriggerType"
const PARAM_PTI_NAME_RECIPIENT = "automaticCallNameRecipient"
const PARAM_PTI_PHONE_RECIPIENT = "automaticCallPhoneRecipient"

const I18_NAME = "internalrecipients.menu_name_recipient";
const I18_PHONE_NUMBER = "internalrecipients.menu_phone_number_international_format";

const TYPE_ACTION_RADIO_GROUP_NAME = "type-action-radio";

const I18_ERR_FIELD_CANT_BE_EMPTY = "common.menu_field_cannot_be_empty";
const I18_ERR_WRONG_NUMBER_SIP_FORMAT ="common.menu_phone_number_must_start";
const I18_ERR_WRONG_NUMBER_FORMAT = "common.menu_phone_number_must_start_with_+";

const I18_MENU_NOTHING = "common.menu_nothing";
const I18_TRIGGER_ALERT ="configuration.menu_trigger_alert";
const I18_TRIGGER_CALL = "configuration.menu_trigger_call";


let i = 0;
const INDEX_NAME = i++;
const INDEX_PHONE_NUMBER = i++;

export default defineComponent({
  props: {
    isSipActivated: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      radioInputParams: [I18_MENU_NOTHING, I18_TRIGGER_ALERT,I18_TRIGGER_CALL],
      radioGroupNameTypeActionValues: TYPE_ACTION_RADIO_GROUP_NAME,
      inputFields: [
        {
          value: '',
          title: I18_NAME,
          maxLen: 50,
          errorMsg: '',
        },
        {
          value: '',
          title: I18_PHONE_NUMBER,
          maxLen: 16,
          errorMsg: '',
        },
      ],
      typeActionMode: undefined,
    }
  },
  computed: {
    getInputFields() {
      let inputFieldsRendered = [];
      inputFieldsRendered.push(this.inputFields[INDEX_NAME]);
      inputFieldsRendered.push(this.inputFields[INDEX_PHONE_NUMBER]);
      return inputFieldsRendered;
    },
  },
  created(){     
    if(getNumberPtiValue('SOSButton1')==1){
      this.typeActionMode = 1;
    }
    if(getNumberPtiValue(PARAM_PTI_GET_TRIGGER_TYPE)==2){
      this.typeActionMode = 2;
    }else if(getNumberPtiValue('SOSButton1')==0){
      this.typeActionMode = 0;
    }
  },
  mounted() {
    document.getElementById(this.radioGroupNameTypeActionValues + this.typeActionMode).checked = true;
  },

  methods:{
    initinputfield() {
      if(this.typeActionMode != 2){
        this.inputFields[0].value ="";
        this.inputFields[1].value ="";
      }
      else {
        this.inputFields[0].value = getParameterPTIValue(PARAM_PTI_NAME_RECIPIENT);
        this.inputFields[1].value = getParameterPTIValue(PARAM_PTI_PHONE_RECIPIENT);
      }
    }, 


    saveRadio() {
      setNumberPtiValue(PARAM_PTI_GET_TRIGGER_TYPE, this.typeActionMode);
      if(this.typeActionMode == 2 || this.typeActionMode == 0)
        setNumberPtiValue('SOSButton1',0)
      if(this.typeActionMode == 1)
        setNumberPtiValue('SOSButton1',1)
    },

    saveInputFields() {
      let nbErrors = 0;
      if(this.typeActionMode == 2)
       nbErrors += Number(this.checkInputFields());
      if (!nbErrors){
        setParameterPTIValue(PARAM_PTI_NAME_RECIPIENT, this.inputFields[0].value);
        setParameterPTIValue(PARAM_PTI_PHONE_RECIPIENT, this.inputFields[1].value);
        this.$router.push("/");
      }
    },

    checkInputFields() {
      const inputFieldsComputed = this.getInputFields;
      let nbErrors = inputFieldsComputed.length;
      for (let i = 0; i < inputFieldsComputed.length; ++i){
        nbErrors -= checkFieldIsNotEmpty(inputFieldsComputed, i, I18_ERR_FIELD_CANT_BE_EMPTY); 
      }
      if (this.isSipActivated){
        if (!this.inputFields[INDEX_PHONE_NUMBER].errorMsg.length){
        nbErrors += !this.checkSip();
      }
      }else{
      if(!this.inputFields[INDEX_PHONE_NUMBER].errorMsg.length){
        nbErrors += !this.checkPhoneNb();
        }
      }
      return nbErrors;
    },

    checkPhoneNb() {
      const phoneNb = this.inputFields[INDEX_PHONE_NUMBER].value;
      if (phoneNb[0] != '+' || phoneNb.length === 1){
        return setError(this.inputFields, INDEX_PHONE_NUMBER, I18_ERR_WRONG_NUMBER_FORMAT);
      }
      for (let i = 1; i < phoneNb.length; ++i)
        if (phoneNb[i] < '0' || phoneNb[i] > '9')
          return setError(this.inputFields, INDEX_PHONE_NUMBER, I18_ERR_WRONG_NUMBER_FORMAT); 
      return true;
    },

    checkSip() {
      const sipInfos = this.inputFields[INDEX_PHONE_NUMBER].value;
      const sipRegex = /^(\+)?\d{1,50}$/;
      if (!sipRegex.test(sipInfos)) {
        return setError(this.inputFields, INDEX_PHONE_NUMBER, I18_ERR_WRONG_NUMBER_SIP_FORMAT);
      }
      return true;
    }  
  },

});
</script>