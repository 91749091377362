<template>
  <Title
    title="wristalert.menu_title_wrist_alert"
  />
  <!-- <AlertMode 
    ref="AlertMode"
    :is-discret-mode-enabled="isDiscretModeEnabled"
    @new-discret-mode-state="isDiscretModeEnabled = $event"
  /> -->
  <ThresholdValue
    ref="ThresholdValue"
  />
  <lcAlertBidirectionnelle
  ref="lcAlertBidirectionnelle"
  />
  <!-- <NotifPreAlert
    v-if="!isDiscretModeEnabled"
    ref="NotifPreAlert"
  /> -->
  <CustomButton
    text="common.menu_save"
    @click="save"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { getNumberPtiValue } from '../../dao/parametre-pti'
import AlertMode from './local-components/lc-alert-mode.vue'
import NotifPreAlert from './local-components/lc-notif-pre-alert.vue'
import lcAlertBidirectionnelle from './local-components/lc-alert-bidirectionnelle.vue'
import ThresholdValue from './local-components/lc-threshold-values.vue'


const PARAM_PTI_WRIST_DISCRET_MODE = "isWristMouvementDiscretModeEnabled";


export default defineComponent({

  components: {
    // AlertMode,
    // NotifPreAlert,
    lcAlertBidirectionnelle,
    ThresholdValue,
  },

  data() {
    return {
      isDiscretModeEnabled: undefined,
    }
  },

  created() {
    this.isDiscretModeEnabled = getNumberPtiValue(PARAM_PTI_WRIST_DISCRET_MODE);
  },

  methods: {
    
    save() {
      // if (!this.isDiscretModeEnabled && !this.$refs.NotifPreAlert.check())
      //   return ;
      // if (!this.isDiscretModeEnabled)
        // this.$refs.NotifPreAlert.save();
      this.$refs.lcAlertBidirectionnelle.save();
      this.$refs.ThresholdValue.save();
      // this.$refs.AlertMode.save();
      this.$router.push('/');
    }
  }

})
</script>
