// utils.js

export const DB_COMPANY_ID_COLUMN_INDEX = 1;

export const createBeaconFromDbEntry = dbBeacon => {
  return [dbBeacon[DB_COMPANY_ID_COLUMN_INDEX].toUpperCase()];
};

export const BBE9_UUID = "4D41474E54414027BDC419D9D8CE069E";
export const LOC_EX_UUID = "2673DAF8E45E4D85A91D6DF91D9BCD79";

export const MODEL_BBE9 = "e-BBE9";
export const MODEL_LOC_EX = "LOC-EX 01";
export const MODEL_IBEACON = "iBeacon";
export const MODEL_EDDY = "Eddystone";
export const MODEL_CUSTOM = "Autre UUID";
