<template>
  <!-- BEGIN: InternalRecipientMirrorList local component-->
  <div>
    <div class="font-bold text-theme-0">
      {{ $t("whitelist.menu_nb_recipient_mirror") + recipientMirrorList.length }}
    </div>
    <Pagination
      v-if="recipientMirrorList.length"
      :current-page="page"
      :total-pages="getTotalPages"
      @begin="page = 1"
      @end="page = getTotalPages"
      @decrement="page = page === 1 ? 1 : --page"
      @increment="page = page === getTotalPages ? page : ++page"
    />
    <CustomTablePreviewOnly
      v-if="recipientMirrorList.length"
      :headers="headers"
      :elems-list="getRecipientMirrorPage"
      :screen-percent-width="10"
      list-element-component-name="ListElementPreviewOnly"
    />
  </div>
  <!-- END: InternalRecipientMirrorList local component-->
</template>

<script>
import { defineComponent } from 'vue'
import { useStore } from "@/store";
import { getBoolPtiValue } from '../../../dao/parametre-pti';


const I18_HEADER_NAME = 'internalrecipients.menu_name';
const I18_HEADER_PHONE_NB = 'internalrecipients.menu_call_number';
const I18_HEADER_PHONE_NB_OR_SIP_ID = 'internalrecipients.menu_number_or_sip_id';

const PARAM_PTI_IS_SIP_AVAILABLE = "SipManagerAvailable";
const PARAM_PTI_IS_SIP_ACTIVATED = "SipActivationState";

const NB_ITEMS = 10;

const MODEL_LOGIN_PATH = "model-login";


export default defineComponent({
  
  props: {
    recipientMirrorList: {
      type: Array,
      required: true,
    },
    isTlsActivated: {
      type: Boolean,
      required: true,
    }
  },
  
  data() {
    return {
      page: 1,
      headers: [],
      isSipActivated: false,
    }
  },

  computed: {
    getRecipientMirrorPage() {
      const startIndex = (this.page - 1) * NB_ITEMS;
      const length = startIndex + NB_ITEMS < this.recipientMirrorList.length ?
          NB_ITEMS : this.recipientMirrorList.length - startIndex;
      let recipientMirrorPage = new Array(length);
      for (let i = 0; i < length; ++i)
        recipientMirrorPage[i] = this.recipientMirrorList[i + startIndex];
      return recipientMirrorPage;
    },

    getTotalPages() {
      return Math.floor(this.recipientMirrorList.length / 10) + (this.recipientMirrorList.length % 10 != 0);
    },
  },

  created() {
    this.initHeaders(this.isTlsActivated);
  },

  methods: {

    initHeaders(isTlsActivated) {
      let isSipActivated;
      let isDeployment = false; 
      if (useStore().state.emeritValues.disconnectionPath === MODEL_LOGIN_PATH)
        isDeployment = true;
      const isSipAvailable = isDeployment ? true : getBoolPtiValue(PARAM_PTI_IS_SIP_AVAILABLE);
      if (isSipAvailable === undefined)
        isSipActivated = false;
      else if (isSipAvailable)
        isSipActivated = getBoolPtiValue(PARAM_PTI_IS_SIP_ACTIVATED)
      if (isSipActivated && !isTlsActivated)
        this.headers = [I18_HEADER_NAME, I18_HEADER_PHONE_NB_OR_SIP_ID];
      else
        this.headers = [I18_HEADER_NAME, I18_HEADER_PHONE_NB]; 
    },
  }
})
</script>
