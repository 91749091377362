import { useStore } from "@/store";

const TABLE_NAME_BLE_TRANSPARENT = "ble_transparent";
const COLUMN_BLE_TRANSPARENT_UUID_FILTER = "uuid_filter";
const COLUMN_BLE_TRANSPARENT_UUID_ID = "uuid_id";

export const addBleTransparent = idFilter => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const [firstId, secondId] = idFilter; // Décomposition du tableau idFilter

  let sqlScript = `INSERT INTO ${TABLE_NAME_BLE_TRANSPARENT} (${COLUMN_BLE_TRANSPARENT_UUID_FILTER}) VALUES ("${firstId}")`;

  try {
    dbFile.run(sqlScript);
  } catch (error) {
    console.log("Erreur lors de l'ajout des données à la table ble_transparent:", error);
  }
};

export const deleteAllBleTransparent = () => {
  const dbFile = useStore().state.emeritValues.dbFile;
  const sqlScript = "DELETE FROM " + TABLE_NAME_BLE_TRANSPARENT;
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("deleteAllBleTransparent sql script failed: " + error);
  }
};

export const deleteBleTransparent = uuid_filter => {
  const dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "DELETE FROM " + TABLE_NAME_BLE_TRANSPARENT + " WHERE " + COLUMN_BLE_TRANSPARENT_UUID_FILTER + '="' + uuid_filter + '"';
  try {
    dbFile.exec(sqlScript);
  } catch (error) {
    console.log("deleteBleTransparent failed: " + error);
  }
};

export const getBleTransparent = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT * FROM " + TABLE_NAME_BLE_TRANSPARENT;
  try {
    return dbFile.exec(sqlScript)[0];
  } catch (error) {
    console.log("getBleTransparent sql script failed: " + error);
  }
};

export const getBleTransparentCount = () => {
  var dbFile = useStore().state.emeritValues.dbFile;
  let sqlScript = "SELECT COUNT(*) FROM " + TABLE_NAME_BLE_TRANSPARENT;
  try {
    return dbFile.exec(sqlScript)[0].values[0];
  } catch (error) {
    console.log("getBleTransparentCount failed: " + error);
  }
};
