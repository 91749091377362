<template>
  <!-- BEGIN: LocationSettings local component -->
  <div class="clearfix box mt-6">
    <BoxTitle
      color="bg-red-300"
      title="configuration.menu_alerts_localization"
      help-locale="menu_loc"
    />
    <CheckBoxFieldAndXIcons
      v-for="(locType, index) in location"
      :key="index"
      v-model="locType.value"
      :custom-id="`checkbox-localisation-${index}`"
      :check-box-text="locType.title"
      :is-disabled="locType.isDisabled"
      :tippy-text="locType.tippyText"
      :error-msg="locType.errorMsg"
      :icon-params="locType.iconParams"
      :custom-class="locType.isDisabled ? 'opacity-50' : (index !== 2 || !locType.value ? 'mt-3 mr-3 pb-3 pl-3 border-b border-gray-200' : ' mt-3 mr-3 pl-3')" 
      @change="onCheckBoxChange(index)"
    />
    <div v-if="location[2].value == true">
      <div class="mt-3 ml-3 mr-3">
          <div class="ml-10 mt-3">
            <input
              id="standard"
              v-model="modeValue"
              class="form-check-input p-3"
              type="radio"
              value="1"
              name="radio-standard"
              @change="onRadioChange"
            >
            <label
              class="form-check-label"
              for="standard"
            >
              {{ $t("configuration.menu_location_standard") }}
            </label>
          </div>
          <div class="ml-10 mt-3">
            <input
              id="transparent"
              v-model="modeValue"
              class="form-check-input"
              type="radio"
              value="2"
              name="radio-transparent"
              @change="onRadioChange"
            >
            <label
              class="form-check-label"
              for="transparent"
            >
              {{ $t("configuration.menu_location_transparent") }}
            </label>
          </div>
        </div>
      <div v-if="modeValue == 1">
        <CheckBoxFieldAndXIcons
          v-for="(locType, index) in indoor_standard"
          :key="index"
          v-model="locType.value"
          :custom-id="`checkbox-localisation-standard-${index}`"
          :check-box-text="locType.title"
          :is-disabled="locType.isDisabled"
          :tippy-text="locType.tippyText"
          :error-msg="locType.errorMsg"
          :icon-params="locType.iconParams"
          :custom-class="locType.isDisabled ? 'opacity-50' : (index === 1 ? 'my-3 mr-3 pb-3 border-b border-gray-200' : 'my-3 mr-3')" 
          class="ml-7"
          @change="onCheckBoxChange(index)"
        />
      </div>
      <div v-if="modeValue == 2">
        <CheckBoxFieldAndXIcons
          v-for="(locType, index) in indoor_transparent"
          :key="index"
          v-model="locType.value"
          :custom-id="`checkbox-localisation-transparent-${index}`"
          :check-box-text="locType.title"
          :is-disabled="locType.isDisabled"
          :tippy-text="locType.tippyText"
          :error-msg="locType.errorMsg"
          :icon-params="locType.iconParams"
          :custom-class="locType.isDisabled ? 'opacity-50' : (index === 1 ? 'my-3 mr-3 pb-3 border-b border-gray-200' : 'my-3 mr-3')" 
          class="ml-7"
          @change="onCheckBoxChange(index)"
        />
      </div>
    </div>
    <CheckBoxFieldAndXIcons
      v-for="(locType, index) in location_additional"
      :key="index"
      v-model="locType.value"
      :custom-id="`checkbox-localisation-additional-${index}`"
      :check-box-text="locType.title"
      :is-disabled="locType.isDisabled"
      :tippy-text="locType.tippyText"
      :error-msg="locType.errorMsg"
      :icon-params="locType.iconParams"
      @change="onCheckBoxChange(index)"
    />
  </div>
  <!-- END: LocationSettings local component -->
</template>

<script>
import { defineComponent } from 'vue'
import { getBleBeacons } from "../../../dao/indoor-ble-dao";
import {
  getBoolPtiValue,
  getNumberPtiValue,
  getParameterPTIMax,
  getParameterPTIValue,
  setNumberPtiValue,
  setParameterPTIValue,
} from "../../../dao/parametre-pti";
import { 
  getWifiBeacons,
} from '../../../dao/indoor-wifi-dao';
import {getBleTransparent}from "../../../dao/transparent-indoor-ble-dao";
import {getTransparentWifiBeacons} from "../../../dao/transparent-indoor-wifi-dao";
import { 
  resetError, 
  setError 
} from '../../../utils/fieldManagement';


const BEACON_DB_LEVEL_COLUMN_INDEX = 5;
const BEACON_DB_TYPE_COLUMN_INDEX = 6;
const HOTSPOT_DB_LEVEL_COLUMN_INDEX = 6;

const LEVEL_TYPE = "1";

const NO_SSID_ASSIGN = "locationindoorwifi.menu_no_wifi_hotspot_transparent";
const NO_UUID_ASSIGN = "locationindoorwifi.menu_no_ble_transparent";

const ERR_NO_PARAM_FOUND= "locationindoorwifi.menu_no_param_found_indoord";

const ERR_CONFIRM_DEACTIVATION_LOC = "configuration.menu_confirm_deactivation_of_localisation";
const ERR_NO_WIFI_HOTSPOT = "configuration.menu_no_wifi_access_point_has_been_registered";
const ERR_NO_BLE_BEACON = "configuration.menu_no_ble_beacon_has_been_registered";
const ERR_INDICATE_HOTSPOT_LEVEL = "locationindoorwifi.menu_err_one_elem_missing_level";
const ERR_INDICATE_BEACON_LEVEL = "locationindoorble.menu_err_one_elem_missing_level";
const ERR_NO_LEVEL_BEACON = "locationindoorble.menu_err_no_level_beacon";

const CHECKBOX_PTI_PARAMS = [
  "",
  "isGpsLocParamEnabled",
  "isFragmentLocWanted",
  "isLastLocEnabled",
];

const PARAM_PTI_GET_MODE_VALUE = "isTransparentMode"

const PTI_PARAM_GPS = "isGpsLocParamEnabled";
const PTI_PARAM_WIFI_LOC = "isWifiLocParamEnabled";
const PTI_PARAM_INDOOR= "isIndoorEnabled";
const PTI_PARAM_BLE_LOC = "isBluetoothLocParamEnabled";
const PTI_PARAM_WIDGET_LOC = "isFragmentLocWanted";
const PTI_PARAM_IS_BLE_LEVEL_ACTIVATED = "IndoorBleLevelPrecisionActivated";
const PTI_PARAM_IS_BUTTON_LAST_LOC= "isLastLocEnabled";

const ERR_NO_CHECK_STANDARD = "configuration.menu_no_checked_standard";
const ERR_NO_CHECK_TRANSPARENT = "configuration.menu_no_checked_transparent";

const I18_TITLE_WIDGET_LOC = 'configuration.menu_show_fragment_location';
const I18_TIPPY_LOC = "configuration.menu_tippy_widget_loc";

const I18_TITLE_NEVER = 'configuration.menu_location_never';
const I18_TITLE_OUTDOOR = 'configuration.menu_location_outdoor';
const I18_TITLE_INDOOR = 'configuration.menu_location_indoor';
const I18_TITLE_INDOOR_WIFI = 'configuration.menu_location_indoor_wifi';
const I18_TITLE_INDOOR_BLE = 'configuration.menu_location_indoor_ble';
const I18_TITLE_LAST_LOC = 'configuration.menu_show_last_location';

const MODE_STANDARD_VALUE = "configuration.menu_location_standard";
const MODE_TRANSPARENT_VALUE = "configuration.menu_location_transparent";
const MODE_RADIO_GROUP_NAME = "location-indoor-mode-radio";

const NEVER_INDEX = 0;
const OUTDOOR_INDEX = 1;
const INDOOR_INDEX = 2;
const NEW_INDOOR_WIFI_INDEX = 0;
const NEW_INDOOR_BLE_INDEX = 1;
const WIDGET_LOC_INDEX = 0;
const LAST_LOC_INDEX = 1;


export default defineComponent({

  emits: ['mode-value-change'],

  data() {
    return {
      location: [
        {
          title: I18_TITLE_NEVER,
          ptiParam: "",
          value: false,
          isDisabled: false,
          tippyText: "",
          errorMsg: '',
          iconParams: undefined
        },
        {
          title: I18_TITLE_OUTDOOR,
          ptiParam: PTI_PARAM_GPS,
          value: false,
          isDisabled: false,
          tippyText: "",
          errorMsg: '',
          iconParams: [{ componentName: "SlidersIcon", routeName: "emerit-location-outdoor", paramsType: "" }]
        },
        {
          title: I18_TITLE_INDOOR,
          ptiParam: PTI_PARAM_INDOOR,
          value: false,
          isDisabled: false,
          tippyText: "",
          errorMsg: '',
          iconParams: undefined
        },
      ],

      indoor_standard: [
        {
          title: I18_TITLE_INDOOR_WIFI,
          ptiParam: PTI_PARAM_WIFI_LOC,
          value: false,
          isDisabled: false,
          tippyText: "",
          errorMsg: '',
          iconParams: [{ componentName: "SlidersIcon", routeName: "emerit-location-indoor-wifi", paramsType: "" }]
        },
        {
          title: I18_TITLE_INDOOR_BLE,
          ptiParam: PTI_PARAM_BLE_LOC,
          value: false,
          isDisabled: false,
          tippyText: "",
          errorMsg: '',
          iconParams: [{ componentName: "SlidersIcon", routeName: "emerit-location-indoor-ble", paramsType: "" }]
        },
      ],

      indoor_transparent: [
        {
          title: I18_TITLE_INDOOR_WIFI,
          ptiParam: PTI_PARAM_WIFI_LOC,
          value: false,
          isDisabled: false,
          tippyText: "",
          errorMsg: '',
          iconParams: [{ componentName: "SlidersIcon", routeName: "emerit-location-indoor-wifi-transparent", paramsType: "" }]
        },
        {
          title: I18_TITLE_INDOOR_BLE,
          ptiParam: PTI_PARAM_BLE_LOC,
          value: false,
          isDisabled: false,
          tippyText: "",
          errorMsg: '',
          iconParams: [{ componentName: "SlidersIcon", routeName: "emerit-location-indoor-ble-transparent", paramsType: "" }]
        },
      ],

      location_additional: [
        {
          title: I18_TITLE_WIDGET_LOC,
          ptiParam: PTI_PARAM_WIDGET_LOC,
          value: false,
          isDisabled: false,
          tippyText: I18_TIPPY_LOC,
          errorMsg: '',
          iconParams: undefined
        },
        {
          title: I18_TITLE_LAST_LOC,
          ptiParam: PTI_PARAM_IS_BUTTON_LAST_LOC,
          value: false,
          isDisabled: false,
          tippyText: I18_TIPPY_LOC,
          errorMsg: "",
          iconParams: undefined
        },
      ],

      ModeFieldsDesc: [MODE_STANDARD_VALUE, MODE_TRANSPARENT_VALUE],
      radioGroupNameModeValues: MODE_RADIO_GROUP_NAME,
      modeValue: undefined,

      hotspotsListFromDbTransparent: undefined,
      beaconsListFromDbTransparent: undefined,
      hotspotsListFromDb: undefined,
      beaconsListFromDb: undefined,
      isBleLevelActivated: undefined,

    };
  },

  watch: {
    'location': {
      deep: true,
      handler: function updateShowLocation (){
        let loc = this.location_additional[WIDGET_LOC_INDEX];
        let lastLoc = this.location_additional[LAST_LOC_INDEX];
        if(!this.isLocationActivated()) {
          loc.value = false;
          setNumberPtiValue(loc.ptiParam, false);
          loc.isDisabled = true;
          lastLoc.value = false;
          setNumberPtiValue(lastLoc.ptiParam, false);
          lastLoc.isDisabled = true;
        } else {
          loc.isDisabled = false;
          lastLoc.isDisabled = false;
        }
      }
    }
  },

  mounted(){
  
  },

  created() {
  
    for (let i = OUTDOOR_INDEX; i < this.location.length; ++i) {
      this.location[i].value = getBoolPtiValue(this.location[i].ptiParam);
    }
      if (getNumberPtiValue(PTI_PARAM_BLE_LOC) == 1 || getNumberPtiValue(PTI_PARAM_WIFI_LOC) == 1) {
    this.location[INDOOR_INDEX].value = true;
    setNumberPtiValue(INDOOR_INDEX, 1);
    }
    if (!this.location[OUTDOOR_INDEX].value && !this.location[INDOOR_INDEX].value)
      this.location[NEVER_INDEX].value = true;
    this.hotspotsListFromDbTransparent = getTransparentWifiBeacons();
    this.beaconsListFromDbTransparent = getBleTransparent();
    this.hotspotsListFromDbTransparent = this.hotspotsListFromDbTransparent != undefined ? this.hotspotsListFromDbTransparent.values : undefined;
    this.beaconsListFromDbTransparent = this.beaconsListFromDbTransparent != undefined ? this.beaconsListFromDbTransparent.values : undefined;
    this.hotspotsListFromDb = getWifiBeacons();
    this.beaconsListFromDb = getBleBeacons();
    this.hotspotsListFromDb = this.hotspotsListFromDb != undefined ? this.hotspotsListFromDb.values : undefined;
    this.beaconsListFromDb = this.beaconsListFromDb != undefined ? this.beaconsListFromDb.values : undefined;
    this.isBleLevelActivated = getBoolPtiValue(PTI_PARAM_IS_BLE_LEVEL_ACTIVATED);

    if(getNumberPtiValue(PARAM_PTI_GET_MODE_VALUE) == 1){
      this.modeValue = 2;
    }else
      this.modeValue = 1;
    if(this.modeValue == 2){
      if(getNumberPtiValue(PTI_PARAM_BLE_LOC) == 1){
        this.indoor_transparent[1].value = true;
        }else{
          this.indoor_transparent[1].value = false;
        }
        if(getNumberPtiValue(PTI_PARAM_WIFI_LOC) == 1){
          this.indoor_transparent[0].value = true;
        }else{
          this.indoor_transparent[0].value = false;
        }
      }else {
        this.indoor_standard[1].value = false;
        this.indoor_standard[0].value = false;
      }
      if(this.modeValue == 1){
        if(getNumberPtiValue(PTI_PARAM_BLE_LOC) == 1){
          this.indoor_standard[1].value = true;
        }else{
          this.indoor_standard[1].value = false;
        }
        if(getNumberPtiValue(PTI_PARAM_WIFI_LOC) == 1){
          this.indoor_standard[0].value = true;
        }else
          this.indoor_standard[0].value = false;
        }else {
          this.indoor_standard[1].value = false;
          this.indoor_standard[0].value = false;
        }
        if (getParameterPTIValue(PTI_PARAM_WIDGET_LOC) == 0){
          this.location_additional[0].value = false
        } else {
          this.location_additional[0].value = true
        }
        if (getParameterPTIValue(PTI_PARAM_IS_BUTTON_LAST_LOC) == 0){
          this.location_additional[1].value = false
        } else {
          this.location_additional[1].value = true
        }
      // for (let i = 0; i < this.location_additional.length; ++i) {
      //   this.location_additional[i].value = getBoolPtiValue(this.location_additional[i].ptiParam);
      // }
    },

  methods: {
 
    onCheckBoxChange(index) {
      this.resetLocationErrorMsg();
      if (index == NEVER_INDEX && this.location[NEVER_INDEX].value == true)
        this.deactivateAllLocation();
      else if (index != NEVER_INDEX && this.location[index].value == true)
        this.location[NEVER_INDEX].value = false;
      for (let i = 1; i < this.location.length; ++i)
        setNumberPtiValue(CHECKBOX_PTI_PARAMS[i], this.location[i].value);
    
    if (this.location[INDOOR_INDEX].value == true) {
        setNumberPtiValue(PTI_PARAM_INDOOR,this.location[INDOOR_INDEX].value);
        if (this.indoor_standard[0].value || this.indoor_transparent[0].value){
          setNumberPtiValue(PTI_PARAM_WIFI_LOC,1);
        }else {
          setNumberPtiValue(PTI_PARAM_WIFI_LOC,0);
        }
        if (this.indoor_standard[1].value || this.indoor_transparent[1].value){
          setNumberPtiValue(PTI_PARAM_BLE_LOC,1);
       }else {
          setNumberPtiValue(PTI_PARAM_BLE_LOC,0);
        }
      }else {
        setNumberPtiValue(PTI_PARAM_WIFI_LOC,0);
        setNumberPtiValue(PTI_PARAM_BLE_LOC,0);
        setNumberPtiValue(PTI_PARAM_INDOOR,0);
        setNumberPtiValue(PARAM_PTI_GET_MODE_VALUE,0);
      }
      if(this.location_additional[WIDGET_LOC_INDEX].value){
        setNumberPtiValue(PTI_PARAM_WIDGET_LOC,1)
      }else {
      setNumberPtiValue(PTI_PARAM_WIDGET_LOC,0)
      }
      if(this.location_additional[LAST_LOC_INDEX].value){
        setNumberPtiValue(PTI_PARAM_IS_BUTTON_LAST_LOC,1)
      }else {
        setNumberPtiValue(PTI_PARAM_IS_BUTTON_LAST_LOC,0)
      }
    },
  
    onRadioChange() {
      if (this.modeValue == 1) {
        setNumberPtiValue(PARAM_PTI_GET_MODE_VALUE, 0);
        setNumberPtiValue(PTI_PARAM_WIFI_LOC, 0);
        setNumberPtiValue(PTI_PARAM_BLE_LOC, 0);
        this.indoor_transparent[0].value = false;
        this.indoor_transparent[1].value = false;
      } else {
        setNumberPtiValue(PARAM_PTI_GET_MODE_VALUE, 1);
        setNumberPtiValue(PTI_PARAM_WIFI_LOC, 0);
        setNumberPtiValue(PTI_PARAM_BLE_LOC, 0);
        this.indoor_standard[0].value = false;
        this.indoor_standard[1].value = false;
      }
      this.$emit('mode-value-change', this.modeValue);
    },

   isLocationActivated() {
      if (this.location[1].value || this.location[2].value)
        return true;
      return false;
    },

    deactivateAllLocation() {
      if(this.location[NEVER_INDEX].value){
        this.location[OUTDOOR_INDEX].value = false;
        this.location[INDOOR_INDEX].value = false;
        this.location[NEW_INDOOR_WIFI_INDEX].value = false;
        this.location[NEW_INDOOR_BLE_INDEX].value = false;
        this.location[WIDGET_LOC_INDEX].value = false;
        this.location[LAST_LOC_INDEX].value = false;
        setParameterPTIValue(PTI_PARAM_INDOOR,0);
        setParameterPTIValue(PTI_PARAM_BLE_LOC,0);
        setParameterPTIValue(PTI_PARAM_WIFI_LOC,0);
        setParameterPTIValue(PTI_PARAM_WIDGET_LOC,0);
        setParameterPTIValue(PTI_PARAM_IS_BUTTON_LAST_LOC,0);
      }
      this.location[NEVER_INDEX].value = true;
    },

    check() {
      let nbCorrect = 0;
      nbCorrect += Number(this.checkWifiHotspot());
      nbCorrect += Number(this.checkBleBeacons());
      nbCorrect += Number(this.checkTransparentWifiHotspot());
      nbCorrect += Number(this.checkTransparentBleBeacons());
      if (this.location[INDOOR_INDEX].value && this.indoor_standard[1].value == false &&  this.indoor_standard[0].value == false
          && this.indoor_transparent[1].value == false &&  this.indoor_transparent[0].value == false ){
            this.location[INDOOR_INDEX].errorMsg = ERR_NO_PARAM_FOUND;
            return false;
          }
      if (this.location[INDOOR_INDEX].value  && this.modeValue == 2
      && !this.indoor_transparent[1].value  &&  !this.indoor_transparent[0].value){
        this.indoor_transparent[0].errorMsg = ERR_NO_CHECK_TRANSPARENT;
        this.indoor_transparent[1].errorMsg = ERR_NO_CHECK_TRANSPARENT;
        return setError(this.indoor_transparent, NEW_INDOOR_BLE_INDEX, ERR_NO_CHECK_TRANSPARENT);

      }
      if (this.location[INDOOR_INDEX].value && this.modeValue == 1
      && !this.indoor_standard[1].value  &&  !this.indoor_standard[0].value  ){
        this.indoor_standard[0].errorMsg = ERR_NO_CHECK_STANDARD;
        this.indoor_standard[1].errorMsg = ERR_NO_CHECK_STANDARD;
        return setError(this.indoor_standard, NEW_INDOOR_BLE_INDEX, ERR_NO_CHECK_STANDARD);
      }
          
      resetError(this.location, INDOOR_INDEX);
      if (nbCorrect != 4){
        return false;
      }
      for (let i = 0; i < this.location.length; ++i)
        if (this.location[i].value === true){
          return true;
        }
      this.location[NEVER_INDEX].errorMsg = ERR_CONFIRM_DEACTIVATION_LOC;

      return false;
    },

    resetLocationErrorMsg() {
      for (let i = 0; i < this.indoor_standard.length; ++i)
        if (this.indoor_standard[i].errorMsg.length)
          this.indoor_standard[i].errorMsg = "";
      for (let i = 0; i < this.indoor_transparent.length; ++i)
        if (this.indoor_transparent[i].errorMsg.length)
          this.indoor_transparent[i].errorMsg = "";
    },

    checkWifiHotspot() {
      if (this.location[INDOOR_INDEX].value === true 
          && this.hotspotsListFromDb === undefined 
          && this.indoor_standard[NEW_INDOOR_WIFI_INDEX].value === true)
        return setError(this.indoor_standard, NEW_INDOOR_WIFI_INDEX, ERR_NO_WIFI_HOTSPOT);
      if (this.indoor_standard[NEW_INDOOR_BLE_INDEX].value
          && this.indoor_standard[NEW_INDOOR_WIFI_INDEX].value
          && this.isBleLevelActivated
          && !this.checkWifiHotspotLevel())
        return setError(this.indoor_standard, NEW_INDOOR_WIFI_INDEX, ERR_INDICATE_HOTSPOT_LEVEL);
      return true;
    },

    checkWifiHotspotLevel() {
      for (let i = 0; i < this.hotspotsListFromDb.length; ++i){
          if (!this.hotspotsListFromDb[i][HOTSPOT_DB_LEVEL_COLUMN_INDEX].length){
            return false;
          }
      }
      return true;
    },
 
    checkBleBeaconLevel() {
      let levelBeaconIsMissing = true;
      for (let i = 0; i < this.beaconsListFromDb.length; ++i) {
        if (levelBeaconIsMissing
            && this.beaconsListFromDb[i][BEACON_DB_TYPE_COLUMN_INDEX].valueOf() === LEVEL_TYPE)
          levelBeaconIsMissing = false;
        if (this.beaconsListFromDb[i][BEACON_DB_TYPE_COLUMN_INDEX].valueOf() != LEVEL_TYPE
            && !this.beaconsListFromDb[i][BEACON_DB_LEVEL_COLUMN_INDEX].length)
          return setError(this.indoor_standard, NEW_INDOOR_BLE_INDEX, ERR_INDICATE_BEACON_LEVEL);
      }
      if (levelBeaconIsMissing)
        return setError(this.indoor_standard, NEW_INDOOR_BLE_INDEX, ERR_NO_LEVEL_BEACON);
      return true;
    },

    checkBleBeacons() {
      if (this.indoor_standard[1].value === true) {
        if(getBoolPtiValue(PTI_PARAM_BLE_LOC)
            && this.beaconsListFromDb === undefined)
          return setError(this.indoor_standard, NEW_INDOOR_BLE_INDEX, ERR_NO_BLE_BEACON);
        if (this.isBleLevelActivated && !this.checkBleBeaconLevel())
          return false;
      }
      return resetError(this.indoor_standard, NEW_INDOOR_BLE_INDEX);
    },

    checkTransparentWifiHotspot() {
      if (this.location[INDOOR_INDEX].value 
          && this.hotspotsListFromDbTransparent === undefined 
          && this.indoor_transparent[0].value === true)
        return setError(this.indoor_transparent, NEW_INDOOR_WIFI_INDEX, NO_SSID_ASSIGN);
      return true
    },

    checkTransparentBleBeacons() {
      if (this.indoor_transparent[1].value === true) {
        if(getBoolPtiValue(PTI_PARAM_BLE_LOC)
            && this.beaconsListFromDbTransparent === undefined)
          return setError(this.indoor_transparent, NEW_INDOOR_BLE_INDEX, NO_UUID_ASSIGN);
      }
      return resetError(this.indoor_transparent, NEW_INDOOR_BLE_INDEX);
    },
  }
})

</script>