<template>
  <div>
    <Title
      title="internalrecipients.menu_internal_recipients"
    />
    <RecipientsSettings
      :recipient-list="recipientList"
      :icons-rendered-list="iconsRenderedList"
      :is-sip-activated="isSipActivated"
      :modal-add-modify-recipient-id="modalIdAddModifyRecipient"
      :modal-alert-confirm-id="modalIdAlertConfirm"
      @add-recipient="$refs.modalAddModifyRecipient.initValues(undefined)"
      @remove-all-recipients="initModalConfirm"
    />
    <RecipientsList
      :recipient-list="recipientList"
      :is-pec-activated="isPecActivated"
      :is-sip-activated="isSipActivated"
      :modal-add-modify-recipient-id="modalIdAddModifyRecipient"
      :modal-alert-confirm-id="modalIdAlertConfirm"
      @get-icons-rendered-list="iconsRenderedList = $event"
      @modify-recipient="initModalModify"
      @remove-recipient="initModalConfirm"
    />
    <ModalAddModifyRecipient
      ref="modalAddModifyRecipient"
      :modal-id="modalIdAddModifyRecipient"
      :recipient-list="recipientList"
      :is-sip-activated="isSipActivated"
      :is-pec-activated="isPecActivated"
      @add-recipient="addNewRecipient"
      @modify-recipient="modifyExistingRecipient"
    />
    <ModalAlertConfirmChoice
      ref="modalAlertConfirmChoice"
      :modal-id="modalIdAlertConfirm"
      alert-title="common.menu_are_you_sure"
      :alert-text="modalAlertText"
      icon-component-name="AlertTriangleIcon"
      left-button-component-name="ButtonDismissModal"
      left-button-text="common.menu_cancel"
      left-button-custom-class="btn btn-outline-secondary mr-1"
      right-button-component-name="ButtonDismissModal"
      right-button-text="common.menu_remove"
      right-button-custom-class="btn btn-danger"
      @right-button="remove"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useStore } from "@/store";
import {
  getBoolPtiValue,
} from '../../dao/parametre-pti'
import { 
  addRecipient,
  deleteAllRecipient, 
  deleteRecipient, 
  getAllRecipient, 
  getLastRecipientIdCreated, 
  updateRecipient
} from '../../dao/recipient-dao';
import { 
  addRecipientTakenIntoAccount,
  deleteAllRecipientTakenIntoAccount, 
  deleteRecipientTakenIntoAccount, 
  getAllRecipientTakenIntoAccount,
  getLastRecipientTakenInAccountIdCreated,
  updateRecipientTakenIntoAccount
} from '../../dao/recipient-taken-into-account';
import {
  createRecipientFromDb,
  createRecipientFromDbTakenInAccount
} from './local-components/utils'
import RecipientsSettings from './local-components/lc-recipients-settings.vue'
import RecipientsList from './local-components/lc-recipients-list.vue'
import ModalAddModifyRecipient from './local-components/lc-modal-add-modify-recipient.vue'


const MODEL_LOGIN_PATH = "model-login";

const PARAM_PTI_IS_PEC = "TraitementSmsPriseEnCompteAppel";
const PARAM_PTI_IS_SIP_AVAILABLE = "SipManagerAvailable";
const PARAM_PTI_IS_SIP_ACTIVATED = "SipActivationState";

const REMOVE_ONE_ITEM = 0;
const REMOVE_EVERYTHING = 1;

const I18_REMOVE_ONE_RECIPIENT = "internalrecipients.menu_remove_one_recipient";
const I18_REMOVE_EVERYTHING = "internalrecipients.menu_remove_all_recipients";

const BODY = "BODY";
const MODAL_ID_ADD_MODIFY_RECIPIENT = "modal-add-modify-recipient";
const MODAL_ID_ALERT_CONFIRM = "modal-alert-confirm";


export default defineComponent({

  components: {
    RecipientsSettings,
    RecipientsList,
    ModalAddModifyRecipient,
  },

  /**
   * Dismiss the modals if they're opened by clicking on their right buttons, 
   * and wait X milliseconds until the modals are correctly close by checking
   * if they're still present in the <body></body> HTML element, then route
   * to the new view.
   * 
   * Fix a bug when opening a modal and then using navigation arrows (next, prev).
   * The modal wasn't correctly closed and provoquing a crash with all the other modals
   * until next reload.
   */
  beforeRouteLeave (to, from, next) {
    this.$refs.modalAlertConfirmChoice.dismiss();
    this.$refs.modalAddModifyRecipient.dismiss();
    let intervalId = setInterval(() => {  
      const modalAlertConfirm = document.getElementById(MODAL_ID_ALERT_CONFIRM);
      const modalAddModifyRecipient = document.getElementById(MODAL_ID_ADD_MODIFY_RECIPIENT);
      if (modalAlertConfirm.parentElement.tagName.valueOf() != BODY
          && modalAddModifyRecipient.parentElement.tagName.valueOf() != BODY) {
        clearInterval(intervalId);
        next();
      }
    }, 100);
	},

  data() {
    return {
      isSipActivated: false,
      isPecActivated: false,
      recipientList: [],
      iconsRenderedList: [],
      currentIndex: undefined,
      removeType: undefined,
      daoCreateRecipient: undefined,
      daoLastRecipientIdCreated: undefined,
      daoGetAllRecipients: undefined,
      daoAddRecipient: undefined,
      daoModifyRecipient: undefined,
      daoRemoveOneRecipient: undefined,
      daoRemoveAllRecipients: undefined,
      modalIdAddModifyRecipient: MODAL_ID_ADD_MODIFY_RECIPIENT,
      modalIdAlertConfirm: MODAL_ID_ALERT_CONFIRM,
    }
  },

  computed: {
    modalAlertText() {
      return this.removeType == REMOVE_ONE_ITEM ? 
          I18_REMOVE_ONE_RECIPIENT : I18_REMOVE_EVERYTHING;
    },
  },

  watch: {
    'recipientList.length': {
      handler: function updateIcons() {
        if (!this.recipientList.length)
          this.iconsRenderedList = [];
      }
    }
  },

  created() {
    this.isPecActivated = getBoolPtiValue(PARAM_PTI_IS_PEC);
    this.initSipValue();
    this.initDaoFunctions();
    this.initRecipientList();
  },

  methods: {

    initSipValue() {
      let isDeployCred = false; 
      if (useStore().state.emeritValues.disconnectionPath === MODEL_LOGIN_PATH)
        isDeployCred = true;
      const isSipAvailable = isDeployCred ? true : getBoolPtiValue(PARAM_PTI_IS_SIP_AVAILABLE);
      if (isSipAvailable === undefined)
        this.isSipActivated = false;
      else if (isSipAvailable)
        this.isSipActivated = getBoolPtiValue(PARAM_PTI_IS_SIP_ACTIVATED)
    },

    initDaoFunctions() {
      if (this.isPecActivated) {
        this.daoCreateRecipient = createRecipientFromDbTakenInAccount ;
        this.daoLastRecipientIdCreated = getLastRecipientTakenInAccountIdCreated;
        this.daoGetAllRecipients = getAllRecipientTakenIntoAccount;
        this.daoAddRecipient = addRecipientTakenIntoAccount;
        this.daoModifyRecipient = updateRecipientTakenIntoAccount;
        this.daoRemoveOneRecipient = deleteRecipientTakenIntoAccount;
        this.daoRemoveAllRecipients = deleteAllRecipientTakenIntoAccount;
      }
      else {
        this.daoCreateRecipient = createRecipientFromDb; 
        this.daoLastRecipientIdCreated = getLastRecipientIdCreated;
        this.daoGetAllRecipients = getAllRecipient;
        this.daoAddRecipient = addRecipient;
        this.daoModifyRecipient = updateRecipient;
        this.daoRemoveOneRecipient = deleteRecipient;
        this.daoRemoveAllRecipients = deleteAllRecipient;
      }
    },

    initRecipientList() {
      let tmpList = this.daoGetAllRecipients();
      if (tmpList)
        for (let i = 0; i < tmpList.values.length; ++i)
          this.recipientList.push(this.daoCreateRecipient(tmpList.values[i]));
    },

    initModalConfirm(index) {
      this.currentIndex = index == undefined ? this.currentIndex : index;
      this.removeType = index == undefined ? REMOVE_EVERYTHING : REMOVE_ONE_ITEM;
    },

    initModalModify(index) {
      this.currentIndex = index;
      this.$refs.modalAddModifyRecipient.initValues(index)
    },

    addNewRecipient(newRecipient) {
      this.daoAddRecipient(newRecipient);
      newRecipient.id = this.daoLastRecipientIdCreated();
      this.recipientList.push(newRecipient);
    },

    modifyExistingRecipient(newRecipient) {
      for (let i = 0; i < this.recipientList.length; ++i)
        if (this.recipientList[i].id === newRecipient.id)
          this.recipientList[i] = newRecipient;
      this.daoModifyRecipient(newRecipient);
    },

    remove() {
      if (this.removeType === REMOVE_ONE_ITEM) {
        this.daoRemoveOneRecipient(this.recipientList[this.currentIndex].id);
        this.recipientList.splice(this.currentIndex, 1)
      }
      else {
        this.recipientList = [];
        this.daoRemoveAllRecipients();
      }
    },

  }
})
</script>
