{
  "common": {
    "menu_nothing":"Nichts",
    "menu_min_message": "Die Mindestanzahl an Nachrichten beträgt 1",
    "menu_field_cannot_be_empty_need_one": "Mindestens ein Feld muss ausgefüllt werden",
    "menu_affichage": "Anzeige",
    "menu_son": "Seine",
    "menu_post_alert": "Nachalarm",
    "menu_vibration_5_secondes": "Vibrationen alle 5 Sekunden",
    "menu_phone_number_must_start": "Die Telefonnummer darf nur Ziffern enthalten",
    "menu_wrong_file_certificate": "Falsches Dateiformat. Das Format muss .pfx oder .p12 sein.",
    "menu_wrong_config": "Die Konfiguration ist falsch",
    "menu_see_help_for_more_info": "Weitere informationen finden sie in der online-hilfe.",
    "menu_back": "Zurück",
    "menu_save": "Speichern",
    "menu_settings": "Einstellungen",
    "menu_notification_prealert": "Voralarm-Benachrichtigung",
    "menu_notification_by_vibration": "Benachrichtigung durch Vibration",
    "menu_notification_by_sound": "Benachrichtigung durch Ton",
    "menu_notification_by_flash": "Benachrichtigung durch Blitzlicht",
    "menu_notification_duration": "Dauer der Voralarmbenachrichtigung",
    "menu_seconds": "Sekunden",
    "menu_minutes": "Minuten",
    "menu_degrees": "Grad",
    "menu_meters": "Meter",
    "menu_settings_for": "Einstellungen für ",
    "menu_confirm": "Bestätigen Sie",
    "menu_field_cannot_be_empty": "Feld kann nicht leer sein",
    "menu_select_at_least_one_triggering_mode": "Mindestens einen Auslösemodus auswählen",
    "menu_phone_number_must_start_with_+": "Rufnummer muss mit + beginnen",
    "menu_phone_number_is_too_short": "Rufnummer ist zu kurz",
    "menu_phone_number_already_used": "Rufnummer bereits verwendet",
    "menu_select_at_least_one_event": "Mindestens ein Ereignis auswählen",
    "menu_are_you_sure": "Sind Sie sicher?",
    "menu_add": "Hinzufügen",
    "menu_modify": "Ändern",
    "menu_remove": "Entfernen",
    "menu_remove_everything": "Alles entfernen",
    "menu_wrong_flie_format": "Falsches Dateiformat",
    "menu_cancel": "Abbrechen"
  },
  "configuration": {
    "menu_no_checked_standard": "Für den Standardmodus wurden keine Parameter ausgewählt",
    "menu_no_checked_transparent": "Für den transparenten Modus wurden keine Parameter ausgewählt",
    "menu_location_standard": "Standardmodus",
    "menu_location_transparent": "Transparenter Modus",
    "menu_location_indoor": "Innenbereich",
    "menu_type_action": "Art der Aktion, die mit drei schnellen Tipps verbunden ist",
    "menu_device_stop": "Gerät stoppen",
    "menu_custom_on_off": "Anpassung der Ein-/Aus-Taste",
    "menu_trigger_alert":"Alarm auslösen",
    "menu_trigger_call": "Rufen Sie durch dreimaliges Tippen auf die folgende Nummer an:",
    "menu_tippy_text_on_off":"Gehe zu [Anpassung der Ein-/Ausschalttaste], um die Funktion zu aktivieren",
    "menu_show_last_location": "Speichern Sie den zuletzt bekannten Standort bei jedem Gerätestopp",
    "menu_force_alert_reactivated_system": "Erzwingen Sie die Reaktivierung des Systems bei jedem Einschalten des Geräts",
    "menu_err_serv_with_no_events": "Es wurden keine Ereignisse konfiguriert",
    "menu_err_one_or_several_recipient_have_message_but_no_events": "Ein oder mehrere Empfänger erhalten Ereignisse per SMS, aber es wurden keine Ereignisse konfiguriert",
    "menu_img_upload_failure": "Bild hochladen fehlgeschlagen, überprüfen Sie Ihre Internetverbindung",
    "menu_choose_at_least_one_custom_screen": "Keine Konfiguration abgeschlossen",
    "menu_custom_screens": "Bildschirm anpassen",
    "menu_communication_option": "Kommunikationsoptionen",
    "menu_system_option": "Systemoptionen",
    "menu_disable_on_off_btn": "Power-taste deaktivieren, um zu verhindern, dass sich das Gerät ausschaltet",
    "menu_model_check_emerit_version_unstopable_call": "Diese Einstellung erfordert, dass die geräte-firmware kompatibel ist. Sie können die ROM-Version über das info-widget überprüfen.",
    "menu_tippy_unstopable_call_not_available": "Diese Einstellung ist nicht mit der Firmware Ihres Geräts kompatibel. Bitte kontaktieren Sie den technischen Support für weitere Informationen.",
    "menu_call_cant_be_stop": "Alarmrufe können nicht vom Benutzer gestoppt werden",
    "menu_whitelist": "Whitelist (Beschränkung eingehender Anrufe)",
    "menu_wifi_configuration": "Wifi verbindung",
    "menu_tippy_sip_not_available": "Die VoIP-Aktivierung ist auf Ihrem Gerät nicht verfügbar",
    "menu_tippy_widget_loc": "Für diese Einstellung muss mindestens einer der Ortungsmodi aktiviert sein",
    "menu_model_check_emerit_version": "VoIP erfordert, dass die Emerit SIP-App auf diesem Gerät vorhanden ist. Sie können über das Info-Widget prüfen, ob sie vorhanden ist.",
    "menu_enabling_sip_will_remove_recipients": "Durch die Aktivierung von VoIP werden registrierte interne Empfänger entfernt. Sie müssen eine neue Empfängerliste eingeben.",
    "menu_disabling_sip_will_remove_recipients": "Durch das Deaktivieren von VoIP werden registrierte interne Empfänger entfernt. Sie müssen eine neue Empfängerliste eingeben.",
    "menu_wrong_character_in_user_id": "Benutzeridentifikation kann kein '_'-Zeichen enthalten, wenn mindestens eine Emerit-Uhr als interner Empfänger ausgewählt ist",
    "menu_choose_notif_mode": "Auswahl des Benachrichtigungsmodus für eingehende SMS",
    "menu_err_choose_at_least_one_notif_mode": "Mindestens einen Benachrichtigungsmodus auswählen",
    "menu_alerts_processing": "Bearbeitung von Benachrichtigungen",
    "menu_internal_recipients": "Interne Empfänger",
    "menu_alarm_monitoring_center": "Alarmüberwachungszentrale",
    "menu_alarm_monitoring_server": "Alarmüberwachungsserver",
    "menu_alerts_localization": "Lokalisierung von Alarmen",
    "menu_location_never": "Niemals",
    "menu_location_outdoor": "Außen",
    "menu_alerts_triggering_modes": "Auslösemodus für Alarme",
    "menu_alert_activation_sos_button": "SOS-Taste",
    "menu_alert_activation_sos_button_and_programmable_button": "SOS-Taste und programmierbare Taste",
    "menu_alert_activation_side_black_button": "Seitliche schwarze Taste",
    "menu_alert_activation_side_yellow_button": "Seitliche gelbe Taste",
    "menu_alert_activation_front_programmable_button": "Programmierbare Taste vorne",
    "menu_alert_activation_side_programmable_button": "Seitliche programmierbare Taste",
    "menu_alert_activation_automatic_detection": "Automatische Erkennungen",
    "menu_wrist_alert_mode": "Schnelle Bewegungen des Handgelenks",
    "menu_wearing_style_wrist": "Handgelenk",
    "menu_wearing_style_waist": "Taille",
    "menu_wearing_style_arms": "Arm",
    "menu_wearing_style_neck": "Hals",
    "menu_alert_activation_power_button": "Taste EIN/AUS",
    "menu_update_configuration": "Konfiguration aktualisieren",
    "menu_configuration_update_has_succeeded": "Die Aktualisierung der Konfiguration war erfolgreich",
    "menu_device_must_be_restarted": "Starten Sie das Gerät oder starten Sie den Vorgang zum Laden der neuen Konfiguration",
    "menu_no_alert_recipient_was_selected": "Es wurde kein Alarmempfänger ausgewählt",
    "menu_no_alert_processing_was_selected": "Es wurde keine Alarmverarbeitung ausgewählt",
    "menu_no_recipient_or_server_was_created": "Es wurde kein Empfänger oder Server erstellt",
    "menu_no_recipient_was_created": "Es wurde kein Empfänger erstellt",
    "menu_no_server_was_created": "Es wurde kein Server erstellt",
    "menu_validate_configuration": "Konfiguration validieren",
    "menu_wifi": "Wifi",
    "menu_select_at_least_one_automatic_detection": "Mindestens eine automatische Erkennung auswählen",
    "menu_save_at_least_one_wifi_connection": "Mindestens eine Wifi-Verbindung speichern",
    "menu_configuration_update_has_failed": "Update ist fehlgeschlagen",
    "menu_check_internet_connection_and_retry": "Internetverbindung prüfen und erneut versuchen",
    "menu_download_configuration_model_to_computer": "Konfigurationsvorlage auf den Computer herunterladen",
    "menu_configuration_model_name": "Name der Konfigurationsvorlage",
    "menu_device_notification": "Gerätebenachrichtigungsverwaltung",
    "menu_password_for_deployment": "Kennwort für die Profil-Konfiguration bestehend aus: 6 Zeichen, davon mindestens 1 Kleinbuchstabe, 1 Großbuchstabe und 1 Zahl, kein Sonderzeichen",
    "menu_enter_a_password": "Geben Sie ein Kennwort ein",
    "menu_password_must_be_different_from_access_password": "Das Kennwort muss sich vom Zugangspasswort unterscheiden",
    "menu_location_indoor_wifi": "Im Innenbereich über Wifi",
    "menu_location_indoor_ble": "Im Innenbereich mit Bluetooth",
    "menu_location_automatic_incoming_call_pickup_after_three_rings": "Automatische Anrufübernahme nach 3 Klingelzeichen",
    "menu_err_ble_level_precision_not_activated": "Die Verwaltung mit Bluetooth-Beacons wurde für die Wifi-Innenraumortung ausgewählt. Dieser Parameter ist obligatorisch.",
    "menu_deployment_password": "Kennwort für die Profil-Bearbeitung",
    "menu_no_wifi_access_point_has_been_registered": "Es wurde kein Wifi Access Point registriert",
    "menu_no_ble_beacon_has_been_registered": "Es wurde kein Bluetooth-Beacon registriert",
    "menu_deactivation_of_alert_system_by_the_user": "Deaktivierung des Warnsystems durch den Benutzer",
    "menu_freely_disable_enable_alert_system": "Erlauben Sie das Alarmsystem frei zu deaktivieren/aktivieren",
    "menu_temporarily_disable_enable_alert_system": "Erlauben Sie, das Warnsystem vorübergehend zu deaktivieren/aktivieren",
    "menu_duration_of_deactivation": "Dauer der Deaktivierung",
    "menu_GSM_data_wifi_issue_notification": "Benachrichtigung bei GSM-, Daten- und Wifi-Problemen",
    "menu_battery_monitoring": "Batterieüberwachung",
    "menu_networks_monitoring": "Überwachung von Netzwerken",
    "menu_low_battery_notification_triggering_threshold": "Auslöseschwelle für Benachrichtigung bei niedrigem Batteriestand",
    "menu_name_is_not_displayed": "Der Benutzername wird nicht auf dem Gerät angezeigt",
    "menu_confirm_deactivation_of_localisation": "Bestätigen Sie die Deaktivierung des Standorts",
    "menu_ask_to_activate_sip": "Aktivieren Sie Voice over IP (VoIP) mit SIP-Konto",
    "menu_wrong_sip_configuration": "Alle VoIP-Felder müssen ausgefüllt werden",
    "menu_show_fragment_location": "Aktivieren Sie das Widget für den aktuellen Standort",
    "menu_5_percent": "5%",
    "menu_10_percent": "10%",
    "menu_20_percent": "20%",
    "menu_name_is_displayed": "Der Benutzername wird auf dem Gerät angezeigt",
    "menu_name_is_battery_percentage_is_displayed": "Batterieprozentsatz anzeigen",
    "menu_name_is_wake_up_screen_when_charging_enabled": "Aktivieren Sie den Bildschirm alle 10 Sekunden während des Ladevorgangs, wenn die Uhr eingeschaltet ist",
    "menu_name_is_sound_notif_securite_positive_enabled": "Ton für Benachrichtigungen aktivieren"
  },
  "alerttrigerring": {
    "menu_enable_selecte_switch":"Bitte wählen Sie mindestens eine Erkennung aus",
    "menu_agression": "Angriff",
    "menu_demande_secours": "Nach Hilfe fragen",
    "menu_message_alert": "Warnmeldung zu senden",
    "menu_communication_unidirectionnelle": "Einwegkommunikation (Lautsprecher deaktiviert)",
    "menu_communication_bidirectionnelle": "Zwei-Wege-Kommunikation",
    "menu_cycle_destinataires_télésurveillance": "Automatischer Rufzyklus (interne Empfänger oder Fernüberwachungsruf)",
    "menu_sos_button_settings": "Einstellungen der SOS-Taste",
    "menu_power_button_settings": "Einstellungen der Taste Power ON/OFF",
    "menu_automatic_detection_settings": "Einstellungen für die automatische Erkennung",
    "menu_trigger_mode": "Auslösemodus",
    "menu_one_long_press": "Einmal lang drücken",
    "menu_three_short_presses": "Dreimaliges kurzes Drücken",
    "menu_alert_mode": "Alarmierungsmodus",
    "menu_speaker_on_request_for_help": "Lautsprecher EIN > Bitte um Hilfe",
    "menu_silent_assault": "Lautlos > Überfallsituation",
    "menu_select_at_least_one_notification_mode": "Mindestens einen Benachrichtigungsmodus auswählen",
    "menu_front_programmable_button_settings": "Einstellungen für programmierbare Tasten auf der Vorderseite",
    "menu_side_black_button_settings": "Einstellungen für schwarze Tasten an der Seite",
    "menu_side_yellow_button_settings": "Einstellungen für gelbe Tasten an der Seite",
    "menu_side_programmable_button_settings": "Einstellungen für programmierbare Tasten an der Seite",
    "menu_sos_button_and_programmable_button_settings": "Einstellungen für SOS-Taste und programmierbare Tasten"
  },
  "locationoutdoor": {
    "menu_outdoor_location": "Einstellungen für den Außenbereich",
    "menu_gps_based": "GPS-basierte Ortung in Innenräumen",
    "menu_refreseh_only_when_event_sending": "Nur aktualisieren, wenn Ereignis gesendet wird",
    "menu_update_at_regular_intervals": "In regelmäßigen Abständen aktualisieren",
    "menu_automatic_refresh_interval": "Automatisches Aktualisierungsintervall",
    "menu_location_update_method": "Standortaktualisierungsmethode"
  },
  "locationindoorwifi": {
    "menu_err_ssid_already_exist": "Die SSID existiert bereits in der Liste",
    "menu_no_ble_transparent": "Es wurde keine Bluetooth UUID registriert",
    "menu_no_wifi_hotspot_transparent": "Es wurde kein WiFi-SSID registriert",
    "menu_no_param_found_indoord": "Es wurde kein Parameter ausgewählt",
    "menu_list_ssid_filter": "Liste der zu filternden SSIDs",
    "menu_add_ssid_filter": "SSIDs zum Filtern hinzufügen",
    "menu_wifi_based_indoor_location_transparent": "Innenraumpositionierung basierend auf WiFi (Transparenter Modus)",   
    "menu_disable_ble_loc_to_handle_floors": "Diese Einstellung muss auf der Einrichtungsseite für die Bluetooth-Ortung im Innenbereich deaktiviert werden",
    "menu_enable_ble_loc_to_handle_floors": "Diese Einstellung muss auf der Einrichtungsseite für den Innenbereich über Bluetooth aktiviert werden",
    "menu_wifi_based_indoor_location": "Wifi-basierte Ortung in Innenräumen",
    "menu_enable_wifi_based_indoor_location": "Wifi-basierte Innenraumortung aktivieren",
    "menu_import_a_new_wifi_hotspot_file": "Eine neue Wifi-Hotspot-.csv-Datei importieren",
    "menu_import_succeeded": "Import erfolgreich",
    "menu_confirm_import": "Bestätigen Sie den Import",
    "menu_import_will_replace_existing_list": "Der Import aus einer .csv-Datei ersetzt die aktuell gespeicherte Wifi-Hotposts-Liste",
    "menu_error_while_importing": "Fehler beim Importieren",
    "menu_export_to_csv_wifi_configuration": "Exportieren Sie die Wifi-Hotposts-Liste in eine .csv-Datei",
    "menu_excel_file_is_empty": "Excel-Datei ist leer",
    "menu_check_your_excel_file": "Überprüfen Sie Ihre Excel-Datei",
    "menu_download_excel_file_template_for_importation": "Excel-Vorlage für den Import herunterladen",
    "menu_number_of_imported_wifi_hotspots": "Anzahl der importierten WiFi-Hotspots: ",
    "menu_an_error_was_detected_line": "Es wurde ein Fehler in der Zeile entdeckt",
    "menu_number_of_registered_wifi_hotspots": "Anzahl der registrierten WiFi-Hotspots: ",
    "menu_incorrect_column_headers": "Falsche Spaltenüberschriften. Bitte verwenden Sie die Excel-Vorlage, um Wifi-Hotspots zu erfassen, und speichern Sie sie im .csv-Format mit dem Zeichen ';' als Trennzeichen.",
    "menu_wifi_hotspot_for_indoor_location": "Wifi-Hotspot für Indoor-Standort",
    "menu_no_wifi_hotspot_recorded": "Kein Wifi-Hotspot aufgezeichnet",
    "menu_no_wifi_hotspot_imported": "Kein Wifi-Hotspot importiert",
    "menu_add_hotspot": "Wifi-Hotspot hinzufügen",
    "menu_modify_hotspot": "Wifi-Hotspot ändern",
    "menu_hotspot_ssid": "SSID",
    "menu_hotspot_bssid": "BSSID",
    "menu_hotspot_code_zone": "Zonencode",
    "menu_hotspot_level": "Ebene",
    "menu_hotspot_description": "Beschreibung",
    "menu_tippy_ssid": "Wifi-Netzwerkname",
    "menu_tippy_bssid": "MAC-Adresse, die vom Wifi-Hotspot gesendet wird (6 Paare von hexadezimalen Zeichen, getrennt durch das Zeichen ':')",
    "menu_tippy_code_zone": "Vom SIA-Protokoll gesendete Informationen",
    "menu_tippy_level": "0 für Erdgeschoss, negative Zahl für Untergeschoss (z.B. -3)",
    "menu_tippy_description": "Mit dem Hotspot verbundener Standort. Es ist verboten, das Zeichen „_“ zu verwenden, wenn die Option zum Senden von Nachrichten an Emerit-Uhren aufgrund eines Fehlers aktiviert ist (dieser Fehler wird im nächsten Update behoben).",
    "menu_err_empty_ssid": "Das SSID-Feld darf nicht leer sein.",
    "menu_err_wrong_bssid": "Falsche BSSID. Die BSSID muss eine Folge von 6 Ziffern-/Buchstabenpaaren sein, die durch ein ':'-Zeichen getrennt sind. Beispiel: 11:22:33:44:55:66.",
    "menu_err_bssid_already_exist": "Die eingegebene BSSID wurde bereits registriert.",
    "menu_err_wrong_level": "Falsche Ebenennummer. Muss eine positive oder negative Zahl sein.",
    "menu_err_empty_desc": "Beschreibung kann nur aus Buchstaben oder Zahlen bestehen, die durch Leerzeichen getrennt sind",
    "menu_err_wrong_code_zone": "Codezone muss eine Folge von 3 Zahlen zwischen 001 und 999 sein. Beispiel: 007.",
    "menu_add_hotspot_success": "Die Stufe wurde nicht für alle Wifi-Hotspots eingegeben. Bitte überprüfen Sie die registrierte Liste.",
    "menu_err_one_elem_missing_level": "Wifi-Hotspot mit Erfolg hinzugefügt",
    "menu_add_hotspot_failed": "Fehler beim Versuch, einen neuen Wifi-Hotspot hinzuzufügen",
    "menu_modify_hotspot_success": "Wifi-Hotspot mit Erfolg geändert",
    "menu_level_precision_with_ble": "Etagenwechsel mit Bluetooth Beacons behandeln",
    "menu_enable_import_export_csv": ".csv-Datei importieren/exportieren",
    "menu_add_manually_wifi_hotspots": "Manuelles Hinzufügen von Wifi-Hotspots",
    "menu_remove_all_hotspots": "Sind Sie sicher, dass Sie alle registrierten Wifi-Hotspots löschen möchten?",
    "menu_remove_one_hotspot": "Sind Sie sicher, dass Sie diesen Wifi-Hotspot löschen möchten?",
    "menu_precise_wifi_location_with_ble": "Wifi-Standort mit Bluetooth präzisieren",
    "menu_title_refresh_choice":" Frequence d'actualisation"
  },
  "locationindoorble": {
    "menu_remove_one_beacon_transparent": "Möchten Sie diese UUID wirklich löschen?",
    "menu_add_beacon_transparent_success": "UUID erfolgreich hinzugefügt",
    "menu_condition_uuid_transparent": "Das UUID-Format muss 32 Zeichen in hexadezimaler Form sein",
    "menu_add_beacon_transparent_failed": "UUID bereits registriert",
    "menu_list_ssid_filtered": "Liste der zu filternden SSIDs",
    "menu_custom_model": "Andere UUID",
    "menu_ble_based_indoor_location_transparent": "Innenraumpositionierung basierend auf Bluetooth (Transparenter Modus)",
    "menu_list_uuid_filtered": "Liste der zu filternden UUIDs",
    "menu_add_uuid_filtered": "Company ID (UUID) zum Filtern hinzufügen",
    "menu_ble_based_indoor_location": "Bluetooth-basierte Innenraumortung",
    "menu_enable_ble_based_indoor_location": "Aktivieren Sie die Bluetooth-basierte Innenraumortung",
    "menu_download_excel_file_template_for_importation": "Excel-Vorlage für den Import herunterladen",
    "menu_import_a_new_ble_beacons_file": "Importieren Sie eine neue Bluetooth-Beacons .csv-Datei",
    "menu_import_succeeded": "Import erfolgreich",
    "menu_confirm_import": "Bestätigen Sie den Import",
    "menu_import_will_replace_existing_list": "Der Import aus einer .csv-Datei ersetzt die aktuell gespeicherte Bluetooth-Beacons-Liste",
    "menu_error_while_importing": "Fehler beim Importieren",
    "menu_excel_file_is_empty": "Excel-Datei ist leer",
    "menu_check_your_excel_file": "Überprüfen Sie Ihre Excel-Datei.",
    "menu_export_to_csv_ble_configuration": "Exportieren Sie die Bluetooth-Beacons-Liste in eine .csv-Datei",
    "menu_no_ble_beacon_recorded": "Kein Bluetooth-Beacon aufgezeichnet",
    "menu_no_ble_beacon_imported": "Kein Bluetooth-Beacon importiert",
    "menu_number_of_registered_ble_beacons": "Anzahl der registrierten Bluetooth-Beacons: ",
    "menu_incorrect_column_headers_ble": "Falsche Spaltenüberschriften. Bitte verwenden Sie die Excel-Vorlage zum Erfassen von Bluetooth-Beacons und speichern Sie sie im .csv-Format mit dem Zeichen ';' als Trennzeichen.",
    "menu_number_of_imported_ble_beacons": "Anzahl der importierten Bluetooth-Beacons: ",
    "menu_an_error_was_detected_line": "Es wurde ein Fehler in der Zeile ",
    "menu_add_beacon": "Bluetooth-Beacon hinzufügen",
    "menu_modify_beacon": "Bluetooth-Beacon ändern",
    "menu_add_manually_ble_beacons": "Manuelles Hinzufügen von Bluetooth-Beacon",
    "menu_remove_all_beacons": "Sind Sie sicher, dass Sie alle registrierten Bluetooth-Beacons löschen möchten?",
    "menu_remove_one_beacon": "Sind Sie sicher, dass Sie dieses Bluetooth-Beacon löschen möchten?",
    "menu_add_beacon_success": "Bluetooth-Beacon erfolgreich hinzugefügt",
    "menu_add_beacon_failed": "Fehler beim Versuch, ein neues Bluetooth-Beacon hinzuzufügen",
    "menu_modify_beacon_success": "Bluetooth-Beacon mit Erfolg geändert",
    "menu_beacon_model": "Bluetooth-Beacon-Modell",
    "menu_beacon_company_id": "Firmen-ID",
    "menu_beacon_id": "Beacon-ID",
    "menu_beacon_type": "Typ",
    "menu_beacon_level": "Ebene",
    "menu_beacon_code_zone": "Zonencode",
    "menu_beacon_description": "Beschreibung",
    "menu_tippy_uuid": "Entspricht der UUID des iBeacon-Protokolls, die eine Folge von 32 hexadezimalen Zeichen ist",
    "menu_tippy_namespace_id": "Entspricht der Namespace-ID des Eddystone-Protokolls, die eine Folge von 20 hexadezimalen Zeichen ist",
    "menu_tippy_bbe9_major_minor": "Entspricht den 8 hexadezimalen Zeichen, die auf e-BBE9 Bluetooth beacon aufgedruckt sind",
    "menu_tippy_loc_ex_mac_address": "Entspricht der auf dem Bluetooth LOC-EX 01 Beacon aufgedruckten MAC-Adresse (6 Paare hexadezimaler Zeichen getrennt durch ':')",
    "menu_tippy_ibeacon_major_minor": "Entspricht der Verkettung von Major + Minor aus dem iBeacon-Protokoll, die eine Folge von 8 hexadezimalen Zeichen ist",
    "menu_tippy_instance_id": "Entspricht der Instanz-ID des Eddystone-Protokolls, die eine Folge von 12 hexadezimalen Zeichen ist",
    "menu_tippy_level": "0 für Erdgeschoss, negative Zahl für Untergeschoss (z.B. -3)",
    "menu_tippy_code_zone": "Vom SIA-Protokoll gesendete Informationen",
    "menu_tippy_description": "Mit dem Beacon verbundener Standort. Es ist verboten, das Zeichen „_“ zu verwenden, wenn die Option zum Senden von Nachrichten an Emerit-Uhren aufgrund eines Fehlers aktiviert ist (dieser Fehler wird im nächsten Update behoben).",
    "menu_err_ibeacon_company_id": "Falsche Unternehmens-ID. Diese entspricht der UUID. Sie muss daher aus 32 hexadezimalen Zeichen bestehen.",
    "menu_err_ibeacon_beacon_id": "Falsche Beacon-ID. Diese entspricht Major + Minor. Sie muss daher aus 8 hexadezimalen Zeichen bestehen.",
    "menu_err_eddystone_company_id": "Falsche Unternehmens-ID. Dies entspricht der Namespace-ID. Sie muss daher aus 20 hexadezimalen Zeichen bestehen.",
    "menu_err_eddystone_beacon_id": "Falsche Beacon-ID. Diese entspricht der Instanz-ID. Sie muss daher aus 12 hexadezimalen Zeichen bestehen.",
    "menu_err_bbe9_beacon_id": "Falsche Beacon-ID. Diese entspricht den 8 hexadezimalen Zeichen, die auf dem e-BBE9 Bluetooth-Beacon aufgedruckt sind.",
    "menu_err_bbe9_already_exist": "Ein e-BBE9 Bluetooth-Beacon mit dieser Kennung ist bereits vorhanden.",
    "menu_err_loc_ex_beacon_id": "Falsche Beacon-ID. Die Beacon-ID entspricht der auf dem LOC-EX 01 Bluetooth Beacon aufgedruckten MAC-Adresse.",
    "menu_err_loc_ex_already_exist": "Ein Bluetooth LOC-EX 01 Beacon mit dieser Kennung existiert bereits.",
    "menu_err_ibeacon_already_exist": "Ein iBeacon-Bluetooth-Beacon mit dieser UUID und dieser Major + Minor existiert bereits",
    "menu_err_eddy_already_exist": "Ein Eddystone-Bluetooth-Beacon mit dieser Namespace-ID und dieser Instanz-ID existiert bereits",
    "menu_err_wrong_level": "Falsche Level-Nummer. Muss eine positive oder negative Zahl sein.",
    "menu_err_empty_desc": "Das Beschreibungsfeld darf nicht leer sein.",
    "menu_err_wrong_code_zone": "Der Codebereich muss eine Folge von 3 Zahlen zwischen 001 und 999 sein. Beispiel: 007.",
    "menu_level_precision_with_ble": "Änderungen an den Etagen vornehmen",
    "menu_err_one_elem_missing_level": "Bitte geben Sie die Ebene für jedes Bluetooth-Beacon an.",
    "menu_err_wifi_level_precision_is_activated": "Die Verwaltung von Etagenwechseln mithilfe von Bluetooth-Beacons wurde für die Wifi-Innenposition ausgewählt. Dieser Parameter ist obligatorisch.",
    "menu_err_no_level_beacon": "Mindestens ein Beacon mit dem Typ LEVEL ist erforderlich, um Etagenänderungen zu verwalten",
    "menu_enable_import_export_csv": ".csv-Datei importieren/exportieren",
    "menu_title_refresh_ble_choice": "Aktualisierungsfrequenz",
    "menu_scan_mode_fastest": "Aktualisierung in regelmäßigen Abständen (6 Sekunden, hoher Verbrauch)",
    "menu_scan_mode_average": "Aktualisierung in regelmäßigen Abständen (10 Sekunden, mäßiger Verbrauch)",
    "menu_scan_mode_on_demand": "Aktualisierung nur bei Übertragung eines Ereignisses",
    "menu_ble_new_algo_activation_title":"Algorithmuskonfiguration",
    "menu_ble_new_algo_activation":"Aktivieren des neuen Algorithmus für die Ortung von Beacons"
  },
  "internalrecipients": {
    "menu_name_recipient": "Name des Empfängers",
    "menu_duration_of_call_attempt_before_next_number": "Dauer des Notrufversuchs vor dem Wechsel zur nächsten Nummer",
    "menu_event_select_call_and_or_sip_messaging": "SIP-Anruf und/oder Nachricht auswählen",
    "menu_request_loc_with_sms_command": "Dieser Empfänger kann den Standort mit dem folgenden SMS-Befehl anfordern: ?LOC",
    "menu_sip_scenario": "Übermittlung von Alarmen per SIP-Konto",
    "menu_alert_configuration_not_available_with_pec": "Wenn das Benachrichtigungsszenario 'SMS mit Bestätigung, dann Anruf' aktiviert ist, erfolgt die Konfiguration für den Empfang von Benachrichtigungen automatisch.",
    "menu_sens_sms_with_sip_not_possible": "Das Senden von SMS an eine Telefonnummer über ein SIP-Konto ist nicht verfügbar. Nur Anrufe sind verfügbar.",
    "menu_err_sip_account_already_exist": "Ein Empfänger mit dieser SIP-ID existiert bereits",
    "menu_receive_events_by_sip_messaging": "Dieser Empfänger erhält Benachrichtigungen per SIP-Nachricht",
    "menu_switch_watch_is_using_sip_account": "Dieser Empfänger verwendet ein SIP-Konto",
    "menu_recipient_added_with_success": "Empfänger hinzugefügt",
    "menu_recipient_modified_with_success": "Empfänger geändert",
    "menu_modify_recipient": "Empfänger ändern",
    "menu_sip_account_id": "SIP-ID",
    "menU_sip_servel_url": "SIP-Server-URL",
    "menu_icon_sms_desc": ": Dieser Empfänger erhält SMS-Benachrichtigungen",
    "menu_icon_phone_desc": ": Dieser Empfänger erhält Anrufbenachrichtigungen",
    "menu_icon_watch_desc": ": Dieser Empfänger ist eine Emerit-Uhr",
    "menu_icon_sip_desc": ": Dieser Empfänger verwendet ein SIP-Konto",
    "menu_number_or_sip_id": "Rufnummer / SIP-ID",
    "menu_remove_all_recipients": "Sind Sie sicher, dass Sie alle registrierten Empfänger entfernen möchten?",
    "menu_remove_one_recipient": "Möchten Sie diesen Empfänger wirklich entfernen?",
    "menu_add_recipient": "Empfänger hinzufügen",
    "menu_sms_and_calls_cycle": "SMS- und Anrufkaskade",
    "menu_sms_then_calls_cycle": "SMS ruft dann Kaskade an",
    "menu_sms_with_acknowledgment_then_call": "SMS mit Bestätigung, dann Anruf",
    "menu_scenario_is": "Alarmszenario: ",
    "menu_settings": "Einstellungen",
    "menu_nb_recipients_saved": "Anzahl gespeicherter interner Empfänger: ",
    "menu_is_emerit_watch": "Dieser Empfänger ist eine Emerit-Uhr",
    "menu_internal_recipients": "Interne Empfänger",
    "menu_monitoring_center_call_numbers": "Rufnummern der Überwachungszentrale",
    "menu_recipient": "Empfänger",
    "menu_enable_recipient": "Empfänger freischalten",
    "menu_call_number": "Rufnummer",
    "menu_enable_call_number": "Rufnummer freischalten",
    "menu_name": "Name",
    "menu_phone_number_international_format": "Rufnummer (internationales Format)",
    "menu_receive_alerts_by_call": "Dieser Empfänger erhält Benachrichtigungen per Anruf",
    "menu_receive_events_by_sms": "Dieser Empfänger erhält SMS-Benachrichtigungen",
    "menu_event_alerts": "Benachrichtigungen",
    "menu_event_system_status": "System-Status",
    "menu_event_charge_status": "Ladestatus",
    "menu_event_low_battery": "Niedriger Batteriestand",
    "menu_event_beacon_ble_low_battery": "Bluetooth Beacon Batterie schwach",
    "menu_event_select_call_and_or_sms": "Anruf und / oder SMS auswählen",
    "menu_nfc_reading": "NFC-Tag lesen"
  },
  "internalrecipientssettings": {
    "menu_internal_recipients_settings": "Einstellungen für interne Empfänger",
    "menu_sms_language_selection": "SMS-Sprachauswahl",
    "menu_alert_scenario_type": "Typ des Alarmszenarios",
    "menu_sms_and_calls_cycle": "Zyklus SMS und Anrufe",
    "menu_sms_then_calls_cycle": "Zyklus SMS und Anrufe",
    "menu_sms_with_acknowledgment_then_call": "SMS mit Quittierung und dann Anruf",
    "menu_maximum_waiting_time_for_acknowledgment_reception": "Maximale Wartezeit für den Empfang der Quittung",
    "menu_call_cycle_stopped_by_sms": "Anrufzyklus durch SMS gestoppt",
    "menu_show_sms_data_selection": "SMS-Datenauswahl anzeigen",
    "menu_sms_data_selection": "Auswahl der SMS-Daten",
    "menu_basic_data": "Grunddaten",
    "menu_gps_data": "GPS-Daten",
    "menu_device_user": "Geräte-Benutzername",
    "menu_battery_level": "Batteriestand",
    "menu_event_timestamp": "Zeitstempel des Ereignisses",
    "menu_google_map_link": "Google Map-Link",
    "menu_timestamp": "Zeitstempel",
    "menu_adress_google_reverse_geocoding": "Adresse (Google Reverse Geocoding)",
    "menu_accuracy": "Genauigkeit",
    "menu_coordinates_DMS_format": "Koordinaten im DMS-Format",
    "menu_coordinates_DD_format": "Koordinaten im DD-Format",
    "menu_alert_labels_customization": "Anpassung von Warnhinweisen",
    "menu_show_alert_labels_customization": "Anpassung der Warnhinweise anzeigen",
    "menu_request_for_help": "Anfrage nach Hilfe (Standard)",
    "menu_assault": "Überfall (Standard)",
    "menu_no_movement": "keine Bewegung (Standard)",
    "menu_tilt": "Neigung (Standard)"
  },
  "sipconfiguration": {
    "menu_title": "VOIP",
    "menu_sip_need_configuration": "VOIP konfigurieren",
    "menu_account_name": "Kontobeschreibung",
    "menu_user_name": "Benutzername",
    "menu_user_password": "Kennwort",
    "menu_server_url": "Url des Servers"
  },
  "ipserversettings": {
    "menu_std_gps_timestamp(O)": "[H] Zeitstempel der GPS-Position",
    "menu_extended_data_T2i_format_selection": "Erweiterte Datenauswahl im T2i©-Format",
    "menu_T2i_format": "T2i©-Format",
    "menu_sia_protocol_settings": "Einstellungen Protokoll SIA DC-09",
    "menu_encapsulated_alarm_protol": "Gekapseltes Alarmprotokoll",
    "menu_admeco_cid_contact_id": "Ademco Kontakt-ID (CID)",
    "menu_sia_dcs": "SIA DCS",
    "menu_extended_data_format_and_selection": "Erweitertes Datenformat und Auswahl",
    "menu_standard_format": "Standardformat",
    "menu_esi_format": "ESI©-Format",
    "menu_add_the_message_ending_timestamp": "Hinzufügen des Zeitstempels für das Ende der Nachricht",
    "menu_extended_data_standard_format_selection": "Erweiterte Daten Standardformatauswahl",
    "menu_extended_data_esi_format_selection": "Erweiterte Daten Auswahl des ESI©-Formats",
    "menu_std_battery": "B] Batteriestand",
    "menu_std_event_timestamp": "H] Zeitstempel des Ereignisses",
    "menu_std_imei": "D] IMEI des Geräts",
    "menu_std_beacon_id": "C] Wifi-Hotspot/Bluetooth-Beacon-Identifikator",
    "menu_std_gps_accuracy": "M] GPS-Standortgenauigkeit",
    "menu_std_gps_timestamp": "O] Zeitstempel des GPS-Standorts",
    "menu_std_gps_speed": "S] Geschwindigkeit des GPS-Standorts",
    "menu_std_gps_validity": "V] Gültigkeit des GPS-Standorts",
    "menu_std_gps_altitude": "Z] GPS-Standort Höhe",
    "menu_esi_battery_level": "B] Batteriestand",
    "menu_esi_gps_timestamp": "T] GPS-Standort Zeitstempel",
    "menu_gps_coordinates_format": "GPS-Koordinaten-Format",
    "menu_gps_DD": "DD Dezimalgrad",
    "menu_gps_DDM": "DDM Grad dezimale Minuten",
    "menu_customed_field": "Benutzerdefiniertes Feld",
    "menu_lpref": "Lpref",
    "menu_monitoging_server_sia_protocol_settings": "Leistellen-Server SIA DC-09 Protokolleinstellungen",
    "menu_monitoring_center_server_sia_protocol_settings": "Leistellenserver SIA DC-09-Protokolleinstellungen",
    "menu_location_maximum_age": "Standort maximales Alter",
    "menu_location_maximum_accuracy": "Maximale Genauigkeit des Standorts"
  },
  "ipserver": {
    "menu_unit_description_message":"Berichten",
    "menu_event_end_tracking_stop_post_alert":"Einde van tracking bij Post-alert stop",
    "menu_event_end_tracking_after_x_message":"Einde van tracking na het verzenden van",
    "menu_event_permanents":"Permanent",
    "menu_event_trigger_alert":"Bij het activeren van een alarm",
    "menu_enable_link_test":"Link Test inschakelen",
    "menu_link_test":"Link Test",
    "menu_alarm_monitoring_center_server": "Server van het alarmbewakingscentrum",
    "menu_enable_main_server": "Hoofdserver inschakelen",
    "menu_main_server": "Hoofdserver",
    "menu_events_to_be_transmitted": "Te verzenden gebeurtenissen",
    "menu_client_id": "Klant-ID",
    "menu_ip_url": "IP-adres of URL",
    "menu_port": "Poortnummer",
    "menu_enable_cyclic_test": "Tracking inschakelen",
    "menu_cyclic_test": "Tracking",
    "menu_test_interval": "Verzendinterval",
    "menu_backup_server": "Back-upserver",
    "menu_backup_sms": "SMS-back-up",
    "menu_enable_backup_server": "Back-upserver inschakelen",
    "menu_enable_backup_sms": "SMS-back-up inschakelen",
    "menu_port_must_be_between_0_and_65536": "Het poortnummer moet tussen 0 en 65536 liggen"
  },
  "wearingways": {
    "menu_waist_worn": "am Körper getragen",
    "menu_enable_no_movement_detection": "Aktivieren Sie die Ruhealarm",
    "menu_enable_tilt_detection": "Aktivieren Sie den Lagealarm",
    "menu_no_movement_detection": "Ruhealarmerkennung",
    "menu_tilt_detection": "Lageerkennung",
    "menu_detection_duration_before_prealert": "Dauer der Erkennung vor dem Voralarm",
    "menu_detection_threshold": "Erkennungsschwelle",
    "menu_detection_mode": "Erkennungsmodus",
    "menu_tilt_only": "Nur Neigung",
    "menu_tilt_without_movement": "Kippen ohne Bewegung",
    "menu_select_a_wearing_way_and_modify_settings_if_needed": "Wählen Sie eine Trageweise und ändern Sie die Einstellungen, falls erforderlich."
  },
  "wristalert": {
    "menu_noise_mode": "Lautsprecher EIN > Bitte um Hilfe",
    "menu_discret_mode": "Lautlos > Überfallkontext",
    "menu_choose_alert_mode": "Alarmierungsmodus",
    "menu_title_wrist_alert": "Konfiguration des Alarms durch Bewegung des Handgelenks",
    "menu_choose_threshold": "Bewegungsintensität zum auslösen des alarms",
    "menu_thresold_value_low": "Niedrig schnell",
    "menu_thresold_value_middle": "Mittelschnell (empfohlen)",
    "menu_thresold_value_high": "Sehr schnell"
  },
  "login": {
    "menu_first_device_boot_error": "Wenn dies die erste Konfiguration eines Geräts ist, muss ein Inbetriebnahmeverfahren durchgeführt werden, bevor es konfiguriert werden kann.",
    "menu_quick_start_guide": "Onlinehilfe",
    "menu_home_help": "https://magneta.document360.io/v1/de",
    "menu_connexion_error": "Ein Verbindungsproblem ist aufgetreten. Bitte überprüfen sie ihre internetverbindung.",
    "menu_enter_five_last_digits_of_serial_number": "Geben Sie die 5 letzten Ziffern der Seriennummer (SN) ein",
    "menu_enter_five_last_digits_of_imei_number": "Geben Sie die 5 letzten Ziffern der IMEI-Nummer ein",
    "menu_login": "Anmeldung",
    "menu_select_device_model": "Wählen Sie das Gerätemodell",
    "menu_please_select_the_device_model": "Bitte wählen Sie zuerst das Gerätemodell",
    "menu_entered_numbers_dont_match_with_available_device": "Die eingegebenen Nummern stimmen mit keinem Gerät überein oder es ist ein Verbindungsproblem aufgetreten.",
    "menu_please_read_quick_start_guide_first": "Bitte lesen Sie zuerst die Schnellstartanleitung.",
    "menu_configure_this_device": "Konfigurieren Sie dieses Gerät",
    "menu_configuration_models_management": "Verwaltung von Konfigurationsvorlagen",
    "menu_deploy_a_configuration_model": "Eine Konfigurationsvorlage bereitstellen",
    "menu_invalid_file": "Ungültige Datei",
    "menu_only_file_with_emerit_extension_are_supported": "Nur Dateien mit der Erweiterung .emerit werden unterstützt",
    "menu_create_configuration_model": "Konfigurationsvorlage erstellen",
    "menu_modify_a_configuration_model": "Eine Konfigurationsvorlage ändern",
    "menu_drag_and_drop_file_or_click_to_select": "Ziehen Sie die Konfigurationsvorlage per Drag & Drop oder klicken Sie, um die Datei auszuwählen",
    "menu_configuration_model": "Konfigurationsvorlage",
    "menu_configuration_model_creation": "Eine Konfigurationsvorlage erstellen",
    "menu_connect_to_a_device": "Verbinden mit einem Gerät",
    "menu_models_dont_match": "Die ausgewählte datei stimmt nicht überein oder das konfigurationsmodell ist nicht mit dem ausgewählten modell kompatibel.",
    "menu_select_language": "Sprache auswählen"
  },
  "wifi": {
    "menu_mode_utilisation": "Aktualisierung der Zeitzone entsprechend dem Verwendungsmodus",
    "menu_select_utc": "Wählen Sie eine Zeitzone aus",
    "menu_error_utc_none": "Bitte wählen Sie eine Zeitzone aus",
    "menu_mode_wifi_alone": "Nur WiFi:",
    "menu_mode_gsm_plus_wifi": "WiFi + GSM (SIM-Karte): automatische Aktualisierung",
    "menu_certificate_upload_success": "Zertifikat erfolgreich heruntergeladen",
    "menu_certificate_upload_fail": "Download des Zertifikats fehlgeschlagen",
    "menu_err_pls_upload_certificate": "Bitte laden Sie ein Zertifikat hoch",
    "menu_err_only_pfx_p12_accepted": "Es werden nur Zertifikate im Format .pfx/.p12 akzeptiert.",
    "menu_err_certificate_is_too_heavy": "Die Größe des Zertifikats ist zu groß, sie darf nicht mehr als 5 MB betragen.",
    "menu_upload_certificat": "PEAP-Zertifikat importieren (.pfx/.p12)",
    "menu_certificate_password": "Kennwort",
    "menu_certificate_identifiant": "Kennung",
    "menu_certificate_type": "CA Certificat",
    "menu_name_is_update_app_by_wifi_enabled": "Konfigurationen abrufen und Updates nur bei aktivem WLAN installieren",
    "menu_use_wifi_connection": "Wifi-Verbindung für das Senden von Alarmen verwenden",
    "menu_config_wifi_network": "WLAN-Netzwerke verwalten",
    "menu_nb_wifi_network_saved": "Anzahl der gespeicherten WLAN-Netzwerke: ",
    "menu_send_alert_with_wifi": "Das WLAN-Netz, sofern verfügbar, wird gegenüber dem GSM-Netz priorisiert",
    "menu_network_added_with_success": "WLAN-Netzwerk hinzugefügt",
    "menu_network_modified_with_success": "Wifi-Netzwerk geändert",
    "menu_remove_all_networks": "Sind Sie sicher, dass Sie alle gespeicherten WLAN-Netzwerke entfernen möchten?",
    "menu_remove_one_network": "Möchten Sie dieses WLAN-Netzwerk wirklich entfernen?",
    "menu_registred_wifi_networks_list": "Liste der registrierten Wifi-Netzwerke - nur 2,4 GHz",
    "menu_ssid": "Netzwerkname SSID",
    "menu_security_type": "Sicherheitstyp",
    "menu_password": "Kennwort",
    "menu_add_a_wifi_network": "Ein Wifi-Netzwerk hinzufügen",
    "menu_modify_a_wifi_network": "WLAN-Netzwerk ändern",
    "menu_do_you_want_to_remove_this_network": "Möchten Sie dieses Netzwerk entfernen?",
    "menu_please_select_security_type": "Bitte wählen Sie den Sicherheitstyp",
    "menu_hidden": "Verstecktes Netzwerk",
    "menu_network_already_exists": "Netzwerk existiert bereits"
  },
  "whitelist": {
    "menu_add_a_recipient_whitelist": "Zusätzliche eingehende nummer hinzufügen",
    "menu_modify_a_recipient_whitelist": "Zusätzliche eingangsnummer ändern",
    "menu_recipient_whitelist_modified_with_success": "Zusätzliche eingangsnummer erfolgreich geändert",
    "menu_recipient_whitelist_added_with_success": "Zusätzliche eingehende nummer erfolgreich hinzugefügt",
    "menu_nb_recipient_whitelist": "Zusätzliche eingehende Rufnummern: ",
    "menu_nb_recipient_mirror": "Benachrichtigungsempfänger: ",
    "menu_remove_all_recipient_whitelist": "Sind Sie sicher, dass Sie alle zusätzlichen eingehenden Nummern von der Whitelist entfernen möchten?",
    "menu_remove_one_recipient_whitelist": "Sind Sie sicher, dass Sie diese zusätzliche eingehende Nummer von der Whitelist entfernen möchten?",
    "menu_add_whitelist_recipient": "Eingehende Nummer hinzufügen",
    "menu_nb_total_whitelist_recipient": "Gesamtzahl der in der whitelist erlaubten eingehenden Nummern: ",
    "menu_whitelist_title": "Whitelist-Konfiguration"
  },
  "customscreens": {
    "menu_img_upload_info": "Die Größe eines .png/.gif-Bildes darf 1 MB nicht überschreiten. Die Bildschirmauflösung der Uhr beträgt 128 x 96 Pixel. Für eine optimale Wiedergabe des Bildes beachten Sie die Höhe/Bildschirmbreite (1,33). Wenn das Verhältnis nicht beachtet wird, wird die Größe des Bildes automatisch angepasst.",
    "menu_err_pls_upload_launch_screen": "Bitte laden Sie ein Bild hoch.",
    "menu_err_pls_upload_widget_screen": "Bitte laden Sie ein Bild hoch oder geben Sie Text ein.",
    "menu_err_only_png_gif_accepted": "Nur Bilder im .png/.gif-Format werden akzeptiert.",
    "menu_err_img_is_too_heavy": "Die Bildgröße ist zu groß, sie darf 1 MB nicht überschreiten.",
    "menu_img_upload_success": "Bild erfolgreich hochgeladen",
    "menu_img_upload_fail": "Bild konnte nicht hochgeladen werden",
    "menu_custom_screens_overview": "Vorschau",
    "menu_remove_launch_img": "Bild entfernen",
    "menu_remove_widget_img": "Hintergrundbild entfernen",
    "menu_display_widget_text_in_black": "Text in Schwarz anzeigen",
    "menu_custom_widget_top_text": "Oberer Text (max. 18 Zeichen inkl. Leerzeichen)",
    "menu_custom_widget_bottom_text": "Unterer Text (max. 18 Zeichen inkl. Leerzeichen)",
    "menu_upload_launch_screen_img": "Neues Bild herunterladen (.png/.gif)",
    "menu_upload_widget_screen_img": "Neues Hintergrundbild herunterladen (.png/.gif)",
    "menu_title_yes_custom_launch_screen": "Bildeinrichtung starten",
    "menu_title_no_custom_launch_screen": "Startbildschirm anpassen",
    "menu_title_yes_custom_widget_screen": "Kontakt-Widget einrichten",
    "menu_title_no_custom_widget_screen": "Kontakt-Widget hinzufügen",
    "menu_custom_screens_title": "Bildschirme anpassen"
  },
  "password": {
    "menu_create_new_password_for_model": "Geben Sie ein 6-stelliges Passwort ein, das mindestens 1 Kleinbuchstaben, 1 Großbuchstaben und 1 Zahl ohne Sonderzeichen enthält. Dieses Passwort wird bei jeder Änderung dieses Modells abgefragt.",
    "menu_enter_password": "Kennwort eingeben",
    "menu_enter_deployment_password": "Kennwort für die Konfigurationvorlagenerstellung eingeben",
    "menu_create_new_password": "Geben Sie ein 6-stelliges Kennwort ein, das mindestens 1 Kleinbuchstaben, 1 Großbuchstaben und 1 Zahl enthält, keine Sonderzeichen. Dieses Kennwort wird jedes Mal abgefragt, wenn Sie eine Verbindung zu diesem Gerät herstellen.",
    "menu_confirm_password": "Kennwort bestätigen",
    "menu_wrong_password": "Falsches Kennwort",
    "menu_please_retry": "Bitte versuchen Sie es erneut",
    "menu_empty_password": "Leeres Kennwort",
    "menu_passwords_dont_match": "Kennwörter stimmen nicht überein",
    "menu_at_least_one_password_is_empty": "Mindestens ein Kennwort ist leer",
    "menu_password_dont_meet_requirements": "Kennwort entspricht nicht den Anforderungen",
    "menu_modify_password": "Ändern Sie das Kennwort",
    "menu_password_creation_warning": "Durch das Einloggen in die emerit-Konsole und nach dem Erstellen des Kennworts werden Sie de facto zum Administrator des oben genannten Gerätes"
  },
  "contact": {
    "menu_contact_link": "https://www.magneta.fr/de/kontakt/",
    "menu_if_you_have_any_question_please_click_on_the_folowing_button": "Bei Fragen zur Gerätekonfiguration oder -nutzung klicken Sie bitte auf die folgende Schaltfläche:",
    "menu_technical_support_email": "E-Mail für technische Unterstützung:",
    "menu_online_support_request_form": "Online-Support-Anfrageformular"
  },
  "deploy": {
    "menu_upload_failed": "Bereitstellung fehlgeschlagen, überprüfen Sie die Internetverbindung und versuchen Sie es erneut",
    "menu_device_updated": "Gerät aktualisiert",
    "menu_fill_server_ids": "Client-IDs konfigurieren",
    "menu_fill_personal_fields": "Geben Sie die zu personalisierenden Daten ein",
    "menu_target_a_device": "Zu konfigurierendes Gerät",
    "menu_client_id_for_tls_main_server": "Client-ID für den Hauptserver der Überwachungszentrale",
    "menu_client_id_for_tls_backup_server": "Client-ID für den Backup-Server der Überwachungszentrale",
    "menu_client_id_for_supervision_main_server": "Client-ID für den Hauptüberwachungsserver",
    "menu_client_id_for_supervision_backup_server": "Client-ID für den Backup-Überwachungsserver",
    "menu_from_a_configuration_model": "Aus Konfigurationsvorlage",
    "menu_five_last_digits_of_serial_number": "5 letzte Ziffern der Seriennummer (SN)",
    "menu_five_last_digits_of_imei_number": "5 letzte Ziffern der IMEI-Nummer",
    "menu_deploy_not_allowed": "Sie sind nicht berechtigt, dieses Gerät per Bereitstellung zu konfigurieren, Bereitstellungskennwörter stimmen nicht überein"
  },
  "side_menu": {
    "configuration": "Konfiguration",
    "info": "Handbücher",
    "contact": "Kontakt"
  },
  "watchhelp": {
    "menu_custom_screens_view": "https://www.support.magneta.fr/docs/en/watch-system-options#ii-customize-screen",
    "menu_locex_help": "https://www.support.magneta.fr/docs/en/beacon-loc-ex-01",
    "menu_wifi_connection_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#i-use-wifi-connection",
    "menu_sip_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#ii-activate-voice-over-ip-voip-with-sip-account",
    "menu_whitelist_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#iii-whitelist-incoming-call-limitation",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/en/watch-main-page#i-device-user-name",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/en/watch-main-page#ii-alerts-processing",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/en/watch-main-page#iii-alerts-triggering-mode",
    "menu_loc": "https://www.support.magneta.fr/docs/en/watch-main-page#iv-alerts-localization",
    "menu_communication_option": "https://www.support.magneta.fr/docs/en/watch-main-page#v-communication-options",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/en/watch-main-page#vi-battery-monitoring",
    "menu_device_notif_management": "https://www.support.magneta.fr/docs/en/watch-main-page#vii-device-notification-management",
    "menu_system_option": "https://www.support.magneta.fr/docs/en/watch-main-page#viii-system-options",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9#associating-a-type-to-beacons",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#configuring-personal-settings",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#entering-the-deployment-password",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#templates-modification",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#configuration-template-creation",
    "menu_model_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#ii-multidevice-setup",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#iii-deployment-of-a-configuration",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#enter-password",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#password-creation",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#i-gps-outdoor-location",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#ii-wifi-indoor-location",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#iii-bluetooth-indoor-location",
    "menu_main_page": "https://www.support.magneta.fr/docs/en/watch-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/en/watch-commissioning#iv-first-boot"
  },
  "phonehelp": {
    "menu_locex_help": "https://www.support.magneta.fr/docs/en/beacon-loc-ex-01",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/en/smartphones-main-page#i-device-user-name",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/en/smartphones-main-page#ii-alerts-processing",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/en/smartphones-main-page#iii-alerts-triggering-mode",
    "menu_loc": "https://www.support.magneta.fr/docs/en/smartphones-main-page#iv-alerts-localization",
    "menu_communication_option": "https://www.support.magneta.fr/docs/en/smartphones-main-page#v-communication-options",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/en/smartphones-main-page#vi-battery-monitoring",
    "menu_deactivate_alert_system": "https://www.support.magneta.fr/docs/en/smartphones-main-page#vii-alert-system-deactivation-by-the-user",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9#associating-a-type-to-beacons",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#configuring-personal-settings",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#entering-the-deployment-password",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#templates-modification",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#configuration-template-creation",
    "menu_model_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#ii-multidevice-setup",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#iii-deployment-of-a-configuration",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#enter-password",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#password-creation",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#i-gps-outdoor-location",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#ii-wifi-indoor-location",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#iii-bluetooth-indoor-location",
    "menu_main_page": "https://www.support.magneta.fr/docs/en/smartphones-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/en/smartphones-commissioning#ii-first-boot"
  }
}
