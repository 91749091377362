<template>
  <!-- BEGIN: CheckBoxFieldAndXIcons component-->
  <div :class="customClass">
    <input
      :id="customId"
      type="checkbox"
      class="form-check-input float-left"
      :class="getDisabledClass"
      :checked="modelValue"
      :disabled="isDisabled"
      @change="$emit('update:modelValue', $event.target.checked)"
    >
    <Tippy
      v-if="isDisabled && tippyText.length"
      :content="$t(tippyText)"
      :options="{
        theme: 'light',
      }"
    >
      <label 
        class="form-check-label text-md" 
        :class="getDisabledClass"
        :for="customId"
      >
        {{ $t(checkBoxText) }}
      </label>
    </Tippy>
    <label 
      v-else
      class="form-check-label text-md" 
      :for="customId"
    >
      {{ $t(checkBoxText) }}
    </label>
    <template 
      v-if="modelValue && iconParams" 
    >
      <router-link 
        v-for="(icon, index) in getIconWithClasses"
        :key="index"
        :to="{ name: icon.routeName, params: { type: icon.paramsType } }"
      >
        <div :class="icon.class">
          <component :is="icon.componentName" />
        </div>
      </router-link>
    </template>
    <p 
      v-if="errorMsg != ''" 
      class="mt-1"
    >
      {{ $t(errorMsg) }}
    </p>
  </div>
  <!-- END: CheckBoxFieldAndXIcons component-->
</template>

<script>
import { defineComponent } from 'vue'


const DISABLED_CLASS = "opacity-50";


/**
 * -------------------------- DESCRIPTION ---------------------------
 * A checkbox with a description indicating what's its purpose, and 0 or 
 * x icons rendered on its right when checkbox is checked, that can 
 * router-link to another page when the user clicks on it. 
 * Used mainly in emerit-main-config for example. v-model 
 * MUST be used on this component, the value of the checkbox will be
 * automatically bind to the parent variable.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {Boolean} modelValue
 *    Value of the checkbox. Automatically set when using v-model on this
 *    component. For example, v-model="parentVariable" will bind this 
 *    modelValue to parentVariable. Will be initialized with parent variable
 *    value.
 * 
 * @param {String} customId
 *    Unique id used to link description to the checkbox.
 * 
 * @param {String} checkBoxText
 *    Description of the checkbox prompt to the user.
 *    Should be i18 key for the locale (ex: 'common.menu_save').
 * 
 * @param {String} errorMsg
 *    If empty, nothing appears. Otherwise, this string will appear 
 *    as an error in red to the user. Should be key for i18 locale.
 *    Empty by default.
 * 
 * @param {String} customClass
 *    A custom class to override the default class used to style this 
 *    component. Do not fill this props if you're ok with the default 
 *    style.
 * 
 * @param {Boolean} isDisabled
 *    If true, disables the checkbox field. A tippy can be shown to
 *    explain why it's disabling by filling tippyText props. See tippyText
 *    props documentation for more information. False by default. 
 *    Do not fill this props if you do not need this feature.
 * 
 * @param {String} tippyText
 *    The text of the tippy shown in case the component is disabled.
 *    If tippyText is empty, the tippy will not be shown. Should be key 
 *    for i18 locale. Empty string by default. Do not fill this props 
 *    if you do not need this feature.
 * 
 * @param {Array[x Object] || undefined} iconParams
 *    An array of objects representing icons that will be rendered when
 *    checkbox is checked. Each object will result into an icon. 
 *    Each icon can route to a view, with additional parameters if needed. 
 *    This props is undefined by default, and when it's the case no icon 
 *    will be rendered; so do not fill this props if you do not need icons.
 *    The object should be the following:
 *    
 *    iconParams: {
 *      componentName: 'SlidersIcon',         // Name of the dynamic component
 *      class: 'mb-3'                         // Usefull for spaces between several icons,
 *                                               can be empty otherwise.
 *      routeName: 'emerit-location-outdoor', // Name of the route for router-link when icon
 *                                               is clicked.
 *      paramsType: ''                        // Additional parameter for router-link.
 *    }                                          Can be empty.
 * 
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits update:modelValue
 * @return {Boolean}
 *    Emits the value of the checkbox (true or false). When v-model is
 *    used on this component, v-model automatically update with this
 *    value.
 *                    
 * ------------------------------------------------------------------
 */
export default defineComponent({

  name: 'CheckBoxFieldAndXIcons',
  
  props: {
    // eslint-disable-next-line
    modelValue: {
      required: true,
    },
    customId: {
      type: String,
      required: true,
    },
    checkBoxText: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    tippyText: {
      type: String,
      default: '',
    },
    errorMsg: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: 'clearfix p-3 border-b border-gray-200',
    },
    iconParams: {
      type: Array || undefined,
      default: undefined,
    }
  },

  emits: ['update:modelValue'],
  
  computed: {
    getIconWithClasses() {
      if (!this.iconParams)
        return undefined;
      let icon = new Array();
      for (let i = 0; i < this.iconParams.length; ++i) {
        let iconClass = (i === 0) ? "float-right" : "float-right pr-3";
        icon.push({
          componentName: this.iconParams[i].componentName,
          routeName: this.iconParams[i].routeName,
          paramsType: this.iconParams[i].paramsType,
          class: iconClass,
        })
      }
      return icon;
    },

    getDisabledClass() {
      if (this.isDisabled)
        return DISABLED_CLASS;
      return '';
    }    
  }
})
</script>
